export default {
  name: 'crmPropertyValuation',
  namespace: 'property_valuations',
  methods: [
    {
      name: 'find',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/crm/${namespace}/${id}/`,
      urlParams: ['id']
    },
    {
      name: 'filter',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/crm/${namespace}/`,
      urlParams: []
    },
    {
      name: 'create',
      params: ['data'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace }) => `/v1/crm/${namespace}/`,
      urlParams: []
    },
  ]
}
