import ApiBase from 'app/services/api/entities/Base'

const BASE_URL = '/v1/public/seo/urls/'

function* all(params = {}, requestOptions = {}) {
  return yield ApiBase.privateGet(BASE_URL, {
    ...requestOptions,
    params
  })
}

export default {
  all,
}
