import { formatMoney, formatPercentaje } from 'services/number'
import { createReducer, createActions } from 'reduxsauce'

export const INITIAL_STATE = {
  params: {},
  valueEstimationResponse: null,
  total: null,
  personalProfit: null,
  profitGoal: null,
  progress: null,
  valorisation: null,
  appliedBranchCoefficient: null,
  totalToAnimateNetwork: null,
  totalAfterNetworkAnimation: null,
  percentageToAnimateNetwork: 20,
  commissions: [],
  commissionsAfterNetworkAnimation: [],
  commissionsAndCoefficients: [],
}

const { Types, Creators } = createActions(
  {
    loadData: ['responseData'],
    loadParams: ['paramsData'],
  },
  { prefix: 'VALUE_ESTIMATION_' }
)

const mapCommissions = (commissions) => commissions.map((c) => ({
  amount: formatMoney(c.amount),
  level: c.level,
  position: c.position,
  percentage: formatPercentaje(c.percentage),
}))
const mapBranchCoefficients = (branches, coefficients) => {
  const byBranchNumber = coefficients.sort((a, b) => a.branches - b.branches)
  if (branches < 1) {
    return byBranchNumber[0]
  }

  const exactMatch = byBranchNumber.find((bn) => bn.branches === branches)
  if (exactMatch) {
    return exactMatch
  }

  return byBranchNumber[byBranchNumber.length - 1]
}

const annualProgressValorisation = (valorisation, goal, proratedGoal, currentAmount, coefficients) => {
  let identifier = 'GTE_PRORATE'

  if (currentAmount < proratedGoal) {
    identifier = 'LT_PRORATE'
  }

  if (currentAmount >= goal) {
    identifier = 'GTE_GOAL'
  }

  const chosenCoefficient = coefficients.find((c) => c.identifier === identifier)
  return {
    chosenCoefficient,
    valorisation: valorisation * chosenCoefficient.coefficient
  }
}

const loadParams = (state, { paramsData }) => ({ ...state, params: paramsData })
const loadData = (state, { responseData }) => {
  const {
    params: PARAMS,
  } = state
  const {
    commissions,
    branches,
    total,
    profitGoal,
    personalProfit,
    progress,
  } = responseData

  const toAnimateNetwork = PARAMS.ownerInvestmentPercentage / 100
  const totalToAnimateNetwork = total * toAnimateNetwork
  const appliedBranchCoefficient = mapBranchCoefficients(branches, PARAMS.branchMultiplier)
  const cleanCommissions = commissions.filter(
    (c) => !!c.level
  ).sort(
    (a, b) => a.position - b.position
  )
  let valorisation = 0

  const commissionsAndCoefficients = cleanCommissions.map((c) => {
    const levelCoefficient = PARAMS.commissionDistributionMultiplier.find((lc) => lc.position === c.position)
    const amount = c.amount * (1 - toAnimateNetwork) * levelCoefficient.coefficient
    valorisation += amount
    return {
      ...c,
      amount: formatMoney(amount),
      coefficient: levelCoefficient,
    }
  })

  const profitGoalProrated = profitGoal * (progress / 100)
  const annualGoalProgressCoefficients = PARAMS.financialGoalMultiplier.sort(
    (a, b) => a.coefficient - b.coefficient
  )
  const annualGoalProgressValorisation = annualProgressValorisation(
    valorisation,
    profitGoal,
    profitGoalProrated,
    personalProfit,
    PARAMS.financialGoalMultiplier,
  )

  return {
    ...state,
    branches,
    profitGoal: formatMoney(profitGoal),
    profitGoalProrated: formatMoney(profitGoalProrated),
    personalProfit: formatMoney(personalProfit),
    appliedBranchCoefficient,
    valueEstimationResponse: responseData,
    commissions: mapCommissions(cleanCommissions),
    commissionsAfterNetworkAnimation: mapCommissions(
      cleanCommissions.map((c) => ({
        ...c,
        amount: c.amount * (1 - toAnimateNetwork)
      }))
    ),
    commissionsAndCoefficients,
    total: formatMoney(total),
    totalToAnimateNetwork: formatMoney(totalToAnimateNetwork),
    totalAfterNetworkAnimation: formatMoney(total - totalToAnimateNetwork),
    valorisation: formatMoney(valorisation),
    branchValorisation: formatMoney(appliedBranchCoefficient.coefficient * valorisation),
    annualGoalProgressValorisation: formatMoney(annualGoalProgressValorisation.valorisation),
    percentageToAnimateNetworkFormatted: formatPercentaje(PARAMS.ownerInvestmentPercentage),
    annualGoalProgressCoefficients,
    branchCoefficients: PARAMS.branchMultiplier.sort((a, b) => a.branches - b.branches),
    chosenAnnualGoalProgressCoefficient: annualGoalProgressValorisation.chosenCoefficient,

    grossValorisation: formatMoney(annualGoalProgressValorisation.valorisation),
    finalValorisationCoefficient: PARAMS.annualBillingCompound,
    finalValorisation: formatMoney(annualGoalProgressValorisation.valorisation * PARAMS.annualBillingCompound),
    finalValorisationPercentage: formatPercentaje(PARAMS.highLowRatioPercentage),
    highValorisation: formatMoney(annualGoalProgressValorisation.valorisation * PARAMS.annualBillingCompound * ((100 + PARAMS.highLowRatioPercentage) / 100)),
    lowValorisation: formatMoney(annualGoalProgressValorisation.valorisation * PARAMS.annualBillingCompound * ((100 - PARAMS.highLowRatioPercentage) / 100)),
  }
}

export const MLMValueEstimationActionTypes = Types
export const MLMValueEstimationActionCreators = Creators
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_DATA]: loadData,
  [Types.LOAD_PARAMS]: loadParams,
})
