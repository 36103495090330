import {
  actionChannel,
  put,
  select,
  take,
  throttle,
  race,
  all,
} from 'redux-saga/effects'
import { buffers } from 'redux-saga'
import { checkFeatureFlag } from 'config'
import { getPropertyData } from 'selectors/property'
import { store } from 'services'
import { actionTypes, getFormValues } from 'redux-form'
import {
  DRAFT_ACTION,
  DRAFT_ACTION_END,
  PropertyActionCreators
} from 'app/reducers/PropertyRedux'
import UserDetails from 'services/api/entities/userDetails'
import { getUserProfile } from 'app/selectors'
import { buildActors, prepareAgentBrokers } from 'selectors/dealActors'
import { getExternalLeadSources } from 'app/sagas/ExternalLeadSources'


export function* createAndReview({ data }) {
  yield put(store.create('property').action(data))
  const response = yield take(store.create('property').types.success)
  if (response.data) {
    yield put(store.review('property').action(response.data.id))
    const reviewResponse = yield take(store.review('property').types.success)

    if (reviewResponse.response.status === 200) {
      yield put(
        store.createAndReview('property').action(reviewResponse.response.data)
      )
    }
  }
}

function* saveProperty(action) {
  const {
    meta: { form }
  } = action
  if (form !== 'editProperty' && form !== 'createProperty') {
    return
  }
  try {
    const values = yield select(getFormValues(form))
    const formData = yield select(getPropertyData(form))
    if (values.details.id) {
      yield put(store.draft('property').action(values.details.id, formData))
      yield race({
        failure: take(store.draft('property').types.failure),
        success: take(store.draft('property').types.success)
      })
    } else {
      yield put(store.create('property').action(formData))
      yield race({
        failure: take(store.create('property').types.failure),
        success: take(store.create('property').types.success)
      })
    }
  } catch (e) {
    console.log(`could not ${form}: ${e.message}`)
  }
}

export function* autoDraftPropertyWatcher() {
  const draftChannel = yield actionChannel(DRAFT_ACTION, buffers.sliding(1))
  while (true) {
    const action = yield take(draftChannel)
    yield saveProperty(action)
    yield put({ type: DRAFT_ACTION_END })
  }
}

const isActionDraftProperty = action => {
  const { meta: { field, form } } = action

  return (
    (form === 'editProperty' || form === 'createProperty') &&
    field !== 'media.imagesJson'
  ) 
}

function* handlePropertyChange(action) {
  if (isActionDraftProperty(action)) {
    yield put({
      ...action,
      type: DRAFT_ACTION
    })
  }
}

export function* autoSavePropertyWatcher() {
  yield throttle(5000, actionTypes.CHANGE, handlePropertyChange)
}

export function* handlePropertyDraftChange(action) {
  if (isActionDraftProperty(action)) {
    yield put(PropertyActionCreators.draftAction())
  }
}

export function* autoSavePropertyWatcherImagesJson(action) {
  const {
    meta: { field, form }
  } = action

  if (
    (form === 'editProperty' || form === 'createProperty') &&
    field === 'media.imagesJson'
  ) {
    yield saveProperty(action)
  }
}

export function* closeDealProperty({ propertyId, property, dealValues }) {
  const currentUser = yield select(getUserProfile)
  const referalLeadSourceOptions = yield getExternalLeadSources()

  const { brokers, externalAgents, contact, propertyCloseValues } = dealValues

  const agentResponses = yield all(
    brokers.map(broker => UserDetails.agent(broker.id)),
  )

  const brokersWithAgentInfo = prepareAgentBrokers(
    Number(currentUser.userId),
    brokers,
    agentResponses
      .filter(response => response.success())
      .map(response => response.data),
  )

  const actors = buildActors({
    property,
    contact,
    initialBrokerId: Number(currentUser.userId),
    brokers: brokersWithAgentInfo,
    externalAgents: externalAgents,
    referalLeadSourceOptions: referalLeadSourceOptions,
  })

  const { neximer, neximerData, externalAgent, ...payload } = propertyCloseValues

  if (checkFeatureFlag('deal-actors')) {
    payload.actors = actors
  } else {
    payload.externalAgent = externalAgent
    payload.neximer = neximer
    payload.neximerData = neximerData
  }

  yield put(store.reportDealByProperty('property').action(propertyId, payload))
  yield put(PropertyActionCreators.closeDealPropertyFinished())
}