import { useSelector } from 'react-redux'
import moment from 'moment'
import config from 'config'
import { Contracts, REQUEST_CONTRACTS_TYPES } from 'app/services/seo'
import { hasContractFeatureSelector, getUserProfile } from 'app/selectors'
import { monthNames } from 'services/date'
import CONTRACT_VERSION_PERMISSIONS from 'components/ContractVersion/permissions'
import { contractVersionPermissionSelector } from 'app/selectors/contractVersion'
import { downloadFileLink, splitDotIndex, linkToFile } from './downloadFileLink'

export const NEXIMO_YEAR_CREATION = 2018

export const optionsForSelect = options =>
  options.map(({ label, value, disabled }, index) => (
    <option key={index} value={value} disabled={disabled}>
      {label}
    </option>
  ))

/**
 * @param {InputEvent} param
 */
export const getEventValue = ({ target }) => target.value

/**
 * An array of months with its names (example: It can be used in any dropdown)
 */
export const monthsOptions = Object.values(monthNames).map(
  (monthName, index) => ({
    label: monthName,
    value: index + 1,
  }),
)

export const getMonthsOptions = months => {
  const monthsOptions = (Array.isArray(months) && months) || new Array(months)

  return monthsOptions.fill(1).map((_, index) => ({
    value: index + 1,
    label: `${index + 1} mes${index > 0 ? 'es' : ''}`,
  }))
}

/**
 * getMonthsOptions
 * @param {Number|Number[]} months
 */

export const getPercentageCommissionOptions = options =>
  options.map(p => ({
    label: `${p}%`,
    value: `${p / 100}`,
  }))

/**
 * getPercentageCommissionOptions
 * @param {Number[]} options
 */

export const getDaysOptions = options =>
  options.map(day => ({
    label: `${day} día${day !== 1 && 's'}`,
    value: `${day}`,
  }))

/**
 * getDaysOptions
 * @param {Number[]} options
 */

export function* makeRangeIterator(start = 1, end = 100, step = 1) {
  for (let i = start; i <= end; i += step) {
    yield i
  }
}


/**
 * Neximers can create collaboration contracts only if the property is not a development and is this option is not blocked from the user contract configuration.
 */
export const useGetContractTypes = propertyDevelopmentType => {
  const { isAllowed } = useSelector(state =>
    contractVersionPermissionSelector(state, CONTRACT_VERSION_PERMISSIONS.MONTHS_TO_CREATE_COLAB_CONTRACT)
  )
  const hasBlockCollaboration = useSelector(state =>
    hasContractFeatureSelector(state, 'BLOCK_COLLABORATION'),
  )
  const blockCollaborationAfterNMonths = useSelector(state =>
    hasContractFeatureSelector(state, 'BLOCK_COLLABORATION_AFTER_N_MONTHS'),
  )

  if (hasBlockCollaboration) {
    return REQUEST_CONTRACTS_TYPES.map(contract => {
      const shouldBeDisabled =
        contract.value === Contracts.COLLABORATION &&
        (propertyDevelopmentType !== 'NONE' || hasBlockCollaboration)

      return { ...contract, disabled: shouldBeDisabled }
    })
  }

  if (blockCollaborationAfterNMonths) {

    return REQUEST_CONTRACTS_TYPES.map(contract =>
      contract.value === Contracts.COLLABORATION && 
      (propertyDevelopmentType !== 'NONE' || !isAllowed)
        ? { ...contract, disabled: true }
        : contract,
    )
  }

  return REQUEST_CONTRACTS_TYPES.map(contract =>
    contract.value === Contracts.COLLABORATION &&
    propertyDevelopmentType !== 'NONE'
      ? { ...contract, disabled: true }
      : contract,
  )
}

/**
 * @description Returns whether a lead is prequalifed or not based on the preferredLocations property, an Applicant object is expected
 * this object is retrieved from the following API call -> /v1/crm/applicants/?status=ACCEPTED
 * @param {Object} lead
 */
export const isPrequalified = (lead) => {
  return lead.advertisingSource && lead.brokerType
}

export { downloadFileLink, splitDotIndex, linkToFile }
