import crudAction from './actions'

export default {
  name: 'propertyDocumentation',
  namespace: 'property_documentation',
  methods: [
    ...crudAction,
    {
      name: 'getDocuments',
      params: [],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => "/v1/document_type/list_documents/",
      urlParams: []
    },
    {
      name: 'getPropertyToDocumented',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/properties/${id}/get_documentation/`,
      urlParams: []
    },
  ]
}