import priceSelector from 'app/selectors/chunks/price'
import { OPERATION_TYPES_BIBLIO } from 'app/services/seo'
import { safeDecimal } from 'app/services/number'

export const getInitialValuesForRequestLegalSupport = binLeadData => {
  if (!binLeadData || !binLeadData.property) {
    return {}
  }
  const {
    operationType,
    id,
    price = {},
    referalLeadId,
    referalLeadSource,
    referalLeadCustomPercentage,
  } = binLeadData.property
  const brokerOwner = binLeadData.broker?.id

  const data = {
    operationType,
    propertyId: id,
    isBrokerOwner: Number(binLeadData.currentUserId) === Number(brokerOwner),
    referalLeadId: referalLeadId,
    referalLeadSource: referalLeadSource && { value: referalLeadSource },
    referalLeadCustomPercentage: Number(referalLeadCustomPercentage) || 0,
    price: price.amount,
    currency: price.currency,
    contactTransaction: binLeadData.contactTransaction,
    operationTypeLabel: OPERATION_TYPES_BIBLIO[operationType] || operationType,
    saleCommissionPercentage: `${safeDecimal(binLeadData.saleCommissionPercentage * 100)}%`
  }

  return data
}

export const mapDealData = data => {
  if (!data) {
    return {}
  }

  const { ad, property, price, externalAgent, ...rest } = data

  const newProperty = ad || property

  if (typeof price === 'object') {
    newProperty.price = price
  }

  const dealData = {
    ...rest,
    property: newProperty
  }

  if (newProperty && newProperty.saleCommissionPercentage) {
    const sCP = property.saleCommissionPercentage
    dealData.saleCommissionPercentage = sCP < 1 ? sCP : safeDecimal(sCP / 100)
  }

  if (newProperty && newProperty.saleCommissionDays) {
    const commissionDays = property.saleCommissionDays
    dealData.saleCommissionPercentage = (commissionDays / 30).toFixed(2)
  }

  return dealData
}

class ValidateExtraTransaction {
  constructor(
    extraTransaction,
    contactTransaction,
    requestedDocument,
    transactionPrice
  ) {
    this.price = transactionPrice // { amount: Number, currency: String }
    this.extra = extraTransaction || {}
    this.contact = contactTransaction || {}
    this.requestedDocument = requestedDocument

    this.extraTransaction = {
      cashOfferPayment: '',
      financedOfferPayment: '',
      offerPaymentDeposit: '',
      bidPaymentDate: '',
      signatureOfferDate: '',
      rentDepositAmount: '',
      leaseTerm: '',
      deliveryPropertyOfRent: '',
      commissionRatePrivateContract: '',
      commissionRateDeedSigning: ''
    }

    this.contactTransaction = {}
  }

  isOffer = () => this.requestedDocument === 'OFFER'
  isSell = () => this.requestedDocument === 'SELL'
  isRent = () => this.requestedDocument === 'RENT'
  isCommission = () => this.requestedDocument === 'COMMISSION'

  shoulAddPaymentMethod = () =>
    this.extra.paymentMethod && (this.isOffer() || this.isSell())

  shouldBeAddedContact = () => this.isOffer() || this.isSell() || this.isRent()

  validateContact = () => {
    this.contactTransaction = {
      id: this.contact.id,
      fullName: this.contact.fullName,
      telephone: this.contact.telephone,
      mobilePhone: this.contact.mobilePhone,
      email: this.contact.email,
      referalLeadSource: this.contact.referalLeadSource,
      referalLeadId: this.contact.referalLeadId,
      referalLeadCustomPercentage: this.contact.referalLeadCustomPercentage,
    }
  }

  validadteOffer = () => {
    const {
      bidPaymentDate,
      signatureOfferDate,
      offerPaymentDeposit
    } = this.extra

    this.extraTransaction.bidPaymentDate = bidPaymentDate
    this.extraTransaction.signatureOfferDate = signatureOfferDate
    this.extraTransaction.offerPaymentDeposit =
      offerPaymentDeposit && offerPaymentDeposit.value
  }

  validadteSell = () => {
    // sell validation should be here
  }

  validateRent = () => {
    const { leaseTerm, deliveryPropertyOfRent, rentDepositAmount } = this.extra

    this.extraTransaction.leaseTerm = leaseTerm && leaseTerm.value
    this.extraTransaction.deliveryPropertyOfRent = deliveryPropertyOfRent
    this.extraTransaction.rentDepositAmount = priceSelector(rentDepositAmount)
  }

  validateCommission = () => {
    const {
      commissionRatePrivateContract,
      commissionRateDeedSigning
    } = this.extra

    this.extraTransaction.commissionRateDeedSigning =
      commissionRateDeedSigning && parseFloat(commissionRateDeedSigning)
    this.extraTransaction.commissionRatePrivateContract =
      commissionRatePrivateContract && parseFloat(commissionRatePrivateContract)
  }

  validatePaymentMethod = () => {
    const { cashOfferPayment, financedOfferPayment, paymentMethod } = this.extra

    if (paymentMethod.value === 'DOWN_PAYMENT_OFFER') {
      this.extraTransaction.cashOfferPayment = this.price
    } else if (paymentMethod.value === 'ADVANCE_PAYMENT_OFFER') {
      this.extraTransaction.financedOfferPayment = this.price
    } else if (paymentMethod.value === 'OTHER') {
      this.extraTransaction.cashOfferPayment = priceSelector(cashOfferPayment)
      this.extraTransaction.financedOfferPayment = priceSelector(financedOfferPayment)
    }
  }

  formatValues = values =>
    Object.keys(values)
      .filter(key => values[key])
      .map(key => ({
        [key]: values[key]
      }))
      .reduce((acc, value) => ({ ...acc, ...value }), {})

  getValidatedValues() {
    this.isOffer() && this.validadteOffer()
    this.isSell() && this.validadteSell()
    this.isRent() && this.validateRent()
    this.isCommission() && this.validateCommission()
    this.shoulAddPaymentMethod() && this.validatePaymentMethod()
    this.shouldBeAddedContact() && this.validateContact()

    return {
      contactTransaction: this.formatValues(this.contactTransaction),
      extraTransaction: this.formatValues(this.extraTransaction)
    }
  }
}

export const mapDataToRequestLegalSupport = ({
  legalSupportValues,
  closeDealData
}) => {
  if (!legalSupportValues) {
    return {}
  }

  const { contact, price, property, binLead, externalAgent } = closeDealData
  const {
    isBrokerOwner,
    propertyId,
    referalLeadId,
    referalLeadSource,
    referalLeadCustomPercentage,
    requestedDocument,
    offer: offerData,
    conditions,
    comments,
    signDate,
    neximoBonus,
    commissionReceipt,
    policy,
    basicProtection,
    rentAmount,
    rentCurrency,
    contactTransaction,
    extraTransaction: extra,
    saleCommissionPercentage: commission
  } = legalSupportValues

  const legalSupportData = {
    contact,
    price,
    binLead,
    document_request: requestedDocument,
    property: property || propertyId,
  }
  
  if (isBrokerOwner) {
    legalSupportData.referalLeadId = referalLeadId || null
    legalSupportData.referalLeadSource = referalLeadSource?.value || null
    legalSupportData.referalLeadCustomPercentage = referalLeadCustomPercentage || 0
  }

  if (externalAgent) {
    legalSupportData.externalAgent = externalAgent
  }

  if (requestedDocument === 'OFFER') {
    const offer = {
      amount: (offerData && offerData.amount) || 0,
      currency: offerData && offerData.currency && offerData.currency.value
    }

    legalSupportData.document_request_object = {
      offer,
      conditions
    }
  }

  if (requestedDocument === 'SELL') {
    legalSupportData.document_request_object = {
      comments,
      neximo_bonus: neximoBonus,
      sign_date: signDate
    }
  }

  if (requestedDocument === 'RENT') {
    legalSupportData.document_request_object = {
      comments,
      sign_date: signDate,
      policy: Boolean(policy),
      basic_protection: Boolean(basicProtection),
    }

    if (rentAmount) {
      legalSupportData.document_request_object.amount = {
        amount: rentAmount || 0,
        currency: rentCurrency && rentCurrency.value
      }
    }
  }

  if (requestedDocument === 'COMMISSION' && commissionReceipt) {
    legalSupportData.commission_receipt = commissionReceipt.url
  }

  const extraTransaction = new ValidateExtraTransaction(
    extra,
    contactTransaction,
    requestedDocument,
    price
  )

  return {
    ...legalSupportData,
    ...extraTransaction.getValidatedValues()
  }
}

export const mapDataToPropertyClose = data => {
  if (!data) {
    return {}
  }

  const {
    requestedDocument,
    conditions,
    comments,
    signDate,
    neximoBonus,
    commissionReceipt,
    policy,
    basicProtection,
    rentAmount,
    rentCurrency,
    price,
    reason,
    contact,
    externalAgent,
    offer:offerData,
    neximer,
    neximerData,
    saleHouseDeedUrls,
    rentContractUrls,
    saleHouseDeedDocTypeId,
    contactTransaction,
    extraTransaction: extra,
    saleCommissionPercentage: commission
  } = data

  const legalSupportData = {
    price,
    reason,
    document_request: requestedDocument,
  }
  if(contact){
    legalSupportData.contact = {
      ...contact,
      referalLeadSource:
        contact?.referalLeadSource &&
        contact.referalLeadSource.value !== 'noneReferalLead'
          ? contact.referalLeadSource.value
          : undefined,
    }
  }
  if(neximer){
    legalSupportData.neximer = neximer
    legalSupportData.neximerData = neximerData
  }
  if (externalAgent) {
    legalSupportData.externalAgent = externalAgent
  }

  if (requestedDocument === 'OFFER') {
    const offer = {
      amount: (offerData && offerData.amount) || 0,
      currency: offerData && offerData.currency && offerData.currency.value
    }
    legalSupportData.document_request_object = {
      offer,
      conditions
    }
  }

  if (requestedDocument === 'SELL') {
    legalSupportData.document_request_object = {
      comments,
      neximo_bonus: neximoBonus,
      sign_date: signDate
    }
  }

  if (requestedDocument === 'RENT') {
    legalSupportData.document_request_object = {
      comments,
      sign_date: signDate,
      policy: Boolean(policy),
      basic_protection: Boolean(basicProtection),
    }
    if (rentAmount) {
      legalSupportData.document_request_object.amount = {
        amount: rentAmount || 0,
        currency: rentCurrency && rentCurrency.value
      }
    }

    if(rentContractUrls && rentContractUrls.length > 0) {
      legalSupportData.rentContract = rentContractUrls.filter(Boolean).map(url => ({ url }))
    }
  }

  if (requestedDocument === 'COMMISSION') {
    if(commissionReceipt && commissionReceipt.length > 0) {
      legalSupportData.commission_receipt = commissionReceipt.filter(Boolean).map(url => ({ url }))
    }

    if(saleHouseDeedUrls && saleHouseDeedUrls.length > 0) {
      legalSupportData.saleHouseDeed = {
        url: saleHouseDeedUrls.filter(Boolean).map(url => ({ url })),
        documentType: saleHouseDeedDocTypeId,
      }
    }
  }

  const extraTransaction = new ValidateExtraTransaction(
    extra,
    contactTransaction,
    requestedDocument,
    price
  )

  return {
    ...legalSupportData,
    ...extraTransaction.getValidatedValues()
  }
}

export function preparePropertyCloseToActors(currentUser, dealValues) {
  const otherNeximer = dealValues.neximerData
  const externalAgent = dealValues.externalAgent

  const brokers = []
  const externalAgents = []

  brokers.push({
    id: currentUser.userId,
    fullName: currentUser.fullName,
    email: currentUser.email,
    telephone: currentUser.telephone,
  })

  if (otherNeximer) {
    brokers.push({
      id: otherNeximer.id,
      fullName: otherNeximer.fullName,
      email: otherNeximer.email,
      telephone: otherNeximer.telephone,
    })
  }

  if (externalAgent) {
    externalAgents.push({
      email: externalAgent.email,
      fullName: externalAgent.fullName,
      telephone: externalAgent.telephone,
    })
  }

  let contact = null

  // Contact can be in different places
  // This deals with that mess
  if (dealValues.contact?.id || dealValues.contact?.fullName || dealValues.contactTransaction?.fullName) {
    const _contact = dealValues?.contact || {}
    const _contactTransaction = dealValues.contactTransaction || {}
    const referalData = _contact.referalData || {}

    const fields = [
      'id',
      'fullName',
      'email',
      'referalLeadSource',
      'referalLeadId',
      'referalLeadCustomPercentage',
    ]
    contact = fields.reduce((acc, field) => {
      acc[field] = _contact[field] || _contactTransaction[field] || referalData[field] || null
      return acc
    }, {})
  }

  return {
    brokers,
    contact,
    externalAgents,
    propertyCloseValues: dealValues,
  }
}

export function prepareContactPropertyCloseToActors(currentUser, dealValues) {
  const { contactTransaction, externalAgent, property } = dealValues
  const brokers = []
  const externalAgents = []
  let contact = null

  const isNewProperty = !property.id
  const hasPropertyBroker = Boolean(property.broker)

  const isThereOtherBrokerInvolved =
    property.broker && Number(property.broker.id) !== Number(currentUser.userId)

  brokers.push({
    id: currentUser.userId,
    fullName: currentUser.fullName,
    email: currentUser.email,
  })

  // Load here the other broker
  if (!isNewProperty && isThereOtherBrokerInvolved && hasPropertyBroker) {
    const broker = {
      id: property.broker.id,
      fullName: property.broker.fullName,
      email: property.broker.email,
    }
    if (property.broker.sharedCommissionPercentage) {
      broker.commissionPercentage = Number(
        property.broker.sharedCommissionPercentage,
      )
    }
    brokers.push(broker)
  }

  if (externalAgent && externalAgent.email) {
    const externalAgentActor = {
      email: externalAgent.email,
      fullName: externalAgent.fullName,
      telephone: externalAgent.telephone,
    }

    if (externalAgent.sharedCommissionPercentage) {
      externalAgentActor.commissionPercentage = Number(
        externalAgent.sharedCommissionPercentage,
      )
    }
    externalAgents.push(externalAgentActor)
  }

  if (contactTransaction?.id) {
    contact = {
      id: contactTransaction.id,
      fullName: contactTransaction.fullName,
      email: contactTransaction.email,
      referalLeadSource: contactTransaction.referalLeadSource,
      referalLeadId: contactTransaction.referalLeadId,
      referalLeadCustomPercentage:
        contactTransaction.referalLeadCustomPercentage,
    }
  }

  return {
    brokers,
    contact,
    externalAgents,
    propertyCloseValues: dealValues,
  }
}
