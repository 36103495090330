import { createReducer, createActions } from 'reduxsauce'

const REQUEST_INITIAL_STATE = {
  loading: false,
  progress: 0,
  finished: false,
  error: null,
  request: null,
  response: null
}

const INITIAL_STATE = { }

const request = (state, { fileName, file, s3PresignedUrl, hasExpiration, hashPath, extraData }) => ({
  ...state,
  [fileName]: {
    ...REQUEST_INITIAL_STATE,
    loading: true,
    request: {
      fileName,
      file,
      s3PresignedUrl,
      hasExpiration,
      hashPath,
      extraData
    }
  }})

const progress = (state, { fileName, progress }) => ({
  ...state,
  [fileName]: {
    ...state[fileName],
    progress
  }})

const success = (state, { fileName, response, url }) => ({
  ...state,
  [fileName]: {
    ...state[fileName],
    loading: false,
    finished: true,
    progress: 100,
    url,
    response
  }})

const failure = (state, { fileName, error }) => ({
  ...state,
  [fileName]: {
    ...state[fileName],
    loading: false,
    finished: true,
    error,
  }})

const cleanState = () => INITIAL_STATE

const { Types, Creators } = createActions({
  request: ['fileName', 'file', 's3PresignedUrl', 'hasExpiration', 'hashPath', 'extraData'],
  success: ['fileName', 'response', 'url'],
  progress: ['fileName', 'progress'],
  cleanState: [],
  failure: ['fileName', 'error']
}, { prefix: 'FILE_UPLOAD_'})


const HANDLERS = {
  [Types.CLEAN_STATE]: cleanState,
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.PROGRESS]: progress,
  [Types.FAILURE]: failure
}

export const FileUploadActionTypes = Types

export const FileUploadActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)