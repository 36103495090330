import { call, put, select, delay } from 'redux-saga/effects'
import api from 'app/services/api'
import { PropertyComplaintsCreators } from 'app/reducers/ReportPropertiesReducer'

export function* sendData({ formData, propertyId, isPublicReport }) {
  const authToken = yield select(state => state.auth.token)
  let response
  if (isPublicReport) {
    response = yield call(
      api.propertyComplaints.createReportPublic,
      propertyId,
      formData
    )
  } else {
    response = yield call(
      api.propertyComplaints.createReportBroker,
      authToken,
      propertyId,
      formData
    )
  }

  if (response.status === 201) {
    yield put(PropertyComplaintsCreators.sendReportSuccess(response.data))
    yield put(PropertyComplaintsCreators.reportProperty(false))
    yield delay(3000)
    yield put(PropertyComplaintsCreators.clearPostPropertyReport())
    return
  }

  yield put(PropertyComplaintsCreators.sendReportFailure(response.data))
}
