import { put } from 'redux-saga/effects'
import { virtualTour } from 'services/api/entities'
import { ValidateImageActionCreators } from 'app/reducers/VirtualTourRedux';

export function* validateVirtualTourImage({ data }) {
  const response = yield virtualTour.validateImage(data)
  if (response.success()) {
    return yield put(ValidateImageActionCreators.validateImageSuccess())
  }
  yield put(ValidateImageActionCreators.validateImageFailure(response.data))
}
