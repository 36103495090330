import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {
  api: null
}

const { Types, Creators } = createActions({
  apiError: ['error']
}, { prefix: 'ERRORS_' })

const apiError = (state, { error }) => ({ ...state, api: error })

const HANDLERS = {
  [Types.API_ERROR]: apiError
}

export const ErrorActionTypes = Types
export const ErrorActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
