import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { image } from 'services'

import styles from './styles'

const ProgressRing = props => {
  const [offset, setOffset] = useState(0)
  const circleRef = useRef(null)
  const {
    size,
    progress,
    strokeWidth,
    circleOneStroke,
    fillBgCircle,
    circleTwoStroke,
    imageUrl,
    text,
    styleImage = {},
    styleText = {}
  } = props

  const center = size / 2
  const radius = size / 2 - strokeWidth / 2
  const circumference = 2 * Math.PI * radius

  useEffect(
    () => {
      const progressOffset = ((100 - progress) / 100) * circumference
      setOffset(progressOffset)
      circleRef.current.style =
        'transition: stroke-dashoffset 850ms ease-in-out;'
    },
    [setOffset, circumference, progress, offset]
  )

  return (
    <div className="ring-container">
      <svg className="svg" width={size} height={size}>
        <circle
          className="svg-circle-bg"
          stroke={circleOneStroke}
          cx={center}
          cy={center}
          r={radius - strokeWidth}
          strokeWidth={strokeWidth}
          fill={fillBgCircle || 'none'}
        />
        <circle
          className="svg-circle"
          ref={circleRef}
          stroke={circleTwoStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
        {imageUrl && (
          <image
            className="svg-image"
            href={image(imageUrl)}
            height={`${center}`}
            width={`${center}`}
            x={center / 2}
            y={center / 2}
            style={styleImage}
          />
        )}
        {text && (
          <text className="svg-circle-text" x="50%" y="50%" style={styleText}>
            {text}
          </text>
        )}
      </svg>
      <style jsx>{styles}</style>
    </div>
  )
}

ProgressRing.propTypes = {
  size: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  circleOneStroke: PropTypes.string.isRequired,
  circleTwoStroke: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  styleImage: PropTypes.object,
  text: PropTypes.string,
  styleText: PropTypes.object,
}

export default ProgressRing
