import crudAction from './actions'

export default {
  name: 'area',
  namespace: 'areas',
  methods: [
    ...crudAction,
    {
      name: 'userAreas',
      params: [],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => '/v1/user_areas/',
      urlParams: []
    },
  ]
}
