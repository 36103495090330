import { useEffect } from 'react'

function useFeedbackAfterSuccess(
  { loading, response, success, error },
  handleShowSuccessFeedback,
  timeout = 2500
) {
  const _success =
    typeof success === 'boolean'
      ? success
      : !loading && !error && response !== null

  useEffect(
    () => {
      let timeoutId = null

      if (!loading && _success && !error) {
        handleShowSuccessFeedback(true)

        timeoutId = setTimeout(() => handleShowSuccessFeedback(false), timeout)
      }

      return () => {
        clearInterval(timeoutId)
      }
    },
    [loading, _success, error]
  )
}

export default useFeedbackAfterSuccess
