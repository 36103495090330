import crudAction from './actions'

export default {
  name: 'customEventParticipant',
  namespace: 'custom_event_participants',
  methods: [
    {
      name: 'publicPatch',
      params: ['id', 'data'],
      successCode: 200,
      method: 'Patch',
      url: ({ namespace, id }) => `/v1/public/${namespace}/${id}/`,
      urlParams: [ 'id' ]
    }
  ]
}

