import { put } from 'redux-saga/effects'

import { SeoUrls } from 'services/api/entities'
import { SeoUrlsActionCreators } from 'app/reducers/SeoUrlsRedux'

export function* all({ page, params }) {
  const response = yield SeoUrls.all({ page, ...params })

  if (response.success()) {
    yield put(SeoUrlsActionCreators.allSuccess(response.data))
  } else {
    yield put(SeoUrlsActionCreators.allFailure(response.data))
  }
}
