/**
 * Created by neto on 3/21/17.
 */
import api from '../api';

const register = ({ email, password, personalInfo = {} }) => api.privatePost('/user/v1',
  {
    data: {
      email,
      password,
      personalInfo,
      roles: ['LANDLORD'],
      aboutUsId: 0
    }
  }
);

const login = ({ email, password }) => api.privatePost('/api/auth/login',
  {
    data: {
      email,
      password
    }
  }
);

const userDetails = ({ token }) => api.privateGet(`/api/user/v1/me`, { token });

const userDetailsUpdate = ({ token, userData }) => api.privatePut('/api/user/v1', {
  token,
  data: {
    id: userData.id,
    password: userData.password,
    personalInfo: {
      email: userData.personalInfo.email,
      telephone: userData.personalInfo.telephone,
      skype: userData.personalInfo.skype,
      occupation: userData.personalInfo.occupation
    }
  }
});

const refreshToken = rt => api.privatePost('/v1/oauth/refresh_token/', { data: { token: rt } });

const confirmEmail = token => api.privateGet('/v1/public/users/confirm', { params: { token } })

const sendCode = (token, phoneNumber) => api.privatePost('/v1/users/send_verification_code/', { token, data: { phoneNumber } })

const verifyCode = (token, code, phoneNumber) => api.privatePost('/v1/users/confirm_verification_code/', { token, data: { code, phoneNumber } })

const getContactToken = (binLeadId) => api.post(`/v1/crm/public/bin_leads/${binLeadId}/token/`)

export default {
  register,
  login,
  userDetails,
  userDetailsUpdate,
  refreshToken,
  confirmEmail,
  sendCode,
  verifyCode,
  getContactToken,
}
