import api from 'app/services/api'
import { call, put, select } from 'redux-saga/effects'

export function *saveAnswer({requirement, answer}) {
  const response = yield call(
    api.binLeadQualityCheck.saveAnswer,
    requirement,
    answer
  )
}
