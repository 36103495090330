import { call, put, select } from 'redux-saga/effects'
import { DirectAnnonceLeadActionCreators } from 'app/reducers/DirectAnnonceLeadRedux'
import { RejectLeadActionCreators } from 'app/reducers/RejectLeadRedux'
import { PostponeLeadActionCreators } from 'app/reducers/PostponeLeadRedux'
import { AcceptLeadActionCreators } from 'app/reducers/AcceptLeadRedux'
import api from 'app/services/api'

export function* getDirectAnnonceLead({ id }) {
  const token = yield select(state => state.auth.token)
  const response = yield call(
    api.directAnnonceLead.getDirectAnnonceLeadById,
    token,
    id
  )

  if (response.status === 200) {
    return yield put(
      DirectAnnonceLeadActionCreators.getDirectAnnonceLeadSuccess(response.data)
    )
  }

  yield put(
    DirectAnnonceLeadActionCreators.getDirectAnnonceLeadFailure(response.data)
  )
}

export function* rejectDirectAnnonceLead({ lead, data }) {
  const token = yield select(state => state.auth.token)
  const leadId = lead.id
  data['rejectionReason'] = data.rejectionReason.value

  const response = yield call(
    api.directAnnonceLead.rejectDirectAnnonceLead,
    token,
    leadId,
    data
  )

  if (response.status === 200) {
    return yield put(
      RejectLeadActionCreators.rejectLeadSuccess(response.data)
    )
  }
}

export function* postponeDirectAnnonceLead({ lead, data }) {
  const token = yield select(state => state.auth.token)
  data['postponeReason'] = data.postponeReason.value
  data['postponedUntil'] = new Date(data.postponedUntil).toISOString()
  const leadId = lead.id

  const response = yield call(
    api.directAnnonceLead.postponeDirectAnnonceLead,
    token,
    leadId,
    data
  )

  if (response.status === 200) {
    return yield put(
      PostponeLeadActionCreators.postponeLeadSuccess(response.data)
    )
  }
}

export function *acceptDirectAnnonceLead({ lead }) {
  const token = yield select(state => state.auth.token)
  const response = yield call(
    api.directAnnonceLead.acceptDirectAnnonceLead,
    token,
    lead.id
  )

  if (response.status === 200) {
    return yield put(
      AcceptLeadActionCreators.acceptLeadSuccess(response.data)
    )
  }
}
export function *acceptDirectAnnonceLeadNew({ daLeadAssignationId }) {
  const token = yield select(state => state.auth.token)
  const response = yield call(
    api.directAnnonceLead.acceptDirectAnnonceLead,
    token,
    daLeadAssignationId
  )
  
  if (response.status === 200) {
    return yield put(
      DirectAnnonceLeadActionCreators.acceptDirectAnnonceLeadSuccess()
    )
  }
 
  return yield put(
    DirectAnnonceLeadActionCreators.acceptDirectAnnonceLeadFailure(response.data)
  )
}

export function* rejectDirectAnnonceLeadNew({ daLeadAssignationId, data }) {
  const token = yield select(state => state.auth.token)
  data['rejectionReason'] = data.rejectionReason.value

  const response = yield call(
    api.directAnnonceLead.rejectDirectAnnonceLead,
    token,
    daLeadAssignationId,
    data
  )

  if (response.status === 200) {
    return yield put(
      DirectAnnonceLeadActionCreators.rejectDirectAnnonceLeadSuccess()
    )
  }

  return yield put(
    DirectAnnonceLeadActionCreators.rejectDirectAnnonceLeadFailure(response.data)
  )
}
