import { createReducer, createActions } from 'reduxsauce'

const REQUEST_INITIAL_STATE = {
  loading: false,
  finished: false,
  error: null,
  request: null,
  response: null,
  location: null,
}

const INITIAL_STATE = {
  suggestionOptions: [],
  search: '',
  error: null,
  loadingSuggest: false,
  labelSearch:''
}

const { Types, Creators } = createActions({
  getLocationRequest: ['search'],
  getLocationSuccess: ['data'],
  getLocationFailure: ['error'],
  setLocation: ['location']
}, { prefix: 'HERE_' })

const getLocationRequest = (state, { search }) => ({
  ...state,
  search,
  loadingSuggest: true,
}
)

const getLocationSuccess = (state, { data }) => ({
  ...state,
  loadingSuggest: false,
  suggestionOptions: data,
}
)

const getLocationFailure = (state, { error }) => ({
  ...state,
  error: error,
  loadingSuggest: false,
}
)

const setLocation = (state, { location }) => {
  return {
    ...state,
    labelSearch: location.label
  }
}

const HANDLERS = {
  [Types.GET_LOCATION_REQUEST]: getLocationRequest,
  [Types.GET_LOCATION_SUCCESS]: getLocationSuccess,
  [Types.GET_LOCATION_FAILURE]: getLocationFailure,
  [Types.SET_LOCATION]: setLocation,
}
export const HereLocationTypes = Types
export const HereActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
