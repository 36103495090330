/**
  Example
  const { lastRequestedUpdate } = yield select(
    state => state.networkStats.networkHeadStats
  )
  if (!shouldMakeANewRequest(avoidCache, lastRequestedUpdate, cacheTime)) {
    return
  }
  const response = yield NetworkStats.getAllStats()
  if (response.success()) {
    const networkHeadStats = setLastRequestedUpdate(response.data)
 */

const setLastRequestedUpdate = data => ({
  ...data,
  lastRequestedUpdate: new Date().getTime()
})

/**
 * isValidCache
 * @param {Number} lastRequestedUpdate  - number of milliseconds since the Unix Epoch
 * @param {Number} maximumTimeForValidCache - milliseconds
 */
const isValidCache = (lastRequestedUpdate, maximumTimeForValidCache) => {
  if (!lastRequestedUpdate || !maximumTimeForValidCache) {
    return false
  }

  const timeSinceLastRequest = new Date().getTime() - lastRequestedUpdate
  return timeSinceLastRequest < maximumTimeForValidCache
}

/**
 * shouldMakeANewRequest
 * @param {Boolean} avoidCache - To force a new request 
 * @param {Number} lastRequestedUpdate  - number of milliseconds since the Unix Epoch
 * @param {Number} maximumTimeForValidCache - milliseconds
 */
const shouldMakeANewRequest = (
  avoidCache,
  lastRequestedUpdate,
  maximumTimeForValidCache
) => {
  return (
    avoidCache || !isValidCache(lastRequestedUpdate, maximumTimeForValidCache)
  )
}

export { isValidCache, shouldMakeANewRequest, setLastRequestedUpdate }
