import { createReducer, createActions } from 'reduxsauce'

export const INITIAL_STATE = {
  conversationId: null,
  firstThreadId: null,
  loadingConversation: false,
  errorConversation: null,
  loading: false,
  files: {}
}

const { Types, Creators } = createActions({
  createConversationRequest: ['data'],
  createConversationSuccess: ['conversationId', 'firstThreadId'],
  createConversationFailure: ['errorConversation'],

  queueAttachment: ['fileName', 'mimeType', 'data', 'file'],
  createAttachmentRequest: ['fileName', 'mimeType', 'data', 'file'],
  createAttachmentSuccess: ['fileName', 'hash'],
  createAttachmentFailure: ['fileName', 'error'],

  uploadAttachmentsFinished: [],

  removeAttachment: ['fileName'],

  reset: []
}, { prefix: 'HELPDESK_' })

const createConversationRequest = state => ({
  ...state,
  conversationId: null,
  firstThreadId: null,
  loading: true,
  loadingConversation: true,
  errorConversation: null
})
const createConversationSuccess = (state, { conversationId, firstThreadId }) => ({
  ...state,
  conversationId,
  firstThreadId,
  loadingConversation: false,
  errorConversation: null
})
const uploadAttachmentsFinished = state => ({
  ...state,
  loading: false
})
const createConversationFailure = (state, { errorConversation }) => ({
  ...state,
  conversationId: null,
  firstThreadId: null,
  loading: false,
  loadingConversation: false,
  errorConversation
})

const createAttachmentRequest = (state, { fileName, mimeType, data, file }) => {
  return {
    ...state,
    files: {
      ...state.files,
      [fileName]: {
        loading: false,
        error: null,
        hash: null,
        file,
        fileName,
        mimeType,
        data
      }
    }
  }
}

const createAttachmentSuccess = (state, { fileName, hash }) => {
  // maybe the attachment was removed prior to its upload completion
  if (!state.files[fileName]) {
    return state
  }

  const files = {
    ...state.files,
    [fileName]: {
      ...state.files[fileName],
      loading: false,
      error: null,
      hash
    }
  }

  return {
    ...state,
    files
  }
}

const createAttachmentFailure = (state, { fileName, error }) => {
  const files = {
    ...state.files,
    [fileName]: {
      ...state.files[fileName],
      loading: false,
      error
    }
  }

  const loading = Object.keys(files).map(fileKey => files[fileKey].loading).reduce((mem, fileLoading) => mem || fileLoading, false)

  return {
    ...state,
    loading,
    files
  }
}

const removeAttachment = (state, { fileName }) => {
  const files = { ...state.files }

  delete files[fileName]

  return {
    ...state,
    files
  }
}

const reset = state => INITIAL_STATE

const HANDLERS = {
  [Types.CREATE_ATTACHMENT_REQUEST]: createAttachmentRequest,
  [Types.CREATE_ATTACHMENT_SUCCESS]: createAttachmentSuccess,
  [Types.UPLOAD_ATTACHMENTS_FINISHED]: uploadAttachmentsFinished,
  [Types.CREATE_ATTACHMENT_FAILURE]: createAttachmentFailure,

  [Types.CREATE_CONVERSATION_REQUEST]: createConversationRequest,
  [Types.CREATE_CONVERSATION_SUCCESS]: createConversationSuccess,
  [Types.CREATE_CONVERSATION_FAILURE]: createConversationFailure,

  [Types.REMOVE_ATTACHMENT]: removeAttachment,

  [Types.RESET]: reset
}

export const HelpDeskActionTypes = Types

export const HelpDeskActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)

