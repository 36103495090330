import css from 'styled-jsx/css'
import colors, { buttons } from 'theme/colors'
import measurements from 'theme/measurements'
import fonts from 'theme/fonts'
import { fontSizes } from 'theme'

const DURATION = '100ms'

export default css`
  .button {
    height: 2.1875rem;
    border-radius: ${measurements.borderRadius};
    cursor: pointer;
    display: inline-flex;
    outline: none;
    border: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;
    font: ${fonts.regular};
  }

  .button :globa(> i) {
    margin-right: 0.5rem;
  }

  .button.bold {
    font: ${fonts.semiBold};
  }

  .button.fullWidth {
    width: 100%;
  }

  .button.outline {
    background-color: transparent;
    border: 1px solid;
    transition: 100ms all;
  }

  .button.large {
    height: 3.3rem;
    border-radius: ${measurements.borderRadius};
    font-size: 1.125rem;
  }

  .button.small {
    height: 2.375rem;
    padding: 0 0.875rem;
    font-size: 0.875rem;
  }

  .button.tiny {
    height: 1.875rem;
    font-size: 0.875rem;
  }

  .button.disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }

  .button.none {
    border: 0;
    background-color: ${colors.transparent};
    color: inherit;
  }

  .button.rounded {
    border-radius: 3.125rem;
  }

  .button.circle.tiny {
    height: 1.875rem;
    width: 1.875rem;
  }
  .button.circle.small {
    height: 2.375rem;;
    width: 2.375rem;;
  }
  .button.circle {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 2.1875rem;
    width: 2.1875rem;
    line-height: 0;
  }
  .button.circle.large {
    height: 3.5rem;
    width: 3.5rem;
  }

  .button.circle > :global(i) {
    margin-right: 0;
  }

  .button:focus {
    box-shadow: 0 0 0px 3px ${colors.alpha(colors.secondary, 0.4)};
  }
`

export const toggleButtonStyles = css.global`
  .grow-from-left-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8),
      -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  }
  .grow-from-left-enter.grow-from-left-enter-active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .grow-from-left-leave {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  }
  .grow-from-left-leave.grow-from-left-leave-active {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  .grow-from-right-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8),
      -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  }
  .grow-from-right-enter.grow-from-right-enter-active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .grow-from-right-leave {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  }
  .grow-from-right-leave.grow-from-right-leave-active {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  .grow-from-center-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8),
      -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  }
  .grow-from-center-enter.grow-from-center-enter-active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .grow-from-center-leave {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  }
  .grow-from-center-leave.grow-from-center-leave-active {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  .grow-from-up-left-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8),
      -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  }
  .grow-from-up-left-enter.grow-from-up-left-enter-active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .grow-from-up-left-leave {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  }
  .grow-from-up-left-leave.grow-from-up-left-leave-active {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  .grow-from-up-center-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8),
      -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  }
  .grow-from-up-center-enter.grow-from-up-center-enter-active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .grow-from-up-center-leave {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  }
  .grow-from-up-center-leave.grow-from-up-center-leave-active {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  .grow-from-up-right-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8),
      -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  }
  .grow-from-up-right-enter.grow-from-up-right-enter-active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .grow-from-up-right-leave {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  }
  .grow-from-up-right-leave.grow-from-up-right-leave-active {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  .dd-menu {
    display: inline-block;
    position: relative;
  }
  .dd-menu.dd-menu-center .dd-menu-items {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .dd-menu.dd-menu-left .dd-menu-items {
    left: 0;
  }
  .dd-menu.dd-menu-left
    .dd-menu-items
    [class^='nested-']:not(.nested-reverse)
    > span {
    right: 100%;
    padding-right: 0.5em;
  }
  .dd-menu.dd-menu-left .dd-menu-items .nested-reverse > span {
    left: 100%;
    padding-left: 0.5em;
  }
  .dd-menu.dd-menu-left.dd-menu-inverse
    .dd-menu-items
    [class^='nested-']:not(.nested-reverse)
    > span {
    right: 100%;
    padding-right: 0.3em;
  }
  .dd-menu.dd-menu-left.dd-menu-inverse .dd-menu-items .nested-reverse > span {
    left: 100%;
    padding-left: 0.3em;
  }
  .dd-menu.dd-menu-right .dd-menu-items {
    right: 0;
  }
  .dd-menu.dd-menu-right
    .dd-menu-items
    [class^='nested-']:not(.nested-reverse)
    > span {
    left: 100%;
    padding-left: 0.5em;
  }
  .dd-menu.dd-menu-right .dd-menu-items .nested-reverse > span {
    right: 100%;
    padding-right: 0.5em;
  }
  .dd-menu.dd-menu-right.dd-menu-inverse
    .dd-menu-items
    [class^='nested-']:not(.nested-reverse)
    > span {
    left: 100%;
    padding-left: 0.3em;
  }
  .dd-menu.dd-menu-right.dd-menu-inverse .dd-menu-items .nested-reverse > span {
    right: 100%;
    padding-right: 0.3em;
  }
  .dd-menu.dd-menu-sm .dd-menu-items {
    width: 150px;
  }
  .dd-menu.dd-menu-md .dd-menu-items {
    width: 300px;
  }
  .dd-menu.dd-menu-lg .dd-menu-items {
    width: 450px;
  }
  .dd-menu.dd-menu-xl .dd-menu-items {
    width: 600px;
  }
  .dd-menu .dd-menu-items {
    position: absolute;
    z-index: 7;
    margin: 0;
  }
  .dd-menu .dd-menu-items.dd-items-upwards {
    bottom: 100%;
    margin: 0 0 0.5em;
  }
  .dd-menu .dd-menu-items ul,
  .dd-menu .dd-menu-items ol {
    list-style: none;
    padding: 0;
    margin: 0;
    color: ${colors.black};
    background-color: ${colors.grayBackground};
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.29);
  }
  .dd-menu .dd-menu-items ul.dd-items-right li > *,
  .dd-menu .dd-menu-items ol.dd-items-right li > * {
    text-align: right;
  }
  .dd-menu .dd-menu-items ul.dd-items-left li > *,
  .dd-menu .dd-menu-items ol.dd-items-left li > * {
    text-align: left;
  }
  .dd-menu .dd-menu-items ul.dd-items-center li > *,
  .dd-menu .dd-menu-items ol.dd-items-center li > * {
    text-align: center;
  }
  .dd-menu .dd-menu-items ul li:hover:not([role='separator']):not(.separator),
  .dd-menu .dd-menu-items ul li > *:focus,
  .dd-menu .dd-menu-items ol li:hover:not([role='separator']):not(.separator),
  .dd-menu .dd-menu-items ol li > *:focus {
    color: ${colors.black};
    background-color: ${colors.boxShadow};
    outline: none;
  }
  .dd-menu .dd-menu-items ul li > *:not(.dd-item-ignore),
  .dd-menu .dd-menu-items ol li > *:not(.dd-item-ignore) {
    display: block;
    width: auto;
    min-width: 100%;
    padding: 1em 1.5em;
    font-family: Roboto, sans-serif;
    white-space: pre;
    color: inherit;
    text-decoration: none;
    font-size: inherit;
  }
  .dd-menu .dd-menu-items ul li > button,
  .dd-menu .dd-menu-items ol li > button {
    border: none;
    background: transparent;
  }
  .dd-menu.dd-menu-inverse .dd-menu-items ul,
  .dd-menu.dd-menu-inverse .dd-menu-items ol {
    color: ${colors.white};
    background-color: ${colors.rent};
  }
  .dd-menu.dd-menu-inverse
    .dd-menu-items
    ul
    li:hover:not([role='separator']):not(.separator),
  .dd-menu.dd-menu-inverse .dd-menu-items ul li > *:focus,
  .dd-menu.dd-menu-inverse
    .dd-menu-items
    ol
    li:hover:not([role='separator']):not(.separator),
  .dd-menu.dd-menu-inverse .dd-menu-items ol li > *:focus {
    color: ${colors.white};
    background-color: ${colors.anchorGray};
  }
  .dd-menu.dd-menu-inverse [role='separator'],
  .dd-menu.dd-menu-inverse .separator {
    background-color: ${colors.steelGray};
  }
  .dd-menu [role='separator'],
  .dd-menu .separator {
    content: '';
    display: block;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.15);
    margin: 0.5em 0 0.5em 0;
  }
  .dd-menu .dd-items-upwards li.nested-dd-menu > span {
    bottom: 0;
    top: initial;
  }
  .dd-menu li.nested-dd-menu {
    position: relative;
  }
  .dd-menu li.nested-dd-menu > span {
    position: absolute;
    top: 0;
  }

  /*
   *Custom styles 
   */

  .dd-menu-items ul {
    border-radius: ${measurements.borderRadius};
  }

  .dropdown-tootle-menu .menu-container {
    border: 1px solid ${colors.border};
    border-radius: 8px;
    min-width: 8rem;
  }

  .dropdown-tootle-menu .menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    justify-content: space-between;
    color: ${colors.frame};
    font: ${fonts.semiBold};
    font-size: ${fontSizes.small};
    cursor: pointer;
  }

  .dropdown-tootle-menu .menu-item:hover {
    background-color: ${colors.lightGray};
  }


  /*# sourceMappingURL=react-dd-menu.min.css.map */
`
