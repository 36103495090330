import { createReducer, createActions } from 'reduxsauce'
import { mapInitialValuesToPropertyDoc } from 'selectors/propertyDocumentation'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback
} from './utils'

export const INITIAL_STATE = {
  loading: false,
  success: false,
  failure: false,
  getPropertyFeedback: {},
  documentFeedback: {},
  originalProperty: {}, 
  propertyDetail: {}
}

const { Types, Creators } = createActions(
  {
    registerNewFile: ['propertySnapshot', 'newDocument', 'registerDocumentType', 'fileName'],
    getPropertyToDocumentation: ['propertyId'],
    getPropertyToDocumentationSuccess: ['property'],
    getPropertyToDocumentationFailure: ['error'],
    updatePropertyDetail: ['property'],
    updateDocuments: ['propertySnapshot', 'updatedDocument', 'registerDocumentType'],
    deleteDocument: ['propertySnapshot', 'document', 'registerDocumentType', 'fileName'],
    saveDocumentationRequest: ['data', 'pk'],
    saveDocumentationSuccess: [],
    saveDocumentationFailure: [],
    updateFileStatus: ['payload'],
    updateFileStatusSuccess: ['payload'],
    updateFileStatusFailure: ['payload'],
    reset: []
  },
  { prefix: 'PROPERTY_DOCS_' }
)

const saveDocumentationRequest = (state, { data }) => {
  return {
    ...state,
    loading: true,
    data
  }
}

const saveDocumentationSuccess = state => {
  return {
    ...state,
    loading: false,
    success: true
  }
}

const saveDocumentationFailure = state => {
  return {
    ...state,
    loading: false,
    failure: true
  }
}

const registerNewFile = state => state

const getPropertyToDocumentation = state => ({
  ...state,
  getPropertyFeedback: requestFeedback()
})

const getPropertyToDocumentationSuccess = (state, { property }) => ({
  ...state,
  originalProperty: property,
  propertyDetail: mapInitialValuesToPropertyDoc(property),
  getPropertyFeedback: successFeedback()
})

const getPropertyToDocumentationFailure = (state, { error }) => ({
  ...state,
  getPropertyFeedback: failureFeedback(error)
})

const updatePropertyDetail = (state, { property }) => ({
  ...state,
  propertyDetail: mapInitialValuesToPropertyDoc(property)
})

const updateDocuments = (state, { propertySnapshot, updatedDocument, registerDocumentType }) => {
  const newProperty = { ...propertySnapshot }

  if (registerDocumentType === 'document') {
    newProperty.documents = newProperty.documents.map(document =>
      document.id === updatedDocument.id ? updatedDocument : document
    )
  } else if (registerDocumentType === 'contract') {
    newProperty.contracts = newProperty.contracts.map(contract =>
      contract.id === updatedDocument.id ? updatedDocument : contract
    )
  }

  return {
    ...state,
    propertyDetail: mapInitialValuesToPropertyDoc(newProperty)
  }
}

const updateFileStatus = (state, { payload }) => ({
  ...state,
  documentFeedback: {
    ...state.documentFeedback,
    [payload.fileName]: requestFeedback()
  }
})

const updateFileStatusSuccess = (state, { payload }) => ({
  ...state,
  documentFeedback: {
    ...state.documentFeedback,
    [payload.fileName]: successFeedback()
  }
})

const updateFileStatusFailure = (state, { payload }) => ({
  ...state,
  documentFeedback: {
    ...state.documentFeedback,
    [payload.fileName]: failureFeedback(payload.error)
  }
})

const reset = state => INITIAL_STATE

const HANDLERS = {
  [Types.REGISTER_NEW_FILE]: registerNewFile,
  [Types.SAVE_DOCUMENTATION_REQUEST]: saveDocumentationRequest,
  [Types.SAVE_DOCUMENTATION_SUCCESS]: saveDocumentationSuccess,
  [Types.SAVE_DOCUMENTATION_FAILURE]: saveDocumentationFailure,
  [Types.UPDATE_PROPERTY_DETAIL]: updatePropertyDetail,
  [Types.UPDATE_DOCUMENTS]: updateDocuments,
  [Types.GET_PROPERTY_TO_DOCUMENTATION]: getPropertyToDocumentation,
  [Types.GET_PROPERTY_TO_DOCUMENTATION_SUCCESS]: getPropertyToDocumentationSuccess,
  [Types.GET_PROPERTY_TO_DOCUMENTATION_FAILURE]: getPropertyToDocumentationFailure,
  [Types.UPDATE_FILE_STATUS]: updateFileStatus,
  [Types.UPDATE_FILE_STATUS_SUCCESS]: updateFileStatusSuccess,
  [Types.UPDATE_FILE_STATUS_FAILURE]: updateFileStatusFailure,
  [Types.RESET]: reset
}

export const PropertyDocumentationActionTypes = Types

export const PropertyDocumentationActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
