import { createReducer, createActions } from 'reduxsauce'
import {
  failureFeedback,
  initialFeedback,
  requestFeedback,
  successFeedback
} from './utils'

const INITIAL_STATE = {
  folders: [],
  feedback: initialFeedback()
}

const { Types, Creators } = createActions(
  {
    all: ['page', 'params'],
    allSuccess: ['folders'],
    allFailure: ['errors'],
    clear: []
  },
  { prefix: 'DOCUMENTATION_FOLDERS_' }
)

const all = (state, { page, params }) => ({
  ...state,
  feedback: requestFeedback()
})

const allSuccess = (state, { folders }) => ({
  ...state,
  folders,
  feedback: successFeedback()
})

const allFailure = (state, { errors }) => ({
  ...state,

  ...state.allFolders,
  feedback: failureFeedback(errors)
})

const clear = () => INITIAL_STATE

export const DocumentationFoldersActionTypes = Types

const HANDLERS = {
  [Types.ALL]: all,
  [Types.ALL_SUCCESS]: allSuccess,
  [Types.ALL_FAILURE]: allFailure,
  [Types.CLEAR]: clear
}

export const DocumentationFoldersActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
