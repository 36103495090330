import { createReducer, createActions } from 'reduxsauce'
import { snakeToCamel } from 'services/string'

const INITIAL_STATE = {
  filters: {
    selectedLocations: []
  },
  currentPath: '',
  typeOfResourse: [],
  lastRequestedUpdate: null,
  footerLinks: [],
  storageFooterLinks: {},
  highlightsProperties:  [],
  vipAsideLinks: []
}

const { Types, Creators } = createActions(
  {
    searchWithLocationString: ['filters', 'headersNexsites'],
    searchWithLocationObject: ['filters', 'headersNexsites'],
    updateFilters: ['filters'],
    getSEOFooterResourse: ['typeOfResourse', 'filters'],
    updateHighlightsProperties: ['highlightsProperties'],
    updateFooterLinksProperties: ['footerLinks', 'lastRequestedUpdate'],

    clear: []
  },
  { prefix: 'SRP_' }
)

const mapFilters = filters => {
  const { location, ...rest } = filters

  let finalData = {}

  Object.keys(rest).forEach(k => {
    finalData[snakeToCamel(k)] = rest[k]
  })

  if (location) {
    finalData.selectedLocations = [location.split('--').join('::')]
  } else {
    finalData.selectedLocations = []
  }

  return finalData
}

const search = (state, { filters }) => ({
  ...state,
  filters: mapFilters(filters),
})

export const updateFilters = (state, { filters }) => ({
  ...state,
  filters: {
    ...state.filters,
    ...filters
  }
})

const getSEOFooterResourse = (state, { typeOfResourse }) => ({
  ...state,
  typeOfResourse
})

const updateHighlightsProperties = (state, { highlightsProperties }) => ({
  ...state,
  highlightsProperties
})

const updateFooterLinksProperties = (state, { footerLinks, lastRequestedUpdate }) => {
  return {
    ...state,
    footerLinks,
    lastRequestedUpdate,
  }
}

const clear = () => INITIAL_STATE

export const SrpActionTypes = Types

const HANDLERS = {
  [Types.SEARCH_WITH_LOCATION_STRING]: search,
  [Types.SEARCH_WITH_LOCATION_OBJECT]: search,
  [Types.UPDATE_FILTERS]: updateFilters,

  [Types.GET_SEO_FOOTER_RESOURSE]: getSEOFooterResourse,
  [Types.UPDATE_HIGHLIGHTS_PROPERTIES]: updateHighlightsProperties,
  [Types.UPDATE_FOOTER_LINKS_PROPERTIES]: updateFooterLinksProperties,

  [Types.CLEAR]: clear
}

export const SrpActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
