const SUCCESS_CODE = /^2\d{2}$/

class Response {
  constructor(rawResponse) {
    this.response = rawResponse
    this.status = rawResponse.status
    this.data = rawResponse.data
  }

  success() {
    return SUCCESS_CODE.test(this.status)
  }
}

export default Response
