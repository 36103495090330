import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {
  property: null,
  loadingProperty: false,
  errorProperty: false,
  errors: null,
  notFoundProperty:false,
  vipFooterLinks: [],
  vipAsideLinks: [],
  suggestedProperties: [],
}

const { Types, Creators } = createActions(
  {
    getVipSeoInfoByPropertyId: ['propertyId'],
    getVipSeoInfoByPropertyIdSuccess: ['property'],
    getVipSeoInfoByPropertyIdFailure: ['errors'],
    getVipSeoInfoByPropertyIdNotFound: [],

    updateFooterLinksProperties: ['vipFooterLinks'],
    updateAsideLinksProperties: ['vipAsideLinks'],
    updateSuggestedProperties: ['suggestedProperties'],

    clear: []
  },
  { prefix: 'VIP_' }
)

const updateFooterLinksProperties = (state, { vipFooterLinks }) => ({
  ...state,
  vipFooterLinks
})

const getVipSeoInfoByPropertyId = state => ({
  ...state,
  loadingProperty: true,
  errorProperty: false,
  errors: null,
})

const getVipSeoInfoByPropertyIdSuccess = (state, { property }) => ({
  ...state,
  property,
  loadingProperty: false,
  errorProperty: false,
  errors: null,
})

const getVipSeoInfoByPropertyIdFailure = (state, { errors }) => ({
  ...state,
  errors,
  loadingProperty: false,
  errorProperty: true
})
const getVipSeoInfoByPropertyIdNotFound = state => ({
  ...state,
  loadingProperty: false,
  notFoundProperty:true
})

const updateAsideLinksProperties = (state, { vipAsideLinks }) => ({
  ...state,
  vipAsideLinks
})

const updateSuggestedProperties = (state, { suggestedProperties }) => ({
  ...state,
  suggestedProperties
})

const clear = () => INITIAL_STATE

export const VipActionTypes = Types

const HANDLERS = {
  [Types.GET_VIP_SEO_INFO_BY_PROPERTY_ID]: getVipSeoInfoByPropertyId,
  [Types.GET_VIP_SEO_INFO_BY_PROPERTY_ID_SUCCESS]: getVipSeoInfoByPropertyIdSuccess,
  [Types.GET_VIP_SEO_INFO_BY_PROPERTY_ID_FAILURE]: getVipSeoInfoByPropertyIdFailure,
  [Types.GET_VIP_SEO_INFO_BY_PROPERTY_ID_NOT_FOUND]: getVipSeoInfoByPropertyIdNotFound,

  [Types.UPDATE_ASIDE_LINKS_PROPERTIES]: updateAsideLinksProperties,
  [Types.UPDATE_FOOTER_LINKS_PROPERTIES]: updateFooterLinksProperties,
  [Types.UPDATE_SUGGESTED_PROPERTIES]: updateSuggestedProperties,

  [Types.CLEAR]: clear
}

export const VipActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
