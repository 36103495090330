import ApiBase from 'app/services/api/entities/Base'

function* summary(brokerId) {
  return yield ApiBase.privateGet(`/v1/neximers/${brokerId}/`)
}

function* suscription(brokerId) {
  return yield ApiBase.privatePost(`/v1/neximers/${brokerId}/subscription/`)
}

function* createContract(brokerId, contractTypeId) {
  return yield ApiBase.privatePost(`/v1/neximers/${brokerId}/contracts/`, {
    data: { documentType: contractTypeId },
  })
}

function* finishProcess(brokerId) {
  return yield ApiBase.privatePost(`/v1/neximers/${brokerId}/finish_process/`)
}

export default {
  summary,
  suscription,
  createContract,
  finishProcess,
}
