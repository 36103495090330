// taking 1rem = 16px

export default {
  borderRadius: '0.5rem',  // 4px
  borderRadiusInput: '0.25rem',  // 4px
  containerSize: '69.37rem', // 1109.92 px
  containerMediumSize: '43rem', // 1350px
  containerLargeSize: '84.375rem', // 1350px
  headerHeight: '4.375rem', // 70 px
  mobileBreakPoint: '62rem' // 1109.92 px
}
