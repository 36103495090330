import { call, put, select, delay, all } from 'redux-saga/effects'
import { checkFeatureFlag } from 'config'
import { BinCloseDealActionCreators } from 'app/reducers/CloseDealRedux'
import api from 'app/services/api'
import { getUserProfile } from 'app/selectors'
import UserDetails from 'services/api/entities/userDetails'
import {
  mapDataToRequestLegalSupport,
  prepareContactPropertyCloseToActors,
} from 'app/selectors/closeDeal'
import { buildActors, prepareAgentBrokers } from 'selectors/dealActors'
import { getExternalLeadSources } from 'app/sagas/ExternalLeadSources'

export function* createWithoutExternalAgent({ data }) {
  const token = yield select(state => state.auth.token)

  const response = yield call(
    api.closeDeal.createCloseWithoutExternalAgent,
    token,
    data,
  )

  if (response.status === 201) {
    yield put(BinCloseDealActionCreators.createCloseDealSuccess(response.data))

    yield put(BinCloseDealActionCreators.changeTypeClose('FINISH_CLOSE'))
    yield delay(1500)
    return yield put(BinCloseDealActionCreators.finishedCreateCloseDeal())
  }

  yield put(BinCloseDealActionCreators.createCloseDealFailure(response.data))
}

export function* createWithExternalAgent({ data }) {
  const token = yield select(state => state.auth.token)

  const response = yield call(
    api.closeDeal.createCloseWithExternalAgent,
    token,
    data,
  )

  if (response.status === 201) {
    yield put(BinCloseDealActionCreators.createCloseDealSuccess(response.data))

    return yield put(BinCloseDealActionCreators.changeTypeClose('FINISH_CLOSE'))
  }
  yield put(BinCloseDealActionCreators.createCloseDealFailure(response.data))
}

export function* requestLegalSupport({ legalSupportValues }) {
  const currentUser = yield select(getUserProfile)
  const referalLeadSourceOptions = yield getExternalLeadSources()
  const { closeDealData, selectedPropertyToDeal } = yield select(
    state => state.closeDeal,
  )

  const dealValues = mapDataToRequestLegalSupport({
    legalSupportValues,
    closeDealData,
  })

  const selectedProperty = selectedPropertyToDeal?.property || {}
  const brokerPropertyOwner = selectedPropertyToDeal?.broker || {}
  // Legal support values are the values from the legal support form
  const property = {
    ...selectedProperty,
    broker: brokerPropertyOwner,
    referalLeadCustomPercentage: legalSupportValues.referalLeadCustomPercentage || null,
    referalLeadId: legalSupportValues.referalLeadId || null,
    referalLeadSource: legalSupportValues.referalLeadSource?.value || null,
  }

  const {
    brokers,
    contact,
    externalAgents,
    propertyCloseValues,
  } = prepareContactPropertyCloseToActors(currentUser, {
    ...dealValues,
    property,
  })

  const agentResponses = yield all(
    brokers.map(broker => UserDetails.agent(broker.id)),
  )

  const brokersWithAgentInfo = prepareAgentBrokers(
    Number(currentUser.userId),
    brokers,
    agentResponses
      .filter(response => response.success())
      .map(response => response.data),
  )

  const actors = buildActors({
    property,
    contact,
    initialBrokerId: Number(currentUser.userId),
    brokers: brokersWithAgentInfo,
    externalAgents: externalAgents,
    referalLeadSourceOptions: referalLeadSourceOptions,
  })

  const { externalAgent, ...values } = propertyCloseValues

  if (checkFeatureFlag('deal-actors')) {
    values.actors = actors
  } else {
    values.externalAgent = externalAgent
  }

  const propertyId = selectedPropertyToDeal?.property?.id

  if (propertyId) {
    // Existing property
    yield put(BinCloseDealActionCreators.createCloseDeal({
      ...values,
      property: propertyId,
    }))
  } else if (typeof values.property === 'object') {
    // Nonexisting property
    yield put(BinCloseDealActionCreators.createCloseDealWithExternalAgent(values))
  }
}
