import { put } from 'redux-saga/effects'
import moment from 'moment'
import { formatAppDate } from 'services/date'
import { NEXIMO_YEAR_CREATION } from 'services/config'
import { removeAllEmptyKeys } from 'services/utils'

import { NetworkStats } from 'services/api/entities'
import { NetworkStatsActionCreators } from 'app/reducers/NetworkStatsRedux'
import { summaryNetworkStatsSelector } from 'selectors/NetworkStats'
import { getTableType } from 'components/Network/Stats/utils'

const neximoYearCreation = formatAppDate(
  moment()
    .year(NEXIMO_YEAR_CREATION)
    .startOf('year'),
)

export function* getStatsRequest({ params }) {
  const statType = getTableType(params) || 'summary' // 'topClosers' | 'topBilling' | 'topNetworkBilling'

  const { market, constellation } = params
  const newParams = removeAllEmptyKeys({
    market,
    constellation,
    from: params.from || neximoYearCreation,
    to: params.to || formatAppDate(moment()),

    limit: 10, // Retrieve TOP 10
    offset: 0,
  })

  const NetworkStatApi = NetworkStats[statType]
  const response = yield NetworkStatApi(newParams)

  if (response.success()) {
    let count = 0
    let results = []

    if (statType === 'summary') {
      results = response.data
    } else {
      count = response.data.count
      results = response.data.results
    }

    return yield put(
      NetworkStatsActionCreators.getStatsSuccess(
        count,
        summaryNetworkStatsSelector(results, statType),
      ),
    )
  }

  yield put(NetworkStatsActionCreators.getStatsFailure(response.data))
}
