/**
 * Holds the state for the uploading document (Dokaltin)
 */

import { createReducer, createActions } from 'reduxsauce'
import { utils } from 'services'

const INITIAL_PAGE_DATA_STATE = {
  id: null,
  order: 0,
  pdfPage: null,    // the page object read from a PDF document
  uploadError: null,
  progress: 0,      // upload progress
  uploading: false,
  content: null,    // the base64 rendered from a canvas or read from an image
  url: '',          // url where the page was uploaded at
  imageType: ''
}

const INITIAL_STATE = {
  bondId: null,
  originalFilename: '',
  originalFileUrl: '',
  originalFileUploading: false,
  originalFileProgress: 0,
  hashPath: '',
  fileType: '',
  processing: false,
  creating: false,  // when expecting for POST to return
  created: false,  // when expecting for POST to return
  pageCount: 0,
  pagesData: {},
  error: null
}

const { Types, Creators } = createActions({
  processDocument: ['bondId','file'],
  documentProcessed: ['pageCount', 'pages'],
  setPageContent: ['pageId', 'content', 'imageType'],
  reset: [],
  createDocument: [],
  failure: ['error'],
  documentCreated: ['documentData']
}, { prefix: 'DOC_FILE_' })


const processDocument = (state, { bondId, file }) => {
  return {
    ...state,
    bondId,
    originalFilename: encodeURIComponent(file.name),
    originalFileUploading: true,
    hashPath: utils.getGuid() + utils.getGuid(),
    fileType: file.type.toLowerCase(),
    processing: true
  }
}

const documentProcessed = (state, { pageCount, pages }) => {
  const pagesObject = {}
  pages.forEach(page => {
    pagesObject[page.id] = {
      ...INITIAL_PAGE_DATA_STATE,
      ...page
    }
  })

  return {
    ...state,
    processing: false,
    pageCount,
    pagesData: pagesObject
  }
}

const setPageContent = (state, { pageId, content, imageType }) => {
  return {
    ...state,
    pagesData: {
      ...state.pagesData,
      [pageId]: {
        ...state.pagesData[pageId],
        content,
        imageType
      }
    }
  }
}

const createDocument = state => {
  const { pagesData } = state

  for (let key in pagesData) {
    pagesData[key].uploading = true
  }

  return {
    ...state,
    creating: true,
    created: false,
    pagesData
  }
}

const reset = state => INITIAL_STATE

const pageUploadProgress = (state, { fileName, progress }) => {
  if (state.originalFilename === fileName) {
    return {
      ...state,
      originalFileProgress: progress
    }
  }

  // if it's not one of our files...
  if (!Object.keys(state.pagesData).includes(fileName)) {
    return state
  }

  return {
    ...state,
    pagesData: {
      ...state.pagesData,
      [fileName]: {
        ...state.pagesData[fileName],
        progress
      }
    }
  }
}

const pageUploadSuccess = (state, { fileName, response, url }) => {
  // if it's the original file...
  if (state.originalFilename === fileName) {
    return {
      ...state,
      originalFileUrl: url,
      originalFileUploading: false
    }
  }


  // if it's not one of our files...
  if (!Object.keys(state.pagesData).includes(fileName)) {
    return state
  }

  return {
    ...state,
    pagesData: {
      ...state.pagesData,
      [fileName]: {
        ...state.pagesData[fileName],
        url,
        uploading: false,
        progress: 100
      }
    }
  }
}


const pageUploadError = (state, { fileName, error }) => {
  // if it's not one of our files...
  if (!Object.keys(state.pagesData).includes(fileName)) {
    return state
  }

  return {
    ...state,
    pagesData: {
      ...state.pagesData,
      [fileName]: {
        ...state.pagesData[fileName],
        uploading: false,
        uploadError: error,
        progress: 0
      }
    }
  }
}

const failure = (state, { error }) => {
  return {
    ...state,
    creating: false,
    created: false,
    error
  }
}

const documentCreated = (state, { documentData }) => {
  return {
    ...state,
    creating: false,
    created: true,
    error: null
  }
}

const HANDLERS = {
  [Types.PROCESS_DOCUMENT]: processDocument,
  [Types.DOCUMENT_PROCESSED]: documentProcessed,
  [Types.SET_PAGE_CONTENT]: setPageContent,
  [Types.RESET]: reset,
  [Types.CREATE_DOCUMENT]: createDocument,
  [Types.FAILURE]: failure,
  [Types.DOCUMENT_CREATED]: documentCreated,
  'FILE_UPLOAD_PROGRESS': pageUploadProgress,
  'FILE_UPLOAD_SUCCESS': pageUploadSuccess,
  'FILE_UPLOAD_ERROR': pageUploadError
}

export const DocumentFileActionTypes = Types
export const DocumentFileActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
