import crudAction from './actions'

export default {
  name: 'neximerExpenses',
  namespace: 'neximer_monthly_expenses',
  methods: [
    ...crudAction,
    {
      name: 'childrenFinancial',
      params: ['userId','data'],
      successCode: 200,
      method: 'Get',
      url: ({ userId, namespace }) => `/v1/brokers/${userId}/network_monthly_expenses/`,
      urlParams: ['userId']
    }
  ]
}
