import { createReducer, createActions } from 'reduxsauce'

const TOUR_INITIAL_STATE = {
  isOpen: false,
  hasViewed: false
}

const INITIAL_STATE = {}

const { Types, Creators } = createActions(
  {
    registerTour: ['tracingTourName'],
    toggleOpen: ['tracingTourName', 'isOpen'],
    hasViewed: ['tracingTourName','hasViewed'],
  },
  { prefix: 'TRACING_TOURS_' }
)

const registerTour = (state, { tracingTourName }) => {
  if (state[tracingTourName]) {
    return state
  }

  return {
    ...state,
    [tracingTourName]: TOUR_INITIAL_STATE
  }
}

const toggleOpen = (state, { tracingTourName, isOpen }) => ({
  ...state,
  [tracingTourName]: {
    ...state[tracingTourName],
    isOpen
  }
})

const hasViewed = (state, { tracingTourName, hasViewed }) => ({
  ...state,
  [tracingTourName]: {
    ...state[tracingTourName],
    hasViewed
  }
})

const HANDLERS = {
  [Types.REGISTER_TOUR]: registerTour,
  [Types.TOGGLE_OPEN]: toggleOpen,
  [Types.HAS_VIEWED]: hasViewed,
}

export const TracingToursActionTypes = Types

export const TracingToursActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
