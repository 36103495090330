import { createReducer, createActions } from 'reduxsauce'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback,
} from './utils'

export const INITIAL_STATE = {
  validateImage: initialFeedback(),
  validateImageStatus: initialFeedback(),
}

const { Types, Creators } = createActions(
  {
    validateImage: ['data'],
    validateImageSuccess: ['data'],
    validateImageFailure: ['errors'],
    finishedValidateImage: [],

    cleanState: [],
  },
  { prefix: 'VIRTUAL_TOUR_VALIDATE_IMAGE_' }
)

const validateImage = state => ({
  ...state,
  validateImageStatus: requestFeedback(),
})
const validateImageSuccess = (state) => ({
  ...state,
  validateImageStatus: successFeedback(),
})
const validateImageFailure = (state, { errors }) => ({
  ...state,
  validateImageStatus: failureFeedback(errors),
})
const finishedValidateImage = state => ({
  ...state,
  validateImageStatus: initialFeedback(),
})

const cleanState = () => INITIAL_STATE

const HANDLERS = {
  [Types.VALIDATE_IMAGE]: validateImage,
  [Types.VALIDATE_IMAGE_SUCCESS]: validateImageSuccess,
  [Types.VALIDATE_IMAGE_FAILURE]: validateImageFailure,

  [Types.FINISHED_VALIDATE_IMAGE]: finishedValidateImage,

  [Types.CLEAN_STATE]: cleanState,
}

export const ValidateImageActionTypes = Types

export const ValidateImageActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
