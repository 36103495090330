import { call, put, select } from 'redux-saga/effects'

import { PdfActionCreators } from 'app/reducers/PdfDownloadRedux'
import api from 'app/services/api/api'

export function* downloadPdf({ endpoint, fileName, data}) {

  const token = yield select(state => state.auth.token)
  const response = yield call(api.privatePost, endpoint, { token, data })

  if (response.status === 200) {
    yield call(blobToFileDownload, response.data.url, fileName)
    yield put(PdfActionCreators.getPdfSuccess(response.data.url))
    return
  }

  yield put(PdfActionCreators.getPdfFailure())
}

export const blobToFileDownload = (url, fileName = 'document') =>
  new Promise(resolve => {
    const element = document.createElement('a')
    element.style.display = 'none'
    element.setAttribute('href', url)
    element.setAttribute('download', fileName)
    element.setAttribute('target', '_blank')
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
    resolve()
  })
