import crudAction from './actions'

export default {
  name: 'user',
  namespace: 'users',
  methods: [
    ...crudAction,
    {
      name: 'updateProfile',
      params: ['data'],
      successCode: 200,
      method: 'Patch',
      url: ({ namespace, id }) => `/v1/${namespace}/update_profile/`,
      urlParams: ['id']
    },
    {
      name: 'updateWorkAreas',
      params: ['data'],
      successCode: 200,
      method: 'Patch',
      url: ({ namespace, id }) => `/v1/${namespace}/update_profile/`,
      urlParams: ['id']
    },
    {
      name: 'updateRecruitingAreas',
      params: ['data'],
      successCode: 200,
      method: 'Patch',
      url: ({ namespace, id }) => `/v1/${namespace}/update_profile/`,
      urlParams: ['id']
    },
    {
      name: 'createMailbox',
      params: ['data'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace }) => `/v1/${namespace}/create_mailbox/`,
      urlParams: []
    },
    {
      name: 'availableMailboxes',
      params: ['data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace }) => `/v1/${namespace}/available_mailboxes/`,
      urlParams: []
    },
    {
      name: 'review',
      params: ['data'],
      successCode: 200,
      method: 'Patch',
      url: ({ namespace, id }) => `/v1/${namespace}/review/`,
      urlParams: ['id']
    },
    {
      name: 'updatePassword',
      params: ['data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace }) => `/v1/${namespace}/update_password/`,
      urlParams: []
    },
    {
      name: 'updateMailboxPassword',
      params: ['data'],
      successCode: 204,
      method: 'Patch',
      url: ({ namespace }) => `/v1/${namespace}/set_new_mailbox_password/`,
      urlParams: []
    },
    {
      name: 'getFiscalData',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/${namespace}/fiscal_data/`,
      urlParams: []
    },
    {
      name: 'updateFiscalData',
      params: ['data'],
      successCode: 200,
      method: 'Patch',
      url: ({ namespace }) => `/v1/${namespace}/fiscal_data/`,
      urlParams: []
    },
    {
      name: 'publicChangePassword',
      params: ['data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace }) => `/v1/public/${namespace}/change_password/`,
      urlParams: []
    },
    {
      name: 'publicResetPassword',
      params: ['data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace }) => `/v1/public/${namespace}/reset_password/`,
      urlParams: []
    },
    {
      name: 'getPreferences',
      params: ['section'],
      successCode: 200,
      method: 'Get',
      url: () => `/v1/user/preferences/`,
      urlParams: []
    },
    {
      name: 'getLeadsPreferences',
      params: ['section'],
      successCode: 200,
      method: 'Get',
      url: () => `/v1/user/preferences/`,
      urlParams: []
    },
    {
      name: 'setPreferences',
      params: ['data'],
      successCode: 200,
      method: 'Post',
      url: () => `/v1/user/preferences/bulk/`,
      urlParams: []
    },
    {
      name: 'setLeadsPreferences',
      params: ['section','name','data'],
      successCode: 200,
      method: 'Patch',
      url: ({ section, name }) => `/v1/user/preferences/${section}__${name}/`,
      urlParams: ['section','name']
    },
    {
      name: 'networkConfig',
      params: ['userId'],
      successCode: 200,
      method: 'Get',
      url: ({ userId }) => `/v1/users/${userId}/network_config/`,
      urlParams: ['userId']
    },
    {
      name: 'sendVerificationCode',
      params: ['verification_code_type', 'email', 'phone_number'],
      successCode: 201,
      method: 'Post',
      url: () => '/v1/users/send_verification_code/',
      urlParams: []
    },
    {
      name: 'confirmVerificationCode',
      params: ['verification_code_type', 'code', 'email', 'phone_number'],
      successCode: 200,
      method: 'Post',
      url: () => '/v1/users/confirm_verification_code/',
      urlParams: []
    },
  ]
}