import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { allKeysToSnakeCase, removeEmptyKeys } from 'services/utils'

/**
 * @func useRouterChangeQueryParams
 * @description This hook is used to change the query params of the url
 *              You can deal with pagination, filters (dropdowns), etc.
 *              Note: If you need a search input, there is a component called
 *              SearchFiltersBox.
 * @param {func} callback
 * @param {Object} config
 * @param {String} config.pathname - '/your/pathname'
 * @param {Number} config.limit - Default 10
 */
function useRouterChangeQueryParams(callback, config = {}) {
  const router = useRouter()
  const { query } = router
  const { offset = 0 } = query
  const LIMIT = config.limit || 10
  const page = offset / LIMIT + 1

  useEffect(() => {
    callback(allKeysToSnakeCase(query))
  }, [query])

  function handleQueryChange(newParams) {
    window.scrollTo(0, 0)

    const newQuery = removeEmptyKeys({
      limit: LIMIT,
      ...query,
      ...newParams,
      offset: newParams.offset || 0,
    })

    router.push({
      pathname: config.pathname,
      query: newQuery,
    })
  }

  function onPageChange(indexPage) {
    handleQueryChange({ offset: indexPage * LIMIT })
  }

  return {
    page,
    query,
    handleQueryChange,
    onPageChange,
  }
}

export default useRouterChangeQueryParams
