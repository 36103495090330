import config from 'config'
import { companyConfig } from 'globalConfigApp'

import styles from './styles'

const CONTACT_PHONE = config('CONTACT_PHONE')

export default [
  <article
    data-category="legal"
    data-title="¿Cuál es la lista de documentos mínima que debemos subir para el semaforo Verde?"
    data-url="cual-es-la-lista-de-documentos-minima-que-debemos-subir-para-el-semaforo-verde"
    className="wrapper-article"
  >
    <h4>
      ¿Cuál es la lista de documentos mínima que debemos subir para el semaforo
      Verde?
    </h4>
    <div className="content">
      <p>La documentación mínima requerida es:</p>
      <ol>
        <li>Título de propiedad</li>
        <li>Identificación del propietario o los propietarios</li>
        <li>Acta de matrimonio del propietario</li>
        <li>RFC de los propietarios</li>
        <li>Contrato de intermediación.</li>
      </ol>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="legal"
    data-title={"¿Qué es el precio \"fuera de mercado\"? y ¿cuál es la escala?"}
    data-url="que-es-el-precio-fuera-de-mercado-y-cual-es-la-escala"
    className="wrapper-article"
  >
    <h4>¿Qué es el precio "fuera de mercado"? y ¿cuál es la escala?</h4>
    <div className="content">
      <p>
        Nos estamos basando de una herramienta especializada en valuación de
        propiedades <strong>(YALS)</strong> y cuando un inmueble esta{' '}
        <span className="tag">10%</span> arriba o{' '}
        <span className="tag">10%</span> abajo se considera{' '}
        <strong>
          <u>NO DESTACABLE</u>
        </strong>
        .
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="legal"
    data-title="¿Cómo se pondra el equipo legal en contacto conmigo para indicarme los pasos a seguir?"
    data-url="como-se-pondra-el-equipo-legal-en-contacto-conmigo-para-indicarme-los-pasos-a-seguir"
    className="wrapper-article"
  >
    <h4>
      ¿Cómo se pondra el equipo legal en contacto conmigo para indicarme los
      pasos a seguir?
    </h4>
    <div className="content">
      <p>
        Nos pondemos en contacto contigo por WhatsApp y deberas estar pendiente de tu correo {companyConfig.name}
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="legal"
    data-title="¿Cuál es el plazo de respuesta del departamento legal? ¿En cuánto tiempo recibo mi contrato de arrendamiento?"
    data-url="cual-es-el-plazo-de-respuesta-del-departamento-legal-en-cuanto-tiempo-recibo-mi-contrato-de-arrendamiento"
    className="wrapper-article"
  >
    <h4>
      ¿Cuál es el plazo de respuesta del departamento legal? ¿En cuánto tiempo
      recibo mi contrato de arrendamiento?
    </h4>
    <div className="content">
      <p>
        Al momento de tener los documentos indispensables completos, tendrás tu
        contrato de arrendamiento en las próximas <span className="tag">8 horas hábiles</span>, tomando en
        cuenta que los horarios de oficina son de lunes a viernes de 9:00 a
        19:00 horas.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="legal"
    data-title="Sí el semaforo es naranja, ¿mi propiedad se publica? ¿Se destaca?"
    data-url="si-el-semaforo-es-naranja-mi-propiedad-se-publica-se-destaca"
    className="wrapper-article"
  >
    <h4>Sí el semaforo es naranja, ¿mi propiedad se publica? ¿Se destaca?</h4>
    <div className="content">
      <p>
        Si se destaca con una prioridad <b>secundaria</b>, es decir, se le da
        prioridad a las propiedades en semaforo{' '}
        <span className="tag">VERDE</span> en los espacios disponibles de
        promoción con los que contamos, en caso de que hayan disponibles, se
        completarán con las propiedades en semaforo{' '}
        <span className="tag">NARANJA</span>
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="legal"
    data-title="¿Cuál es la diferencia entre Semaforo Verde y Naranja en cuanto a mi plan de marketing?"
    data-url="cual-es-la-diferencia-entre-semaforo-verde-y-naranja-en-cuanto-a-mi-plan-de-marketing"
    className="wrapper-article"
  >
    <h4>
      ¿Cuál es la diferencia entre Semaforo Verde y Naranja en cuanto a mi plan
      de marketing?
    </h4>
    <div className="content">
      <p>
        Las propiedades en semaforo <span className="tag">VERDE</span> siempre
        tendran mas prioridad al ser promocionadas, una propiedad con semaforo{' '}
        <span className="tag">NARANJA</span> puede ser que{' '}
        <strong>NUNCA</strong> sea destacada.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="legal"
    data-title="¿En cuánto tiempo mi anuncio se publicará una vez el semaforo pasa a verde?"
    data-url="en-cuanto-tiempo-mi-anuncio-se-publicara-una-vez-el-semaforo-pasa-a-verde"
    className="wrapper-article"
  >
    <h4>
      ¿En cuánto tiempo mi anuncio se publicará una vez el semaforo pasa a
      verde?
    </h4>
    <div className="content">
      <p>
        Las propiedades se comienzan a publicar en el instante que son{' '}
        <strong>aprobadas</strong>, el semaforo solo diferencia si se destaca o
        no.
        <br />
        Es importante mencionar que el semaforo es un elemento más que nuestro{' '}
        <span className="tag">algoritmo de destaque</span> considera para tomar
        decisiones.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="legal"
    data-title="¿Qué pasa si no solicito el apoyo legal para la documentación de mi cierre y que reporte mi cierre cuando este realizado?"
    data-url="que-pasa-si-no-solicito-el-apoyo-legal-para-la-documentacion-de-mi-cierre-y-que-reporte-mi-cierre-cuando-este-realizado"
    className="wrapper-article"
  >
    <h4>
      ¿Qué pasa si no solicito el apoyo legal para la documentación de mi cierre
      y que reporte mi cierre cuando este realizado?
    </h4>
    <div className="content">
      <p>
        No hay problema, pueden reportarnos el cierre realizado para ingresar la
        información pertinente al sistema.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="legal"
    data-title="¿Este nuevo protocolo es obligatorio?"
    data-url="este-nuevo-protocolo-es-obligatorio"
    className="wrapper-article"
  >
    <h4>¿Este nuevo protocolo es obligatorio?</h4>
    <div className="content">
      <p>
        Si, este protocolo es un beneficio para los neximers y un apoyo
        necesario para llevar organizadamente la operación de <b>NUESTRO</b>{' '}
        negocio
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="legal"
    data-title={
      'Cuando la documentacion está arriba, ¿dónde se encontrarán los documentos en mi dashboard? ¿en "mis documentos"?'
    }
    data-url="donde-se-encontraran-los-documentos-en-mi-dashboard"
    className="wrapper-article"
  >
    <h4>
      Cuando la documentacion está arriba, ¿dónde se encontrarán los documentos
      en mi dashboard? ¿en "mis documentos"?
    </h4>
    <div className="content">
      <p>
        Se encontrará en{' '}
        <span className="tag">
          Mis propiedades / Detalle propiedad / Documentación
        </span>
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="legal"
    data-title="¿Qué es el algoritmo de destaque?"
    data-url="que-es-el-algoritmo-de-destaque"
    className="wrapper-article"
  >
    <h4>¿Qué es el algoritmo de destaque?</h4>
    <div className="content">
      <p>
        Es una herramienta inteligente desarrollada por <strong>{companyConfig.name}</strong>{' '}
        que estudia todo el inventario de propiedades para tomar decisiones al
        momento de seleccionar que propiedades seran destacadas <strong>semanalmente</strong>.
        <br />
        Toma en cuenta distintas variables como el semaforo de validacion, leads
        recibidos, desempeño del Neximer, entre otros.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="legal"
    data-title="¿Dónde puedo descargar los contratos?"
    data-url="donde-puedo-descargar-los-contratos"
    className="wrapper-article"
  >
    <h4>¿Dónde puedo descargar los contratos?</h4>
    <div className="content">
      <p>
        Al ingresar a tu cuenta {companyConfig.name} en la parte superior derecha encontraras
        la sección
        <br />
        <span className="tag">Mis documentos</span> en dónde podrás descargar
        los contratos que manejamos.
      </p>
      <p>Alli encontraras:</p>

      <ol>
        <li>Código de Etica.</li>
        <li>Contrato de Arrendamiento.</li>
        <li>Contrato de Colaboración.</li>
        <li>Contrato Privado de compra-venta.</li>
        <li>Contrato Privado de compra-venta.</li>
        <li>Contrato Promesa de compra-venta.</li>
        <li>Contrato de Promoción de Casa Habitación.</li>
        <li>Contrato de Intermediación en Arrendamiento en Exclusiva.</li>
        <li>Contrato de Intermediación en compra-venta en Exclusiva.</li>
        <li>Contrato de Intermediación en Arrendamiento en Opción.</li>
        <li>Contrato de Intermediación en compra-venta en Opción.</li>
        <li>Anexos Contrato de Intermediación en Arrendamiento.</li>
        <li>Anexos Contrato de Intermediación en compra-venta.</li>
        <li>Carta de presentación {companyConfig.name}.</li>
        <li>Formato Opinión de Valor (Estudio de Mercado).</li>
      </ol>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="legal"
    data-title={`¿Puedo usar un contrato diferente al de ${companyConfig.name}?`}
    data-url="puedo-usar-un-contrato-diferente-al-de-neximo"
    className="wrapper-article"
  >
    <h4>¿Puedo usar un contrato diferente al de {companyConfig.name}?</h4>
    <div className="content">
      <p>
        El contrato puede ser uno entregado por el cliente pero validado por
        {companyConfig.name}.
      </p>
      <p>
        Te sugerimos validar tu contrato a través{' '}
        <span className="tag">soporte administrativo > soporte legal</span>
      </p>
      <p>
        No será posible usar las herramientas de {companyConfig.name} para una propiedad sin
        contrato de intermediación con {companyConfig.name}.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="legal"
    data-title="¿Qué pasa si el propietario no quiere firmar el contrato?"
    data-url="que-pasa-si-el-propietario-no-quiere-firmar-el-contrato"
    className="wrapper-article"
  >
    <h4>¿Qué pasa si el propietario no quiere firmar el contrato?</h4>
    <div className="content">
      <p>
        Los clientes tienen la obligación de firmar un contrato de
        intermediación con {companyConfig.name}. Recomendamos demostrar al cliente todas las
        herramientas que {companyConfig.name} usa para promover su propiedad, para justificar
        una colaboración contractual entre el cliente, el aliado y {companyConfig.name}.
      </p>
      <p>
        Sera imposible comercializar una propiedad sin contrato de
        intermediación.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="legal"
    data-title="¿Cómo puedo solicitar apoyo legal?"
    data-url="como-puedo-solicitar-apoyo-legal"
    className="wrapper-article"
  >
    <h4>¿Cómo puedo solicitar apoyo legal?</h4>
    <div className="content">
      <p>
        Nuestro equipo legal esta disponible en el siguiente número{' '}
        {CONTACT_PHONE}
        con un horario de Lunes a Viernes de 9:00 am a 6:00 pm para asesorarte
        personalmente.
      </p>
      <p>
        Recuerda que también puedes solicitar apoyo a través de{' '}
        <span className="tag">Soporte administrativo > Soporte legal</span>.
      </p>
      <p>
        Podemos brindarte asesoramiento con temas personales o cualquier duda
        que tengas sobre tus propiedades. Te brindaremos una respuesta dentro de
        24h después de tu solicitud.
      </p>
      <p>
        Hemos creado un breve tutorial que puedes ver a continuación:{' '}
        <a href="https://www.youtube.com/watch?v=Ip73md_5muk">ver aquí</a>
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="legal"
    data-title="¿Neximo nos apoyará a revisar las escrituras de las propiedades?"
    data-url="neximo-nos-apoyara-a-revisar-las-escrituras-de-las-propiedades"
    className="wrapper-article"
  >
    <h4>¿Neximo nos apoyará a revisar las escrituras de las propiedades?</h4>
    <div className="content">
      <p>
        Nuestro equipo de onboarding te puede apoyar a comprobar las escrituras
        de tus propiedades.
      </p>
      <p>
        Nuestro equipo Onboarding esta disponible en el siguiente número
        {CONTACT_PHONE} con un horario de Lunes a Viernes de 9:00 am a 7:00 pm
        para asesorarte personalmente. Recuerda que también puedes solicitar
        apoyo a través de la plataforma en
        <br />
        <span className="tag">
          Soporte administrativo > Otros > Vaidación de escrituras
        </span>
        .
      </p>
      <p>Te brindaremos una respuesta dentro de 24h después de tu solicitud.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Qué documentación mínima se requiere para que se apruebe una propiedad en intermediación?"
    data-url="que-documentacion-minima-se-requiere-para-que-se-apruebe-una-propiedad-en-intermediacion"
    className="wrapper-article"
  >
    <h4>¿Qué documentación mínima se requiere para que se apruebe una propiedad en intermediación?</h4>
    <div className="content">
      <b>Persona Física:</b>
      <ul>
        <li>Contrato debidamente llenado y firmado</li>
        <li>Contacto del propietario (Teléfono y correo)</li>
        <li>Identificación oficial y vigente del propietario: PASAPORTE, LICENCIA, INAPAM, IFE, INE</li>
      </ul>

      <b>Persona Moral:</b>
      <ul>
        <li>Contrato debidamente llenado y firmado</li>
        <li>Contacto de la empresa</li>
        <li>Identificación oficial y vigente del representante legal</li>
      </ul>

      <b>Persona Física con Representante Legal:</b>
      <ul>
        <li>Contrato debidamente llenado y firmado</li>
        <li>Contacto del representante legal del propietario</li>
        <li>Identificación oficial y vigente del representante legal</li>
      </ul>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Qué documentación me falta para que sea apta para la publicación con verificación?"
    data-url="que-documentacion-me-falta-para-que-sea-apta-para-la-publicacion-con-verificacion"
    className="wrapper-article"
  >
    <h4>¿Qué documentación me falta para que sea apta para la publicación con verificación?</h4>
    <div className="content">
      <b>Personas Físicas:</b>
      <ul>
        <li>Contrato debidamente llenado y firmado</li>
        <li>Contacto del propietario (Teléfono y correo)</li>
        <li>Identificación oficial</li>
        <li>Título de propiedad completo con datos de inscripción ante el RPP</li>
        <li>Anexo B firmado</li>
      </ul>

      <b>Persona Moral:</b>
      <ul>
        <li>Contrato Debidamente Llenado Y Firmado</li>
        <li>Contacto De La Empresa (Teléfono y correo)</li>
        <li>Identificación Oficial Vigente Del Representante Legal</li>
        <li>Poder Notarial Del Representante Legal</li>
        <li>Título De Propiedad Completo Con Datos De Inscripción En RPP</li>
        <li>Anexo B Firmado Por El Representante Legal</li>
      </ul>

      <b>Persona Física con Representante Legal:</b>
      <ul>
        <li>Contrato debidamente llenado y firmado</li>
        <li>Contacto del representante legal del propietario</li>
        <li>Identificación oficial vigente del representante legal</li>
        <li>Poder notarial del representante legal</li>
        <li>Título de propiedad completo con datos de inscripción en RPP</li>
        <li>Anexo B firmado por el representante legal</li>
      </ul>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Dónde descargo los contratos?"
    data-url="donde-descargo-los-contratos"
    className="wrapper-article"
  >
    <h4>¿Dónde descargo los contratos?</h4>
    <div className="content">
      <p>
        <b>1.-</b> Documento PDF que se descarga de la sección <span className="tag">Documentos</span>, 
        es un PDF editable únicamente en ciertas partes, 
        te permite ingresar datos pero no eliminar partes del contrato, 
        por consiguiente, las demás variantes (ya sea sobre Persona Física, 
        Persona moral o persona Física con representación) permanecen en el documento. 
        En el apartado donde indica nuevos contratos de 
        intermediación está un botón color verde que indica descargar ahora, le das clic 
        y podrás ver un listado de los contratos que puedes descargar.
      </p>
      <p>
        <b>2.-</b> Como solución a la problemática anterior, para obtener un contrato 
        que contuviera únicamente las partes que corresponden a tu caso específico, 
        se creó la Herramienta de Contrato Self Service 
        ubicada en tu Dashboard en 
        la sección <span className='tag'>Contratos</span>, con la cual se te hacen una serie de preguntas en un 
        formulario que, al terminar de contestarlo, te arroja el contrato lleno y 
        personalizado únicamente con las partes que correspondan al caso concreto.
      </p>
      <p>
        <b>3.-</b> Una tercera opción y la más reciente, se utiliza únicamente 
        cuando tus clientes propietarios son extranjeros y requieren la 
        traducción en Inglés del contrato de intermediación. Esta opción 
        la encuentras en la sección de <span className="tag">Documentación</span>

      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="Tipos de contrato en altas"
    data-url="tipos-de-contrato-en-altas"
    className="wrapper-article"
  >
    <h4>Tipos de contrato en altas</h4>
    <div className="content">
      <p>Tienes a tu disposición 3 contratos: Intermediación, Colaboración y el Máster Bróker.</p>
      <p>
        <ul><b>CONTRATO DE INTERMEDIACIÓN COMPRAVENTA:</b> Es aquel contrato cuyo objetivo 
        es determinar obligaciones del Profesional inmobiliario y del Propietario, para
         lograr la venta individual de su propiedad, además este contrato protege el 
         pago de tu comisión, esta relación se da entre el Profesional inmobiliario y 
         el Propietario.
        </ul>
        <ul><b>CONTRATO DE INTERMEDIACIÓN ARRENDAMIENTO:</b>  Este contrato de igual forma 
        se da entre el Profesional inmobiliario y el Propietario, te ayuda para 
        aquellos clientes que deseen rentar su propiedad por un tiempo determinado.
        </ul> 
        <ul><b>CONTRATO MASTER BRÓKER:</b> Su función es brindar el servicio de promoción 
        a un Desarrollador/Constructora, este tipo de contratos se da para que el 
        Neximer pueda manejar todo el inventario en un lapso de tiempo.
        </ul>
        <ul><b>ACUERDO DE COLABORACIÓN:</b> Podrás solicitar este contrato solo si
         tienes menos de 3 meses como miembro de {companyConfig.name}.  Así mismo te permite poder 
         establecer el inventario que te comparte un Asesor externo, plazo y porcentaje 
         de comisión a compartir, recuerda que las colaboraciones entre Neximers no 
         están permitidas.
        </ul>
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article      
    data-category="legal"
    data-title="¿Dónde puedo encontrar el contrato self - service?"
    data-url="donde-puedo-encontrar-el-contrato-self-service"
    className="wrapper-article"
  >
    <h4>¿Dónde puedo encontrar el contrato self - service?</h4>
    <div className="content">
      <p>Es muy fácil solo necesitas seguir los siguientes pasos:</p>
      <ul>Ingresar a la siguiente liga <a href='/dashboard/contracts' target='_blank'>Contratos</a></ul>
      <ul>Da click en <span className="tag">Contratos</span></ul>
      <ul>Podrás visualizar un botón de color verde <span className="tag">Crear Contrato</span></ul>
      <ul>Posterior a ello se desplegará una nueva ventana, en donde el mismo sistema 
        te preguntará ¿qué tipo de documento neceistas?</ul>
      <p>A partir de este punto deberás de responder una serie de preguntas conforme a 
        la negociación que se dio con tu cliente propietario.</p>
      <p>Recuerda que si no completas el llenado tu contrato seguirá apareciendo como <span className="tag">Borrador</span>, 
        para que puedas descargar tu contrato es necesario que respondas a todo el formulario.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Por qué tenemos que especificar si la comisión tiene IVA?"
    data-url="por-que-tengo-que-especificar-si-la-comision-tiene-iva"
    className="wrapper-article"
  >
    <h4>¿Por qué tenemos que especificar si la comisión tiene IVA?</h4>
    <div className="content">
      <p>La Ley del Impuesto al Valor Agregado (IVA) específica un catálogo de actividades/servicios 
        que están gravados, es decir debemos pagar un impuesto para gozar de ellos. El servicio que 
        ofreces al Propietario se encuentra dentro de este listado, por esté motivo en tus contratos 
        deberás especificar si la comisión incluye o es más IVA. 
      </p>
      <p><b>Todo lo que determine la ley tiene carácter de obligatorio, si tu omites trasladar el IVA, 
        entonces la autoridad procederá a cobrar dicho impuesto directamente a ti por lo que tu 
        comisión se verá diluida y no corresponderá al monto total pactado.</b>
      </p>
      </div>
      <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Cuáles son los datos de inscripción en el Registro Público?"
    data-url="cuales-son-los-datos-de-inscripcion-en-el-registro-publico"
    className="wrapper-article"
  >
    <h4>¿Cuáles son los datos de inscripción en el Registro Público?</h4>
    <div className="content">
      <p>Son aquellos que se encuentren en la boleta de inscripción al Registro Público de la Propiedad.
        Son de suma importancia pues garantiza que el acto que se inscribió cumple con todos los requisitos
        legales pues es el personal certificador quien revisa y autoriza. Además en ella podrás encontrar
        los datos esenciales para el llenado de tu contrato de intermediación:
      </p>
      <ul>El folio real (clave única alfanumérica con la que se identifica un inmueble)</ul>
      <ul>Fecha en que se inscribió dicho acto</ul>
      <ul>El nombre de la autoridad quien ordenó realizar el asiento</ul>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Cuál es la firma digital permitida?"
    data-url="cual-es-la-firma-digital-permitida"
    className="wrapper-article"
  >
    <h4>¿Cuál es la firma digital permitida?</h4>
    <div className="content">
      <p>Existe una norma que regula la firma y es la Nom-151, en la cual establece los requisitos que debe
        cumplir un proveedor de servicios de firma electrónica para que el documento tenga validez, si
        utilizas alguna aplicación o plataforma asegúrate que cuente con dicha certificación para que
        puedas cargar tu contrato.
      </p>
      <p><b>La firma electrónica produce los mismos efectos que una firma autógrafa.</b></p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Por qué el departamento de  legal ya no me apoya con mis contratos?"
    data-url="por-que-el-departamento-de-legal-ya-no-me-apoya-con-mis-contratos"
    className="wrapper-article"
  >
    <h4>¿Por qué el departamento de  legal ya no me apoya con mis contratos?</h4>
    <div className="content">
      <p>Seguimos Trabajando para ti, el equipo de Legal continua apoyandote  es por eso que evolucionamos: Desde el 15 de noviembre de 2020, {companyConfig.name} desarrolló una nueva herramienta para tí y es el Contrato Self Service, con esto acortamos el tiempo de espera para que puedas crear tus propios contratos, esto no quiere decir que perderás beneficios pues si tu necesitas que revisemos tus documentos seguirás contando con la asesoría de un abogado.</p>
      <p>Existen casos que por su complejidad el área legal deberá realizar el contrato, te proporcionamos la siguiente lista:</p>
      <ul>
        <li>Procesos judiciales</li>
        <li>Sucesiones</li>
        <li>Capitliaciones matrimoniales</li>
        <li>Fideicomisos</li>
        <li>Colaboraciones con asesores externos</li>
        <li>Desarrollos/Constructoras</li>
      </ul>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Cuánto tiempo tarda en responder Legal mis correos?"
    data-url="cuanto-tiempo-tarda-en-responder-legal-mis-correos"
    className="wrapper-article"
  >
    <h4>¿Cuánto tiempo tarda en responder Legal mis correos?</h4>
    <div className="content">
      <p>El tiempo de respuesta es de 24 hrs a 72 hrs hábiles.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Cuál es la diferencia entre el contrato máster bróker, COMISIÓN MERCANTIL y colaboración?"
    data-url="cual-es-la-diferencia-entre-el-contrato-master-broker-comision-mercantil-y-colaboracion"
    className="wrapper-article"
  >
    <h4>¿Cuál es la diferencia entre el contrato máster bróker, COMISIÓN MERCANTIL y colaboración?</h4>
    <div className="content">
      <p>El contrato Máster Bróker se da en negociaciones con Desarrolladores/Constructoras y es quien paga directamente la comisión mientras que en una colaboración es entre un Neximer y un asesor externo comparten  comisión.</p>
      <p>Master Broker: Desarrollo en exclusiva</p>
      <p>Comisión Mercantil: Desarrollo en opción</p>
      <p>Colaboración: Convenio entre asesores que comparten comisión.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Qué es una compensación?"
    data-url="que-es-una-compensacion"
    className="wrapper-article"
  >
    <h4>¿Qué es una compensación?</h4>
    <div className="content">
      <p>Es aquella cantidad de dinero/porcentaje que se establece en los contratos en caso de que alguna de las partes incumpla con alguna obligación estipulada.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Qué es un representante?"
    data-url="que-es-un-representante"
    className="wrapper-article"
  >
    <h4>¿Qué es un representante?</h4>
    <div className="content">
      <p>Es la facultad que tiene una persona de actuar a nombre y cuenta de otro, dichas facultades deberán constar en un poder notarial, pudiendo el representante realizar todo tipo de actos que se describen en este o bien ya sea que lo determine la ley.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Puede firmar un extranjero mi contrato?"
    data-url="puede-firmar-un-extranjero-mi-contrato"
    className="wrapper-article"
  >
    <h4>¿Puede firmar un extranjero mi contrato?</h4>
    <div className="content">
      <p>Desde luego también puedes contratar con extranjeros, asegúrate de contar con el documento que acredite su legal estancia en territorio mexicano.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="Si no pide factura el cliente, ¿Por qué le tengo que cobrar el IVA?"
    data-url="si-no-pide-factura-el-cliente-por-que-le-tengo-que-cobrar-el-iva"
    className="wrapper-article"
  >
    <h4>Si no pide factura el cliente, ¿Por qué le tengo que cobrar el IVA?</h4>
    <div className="content">
      <p>Todo lo que determine la ley tiene carácter de obligatorio, por lo que existe una ley que regula el pago del IVA, en este caso es la La ley al Impuesto al Valor Agregado, en ella se encuentra la obligación de cobrar el impuesto y no está sujeto a si el cliente necesita o no una factura, por lo que es de suma importancia cumplir con dicha disposición.</p>
      <p><b>Si tú omites trasladar el IVA, entonces la autoridad procederá a cobrar dicho impuesto directamente a ti por lo que tu comisión se verá diluida y no corresponderá al monto total pactado.</b></p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Qué documentos necesito, para qué legal haga mi contrato de Intermediación?"
    data-url="que-documentos-necesito-para-que-legal-haga-mi-contrato-de-intermediacion"
    className="wrapper-article"
  >
    <h4>¿Qué documentos necesito, para qué legal haga mi contrato de Intermediación?</h4>
    <div className="content">
      <p><b>DEL ASESOR:</b> datos completos de identificación, identificación oficial vigente.</p>
      <p><b>PROPIETARIO (PERSONA FÍSICA):</b> Datos de contacto del propietario, RFC, identificación oficial vigente del propietario, escritura pública de la propiedad hasta los datos de inscripción en el registro público de la propiedad.</p>
      <p><b>PERSONA MORAL:</b> Datos de contacto de la empresa, identificación oficial vigente del representante legal, acta constitutiva de la empresa/ poder notarial del representante legal, escritura pública de la propiedad hasta los datos de registro público de la propiedad.</p>
      <p><b>PROPIETARIO CUENTA CON REPRESENTANTE LEGAL:</b> Datos de contacto del representante legal del propietario, correo electrónico, identificación oficial vigente del representante legal, poder notarial del representante legal, escritura pública de la propiedad y datos de registro público de la propiedad.</p>
      <p><b>DOCUMENTOS SUJETOS A CONSIDERACIÓN DE LEGAL YA QUE DEPENDIENDO DEL CASO, SE PUEDE SOLICITAR INFORMACIÓN ADICIONAL.</b></p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Con una carta poder mi cliente puede vender/rentar la casa de su mamá?"
    data-url="con-una-carta-poder-mi-cliente-puede-vender-rentar-la-casa-de-su-mama"
    className="wrapper-article"
  >
    <h4>¿Con una carta poder mi cliente puede vender/rentar la casa de su mamá?</h4>
    <div className="content">
      <p>Esto no es posible, para que esto sea válido es a través de un poder ya que es el código civil que prevé esta situación e impone la obligación de que sea mediante este documento, al tratarse de bienes inmuebles.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Qué es un gravamen?"
    data-url="que-es-un-gravamen"
    className="wrapper-article"
  >
    <h4>¿Qué es un gravamen?</h4>
    <div className="content">
      <p>Es cualquier limitación que puede tener una propiedad para que el propietario pueda vender (enajenar/transmitir) y hasta que no sea resuelta podrá continuar con la venta, pues este tipo de anotaciones quedan insertas en el Registro Público de la Propiedad y del Comercio.</p>
      <p>Ejemplos:</p>
      <ul>
        <li>Fianzas</li>
        <li>Hipotecas</li>
        <li>Embargos</li>
      </ul>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title={`Mi cliente me pregunta ¿Por qué ${companyConfig.name} se queda con el dinero de mi casa?`}
    data-url="por-que-neximo-se-queda-con-el-dinero-de-mi-casa"
    className="wrapper-article"
  >
    <h4>Mi cliente me pregunta ¿Por qué {companyConfig.name} se queda con el dinero de mi casa?</h4>
    <div className="content">
      <p>{companyConfig.name} por disposición legal no está facultado para recibir cantidades parciales o la totalidad de una compraventa o renta.</p>
      <p>En caso del Depósito en Garantía solo resguarda la cantidad hasta que se celebre el contrato privado o la firma definitiva de la escritura de compraventa y devuelve al 100% dicho concepto al comprador/arrendatario. Con excepción de que el comprador/arrendatario no cumpla con el compromiso que adquirió de comprar o rentar.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title={`¿Por qué ${companyConfig.name} ya no acepta la vigencia indefinida?`}
    data-url="por-que-neximo-ya-no-acepta-la-vigencia-indefinida"
    className="wrapper-article"
  >
    <h4>¿Por qué {companyConfig.name} ya no acepta la vigencia indefinida?</h4>
    <div className="content">
      <p>En {companyConfig.name} estamos preocupados por cuidar que tu inventario esté vigente, es por ello que se creó la estrategia para que en tus contratos tengan una vigencia definida. Esto te ayudará, pues será un recordatorio para que solicites información al propietario y tengas conocimiento si el inmueble sigue estando disponible.</p>
      <p>Con esto evitamos también molestia a todos nuestros clientes.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Cómo hago el cierre en plataforma? Desde mis propiedades"
    data-url="como-hago-el-cierre-en-plataforma-desde-mis-propiedades"
    className="wrapper-article"
  >
    <h4>¿Cómo hago el cierre en plataforma? Desde mis propiedades</h4>
    <div className="content">
      <p>Ingresar a la liga <a href='/dashboard' target='_blank'>dashboard</a> posteriormente te diriges a la Sección <span className='tag'>Mis propiedades</span> seleccionar la propiedad que queremos realizar el cierre, nos vamos a la opción Eliminar (Bote de basura) y seleccionar la opción correcta, posteriormente nos llevará al proceso de inicio de cierre primero ingresar el nombre del cliente al que se vendió/rento inmueble si ya se cuenta con el nombre dentro de la lista de contactos del cliente solo ingresar nombre le das click y enseguida se visualiza información del cliente, en caso de que no se cuente con datos del cliente se da de alta (ingresar nombre, correo y teléfono) Al poner el precio final, con el que se vendió la propiedad, se visualizará la comisión que ganó con esa venta.</p>
      <p><b>VIDEOS TUTORIALES</b> <a href='https://www.youtube.com/channel/UCabfKcMV0WMCazHrWSCtYjA'>https://www.youtube.com/channel/UCabfKcMV0WMCazHrWSCtYjA</a></p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Puedo sustituir el título de propiedad por otro documento para que sea apta para publicar?"
    data-url="puedo-sustituir-el-titulo-de-propiedad-por-otro-documento-para-que-sea-apta-para-publicar"
    className="wrapper-article"
  >
    <h4>¿Puedo sustituir el título de propiedad por otro documento para que sea apta para publicar?</h4>
    <div className="content">
      <p>No, la ley determina que sólo la escritura pública o sentencia es el documento probatorio para que una persona acredite tener la propiedad sobre un inmueble.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Puede firmar otra persona que no sea el propietario el contrato para la intermediación?"
    data-url="puede-firmar-otra-persona-que-no-sea-el-propietario-el-contrato-para-la-intermediacion"
    className="wrapper-article"
  >
    <h4>¿Puede firmar otra persona que no sea el propietario el contrato para la intermediación?</h4>
    <div className="content">
      <p>Si, esto solo se puede lograr a través de un poder notarial, este deberá contar con facultades para actos de administración o un poder especial para actos de dominio.</p>
      <p>Si la intención es que el representante legal firme la Escritura Pública, forzosamente el poder deberá contar con facultades para actos de dominio y cumplir, en su caso, con las especificaciones que determine cualquier institución acreditante que este otorgando un crédito al comprador (vigencia, facultades y restricciones dependen de las instituciones acreditantes)</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Cuándo el área legal hace mi contrato para intermediación?"
    data-url="cuando-el-area-legal-hace-mi-contrato-para-intermediacion"
    className="wrapper-article"
  >
    <h4>¿Cuándo el área legal hace mi contrato para intermediación?</h4>
    <div className="content">
      <p>Tu equipo legal te apoya en casos específicos, como son: Sucesiones, Sentencias judiciales, Convenio de capitulación matrimonial, Fideicomiso, Apoderado, Titular menor de edad, Titular asesor.</p>
      <p>Esto no implica que te encuentres solo en tu actividad inmobiliaria, pues si tienes alguna duda puedes solicitar asesoría con tu abogada asignada.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Cuál es la diferencia entre un contrato de colaboración, intermediación y MB?"
    data-url="cual-es-la-diferencia-entre-un-contrato-de-colaboracion-intermediacion-y-mb"
    className="wrapper-article"
  >
    <h4>¿Cuál es la diferencia entre un contrato de colaboración, intermediación y MB?</h4>
    <div className="content">
      <p><b>COLABORACIÓN:</b> En esta negociación interviene un asesor de {companyConfig.name} y uno externo, y comparten comisión este tipo de contrato te permite definir el inventario a compartir así como el porcentaje de comisión.</p>
      <p><b>INTERMEDIACIÓN:</b> Aquí solo interactúa el propietario del Inmueble y el Asesor inmobiliario a quien se le encomienda la promoción del mismo. Este contrato te permite definir obligaciones de cada una de las partes.</p>
      <p><b>MASTER BROKER:</b> Puede intervenir una persona física o moral propietaria de inmuebles respecto de Desarrollos Inmobiliarios con diferentes unidades privativas, cuya actividad frecuente es la compra y venta, en este tipo de contratos te permite promocionar diferentes inmuebles y porcentajes de comisión.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Qué ocurre, si mi cliente compró la propiedad y no tenía construcciones, pero al transcurrir el tiempo construyó su casa?"
    data-url="que-ocurre-si-mi-cliente-compro-la-propiedad-y-no-tenia-construcciones-pero-al-transcurrir-el-tiempo-construyo-su-casa"
    className="wrapper-article"
  >
    <h4>¿Qué ocurre, si mi cliente compró la propiedad y no tenía construcciones, pero al transcurrir el tiempo construyó su casa?</h4>
    <div className="content">
      <p>Deberás solicitar información adicional y preguntar si cuenta con la documentación que regula dichas construcciones (licencia de construcción, aviso de terminación de obra, certificado de habitabilidad, escritura de erección de propiedad, etc), pues cualquier construcción, remodelación requiere tener autorización de catastro para llevarse a cabo.</p>
      <p>Esta situación impacta en el pago del impuesto predial, pues al haber construcciones la propiedad aumenta de valor y por consiguiente el impuesto que pagas mes con mes aumentará por este motivo.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Por qué se necesita un poder notarial para que otra persona firme el contrato?"
    data-url="por-que-se-necesita-un-poder-notarial-para-que-otra-persona-firme-el-contrato"
    className="wrapper-article"
  >
    <h4>¿Por qué se necesita un poder notarial para que otra persona firme el contrato?</h4>
    <div className="content">
      <p>Esto da certeza y seguridad jurídica a las partes, es por eso que el código civil prevé esta situación y establece el mecanismo para que un tercero pueda actuar a cuenta y nombre de otro. Esto solo se logra por medio de un poder ya que define límites de su representación.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Cuál es la diferencia entre contrato de intermediación en exclusiva y contrato de intermediación en opción?"
    data-url="cual-es-la-diferencia-entre-contrato-de-intermediacion-en-exclusiva-y-contrato-de-intermediacion-en-opcion"
    className="wrapper-article"
  >
    <h4>¿Cuál es la diferencia entre contrato de intermediación en exclusiva y contrato de intermediación en opción?</h4>
    <div className="content">
      <p><b>Contrato Exclusiva:</b> En esta modalidad el Propietario adquiere el compromiso para no contratar los servicios de cualquier otro agente inmobiliario.</p>
      <p><b>Contrato Opción:</b> Bajo este esquema el Propietario puede vender/rentar por sí o por cualquier tercero el inmueble y esto no implica consecuencias. En este caso el primero que logre la venta/renta es al que le pagarán la comisión.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿Cómo me puedo contactar con legal directamente?"
    data-url="como-me-puedo-contactar-con-legal-directamente"
    className="wrapper-article"
  >
    <h4>¿Cómo me puedo contactar con legal directamente?</h4>
    <div className="content">
      <p>
        En tu dashboard tienes tu sección de soporte, ahi encontraras los correos
        de tus abogados asi como la línea directa, en <span className="tag">Soporte</span>.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="legal"
    data-title="¿En donde veo mis contratos?"
    data-url="en-donde-veo-mis-contratos"
    className="wrapper-article"
  >
    <h4>¿En donde veo mis contratos?</h4>
    <div className="content">
      <p>
        En tu dashboard en la sección mi perfil, tienes acceso a todos los
        contratos que firmaste con nosotros.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>
]
