import React from 'react'
import moment from 'moment'

import { useResizeWindow } from 'app/hooks'
import { getAvailableDatesForCalendar, formatDates } from 'app/services/date'

import { Button, IonIcon } from 'controls'
import DateElement from './DateElement'

import { calendarStyles } from './styles'

const CalendarInline = ({ selectedDate, setSelectedDate }) => {
  const { innerWidth } = useResizeWindow()
  const [currentDate, setCurrentDate] = React.useState(new Date())
  const today = moment(new Date())
    .startOf('day')
    .toString()

  const offset = (innerWidth > 992 && 5) || (innerWidth > 772 && 4) || 2

  const availableDates = getAvailableDatesForCalendar(currentDate, {
    timeMeasure: 'days',
    leftOffset: offset,
    rightOffset: offset,
  })

  const firstDate = availableDates[0].startOf('day').toString()
  const lastDate = availableDates[availableDates.length - 1]
    .startOf('day')
    .toString()

  return (
    <div className="calendar-container">
      <div className="title-date">
        <Button
          className="button-title"
          fullWidth
          onClick={() => setCurrentDate(selectedDate)}
        >
          <span className="title">
            {moment(selectedDate).format(formatDates['FULL_READABLE_DATE'])}
          </span>
        </Button>
      </div>

      <div className="calendar-body">
        <Button
          size="small"
          styleType="rounded"
          disabled={today === firstDate}
          className="button-control-calendar left-button"
          onClick={() => setCurrentDate(firstDate)}
        >
          <IonIcon icon="md-arrow-dropleft-circle" />
        </Button>
        <ul className="calendar-list">
          {availableDates.map((currentMomentDate, index) => (
            <li className="calendar-item" key={index}>
              <DateElement
                currentMomentDate={currentMomentDate}
                selectedDate={selectedDate}
                onSelect={setSelectedDate}
              />
            </li>
          ))}
        </ul>
        <Button
          size="small"
          styleType="rounded"
          className="button-control-calendar right-button"
          onClick={() => setCurrentDate(lastDate)}
        >
          <IonIcon icon="md-arrow-dropright-circle" />
        </Button>
      </div>
      <style jsx>{calendarStyles}</style>
    </div>
  )
}

export default CalendarInline
