import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { SimpleSelect, MultiSelect } from 'react-selectize'

import { shallowEqual } from 'services/utils'

import Item from 'controls/DropDown/Item'
import Option from 'controls/DropDown/Option'
import { Spinner } from 'controls'

import styles, { overwrites, dropDownProps, dropDownCheckboxStyles } from './styles'
import inputStyles, { select as selectStyles } from 'controls/Input/styles'

export class DropDownSelectize extends Component {
  static propTypes = {
    className: PropTypes.string,
    showSearch: PropTypes.bool,
    multiple: PropTypes.bool,
    label: PropTypes.string,
    fieldTheme: PropTypes.oneOf(['normal', 'fit']),
    blurAfterSelect: PropTypes.bool,
    loading: PropTypes.bool
  }

  static defaultProps = {
    className: '',
    multiple: false,
    showSearch: true,
    style: {},
    hiddenItem: false,
    blurAfterSelect: true,
    fieldTheme: 'normal'
  }

  constructor(props) {
    super(props)

    this.tagRef = React.createRef()
  }

  onValueChange = (...values) => {
    const { multiple, blurAfterSelect } = this.props
    this.props?.onValueChange?.(...values)

    // TODO: Improve this
    if (this.tagRef?.current && !multiple && blurAfterSelect) {
      this.tagRef?.current?.refs?.select?.refs?.control?.querySelector('input')?.blur()
    }
  }

  render() {
    const { className, multiple, showSearch, fieldTheme, label, loading, meta = {}, onValueChange, setRef, ...rest } = this.props
    
    const { valid = false, invalid = false, touched = false, error, warning, initial} = meta
    const isInvalid = invalid && touched

    const search = showSearch ? {} : { search: '' }
    const Tag = multiple ? MultiSelect : SimpleSelect
    const isFitMultipleField = fieldTheme === 'fit' && multiple
    const isFitField = fieldTheme === 'fit' || isFitMultipleField

    return (
      <div
        className={classnames(
          "wrapper-dropdown-selectize",
          this.props.containerClassName,
          { 'fit-field-multiple': isFitMultipleField },
          { error: isInvalid }
        )}
        style={{ width: '100%', ...this.props.style }}
      >
        {isFitField && label && <label className="label fit-field">{label}</label>}
        {isFitField && loading && (
          <div className={classnames("loading", { disabled: this.props.disabled })}>
            <Spinner size={20} thickness={4} />
          </div>
        )}
        <Tag
          hideResetButton
          renderOption={Option}
          renderValue={Item}
          theme="bootstrap3" // can be one of "default" | "bootstrap3" | "material" | ...
          transitionEnter
          className={classnames(
            className,
            dropDownProps.className,
            {
              [dropDownProps.hideSearchClassName]: !showSearch
            },
            { ['fit-field']: isFitField },
            {
              'fit-field-multiple': isFitMultipleField
            }
          )}
          defaultValues={multiple && initial}
          ref={setRef || this.tagRef}
          onValueChange={this.onValueChange}
          {...search}
          {...rest}
        />
        <style jsx global>
          {styles}
        </style>
        <style jsx global>
          {overwrites}
        </style>
      </div>
    )
  }
}

export class DropDownSelectizeForm extends Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    multiple: PropTypes.bool
  }

  static defaultProps = {
    multiple: false
  }

  constructor(props) {
    super(props)
    this.state = {
      focused: false
    }
  }

  setValue = (reduxValue, onChange) => selectValue => {
    if (!shallowEqual(selectValue, reduxValue)) {
      onChange(selectValue)
    }
  }

  onBlur = ({ originalEvent, value, open }) => {
    this.setState({
      focused: false
    })
    if (this.props.input.onBlur) {
      this.props.input.onBlur(value)
    }
  }

  onFocus = () => {
    this.setState({
      focused: true
    })
  }

  render() {
    const {
      multiple,
      input: { value, onChange, ...inputRest },
      ...rest
    } = this.props
    const handlers = multiple
      ? { onValuesChange: this.setValue(value, onChange) }
      : { onValueChange: this.setValue(value, onChange) }

    const valueChange = multiple
      ? {
        onValuesChange: this.setValue(value, onChange)
      }
      : {
        onValueChange: this.setValue(value, onChange)
      }
    return (
      <DropDownSelectize
        {...rest}
        {...handlers}
        {...inputRest}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        multiple={multiple}
        value={value}
        {...valueChange}
      />
    )
  }
}


export class SimpleDropDown extends Component {
  static propTypes = {
    forceEmptyOptionAsPlaceholder: PropTypes.bool, // To show firts option as placeholder
  }

  render() {
    const {
      id,
      placeholder,
      label = '',
      value,
      className,
      disabled,
      disabledFirstOption = true,
      style,
      loading,
      labelStyle,
      meta = {},
      forceEmptyOptionAsPlaceholder,
      children,
      options,
      ...rest
    } = this.props

    const { valid = false, invalid = false, touched = false, error, warning } = meta

    return (
      <div
        style={{ width: '100%' }}
        className={classnames('select-container', { disabled, error: touched && !valid })}
      >
        <select
          {...rest}
          className={classnames(
            'fl-input-container',
            {
              disabled
            },
            className
          )}
          id={id}
          disabled={disabled}
          style={style}
          value={value}
          aria-label={label || this.props['aria-label'] || placeholder}
        >
          <option value="" disabled={disabledFirstOption} selected={forceEmptyOptionAsPlaceholder} className="placeholder">
            {placeholder || label}
          </option>
          {options && !Boolean(children) && options.map(({ label, value, ...rest }, i) => (
            <option key={i} value={String(value)} {...rest}>
              {label}
            </option>
          ))}
          {children}
        </select>
        {label && <label htmlFor={id} className={classnames(className)} style={labelStyle}>{label}</label>}
        {loading && <div className="loading"><Spinner size={20} thickness={4}/></div>}
        <style jsx>{selectStyles}</style>
      </div>
    )
  }
}

export const SimpleDropDownForm = ({ input,...rest }) => (
  <SimpleDropDown {...input} {...rest} />
)

export class DropDownForm extends Component {

  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired
    }))
  }

  static defaultProps = {
    options: []
  }

  handleChange = (event) => {
    const {
      options,
      input: { onChange }
    } = this.props
    const newOption = options.find(oo => String(oo.value) === String(event.target.value))
    onChange(newOption)
  }

  handleBlur = (event) => {
    const {
      options,
      input: { onBlur }
    } = this.props
    const newOption = options.find(oo => String(oo.value) === String(event.target.value))
    onBlur(newOption)
  }

  handleFocus = (event) => {
    const {
      options,
      input: { onFocus }
    } = this.props
    const newOption = options.find(oo => String(oo.value) === String(event.target.value))
    onFocus(newOption)
  }

  inputValue = (value) => {
    const v = this.props.options.find(o => String(o.value) === String(value.value))
    return v ? v.value : ''
  }

  render() {
    const {
      input,
      defaultValue,
      ...rest
    } = this.props

    return <SimpleDropDownForm {...input} {...rest} onChange={this.handleChange} onBlur={this.handleBlur} onFocus={this.handleFocus} value={this.inputValue(input.value) || defaultValue}>
    </SimpleDropDownForm>
  }
}

export const DropDownCheckbox = ({
  placeholder,
  label = '',
  value,
  options,
  className,
  disabled,
  style,
  ariaLabel,
  onChange,
  handleToggleOpen,
  meta = {}
}) => {
  const [internalValues, setInternalValues] = React.useState(value)
  const [isOpen, setIsOpen] = React.useState(false)

  const {
    valid = false,
    invalid = false,
    touched = false,
    error,
    warning
  } = meta

  useEffect(
    () => {
      setInternalValues(value)
    },
    [value]
  )

  function handleChange(newValue, checked) {
    const newValues = { ...internalValues, [newValue]: checked ? true : '' }

    setInternalValues(newValues)

    if (onChange) {
      onChange(newValues)
    }
  }

  function onToggleOpen() {
    if (handleToggleOpen) {
      handleToggleOpen(!isOpen)
    }

    setIsOpen(!isOpen)
  }

  return (
    <div
      className={classnames('fl-input-container', 'dropdown-check-list', {
        disabled,
        open: isOpen,
        error: touched && !valid
      })}
    >
      <label className="fl-input-label">{label}</label>

      <ul
        className={classnames('input', 'items', { disabled }, className)}
        style={style}
        aria-label={ariaLabel || label || placeholder}
      >
        <li className="option-checkbox-wrapper label">
          <span
            className="input anchor"
            role="input"
            tabIndex="0"
            onClick={onToggleOpen}
          >
            {label}
          </span>
        </li>
        {options.map(({ label, value: fieldName }) => (
          <li key={fieldName} className="option-checkbox-wrapper">
            <label>
              {label}
              <input
                type="checkbox"
                name={fieldName}
                checked={Boolean(internalValues[fieldName])}
                onChange={({ target }) =>
                  handleChange(target.name, target.checked)
                }
              />
            </label>
          </li>
        ))}
      </ul>

      <style jsx>{inputStyles}</style>
      <style jsx>{dropDownCheckboxStyles}</style>
    </div>
  )
}

DropDownCheckbox.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  ariaLabel: PropTypes.string,
  onChange: PropTypes.func,
  meta: PropTypes.object
}

DropDownCheckbox.defaultProps = {
  value: {}
}