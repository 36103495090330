import {
  auth,
  binAlerts,
  binLeadQualityCheck,
  config,
  geo,
  dokaltin,
  bond,
  files,
  directAnnonceLead,
  notification,
  stats,
  helpdesk,
  directory,
  portals,
  rfc,
  propertyDocumentation,
  closeDeal,
  OnboardingMaterials,
  propertyComplaints,
  virtualTour
} from './entities'

export default {
  auth,
  binAlerts,
  binLeadQualityCheck,
  config,
  geo,
  dokaltin,
  bond,
  files,
  directAnnonceLead,
  notification,
  stats,
  helpdesk,
  directory,
  portals,
  rfc,
  propertyDocumentation,
  closeDeal,
  OnboardingMaterials,
  propertyComplaints,
  virtualTour
}
