/**
 * This module works with hashed store to support multiple request
 * For example, if some view has the same info but from different dates
 * in different tables.
 *
 * Requirements:
 *    All request need fromDate and toDate, this values are strings.
 *
 *
 * Component example:
 *    useEffect(() => {
 *       dispatch(actionCreator(fromDate, toDate, params))
 *    }, [fromDate, toDate])
 *
 *    const first_table_results = useSelector(
 *       state => commissionSummarySelector(state, fromDate, toDate)
 *    )
 */

import { createReducer, createActions } from 'reduxsauce'
import {
  requestFeedback,
  successFeedback,
  failureFeedback,
  addHashedState,
  updateHashedState,
  removeHashedState,
  initialFeedback
} from 'reducers/utils'

import { createHash, BASE_MLM_QUOTAS } from 'selectors/MLMQuotas'

const INITIAL_STATE = {
  quotas: {},
  transactionSummary: {
    feedback: initialFeedback(),
    profit: null,
    activeDeals: null,
    notPayedProfit: null,
    activeDealsId: []
  },
  dealsDetail: {
    feedback: initialFeedback(),
    dealsDetail: []
  },
}

const { Types, Creators } = createActions(
  {
    getQuotasRequest: ['from', 'to', 'params', "includeProfitRequest"],
    getQuotasSuccess: ['from', 'to', 'count', 'quotas', 'total'],
    getQuotasFailure: ['from', 'to', 'errors'],
    cleanQuota: ['from', 'to'],
    getTransactionSummaryRequest: ['from', 'to', 'params', 'tracingUserType'],
    getTransactionSummarySuccess: ['summary'],

    getDealsDetails: ['dealsId', 'tracingUserType'],
    getDealsDetailsSuccess: ['dealsDetail'],
    getDealsDetailsFailure: ['errors'],
  },
  { prefix: 'MLM_QUOTAS_' }
)

const getQuotasRequest = (state, { from, to, params }) => ({
  ...state,
  quotas: addHashedState(state.quotas, createHash(from, to), {
    ...BASE_MLM_QUOTAS,
    params,
    feedback: requestFeedback()
  })
})

const getQuotasSuccess = (state, { from, to, count, quotas, total }) => ({
  ...state,
  quotas: updateHashedState(state.quotas, createHash(from, to), {
    total,
    count,
    quotas,
    feedback: successFeedback()
  })
})

const getQuotasFailure = (state, { from, to, errors }) => ({
  ...state,
  quotas: updateHashedState(state.quotas, createHash(from, to), {
    feedback: failureFeedback(errors)
  })
})

const cleanQuota = (state, { from, to }) => ({
  ...state,
  quotas: removeHashedState(state.quotas, createHash(from, to))
})

const getTransactionSummaryRequest = state =>
  updateHashedState(state, 'transactionSummary', {
    feedback: requestFeedback()
  })

const getTransactionSummarySuccess = (state, { summary }) =>
  updateHashedState(state, 'transactionSummary', {
    feedback: successFeedback(),
    ...summary
  })

const getDealsDetails = state =>
  updateHashedState(state, 'dealsDetail', {
    feedback: requestFeedback()
  })

const getDealsDetailsSuccess = (state, { dealsDetail }) =>
  updateHashedState(state, 'dealsDetail', {
    feedback: successFeedback(),
    dealsDetail
  })

const getDealsDetailsFailure = (state, { errors }) =>
  updateHashedState(state, 'dealsDetail', {
    feedback: failureFeedback(errors)
  })

const HANDLERS = {
  [Types.GET_QUOTAS_REQUEST]: getQuotasRequest,
  [Types.GET_QUOTAS_SUCCESS]: getQuotasSuccess,
  [Types.GET_QUOTAS_FAILURE]: getQuotasFailure,
  [Types.CLEAN_QUOTA]: cleanQuota,
  [Types.GET_TRANSACTION_SUMMARY_REQUEST]: getTransactionSummaryRequest,
  [Types.GET_TRANSACTION_SUMMARY_SUCCESS]: getTransactionSummarySuccess,

  [Types.GET_DEALS_DETAILS]: getDealsDetails,
  [Types.GET_DEALS_DETAILS_SUCCESS]: getDealsDetailsSuccess,
  [Types.GET_DEALS_DETAILS_FAILURE]: getDealsDetailsFailure,
}
export const MLMQuotasActionTypes = Types
export const MLMQuotasActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
