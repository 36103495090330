import { call, select } from 'redux-saga/effects'
import api from 'app/services/api/api'

import Response from 'app/classes/Response'

/**
 * RequestOptions structure
 * @typedef RequestOptions
 * @type {Object}
 *
 * @property {Object} params
 * @property {Object} data
 * @property {Object} headers
 *
 * @example
 * privateGet('/some/path', requestOptions)
 */

function* makeRequest(context, endpoint, params, data, headers = {}) {
  const rawResponse = yield call(
    api.makeRequest,
    context,
    endpoint,
    params,
    data,
    headers
  )

  const response = new Response(rawResponse)

  return response
}

function* privateRequest(method, endpoint, params, data, headers = {}) {
  const token = yield select(state => state.auth.token)

  const authHeader = token ? { Authorization: `JWT ${token}` } : {}

  return yield makeRequest(method, endpoint, params, data, {
    ...authHeader,
    ...headers
  })
}

function* privateGet(endpoint, { params = {}, data = {}, headers = {} } = {}) {
  return yield privateRequest('get', endpoint, params, data, headers)
}

function* privatePost(endpoint, { params = {}, data = {}, headers = {} } = {}) {
  return yield privateRequest('post', endpoint, params, data, headers)
}

function* privatePut(endpoint, { params = {}, data = {}, headers = {} } = {}) {
  return yield privateRequest('put', endpoint, params, data, headers)
}

function* privatePatch(endpoint, { params = {}, data = {}, headers = {} } = {}) {
  return yield privateRequest('patch', endpoint, params, data, headers)
}

function* privateDelete(endpoint, { params = {}, data = {}, headers = {} } = {}) {
  return yield privateRequest('delete', endpoint, params, data, headers)
}

export default {
  makeRequest,
  privateRequest,
  privateGet,
  privatePost,
  privatePut,
  privatePatch,
  privateDelete
}
