import crudAction from '../actions'

export default {
  name: 'crmFollowUps',
  namespace: 'crm/followups',
  methods: [
    ...crudAction,
    {
      name: 'filterAll',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/${namespace}/`,
      urlParams: []
    }
  ]
}
