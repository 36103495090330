import config from 'config'
import {
  OCUPATION_STATUS,
  STATUS_LABEL,
  getPropertySEODescription
} from 'services/seo'
import { currency as formatCurrency } from 'services/number'
import { PORTALS_SHOW_HIGHLIGHT_LABEL } from 'app/constants/propertyHighlight'

const FIRST_ID_VALIDATED_BY_LEGAL = parseInt(
  config('FIRST_ID_VALIDATED_BY_LEGAL')
)

class Property {
  constructor(property) {
    this.property = property
    this.status = property.status
    this.semaphore = property.semaphore
    this.adStatus = property.propertyAdStatus
    this.statusDocumentation = property.statusDocumentation
    this.deletedAt = property.deletedAt
    this.approvedAt = property.approvedAt
    this.sitesProperties = property.sitesProperties || []
    this.developmentType = property.developmentType || null

    this.notMarketableStatus = [
      'PAUSED',
      'CLAIMED',
      'ARCHIVED',
      'PENDING_FOR_DELETE'
    ]

    this.notAvailableForShow = ['PAUSED', 'PENDING_FOR_DELETE']

    this.allowedStatusToPublicShow = [
      'PUBLISHED',
      'APPROVED',
      'CLAIMED',
      'ARCHIVED'
    ]

  this.allowedStatusToHiglight = [
    'ACTIVE',
    'APPROVED',
    'PUBLISHED',
  ]

    this.allowedSemaphoresToHighlight = [
      'READY_FOR_PUBLICATION_AND_VERIFIED',
      'READY_FOR_PUBLICATION',
    ]
  }

  // Start methods related to property status
  getStatus = () => this.status
  isDraft = () => this.status === 'DRAFT'
  isPendingForApproval = () => this.status === 'PENDING_FOR_APPROVAL'
  isCreated = () => this.status === 'CREATED'
  isUserReviewNeeded = () => this.status === 'USER_REVIEW_NEEDED'
  isApproved = () => this.status === 'APPROVED'
  isPublished = () => this.status === 'PUBLISHED'
  isInProcess = () => this.status === 'IN_PROCESS'
  isSold = () => this.status === 'SOLD'
  isLeased = () => this.status === 'LEASED'
  isClaimed = () => this.status === 'CLAIMED'
  isArchived = () => this.status === 'ARCHIVED'
  isPropertyInClaimedProcess = () => this.status === 'PENDING_FOR_DELETE'
  isInDeletingProcess = () => this.status === 'PENDING_FOR_DELETE'
  isDeleted = () => this.deletedAt !== null

  //Method related to property pending_documentation status
  isDocumentationPendingWithAllowedStatus() {
    if (this.statusDocumentation !== 'PENDING_DOCUMENTATION') {
      return true
    }
    return this.adStatus === 'PUBLISHED' && this.hasMarketableStatus()
  }

  propertyIsSellable() {
  /**
   * brokerData null means:
   * Property has not marketable status
   * Broker is blocked
   * Property was deleted
   */
    return Boolean(this.property.brokerData)
  }

  hasMarketableStatus() {
    return !this.notMarketableStatus.includes(this.status)
  }

  hasAllowedStatusToPublicShow() {
    return this.allowedStatusToPublicShow.includes(this.status)
  }

  propertyShouldBeIndexed() {
    const isAvailableToPublicShow = this.isAvailableToPublicShow()

    if (isAvailableToPublicShow) {
      return true
    }

    return (
      this.hasAllowedStatusToPublicShow() &&
      (this.propertyIsSellable() || this.isClaimed() || this.isArchived())
    )
  }

  propertyShouldBeHidden() {
    return this.notAvailableForShow.includes(this.status)
  }

  isAvailableToPublicShow() {
    return !this.isDeleted() && this.isPublished() && this.propertyIsSellable()
  }

  isAvailableToAddToTheRequirement = () =>
    !this.deletedAt &&
    this.hasMarketableStatus() &&
    this.adStatus === 'PUBLISHED'

  isAPropertyValidatedByLegal = () =>
    this.property.id >= FIRST_ID_VALIDATED_BY_LEGAL

  shouldShowLegalStatus = () => 
    this.isAPropertyValidatedByLegal() && this.statusDocumentation && this.isDocumentationPendingWithAllowedStatus()

  formatedPrice = (config = {}) => {
    const { amount, currency } = this.property.price || {}

    const formatedPrice = formatCurrency(amount || 0, {
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...config
    })

    return `${formatedPrice} ${currency || ''}`
  }

  smallFormatedPrice = () => this.formatedPrice({
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  currentOcupationStatus = () => {
    const isClosed = this.status === 'ARCHIVED'

    const foundCurrentOcupationStatus =
      isClosed &&
      OCUPATION_STATUS.find(
        ({ value }) => value === this.property.operationType
      )

    const statusLabel = isClosed
      ? foundCurrentOcupationStatus
      : STATUS_LABEL.find(({ value }) => value === this.status)

    return statusLabel
  }

  isHighlighted = () => {
    for (const site of this.sitesProperties) {
      if (PORTALS_SHOW_HIGHLIGHT_LABEL.includes(site.siteName) && site.isHighlight && !site.terminatedAt) {
        return true
      }
    }
    return false
  }

  hasValidSemaphoreToHighlight = () => {
    return this.allowedSemaphoresToHighlight.includes(this.semaphore)
  }

  hasValidStatusToHighlight = () =>
    this.allowedStatusToHiglight.includes(this.status)

  isNotRealEstateDevelopment = () => {
    return !this.developmentType || this.developmentType == 'NONE' 
  }

  canBeHighlighted = () =>
    this.hasValidStatusToHighlight() &&
    this.hasValidSemaphoreToHighlight() &&
    this.isNotRealEstateDevelopment()

  propertySEODescription() {
    return getPropertySEODescription(this.property)
  }
}

export default Property
