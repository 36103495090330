import crudAction from './actions'

export default {
  name: 'brokerNote',
  namespace: 'broker_notes',
  methods: [
    ...crudAction
  ]
}

