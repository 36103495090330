import danger from './danger'
import dangerOutline from './danger.outline'
import facebook from './facebook'
import info from './info'
import infoOutline from './info.outline'
import none from './none'
import noneOutline from './none.outline'
import primary from './primary'
import primaryOutline from './primary.outline'
import secondary from './secondary'
import secondaryOutline from './secondary.outline'
import success from './success'
import successOutline from './success.outline'
import tertiary from './tertiary'
import warning from './warning'
import warningOutline from './warning.outline'

export default {
  danger,
  dangerOutline,
  facebook,
  info,
  infoOutline,
  none,
  noneOutline,
  primary,
  primaryOutline,
  secondary,
  secondaryOutline,
  success,
  successOutline,
  tertiary,
  warning,
  warningOutline
}