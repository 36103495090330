import { colors, font, fontSizes } from 'theme'
import css from 'styled-jsx/css'

export default css`
.content-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
}
.progress-ring.absolute {
  position: absolute;
}
.progress-ring-circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.content-circle {
  position: relative;
  text-align: center;
}
.content-circle .message {
  position: absolute;
  margin: 0 auto;
  padding: .3rem;
  width: 100%;
  font-weight: bold;
  width: 85%;
}
`

export const circleProgressBoxStyles = css`
  .circle-progress-box-container {
    position: relative;
    width: fit-content;
  }
  .circle-progress-container,
  .circle-progress-box-content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
  }
  .circle-progress-container {
    transform: rotate(118deg);
    width: fit-content;
  }
  .circle-progress-box-content {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 1.4rem;
    right: 1.1rem;
    left: 1.4rem;
    bottom: 1.3rem;
    border-radius: 50%;
  }
  .circle-progress-box-content.light-gray {
    background-color: ${colors.lightGray};
  }

  .circle-progress-container.disabled {
    position: initial;
  }
`