import { createReducer, createActions } from 'reduxsauce'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback
} from './utils'

export const INITIAL_STATE = {
  rentingRequirements: [],
  showFormToEditPrequalify: false,
  updatePrequalifyStatus: initialFeedback(),
  getRentingRequirementsStatus: initialFeedback()
}

const { Types, Creators } = createActions(
  {
    updatePrequalify: ['data'],
    updatePrequalifySuccess: ['data'],
    updatePrequalifyFailure: ['data'],

    getRentingRequirements: [],
    getRentingRequirementsSuccess: ['data'],
    getRentingRequirementsFailure: ['data'],

    toggleEditPrequalifyForm: ['show'],

    cleanUpdatePrequalifyStatus: [],
    cleanState: []
  },
  { prefix: 'BIN_LEAD_PREQUALIFY_' }
)

const updatePrequalify = state => ({
  ...state,
  updatePrequalifyStatus: requestFeedback()
})

const updatePrequalifySuccess = state => ({
  ...state,
  updatePrequalifyStatus: successFeedback()
})

const updatePrequalifyFailure = state => ({
  ...state,
  updatePrequalifyStatus: failureFeedback()
})

const getRentingRequirements = state => ({
  ...state,
  getRentingRequirementsStatus: requestFeedback()
})

const getRentingRequirementsSuccess = (state, { data }) => ({
  ...state,
  rentingRequirements: data,
  getRentingRequirementsStatus: successFeedback()
})

const getRentingRequirementsFailure = state => ({
  ...state,
  getRentingRequirementsStatus: failureFeedback()
})

const toggleEditPrequalifyForm = (state, { show }) => ({
  ...state,
  showFormToEditPrequalify: show
})

const cleanUpdatePrequalifyStatus = state => ({
  ...state,
  updatePrequalifyStatus: initialFeedback()
})

const cleanState = state => ({
  ...INITIAL_STATE,
  rentingRequirements: state.rentingRequirements
})

const HANDLERS = {
  [Types.UPDATE_PREQUALIFY]: updatePrequalify,
  [Types.UPDATE_PREQUALIFY_SUCCESS]: updatePrequalifySuccess,
  [Types.UPDATE_PREQUALIFY_FAILURE]: updatePrequalifyFailure,

  [Types.GET_RENTING_REQUIREMENTS]: getRentingRequirements,
  [Types.GET_RENTING_REQUIREMENTS_SUCCESS]: getRentingRequirementsSuccess,
  [Types.GET_RENTING_REQUIREMENTS_FAILURE]: getRentingRequirementsFailure,

  [Types.TOGGLE_EDIT_PREQUALIFY_FORM]: toggleEditPrequalifyForm,

  [Types.CLEAN_UPDATE_PREQUALIFY_STATUS]: cleanUpdatePrequalifyStatus,
  [Types.CLEAN_STATE]: cleanState
}

export const binLeadPrequalifyActionTypes = Types

export const binLeadPrequalifyActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
