import React from 'react'
import PropTypes from 'prop-types'
import { colors } from 'theme'
import styles from './styles'

const Spinner = ({
  color, thickness, size, style
}) => {
  const spinnerStyle = {
    borderTop: `${thickness}px solid ${color}`,
    borderRight: `${thickness}px solid ${colors.alpha(color, 0.2)}`,
    borderBottom: `${thickness}px solid ${colors.alpha(color, 0.2)}`,
    borderLeft: `${thickness}px solid ${colors.alpha(color, 0.2)}`,
    width: size,
    height: size
  }

  const mergedStyle = {
    ...spinnerStyle,
    ...style
  }

  return (
    <div
      className="loader"
      style={mergedStyle}
    >
      Cargando...
      <style jsx>{styles}</style>
    </div>
  )
}

Spinner.propTypes = {
  size: PropTypes.number.isRequired,
  thickness: PropTypes.number.isRequired,
  color: PropTypes.string,
  style: PropTypes.object
}

Spinner.defaultProps = {
  color: colors.frame,
  size: 50,
  thickness: 5,
  style: {}
}


const FullPage = ({ bgColor, isOpen, children, style = {} }) => {
  const bodyStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    backgroundColor: colors.white
  }
  const contentSpinner = {
    backgroundColor: `${bgColor}`,
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '0.9',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '1000'
  }

  if (isOpen) {
    return (
      <div style={{ ...contentSpinner, ...style }}>
        {children}
        <div style={bodyStyle}>
          <Spinner />
        </div>
      </div>
    )
  }
  return null
}

FullPage.defaultProps = {
  bgColor: colors.footerBg,
}

export default Spinner
export const FullPageSpinner = FullPage

