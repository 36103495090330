import { createReducer, createActions } from 'reduxsauce'
import {
  failureFeedback,
  initialFeedback,
  requestFeedback,
  successFeedback
} from './utils'

const INITIAL_STATE = {
  id: null,
  firstName: null,
  lastName: null,
  secondLastName: null,
  primaryTelephone: null,
  avatar: null,
  email: null,
  video: null,
  about: null,
  experience: null,
  areas: [],
  feedback: initialFeedback()
}

const { Types, Creators } = createActions(
  {
    getUserPrivateDetail: ['data'],
    getUserPrivateDetailSuccess: ['data'],
    getUserPrivateDetailFailure: ['errors'],
    allAreas: ['data'],
    areasSuccess: ['data'],
    areasFailure: ['errors'],
    clear: [],
  },
  { prefix: 'BIN_PROFILE_CARD_' }
)

const getUserPrivateDetail = (state, { data }) => ({
  ...state,
  feedback: requestFeedback()
})

const getUserPrivateDetailSuccess = (state, { data }) => ({
  ...state,
  ...data,
  feedback: successFeedback()
})

const getUserPrivateDetailFailure = (state, { errors }) => ({
  ...state,
  feedback: failureFeedback(errors)
})

const allAreas = (state, { data }) => ({
  ...state,
  feedback: requestFeedback()
})

const areasSuccess = (state, { data }) => ({
  ...state,
  areas: [...data],
  feedback: successFeedback()
})

const areasFailure = (state, { errors }) => ({
  ...state,
  feedback: failureFeedback(errors)
})

const clear = () => INITIAL_STATE

export const UserPrivateDetailActionTypes = Types

const HANDLERS = {
  [Types.GET_USER_PRIVATE_DETAIL]: getUserPrivateDetail,
  [Types.GET_USER_PRIVATE_DETAIL_SUCCESS]: getUserPrivateDetailSuccess,
  [Types.GET_USER_PRIVATE_DETAIL_FAILURE]: getUserPrivateDetailFailure,
  [Types.ALL_AREAS]: allAreas,
  [Types.AREAS_SUCCESS]: areasSuccess,
  [Types.AREAS_FAILURE]: areasFailure,
  [Types.CLEAR]: clear
}

export const UserPrivateDetailActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
