import ApiBase from 'app/services/api/entities/Base'

function* all(balanceId, params = {}, requestOptions = {}) {
  return yield ApiBase.privateGet(
    `/v1/accounts/monthly_financial_statements/${balanceId}/transactions/`,
    {
      ...requestOptions,
      params
    }
  )
}

export default {
  all
}
