import ApiBase from 'app/services/api/entities/Base'
import api from '../api'

const getRfc = (token) => {
  return api.privateGet(`v1/rfc/`, {token})
}

function* rfc() {
  return yield ApiBase.privateGet('/v1/rfc/')
}

export default {
  rfc,
  getRfc
}
