import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { store } from 'services'
import { ContactJourneyCreators } from 'app/reducers/ContactJourneyRedux.js'

import { mapObjToArrayValues } from './utils'

const useManagerContactSchedule = (initialTypeOfModal = 'confirm') => {
  const [makedSchedule, setMakedSchedule] = React.useState(false)
  const [typeOfModal, setTypeOfModal] = React.useState(initialTypeOfModal)

  const dispatch = useDispatch()

  const { makeScheduleRequest } = useSelector(state => ({
    makeScheduleRequest: store.makeSchedule('crmBinLead').selector(state)
  }))

  useEffect(() => {
    const { loading, finished, error, response } = makeScheduleRequest

    if (makedSchedule && (!loading && finished, !error)) {
      setTypeOfModal('success')
      dispatch(ContactJourneyCreators.sendContactJourneyEvent('contact-journey', 'make-meeting-finished-process'))
    }
  }, mapObjToArrayValues(makeScheduleRequest))

  const makeSchedule = (binLeadId, values) => {
    setMakedSchedule(true)

    dispatch(
      store.makeSchedule('crmBinLead').action(binLeadId, values)
    )
  }

  const sharedData = {
    typeOfModal,
    loading: makeScheduleRequest.loading,
    error: makeSchedule.error
  }

  return [sharedData, { makeSchedule, setTypeOfModal }]
}

export default useManagerContactSchedule
