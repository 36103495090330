import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {
  accepting: false
}

const { Types, Creators } = createActions({
  acceptLeadRequest: ['lead', 'data'],
  acceptLeadSuccess: ['data']
}, { prefix: 'ACCEPT_LEAD_' })

const acceptLeadRequest = (state, { lead }) => ({
  ...state,
  accepting: true
})

const acceptLeadSuccess = (state, { data}) => ({
  ...state,
  accepting: false
})

const HANDLERS = {
  [Types.ACCEPT_LEAD_REQUEST]: acceptLeadRequest,
  [Types.ACCEPT_LEAD_SUCCESS]: acceptLeadSuccess
}

export const AcceptLeadTypes = Types
export const AcceptLeadActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)


