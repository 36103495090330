import React, { PureComponent } from 'react'
import css from 'styled-jsx/css'
import Link from 'next/link'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Spinner from 'controls/Spinner'
import { colors } from 'theme'
import styles from './styles'
import variants from './buttonStyles'

class Button extends PureComponent {
  static propTypes = {
    color: PropTypes.oneOf([
      'none',
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'tertiary',
      'facebook'
    ]),
    style: PropTypes.object,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    fullWidth: PropTypes.bool,
    outline: PropTypes.bool,
    variation: PropTypes.bool,
    onClick: PropTypes.func,
    styleType: PropTypes.oneOf(['normal', 'rounded', 'circle']),
    type: PropTypes.oneOf(['button', 'submit']),
    size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large'])
  }

  static defaultProps = {
    className: '',
    color: 'none',
    outline: false,
    variation: false,
    fullWidth: false,
    size: 'medium',
    type: 'button',
    style: {},
    loading: false
  }

  onClick = (e = {}) => {
    if (this.props.disabled) {
      if (typeof e.preventDefault === 'function') {
        e.preventDefault()
      }
      return false
    }
    if (this.props.onClick) {
      this.props.onClick(e)
    }
  }

  buttonRender = () => {
    const {
      color,
      size,
      disabled,
      variation,
      outline,
      type,
      fullWidth,
      bold,
      styleType,
      loading,
      ...rest
    } = this.props
    // require only the style we want to use for this instance
    const colorStyle = outline ? variants[`${color}Outline`] : variants[color]

    return (
      <button
        {...rest}
        style={this.props.style}
        className={classnames(
          'button',
          { bold },
          size,
          styleType,
          { disabled: disabled || loading, fullWidth, variation },
          this.props.color,
          { outline },
          this.props.className
        )}
        onClick={this.onClick}
        type={type}
        disabled={disabled || loading}
      >
        {
          !loading
            ? this.props.children
            : <Spinner size={25} thickness={3} color={outline ? colors.grey : colors.white} />
        }
        <style jsx>{styles}</style>
        <style jsx>{colorStyle}</style>
      </button>
    )
  }

  render() {
    const { href, as, target } = this.props

    if (href && target) {
      return (
        <a href={href} target={target}>
          {this.buttonRender()}
        </a>
      )
    }

    if (href) {
      return (
        <Link href={href} as={as}>
          <a>{this.buttonRender()}</a>
        </Link>
      )
    }

    return this.buttonRender()
  }
}

export default Button
