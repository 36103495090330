import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { store } from 'services'

import { mapObjToArrayValues } from './utils'

const useManagerOfferModal = () => {
  const [typeOfModal, setTypeOfModal] = React.useState('offer')
  const [makedOffer, setmakedOffer] = React.useState(false)
  const [sendedCallMen, setSendedCallMen] = React.useState(false)

  const dispatch = useDispatch()

  const { makeOfferRequest, contactMeRequest } = useSelector(state => ({
    makeOfferRequest: store.makeOffer('crmOffer').selector(state),
    contactMeRequest: store.contactMe('crmBinLead').selector(state)
  }))

  useEffect(() => {
    const { loading, finished, error, response } = makeOfferRequest

    if (makedOffer && (!loading && finished, !error && response)) {
      setTypeOfModal('paymentInfo')
    }
    if (makedOffer && (!loading && finished, error)) {
    }
  }, mapObjToArrayValues(makeOfferRequest))

  useEffect(() => {
    const { loading, finished, error, response } = contactMeRequest

    if (sendedCallMen && (!loading && finished, !error && response)) {
      setTypeOfModal('success')
    }
  }, mapObjToArrayValues(contactMeRequest))

  const handleSubmitOffer = (binLeadId, values) => {
    dispatch(store.makeOffer('crmOffer').action(binLeadId, values))

    setmakedOffer(true)
  }

  const handleContactMe = (binLeadId, { contactMe, timeToContact }) => {
    const offer = makeOfferRequest.response.id

    dispatch(
      store.contactMe('crmBinLead').action(binLeadId, {
        offer,
        call_me: contactMe,
        horary: timeToContact
      })
    )
    setSendedCallMen(true)

    if(!contactMe) {
      setTypeOfModal('successWithoutContactMe')
    }
  }

  const sharedData = {
    typeOfModal,
    offerLoading: makeOfferRequest.loading,
    contactMeLoading: contactMeRequest.loading
  }

  return [sharedData, { handleSubmitOffer, handleContactMe, setTypeOfModal }]
}

export default useManagerOfferModal
