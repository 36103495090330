import { USER_STATUS_DICT as US } from 'services/seo'

/**
 * ORIGINAL ERRORS TEMPLATES
 *    inactiveUser:
 *      'user with status {user_status} cannot profit from MLM',
 *    insufficientScope:
 *      '{level_name} with scope {level_scope} is not enough to profit from distance {distance}'
 */

function inactiveUser({ userStatus }) {
  const status = US[userStatus] || userStatus

  return `Usuarios con estatus '${status}' no pueden ganar comisión de la red`.trim()
}

function insufficientScope({ levelName, levelScope, distance }) {
  if (levelScope === 0) {
    return `Nivel ${levelName.toUpperCase()} no puede ganar comisiones de la red`
  }

  return `Nivel ${levelName.toUpperCase()} no puede ganar comisión de ahijados de nivel ${distance}`
}

const TRANSLATED_ERRORS = {
  inactiveUser,
  insufficientScope
}

const getInactiveUserValues = error => {
  const [userStatus] = error
    .split('user with status')[1]
    ?.split('cannot profit from MLM')

  if (!userStatus) {
    return null
  }

  return { userStatus: userStatus.trim() }
}

const getInsufficientScopeValues = error => {
  const [levelName, restError] = error.split('with scope')
  const [levelScope, distance] = restError.match(/(\d+)/g) || []

  if (!levelScope) {
    return null
  }

  return {
    levelName: levelName.trim(),
    levelScope: Number(levelScope),
    distance: Number(distance)
  }
}

/**
 * @function translateQuotaError
 * @param {String} error - One of "ORIGINAL ERRORS TEMPLATES" cases
 * @returns {String}
 */
export const translateQuotaError = error => {
  if (!error) {
    return null
  }

  if (error.includes('user with status')) {
    const values = getInactiveUserValues(error)

    if (!values) {
      return error
    }

    return TRANSLATED_ERRORS.inactiveUser(values)
  }

  if (error.includes('with scope')) {
    const values = getInsufficientScopeValues(error)
    if (!values) {
      return error
    }

    return TRANSLATED_ERRORS.insufficientScope(values)
  }

  return error
}
