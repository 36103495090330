import crudAction from './actions'

export default {
  name: 'fiscalData',
  namespace: 'fiscal_data',
  methods: [
    {
      name: 'filter',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, }) => `/v1/${namespace}/`,
      urlParams: []
    },
    {
      name: 'patch',
      params: ['data'],
      successCode: 200,
      method: 'Patch',
      url: ({ namespace, id }) => `/v1/${namespace}/`,
      urlParams: []
    },
  ]
}