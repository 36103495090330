export default {
  name: 'crmOffer',
  namespace: 'crm/offer',
  methods: [
    {
      name: 'makeOffer',
      params: ['data'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace }) => `/v1/${namespace}/`,
      urlParams: ['id']
    }
  ]
}
