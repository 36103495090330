import css from 'styled-jsx/css'
import { colors, fontSizes } from 'theme'

export default css.global`
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    content: '';
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: ${colors.borderInput};
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: ${colors.lightBg};
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: ${colors.borderInput};
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: ${colors.white};
  }

  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: ${colors.borderInput};
  }

  .react-datepicker-wrapper {
    display: inline-block;
  }

  .react-datepicker {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: ${colors.white};
    color: ${colors.black};
    border: 1px solid ${colors.borderInput};
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
  }

  .react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
  }

  .react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
  }

  .react-datepicker--time-only .react-datepicker__time {
    border-radius: 0.3rem;
  }

  .react-datepicker--time-only .react-datepicker__time-box {
    border-radius: 0.3rem;
  }

  .react-datepicker__triangle {
    position: absolute;
    left: 50px;
  }

  .react-datepicker-popper {
    z-index: 1;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 10px;
  }

  .react-datepicker-popper[data-placement^='top'] {
    margin-bottom: 10px;
  }

  .react-datepicker-popper[data-placement^='right'] {
    margin-left: 8px;
  }

  .react-datepicker-popper[data-placement^='right']
    .react-datepicker__triangle {
    left: auto;
    right: 42px;
  }

  .react-datepicker-popper[data-placement^='left'] {
    margin-right: 8px;
  }

  .react-datepicker-popper[data-placement^='left'] .react-datepicker__triangle {
    left: 42px;
    right: auto;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: ${colors.lightBg};
    border-bottom: 1px solid ${colors.borderInput};
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
  }

  .react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__month-year-dropdown-container--select,
  .react-datepicker__year-dropdown-container--scroll,
  .react-datepicker__month-dropdown-container--scroll,
  .react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header {
    margin-top: 0;
    color: ${colors.black};
    font-weight: bold;
    font-size: 0.944rem;
  }

  .react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
  }

  .react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: ${colors.lightDullGray};
  }

  .react-datepicker__navigation--previous:hover {
    border-right-color: ${colors.boxShadow};
  }

  .react-datepicker__navigation--previous--disabled,
  .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: ${colors.dullGray};
    cursor: default;
  }

  .react-datepicker__navigation--next {
    right: 10px;
    border-left-color: ${colors.lightDullGray};
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 80px;
  }

  .react-datepicker__navigation--next:hover {
    border-left-color: ${colors.boxShadow};
  }

  .react-datepicker__navigation--next--disabled,
  .react-datepicker__navigation--next--disabled:hover {
    border-left-color: ${colors.dullGray};
    cursor: default;
  }

  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: ${colors.lightDullGray};
  }

  .react-datepicker__navigation--years-previous:hover {
    border-top-color: ${colors.boxShadow};
  }

  .react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: ${colors.lightDullGray};
  }

  .react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: ${colors.boxShadow};
  }

  .react-datepicker__month-container {
    float: left;
  }

  .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
  }

  .react-datepicker__time-container {
    float: right;
    border-left: 1px solid ${colors.borderInput};
    width: 70px;
  }

  .react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid ${colors.borderInput};
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0;
  }

  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 70px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 0px;
    padding-left: 0px;
    width: 100%;
    box-sizing: content-box;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: ${colors.lightBg};
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${colors.highlightBlue};
    color: white;
    font-weight: bold;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: ${colors.highlightBlue};
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    color: ${colors.lightDullGray};
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent;
  }

  .react-datepicker__week-number {
    color: ${colors.lightDullGray};
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }

  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
  }

  .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 0.3rem;
    background-color: ${colors.lightBg};
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    white-space: nowrap;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${colors.black};
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }

  .react-datepicker__day {
    cursor: pointer;
  }

  .react-datepicker__day:hover {
    border-radius: 0.3rem;
    background-color: ${colors.lightBg};
  }

  .react-datepicker__day--today {
    font-weight: bold;
  }

  .react-datepicker__day--highlighted {
    border-radius: 0.3rem;
    background-color: ${colors.limeGreen};
    color: ${colors.white};
  }

  .react-datepicker__day--highlighted:hover {
    background-color: ${colors.pickleGreen};
  }

  .react-datepicker__day--highlighted-custom-1 {
    color: magenta;
  }

  .react-datepicker__day--highlighted-custom-2 {
    color: green;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    border-radius: 0.3rem;
    background-color: ${colors.highlightBlue};
    color: ${colors.white};
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover {
    background-color: ${colors.text};
  }

  .react-datepicker__day--keyboard-selected {
    border-radius: 0.3rem;
    background-color: ${colors.textBlueBright};
    color: ${colors.white};
  }

  .react-datepicker__day--keyboard-selected:hover {
    background-color: ${colors.text};
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: rgba(33, 107, 165, 0.5);
  }

  .react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
    background-color: ${colors.lightBg};
    color: ${colors.black};
  }

  .react-datepicker__day--disabled {
    cursor: default;
    color: ${colors.lightDullGray};
  }

  .react-datepicker__day--disabled:hover {
    background-color: transparent;
  }

  .react-datepicker__input-container {
    position: relative;
    display: inline-block;
  }

  .react-datepicker__year-read-view,
  .react-datepicker__month-read-view,
  .react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
  }

  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    cursor: pointer;
  }

  .react-datepicker__year-read-view:hover
    .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__year-read-view:hover
    .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-read-view:hover
    .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view:hover
    .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view:hover
    .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-year-read-view:hover
    .react-datepicker__month-read-view--down-arrow {
    border-top-color: ${colors.boxShadow};
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    border-top-color: ${colors.lightDullGray};
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 0.45rem;
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    background-color: ${colors.lightBg};
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid ${colors.borderInput};
  }

  .react-datepicker__year-dropdown:hover,
  .react-datepicker__month-dropdown:hover,
  .react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
  }

  .react-datepicker__year-dropdown--scrollable,
  .react-datepicker__month-dropdown--scrollable,
  .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
  }

  .react-datepicker__year-option,
  .react-datepicker__month-option,
  .react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }

  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }

  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: ${colors.lightDullGray};
  }

  .react-datepicker__year-option:hover
    .react-datepicker__navigation--years-upcoming,
  .react-datepicker__month-option:hover
    .react-datepicker__navigation--years-upcoming,
  .react-datepicker__month-year-option:hover
    .react-datepicker__navigation--years-upcoming {
    border-bottom-color: ${colors.boxShadow};
  }

  .react-datepicker__year-option:hover
    .react-datepicker__navigation--years-previous,
  .react-datepicker__month-option:hover
    .react-datepicker__navigation--years-previous,
  .react-datepicker__month-year-option:hover
    .react-datepicker__navigation--years-previous {
    border-top-color: ${colors.boxShadow};
  }

  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected,
  .react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px;
  }

  .react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 0;
    vertical-align: middle;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 25%;
    right: 7px;
  }

  .react-datepicker__close-icon::after {
    background-color: ${colors.highlightBlue};
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: ${colors.white};
    content: '×';
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 0px;
    text-align: center;
  }

  .react-datepicker__today-button {
    background: ${colors.lightBg};
    border-top: 1px solid ${colors.borderInput};
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
  }

  .react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
  }

  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
  }

  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }
  }

  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
  }

  .react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent;
  }

  .react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: ${colors.lightDullGray};
  }

  .react-datepicker__portal .react-datepicker__navigation--previous:hover {
    border-right-color: ${colors.boxShadow};
  }

  .react-datepicker__portal .react-datepicker__navigation--previous--disabled,
  .react-datepicker__portal
    .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: ${colors.dullGray};
    cursor: default;
  }

  .react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: ${colors.lightDullGray};
  }

  .react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: ${colors.boxShadow};
  }

  .react-datepicker__portal .react-datepicker__navigation--next--disabled,
  .react-datepicker__portal
    .react-datepicker__navigation--next--disabled:hover {
    border-left-color: ${colors.dullGray};
    cursor: default;
  }

  /* Custom styles */

  .react-datepicker-wrapper,
  .react-datepicker-wrapper .react-datepicker__input-container,
  .react-datepicker-wrapper .react-datepicker__input-container .fl-input-container,
  .react-datepicker-wrapper .react-datepicker__input-container .fl-input-label
  {
    width: 100%;
  }

  .date-container {
    position: relative;
  }
  .date-container .react-datepicker-popper {
    z-index: 10;
  }
  .date-container.date-error :global(.fl-input-container input) {
    border: 1px solid ${colors.danger};
  }
  .date-container .date-invalid-message {
    color: ${colors.danger};
    position: absolute;
    right: 0.5rem;
    bottom: 3px;
    width: 90%;
    text-align: end;
    font-size: ${fontSizes.tiny};
    pointer-events: none;
  }

  .react-datepicker__triangle {
    left: 50%;
  }

  .date-container.as-field-form-centered,
  .date-container.as-field-form-centered .react-datepicker-wrapper,
  .date-container.as-field-form-centered .react-datepicker__input-container,
  .date-container.as-field-form-centered .fl-input-container {
    height: 100%;
  }

  .date-container.as-field-form-centered .fl-input-container {
    display: flex;
    justify-content: space-between;
  }
  .date-container.as-field-form-centered .fl-input-container input.fl-input {
    margin-bottom: 1rem;
  }
`

export const datePickerCustomHeaderStyles = css`
  .custom-date-picker--container {
    display: flex;
    justify-content: center;
  }
  :global(.custom-date-picker--container .select-container) {
    margin-top: 0;
  }
  :global(.custom-date-picker--container .select-container) {
    margin: 0 0.3rem;
  }
  :global(.custom-date-picker--container
      .select-container
      select.custom-date-picker--simple-dropdown-select.month-dropdown) {
    width: 6rem;
  }
  :global(.custom-date-picker--container
      .select-container
      select.custom-date-picker--simple-dropdown-select) {
    padding-left: 0;
  }
  :global(.custom-date-picker--container .navigation-buttton i) {
    margin: 0 0.3rem;
  }
`