import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = [];

const { Types, Creators } = createActions({
  show: ['opts'],
  hide: ['uid'],
  success: ['opts'],
  error: ['opts'],
  warning: ['opts'],
  info: ['opts'],
}, { prefix: 'ALERT_' });

const show = (level = 'success') => (state, { opts }) => [
  ...state,
  {
    ...opts,
    uid: opts.uid || Date.now(),
    level: opts.level || level,
    position: opts.position || 'tc'
  }
];
const hide = (state, { uid }) => state.filter(n => n.uid !== uid);

const HANDLERS = {
  [Types.SHOW]: show(),
  [Types.HIDE]: hide,
  [Types.SUCCESS]: show('success'),
  [Types.ERROR]: show('error'),
  [Types.WARNING]: show('warning'),
  [Types.INFO]: show('info')
};


export const AlertActionTypes = Types;

export const AlertActionCreators = Creators;

export default createReducer(INITIAL_STATE, HANDLERS);

