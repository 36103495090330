import { colors, fonts } from 'theme'
import css from 'styled-jsx/css'

export default css.global`
  .geo-container {
    display: flex;
    flex-direction: column;
    padding: 0 0.875rem;
    height: 3.625rem;
    border: 1px solid ${colors.borderInput};
    border-radius: 4px;
    position: relative;

    transition: 120ms border-color;
  }

  .geo-container input {
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    border-radius: 0;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font: ${fonts.medium};
    color: ${colors.secondary};
    background-color: ${colors.transparent};
    font-size: 1rem;
    line-height: 25px;
    font: ${fonts.medium};
    border: none;
    margin: 1.1rem 0;
    text-shadow: none;
    width: 100%;
    outline: none;
  }

  .geosuggest__suggests-wrapper {
    z-index: 1;
    width: calc(100% + 1.9rem);
    margin: -0.95rem;
    margin-top: -2px;
    position: relative;
  }
  .geosuggest__suggests {
    background-color: white;
    border: 1px solid gray;
    z-index: 1;
    border-radius: 4px;
    /*width: calc(100% + 1.89rem);*/
    width: 100%;
    /*margin-left: -0.95rem;*/
  }

  .geosuggest__suggests.geosuggest__suggests--hidden {
    display: none;
  }

  .geosuggest__item {
    list-style-type: none;
    padding: 1rem;
  }

  .geosuggest__suggests {
    padding: 0;
  }

  .geosuggest__item.geosuggest__item--active {
    background-color: ${colors.primary};
  }
`
