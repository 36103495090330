import { call, put, select } from 'redux-saga/effects'
import { BondActionCreators } from 'app/reducers/BondRedux'
import api from 'app/services/api'

export function* getBonds() {
  const token = yield select(state => state.auth.token)
  const response = yield call(api.bond.getBonds, token)

  if (response.status === 200) {
    return yield put(BondActionCreators.getBondsSuccess(response.data))
  }

  yield put(BondActionCreators.getBondsFailure(response.data))
}

export function* getBond({ id }) {
  const token = yield select(state => state.auth.token)
  const response = yield call(api.bond.getBondById, token, id)

  if (response.status === 200) {
    return yield put(BondActionCreators.getBondSuccess(response.data))
  }

  yield put(BondActionCreators.getBondFailure(response.data))
}

export function* getBondCompleted({ id }) {
  const token = yield select(state => state.auth.token)
  const response = yield call(api.bond.getBondById, token, id)

  if (response.status === 200) {
    return yield put(BondActionCreators.getBondCompletedSuccess(response.data))
  }

  yield put(BondActionCreators.getBondCompletedFailure(response.data))
}

export function* createActor({ bondId, fullName, email, role }) {
  const token = yield select(state => state.auth.token)

  const response = yield call(api.bond.createActor, token, bondId, fullName, email, role)

  if (response.status === 201) {
    return yield put(BondActionCreators.createActorSuccess(bondId, response.data))
  }

  yield put(BondActionCreators.createActorFailure(response.data))
}

export function* deleteActor({ bondId, actorId }) {
  const token = yield select(state => state.auth.token)

  const response = yield call(api.bond.deleteActor, token, actorId)

  if (response.status === 204) {
    return yield put(BondActionCreators.deleteActorSuccess(bondId, actorId))
  }

  return yield put(BondActionCreators.deleteActorFailure(bondId, actorId, response.data))
}

export function* createTask({ bondId, description, actorId }) {
  const token = yield select(state => state.auth.token)

  const response = yield call(api.bond.createTask, token, bondId, description, actorId)

  if (response.status === 201) {
    return yield put(BondActionCreators.createTaskSuccess(bondId, response.data))
  }

  yield put(BondActionCreators.createTaskFailure(response.data))
}


export function* updateTask({ bondId, taskId, data }) {
  const token = yield select(state => state.auth.token)

  const response = yield call(api.bond.updateTask, token, taskId, data)

  if (response.status === 200) {
    return yield put(BondActionCreators.updateTaskSuccess(bondId, response.data))
  }

  yield put(BondActionCreators.updateTaskFailure(response.data))
}

export function* deleteTask({ taskId }) {
  const token = yield select(state => state.auth.token)

  const response = yield call(api.bond.deleteTask, token, taskId)

  if (response.status === 204) {
    return yield put(BondActionCreators.deleteTaskSuccess(bondId, taskId))
  }

  yield put(BondActionCreators.deleteTaskFailure(response.data))
}
