import React from 'react'
import { useRouter } from 'next/router'

import Link from 'next/link'

const getAsPathname = as => {
  if (typeof as === 'object') {
    return as.pathname
  }

  return as
}

const SEOLink = ({ as, href, children, useNext=true, ...rest }) => {
  const { asPath } = useRouter()

  if (getAsPathname(as) === asPath) {
    return React.Children.map(children, child =>
      React.createElement('span', child.props, child.props.children)
    )
  }

  if (useNext) {
    return (
      <Link as={as} href={href} {...rest}>
        {children}
      </Link>
    )
  }


  return (
    <a href={href} {...rest}>
      {children}
    </a>
  )
}

export default SEOLink
