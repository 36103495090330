import React from 'react'
import classNames from 'classnames'

import { CircleProgress } from 'controls'
import { circleProgressBoxStyles } from './style'
import { colors } from 'theme'

// This persentage is selected to avoid that circle progress be closed
const PERCENTAGE_PROGRESS = 85

const RotateCircleProgress = ({ progress, disabled, size, ...rest }) => (
  <div className={classNames('circle-progress-container', { disabled })}>
    <CircleProgress
      percent={progress}
      lineWidth={6}
      strokeLinecap="round"
      size={size || 120}
      {...rest}
    />
    <style jsx>{circleProgressBoxStyles}</style>
  </div>
)

const CircleProgressBox = ({
  progress,
  children,
  size,
  circleBgColor = 'light-gray'
}) => {
  const realProgress = (progress * PERCENTAGE_PROGRESS) / 100

  return (
    <div className="circle-progress-box-container">
      <RotateCircleProgress
        size={size}
        percent={realProgress}
        color={colors.primary}
      />
      <RotateCircleProgress
        disabled
        size={size}
        color={colors.lightGray}
        percent={PERCENTAGE_PROGRESS}
      />
      <span
        className={classNames('circle-progress-box-content', circleBgColor)}
      >
        {children}
      </span>
      <style jsx>{circleProgressBoxStyles}</style>
    </div>
  )
}

export default CircleProgressBox
