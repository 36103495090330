import api from '../api'

const getAlerts = (token, params) =>
  api.privateGet('/v1/crm/bin_lead_requests/', { token, params })

const createAlert = (token, data) =>
  api.privatePost('/v1/crm/bin_lead_requests/', { token, data })

const updateAlert = (token, binLeadRequestId, data) =>
  api.privatePatch(`/v1/crm/bin_lead_requests/${binLeadRequestId}/`, {
    token,
    data
  })

export default {
  getAlerts,
  createAlert,
  updateAlert
}
