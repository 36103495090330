import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {
  addingOrEditingCustomEvent: false,
  event: null,
  slotInfo: null,
  eventType: null
}

const setAddingOrEditingCustomEvent = (state, { value }) => ({
  ...state,
  addingOrEditingCustomEvent: value
})

const setEvent = (state, { event }) => ({
  ...state,
  event: event
})

const setSlotInfo = (state, { slotInfo }) => ({
  ...state,
  slotInfo: slotInfo
})

const setEventType = (state, { eventType }) => ({
  ...state,
  eventType: eventType
})

const { Types, Creators } = createActions(
  {
    setAddingOrEditingCustomEvent: ['value'],
    setEvent: ['event'],
    setSlotInfo: ['slotInfo'],
    setEventType: ['eventType']
  },
  { prefix: 'CUSTOM_EVENT_' }
)

const HANDLERS = {
  [Types.SET_ADDING_OR_EDITING_CUSTOM_EVENT]: setAddingOrEditingCustomEvent,
  [Types.SET_EVENT]: setEvent,
  [Types.SET_SLOT_INFO]: setSlotInfo,
  [Types.SET_EVENT_TYPE]: setEventType
}

export const CustomEventActionTypes = Types

export const CustomEventActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
