import React from 'react'

import Input from 'controls/Input'
import { CURRENCY_TYPES } from 'services/seo'
import { SimpleDropDown } from 'controls/DropDown'
import { optionsForSelect } from 'components/utils'

import { priceInputStyles } from './styles'

const DEFAULT_VALUE = {
  amount: 0,
  currency: 'MXN'
}

const PriceInput = ({ value, onChange, ...rest }) => {
  const { amount, currency } = value || DEFAULT_VALUE

  function handleChange(newValue) {
    onChange?.({ amount, currency, ...newValue })
  }

  const formatedValue =
    Number(amount) > 0 ? Intl.NumberFormat().format(amount) : 0

  return (
    <div className="input-price-container">
      <Input
        {...rest}
        value={formatedValue}
        label="Precio"
        name="amount"
        className="input-price-amount form-input-margin"
        onChange={e =>
          handleChange({
            amount:
              Number(e.target.value.replace('$', '').replace(/,/g, '')) || 0
          })
        }
      />
      <SimpleDropDown
        value={currency}
        label="Moneda"
        name="currency"
        className="input-price-currency form-input-margin"
        placeholder="Selecciona la moneda"
        onChange={e => handleChange({ currency: e.target.value })}
      >
        {optionsForSelect(CURRENCY_TYPES)}
      </SimpleDropDown>

      <style jsx>{priceInputStyles}</style>
    </div>
  )
}

export default PriceInput
