export default {
  name: 'accounts',
  namespace: 'accounts',
  methods: [
    {
      name: 'monthlyFinancialStatements',
      params: ['limit'],
      successCode: 200,
      method: 'Get',
      url: () => '/v1/accounts/monthly_financial_statements/',
      urlParams: ['limit']
    },
    {
      name: 'updateInvoice',
      params: ['balanceId', 'data'],
      successCode: 200,
      method: 'Patch',
      url: ({ balanceId }) => `/v1/accounts/monthly_financial_statements/${balanceId}/`,
      urlParams: ['balanceId']
    },
    {
      name: 'latestMonthlyFinancialStatements',
      params: [],
      successCode: 200,
      method: 'Get',
      url: () => '/v1/accounts/monthly_financial_statements/latest/',
      urlParams: []
    },
    {
      name: 'monthlyTransactions',
      params: ['monthlyFinancialStatementId'],
      successCode: 200,
      method: 'Get',
      url: ({ monthlyFinancialStatementId }) =>
        `/v1/accounts/monthly_financial_statements/${monthlyFinancialStatementId}/transactions/`,
      urlParams: ['monthlyFinancialStatementId']
    }
  ]
}
