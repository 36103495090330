import { createSelector } from 'reselect'
import { initialFeedback } from 'reducers/utils'
import { DISTRICTS_I24_ON_DEMAND } from 'app/constants/propertyHighlight'

/**
 * @typedef {Object} RawPlan
 * @description Raw plan from API - It doesn't have an id
 * @property {String} portal - Portal name
 * @property {String} plan - Plan name
 * @property {Number} duration - Duration in days
 * @returns 
 */
export function getPlanId(rawPlan) {
  const { portal, plan, duration } = rawPlan
  return`${portal}---${plan}---${duration}`
}

/**
 * @typedef {Object} Plan
 * @property {Number} id - Plan id - getPlanId instance
 * @property {String} plan - Plan name - Use as label
 * @property {Number} stock - Stock
 * @property {Number} duration - Duration in days
 * @property {Object} price - { currency: String, amount: Number }
 */

/**
 * @typedef {Object} PropertyOrder
 * @property {Number} highlightOrder - Order id
 * @property {String} plan - Plan name - Use as label
 * @property {Number} property - Property id
 * @property {Number} site - Site id
 * @property {String} siteName - Site name
 * @property {String} orderStatus - Order status
 * @property {String} orderPaidAt - Order paid date
 * @property {String} source - Source = <NEXIMER, NEXIMO> - { PROPERTY_HIGHLIGHT_AUTHOR } from 'constants/propertyHighlight'
 */

/**
 * @returns {Object.<string, Plan[]>}
 * @example { portalName: Plan[] }
 */
export function formatPlans(plansRaw) {
  /**
   * Selectors for property highlight
   * @example
     plansSelector([
       {
         duration: 30,
        price: { amount: 2000.0, currency: 'MXN' },
        portal: 'mercadolibre',
        stock: 50,
        plan: 'gold_premiun',
      }
    ])
    >>>
    {
        "mercadolibre": [
            {
                "id": "mercadolibre---gold_premiun---30", // Check getPlanId
                "portal": "mercadolibre",
                "plan": "gold_premiun",
                "duration": 30,
                "price": {
                    "amount": 2000,
                    "currency": "MXN"
                },
                "stock": 50
            },
            {
                "id": "mercadolibre---gold---30", // Check getPlanId
                "portal": "mercadolibre",
                "plan": "gold",
                "duration": 30,
                "price": {
                    "amount": 1500,
                    "currency": "MXN"
                },
                "stock": 100
            }
        ]
    }
  */
  const plans = {}

  for (const plan of plansRaw) {
    const { portal, plan: planName, ...rest } = plan
    const id = getPlanId(plan)

    if (!plans[portal]) {
      plans[portal] = []
    }

    plans[portal].push({ id, portal, plan: planName, ...rest })
  }

  return plans
}

/**
 * @returns {Object.<string, Plan[]>}
 * @example { portalName: Plan[] }
 */
export const plansSelector = state => state.propertyHighlight.plans

/**
 * @returns {Object.<string, PropertyOrder[]>}
 * @example { propertyId: PropertyOrder[] }
 */
export const propertyOrdersSelector = state =>
  state.propertyHighlight.propertyOrders

/**
 * @returns {Object.<String|Number, String[]>}
 * @example { 4951: ["portalName---planName---duration"] }
 */
export const shoppingCartSelector = state =>
  state.propertyHighlight.shoppingCart

export const purchaseOrderSelector = state =>
  state.propertyHighlight.purchaseOrder

export const validatedPropertiesShoppingCartSelector = state =>
  state.propertyHighlight.validatedPropertiesShoppingCart

export const propertyHasBeenValidatedSelector = createSelector(
  validatedPropertiesShoppingCartSelector,
  (state, propertyId) => propertyId,
  (validatedPropertiesShoppingCart, propertyId) =>
    validatedPropertiesShoppingCart.includes(propertyId),
)

export const shoppingCartCountSelector = createSelector(
  shoppingCartSelector,
  shoppingCart => Object.keys(shoppingCart).length,
)

export const shoppingCartDetailSelector = createSelector(
  shoppingCartSelector,
  plansSelector,
  (shoppingCart, plans) => {
    const properties = Object.keys(shoppingCart).map(propertyId => {
      const propertyPlans = shoppingCart[propertyId].planIds.map(planId => {
        const [portalName, planName, duration] = planId.split('---')
        return plans[portalName].find(plan => plan.id === planId)
      })

      return {
        propertyId,
        propertyPlans,
        firstImage: shoppingCart[propertyId].firstImage,
        isValidToHighlight: shoppingCart[propertyId].isValidToHighlight,
      }
    })

    return properties
  },
)

/**
 * @returns {Plan[]} - with propertyId and firstImage
 */
export const shoppingCartPlansDetailSelector = createSelector(
  shoppingCartDetailSelector,
  shoppingCart =>
    shoppingCart
      .map(({ propertyId, firstImage, propertyPlans, isValidToHighlight }) =>
        propertyPlans.map(plan => ({
          propertyId,
          firstImage,
          isValidToHighlight,
          ...plan,
        })),
      )
      .flat(), // TODO: Remove flat, instead use for of
)

/**
 * @returns {{ portal: string, plansCount: number, total: number }[]}
 */
export const shoppingCheckoutDetailSelector = createSelector(
  shoppingCartPlansDetailSelector,
  propertyPlans => {
    return propertyPlans.reduce((acc, { portal, price }) => {
      const portalIndex = acc.findIndex(item => item.portal === portal)

      if (portalIndex === -1) {
        acc.push({ portal, plansCount: 1, total: price.amount })
      } else {
        acc[portalIndex].plansCount++
        acc[portalIndex].total += price.amount
      }

      return acc
    }, [])
  },
)

/**
 * Use it to populate the form with the plans that are already in the shopping cart
 * @returns {Object.<string, string>}
 * @example
 *    {
 *       portalName:        "portalName---planName---duration",
 *       anotherPortalName: "portalName---planName---duration",
 *    }
 */
export const shoppingModalAddedPlansSelector = createSelector(
  shoppingCartSelector,
  (state, propertyId) => propertyId,
  (shoppingCart, propertyId) => {
    const propertyShoppingItem = shoppingCart[propertyId]

    if (!propertyShoppingItem) {
      return {}
    }

    const plans = {}

    for (const addedPlan of propertyShoppingItem.planIds) {
      const [portalName, planName, duration] = addedPlan.split('---')
      plans[portalName] = addedPlan
    }

    return plans
  },
)

export const shoppingCartTotalSelector = createSelector(
  shoppingCartPlansDetailSelector,
  propertyPlans =>
    propertyPlans.reduce((acc, { price }) => acc + price.amount, 0),
)

export const confirmOrderFeedbackSelector = state =>
  state.propertyHighlight.confirmOrderFeedback

/**
 * @typedef {Object} OrderItem
 * @property {Number} property - Property id
 * @property {Object} price - { currency: String, amount: Number }
 * @property {String} portal - Portal name
 * @property {String} plan - Plan name
 * @property {Number} duration - Plan duration
 * @property {String} durationMeasure - Duration measure (DAYS | LEADS)
 * 
 * @example
 *      {
            "property": 5961,
            "price":
            {
                "amount": 2000,
                "currency": "MXN"
            },
            "portal": "mercadolibre",
            "plan": "gold_premiun",
            "duration": 30,
            "durationMeasure": "DAYS"
        }
 */

/**
 * @returns {OrderItem[]}
 */
export const createOrderSelector = createSelector(
  shoppingCartSelector,
  plansSelector,
  (shoppingCart, plans) => {
    const order = []

    for (const propertyId of Object.keys(shoppingCart)) {
      for (const planId of shoppingCart[propertyId].planIds) {
        const [portalName, planName, duration] = planId.split('---')
        const plan = plans[portalName].find(plan => plan.id === planId)

        order.push({
          property: propertyId,
          price: plan.price,
          portal: plan.portal,
          plan: plan.plan,
          duration: plan.duration,
          durationMeasure: plan.durationMeasure
        })
      }
    }

    return order
  },
)

/**
 * @function areTheSamePlan
 * @param {Plan} source
 * @param {Plan} target
 */
function areTheSameAndHighlightedPlans(source, target) {
  return (
    source.isHighlight &&
    !source.terminatedAt &&
    String(source.siteName).toLowerCase() ===
      String(target.portal).toLowerCase() &&
    source.plan == target.plan
    // TODO: Add duration
  )
}

export const shoppingDetailModalSelector = state =>
  state.propertyHighlight.shoppingDetailModal

const isInI24DistrictOnDemand = (district) => {
  return DISTRICTS_I24_ON_DEMAND.includes(district)
}

export const propertyDisabledPlansSelector = createSelector(
  propertyOrdersSelector,
  plansSelector,
  shoppingDetailModalSelector,
  (state, propertyId) => propertyId,
  (propertyOrders, plans, shoppingDetailModal, propertyId ) => {
    const disabledPlans = {}
    for (const portalName of Object.keys(plans)) {
      if (!propertyOrders[propertyId]) {
        continue
      }
      if (
        portalName == "inmuebles24" && 
        isInI24DistrictOnDemand(shoppingDetailModal.district)
      ) {
        continue
      }

      disabledPlans[portalName] = []

      for (const plan of plans[portalName]) {
        const matchingPlan = propertyOrders[propertyId].find(pPlan =>
          areTheSameAndHighlightedPlans(pPlan, plan),
        )
        disabledPlans[portalName].push({
          ...plan,
          disabled: Boolean(matchingPlan),
        })
      }
    }
    return disabledPlans
  },
)

export const isPropertyAlreadyAddedSelector = createSelector(
  shoppingCartSelector,
  (state, propertyId) => propertyId,
  (shoppingCart, propertyId) => Boolean(shoppingCart[propertyId]),
)

export const allShoppingCartItemsAreValidSelector = createSelector(
  shoppingCartSelector,
  (shoppingCart) => {
    for (const propertyId of Object.keys(shoppingCart)) {
      if (!shoppingCart[propertyId].isValidToHighlight) {
        return false
      }
    }

    return true
  }
)


export const propertyOrdersFeedbackSlector = state =>
  state.propertyHighlight.propertyOrdersFeedback

export const plansFeedbackSelector = state =>
  state.propertyHighlight.shoppingDetailModal

export const propertiesStatusCheckFeedbackSelector = state =>
  state.propertyHighlight.propertiesStatusCheck?.feedback || initialFeedback()
