import axios from 'axios'
import config from 'config'
const HERE_APP_ID = config('HERE_APP_ID')
const HERE_APP_CODE = config('HERE_APP_CODE')
const HERE_COUNTRY = config('HERE_COUNTRY')
const HERE_URL_API = config('HERE_URL_API')
const HERE_URL_GEOCODE = config('HERE_URL_GEOCODE')



export function fetchLocations(search) {

  return axios.get(HERE_URL_API,
    {
      params: {
        app_id: HERE_APP_ID,
        app_code: HERE_APP_CODE,
        country: HERE_COUNTRY,
        maxresults: 5,
        query: search
      }
    })
}

export function fetchLocationRetrive(locationId) {
  return axios.get(HERE_URL_GEOCODE,
    {
      params: {
        app_id: HERE_APP_ID,
        app_code: HERE_APP_CODE,
        locationId
      }
    })
}