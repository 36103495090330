import { colors } from 'theme'
import css from 'styled-jsx/css'

export default css`
  .button.none {
    background-color: ${colors.transparent};
    border: 0;
  }

  .button:not(.disabled):hover.none, .button:not(.disabled):focus.none {
    background-color: ${colors.transparent};
    border: 0;
  }
`