import ApiBase from 'app/services/api/entities/Base'

function* activeTypes(params = {}) {
  return yield ApiBase.privateGet('/v1/smartcontracts/contract-layouts/', {
    params
  })
}

function* getQuestionnairy(contractTypeId) {
  return yield ApiBase.privateGet(
    `/v1/smartcontracts/contract-layouts/${contractTypeId}/`
  )
}

function* getCheckLayoutVersion(contractId) {
  return yield ApiBase.privateGet(
    `/v1/smartcontracts/contracts/${contractId}/check_layout_version/`
  )
}

function* all(params = {}) {
  return yield ApiBase.privateGet('/v1/smartcontracts/contracts/', {
    params
  })
}

function* create(data) {
  return yield ApiBase.privatePost('/v1/smartcontracts/contracts/', {
    data
  })
}

function* find(contractId) {
  return yield ApiBase.privateGet(`/v1/smartcontracts/contracts/${contractId}/`)
}

function* update(contractId, contractData) {
  return yield ApiBase.privatePut(
    `/v1/smartcontracts/contracts/${contractId}/`,
    { data: contractData }
  )
}

function* createDraft(contractData) {
  return yield ApiBase.privatePost('/v1/smartcontracts/contracts/', {
    data: contractData
  })
}

function* updateDraft(contractId, contractData) {
  return yield ApiBase.privatePatch(
    `/v1/smartcontracts/contracts/${contractId}/`,
    { data: contractData }
  )
}

function* remove(contractId) {
  return yield ApiBase.privateDelete(
    `/v1/smartcontracts/contracts/${contractId}/`
  )
}

function* pdf(contractId) {
  return yield ApiBase.privatePost(
    `/v1/smartcontracts/contracts/${contractId}/pdf/`
  )
}

function* property(propertyId) {
  return yield ApiBase.privateGet(
    `/v1/smartcontracts/properties/${propertyId}/`
  )
}

function* cancelSignatures(contractId) {
  return yield ApiBase.privateDelete(`/v1/smartcontracts/contracts/${contractId}/cancel_signatures/`)
}

function* requestSignatures(contractId) {
  return yield ApiBase.privateGet(`/v1/smartcontracts/contracts/${contractId}/request_signatures/`)
}

export default {
  all,
  // types,
  activeTypes,
  getQuestionnairy,
  getCheckLayoutVersion,
  create,
  update,
  find,
  updateDraft,
  createDraft,
  remove,
  pdf,
  property,
  requestSignatures,
  cancelSignatures
}
