import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './styles'

const defaultModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['link'],
    ['clean']
  ]
}

const defaultFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image'
]

class RichTextEditor extends Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large'])
  }
  static defaultProps = {
    style: {},
    size: 'small'
  }

  UNSAFE_componentWillMount() {
    if (typeof window !== 'undefined') {
      this.ReactQuill = require('react-quill')

      this.forceUpdate()
    }
  }

  getPlainText = () => {
    if (this.editor && typeof this.editor.getEditor === 'function') {
      return this.editor.getEditor().getText()
    }

    return ''
  }

  render() {
    const { ReactQuill } = this
    const { className, size, ...rest } = this.props
    if (!ReactQuill) {
      return <textarea {...this.props} />
    }
    return (
      <>
        <ReactQuill
          ref={r => {
            this.editor = r
          }}
          modules={defaultModules}
          formats={defaultFormats}
          className={size}
          {...rest}
        />
        <style jsx global>
          {styles}
        </style>
      </>
    )
  }
}

export default RichTextEditor
