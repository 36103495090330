import React, { Component } from 'react'
import classname from 'classnames'

import PhoneInput from 'react-phone-number-input'
import styles from './styles'

class PhoneInputForm extends Component {
  handleChange = value => {
    const { onChange } = this.props.input
    if (onChange) {
      onChange(value || '')
    }
  }

  render() {
    const {
      input,
      className,
      label = '',
      placeholder,
      meta: { valid = false, invalid = false, touched = false, error, warning },
      ...rest
    } = this.props

    const isValid = valid && touched

    const isInvalid = invalid && touched

    return (
      <div
        className={classname('wrapper-phone-input', className, {
          'is-valid': isValid,
          'is-invalid': isInvalid
        })}
      >
        <label className="fl-input-label">{label}</label>
        <PhoneInput
          country="MX"
          {...input}
          {...rest}
          placeholder={placeholder || label}
          onChange={this.handleChange}
        />
        {touched &&
          !valid && <span className={'message-error-phone'}>{error}</span>}
        <style jsx global>
          {styles}
        </style>
      </div>
    )
  }
}

export default PhoneInputForm
