export default {
  name: 'mlmRanking',
  namespace: 'mlm_ranking',
  methods: [
    {
      name: 'mlmRankingInvitation',
      params: ['invitationId', 'data'],
      successCode: [200, 201],
      method: 'Patch',
      url: ({ invitationId }) => `/v1/mlm_ranking/invitations/${invitationId}/`,
      urlParams: ['invitationId']
    },
  ]
}
