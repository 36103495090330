import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { IonIcon } from 'controls'
import style from './style'
import classnames from 'classnames'

class Pagination extends PureComponent {
  static propTypes = {
    pageCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired
  }

  static defaultProps = {
    pageCount: 0,
    currentPage: 0
  }

  onPrev() {
    const { currentPage } = this.props
    const previousPageIndex = currentPage - 1
    if (previousPageIndex < 0) {
      return
    }

    this.props.onPageChange(previousPageIndex)
  }

  onNext() {
    const { currentPage, pageCount } = this.props
    const nextPageIndex = currentPage + 1
    if (nextPageIndex === pageCount) {
      return
    }

    this.props.onPageChange(nextPageIndex)
  }

  onClick(pageNumber) {
    const { pageCount, currentPage } = this.props

    if (
      pageNumber >= pageCount ||
      pageNumber < 0 ||
      pageNumber === currentPage
    ) {
      return
    }

    this.props.onPageChange(pageNumber)
  }

  render() {
    const { pageCount, currentPage } = this.props

    return (
      <div className="pagination-container">
        <div
          className={classnames('pagination-button arrow-button-container', {
            disabled: currentPage === 0
          })}
          onClick={this.onPrev}
        >
          <IonIcon icon="ios-arrow-back" size="large" />
        </div>
        {[...Array(pageCount).keys()].map(page => {
          return (
            <div
              key={page}
              className={classnames('pagination-button pagination-item', {
                active: page === currentPage
              })}
              onClick={() => {
                this.onClick(page)
              }}
            >
              {page + 1}
            </div>
          )
        })}
        <div
          className={classnames('pagination-button arrow-button-container', {
            disabled: currentPage >= pageCount - 1
          })}
          onClick={this.onNext}
        >
          <IonIcon icon="ios-arrow-forward" size="large" />
        </div>
        <style jsx>{style}</style>
      </div>
    )
  }
}

export default Pagination
