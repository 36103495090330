import {
  OPERATION_TYPES_BIBLIO as OT,
  PROPERTY_TYPES_BIBLIO as PT,
  LAND_TYPES_BIBLIO as LT,
  DEVELOPMENT_TYPES_VALUES,
} from 'services/seo'

import { formatDate, DATE_MONTH_YEAR_FORMAT_HUMAN } from 'services/date'
import { nFormatter } from 'app/services/number'

export const pluralThink = (value, e = '') => (value == '1' ? '' : `${e}s`)

const mapLocations = sl =>
  sl
    .split('::')
    .filter(v => v)
    .join(', ')

export const PARAMS_VALUES_LABELS = {
  minPrice: minPrice => {
    const { number, letter } = nFormatter(minPrice)
    return `Precio mínimo: ${number} ${letter || ''}`
  },
  maxPrice: maxPrice => {
    const { number, letter } = nFormatter(maxPrice)
    return `Precio máximo: ${number} ${letter || ''}`
  },
  propertyType: propertyType =>
    Array.isArray(propertyType)
      ? propertyType.map(pt => PT[pt])
      : `${PT[propertyType]}`,
  operationType: operationType => `${OT[operationType]}`,
  age: age => `${age === '1' ? 'Un año' : `${age} años`} de antigüedad`,
  bedrooms: bedrooms => {
    if (Number(bedrooms) <= 0) {
      return ''
    }
    return `${bedrooms} recamara${pluralThink(bedrooms)}`
  },
  bathrooms: bathrooms => {
    if (Number(bathrooms) <= 0) {
      return ''
    }
    return `${bathrooms} baño${pluralThink(bathrooms)}`
  },
  halfBathrooms: halfBathrooms => {
    if (Number(halfBathrooms) <= 0) {
      return ''
    }
    return `${halfBathrooms} medio baño${pluralThink(halfBathrooms)}`
  },
  parkingSpaces: parkingSpaces =>
    `${parkingSpaces} espacio${pluralThink(parkingSpaces)} de estacionamiento`,
  surfaceArea: surfaceArea => `${surfaceArea} m2 de terreno`,
  constructionArea: constructionArea => `${constructionArea} m2 de construcción`,
  selectedLocations: selectedLocations => {
    if (Array.isArray(selectedLocations)) {
      return selectedLocations.map(sl => {
        return typeof sl === 'object' ?
          mapLocations(mapObjectLocationToString(sl)) :
          mapLocations(sl)
      })
    }
    return mapLocations(selectedLocations)
  },
  landType: landType => LT[landType] ? `uso de suelo ${LT[landType]}` : '',
  developmentType: (developmentType, { presaleDate } = {}) => {
    if (developmentType === DEVELOPMENT_TYPES_VALUES.PRESALE && presaleDate) {
      const date = formatDate(presaleDate, DATE_MONTH_YEAR_FORMAT_HUMAN)
      return `en preventa con fecha de entrega ${date}`
    }

    if (developmentType === DEVELOPMENT_TYPES_VALUES.IMMEDIATE_DELIVERY) {
      return 'a estrenar con entrega inmediata'
    }

    return ''
  },
}

/**
 * mapFiltersToLabel - Function to map query params filters to human labels
 * @param {object} filters - in camelCase like { minPrice: 1000 }
 * @return {string[]} - with each formated labels
 */

export const mapFiltersToLabel = filters =>
  Object.keys(filters)
    .map(key => {
      const keyToLabel = PARAMS_VALUES_LABELS[key]
      return keyToLabel && filters[key] ? keyToLabel(filters[key]) : null
    })
    .filter(v => v)
    .reduce(
      (acc, value) =>
        Array.isArray(value) ? [...value, ...acc] : [value, ...acc],
      []
    )
    .reverse()

/**
 * mapSelectedLocations
 * @param {object} stateData - like { 'Estado de México': { A SEPOMEX stades info } }
 * @param {object} municipalityData  - linke stateData
 * @param {object} neighborhoodData - like stateData
 * @param {array|string} selectedLocations
 * @return {object[]} - [{ state: {...}, municipality: {...}, neighborhood: {...} }]
 */

export const mapSelectedLocations = ({
  stateData = {},
  municipalityData = {},
  neighborhoodData = {},
  selectedLocations = []
}) => {
  /**
   * selectedLocations has got a format like
   * [23258::Melitón Albañez::La Paz::Baja California Sur] or [::::::Baja California Sur]
   */

  const locations = Array.isArray(selectedLocations)
    ? selectedLocations
    : [selectedLocations]
  return Array.from(
    new Set(
      locations
        .map(loc => loc.split('::'))
        .map(loc => {
          const [postalCode, neighborhood, municipality, state] = loc

          return { postalCode, neighborhood, municipality, state }
        })
    )
  )
    .map(({ neighborhood, municipality, state }) => {
      const finalMapLocation = {}

      if (state) {
        finalMapLocation.state = stateData[state]
      }
      if (municipality) {
        finalMapLocation.municipality = municipalityData[municipality]
      }
      if (neighborhood) {
        finalMapLocation.neighborhood = neighborhoodData[neighborhood]
      }

      return finalMapLocation
    })
    .filter(obj => Object.keys(obj).length > 0)
}

export const disabledCreateAlertButton = ({
  operationType,
  propertyType,
  minPrice,
  maxPrice,
  selectedLocations,
  id
}) => {
  if(id){
    return false
  }
  const isPropertyType =
    (propertyType && Array.isArray(propertyType) && propertyType.length) ||
    propertyType

  const isPrice = minPrice || maxPrice

  const isLocations =
    (selectedLocations &&
      Array.isArray(selectedLocations) &&
      selectedLocations.length)
  
  return !(operationType && isPropertyType && isPrice && isLocations)
}

export const mapObjectLocationToString = (location = {}) => {
  const state = (location.state || {}).name || ''
  const municipality = (location.municipality || {}).name || ''
  const neighborhood = (location.neighborhood || {}).name || ''
  const postalCode = (location.neighborhood || {}).postalCode || ''

  return `${postalCode}::${neighborhood}::${municipality}::${state}`
}

export const statusAlertConfig = {
  ACTIVE: {
    color: 'primary',
    action: 'activar',
    description:
      'Seguiremos buscando propiedades automáticamente y también podrá recibir sugerencias de otros Neximers.',
    emptyAlerts: 'No tienes alertas activas.'
  },
  PAUSED: {
    color: 'secondary',
    action: 'pausar',
    description:
      'Dejaremos de enviarte notificaciones de nuevas propiedades encontradas en la plataforma y dejara de estar disponible para recibir sugerencias de otros Neximers. En el momento que tu requieras podrás activarla nuevamente.',
    emptyAlerts: 'No tienes alertas pausadas.'
  },
  CANCELLED: {
    color: 'warning',
    action: 'eliminar',
    description:
      'Se eliminara de tu listado y  dejaremos de enviarte notificaciones de nuevas propiedades encontradas en la plataforma.',
    emptyAlerts: 'No tienes alertas eliminadas.'
  },
  EXPIRED: {
    emptyAlerts: 'Todas tus alertas están activas.'
  }
}
