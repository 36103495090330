import { initialFeedback, getHashedState } from 'reducers/utils'

export const MLM_COMMISSIONS_RESULTS_LIMIT = 10

export const BASE_MLM_COMMISSIONS = {
  count: 0,
  commissions: [],
  total: {
    amount: 0,
    currency: 'MXN'
  },
  params: {
    offset: 0,
    limit: MLM_COMMISSIONS_RESULTS_LIMIT
  },
  feedback: initialFeedback()
}

/**
 * @function createHashFromDates
 * @param  {...String} args
 * @example
 * > createHashFromDates('01-01-2021', '01-03-2021')
 * > "01-01-2021--01-03-2021"
 * @returns {String}
 */
export const createHashFromDates = (...args) => args.join('--')

export const MLMCommissionsSelector = (state, from, to) =>
  getHashedState(
    state.mlmCommissions.commissions,
    createHashFromDates(from, to),
    BASE_MLM_COMMISSIONS
  )
