import crudAction from '../actions'

export default {
  name: 'crmContact',
  namespace: 'crm/contacts',
  methods: [
    ...crudAction,
    {
      name: 'filterFollowups',
      params: ['contactId'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, contactId}) => `/v1/${namespace}/${contactId}/followups/`,
      urlParams: ['contactId']
    },
    {
      name: 'findFollowups',
      params: ['contactId','followupId'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, contactId, followupId}) => `/v1/${namespace}/${contactId}/followups/${followupId}/`,
      urlParams: ['contactId']
    },
    {
      name: 'createFollowups',
      params: ['contactId', 'data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, contactId}) => `/v1/${namespace}/${contactId}/followups/`,
      urlParams: ['contactId']
    },
    {
      name: 'deleteFollowups',
      params: ['contactId','followupId'],
      successCode: 200,
      method: 'Delete',
      url: ({ namespace, contactId, followupId}) => `/v1/${namespace}/${contactId}/followups/${followupId}/`,
      urlParams: ['contactId', 'followupId']
    },
    {
      name: 'updateFollowups',
      params: ['contactId', 'followupId'],
      successCode: 200,
      method: 'Patch',
      url: ({ namespace, contactId, followupId}) => `/v1/${namespace}/${contactId}/followups/${followupId}/`,
      urlParams: ['contactId','followupId']
    },
    {
      name: 'filterFollowupsTask',
      params: ['contactId', 'followupId'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, contactId, followupId}) => `/v1/${namespace}/${contactId}/followups/${followupId}/tasks/`,
      urlParams: ['contactId', 'followupId']
    },
    {
      name: 'findFollowupsTask',
      params: ['contactId', 'followupId', 'taskId'],
      successCode: 200,
      method: 'Get',
      url: ({namespace, contactId, followupId, taskId}) => `/v1/${namespace}/${contactId}/followups/${followupId}/tasks/${taskId}/`,
      urlParams: ['contactId', 'followupId', 'taskId']
    },
    {
      name: 'createFollowupsTask',
      params: ['contactId', 'followupId', 'data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, contactId, followupId}) => `/v1/${namespace}/${contactId}/followups/${followupId}/tasks/`,
      urlParams: ['contactId', 'followupId']
    },
    {
      name: 'deleteFollowupsTask',
      params: ['contactId', 'followupId', 'taskId'],
      successCode: 200,
      method: 'Delete',
      url: ({namespace, contactId, followupId, taskId}) => `/v1/${namespace}/${contactId}/followups/${followupId}/tasks/${taskId}/`,
      urlParams: ['contactId', 'followupId', 'taskId']
    },
    {
      name: 'updateFollowupsTask',
      params: ['contactId', 'followupId', 'taskId'],
      successCode: 200,
      method: 'Patch',
      url: ({namespace, contactId, followupId, taskId}) => `/v1/${namespace}/${contactId}/followups/${followupId}/tasks/${taskId}/`,
      urlParams: ['contactId', 'followupId', 'taskId']
    },
    {
      name: 'filterFollowupsTaskNote',
      params: ['contactId', 'followupId', 'taskId'],
      successCode: 200,
      method: 'Get',
      url: ({namespace, contactId, followupId, taskId}) => `/v1/${namespace}/${contactId}/followups/${followupId}/tasks/${taskId}/notes/`,
      urlParams: ['contactId', 'followupId', 'taskId']
    },
    {
      name: 'findFollowupsTaskNote',
      params: ['contactId', 'followupId', 'taskId', 'noteId'],
      successCode: 200,
      method: 'Get',
      url: ({namespace, contactId, followupId, taskId, noteId}) => `/v1/${namespace}/${contactId}/followups/${followupId}/tasks/${taskId}/notes/${noteId}/`,
      urlParams: ['contactId', 'followupId', 'taskId','noteId']
    },
    {
      name: 'createFollowupsTaskNote',
      params: ['contactId', 'followupId', 'taskId'],
      successCode: 200,
      method: 'Post',
      url: ({namespace, contactId, followupId, taskId}) => `/v1/${namespace}/${contactId}/followups/${followupId}/tasks/${taskId}/notes/`,
      urlParams: ['contactId', 'followupId', 'taskId']
    },
    {
      name: 'deleteFollowupsTaskNote',
      params: ['contactId', 'followupId', 'taskId', 'noteId', 'noteId'],
      successCode: 200,
      method: 'Delete',
      url: ({namespace, contactId, followupId, taskId, noteId}) => `/v1/${namespace}/${contactId}/followups/${followupId}/tasks/${taskId}/notes/${noteId}/`,
      urlParams: ['contactId', 'followupId', 'taskId','noteId']
    },
    {
      name: 'updateFollowupsTaskNote',
      params: ['contactId', 'followupId', 'taskId', 'noteId', 'noteId'],
      successCode: 200,
      method: 'Patch',
      url: ({namespace, contactId, followupId, taskId, noteId}) => `/v1/${namespace}/${contactId}/followups/${followupId}/tasks/${taskId}/notes/${noteId}/`,
      urlParams: ['contactId', 'followupId', 'taskId','noteId']
    }
  ]
}
