import crudAction from '../actions'

export default {
  name: 'cardToken',
  namespace: 'card_tokens',
  methods: [
    {
      name: 'find',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/payments/${namespace}/${id}/`,
      urlParams: [ 'id' ]
    },
    {
      name: 'filter',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, }) => `/v1/payments/${namespace}/`,
      urlParams: []
    },
    {
      name: 'create',
      params: ['data'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace, }) => `/v1/payments/${namespace}/`,
      urlParams: []
    },
    {
      name: 'update',
      params: ['id', 'data'],
      successCode: 200,
      method: 'Put',
      url: ({ namespace, id }) => `/v1/payments/${namespace}/${id}/`,
      urlParams: [ 'id' ]
    },
    {
      name: 'patch',
      params: ['id', 'data'],
      successCode: 200,
      method: 'Patch',
      url: ({ namespace, id }) => `/v1/payments/${namespace}/${id}/`,
      urlParams: [ 'id' ]
    },
    {
      name: 'delete',
      params: ['id'],
      successCode: 204,
      method: 'Delete',
      url: ({ namespace, id }) => `/v1/payments/${namespace}/${id}/`,
      urlParams: [ 'id' ]
    }
  ]
}
