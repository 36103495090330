
import appointment from './appointment'
import area from './area'
import fiscalDocumentation from './fiscalDocumentation'
import fiscalData from './fiscalData'
import amenity from './amenity'
import publicAmenities from './publicAmenities'
import user from './user'
import broker from './broker'
import oauth from './oauth'
import directAnnonceLead from './directAnnonceLead'
import brokerKitData from './brokerKitData'
import brokerNote from './brokerNote'
import brokerValuation from './brokerValuation'
import customerServicesUsers from './customerServicesUsers'
import contactInfo from './contactInfo'
import property from './property'
import neximerPerformanceStats from './neximerPerformanceStats'
import propertyDocumentation from './propertyDocumentation'
import landlordPropertyData from './landlordPropertyData'
import locationService from './locationService'
import markets from './markets'
import notification from './notification'
import message from './message'
import neximerExpenses from './neximerExpenses'
import neximers from './neximers'
import customEventParticipant from './customEventParticipant'
import stats from './stats'
import accounts from './accounts'
import propertyScore from './propertyScore'
import externalLeadSources from './externalLeadSources'
import propertyVirtualTour from './propertyVirtualTour'
import neximerOnboarding from './neximerOnboarding'
import rfc from './rfc'

import * as elearning from './elearning'

import * as helpdesk from './helpDesk'
import * as payment from './payment'
import * as crm from './crm'
import * as bin from './bin'

import * as dokaltin from './dokaltin'
import * as mlm from './mlm'
import * as nexsites from './nexsites'

export default {
  appointment,
  area,
  fiscalDocumentation,
  fiscalData,
  amenity,
  publicAmenities,
  user,
  broker,
  directAnnonceLead,
  customEventParticipant,
  brokerKitData,
  brokerNote,
  brokerValuation,
  customerServicesUsers,
  contactInfo,
  oauth,
  property,
  propertyDocumentation,
  landlordPropertyData,
  neximerPerformanceStats,
  locationService,
  markets,
  message,
  neximerExpenses,
  neximers,
  notification,
  stats,
  accounts,
  propertyScore,
  externalLeadSources,
  propertyVirtualTour,
  neximerOnboarding,
  rfc,
  ...elearning,
  ...helpdesk,
  ...payment,
  ...crm,
  ...bin,
  ...dokaltin,
  ...mlm,
  ...nexsites,
}
