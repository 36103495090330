import api from '../api'

const getBonds = token => {
  return api.privateGet('/v1/dokaltin/bonds/', {token})
}

const getBondById = (token, id) => {
  return api.privateGet(`/v1/dokaltin/bonds/${id}/`, {token})
}

const createActor = (token, bondId, fullName, email, role) => {
  const data = {
    bondId,
    fullName,
    email,
    role,
  }

  return api.privatePost(`v1/dokaltin/bonds/${bondId}/actors/`, { token, data })
}

const deleteActor = (token, actorId) => {
  return api.privateDelete(`v1/dokaltin/actors/${actorId}/`, { token })
}

const createTask = (token, bond, description, assignee) => {
  const data = {
    bond,
    description,
    assignee
  }

  return api.privatePost('v1/dokaltin/tasks/', { token, data })
}

const updateTask = (token, taskId, updatedData) => {
  const { description, assignee, status } = updatedData
  const data = {
    description,
    assignee,
    status
  }

  return api.privatePatch(`v1/dokaltin/tasks/${taskId}/`, { token, data })
}

const deleteTask = (token, taskId) => {
  return api.privateDelete(`v1/dokaltin/tasks/${taskId}/`, { token })
}

export default {
  getBonds,
  getBondById,
  createActor,
  deleteActor,
  createTask,
  updateTask,
  deleteTask,
}
