import { companyConfig } from 'globalConfigApp'

import styles from './styles'

export default [
  <article
    data-category="fiscal"
    data-title="¿Cómo se cobrará la comisión de mi venta o renta de propiedad?"
    data-url="como-se-cobrara-la-comision-de-mi-venta-o-renta-de-propiedad"
    className="wrapper-article"
  >
    <h4>¿Cómo se cobrará la comisión de mi venta o renta de propiedad?</h4>
    <div className="content">
      <p>
        Tu relacion de colaboracion con {companyConfig.name} esta plasmada dentro de nuestros
        términos y condiciones. Te invitamos a leerlos con atención. {companyConfig.name}
        cobrará la comisión de tus operaciones directamente de parte del
        propietario. Emitirimos una factura para el propietario y dos facturas
        para ti. La primera corresponderá al pago de tus servicios y la segunda
        a la comisión de {companyConfig.name}.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="fiscal"
    data-title="¿Qué sucede cuando un cliente no quiere pagar el IVA de la comisión de
    venta?"
    data-url="que-sucede-cuando-un-cliente-no-quiere-pagar-el-IVA-de-la-comision-de-venta"
    className="wrapper-article"
  >
    <h4>
      ¿Qué sucede cuando un cliente no quiere pagar el IVA de la comisión de
      venta?
    </h4>
    <div className="content">
      <p>
        El IVA es un impuesto universal y obligatorio en México. Es importante
        recordar a tu cliente la obligación de pagar el IVA. Nuestro equipo de
        capacitacion te acompañara a convencer tus clientes de pagar el IVA.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="fiscal"
    data-title="¿Qué porcentaje de comisión aceptan?"
    data-url="que-porcentaje-de-comision-aceptan"
    className="wrapper-article"
  >
    <h4>¿Qué porcentaje de comisión aceptan?</h4>
    <div className="content">
      <p>
        {companyConfig.name} no aplica un % de comisión mínimo. Sin embargo, consideramos que
        las herramientas que te brindamos deben de permitir exigir la comisión
        del mercado.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="fiscal"
    data-title="¿Cómo puedo realizar mi factura?"
    data-url="como-puedo-realizar-mi-factura"
    className="wrapper-article"
  >
    <h4>¿Cómo puedo realizar mi factura?</h4>
    <div className="content">
      <p>
        Ingresa al portal del SAT, despues da click en generar factura. La clave
        80131601 el uso es gastos en general. <br/>
        El concepto puede poner Servicios de Gestión o Servicios Inmobiliarios. <br/>
        RFC NEXIMO - RPG1612195R1<br/>
        REALTECH PROPERTY GROUP SAPI DE CV
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>
]
