import { put, all } from 'redux-saga/effects'

import { MLMQuotas, Deals } from 'services/api/entities'
import { MLMQuotasActionCreators } from 'app/reducers/MLMQuotasRedux'
import { removeAllEmptyKeys } from 'services/utils'
import { sumSaleTotalCommission } from 'app/selectors/MLMQuotas'

/**
 * params.error could be "True" or "False"
 * It's used to filter over quotas with error or without error.
 * If error is empty or null, all quotas are retrieved.
 *
 * To get sum of the request, we use two services:
 *    MLMQuotas.total -> Filter over all requested values
 *    MLMQuotas.profit -> ONLY sum quotas without errors
 */

export function* getQuotasRequest({ from, to, params, includeProfitRequest = true }) {
  const { errorPayed } = params || {}

  const newParams = removeAllEmptyKeys({
    created_at_after: from,
    created_at_before: to,
    ...params,
    error: errorPayed
  })

  const getTotal = errorPayed ? MLMQuotas.total : MLMQuotas.profit

  const requests = [
      MLMQuotas.all(newParams),
  ]

  if (includeProfitRequest) {
    requests.push(getTotal(newParams))
  }

  const [responseQuotas, responseProfit] = yield all(requests)

  const profit =
  responseProfit && responseProfit.success() ? responseProfit.data : null

  if (responseQuotas.success()) {
    const { count, results } = responseQuotas.data
    return yield put(
      MLMQuotasActionCreators.getQuotasSuccess(from, to, count, results, profit)
    )
  }

  yield put(MLMQuotasActionCreators.getQuotasFailure(from, to, response.data))
}

export function* getTransactionSummary({
  from,
  to,
  params,
  tracingUserType = 'currentUser'
}) {
  const newParams = removeAllEmptyKeys({
    created_at_after: from,
    created_at_before: to,
    ...params
  })

  const getDeals =
    tracingUserType === 'childrenDetail'
      ? Deals.closedChildrenDeals
      : Deals.getAll

  const [
    responseProfit,
    responseNotPayedProfit,
    resposeActiveDeals
  ] = yield all([
    MLMQuotas.total({ ...newParams, error: 'False' }),
    MLMQuotas.total({ ...newParams, error: 'True' }),
    getDeals({ ...newParams, active: 'True' })
  ])

  const responsePayload = {
    profit: null,
    activeDeals: null,
    activeDealsUSD: null,
    notPayedProfit: null,
    activeDealsId: []
  }

  if (responseProfit.success()) {
    responsePayload.profit = responseProfit.data
  }
  if (resposeActiveDeals.success()) {
    const { activeDealsMXN, activeDealsUSD } = sumSaleTotalCommission(
      resposeActiveDeals.data
    )
    responsePayload.activeDeals = activeDealsMXN
    responsePayload.activeDealsUSD = activeDealsUSD
    responsePayload.activeDealsId = resposeActiveDeals.data.map(deal => deal.id)
    responsePayload.activeDealsCount = resposeActiveDeals.data.length
  }
  if (responseNotPayedProfit.success()) {
    responsePayload.notPayedProfit = responseNotPayedProfit.data
  }
  
  yield put(
    MLMQuotasActionCreators.getTransactionSummarySuccess(responsePayload)
  )
}

export function* getDealsDetails({
  dealsId = [],
  tracingUserType = 'currentUser'
}) {
  // Retrieve deals details
  const getDetail =
    tracingUserType === 'currentUser' ? Deals.detail : Deals.findChildDeal
  const responseDetails = yield all(dealsId.map(dealId => getDetail(dealId)))

  const deals = []

  for (const response of responseDetails) {
    if (response.success()) {
      deals.push(response.data)
    }
  }

  yield put(MLMQuotasActionCreators.getDealsDetailsSuccess(deals))
}
