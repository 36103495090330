import { createSelector } from 'reselect'

/**
 * Returns an object with following fields:
 * {
 *   deleted: ['array', 'of', 'field', 'ids', 'to'. 'delete'],
 *   updated: [{id: 'dbID123', ...fieldData}],
 *   created: [{...fieldData}]
 * }
 *
 * The returning object is processed by the saga that invokes the API, with as
 * many calls as elements in the arrays combined
 */
export const getSavingFields = createSelector(
  state => state.dokaltin.id,
  state => state.dokaltin.controls,
  (documentId, pageControls) => {
    const toSave = []

    for (let pageIndex in pageControls) {
      const controls = pageControls[pageIndex]

      for (let controlIndex in controls) {
        const control = controls[controlIndex]

        // discard conflictive fields
        const { isNew, modified, deleted, ...rest } = control
        let action = null

        if (control.isNew) {
          action = 'create'
        } else if (!control.isNew && control.deleted) {
          action = 'delete'
        } else if (!control.isNew && !control.deleted && control.modified) {
          action = 'update'
        }

        if (action !== null) {
          toSave.push({
            action,
            documentId,
            pageId: pageIndex,
            control: rest
          })
        }
      }
    }

    return toSave
  }
)

export const canSaveDocument = createSelector(
  state => state.dokaltin,
  dokaltinData => {
    const { controls, shareData, sharedActorData } = dokaltinData

    // it's an actor
    if (shareData.token && sharedActorData.id) {
      let mergedControls = {}
      for (let iX in controls) {
        mergedControls = {
          ...mergedControls,
          ...controls[iX]
        }
      }

      const actorControls = Object.values(mergedControls).filter(control => {
        const assignee = control.assignee || {}

        return assignee.id === sharedActorData.id
      })

      if (actorControls.length === 0) {
        return false
      }

      return actorControls.reduce((hasValue, control) => hasValue && control.value && control.value.length > 0, true)
    }

    // control rules for owner
    return true
  }
)

export const getSharingState = createSelector(
  state => state.dokaltin,
  dokaltinData => ({
    sharing: dokaltinData.sharing,
    shared: dokaltinData.shared,
    sharingError: dokaltinData.sharingError
  })
)

export const getDocumentData = createSelector(
  state => state.dokaltin,
  dokaltinData => {
    return {
      id: dokaltinData.id,
      name: dokaltinData.name || dokaltinData.filename,
      pages: dokaltinData.pages
    }
  }
)

export const getActiveControl = createSelector(
  state => state.dokaltin,
  dokaltinData => {
    const { editingField } = dokaltinData
    const page = dokaltinData.controls[editingField.pageId]
    const control = page ? page[editingField.fieldId] : {}

    return control
  }
)
