import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContactJourneyCreators } from 'app/reducers/ContactJourneyRedux.js'
import { store } from 'services'

import { mapObjToArrayValues } from './utils'

const useShareBinRequirement = (initialTypeOfModal = 'form') => {
  const [sharedRiqueriment, setShareRequirement] = React.useState(false)
  const [typeOfModal, setTypeOfModal] = React.useState(initialTypeOfModal)

  const dispatch = useDispatch()

  const { shareRequirementRequest } = useSelector(state => ({
    shareRequirementRequest: store
      .shareRequirement('crmBinLead')
      .selector(state)
  }))

  useEffect(() => {
    const { loading, finished, error, response } = shareRequirementRequest

    if (sharedRiqueriment && (!loading && finished, !error && response)) {
      setTypeOfModal('success')
      dispatch(ContactJourneyCreators.sendContactJourneyEvent('contact-journey', 'share-public-requirement-finished-process'))
    }
    if (sharedRiqueriment && (!loading && finished, error)) {
    }
  }, mapObjToArrayValues(shareRequirementRequest))

  const shareRequirement = (binLeadId, values) => {
    setShareRequirement(true)

    const finalValues = {
      ...values,
      relationship: values.relationship.value
    }

    dispatch(
      store.shareRequirement('crmBinLead').action(binLeadId, finalValues)
    )
  }

  const sharedData = {
    typeOfModal,
    loading: shareRequirementRequest.loading,
    error: shareRequirement.error
  }

  return [sharedData, { shareRequirement, setTypeOfModal }]
}

export default useShareBinRequirement
