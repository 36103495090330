import { put } from 'redux-saga/effects'

import NetworkReportsAPI from 'services/api/entities/NetworkReports'
import { NetworkReportsActionCreators } from 'app/reducers/NetworkReportsRedux'

export function* getRecommendations({ recommendationType, params }) {
  const RECOMMENDATION_TYPES = {
    INVENTORY: 'user_network_inventory',
    INVENTORY_QUALITY: 'user_network_inventory_quality',
    CONTACTS: 'user_network_contacts',
    BILLING: 'user_network_billing',
  }
  const response = yield NetworkReportsAPI.recommendations({
    recommendationType: RECOMMENDATION_TYPES[recommendationType],
    from: params.from,
    to: params.to,
  })

  if (response.success()) {
    return yield put(
      NetworkReportsActionCreators.getRecommendationsSuccess(response.data),
    )
  }
  return yield put(
    NetworkReportsActionCreators.getRecommendationsFailure(response.error),
  )
}

export function* getInventoryReport({ params }) {
  const response = yield NetworkReportsAPI.inventory(params)

  if (response.success()) {
    return yield put(
      NetworkReportsActionCreators.getInventoryReportSuccess(response.data),
    )
  }
  return yield put(
    NetworkReportsActionCreators.getInventoryReportFailure(response.error),
  )
}

export function* getInventaryQualityReport({ params }) {
  const response = yield NetworkReportsAPI.inventoryQuality(params)

  if (response.success()) {
    return yield put(
      NetworkReportsActionCreators.getInventaryQualityReportSuccess(response.data),
    )
  }
  return yield put(
    NetworkReportsActionCreators.getInventaryQualityReportFailure(response.error),
  )
}

export function* getContactsReport({ params }) {
  const response = yield NetworkReportsAPI.contacts(params)

  if (response.success()) {
    return yield put(
      NetworkReportsActionCreators.getContactsReportSuccess(response.data),
    )
  }
  return yield put(
    NetworkReportsActionCreators.getContactsReportFailure(response.error),
  )
}

export function* getBillingReport({ params }) {
  const response = yield NetworkReportsAPI.billing(params)

  if (response.success()) {
    return yield put(
      NetworkReportsActionCreators.getBillingReportSuccess(response.data),
    )
  }
  return yield put(
    NetworkReportsActionCreators.getBillingReportFailure(response.error),
  )
}

export function* getReportCSV({reportType, params }) {
  const REPORT_API = {
    'INVENTORY': NetworkReportsAPI.inventoryCSV,
    'INVENTORY_QUALITY': NetworkReportsAPI.inventoryQualityCSV,
    'CONTACTS': NetworkReportsAPI.contactsCSV,
    'BILLING': NetworkReportsAPI.billingCSV,
  }
  const reportApi = REPORT_API[reportType]

  const response = yield reportApi(params)
  if (response.success()) {
    return yield put(
      NetworkReportsActionCreators.getReportCSVSuccess(response.data),
    )
  }
  return yield put(
    NetworkReportsActionCreators.getReportCSVFailure(response.error),
  )
}