import colors from 'theme/colors'
import css from 'styled-jsx/css'

const duration = 120

export default css`
  .link {
    color: ${colors.white};
    cursor: pointer;
    padding: 0.75rem;
    display: inline-block;
    box-sizing: border-box;

    transition: ${duration}ms color;
  }

  .underline {
    height: 1px;
    width: 0;
    margin: auto;
    margin-bottom: -1px;
    background-color: ${colors.white};
    transition: ${duration}ms width, ${duration}ms background-color;
  }

  .link:hover{
    color: rgba(255, 255, 255, 0.6);
    transition: ${duration}ms color;
  }

  .link:hover .underline {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    transition: ${duration}ms all;
  }
`