import { useState, useEffect } from 'react'
import Router from 'next/router'

const useLoadingPage = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const onStart = () => {
      setLoading(true)
      setError(false)
    }
    const onEnd = () => {
      setLoading(false)
      setError(false)
    }

    const onError = () => {
      setLoading(false)
      setError(true)
    }

    Router.events.on('routeChangeStart', onStart)
    Router.events.on('routeChangeComplete', onEnd)
    Router.events.on('routeChangeError', onError)

    return () => {
      Router.events.off('routeChangeStart', onStart)
      Router.events.off('routeChangeComplete', onEnd)
      Router.events.off('routeChangeError', onError)
    }
  }, [])

  return { pageLoading: loading, pageLoadingError: error }
}

export default useLoadingPage
