import config from 'config'
import { companyConfig } from 'globalConfigApp'

import styles from './styles'

const CONTACT_PHONE = config('CONTACT_PHONE')

export default [
  <article
    data-category="photos"
    data-title="¿Cómo puedo solicitar mi foto y video de perfil?"
    data-url="como-puedo-solicitar-mi-foto-y-video-de-perfil"
    className="wrapper-article"
  >
    <h4>¿Cómo puedo solicitar mi foto y video de perfil?</h4>
    <div className="content">
      <p>
        La foto y video de perfil se coordinan durante tu primera capacitación.
        En caso de que no hayas asistido, nuestro equipo de onboarding te
        ayudará a coordinar una sesión durante una de las varias sesiones de
        capacitación locales que organizamos cada semana.
      </p>
      <p>
        Te compartimos un video de perfil:{' '}
        <a href="https://www.youtube.com/watch?v=kScLAfMV_qM" target="_blank">
          ver aquí
        </a>
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="photos"
    data-title="¿Cómo puedo solicitar mis sesiones de fotos incluidas en mi paquete?"
    data-url="como-puedo-solicitar-mis-sesiones-de-fotos-incluidas-en-mi-paquete"
    className="wrapper-article"
  >
    <h4>
      ¿Cómo puedo solicitar mis sesiones de fotos incluidas en mi paquete?
    </h4>
    <div className="content">
      <p>Solictar tus sesiones de fotos es muy fácil.</p>
      <p>
        Recuerda aprovechar al máximo tus 3 sesiones de fotografía por mes ya
        que no son acumulables y no podrán ser usadas el siguiente mes.
      </p>
      <p>
        Requerimos que sigas el siguiente protocolo para solicitar tu sesión:
      </p>
      <ol>
        <li>
          <p>
            Ingresa a tu cuenta neximo{' '}
            <a href="/ingresar" target="_blank">
              ingresar
            </a>
          </p>
        </li>
        <li>
          <p>
            Te invitamos a solicitar tu sesión a través del módulo
            <br />
            <span className="tag">
              Soporte Administrativo > Quiero solicitar una sesión de foto
            </span>
          </p>
        </li>
        <li>
          Favor de indicarnos los siguientes datos para programar tu sesión:
          <ul>
            <li>Dirección exacta y referencias</li>
            <li>Fecha y horario disponible para recibir a un fotógrafo</li>
          </ul>
          <p>
            Si tienes alguna solicitud particular para tu sesión de fotos, no
            dudes en hacernos saber tus comentarios.
          </p>
        </li>
      </ol>
      <div className="tag success">
        <p>
          No olvides confirmar que cuentas con un contrato de intermediacion y
          el IFE del propietario. Tambien puedes solicitar una sesión de foto
          desde tu <span className="tag">Módulo de Publicación</span> de una
          propiedad.
        </p>
      </div>
      <p>Dejate guiar por nuestro módulo:</p>
      <ul>
        <li>
          ¿Cúal es el plazo de la solicitud de fotos?
          <p>
            Podemos organizar sesiones dentro de un plazo de 24h. Será posible
            cambiar la fecha y horario de tu sesión hasta con 12 horas de
            anticipación, en caso de NO ser cancelada se tomará como realizada.
          </p>
        </li>

        <li>
          ¿Cuando recibiré mis fotos?
          <p>
            Recibirás tus fotos 24 horas después de la sesión y 48 horas en
            fines de semana. En caso de emergencia, te invitamos a contactarnos
            al: {CONTACT_PHONE} en un horario de Lunes a Viernes de 9:00 am a 6:00 pm.
          </p>
          <p>
            Te compartimos algunos tips para sacar el mayor provecho de tus
            sesiones:
          </p>
          <ol>
            <li>
              Ten limpio y ordenado el inmueble antes de la cita para tu sesión.
            </li>
            <li>
              Los colores claros y neutros amplian visualmente el espacio.
            </li>
            <li>
              El tener una buena iluminación es de vital importancia, revisa
              toda la iluminación de tu hogar tanto natural como
            </li>
            artificial.
          </ol>
        </li>

        <li>
          <p>¿Recibiré una copia de las fotos?</p>
          <p>
            No, tus fotos serán cargadas directamente a tu plataforma con la
            marca de agua de {companyConfig.name}.
          </p>
        </li>
      </ul>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="photos"
    data-title="¿Cómo puedo solicitar video de una propiedad?"
    data-url="como-puedo-solicitar-video-de-una-propiedad"
    className="wrapper-article"
  >
    <h4>¿Cómo puedo solicitar video de una propiedad?</h4>
    <div className="content">
      <p>
        En {companyConfig.name} estamos comprometidos por ofrecerte mejores herramientas que
        te permitan vender tus propiedades más rápido, es por eso que ofrecemos
        servicio de Video de propiedades, sin embargo al no estar incluido en tu
        paquete, requieres una autorización especial para poder realizarlo y
        hacer el cambio por tus sesiones de fotos mensuales. Puedes realizar la
        solicitud de video directamente en{' '}
        <span className="tag">
          Soporte Administrativo > Solicitud de Sesión de video
        </span>
        .
      </p>
      <p>
        Requerimos que sigas el siguiente protocolo para solicitar tu sesión.
      </p>
      <p>
        Favor de indicarnos los siguientes datos para programar tu sesión de
        video:
      </p>

      <ul>
        <li>Dirección exacta y referencias.</li>
        <li>Fecha y horario disponible para recibir a un fotógrafo.</li>
        <li>
          Si tienes alguna solicitud particular para tu sesión de fotos, no
          dudes en hacernos saber tus comentarios.
        </li>
      </ul>
      <div className="tag success">
        <p>
          No olvides confirmar que cuentas con un contrato de intermediacion y
          el IFE del propietario, además de tener la exclusiva de la propiedad.
        </p>
      </div>

      <p>
        Tambien puedes solicitar una sesión de foto desde tu Módulo de
        Publicación de una propiedad. Déjate guiar por nuestro módulo.
      </p>
      <ul>
        <li>
          <p>¿Cúal es el plazo de la solicitud de sesión de video?</p>
          <p>
            Podemos organizar sesiones dentro de un plazo de 24h. Será posible
            cambiar la fecha y horario de tu sesión hasta con 12 horas de
            anticipación, en caso de NO ser cancelada se tomará como realizada.
          </p>
        </li>
        <li>
          <p>¿Cuando recibiré mis fotos?</p>
          <p>
            Recibirás tus fotos 24 horas después de la sesión y 48 horas en
            fines de semana. En caso de emergencia, te invitamos a contactarnos
            al {CONTACT_PHONE} en un horario de Lunes a Viernes de 9:00 am a 6:00 pm.
          </p>
        </li>
      </ul>

      <p>
        Te compartimos algunos tips para sacar el mayor provecho de tus
        sesiones:
      </p>
      <ol>
        <li>
          Ten limpio y ordenado el inmueble antes de la cita para tu sesión.
        </li>
        <li>Los colores claros y neutros amplian visualmente el espacio.</li>
        <li>
          El tener una buena iluminación es de vital importancia, revisa toda la
          iluminación de tu hogar tanto natural como artificial.
        </li>
      </ol>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="photos"
    data-title="¿Cómo puedo solicitar drone de una propiedad?"
    data-url="como-puedo-solicitar-drone-de-una-propiedad"
    className="wrapper-article"
  >
    <h4>¿Cómo puedo solicitar drone de una propiedad?</h4>
    <div className="content">
      <p>
        En {companyConfig.name} estamos comprometidos por ofrecerte novedosas herramientas que
        te permitan captar más contactos, es por eso que ofrecemos servicio de
        Drone de propiedades, sin embargo al no estar incluido en tu paquete,
        requieres una autorización especial para poder realizarlo y hacer el
        cambio por tus sesiones de fotos mensuales. Puedes realizar la solicitud
        de video directamente en
        <br />
        <span className="tag">
          Soporte Administrativo > Solicitud de Sesión de drone
        </span>
      </p>

      <p>
        Requerimos que sigas el siguiente protocolo para solicitar tu sesión.
      </p>

      <p>
        Favor de indicarnos los siguientes datos para programar tu sesión de
        drone:
      </p>

      <ul>
        <li>Dirección exacta y referencias</li>
        <li>Fecha y horario disponible para recibir a un fotógrafo.</li>

        <li>
          Si tienes alguna solicitud particular para tu sesión de fotos, no
          dudes en hacernos saber tus comentarios.
        </li>
      </ul>
      <div className="tag success">
        <p>
          No olvides confirmar que cuentas con un contrato de intermediacion y
          el IFE del propietario, además de tener la exclusiva de la propiedad.
        </p>
      </div>

      <p>
        Tambien puedes solicitar una sesión de foto desde tu Módulo de
        Publicación de una propiedad. Déjate guiar por nuestro módulo.
      </p>

      <ul>
        <li>
          <p>¿Cúal es el plazo de la solicitud de sesión de video?</p>
          <p>
            Podemos organizar sesiones dentro de un plazo de 24h. Será posible
            cambiar la fecha y horario de tu sesión hasta con 12 horas de
            anticipación, en caso de NO ser cancelada se tomará como realizada.
          </p>
        </li>
        <li>
          <p>¿Cuando recibiré mis fotos?</p>
          <p>
            Recibirás tus fotos 24 horas después de la sesión y 48 horas en
            fines de semana. En caso de emergencia, te invitamos a contactarnos
            al {CONTACT_PHONE} en un horario de Lunes a Viernes de 9:00 am a 6:00 pm.
          </p>
        </li>
      </ul>

      <p>
        Te compartimos algunos tips para sacar el mayor provecho de tus
        sesiones:
      </p>
      <ol>
        <li>
          Ten limpio y ordenado el inmueble antes de la cita para tu sesión.
        </li>
        <li>Los colores claros y neutros amplian visualmente el espacio.</li>
        <li>
          El tener una buena iluminación es de vital importancia, revisa toda la
          iluminación de tu hogar tanto natural como artificial.
        </li>
      </ol>
    </div>
    <style jsx>{styles}</style>
  </article>
]
