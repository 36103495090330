import css from 'styled-jsx/css'
import { colors, fontSizes, fontWeights, measurements } from 'theme'

export const calendarStyles = css`
  .title-date {
    border: none;
    margin: 1rem;
  }
  .title-date :global(> button.button-title) {
    height: auto;
    text-align: center;
  }
  .title-date .title {
    min-height: 4rem;
    font-size: ${fontSizes.h4};
    display: flex;
    align-items: center;
  }
  .calendar-body {
    display: flex;
    align-items: center;
  }

  .calendar-body > :global(.button-control-calendar > i) {
    padding: 0;
    margin: 0;
    display: inline-flex;
  }
  .calendar-body > :global(.button-control-calendar) {
    padding: 0.5rem;
  }

  ul.calendar-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }
`

export const dateElementStyles = css`
  .date-element-container {
    border: none;
    margin: 0;
    padding: 0.5rem;
    background-color: ${colors.gray};
    border-radius: ${measurements.borderRadius};
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${colors.sencondary};
    width: 3rem;
    transition: all 0.1s;
    outline: none;
  }
  .date-element-container:focus {
    box-shadow: 0 0 0px 3px ${colors.alpha(colors.secondary, 0.4)};
  }
  .date-element-container .date-element-number {
    font-weight: ${fontWeights.semiBold};
    font-size: ${fontSizes.h4};
  }
  .date-element-container.is-selected {
    color: ${colors.white};     
    background-color: ${colors.primary};
  }
`
