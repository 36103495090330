import React, { useState } from 'react'

import { IonIcon, Button } from 'controls'

import styles from './styles'

const ShowMore = ({ children, label = 'opciones' }) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <div className="show-more-container">
        <Button outline onClick={() => setShow(!show)} color="primary">
          <IonIcon icon={show ? 'ios-remove-circle' : 'ios-add-circle'} />
          <span className="show-more-label">{`Mostrar ${
            show ? 'menos' : 'más'
          } ${label}`}</span>
        </Button>

        <style jsx>{styles}</style>
      </div>
      {show && <div>{children}</div>}
    </>
  )
}

export default ShowMore
