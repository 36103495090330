import { fonts, fontSizes, measurements, colors } from 'app/theme'
import css from 'styled-jsx/css'

export default css`
  .label {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    color: ${colors.white};
    border-radius: ${measurements.borderRadius};
    width: auto;
    height: auto;
  }
  .label.rounded {
    border-radius: 3.125rem;
  }
  .label.disabled {
    cursor: not-allowed;
  }
  .label.tertiary {
    background-color: ${colors.tertiary};
    color: ${colors.frame};
  }
  .label.danger {
    background-color: ${colors.danger};
  }
  .label.dangerLow {
    background-color: ${colors.dangerLow};
  }
  .label.info {
    background-color: ${colors.info};
  }
  .label.none {
    background-color: transparent;
    color: ${colors.text};
  }
  .label.primary {
    background-color: ${colors.primary};
  }
  .label.secondary {
    background-color: ${colors.secondary};
  }
  .label.success {
    background-color: ${colors.success};
  }
  .label.lightSuccess {
    color: ${colors.text};
    background-color: ${colors.alpha(colors.primary, 0.4)};
  }
  .label.lightWarning {
    color: ${colors.text};
    background-color: ${colors.alpha(colors.warning, 0.4)};
  }
  .label.warning {
    background-color: ${colors.warning};
  }
  .label.text {
    background-color: ${colors.text};
  }
  .label.purple {
    background-color: ${colors.purple};
  }
  .label.darkFrame {
    background-color: ${colors.darkFrame};
  }
  .label.babyBlue {
    background-color: ${colors.babyBlue}
  }

  .label.micro {
    font-size: ${fontSizes.micro};
    padding: 0.1rem 0.5rem;
  }
  .label.tiny {
    font-size: ${fontSizes.tiny};
    padding: 0.1rem 0.5rem;
  }
  .label.tiny :global(i) {
    font-size: ${fontSizes.small};
  }
  .label.small {
    font-size: ${fontSizes.small};
    padding: 0.1rem 0.5rem;
  }
  .label.normal {
    font-size: ${fontSizes.p};
    padding: 0.35rem 1rem;
  }
  .label.medium {
    font-size: ${fontSizes.body};
    padding: 0.75rem;
  }
  .label.large {
    font-size: ${fontSizes.h4};
    padding: 1rem;
  }
  .label.rounded {
    border-radius: 3.125rem;
  }
  .label.nexsite1-side-search {
    background-color: ${colors.lightBlueBG};
    color: ${colors.frame};
    font-weight: 600;
    margin: 0 0.5rem 0.625rem 0;
    padding: 0.3125rem;
  }
  .label.nexsite1-header {
    background-color: ${colors.lightText};
  }

  .label.clickable {
    cursor: pointer;
  }
`

export const withRemove = css`
  .disabled {
    cursor: not-allowed;
  }
  .button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0 0 0 0.5rem;
  }
  .button:disabled {
    cursor: not-allowed;
  }
`
