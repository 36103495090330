import { initialFeedback } from 'reducers/utils'

const CONTRACT_NAMES = {
  'service-contract': 'Prestación de Servicios',
  'network-contract': 'Contrato de RED',
  'code-conduct': 'Código de ética',
}

// prettier-ignore
const CONTRACT_STATUS_EQUIVALENTS = new Map([
  [null, 'PENDING'],                            // It needs to be created
  ["PENDING_SIGNATURE", 'SUCCESS'],             // It was created
  ["CREATED", 'ERROR'],                         // CREATED is the first status of a contract
                                                // It means that the contract was created but
                                                // it was not sent successfully to weesign
])

export function contractSelector(contract) {
  return {
    ...contract,
    documentType: contract.documentType,
    name: CONTRACT_NAMES[contract.documentType] || contract.documentType,
    status: CONTRACT_STATUS_EQUIVALENTS.get(contract.status) || contract.status,
    feedback: initialFeedback(),
  }
}

export function pendingContractsSelector(contracts) {
  return contracts
    .map(contractSelector)
    .filter(contract => contract.status === 'PENDING')
}

export function subscriptionSelector(suscription) {
  return {
    ...suscription,
    status: suscription.status === 'COMPLETED' ? 'SUCCESS' : suscription.status,
  }
}

export function summarySelector(summary) {
  return {
    ...summary,
    agent: summary.user,
    suscription: subscriptionSelector(summary.subscription),
    contracts: summary.contracts.map(contractSelector),
  }
}
