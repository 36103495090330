import jwtDecode from 'jwt-decode'
import { createReducer, createActions } from 'reduxsauce'
import { parseToken } from 'app/selectors'
import config from 'config'
import moment from 'moment'

function parseProfile(profile) {
  if (!profile || !profile.mailbox) {
    return profile
  }

  return {
    ...profile,
    userMailbox: `${profile.mailbox}@${profile.mailboxDomain || ''}`
  }
}

const PAYMENT_REMAINDER_DAYS = parseInt(config('PAYMENT_REMAINDER_DAYS'))

const UNAUTHORIZED_STATE = {
  isAuthenticated: false,
  isUserRequestingLogOut: false,
  authenticating: false,
  refreshingToken: false,
  tokenRefreshed: false,
  isPaymentRemainderOpen: false,
  token: null,
  contactProfile: {},
  originalContactToken: null,
  profile: {},
  loggedUserType: null,
  refreshToken: null
}

const INITIAL_STATE = {
  ...UNAUTHORIZED_STATE,
  emailConfirmationLoading: false,
  emailConfirmed: false,
  emailConfirmationError: null,
  teamAssistant: {
    isOpenTeamRemainder: false,
    teamRemainderWasShown: false,
  }
}

const { Types, Creators } = createActions(
  {
    loginRequest: ['email', 'password'],
    loginSuccess: ['response'],
    externalLoginSuccess: ['response'],
    loginFailure: [],

    logoutRequest: [],
    externalLogoutRequest: [],

    refreshTokenRequest: [],
    refreshTokenSuccess: ['refreshData'],
    refreshTokenComplete: [],
    refreshTokenFailure: ['error'],
    refreshPaymentData: [],

    confirmEmailRequest: ['token'],
    confirmEmailSuccess: ['response'],
    confirmEmailFailure: ['error'],

    getContactTokenForLegacyRequirements: ['binLeadId'],
    updateOriginalContactToken: ['originalContactToken'],

    setOpenTeamRemainder: ['isOpenTeamRemainder'],
    setTeamRemainderWasShown: ['teamRemainderWasShown'],

    hidePaymentRemainder: []
  },
  { prefix: 'AUTH_' }
)

const loginRequest = (state, { email, password }) => ({
  ...state,
  authenticating: true,
  isAuthenticated: false
})

const loginSuccess = (state, { response }) => {
  const today = moment(new Date()).format('YYYY/MM/DD')

  const { mailbox, subscriptions, roles } = response.profile || {}

  let sortedSubsc =
    subscriptions &&
    subscriptions
      .filter(s => s.status === 'ACTIVE' && s.expiresAt)
      .map(({ expiresAt }) => moment(expiresAt))

  const finishedPlan = sortedSubsc && sortedSubsc[0]

  const loggedUserType =
    roles && roles.length > 0 && subscriptions && subscriptions.length > 0
      ? 'broker'
      : 'contact'

  return {
    ...state,
    finishedPlan,
    loggedUserType,
    authenticating: false,
    isAuthenticated: true,
    isUserRequestingLogOut: false,
    token: response.token,
    profile: parseProfile(response.profile),
    refreshToken: response.token,
    isPaymentRemainderOpen:
      (mailbox &&
        finishedPlan &&
        moment(finishedPlan).diff(today, 'days') < PAYMENT_REMAINDER_DAYS) ||
      false
  }
}

const loginSuccessSignup = (state, { response }) => {
  const decodedToken = jwtDecode(response.token)
  const roles = decodedToken ? decodedToken.roles : []
  if (roles.includes('LANDLORD') || roles.includes('BROKER')) {
    return {
      ...state,
      authenticating: false,
      isAuthenticated: true,
      token: response.token,
      refreshToken: response.token
    }
  }
  return state
}

const loginFailure = state => ({
  ...state,
  authenticating: false,
  isAuthenticated: false
})

const logoutRequest = state => ({ 
  ...INITIAL_STATE, 
  tokenRefreshed: true, 
  isUserRequestingLogOut: true 
})

const refreshTokenRequest = state => {
  return { ...state, refreshingToken: true }
}
const refreshTokenSuccess = (state, { refreshData }) => {
  const nextState = {
    ...state,
    refreshingToken: false,
    isAuthenticated: true,
    tokenRefreshed: true
  }

  // in case the refresh was just validating that the token hadn't expired
  if (refreshData.token) {
    nextState['token'] = refreshData.token
    nextState['refreshToken'] = refreshData.token
  }

  // update the profile
  if (refreshData.profile) {
    nextState['profile'] = parseProfile(refreshData.profile)
  }

  return nextState
}
const refreshTokenFailure = (state, { error }) => ({
  ...state,
  ...UNAUTHORIZED_STATE,
  tokenRefreshed: true
})

const refreshPaymentData = state => state

const confirmEmailRequest = (state, { token }) => {
  return {
    ...state,
    emailConfirmationLoading: true,
    emailConfirmed: false
  }
}

const confirmEmailSuccess = (state, { response }) => {
  return {
    ...state,
    emailConfirmationLoading: false,
    emailConfirmed: true
  }
}

const confirmEmailFailure = (state, { error }) => {
  return {
    ...state,
    emailConfirmationLoading: false,
    emailConfirmed: false,
    emailConfirmationError: error
  }
}

const updateOriginalContactToken = (state, { originalContactToken }) => ({
  ...state,
  contactProfile: parseToken(originalContactToken) || {},
  originalContactToken
})

const hidePaymentRemainder = state => ({
  ...state,
  isPaymentRemainderOpen: false
})

const setOpenTeamRemainder = (state, { isOpenTeamRemainder }) => ({
  ...state,
  teamAssistant: {
    ...state.teamAssistant,
    isOpenTeamRemainder,
  }
})

const setTeamRemainderWasShown = (state, { teamRemainderWasShown }) => ({
  ...state,
  teamAssistant: {
    ...state.teamAssistant,
    teamRemainderWasShown,
  }
})

const HANDLERS = {
  [Types.LOGIN_REQUEST]: loginRequest,
  STORE_OAUTH_ACCESS_TOKEN_SUCCESS: loginSuccess,
  STORE_OAUTH_ACCESS_CONTACT_TOKEN_SUCCESS: loginSuccess,
  STORE_OAUTH_SIGNUP_SUCCESS: loginSuccessSignup,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.EXTERNAL_LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.LOGOUT_REQUEST]: logoutRequest,
  [Types.EXTERNAL_LOGOUT_REQUEST]: logoutRequest,

  [Types.REFRESH_TOKEN_REQUEST]: refreshTokenRequest,
  [Types.REFRESH_TOKEN_SUCCESS]: refreshTokenSuccess,
  [Types.REFRESH_TOKEN_FAILURE]: refreshTokenFailure,
  [Types.REFRESH_PAYMENT_DATA]: refreshPaymentData,

  [Types.CONFIRM_EMAIL_REQUEST]: confirmEmailRequest,
  [Types.CONFIRM_EMAIL_SUCCESS]: confirmEmailSuccess,
  [Types.CONFIRM_EMAIL_FAILURE]: confirmEmailFailure,

  [Types.UPDATE_ORIGINAL_CONTACT_TOKEN]: updateOriginalContactToken,
  [Types.SET_OPEN_TEAM_REMAINDER]: setOpenTeamRemainder,
  [Types.SET_TEAM_REMAINDER_WAS_SHOWN]: setTeamRemainderWasShown,

  [Types.HIDE_PAYMENT_REMAINDER]: hidePaymentRemainder
}

export const AuthActionTypes = Types
export const AuthActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
