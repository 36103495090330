const isTagManager = () => {
  if (typeof dataLayer !== 'undefined') {
    return true
  }
  console.log("There isn't tag manager")
  return false
}

const pushTag = props => {
  if (isTagManager()) {
    dataLayer.push(props)
  }
}

const pageViewEvent = (
  section,
  action,
  { extraProps = {}, pageProps = {} } = {}
) => {
  if (!section || !action) {
    return Promise.reject()
  }
  const { path, title } = config[section][action]
  pushTag({
    event: 'pageview',
    page: {
      path,
      title,
      ...pageProps
    },
    ...extraProps
  })

  return Promise.resolve()
}

const tagEvent = (section, action, extraProps) => {
  if (!config[section] || !config[section][action]) {
    return Promise.reject('Not event found')
  }

  const event = config[section][action](extraProps)

  pushTag(event)

  return Promise.resolve(event)
}

const config = {
  'contact-journey': {
    'redirect': {
      title: 'Redirect for block content',
      path: '/contact-journey/redirect/'
    },
    'login': {
      title: 'Mount login view',
      path: '/contact-journey/login/'
    },
    'login-password-field': {
      title: 'Focus password field',
      path: '/contact-journey/login/password-field/'
    },
    'login-password-confirm-btn': {
      title: 'Click on confirm button contact login',
      path: '/contact-journey/login/password-confirm-btn/'
    },
    'login-password-cancel-btn': {
      title: 'Click on cancel button contact login',
      path: '/contact-journey/login/password-cancel-btn/'
    },
    'index': {
      title: 'Main contact view',
      path: '/contact-journey/index/'
    },
    'sections-liked': {
      title: 'Liked properties section',
      path: '/contact-journey/sections/liked/'
    },
    'sections-meeting': {
      title: 'Make meeting section',
      path: '/contact-journey/sections/meeting/'
    },
    'sections-help': {
      title: 'Help section',
      path: '/contact-journey/sections/help/'
    },
    'contact-broker-actions': {
      title: 'Toogle button to show contact broker options',
      path: '/contact-journey/contact-broker-actions/'
    },
    'contact-broker-actions-email': {
      title: 'Send a email to broker',
      path: '/contact-journey/contact-broker-actions/email/'
    },
    'contact-broker-actions-whatsapp': {
      title: 'Send a whatsapp to broker',
      path: '/contact-journey/contact-broker-actions/whatsapp/'
    },
    'contact-broker-actions-call-me': {
      title: 'Require re-call to contact',
      path: '/contact-journey/contact-broker-actions/call-me/'
    },
    'contact-broker-actions-call-now': {
      title: 'Make call to broker',
      path: '/contact-journey/contact-broker-actions/call-now/'
    },
    'offer-init-process': {
      title: 'Display offer form',
      path: '/contact-journey/offer/init-process/'
    },
    'offer-cancel-process': {
      title: 'Close offer form',
      path: '/contact-journey/offer/cancel-process/'
    },
    'offer-finished-process': {
      title: 'Finished offer',
      path: '/contact-journey/offer/finished-process/'
    },
    'like-property-init-process': {
      title: 'Like (scoring) property init',
      path: '/contact-journey/like-property/init-process/'
    },
    'like-property-cancel-process': {
      title: 'Like (scoring) property cancel',
      path: '/contact-journey/like-property/cancel-process/'
    },
    'like-property-finished-process': {
      title: 'Like (scoring) property finished',
      path: '/contact-journey/like-property/finished-process/'
    },
    'dislike-property-init-process': {
      title: 'Dislike (reject) property init',
      path: '/contact-journey/dislike-property/init-process/'
    },
    'dislike-property-cancel-process': {
      title: 'Dislike (reject) property cancel',
      path: '/contact-journey/dislike-property/cancel-process/'
    },
    'dislike-property-finished-process': {
      title: 'Dislike (reject) property finished',
      path: '/contact-journey/dislike-property/finished-process/'
    },
    'share-public-requirement-init-process': {
      title: 'Share public requirement init',
      path: '/contact-journey/share-public-requirement/init-process/'
    },
    'share-public-requirement-cancel-process': {
      title: 'Share public requirement cancel',
      path: '/contact-journey/share-public-requirement/cancel-process/'
    },
    'share-public-requirement-finished-process': {
      title: 'Share public requirement finished',
      path: '/contact-journey/share-public-requirement/finished-process/'
    },
    'make-meeting-add-property': {
      title: 'Add property to make a meeting',
      path: '/contact-journey/make-meeting/add-property/'
    },
    'make-meeting-remove-property': {
      title: 'Remove property to make a meeting',
      path: '/contact-journey/make-meeting/remove-property/'
    },
    'make-meeting-touch-calendar': {
      title: 'Select any date from calendar',
      path: '/contact-journey/make-meeting/touch-calendar/'
    },
    'make-meeting-touch-time-to-contact': {
      title: 'Select any time to be contact',
      path: '/contact-journey/make-meeting/touch-time-to-contact/'
    },
    'make-meeting-init-process': {
      title: 'Make meeting init process',
      path: '/contact-journey/make-meeting/init-process/'
    },
    'make-meeting-cancel-process': {
      title: 'Cancel meeting process',
      path: '/contact-journey/make-meeting/cancel-process/'
    },
    'make-meeting-finished-process': {
      title: 'Finished meeting process',
      path: '/contact-journey/make-meeting/finished-process/'
    },
    'help-call-link': {
      title: 'Open help phone number',
      path: '/contact-journey/help/call-link/'
    },
    'help-mail-link': {
      title: 'Open help mail mail',
      path: '/contact-journey/help/mail-link/'
    },
    'sections-vip-detail': {
      title: 'Vip property opened',
      path: '/contact-journey/sections/vip/detail/'
    },
    'sections-vip-like-property-init-process': {
      title: 'Like (scoring) property from VIP init',
      path: '/contact-journey/sections/vip/like-property/init-process/'
    },
    'sections-vip-like-property-cancel-process': {
      title: 'Like (scoring) property from VIP cancel',
      path: '/contact-journey/sections/vip/like-property/cancel-process/'
    },
    'sections-vip-like-property-finished-process': {
      title: 'Like (scoring) property from VIP finished',
      path: '/contact-journey/sections/vip/like-property/init-process/'
    },
    'sections-vip-dislike-property-init-process': {
      title: 'Dislike (reject) property from VIP init',
      path: '/contact-journey/sections/vip/dislike-property/init-process/'
    },
    'sections-vip-dislike-property-cancel-process': {
      title: 'Dislike (reject) property from VIP cancel',
      path: '/contact-journey/sections/vip/dislike-property/cancel-process/'
    },
    'sections-vip-dislike-property-finished-process': {
      title: 'Dislike (reject) property from VIP finished',
      path: '/contact-journey/sections/vip/dislike-property/init-process/'
    }
  },

  'new-property-valuation': {
    'srp-link': extraProps => ({
      type: 'linkClick',
      name: 'Link to new property valuation was clicked from srp page',
      path: extraProps.path
    }),
    'header-link': extraProps => ({
      type: 'linkClick',
      name: 'Link to new property valuation was clicked from header menu',
      path: extraProps.path
    }),
  },

  'vip-public': {
    'get-brochure': extraProps => ({
      type: 'click',
      name: `User requests a brochure - Property Id ${extraProps.propertyId}`,
      path: extraProps.path
    }),
    'send-contact': extraProps => ({
      type: 'click',
      name: `Send contact from VIP contact form - Property Id ${extraProps.propertyId}`,
      path: extraProps.path
    }),
  },

  'visitor-journey': {
    'hero-srp-make-search': extraProps => ({
      type: 'click',
      name: 'SRP - Visitor made main search (search action button)',
      path: extraProps.path
    }),
    'hero-srp-property-type-change': extraProps => ({
      type: 'click',
      name: `SRP - Property type changes: ${extraProps.propertyType}`,
      path: extraProps.path
    }),
    'hero-srp-operation-type-change': extraProps => ({
      type: 'click',
      name: `SRP - Operation type changes: ${extraProps.operationType}`,
      path: extraProps.path
    }),
    'hero-srp-select-suggested-place': extraProps => ({
      type: 'click',
      name: `SRP - Search box - Visitor selects suggested place: ${extraProps.selectedPlace}`,
      path: extraProps.path
    }),
  }
}

export default { tagEvent, pageViewEvent, pushTag, tagManagerConfig: config }
