import Router from 'next/router'
import { throttle, delay, call, take, put, select, cancelled } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import jwtDecode from 'jwt-decode'
import { REHYDRATE } from 'redux-persist'
import { AuthActionCreators } from 'app/reducers/AuthRedux'
import { getTokenFields, parseToken } from 'app/selectors'
import api from 'app/services/api'
import apiStore from 'services/store/api'
import { getNeximoBroadcastChannel } from 'services/seo'

export function* login({ email, password }) {
  const response = yield call(api.auth.login, { email, password })

  if (response.status === 200) {
    yield put(AuthActionCreators.loginSuccess(response.data))
  } else {
    yield put(AuthActionCreators.loginFailure(response.data))
  }
}

export function* rehydrationWatcher() {
  yield take(REHYDRATE)

  yield refreshToken()
}

function refreshTimer(timeStamp) {
  // TODO: user WebWorkers to only call when the token is about to expire
  // the background
  // const milliseconds = timeStamp - new Date().getTime()
  // Token now is valid for a whole day, we update it every 15 minutes
  // This is also for logout a blocked user
  const milliseconds = 60 * 60 * 15

  return eventChannel(emitter => {
    const timerId = setTimeout(() => {
      emitter(true)
    }, milliseconds)

    // unsubscribe function
    return () => {
      clearTimeout(timerId)
    }
  })
}

export function* tokenRefreshClock() {
  const decodedToken = yield select(getTokenFields)
  const tokenRefreshTick = yield call(refreshTimer, decodedToken.exp * 1000 - 30000)

  const timerResult = yield take(tokenRefreshTick)
  if (timerResult === true) {
    yield put(AuthActionCreators.refreshTokenRequest())
  }

  tokenRefreshTick.close()
}

export function* callRefreshToken(decodedToken, refreshToken) {
  if (decodedToken !== null) {
    if (decodedToken.exp * 1000 - new Date().getTime() > 0) {
      let response = {}

      const loggedUserType = yield select(state => state.auth.loggedUserType)

      if(loggedUserType === 'contact') {
        response = yield call(apiStore.oauth.refreshContactToken, null, { token: refreshToken })
      } else {
        const fingerprint = yield select(state => state.userSession.fingerprint)
        response = yield call(apiStore.oauth.refreshToken, null, { token: refreshToken, fingerprint })
      }

      if (response.status === 200 || response.status === 202) {
        yield put(AuthActionCreators.refreshTokenSuccess(response.data))
        yield put(AuthActionCreators.refreshTokenComplete())
        return
      }

      yield put(AuthActionCreators.refreshTokenFailure(response.data))
      yield put(AuthActionCreators.refreshTokenComplete())
      return
    }

    yield put(AuthActionCreators.refreshTokenFailure({ message: 'Token already expired'}))
    yield put(AuthActionCreators.refreshTokenComplete())
    return
  }

  yield put(AuthActionCreators.refreshTokenFailure({ message: 'Token is null'}))
  yield put(AuthActionCreators.refreshTokenComplete())
}

export function* refreshTokenWithToken({ token }) {
  const decodedToken = parseToken(token)
  yield callRefreshToken(decodedToken, token)
}

export function* refreshToken() {
  const decodedToken = yield select(getTokenFields)
  const refreshToken = yield select(state => state.auth.token)
  yield callRefreshToken(decodedToken, refreshToken)
}

export function* getContactTokenForLegacyRequirements({ binLeadId }) {
  const response = yield call(api.auth.getContactToken, binLeadId)
  
  if(response.status === 200) {
    Router.push({
      pathname: '/bin',
      query: { token: response.data.token, requirement: binLeadId }
    },
    {
      pathname: `/bin/${binLeadId}`,
      query: { token: response.data.token,requirement: binLeadId }
    })
    return yield put(AuthActionCreators.updateOriginalContactToken(response.data.token))
  }
}


export function* confirmEmail({ token }) {
  const response = yield call(api.auth.confirmEmail, token)

  // user has been authenticated
  if (response.status === 200) {
    yield put(AuthActionCreators.loginSuccess(response.data))
    yield put(AuthActionCreators.confirmEmailSuccess(response.data))
    return
  }

  yield put(AuthActionCreators.confirmEmailFailure(response.data))
}

export function* externalLogoutRequest() {
  const bc = getNeximoBroadcastChannel()

  if (bc) {
    bc.postMessage(
      AuthActionCreators.externalLogoutRequest()
    )
  }
}

export function* externalLoginSuccess({ response }) {
  const bc = getNeximoBroadcastChannel()
  if (bc) {
    bc.postMessage(
      AuthActionCreators.externalLoginSuccess(response)
    )
  }
}