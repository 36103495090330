import { put } from 'redux-saga/effects'
import { NeximerPropertyActionCreators } from 'app/reducers/NeximerPropertyRedux'
import { NeximerProperty } from 'services/api/entities'

export function* getProperties({ brokerId, params = {} }) {
  const response = yield NeximerProperty.all({
    ...params,
    broker__pk: brokerId,
  })

  if (response.success()) {
    return yield put(
      NeximerPropertyActionCreators.getPropertiesSuccess(
        params,
        response.data.results,
        response.data.count,
      )
    )
  }

  yield put(NeximerPropertyActionCreators.getPropertiesFailure(response.data, params))
}
