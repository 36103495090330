import { put } from 'redux-saga/effects'

import { DocumentationFiles } from 'services/api/entities'
import { DocumentationFilesActionCreators } from 'app/reducers/DocumentationFilesRedux'

export function* all({ folderId, params }) {
  const response = yield DocumentationFiles.all(folderId, params)
  
  if (response.success()) {
    yield put(
      DocumentationFilesActionCreators.allSuccess(
        response.data
      )
    )
  } else {
    yield put(DocumentationFilesActionCreators.allFailure(response.data))
  }
}
