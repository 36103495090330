import ApiBase from 'app/services/api/entities/Base'

function* createSession(data) {
  return yield ApiBase.privatePost('/v1/user_sessions/', { data })
}

function* sessions() {
  return yield ApiBase.privateGet('/v1/user_sessions/')
}

function* logoutSession(id) {
  return yield ApiBase.privatePost(`/v1/user_sessions/${id}/logout/`)
}

function* logoutSessions(sessions) {
  return yield ApiBase.privatePost(`/v1/user_sessions/logout_devices/`, {
    data: { sessions },
  })
}

function* deviceInfo(userAgent) {
  return yield ApiBase.privatePost('/v1/user_sessions/device_info/', {
    data: { userAgent },
  })
}

function* revalidateSession(id) {
  return yield ApiBase.privatePost(`/v1/user_sessions/${id}/revalidate_session/`)
}

export default {
  createSession,
  sessions,
  logoutSession,
  logoutSessions,
  deviceInfo,
  revalidateSession,
}
