import { createReducer, createActions } from 'reduxsauce'
import {
  failureFeedback,
  initialFeedback,
  requestFeedback,
  successFeedback,
  updateHashedState,
} from './utils'

const INITIAL_STATE = {
  recommendations: {
    recommendations: [],
    feedback: initialFeedback(),
  },
  inventory: {
    report: [],
    feedback: initialFeedback(),
  },
  inventoryQuality: {
    report: [],
    feedback: initialFeedback(),
  },
  contacts: {
    report: [],
    feedback: initialFeedback(),
  },
  billing: {
    report: [],
    feedback: initialFeedback(),
  },
  reportCSV: {
    report: [],
    feedback: initialFeedback(),
  }
}

const { Types, Creators } = createActions(
  {
    getRecommendations: ['recommendationType', 'params'],
    getRecommendationsSuccess: ['recommendations'],
    getRecommendationsFailure: ['error'],

    getInventoryReport: ['params'],
    getInventoryReportSuccess: ['report'],
    getInventoryReportFailure: ['error'],

    getInventaryQualityReport: ['params'],
    getInventaryQualityReportSuccess: ['report'],
    getInventaryQualityReportFailure: ['error'],

    getContactsReport: ['params'],
    getContactsReportSuccess: ['report'],
    getContactsReportFailure: ['error'],

    getBillingReport: ['params'],
    getBillingReportSuccess: ['report'],
    getBillingReportFailure: ['error'],

    getReportCSV: ['reportType','params'],
    getReportCSVSuccess: ['report'],
    getReportCSVFailure: ['error'],
    getReportCSVClean: [],
  },
  { prefix: 'NETWORK_REPORTS_' },
)

/**
 * Recommendations
 */

const getRecommendations = state => ({
  ...state,
  recommendations: {
    ...state.recommendations,
    feedback: requestFeedback(),
  },
})

const getRecommendationsSuccess = (state, { recommendations }) => ({
  ...state,
  recommendations: {
    ...state.recommendations,
    recommendations,
    feedback: successFeedback(),
  },
})

const getRecommendationsFailure = (state, { error }) => ({
  ...state,
  recommendations: {
    ...state.recommendations,
    feedback: failureFeedback(error),
  },
})

/**
 * Inventory
 */

const getInventoryReport = state => ({
  ...state,
  inventory: {
    ...state.inventory,
    feedback: requestFeedback(),
  },
})

const getInventoryReportSuccess = (state, { report }) => ({
  ...state,
  inventory: {
    ...state.inventory,
    report,
    feedback: successFeedback(),
  },
})

const getInventoryReportFailure = (state, { error }) => ({
  ...state,
  inventory: {
    ...state.inventory,
    feedback: failureFeedback(error),
  },
})

/**
 * Inventory Quality
 */

const getInventaryQualityReport = state => ({
  ...state,
  inventoryQuality: {
    ...state.inventory,
    feedback: requestFeedback(),
  },
})

const getInventaryQualityReportSuccess = (state, { report }) => ({
  ...state,
  inventoryQuality: {
    ...state.inventory,
    report,
    feedback: successFeedback(),
  },
})

const getInventaryQualityReportFailure = (state, { error }) => ({
  ...state,
  inventoryQuality: {
    ...state.inventory,
    feedback: failureFeedback(error),
  },
})

/**
 * Contacts
 */

const getContactsReport = state => ({
  ...state,
  contacts: {
    ...state.contacts,
    feedback: requestFeedback(),
  },
})

const getContactsReportSuccess = (state, { report }) => ({
  ...state,
  contacts: {
    ...state.contacts,
    report,
    feedback: successFeedback(),
  },
})

const getContactsReportFailure = (state, { error }) => ({
  ...state,
  contacts: {
    ...state.contacts,
    feedback: failureFeedback(error),
  },
})

/**
 * Billing
 */

const getBillingReport = state => ({
  ...state,
  billing: {
    ...state.billing,
    feedback: requestFeedback(),
  },
})

const getBillingReportSuccess = (state, { report }) => ({
  ...state,
  billing: {
    ...state.billing,
    report,
    feedback: successFeedback(),
  },
})

const getBillingReportFailure = (state, { error }) => ({
  ...state,
  billing: {
    ...state.billing,
    feedback: failureFeedback(error),
  },
})

/**
 * reportCSV
 */

const getReportCSV = state => ({
  ...state,
  reportCSV: {
    ...state.reportCSV,
    feedback: requestFeedback(),
  },
})

const getReportCSVSuccess = (state, { report }) => ({
  ...state,
  reportCSV: {
    ...state.reportCSV,
    report,
    feedback: successFeedback(),
  },
})

const getReportCSVFailure = (state, { error }) => ({
  ...state,
  reportCSV: {
    ...state.reportCSV,
    feedback: failureFeedback(error),
  },
})

const getReportCSVClean = state => ({
  ...state,
  reportCSV: INITIAL_STATE.reportCSV,
})

const HANDLERS = {
  [Types.GET_RECOMMENDATIONS]: getRecommendations,
  [Types.GET_RECOMMENDATIONS_SUCCESS]: getRecommendationsSuccess,
  [Types.GET_RECOMMENDATIONS_FAILURE]: getRecommendationsFailure,

  [Types.GET_INVENTORY_REPORT]: getInventoryReport,
  [Types.GET_INVENTORY_REPORT_SUCCESS]: getInventoryReportSuccess,
  [Types.GET_INVENTORY_REPORT_FAILURE]: getInventoryReportFailure,

  [Types.GET_INVENTARY_QUALITY_REPORT]: getInventaryQualityReport,
  [Types.GET_INVENTARY_QUALITY_REPORT_SUCCESS]: getInventaryQualityReportSuccess,
  [Types.GET_INVENTARY_QUALITY_REPORT_FAILURE]: getInventaryQualityReportFailure,

  [Types.GET_CONTACTS_REPORT]: getContactsReport,
  [Types.GET_CONTACTS_REPORT_SUCCESS]: getContactsReportSuccess,
  [Types.GET_CONTACTS_REPORT_FAILURE]: getContactsReportFailure,

  [Types.GET_BILLING_REPORT]: getBillingReport,
  [Types.GET_BILLING_REPORT_SUCCESS]: getBillingReportSuccess,
  [Types.GET_BILLING_REPORT_FAILURE]: getBillingReportFailure,

  [Types.GET_REPORT_CSV]: getReportCSV,
  [Types.GET_REPORT_CSV_SUCCESS]: getReportCSVSuccess,
  [Types.GET_REPORT_CSV_FAILURE]: getReportCSVFailure,
  [Types.GET_REPORT_CSV_CLEAN]: getReportCSVClean,
}

export const NetworkReportsActionTypes = Types
export const NetworkReportsActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
