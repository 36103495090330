import crudAction from './actions'

export default {
  name: 'contact',
  namespace: 'contacts',
  methods: [
    {
      name: 'footerContact',
      params: ['data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace }) => `/v1/${namespace}/footer_contact/`,
      urlParams: []
    },
  ]
}
