import jwtDecode from 'jwt-decode'
import moment from 'moment'
import { createSelector } from 'reselect'
import { CONTRACT_FEATURES } from 'app/constants/contractFeatures'

import {
  curateAddressData,
  getNeximoAddress,
  placeHolderMapData
} from 'services/google'

const app = state => state.app
const auth = state => state.auth
const googleMapsLocation = state => state.geo.locationDetails

const sessionDataMapping = (app, auth) => ({
  rehydrated: app.rehydrated,
  tokenRefreshed: auth.tokenRefreshed,
  authenticating: auth.authenticating,
  isAuthenticated: auth.isAuthenticated,
  isUserRequestingLogOut: auth.isUserRequestingLogOut,
  refreshingToken: auth.refreshingToken,
  token: auth.token
})

export const parseToken = token => {
  try {
    return jwtDecode(token)
  } catch (ex) {
    console.log(ex.message)
  }

  return null
}

const tokenFieldsMapping = auth => {
  const { token } = auth
  return parseToken(token)
}

const profileMapping = auth => (auth ? auth.profile : {})

export const getSessionData = createSelector([app, auth], sessionDataMapping)

export const getUserProfile = createSelector([auth], profileMapping)

export const userIdSelector = createSelector(
  getUserProfile,
  profile => profile.userId
)

export const getTokenFields = createSelector(auth, tokenFieldsMapping)

export const getUserRoles = createSelector(
  getTokenFields,
  decodedToken => (decodedToken ? decodedToken.roles : [])
)

export const getUserPlans = createSelector(
  getTokenFields,
  decodedToken => (decodedToken ? decodedToken.plans : [])
)

export const getUserAccesiblePages = createSelector(
  getUserProfile,
  profile => profile.accessibleDashboardPages || [])

export const getUserId = createSelector(
  getTokenFields,
  decodedToken => (decodedToken ? decodedToken.user_id : null)
)

export const isBroker = createSelector(getUserRoles, roles =>
  roles.includes('BROKER')
)

export const isClient = createSelector(getUserRoles, roles =>
  roles.includes('CLIENT')
)

export const getNeximerProfileContact = createSelector(
  getUserProfile,
  ({ fullName, email, telephone }) => ({
    neximerFullName: fullName,
    neximerEmail: email,
    neximerTelephone: telephone
  })
)

export const getLocationRequest = name => createSelector(
  googleMapsLocation,
  mapsData => mapsData[name] ? mapsData[name] : {
    loading: false,
    finished: false,
    error: null,
    request: null,
    response: null,
    location: null
  }

)

export const getGoogleMapsData = name => createSelector(
  googleMapsLocation,
  mapsData => mapsData[name] && mapsData[name].location ? mapsData[name].location : placeHolderMapData
)

export const getNewValuationFormErrors = createSelector(
  state => state.form.newValuationForm,
  newValuationForm => ({})
)

export const getEmailCofirmationData = createSelector(
  state => state.auth,
  authData => ({
    loading: authData.emailConfirmationLoading,
    confirmed: authData.emailConfirmed,
    error: authData.emailConfirmationError
  })
)

const dokaltinFileUpload = createSelector(
  state => state.dokaltin,
  dokaltinData => ({
    fileUploading: dokaltinData.fileUploading,
    fileUploaded: dokaltinData.fileUploaded,
    fileUploadError: dokaltinData.fileUploadError
  })
)

const dokaltinFiles = createSelector(
  state => state.dokaltin,
  dokaltinData => ({
    files: dokaltinData.files,
    filesLoading: dokaltinData.filesLoading,
    filesLoadError: dokaltinData.filesLoadError
  })
)

export const dokaltin = {
  fileUpload: dokaltinFileUpload,
  doks: dokaltinFiles
}

export const showNotYetPaidModalSelector = createSelector(
  getUserProfile,
  profile => {
    const hasPaid = Boolean(profile.hasPaid)
    const userStatus = profile.status

    return !(hasPaid && userStatus === 'ACTIVE')
  },
)

const contractFeatureSelector = createSelector(
  getUserProfile,
  profile => profile.contractFeatures || []
)

export const hasContractFeatureSelector = createSelector(
  contractFeatureSelector,
  (state, featureFlag) => featureFlag,
  (contractFeatures, featureFlag) =>
    Boolean(contractFeatures.find(feature => feature.includes(featureFlag))),
)

export const propertyDescriptionWithIASelector = state =>
  hasContractFeatureSelector(
    state,
    CONTRACT_FEATURES.PROPERTY_DESCRIPTION_WITH_IA,
  )

export const propertyImageProcessingWithAISelector = state => {
  return hasContractFeatureSelector(
    state,
    CONTRACT_FEATURES.IMAGE_PROCESSING_WITH_AI,
  )
}
