import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { IonIcon } from 'controls'
import { shallowEqual } from 'services/utils'
import { safeDecimal } from 'app/services/number'
import styles, { nonFieldErrors, textarea } from './styles'
import { Spinner } from 'controls'

const formatMonthTitle = (d, locale) => {
  return `${getMonthName(d)} ${d.getFullYear()}`
}

const formatWeekdayShort = (dayNumber, locale) => {
  return getDayShortName(dayNumber)
}

class Input extends Component {
  static defaultProps = {
    autoComplete: 'off',
    type: 'text',
    disabled: false,
    placeholder: '',
    label: '',
    value: '',
    onBlur: () => {},
    onKeyUp: () => {},
    onFocus: () => {},
    inputStyle: {},
    meta: {},
    step: 1,
  }

  static propTypes = {
    autoComplete: PropTypes.string,
    errorMsg: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    pattern: PropTypes.any,
    type: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyUp: PropTypes.func,
    onFocus: PropTypes.func,
    className: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.state = {
      hasValue: false,
      hasError: false,
      focused: false
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      hasValue: Boolean(props.value)
    })
  }

  onBlur = e => {
    this.setState({
      focused: false,
      hasValue: Boolean(e.currentTarget.value)
    })
    this.props.onBlur(e)
  }

  onFocus = e => {
    this.setState({
      focused: true
    })
    this.props.onFocus(e)
  }

  onPaste = e => {
    const {
      onPaste,
      disablePaste,
    } = this.props

    if (disablePaste) {
      e.preventDefault()
    }

    onPaste && onPaste(e)
  }

  render() {
    const {
      autoComplete,
      autoFocus,
      errorMsg,
      id,
      fat,
      name,
      disabled,
      required,
      pattern,
      placeholder,
      label,
      type,
      value,
      checked,
      onBlur,
      onKeyUp,
      onChange,
      style,
      loading,
      labelStyle,
      inputStyle,
      maxLength,
      meta: { valid = false, invalid = false, touched = false, error, warning }
    } = this.props

    const { focused } = this.state

    const isValid = valid && touched

    const isInvalid = invalid && touched

    const isWarning = warning && touched

    const inputClasses = classnames(
      'fl-input',
      { 'fl-valid': isValid },
      { 'fl-invalid': isInvalid }
    )

    const commonClasses = [{ 'fl-msg': touched }, { 'fl-show': touched }]

    const msgErrorClasses = classnames(...commonClasses, {
      'fl-error-msg': isInvalid
    })

    const msgValidClasses = classnames(...commonClasses, {
      'fl-success-msg': isValid
    })

    const msgWarningClasses = classnames(...commonClasses, { 
      'fl-warning-msg': isWarning
    })

    return (
      <div
        style={style}
        className={classnames(
          'fl-input-container',
          {
            focused,
            'has-value': value && value.length > 0,
            valid: isValid,
            disabled,
            error: isInvalid,
            fat,
            'is-checkbox': type === "checkbox",
          },
          this.props.className
        )}
        onClick={() => this.inputDom && this.inputDom.focus()}
      >
        {label && (
          <label className="fl-input-label" htmlFor={id} style={labelStyle}>
            {label}
          </label>
        )}
        <input
          autoFocus={autoFocus}
          onFocus={this.onFocus}
          autoComplete={autoComplete}
          className={inputClasses}
          disabled={disabled}
          required={required}
          value={value}
          checked={checked}
          id={id}
          name={name}
          placeholder={placeholder || label}
          onBlur={this.onBlur}
          onKeyUp={onKeyUp}
          onChange={onChange}
          onPaste={this.onPaste}
          type={type}
          ref={input => (this.inputDom = input)}
          style={{ ...inputStyle }}
          maxLength={maxLength}
          aria-label={label || this.props['aria-label'] || placeholder}
          step={type === "number" && this.props.step ? this.props.step : undefined}
          min={type === "number" && this.props.min ? this.props.min : undefined}
          max={type === "number" && this.props.max ? this.props.max : undefined}
        />
        {touched && valid && (
          <span className={msgValidClasses}>
            <IonIcon icon="android-done" className="success-icon" />
          </span>
        )}
        {touched && !valid && <span className={msgErrorClasses}>{error}</span> || warning && <spa className={msgWarningClasses} >{warning}</spa> }
        {loading && <div className="loading"><Spinner size={20} thickness={4}/></div>}
        <style jsx>{styles}</style>
      </div>
    )
  }
}

export default Input

export const InputForm = field => {
  return <Input {...field.input} {...field} />
}

export const NonFieldInputForm = field => {
  const {
    meta: { touched = false, error },
    className
  } = field
  return (
    <p className={classnames('fl-non-field-error', className, { touched })}>
      {error}
      <style jsx>{nonFieldErrors}</style>
    </p>
  )
}

export class ControlledInput extends Component {
  static propTypes = {
    currentValue: PropTypes.any
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.currentValue !== nextProps.currentValue) {
      this.props.input.onChange(nextProps.currentValue)
    }
  }

  render() {
    return (
      <Input
        {...this.props.input}
        {...this.props}
        value={this.props.currentValue}
      />
    )
  }
}

export class TextAreaInput extends Component {
  render() {
    const { className, meta = {}, styleWrapper = {}, label, rows, placeholder, ...rest } = this.props

    const {
      valid = false,
      invalid = false,
      touched = false,
      error,
      warning
    } = meta

    const isValid = valid && touched
    const isInvalid = invalid && touched

    return (
      <div
        className={classnames('wrapper-textarea-input', className)}
        style={{ styleWrapper }}
      >
        {label && <label className="fl-input-label">{label}</label>}
        <div
          className={classnames(
            'textarea-input',
            { 'textarea-valid': isValid },
            { 'textarea-invalid': isInvalid },
            { 'is-active-rows': typeof rows !== 'undefined' }
          )}
        >
          <textarea
            rows={rows}
            {...rest}
            placeholder={placeholder}
            aria-label={label || this.props['aria-label'] || placeholder}
          />
        </div>
        {touched && !valid && (
          <span className={'textarea-invalid-message'}>{error}</span>
        )}
        <style jsx>{textarea}</style>
      </div>
    )
  }
}

export class TextAreaInputForm extends Component {
  render() {
    const { input, ...rest } = this.props
    return <TextAreaInput {...input} {...rest} />
  }
}

const ObjectInput = ({ value, name }) => <input name={name} type="hidden" />

export class ControlledObjectInput extends Component {
  static propTypes = {
    currentValue: PropTypes.any
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!shallowEqual(this.props.currentValue, nextProps.currentValue)) {
      this.props.input.onChange(nextProps.currentValue)
    }
  }

  render() {
    return (
      <ObjectInput value={this.props.currentValue} name={this.props.name} />
    )
  }
}

export class InputHidden extends ControlledInput {
  render() {
    return <input {...this.props.input} type="hidden" />
  }
}

const getPercentage = percentage => Number(((Number(percentage) || 0) / 100).toFixed(4))

export const PercentageInputForm = ({ input, ...restProps }) => {
  const value = safeDecimal((Number(input.value) || 0) * 100)

  function onChange(e) {
    e.target.value = getPercentage(e.target.value)
    input.onChange(e)
  }

  function onBlur(e) {
    e.target.value = getPercentage(e.target.value)
    input.onBlur(e)
  }

  return (
    <InputForm
      {...restProps}
      type="number"
      step="0.01"
      min="0"
      max="100"
      input={{
        ...input,
        value,
        onChange,
        onBlur,
      }}
    />
  )
}