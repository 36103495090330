import config from 'config'
import { companyConfig } from 'globalConfigApp'

import styles from './styles'



const COMPANY_SHOP_URL = config('COMPANY_SHOP_URL')


export default [
  <article
    data-category="questions"
    data-title="Yo manejo una cartera grande de inversionistas ¿puedo ser Aliado
    únicamente para utilizar su red inmobiliaria para captar opciones para mis
    clientes compradores?"
    data-url="yo-manejo-una-cartera-grande-de-inversionistas-puedo-ser-aliado-unicamente-para-utilizar-su-red-inmobiliaria-para-captar-opciones-para-mis-clientes-compradores"
    className="wrapper-article"
  >
    <h4>
      Yo manejo una cartera grande de inversionistas ¿puedo ser Aliado
      únicamente para utilizar su red inmobiliaria para captar opciones para mis
      clientes compradores?
    </h4>
    <div className="content">
      <p>
        {companyConfig.name} cuenta con una bolsa inmobiliaria donde podrás encontrar todas
        las propiedades de {companyConfig.name} y propiedades publicadas dentro de otras
        bolsas. Así que no necesitas tener propiedades para ser aliado {companyConfig.name}.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="questions"
    data-title={`¿Cuento con algún espacio de ${companyConfig.name} para firmar los contratos con mis
    clientes?`}
    data-url="cuento-con-algun-espacio-de-neximo-para-firmar-los-contratos-con-mis-clientes"
    className="wrapper-article"
  >
    <h4>
      ¿Cuento con algún espacio de {companyConfig.name} para firmar los contratos con mis
      clientes?
    </h4>
    <div className="content">
      <p>
        {companyConfig.name} es una inmobiliaria digital. No contamos con oficinas en toda la
        República. Sin embargo, tenemos algunas oficinas físicas en México. Te
        invitamos a ver nuestra página de contacto para encontrar nuestras
        oficinas en:
      </p>
      <ul>
        <li>Ciudad de México</li>
        <li>Guadalajara</li>
        <li>Querétaro</li>
        <li>Puebla.</li>
      </ul>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="questions"
    data-title="¿Dónde puedo solicitar lonas para mis propiedades?"
    data-url="donde-puedo-solicitar-lonas-para-mis-propiedades"
    className="wrapper-article"
  >
    <h4>¿Dónde puedo solicitar lonas para mis propiedades?</h4>
    <div className="content">
      <p>
        En la nexitienda desde tu dashboard
        <a href={`${COMPANY_SHOP_URL}product/lonas-neximo-gratis/`}>
          Nexitienda
        </a>
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>
]
