import api from '../api'

const saveAnswer = (requirement, answer) => {
  let attended = null

  if (answer === 'yes') {
    attended = true
  } else {
    attended = false
  }

  return api.post(`/v1/crm/public/bin_leads/${requirement}/answer/`, {}, {
    attended
  })
}

export default {
  saveAnswer
}
