import {
  getTitleToSrpLink,
  srpUrl,
  vipPathUrl,
  srpUrlOnlyHasPropertyTypeParam,
  seoTitleWithAddress,
  getPropertySEODescription,
  genderCountPropertyType,
  locationTitle,
  removeUnnusedSRPParams,
  metaTitleSRP
} from 'services/seo'
import { BASE_SRP_URL } from 'pagesConfig/utils/constants'
import { getJsonLDProduct } from 'components/SEO/data'
import utils from 'services/utils'
import { normalizeAsUrl } from 'services/string'
import { image } from 'services'
import { numeric } from 'app/services/validators'
import { companyConfig } from 'globalConfigApp'
import { listCards as helpCategories } from 'components/Help/config'
import { getContentArticle } from 'components/Help/Categories/data'

import { SRP_DESC } from './descriptions'

/**
 * Commons methods
 */

export const addCompany = title => `${title} | ${companyConfig.name}`

const getPage = page => (numeric(page) ? Number(page) : 1)

const addCanonicalPage = (canonical) => {
  // ALL pages should point to first canonical page
  return canonical
}

export const addTitlePage = (title, page, numOfPages) => {
  const numericPage = getPage(page)

  const pageTitle = numOfPages
    ? `Página ${numericPage} de ${numOfPages}`
    : `Página ${numericPage} |`

  return `${pageTitle} ${title}`
}

const getCurrentSrpUrl = (srpParams) => {
  let currentSrpUrl = srpUrl(srpParams)

  return currentSrpUrl === "/" ? BASE_SRP_URL : currentSrpUrl
}

const formatCanonical = canonical => normalizeAsUrl(canonical)

/**
 * pagesCount
 * @param {Object} response - A response object with count attribute
 * @param {Number} limitPerPage - Limit set at original request
 * @returns {Object}
 */
export const pagesCount = (count = 0, limitPerPage = 0) => {
  const countResults = count
  const numOfPages = countResults / limitPerPage

  return {
    countResults,
    numOfPages: Math.ceil(numOfPages) || 1
  }
}


/**
 * getCountResultsTitle
 * To get a part of title with pagination format
 * @param {Object} params - params from url
 * @return {String}
 * @example "1-12 (de 150)" 
 */
const getCountResultsTitle = ({ page = 1, countResults, limitPerPage }) => {
  const lowerBound = (page - 1) * limitPerPage
  const upperBound = lowerBound + limitPerPage

  const finalUpperBound = countResults < upperBound ? countResults : upperBound

  const countTitle =
    countResults > 0
      ? `${lowerBound + 1}-${finalUpperBound} (de ${countResults})`
      : ''

  return countTitle
}
/**
 * shouldAddPageInfo
 * @param {Object} srpParams 
 */
const shouldAddPageInfo = ({ page = 1 }) => Number(page) > 1

const getSrpTitle = (title, srpParams) => {
  const { page, numOfPages } = srpParams

  if (shouldAddPageInfo(srpParams)) {
    return addTitlePage(`de ${title}`, page, numOfPages || 1)
  }

  return title
} 

/**
 * getSearchParams
 * @description To build SRP URL params
 * @param {Object} srpParams 
 * @returns {String}
 */
const getSearchParams = srpParams => {
  const finalParams = removeUnnusedSRPParams(srpParams)

  let params = new URLSearchParams()

  for (const param of Object.keys(finalParams)) {
    params.append(param, finalParams[param])
  }

  return params.toString()
}

/**
 * HELP methods
 */

export const HELP = {}

HELP.canonical = (category, id) => {
  if (id) {
    return `/help/${category}/${id}/`
  }

  if (category) {
    return `/help/${category}/`
  }

  return '/help'
}

HELP.title = (category, id) => {
  if (!category) {
    return null
  }

  if (id) {
    const article = getContentArticle(category, id)

    return (article && article.props && article.props['data-title']) || null
  }

  const categoryFound = helpCategories.find(
    helpCategory => helpCategory.category === category
  )

  return (categoryFound && categoryFound.title) || null
}

/**
 * SRP methods
 */

export const SRP = {}

/**
 * SRP.title
 * @param {Object} srpParams - from useSrpParams hook
 */
SRP.title = srpParams => {
  const srpTitle = SRP.baseTitle(srpParams)

  const title = getSrpTitle(srpTitle, srpParams)

  if (srpUrlOnlyHasPropertyTypeParam(srpParams)) {
    return `${title} | Busca Hoy en ${companyConfig.name}`
  }

  return addCompany(title)
}

SRP.description = srpParams => {
  return SRP_DESC.build(srpParams)
}

/**
 * SRP.canonical
 * @param {Object} srpParams - from useSrpParams hook
 */
SRP.canonical = srpParams => {
  const currentSrpUrl = getCurrentSrpUrl(srpParams)

  const canonical = addCanonicalPage(currentSrpUrl, srpParams.page)

  return formatCanonical(canonical)
}

SRP.baseTitle = srpParams => {
  const { location } = srpParams

  const srpTitle = metaTitleSRP({
    ...srpParams,
    location: location || 'México'
  })

  return srpTitle
}

/**
 * VIP methods
 */

export const VIP = {}

VIP.jsonLD = property => {
  const dataProductJsonLD = getJsonLDProduct(property)

  return dataProductJsonLD
}

VIP.canonical = property => {
  const canonical = vipPathUrl(
    property.propertyType,
    property.operationType,
    property.title,
    property.id,
    property.brokerData?.isExternal ? PROFILE.fullName(property.brokerData) : null
  )

  return canonical
}

VIP.title = property => {
  const title = `${seoTitleWithAddress(property)} | NEX-${property.id}`

  return addCompany(title)
}

VIP.description = property => {
  const { id, operationType, propertyType, bedrooms, bathrooms } = property

  const address = locationTitle(property)

  const propertySEODescription = getPropertySEODescription({
    operationType,
    propertyType,
    bedrooms,
    bathrooms,
    address
  })

  const genderConnector = genderCountPropertyType("TYPE_2", propertyType, 1);
  const externalBrokerInformation = property.brokerData?.isExternal ? ` Compartido por ${property.brokerData.firstName}.` : ''

  const description = `En ${companyConfig.name}, tenemos ${genderConnector} ${propertySEODescription}${externalBrokerInformation} | NEX-${id}`

  return description
}

VIP.openGraphImage = property => {
  const firstImage = property.firstImage
    ? utils.getCroppedImage({file: property.firstImage, width: 250, height: 250})
    : image('property/defaultproperty.jpg')

  return firstImage
}

VIP.descriptionWithAmenities = property => {
  const {
    operationType,
    propertyType,
    bedrooms,
    bathrooms,
    amenities,
    halfBathrooms,
    constructionArea,
    surfaceArea,
    parkingSpaces,
    landType,
    developmentType,
    presaleDate,
  } = property
  const address = locationTitle(property)

  const propertySEODescription = getPropertySEODescription(
    {
      operationType,
      propertyType,
      bedrooms,
      bathrooms,
      address,
      halfBathrooms,
      constructionArea,
      surfaceArea,
      parkingSpaces,
      amenities,
      landType,
      developmentType,
      presaleDate,
    },
    {
      withAmenities: true,
      withLandType: true,
      maxAmenities: Number.POSITIVE_INFINITY,
    },
  )

  return propertySEODescription
}

VIP.author = property => {
  const { firstName, lastName, secondLastName } = property.brokerData || {}
  const author = firstName
    ? `${firstName} ${lastName || ''} ${secondLastName || ''}`
    : ''

  return {
    name: 'author',
    content: author
  }
}

VIP.siteName = property => ({
  name: 'siteName',
  content: addCompany(property.title)
})

/**
 * PROFILE methods
 */

export const PROFILE = {}

PROFILE.formatURL = ({ slug, id }) => `/equipo/${slug}/${id}`

PROFILE.canonical = (profile, params) => {
  if (params.isPropertiesSection) {
    return PROFILE.propertiesCanonical(profile, params)
  }

  return PROFILE.baseCanonical(profile)
}

PROFILE.propertiesCanonical = (profile, params) => {
  const baseCanonical = PROFILE.baseCanonical(profile)
  const canonical = addCanonicalPage(`${baseCanonical}/propiedades`, params.page)

  return canonical
}

PROFILE.baseCanonical = (profile) => {
  const fullName = `${profile.firstName} ${profile.lastName}`
  const slug = utils.slugify(fullName)

  const canonical = PROFILE.formatURL({ slug, id: profile.id })

  return formatCanonical(canonical)
}

PROFILE.fullName = profile => {
  if (!profile || !(profile.firstName || profile.lastName)) {
    return ""
  }

  const { firstName, lastName, secondLastName } = profile

  const fullName = `${firstName} ${lastName || ''} ${secondLastName || ''}`.trim()

  return fullName
}

PROFILE.title = (profile, params) => {
  if (params.isPropertiesSection) {
    return PROFILE.propertiesTitle(profile, params)
  }

  return PROFILE.baseTitle(profile)
}

PROFILE.baseTitle = profile => {
  const fullName = PROFILE.fullName(profile)
  
  const title = `${fullName} | Asesor Inmobiliario`
  
  return addCompany(title)
}

PROFILE.propertiesTitle = (profile, params) => {
  const { page, numOfPages } = params

  const baseTitle = PROFILE.baseTitle(profile)

  const title = addTitlePage(`de propiedades de ${baseTitle}`, page, numOfPages || 1)

  return title
}

PROFILE.description = (profile, params) => {
  if (params.isPropertiesSection) {
    return PROFILE.propertiesDescription(profile, params)
  }

  const fullName = PROFILE.fullName(profile)

  const description = `${fullName} es un agente inmobiliario de ${companyConfig.name}. 
    Conoce su perfil, su cartera de propiedades en venta y en renta, su 
    calificación y últimas transacciones. ${companyConfig.name}, alianza inmobiliaria.`

  return description
}

PROFILE.propertiesDescription = (profile, params) => {
  const fullName = PROFILE.fullName(profile)
  
  const countTitle = getCountResultsTitle(params)

  const description = `Propiedades en venta y en renta de ${fullName} | ${countTitle}`

  return addCompany(description)
}

PROFILE.openGraphImage = profile => {
  if (profile.avatar) {
    return utils.getCroppedImage({file: profile.avatar, width: 255, height: 255})
  }

  return image('advantage/no-foto-solido.png')
}

/**
 * TEAM methods
 */

export const TEAM = {}

TEAM.canonical = params => {
  const canonical = addCanonicalPage('/equipo', params.page)

  return canonical
}

TEAM.title = srpParams => {
  const title = addTitlePage(
    'de Agentes inmobiliarios en México',
    srpParams.page,
    srpParams.numOfPages || 1
  )

  return addCompany(title)
}

TEAM.description = params => {
  return addTitlePage('', params.page, params.numOfPages || 1)
}
