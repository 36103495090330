import { createSelector } from 'reselect'

export const getDocumentPages = createSelector(
  state => state.documentFile.pagesData,
  pages => {
    let pagesArray = []
    for (let key in pages) {
      pagesArray.push(pages[key])
    }
    pagesArray = pagesArray.sort((a, b) => a.order >= b.order)

    return pagesArray
  }
)

export const getUploadState = createSelector(
  state => state.documentFile,
  documentFile => {
    const { pagesData, pageCount } = documentFile

    const uploadState = Object.keys(pagesData).reduce((mem, pageKey) => {
      const pageData = pagesData[pageKey]

      return {
        progress: Math.round((mem.progress + pageData.progress)/pageCount),
        uploading: mem.uploading || pageData.uploading,
        uploadError: mem.uploadError || pageData.uploadError
      }
    }, { progress: 0, uploading: false})

    return uploadState
  }
)

export const getUploading = createSelector(
  state => state.documentFile,
  documentFile => {
    const { pagesData } = documentFile

    const uploadState = Object.keys(pagesData).reduce((mem, pageKey) => {
      const pageData = pagesData[pageKey]

      return mem || pageData.uploading
    }, false)

    return uploadState
  }
)

export const getDocumentPostData = createSelector(
  state => state.documentFile,
  getDocumentPages,
  (data, pages) => {
    return {
      bondId: data.bondId,
      filename: data.originalFilename,
      url: data.originalFileUrl,
      pages: pages.map(page => ({ pageNumber: page.order, url: page.url }))
    }
  }
)