import { all, takeLatest, takeEvery, fork, debounce } from 'redux-saga/effects'
import { AuthActionTypes } from '../reducers/AuthRedux'
import { AppActionTypes } from '../reducers/AppRedux'
import { ContractsActionTypes } from '../reducers/ContractsRedux'
import { ContractVersionActionTypes } from '../reducers/ContractVersionRedux'
import { ContactActionTypes } from '../reducers/ContactReducer'
import { binLeadPrequalifyActionTypes } from '../reducers/binLeadPrequalifyRedux'
import { BinAlertsActionTypes } from '../reducers/BinAlertsRedux'
import { CRMActionTypes } from '../reducers/CRMReducer'
import { GeoActionTypes } from '../reducers/GeoRedux'
import { SrpActionTypes } from '../reducers/SrpRedux'
import { DokaltinActionTypes } from '../reducers/DokaltinRedux'
import { BondActionTypes } from '../reducers/BondRedux'
import { BrokerInfoTypes } from '../reducers/BrokerInfoRedux'
import { FileUploadActionTypes } from '../reducers/FileUploadRedux'
import { DocumentFileActionTypes } from '../reducers/DocumentFileRedux'
import { DirectAnnonceLeadActionTypes } from '../reducers/DirectAnnonceLeadRedux'
import { NotificationActionTypes } from '../reducers/NotificationRedux'
import { StatsActionTypes } from '../reducers/StatsRedux'
import { HelpDeskActionTypes } from '../reducers/HelpDeskRedux'
import { IAActionTypes } from '../reducers/IARedux'
import { ContactJourneyTypes } from '../reducers/ContactJourneyRedux'
import { DirectoryActionTypes } from '../reducers/DirectoryRedux'
import { PortalsActionTypes } from '../reducers/PortalsRedux'
import { RejectLeadTypes } from '../reducers/RejectLeadRedux'
import { PostponeLeadTypes } from '../reducers/PostponeLeadRedux'
import { AcceptLeadTypes } from '../reducers/AcceptLeadRedux'
import { RfcActionTypes } from '../reducers/RFCRedux'
import { PdfActionTypes } from '../reducers/PdfDownloadRedux'
import { PropertyActionTypes } from '../reducers/PropertyRedux'
import { PropertyDocumentationActionTypes } from '../reducers/PropertyDocumentationRedux'
import { PropertyHighlightActionTypes } from '../reducers/PropertyHighlightRedux'
import { BinLeadQualityCheckTypes } from '../reducers/BinLeadQualityCheckRedux'
import { BinCloseDealActionTypes } from '../reducers/CloseDealRedux'
import { VipActionTypes } from '../reducers/VipRedux'
import { HereLocationTypes } from '../reducers/LocationHereRedux'
import { LocationActionTypes } from '../reducers/LocationRedux'
import { OnboardingMaterialsTypes } from '../reducers/OnboardingMaterialsRedux'
import { DealsActionTypes } from '../reducers/DealsRedux'
import { PropertyComplaintsTypes } from '../reducers/ReportPropertiesReducer'
import { NeximerPropertyActionTypes } from '../reducers/NeximerPropertyRedux'
import { NetworkReportsActionTypes } from '../reducers/NetworkReportsRedux'
import { SeoUrlsActionTypes } from '../reducers/SeoUrlsRedux'
import { MLMQuotasActionTypes } from '../reducers/MLMQuotasRedux'
import { TransactionsActionTypes } from '../reducers/TransactionsRedux'
import { MLMCommissionsActionTypes } from '../reducers/MLMCommissionsRedux'
import { NetworkStatsActionTypes } from '../reducers/NetworkStatsRedux'
import { NewChildAgentActionTypes } from '../reducers/NewChildAgentRedux'
import { DocumentationFoldersActionTypes } from '../reducers/DocumentationFoldersRedux'
import { DocumentationFilesActionTypes } from '../reducers/DocumentationFilesRedux'
import { UserPrivateDetailActionTypes } from '../reducers/UserPrivateDetailRedux'
import { UserSessionTypes } from '../reducers/UserSessionRedux'
import { UserOnboardingActionTypes } from '../reducers/UserOnboardingRedux'
import { ValidateImageActionTypes } from '../reducers/VirtualTourRedux'
import { RecruitmentLeadActionTypes } from '../reducers/RecruitmentLeadRedux'
import { ImageTransformationActionTypes } from '../reducers/ImageTransformationRedux'

import * as AuthSagas from './AuthSagas'
import * as BinAlertsSagas from './BinAlertsSagas'
import * as ContractsSagas from './ContractsSagas'
import * as ContractVersionSagas from './ContractVersionSagas'
import * as CRMSagas from './CRMSagas'
import * as binLeadPrequalifySagas from './binLeadPrequalifySagas'
import * as ConfigSagas from './ConfigSagas'
import * as GeoSagas from './GeoSagas'
import * as SrpSagas from './SrpSagas'
import * as ContactSagas from './ContactSagas'
import * as FileUploadSagas from './FileUploadSagas'
import * as DokaltinSagas from './DokaltinSagas'
import * as BondSagas from './BondSagas'
import * as BrokerInfoSagas from './BrokerInfoSagas'
import * as DocumentFileSagas from './DocumentFileSagas'
import * as ElearningSagas from './ElearningSagas'
import * as ContactJourneySagas from './ContactJourneySagas'
import * as PropertySagas from './PropertySagas'
import * as PortalsSagas from './PortalsSagas'
import * as DirectAnnonceLeadSagas from './DirectAnnonceLeadSagas'
import * as NotificationSagas from './NotificationSagas'
import * as StatsSagas from './StatsSagas'
import * as HelpDeskSagas from './HelpDeskSagas'
import * as IASagas from './IASagas'
import * as DirectorySagas from './DirectorySagas'
import * as RFCSagas from './RFCSagas'
import * as PropertyDocumentationSagas from './PropertyDocumentationSagas'
import * as PropertyHighlightSagas from './PropertyHighlightSagas'
import * as PdfDownloadSagas from './PdfDownloadSagas'
import * as BinLeadQualityCheckSagas from './BinLeadQualityCheckSagas'
import * as CloseDealSagas from './CloseDealSagas'
import * as VipSagas from './VipSagas'
import * as ValidateFormSagas from './ValidateFormSagas'
import * as HereLocationSagas from './LocationHereSagas'
import * as LocationSagas from './LocationSagas'
import * as OnboardingMaterialsSagas from './OnboardingMaterialsSagas'
import * as DealsSagas from './DealsSagas'
import * as ReportPropertySagas from './ReportPropertySagas'
import * as NeximerPropertySagas from './NeximerPropertySagas'
import * as NetworkReportsSagas from './NetworkReportsSagas'
import * as SeoUrlsSagas from './SeoUrlsSagas'
import * as MLMQuotasSagas from './MLMQuotasSagas'
import * as TransactionsSagas from './TransactionsSagas'
import * as MLMCommissionsSagas from './MLMCommissionsSagas'
import * as NetworkStatsSagas from './NetworkStatsSagas'
import * as NewChildAgentSagas from './NewChildAgentSagas'
import * as DocumentationFoldersSagas from './DocumentationFoldersSagas'
import * as DocumentationFilesSagas from './DocumentationFilesSagas'
import * as UserSessionSagas from './UserSessionSagas'
import * as UserPrivateDetailSagas from './UserPrivateDetailSagas'
import * as UserOnboardingSagas from './UserOnboardingSagas'
import * as VirtualTourSagas from './VirtualTourSagas'
import * as RecruitmentLeadSagas from './RecruitmentLeadSagas'
import * as ImageTransformationSagas from './ImageTransformationSagas'

import storeSagas from 'services/store/sagas'
import { actionTypes } from 'redux-form'

export default function* rootSaga() {
  yield all([
    fork(AuthSagas.rehydrationWatcher),
    fork(NotificationSagas.checkForNotification),
    takeLatest(AppActionTypes.GET_CONFIG_REQUEST, ConfigSagas.getConfig),
    takeLatest(AppActionTypes.DASHBOARD_REQUEST, ConfigSagas.dashboardRequest),
    takeLatest(
      AuthActionTypes.REFRESH_TOKEN_SUCCESS,
      AuthSagas.tokenRefreshClock
    ),
    takeLatest(
      'STORE_OAUTH_REFRESH_TOKEN_WITH_TOKEN_REQUEST',
      AuthSagas.refreshTokenWithToken
    ),
    takeLatest('STORE_OAUTH_ACCESS_TOKEN_SUCCESS', AuthSagas.tokenRefreshClock),
    takeLatest(
      AuthActionTypes.GET_CONTACT_TOKEN_FOR_LEGACY_REQUIREMENTS,
      AuthSagas.getContactTokenForLegacyRequirements
    ),
    takeLatest(
      AuthActionTypes.LOGOUT_REQUEST,
      AuthSagas.externalLogoutRequest,
    ),
    takeLatest(
      'STORE_OAUTH_ACCESS_TOKEN_SUCCESS',
      AuthSagas.externalLoginSuccess,
    ),

    takeLatest(
      PortalsActionTypes.GET_DATA_TABLE_CALLS,
      PortalsSagas.getDataTableCalls
    ),

    takeLatest(
      PortalsActionTypes.GET_CALL_RECORDS_STATS,
      PortalsSagas.getCallRecordsStats
    ),

    // takeLatest(AuthActionTypes.LOGIN_REQUEST, AuthSagas.login),
    takeLatest(AuthActionTypes.REFRESH_TOKEN_REQUEST, AuthSagas.refreshToken),
    takeLatest('STORE_USER_UPDATE_PROFILE_SUCCESS', AuthSagas.refreshToken),

    debounce(
      1000,
      GeoActionTypes.GET_FULL_LOCATION_REQUEST,
      GeoSagas.getFullLocation
    ),
    debounce(
      1000,
      GeoActionTypes.GET_LAT_LNG_REQUEST,
      GeoSagas.getLatLngLocation
    ),

    takeLatest(AuthActionTypes.CONFIRM_EMAIL_REQUEST, AuthSagas.confirmEmail),

    takeLatest(
      SrpActionTypes.SEARCH_WITH_LOCATION_STRING,
      SrpSagas.searchWithLocationString
    ),
    takeLatest(
      SrpActionTypes.SEARCH_WITH_LOCATION_OBJECT,
      SrpSagas.searchWithLocationObject
    ),
    takeLatest(
      SrpActionTypes.GET_SEO_FOOTER_RESOURSE,
      SrpSagas.getSEOFooterResourse
    ),
    takeLatest(
      VipActionTypes.GET_VIP_SEO_INFO_BY_PROPERTY_ID,
      VipSagas.getVipSeoInfoByPropertyId
    ),

    fork(FileUploadSagas.uploadRequestWatcher),
    takeLatest(
      DokaltinActionTypes.GET_DOCUMENT_REQUEST,
      DokaltinSagas.getDocument
    ),
    takeLatest(DokaltinActionTypes.SAVE_REQUEST, DokaltinSagas.saveFields),

    takeLatest(
      DokaltinActionTypes.LOCK_DOCUMENT_REQUEST,
      DokaltinSagas.lockDocumentRequest
    ),

    takeLatest(
      DokaltinActionTypes.SHARE_DOCUMENT_REQUEST,
      DokaltinSagas.shareDocument
    ),
    takeLatest(
      DokaltinActionTypes.GET_SHARED_DOCUMENT_REQUEST,
      DokaltinSagas.getSharedDocument
    ),
    takeLatest(
      DokaltinActionTypes.GET_DOWNLOADED_DOCUMENT_REQUEST,
      DokaltinSagas.getDownloadedDocument
    ),
    takeLatest(
      DokaltinActionTypes.ASSIGN_FIELD_REQUEST,
      DokaltinSagas.assignField
    ),

    takeLatest(BondActionTypes.GET_BONDS_REQUEST, BondSagas.getBonds),
    takeLatest(BondActionTypes.GET_BOND_REQUEST, BondSagas.getBond),
    takeLatest(
      BondActionTypes.GET_BOND_COMPLETED_REQUEST,
      BondSagas.getBondCompleted
    ),
    takeLatest(BondActionTypes.CREATE_ACTOR_REQUEST, BondSagas.createActor),
    takeLatest(BondActionTypes.DELETE_ACTOR_REQUEST, BondSagas.deleteActor),
    takeLatest(BondActionTypes.CREATE_TASK_REQUEST, BondSagas.createTask),
    takeLatest(BondActionTypes.UPDATE_TASK_REQUEST, BondSagas.updateTask),
    takeLatest(BondActionTypes.DELETE_TASK_REQUEST, BondSagas.deleteTask),

    // Broker info stuff

    takeLatest(BrokerInfoTypes.SEND_CODE_REQUEST, BrokerInfoSagas.sendCode),
    takeLatest(BrokerInfoTypes.VERIFY_CODE_REQUEST, BrokerInfoSagas.verifyCode),
    takeLatest(
      BrokerInfoTypes.CREATE_KIT_REQUEST,
      BrokerInfoSagas.createKitRequest
    ),
    takeLatest(
      BrokerInfoTypes.GET_MAILBOX_OPTIONS_REQUEST,
      BrokerInfoSagas.getMailboxOptionsRequest
    ),

    takeLatest(
      DocumentFileActionTypes.PROCESS_DOCUMENT,
      DocumentFileSagas.processDocument
    ),
    takeLatest(
      DocumentFileActionTypes.CREATE_DOCUMENT,
      DocumentFileSagas.uploadPages
    ),
    takeLatest(FileUploadActionTypes.SUCCESS, DocumentFileSagas.uploadFinished),
    takeLatest(FileUploadActionTypes.FAILURE, DocumentFileSagas.uploadFinished),

    takeLatest(
      'STORE_ELEARNING_COURSE_ENROLLMENT_REQUEST',
      ElearningSagas.enrollmentModule
    ),
    takeEvery(
      actionTypes.CHANGE,
      PropertySagas.autoSavePropertyWatcherImagesJson
    ),
    takeEvery(
      actionTypes.CHANGE,
      PropertySagas.handlePropertyDraftChange
    ),
    fork(PropertySagas.autoSavePropertyWatcher),
    fork(PropertySagas.autoDraftPropertyWatcher),
    takeLatest(
      PropertyActionTypes.CLOSE_DEAL_PROPERTY,
      PropertySagas.closeDealProperty,
    ),
    takeLatest(
      'STORE_LOCATION_SERVICE_ALL_FROM_POSTAL_CODE_REQUEST',
      GeoSagas.allFromPostalCode
    ),
    takeLatest(
      'STORE_LOCATION_SERVICE_LOAD_ADDRESS_OPTIONS_REQUEST',
      GeoSagas.loadAddressOptions
    ),

    takeLatest(
      'STORE_COURSES_ENROLLMENT_REQUEST',
      ElearningSagas.enrollmentModule
    ),

    takeLatest(
      DirectAnnonceLeadActionTypes.GET_DIRECT_ANNONCE_LEAD_REQUEST,
      DirectAnnonceLeadSagas.getDirectAnnonceLead
    ),
    takeLatest(
      DirectAnnonceLeadActionTypes.ACCEPT_DIRECT_ANNONCE_LEAD,
      DirectAnnonceLeadSagas.acceptDirectAnnonceLeadNew
    ),
    takeLatest(
      DirectAnnonceLeadActionTypes.REJECT_DIRECT_ANNONCE_LEAD,
      DirectAnnonceLeadSagas.rejectDirectAnnonceLeadNew
    ),
    takeLatest(
      RejectLeadTypes.REJECT_LEAD_REQUEST,
      DirectAnnonceLeadSagas.rejectDirectAnnonceLead
    ),
    takeLatest(
      PostponeLeadTypes.POSTPONE_LEAD_REQUEST,
      DirectAnnonceLeadSagas.postponeDirectAnnonceLead
    ),
    takeLatest(
      AcceptLeadTypes.ACCEPT_LEAD_REQUEST,
      DirectAnnonceLeadSagas.acceptDirectAnnonceLead
    ),

    takeLatest(RfcActionTypes.GET_RFC_REQUEST, RFCSagas.getRfc),

    takeLatest(
      NotificationActionTypes.GET_NOTIFICATIONS_REQUEST,
      NotificationSagas.getNotifications
    ),

    takeLatest(StatsActionTypes.GET_STATS_REQUEST, StatsSagas.getStats),

    takeEvery(
      HelpDeskActionTypes.CREATE_CONVERSATION_REQUEST,
      HelpDeskSagas.createConversation
    ),

    takeLatest(
      IAActionTypes.PROPERTY_DESCRIPTION,
      IASagas.propertyDescription,
    ),

    takeEvery(
      ContactJourneyTypes.SEND_GT_EVENT,
      ContactJourneySagas.sendGTEvent
    ),
    takeEvery(
      ContactJourneyTypes.SEND_CONTACT_JOURNEY_EVENT,
      ContactJourneySagas.sendContactJourneyEvent
    ),

    takeLatest(
      DirectoryActionTypes.GET_CONTACTS_REQUEST,
      DirectorySagas.getContacts
    ),

    takeLatest(
      ContactActionTypes.SENT_DATA_CONTACT_REQUEST,
      ContactSagas.sentDataContactRequest
    ),

    takeLatest(
      ContactActionTypes.GET_CONTACT_REQUEST,
      ContactSagas.getContactRequest
    ),
    takeLatest(
      ContactActionTypes.HANDLE_SUBMIT_CONTACT_FORM_REQUEST,
      ContactSagas.handleSubmitContactForm
    ),
    takeLatest(
      ContactActionTypes.GET_PROPERTIES_REQUEST,
      ContactSagas.getProperties
    ),
    takeLatest(
      ContactActionTypes.DELETE_CONTACT_REQUEST,
      ContactSagas.deleteContact
    ),
    takeLatest(
      ContactActionTypes.GET_CONTACT_REPORT_REQUEST,
      ContactSagas.getContactReport
    ),
    takeLatest(
      ContactActionTypes.GET_PROFILES_TYPE_REQUEST,
      ContactSagas.getProfilesTypeRequest
    ),
    takeLatest(
      ContactActionTypes.DELETE_CONTACT_PROPERTY_REQUEST,
      ContactSagas.deleteContactPropertyRequest
    ),

    takeLatest(
      PropertyDocumentationActionTypes.GET_PROPERTY_TO_DOCUMENTATION,
      PropertyDocumentationSagas.getPropertyToDocumentation
    ),
    takeLatest(
      PropertyDocumentationActionTypes.REGISTER_NEW_FILE,
      PropertyDocumentationSagas.registerNewFile
    ),
    takeLatest(
      PropertyDocumentationActionTypes.DELETE_DOCUMENT,
      PropertyDocumentationSagas.deleteDocument
    ),
    takeLatest(
      PropertyDocumentationActionTypes.SAVE_DOCUMENTATION_REQUEST,
      PropertyDocumentationSagas.saveDocumentation
    ),
    takeLatest(
      PropertyHighlightActionTypes.ALL_PLANS,
      PropertyHighlightSagas.allPlans,
    ),
    takeLatest(
      PropertyHighlightActionTypes.ALL_PROPERTY_ORDERS,
      PropertyHighlightSagas.allPropertyOrders,
    ),
    takeLatest(
      PropertyHighlightActionTypes.CONFIRM_ORDER,
      PropertyHighlightSagas.confirmOrder,
    ),
    takeLatest(
      PropertyHighlightActionTypes.PURCHASE_ORDER,
      PropertyHighlightSagas.purchaseOrder,
    ),
    takeLatest(
      PropertyHighlightActionTypes.CREATE_PURCHASE_ORDER,
      PropertyHighlightSagas.createPurchaseOrder,
    ),
    takeLatest(
      PropertyHighlightActionTypes.REFRESH_PURCHASE_ORDER,
      PropertyHighlightSagas.refreshPurchaseOrder,
    ),
    takeLatest(
      PropertyHighlightActionTypes.CHECK_PROPERTIES_STATUS,
      PropertyHighlightSagas.checkPropertiesStatus,
    ),
    takeLatest(
      ContactActionTypes.GET_CONTACT_FOLLOWUPS_REQUEST,
      ContactSagas.getContactFollowupsRequest
    ),
    takeLatest(
      ContactActionTypes.GET_FOLLOW_UP_DETAIL_REQUEST,
      ContactSagas.getFollowUpDetailRequest
    ),
    takeLatest(
      BinLeadQualityCheckTypes.SAVE_ANSWER,
      BinLeadQualityCheckSagas.saveAnswer
    ),

    takeLatest(
      binLeadPrequalifyActionTypes.UPDATE_PREQUALIFY,
      binLeadPrequalifySagas.updatePrequalify
    ),
    takeLatest(
      binLeadPrequalifyActionTypes.GET_RENTING_REQUIREMENTS,
      binLeadPrequalifySagas.getRentingRequirements
    ),

    takeLatest(PdfActionTypes.GET_PDF_REQUEST, PdfDownloadSagas.downloadPdf),
    takeLatest(BinAlertsActionTypes.GET_ALERTS, BinAlertsSagas.getAlerts),
    takeLatest(BinAlertsActionTypes.CREATE_ALERT, BinAlertsSagas.createAlert),
    takeLatest(
      BinAlertsActionTypes.CHANGE_STATUS_ALERT,
      BinAlertsSagas.changeStatusAlert
    ),

    takeLatest(
      BinCloseDealActionTypes.CREATE_CLOSE_DEAL,
      CloseDealSagas.createWithoutExternalAgent
    ),
    takeLatest(
      BinCloseDealActionTypes.CREATE_CLOSE_DEAL_WITH_EXTERNAL_AGENT,
      CloseDealSagas.createWithExternalAgent
    ),
    takeLatest(
      BinCloseDealActionTypes.REQUEST_LEGAL_SUPPORT,
      CloseDealSagas.requestLegalSupport
    ),

    takeLatest(
      CRMActionTypes.UPDATE_ALERT_PROPERTIES,
      CRMSagas.updateAlertProperties
    ),
    takeLatest(
      CRMActionTypes.PROPERTY_VALUATIONS,
      CRMSagas.propertyValuations
    ),
    takeLatest(
      CRMActionTypes.GET_PROPERTY_VALUATION,
      CRMSagas.getPropertyValuation
    ),
    takeLatest(
      CRMActionTypes.NEW_PROPERTY_VALUATION,
      CRMSagas.newPropertyValuation
    ),
    takeLatest(
      CRMActionTypes.CREATE_PROPERTY_VALUATION,
      CRMSagas.createPropertyValuation
    ),
    takeLatest(CRMActionTypes.ACCEPT_LEAD, CRMSagas.acceptLead ),

    takeLatest(
      HereLocationTypes.GET_LOCATION_REQUEST,
      HereLocationSagas.getLocationData
    ),
    takeLatest(
      HereLocationTypes.SET_LOCATION,
      HereLocationSagas.setLocation
    ),
    takeEvery(
      LocationActionTypes.STATES,
      LocationSagas.states
    ),
    takeEvery(
      LocationActionTypes.NEIGHBORHOODS,
      LocationSagas.neighborhoods
    ),
    takeEvery(
      LocationActionTypes.MUNICIPALITIES,
      LocationSagas.municipalities
    ),
    takeEvery(
      LocationActionTypes.GET_BY_FULL_LOCATION,
      LocationSagas.getByFullLocation
    ),
    takeLatest(
      OnboardingMaterialsTypes.GET_ALL,
      OnboardingMaterialsSagas.getAll
    ),
    takeLatest(
      OnboardingMaterialsTypes.UPDATE_MATERIAL,
      OnboardingMaterialsSagas.updateMaterial
    ),
    takeLatest(
      DealsActionTypes.GET_ALL,
      DealsSagas.getAll
    ),
    takeLatest(
      DealsActionTypes.FIND,
      DealsSagas.find
    ),
    takeLatest(
      DealsActionTypes.GET_ALL_WITH_DETAILS,
      DealsSagas.getAllWithDetails
    ),
    takeLatest(
      PropertyComplaintsTypes.SEND_REPORT_REQUEST,
      ReportPropertySagas.sendData
    ),
    takeEvery(
      NeximerPropertyActionTypes.GET_PROPERTIES,
      NeximerPropertySagas.getProperties
    ),
    takeLatest(
      NetworkReportsActionTypes.GET_RECOMMENDATIONS,
      NetworkReportsSagas.getRecommendations
    ),
    takeLatest(
      NetworkReportsActionTypes.GET_INVENTORY_REPORT,
      NetworkReportsSagas.getInventoryReport
    ),
    takeLatest(
      NetworkReportsActionTypes.GET_INVENTARY_QUALITY_REPORT,
      NetworkReportsSagas.getInventaryQualityReport
    ),
    takeLatest(
      NetworkReportsActionTypes.GET_CONTACTS_REPORT,
      NetworkReportsSagas.getContactsReport
    ),
    takeLatest(
      NetworkReportsActionTypes.GET_BILLING_REPORT,
      NetworkReportsSagas.getBillingReport
    ),
    takeLatest(
      NetworkReportsActionTypes.GET_REPORT_CSV,
      NetworkReportsSagas.getReportCSV
    ),
    takeLatest(
      ContractsActionTypes.ALL,
      ContractsSagas.all
    ),
    takeLatest(
      ContractsActionTypes.CONTRACT,
      ContractsSagas.contract
    ),
    takeLatest(
      ContractsActionTypes.REMOVE,
      ContractsSagas.remove
    ),
    takeLatest(
      ContractsActionTypes.REQUEST_SIGNATURES,
      ContractsSagas.requestSignatures
    ),
    takeLatest(
      ContractsActionTypes.CANCEL_SIGNATURES,
      ContractsSagas.cancelSignatures
    ),
    fork(ContractsSagas.draftWatcher),
    takeLatest(
      ContractsActionTypes.TYPES,
      ContractsSagas.types
    ),
    takeLatest(
      ContractsActionTypes.CONTRACT_TYPE,
      ContractsSagas.contractType
    ),
    takeLatest(
      ContractsActionTypes.CONTRACT_LAYOUT_VERSION,
      ContractsSagas.contractLayoutVersion
    ),
    takeLatest(
      ContractsActionTypes.FILL_INITIAL_VALUES,
      ContractsSagas.fillInitialValues
    ),
    takeLatest(
      ContractsActionTypes.SAVE,
      ContractsSagas.save
    ),
    takeLatest(
      ContractsActionTypes.PARTIAL_UPDATE,
      ContractsSagas.partialUpdate
    ),
    takeEvery(
      ContractVersionActionTypes.CHECK_PERMISSION,
      ContractVersionSagas.checkPermission,
    ),
    takeEvery(
      ContractVersionActionTypes.GET_URL_BY_CONTRACT,
      ContractVersionSagas.getUrlByContract,
    ),
    takeLatest(
      SeoUrlsActionTypes.ALL,
      SeoUrlsSagas.all
    ),
    takeEvery(
      MLMQuotasActionTypes.GET_QUOTAS_REQUEST,
      MLMQuotasSagas.getQuotasRequest
    ),
    takeLatest(
      MLMQuotasActionTypes.GET_TRANSACTION_SUMMARY_REQUEST,
      MLMQuotasSagas.getTransactionSummary
    ),
    takeLatest(
      MLMQuotasActionTypes.GET_DEALS_DETAILS,
      MLMQuotasSagas.getDealsDetails
    ),
    takeEvery(
      MLMCommissionsActionTypes.GET_COMMISSIONS_REQUEST,
      MLMCommissionsSagas.getCommissionsRequest
    ),
    takeEvery(
      NetworkStatsActionTypes.GET_STATS_REQUEST,
      NetworkStatsSagas.getStatsRequest,
    ),
    takeLatest(
      NewChildAgentActionTypes.SUMMARY,
      NewChildAgentSagas.summary,
    ),
    takeLatest(
      NewChildAgentActionTypes.CREATE_SUBSCRIPTION,
      NewChildAgentSagas.createSubscription,
    ),
    takeLatest(
      NewChildAgentActionTypes.CREATE_CONTRACT,
      NewChildAgentSagas.createContract,
    ),
    takeLatest(
      NewChildAgentActionTypes.FINISH_PROCESS,
      NewChildAgentSagas.finishProcess,
    ),
    takeLatest(
      NewChildAgentActionTypes.TRY_TO_FINISH_REGISTERED_PROCESS,
      NewChildAgentSagas.tryToFinishRegisteredProcess,
    ),
    takeEvery(
      TransactionsActionTypes.GET_TRANSACTIONS_REQUEST,
      TransactionsSagas.getTransactionsRequest
    ),
    takeLatest(
      DocumentationFoldersActionTypes.ALL,
      DocumentationFoldersSagas.all
    ),
    takeLatest(
      DocumentationFilesActionTypes.ALL,
      DocumentationFilesSagas.all
    ),
    takeLatest(
      UserSessionTypes.CREATE_SESSION_REQUEST,
      UserSessionSagas.createSessionRequest,
    ),
    takeLatest(
      UserSessionTypes.REVALIDATE_SESSION,
      UserSessionSagas.revalidateSession,
    ),
    takeLatest(
      UserSessionTypes.LOGOUT_SESSION_REQUEST,
      UserSessionSagas.logoutSessionRequest,
    ),
    takeLatest(
      UserSessionTypes.GET_SESSIONS_REQUEST,
      UserSessionSagas.getSessions,
    ),
    takeLatest(
      UserSessionTypes.LOGOUT_SESSIONS_REQUEST,
      UserSessionSagas.logoutSessionsRequest,
    ),
    fork(UserSessionSagas.userSessionBroadcastChannel),
    takeLatest(
      AuthActionTypes.REFRESH_TOKEN_SUCCESS,
      UserSessionSagas.loginOrRefreshTokenSuccess,
    ),
    takeLatest(
      'STORE_OAUTH_ACCESS_TOKEN_SUCCESS',
      UserSessionSagas.loginOrRefreshTokenSuccess,
    ),
    takeLatest(
      RecruitmentLeadActionTypes.ACCEPT_RECRUITMENT_LEAD,
      RecruitmentLeadSagas.acceptRecruitmentLead
    ),
    takeLatest(
      RecruitmentLeadActionTypes.FIND_RECRUITMENT_LEAD_TO_REJECT,
      RecruitmentLeadSagas.findRecruitmentLeadToReject
    ),
    takeLatest(
      RecruitmentLeadActionTypes.REJECT_RECRUITMENT_LEAD,
      RecruitmentLeadSagas.rejectRecruitmentLead
    ),
    takeLatest(
      RecruitmentLeadActionTypes.CONTACTED_RECRUITMENT_LEAD,
      RecruitmentLeadSagas.contactedRecruitmentLead
    ),
    takeLatest(
      UserPrivateDetailActionTypes.GET_USER_PRIVATE_DETAIL,
      UserPrivateDetailSagas.getUserPrivateDetail
    ),
    takeLatest(
      UserPrivateDetailActionTypes.ALL_AREAS,
      UserPrivateDetailSagas.getBinProfileAreas
    ),
    takeLatest(
      UserOnboardingActionTypes.STATUS,
      UserOnboardingSagas.status
    ),
    takeLatest(
      UserOnboardingActionTypes.CONTRACTS,
      UserOnboardingSagas.contracts
    ),
    takeLatest(
      ValidateImageActionTypes.VALIDATE_IMAGE,
      VirtualTourSagas.validateVirtualTourImage
    ),
    takeLatest(
      ImageTransformationActionTypes.IMAGE_TRANSFORMATION,
      ImageTransformationSagas.transformImage
    ),
    fork(ValidateFormSagas.validateFormWatcher),
    ...storeSagas
  ])
}
