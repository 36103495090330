import { companyConfig } from 'globalConfigApp'

import styles from './styles'

export default [
  <article
    data-category="dates"
    data-title="¿Qué es una cita calificada?"
    data-url="que-es-una-cita-calificada"
    className="wrapper-article"
  >
    <h4>¿Qué es una cita calificada?</h4>
    <div className="content">
      <p>
        Sabemos que captar propiedades es tu actividad principal. Convencer un
        prospecto de darte su propiedad en venta requiere experiencia y
        confianza. En {companyConfig.name}, te queremos ayudar a potencializar tu negocio lo
        más rápido posible. Por ende, nuestro equipo de back office te ayuda a
        conseguir citas con propietarios interesados en vender o rentar una
        propiedad.
      </p>
      <p>
        Para hacer uso de este servicio, es muy importante indicar tus zonas
        preferidas de trabajo, sólo tienes que ingresar a tu cuenta, dentro del
        módulo <span className="tag">Mi perfil > zonas</span>. Recuerda que
        puedes agregar hasta 6 zonas de trabajo.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="dates"
    data-title="¿Cómo funcionan las citas calificadas?"
    data-url="como-funcionan-las-citas-calificadas"
    className="wrapper-article"
  >
    <h4>¿Cómo funcionan las citas calificadas?</h4>
    <div className="content">
      <p>
        Nuestro equipo de Back Office te acompañara en el proceso de conseguir
        citas con propietarios interesados en rentar o vender su propiedad.
        Antes de asignar una nueva cita, nuestro equipo se comunicará contigo
        para confirmar tu aceptación de la cita. Cada cita corresponderá a las
        zonas que hayas elegido. Podrás rechazar una cita si no la puedes
        atender. Sin embargo, esta cita contará como asignada.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="dates"
    data-title="¿Puedo rechazar una cita calificada?"
    data-url="puedo-rechazar-una-cita-calificada"
    className="wrapper-article"
  >
    <h4>¿Puedo rechazar una cita calificada?</h4>
    <div className="content">
      <p>
        Nuestro equipo de Back Office trabaja de la mano contigo para ofrecerte
        el mejor servicio de acuerdo a las zonas de tu preferencia, en caso de
        que necesites hacer un cambio, lo puedes realizar directamente en{' '}
        <span className="tag">Mi perfil > Mis zonas</span>.
      </p>
      <p>
        Puedes rechazar o cancelar una cita calificada, sin embargo es
        importante comunicarte con nuestro equipo para confirmar los motivos de
        rechazo o cancelación de la misma, de esta manera podemos compartir esa
        cita con algún otro Neximer interesado. Recuerda que las citas te las
        otorgamos de acuerdo a los datos que nos proporcionas, es por ello que
        una vez rechazada se contará como asignada.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="dates"
    data-title="¿Qué pasa si me envían citas que no son en mi zona?"
    data-url="que-pasa-si-me-envian-citas-que-no-son-en-mi-zona"
    className="wrapper-article"
  >
    <h4>¿Qué pasa si me envían citas que no son en mi zona?</h4>
    <div className="content">
      <p>Para agregar las zonas de tu preferencia, tienes que ingresar en</p>
      <span className="tag">
        Mi perfil > Mis zonas > Escribe la Colonia, Delegación, Ciudad o
        Municipio > Guardar Zonas
      </span>
      <p>
        Si tienes dudas en cuanto a la ubicación que estás seleccionando, puedes
        buscar la dirección en Google y confirmar que sea la zona en la que
        deseas recibir citas calificadas. De esta forma no deberías recibir
        citas que no se encuentran en tu zona.
      </p>
      <p>
        Recuerda que siempre puedes solicitar nuestro apoyo en la pestaña
        <span className="tag">
          Soporte administrativo > Otros > Citas calificadas
        </span>{' '}
        con gusto podemos asesorarte para que la información que ingreses en tu
        zona sea la correcta. Ahora puedes agregar hasta 6 zonas para que
        nuestro equipo de Back Office puede asignarte las citas.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>
]
