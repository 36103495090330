import { COUNTRY } from 'globalConfigApp'

/**
 *
 * @param {object[]} options - {enabledCountries:['es-MX']}
 * @return {object[]} -  object
 */
export const getEnabledOptions = (options = []) => options.filter(({ enabledCountries = [] }) => enabledCountries.includes(COUNTRY))

export function isContentCountry(whiteCountries = []) {
  return whiteCountries.includes(COUNTRY)
}