export const mapPropertyAdsFormToIds = (
  propertyAdsObjet = {},
  properties = []
) => {
  const propertyAds = Object.keys(propertyAdsObjet)

  const isAnAvailableOption = propertyAds.reduce(
    (acc, value) => (acc ? acc : propertyAdsObjet[value]),
    false
  )

  const idPropertiesSelected = propertyAds.filter(key => propertyAdsObjet[key])

  const propertiesSelected = properties.filter(({ id }) =>
    idPropertiesSelected.includes(String(id))
  )

  return { isAnAvailableOption, propertiesSelected }
}
