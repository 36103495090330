export const getStringError = error => {
  if (!error) {
    return
  }

  if (Array.isArray(error) && error.some(error => typeof error === 'string')) {
    return error.filter(error => typeof error === 'string').join(', ')
  }

  return 'Error'
}

export const getEachErrorFromObj = errors =>
  Object.keys(errors)
    .map(key => ({ [key]: getStringError(errors[key]) }))
    .reduce((acc, err) => ({ ...acc, ...err }), {})

export const mapAsyncErrors = asyncErrors =>
  Object.keys(asyncErrors)
    .map(key => {
      const error = asyncErrors[key]
      const stringError = Array.isArray(error) ? error.join(', ') : error
      return { [key]: stringError }
    })
    .reduce((acc, error) => ({ ...acc, ...error }), {})
