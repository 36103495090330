import { getNumber, numeric } from 'services/validators'

export const onlyNumbers = value => {
  return value.toString().replace(/\D/g, '')
}

export const validNumber = value => {
  return String(value).match(/([\d\.]+)/g)?.join("")?.replace(/^0+/, '') || 0;
}

export const formatCurrency = (value, prefix = '$') => {
  const numberValue = parseInt(value)
  if (isNaN(numberValue)) {
    return `${prefix}0`
  }
  return `${prefix}${numberValue.toLocaleString()}`
}

export const formatMoney = (value, currency = 'MXN', prefix = '$') => {
  const numberValue = parseFloat(value)
  if (isNaN(numberValue)) {
    return `${prefix}0.00` + ' ' + `${currency ?? "MXN"}`
  }
  return (
    `${prefix}${numberValue.toLocaleString('es-MX', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}` +
    ' ' +
    currency
  )
}

export const formatPercentaje = value => {
  return parseFloat(value).toLocaleString('es-MX') + ' %'
}

/***
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
 */

export const genericFormat = (value, options) => {
  const { lan = 'es-MX', ...restOptions } = options
  const int = Intl.NumberFormat(lan, restOptions)
  const numberValue = parseFloat(value)
  if (isNaN(numberValue)) {
    return int.format(0)
  }
  return int.format(numberValue)
}

export const currency = (value, options={}) => {
  const defaultOptions = {
    lan: 'es-MX',
    style: 'currency',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }
  return genericFormat(value, {
    ...defaultOptions,
    ...options,
    currency: options?.currency || 'MXN'
  })
}

export const formatAmount = amount => {
  return currency(amount?.amount || 0, {
    currency: amount?.currency || 'MXN'
  })
}

export const percentage = (value, options={}) => {
  const defaultOptions = {
    lan: 'es-MX',
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }
  return genericFormat(value, {
    ...defaultOptions,
    ...options
  })
}

export const nFormatter = (originalNum, originalMinValue) => {
  let num = originalNum
  if (!num || !+num || typeof +num !== 'number') {
    return {
      number: num
    }
  }

  num = +num

  let minValue = originalMinValue || 0
  let si = [
      { value: 1e18, symbol: 'E' },
      { value: 1e15, symbol: 'P' },
      { value: 1e12, symbol: 'T' },
      { value: 1e9, symbol: 'G' },
      { value: 1e6, symbol: 'M' },
      { value: 1e3, symbol: 'k' }
    ],
    rx = /\.0+$|(\.[0-9]*[1-9])0+$/,
    i

  if (typeof num === 'number' && num >= minValue) {
    for (i = 0; i < si.length; i++) {
      if (num >= si[i].value) {

        return {
          number: num / si[i].value,
          letter: si[i].symbol
        }
      }
    }
  }

  return {
    number: num
  }
}

/**
 * @function numberOrBoolean
 * @param {String} value 
 * @returns {Number|Boolean}
 */
export const numberOrBoolean = value => {
  if (typeof value !== 'string') {
    value = String(value)
  }
  if (value && numeric(value)) {
    return Number(getNumber(value)) || 0
  }

  try {
    return JSON.parse(value)
  } catch (error) {
    return value
  }
}

/**
 * @function safeDecimal
 * @description To deal with operations that returns 'strange' results, like:
 * @example
 * > 0.011 * 100 -> 1.0999999999999999
 * > safeDecimal(0.011 * 100) -> 1.1
 * @param {Number} decimal 
 * @returns {Number}
 */
 export const safeDecimal = decimal => {
  try {
    const value = new Intl.NumberFormat('en-EN').format(decimal)
    return Number(value.replace(/,/g, ''))
  } catch (e) {
    return decimal
  }
}