import ApiBase from 'app/services/api/entities/Base'

function* states() {
  return yield ApiBase.privateGet('/v1/locationservice/states/')
}

function* municipalities(stateId) {
  return yield ApiBase.privateGet(
    `/v1/locationservice/states/${stateId}/municipalities/`,
  )
}

function* neighborhoods(stateId, municipalityId) {
  return yield ApiBase.privateGet(
    `/v1/locationservice/states/${stateId}/municipalities/${municipalityId}/neighborhoods/`,
  )
}

export default {
  states,
  neighborhoods,
  municipalities,
}