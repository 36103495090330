import api from '../api'


const getByUUID = uuid => api.get(`/v1/paymentondemand/payment_charges/${uuid}/`, {}, {}, {})

const getPaymentPlan = (uuid, paymentMethodId) => api.post(
  `/v1/paymentondemand/payment_charges/${uuid}/installments/`,
  {},
  { paymentMethodId },
  {}
)

const confirmPayment = (uuid, count) => api.post(
  `/v1/paymentondemand/payment_charges/${uuid}/confirm/`,
  {},
  {
    count
  },
  {}
)

export default {
  getByUUID,
  getPaymentPlan,
  confirmPayment
}
