import { useState } from 'react'

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: false,
}

function useFeedback() {
  const [feedback, setFeedback] = useState(INITIAL_STATE)

  const setLoading = () =>
    setFeedback({
      success: false,
      error: false,
      loading: true,
    })

  const setSuccess = () =>
    setFeedback({
      success: true,
      error: false,
      loading: false,
    })

  const setError = () =>
    setFeedback(
      setFeedback({
        success: false,
        error: true,
        loading: false,
      }),
    )

  const setClean = () => setFeedback(INITIAL_STATE)

  return {
    ...feedback,
    setFeedback: {
      loading: setLoading,
      success: setSuccess,
      error: setError,
      clean: setClean,
    },
  }
}

export default useFeedback
