export default {
  name: 'crmBinLead',
  namespace: 'bin_leads',
  methods: [
    {
      name: 'find',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/crm/${namespace}/${id}/`,
      urlParams: ['id']
    },
    {
      name: 'publicFindProperty',
      params: ['id', 'ad'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, id }) =>
        `/v1/crm/public/${namespace}/${id}/find_property/`,
      urlParams: ['id']
    },
    {
      name: 'publicFind',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/crm/public/${namespace}/${id}/`,
      urlParams: ['id']
    },
    {
      name: 'publicLike',
      params: ['id', 'data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/crm/public/${namespace}/${id}/like/`,
      urlParams: ['id']
    },
    {
      name: 'publicReject',
      params: ['id', 'data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/crm/public/${namespace}/${id}/reject/`,
      urlParams: ['id']
    },
    {
      name: 'publicView',
      params: ['id'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/crm/public/${namespace}/${id}/view/`,
      urlParams: ['id']
    },

    {
      name: 'privateView',
      params: ['id'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/crm/unique_contact/bin_leads/${id}/view/`,
      urlParams: ['id']
    },
    {
      name: 'privateFindProperty',
      params: ['id', 'ad'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/crm/unique_contact/bin_leads/${id}/find_property/`,
      urlParams: ['id']
    },
    {
      name: 'privateFind',
      params: ['id', 'ad'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/crm/unique_contact/bin_leads/${id}/`,
      urlParams: ['id']
    },
    {
      name: 'privateLike',
      params: ['id', 'data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/crm/unique_contact/${namespace}/${id}/like/`,
      urlParams: ['id']
    },
    {
      name: 'privateReject',
      params: ['id', 'data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/crm/unique_contact/${namespace}/${id}/reject/`,
      urlParams: ['id']
    },
    {
      name: 'filter',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/crm/${namespace}/`,
      urlParams: []
    },
    {
      name: 'acceptLead',
      params: ['id', 'data'],
      successCode: 200,
      method: 'Patch',
      url: ({ namespace, id }) => `/v1/crm/${namespace}/${id}/`,
      urlParams: ['id']
    },
    {
      name: 'contactMe',
      params: ['binLeadId', 'data'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace, binLeadId }) => `/v1/crm/unique_contact/bin_leads/${binLeadId}/mortgage_interest/`,
      urlParams: ['id']
    },
    {
      name: 'shareRequirement',
      params: ['binLeadId', 'data'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace, binLeadId }) => `/v1/crm/unique_contact/bin_leads/${binLeadId}/share/`,
      urlParams: ['id']
    },
    {
      name: 'callMeBack',
      params: ['binLeadId', 'data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, binLeadId }) => `/v1/crm/unique_contact/bin_leads/${binLeadId}/call_me/`,
      urlParams: ['id']
    },
    {
      name: 'makeSchedule',
      params: ['binLeadId', 'data'],
      successCode: 200,
      method: 'Post',
      url: ({ binLeadId }) => `/v1/crm/unique_contact/bin_leads/${binLeadId}/schedule_visit/`,
      urlParams: []
    },
    {
      name: 'closeRequirement',
      params: ['requirementId', 'data'],
      successCode: 200,
      method: 'Post',
      url: ({ requirementId }) => `/v1/crm/requirements/${requirementId}/close/`,
      urlParams: []
    }
  ]
}
