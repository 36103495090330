export default {
  name: 'paymentSubscription',
  namespace: 'subscriptions',
  methods: [
    {
      name: 'patch',
      params: ['id', 'data'],
      successCode: 200,
      method: 'Patch',
      url: ({ namespace, id }) => `/v1/payments/${namespace}/${id}/`,
      urlParams: ['id']
    },
    {
      name: 'create',
      params: ['data'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace }) => `/v1/payments/${namespace}/`,
      urlParams: [],
      seq: false
    },
    {
      name: 'update',
      params: ['id', 'data'],
      successCode: 200,
      method: 'Put',
      url: ({ namespace, id }) => `/v1/payments/${namespace}/${id}/`,
      urlParams: [ 'id' ]
    },
    {
      name: 'filter',
      params: [],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/payments/${namespace}/`,
      urlParams: [],
      seq: false
    },
    {
      name: 'updateSignupFee',
      params: ['paymentId', 'data'],
      successCode: 200,
      method: 'Patch',
      url: ({ paymentId, namespace }) =>
        `/v1/payments/${namespace}/${paymentId}/signup_fee/`,
      urlParams: [],
    },
  ]
}
