import { useEffect } from 'react'

/**
 * To fix background view and prevent scroll.
 * It can be used at modals u overlays.
 * @param {Boolean} isOpen 
 */

const useBlockWindowOverflow = isOpen => {
  useEffect(
    () => {
      if (typeof document !== 'undefined') {
        document.body.style.overflowY = isOpen ? 'hidden' : 'auto'
      }

      return () => {
        if (typeof document !== 'undefined') {
          document.body.style.overflowY = 'auto'
        }
      }
    },
    [isOpen]
  )
}

export default useBlockWindowOverflow
