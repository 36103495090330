import { colors } from 'theme'
import css from 'styled-jsx/css'

export default css`
  .button.facebook {
    background-color: ${colors.facebook};
    border-color: ${colors.facebook};
    color: ${colors.white};
    transition: 100ms background-color, 100ms border-color, 100ms color;
  }
`
