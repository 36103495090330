import { REHYDRATE } from 'redux-persist'
import { call, put, select, take } from 'redux-saga/effects'
import { normalizeAsUrl } from 'services/string'

import { UserSession } from 'services/api/entities'
import { UserSessionActionCreators } from 'app/reducers/UserSessionRedux'

import { allDeviceInfo, getNeximoBroadcastChannel } from 'services/seo'

export function* userSessionBroadcastChannel() {
  yield take(REHYDRATE)
  const bc = getNeximoBroadcastChannel()

  if (bc) {
    console.log('neximo BroadcastChannel was registered')
  }
}

function getFingerprint(alias) {
  return normalizeAsUrl(alias)
}

const DEVICE_TYPES = {
  mobile: 'MOBILE',
  smartphone: 'MOBILE',
  tablet: 'TABLE',
  desktop: 'DESKTOP',
  unknown: 'UNKNOWN',
}

const DEVICE_TYPE_LABELS = {
  smartphone: 'Smartphone',
  mobile: 'Smartphone',
  tablet: 'Tablet',
  desktop: 'Computadora',
}

const joinDeviceInfo = parts => parts.filter(Boolean).join(' ')

function* getDeviceInfo() {
  const response = yield UserSession.deviceInfo(navigator.userAgent)

  if (response.success()) {
    const { device, os, client } = response.data
    const deviceInfo = joinDeviceInfo([
      DEVICE_TYPE_LABELS[device.type] || device.type,
      device.brand,
      device.model,
    ])
    const osInfo = joinDeviceInfo([os.name, os.version])
    const clientInfo = joinDeviceInfo([client.name, client.version])
    return {
      devicetype: DEVICE_TYPES[device.type] || DEVICE_TYPES.unknown,
      deviceInfo: joinDeviceInfo([deviceInfo, '|', osInfo, '|', clientInfo]),
    }
  }

  try {
    const { devicetype, os, browser } = allDeviceInfo()
    return {
      devicetype: DEVICE_TYPES[devicetype] || DEVICE_TYPES.unknown,
      deviceInfo: joinDeviceInfo([
        DEVICE_TYPE_LABELS[devicetype] || devicetype,
        '|',
        `${os.name} ${os.version}`,
        '|',
        `${browser.name} ${browser.version}`,
      ]),
    }
  } catch (e) {
    return {
      devicetype: DEVICE_TYPES.unknown,
      deviceInfo: 'No device info',
    }
  }
}

export function* createSessionRequest({ alias }) {
  const fingerprint = getFingerprint(alias)
  const { devicetype, deviceInfo } = yield call(getDeviceInfo)
  const bc = getNeximoBroadcastChannel()

  const response = yield UserSession.createSession({
    fingerprint,
    alias,
    devicetype,
    deviceInfo,
  })

  if (response.success()) {
    const action = UserSessionActionCreators.createSessionSuccess(
      response.data.id,
      response.data.fingerprint,
      alias,
    )

    yield put(action)

    if (bc) {
      bc.postMessage(action)
    }
    return
  }

  yield put(UserSessionActionCreators.createSessionFailure(response.data))
}

export function* getSessions() {
  const response = yield UserSession.sessions()
  if (response.success()) {
    return yield put(
      UserSessionActionCreators.getSessionsSuccess(response.data),
    )
  }
  yield put(UserSessionActionCreators.getSessionsFailure(response.data))
}

export function* logoutSessionsRequest({ sessions }) {
  const response = yield UserSession.logoutSessions(sessions)
  const bc = getNeximoBroadcastChannel()

  if (response.success()) {
    const action = UserSessionActionCreators.logoutSessionsSuccess(
      response.data,
    )
    yield put(action)
    if (bc) {
      bc.postMessage(action)
    }
    return
  }
  yield put(UserSessionActionCreators.logoutSessionsFailure(response.data))
}

export function* logoutSessionRequest() {
  const { userSessionId } = yield select(state => state.userSession)

  if (!userSessionId) {
    /* Users like OBOTU don't have userSessionId */
    return
  }

  const response = yield UserSession.logoutSession(userSessionId)

  if (response.success()) {
    console.log(`Device ${userSessionId} was logout`)
  } else {
    console.error(`Error at logout device ${userSessionId}`, response.data)
  }
}

export function* revalidateSession() {
  const { userSessionId } = yield select(state => state.userSession)
  const response = yield UserSession.revalidateSession(userSessionId)
  const bc = getNeximoBroadcastChannel()

  if (response.success()) {
    console.log(`Device ${userSessionId} was revalidated`)

    const action = UserSessionActionCreators.revalidateSessionSuccess()
    yield put(action)

    if (bc) {
      bc.postMessage(action)
    }
  } else {
    console.error(`Error at revalidate device ${userSessionId}`, response.data)
  }
}

export function* loginOrRefreshTokenSuccess({ response, refreshData }) {
  const data = { ...response, ...refreshData }

  const bc = getNeximoBroadcastChannel()

  if (bc) {
    bc.postMessage(
      UserSessionActionCreators.loginOrRefreshTokenSuccess(data)
    )
  }
}