import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classnames from 'classnames'
import Input from 'controls/Input'
import DatePicker from 'react-datepicker/lib/'
import { makeRangeIterator } from 'components/utils'
import { Button, IonIcon, SimpleDropDown } from 'controls'
import { monthNames } from 'services/date'

import styles, { datePickerCustomHeaderStyles } from './styles'
class DatePickerComponent extends Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.bool,
    }),
    placeholder: PropTypes.string,
    label: PropTypes.string,
  }

  static defaultProps = {
    placeholder: '',
  }

  handleChange = date => {
    this.props.input.onChange(date ? moment(date).format('YYYY-MM-DD') : null)
  }

  render() {
    const {
      input,
      label,
      customInput,
      asFieldFormCentered,
      meta: { valid = false, invalid = false, touched = false, error, warning },
    } = this.props
    return (
      <div
        className={classnames('date-container', {
          'as-field-form-centered': asFieldFormCentered,
          'date-error': error,
        })}
      >
        <DatePicker
          customInput={customInput || <Input label={label} />}
          selected={input.value ? moment(input.value, 'YYYY-MM-DD') : null}
          renderCustomHeader={DatePickerCustomHeader}
          {...this.props}
          onChange={this.handleChange}
        />
        {error && <span className={'date-invalid-message'}>{error}</span>}
        <style jsx global>
          {styles}
        </style>
      </div>
    )
  }
}

const months = Object.keys(monthNames).map(index => monthNames[index])
const years = Array.from(
  makeRangeIterator(
    new Date().getFullYear() - 90,
    new Date().getFullYear() + 10,
    1
  )
).reverse()

function getYear(date) {
  return moment(date).year()
}

function getMonth(date) {
  return moment(date).month()
}


function DatePickerCustomHeader({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) {
  return (
    <div className="custom-date-picker--container">
      <Button
        className="navigation-buttton dropleft"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <IonIcon icon="md-arrow-dropleft" />
      </Button>

      <SimpleDropDown
        className="custom-date-picker--simple-dropdown-select month-dropdown"
        value={months[getMonth(date)]}
        placeholder="Mes"
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </SimpleDropDown>

      <SimpleDropDown
        className="custom-date-picker--simple-dropdown-select"
        value={getYear(date)}
        placeholder="Año"
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </SimpleDropDown>

      <Button
        className="navigation-buttton dropright"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <IonIcon icon="md-arrow-dropright" />
      </Button>

      <style jsx>{datePickerCustomHeaderStyles}</style>
    </div>
  )
}

export default DatePickerComponent
