import React, { PureComponent } from 'react'
import { Button } from 'controls'
import PropTypes from 'prop-types'

class OpenFileButton extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    onChange: () => {}
  }

  render() {
    const props = Object.assign({}, this.props, { onChange: null })

    return (
      <Button {...props}>
        {
          !props.disabled &&
          <input
            type="file"
            // below, and incredibly awesome hack
            style={{ position: 'absolute', width: '100%', height: '100%', opacity: '0', cursor: 'pointer' }}
            onChange={ this.props.onChange }
          />
        }
        {
          this.props.children
        }
      </Button>
    )
  }
}

export default OpenFileButton
