export const fontFamily = {
  default: "'Montserrat', sans-serif",
  handwrite: "'Yellowtail', cursive",
}


const fonts = {
  regular: `400 1rem ${fontFamily.default};`,
  medium: `500 1rem ${fontFamily.default};`,
  semiBold: `600 1rem ${fontFamily.default};`,
  bold: `700 1rem ${fontFamily.default};`,

  handwrite: `400 1rem ${fontFamily.handwrite}`
}

// Taking as 1rem = 16px
export const fontSizes = {
  h1: '2.25rem', // 36px
  h2: '2rem', // 32px
  h3: '1.75rem', // 28px
  h4: '1.5rem', // 24px
  h5: '1.25rem', // 20px
  body: '1.125rem', // 18px
  p: '1rem', // 16px
  small: '0.875rem', // 14px
  tiny: '0.75rem', // 12px
  micro: '0.625rem' // 10px
}

export const fontSizesMobile = {
  h1: '1.7rem', // 36px
  h2: '1.5rem', // 32px
  h3: '1.3rem', // 28px
  h4: '1.2rem', // 24px
  h5: '1rem', // 20px
  body: '1rem', // 18px
  p: '1rem', // 16px
  small: '0.875rem', // 14px
  tiny: '0.75rem', // 12px
  micro: '0.625rem' // 10px
}

export const fontWeights = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700
}

export const getFont = (font, fontSize) =>
  `font: ${fonts[font]}; font-size: ${fontSizes[fontSize]};`

export default fonts
