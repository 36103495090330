import { call, put, select } from 'redux-saga/effects'
import { DokaltinActionCreators } from 'app/reducers/DokaltinRedux'
import api from 'app/services/api'
import { getSavingFields } from 'selectors/dokaltin'
import { AlertActionCreators } from 'app/reducers/AlertRedux'


export function* lockDocumentRequest({documentId}){
  const token = yield select(state => state.auth.token)
  const lockDocument = yield call(api.dokaltin.lockDocument, token, documentId)

  if(lockDocument.status === 200){
    yield put(DokaltinActionCreators.lockDocumentSuccess())
  }
}

const uploadFileToS3 = (url, formData) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.open('POST', url)
    xhr.onreadystatechange = () => {
      if(xhr.readyState === XMLHttpRequest.DONE && xhr.status === 204) {
        return resolve(xhr.response)
      }

      reject(xhr.response)
    }

    xhr.send(formData)
  })
}

export function* uploadFile({ file }) {
  const uploadFormResponse = yield call(api.dokaltin.getUploadForm)
  if (uploadFormResponse.status !== 200) {
    return yield put(DokaltinActionCreators.uploadFileFailure(uploadFormResponse.data))
  }

  // once we have our uploadform, call S3
  const uploadForm = uploadFormResponse.data
  const formData = new FormData()
  Object.keys(uploadForm.fields).forEach(formKey => {
    formData.append(formKey, uploadForm.fields[formKey])
  })

  formData.set('key', 'dokaltin/uploads/${filename}')
  formData.append('file', file)

  let uploadFileResponse

  try {
    uploadFileResponse = yield call(uploadFileToS3, uploadForm.url, formData)
  } catch (ex) {
    return yield put(DokaltinActionCreators.uploadFileFailure(ex.message))
  }

  yield call(api.dokaltin.processFile, { filename: file.name })

  yield put(DokaltinActionCreators.uploadFileSuccess(uploadFileResponse))
}

export function* getDocument({documentId}) {
  const token = yield select(state => state.auth.token)
  const response = yield call(api.dokaltin.getDocument, token, documentId)

  if (response.status === 200) {
    return yield put(DokaltinActionCreators.getDocumentSuccess(response.data))
  }

  yield put(DokaltinActionCreators.getDocumentFailure(response.data))
}

export function* getSharedDocument({ hashId, validationCode }) {
  const response = yield call(api.dokaltin.getSharedDocument, hashId, validationCode)

  if (response.status === 200) {
    yield put(DokaltinActionCreators.getDocumentSuccess(response.data.document))
    yield put(DokaltinActionCreators.getSharedDocumentSuccess(response.data))
    return
  }

  let message
  if (response.status === 404) {
    message = 'No se encontró el documento'
  } else if (response.status === 403) {
    switch (response.data.errorCode) {
      case 502:
        message = 'El link expiró'
        break
      case 504:
        message = 'El link ya no existe'
        break
      case 506:
        message = 'No tiene permisos para acceder al documento'
        break
      default:
        message = 'No puede accederse al documento'
    }
  }

  yield put(DokaltinActionCreators.getSharedDocumentFailure(message))
}

export function* getDownloadedDocument({ documentId }) {
  const response = yield call(api.dokaltin.getDownloadedDocument, documentId)

  if (response.status === 200) {
    return yield put(DokaltinActionCreators.getDocumentSuccess(response.data))
  }

  yield put(DokaltinActionCreators.getDocumentFailure(response.data))
}


function* saveSharedFields() {
  const token = yield select(state => state.dokaltin.shareData.token)
  const responses = {}
  const { documentId, shareId } = yield select(state => ({documentId: state.dokaltin.id, shareId: state.dokaltin.shareData.id}))
  let savingFields = yield select(getSavingFields)
  savingFields = savingFields.filter(field => field.action === 'update')
  const savingFieldsCount = savingFields.length

  for (let index = 0; index < savingFieldsCount; index++) {
    const { action, documentId, pageId, control } = savingFields[index]
    const { value, format } = control
    const response = yield call(api.dokaltin.updateField, token, control.id, { value, format })

    responses[control.id] = {
      action: 'update',
      success: response.status === 200,
      response: response.data,
      pageId,
      control
    }
  }

  const savingSucceeded = Object.keys(responses).reduce((mem, resKey) => responses[resKey].success && mem, true)

  if (savingSucceeded) {
    const logDataResponse = yield call(api.dokaltin.setLogData, token, documentId, shareId)
  }
  yield put(DokaltinActionCreators.saveFieldsCompleted(responses))
}

/**
 * Each saving field has this structure:
 *
 * {
 *   documentId,
 *   pageId,
 *   action,
 *   control: {
 *     id
 *     x,
 *     y,
 *     etc.
 *   }
 * }
 *
 */
export function* saveFields() {
  const shared = yield select(state => state.dokaltin.shareData && state.dokaltin.shareData.id)
  if (shared) {
    return yield saveSharedFields()
  }

  const token = yield select(state => state.auth.token)
  const savingFields = yield select(getSavingFields)
  const savingFieldsCount = savingFields.length
  const responses = {}

  for (let index = 0; index < savingFieldsCount; index++) {
    const { action, documentId, pageId, control } = savingFields[index]
    let response
    let success = false

    if (action === 'delete') {
      response = yield call(api.dokaltin.deleteField, token, control.id)
      success = response.status === 204
    } else if (action === 'update') {
      const { id, assignee, fieldType, pageId, ...data } = control

      // even being the owner, I can't update the value of the field if it's assigned to an actor
      if (control.assignee && control.assignee.id) {
        delete data.value
      }
      response = yield call(api.dokaltin.updateField, token, control.id, data)
      success = response.status === 200
    } else {
      response = yield call(api.dokaltin.createField, token, documentId, pageId, control)
      success = response.status === 201
    }

    responses[control.id] = {
        action,
        success,
        response: response.data,
        pageId,
        control
      }
  }

  yield put(DokaltinActionCreators.saveFieldsCompleted(responses))
}

export function* shareDocument({ actorId }) {
  const token = yield select(state => state.auth.token)
  const documentId = yield select(state => state.dokaltin.id)

  const response = yield call(api.dokaltin.shareDocument, token, documentId, actorId)

  if (response.status === 201) {
    return yield put(DokaltinActionCreators.shareDocumentSuccess(response.data))
  }

  yield put(DokaltinActionCreators.shareDocumentFailure(response.data))
}

export function* assignField({ fieldId, actorId, pageId }) {
  const token = yield select(state => state.auth.token)

  const response = yield call(api.dokaltin.updateField, token, fieldId, { assigneeId: actorId })

  if(response.status === 404){
    yield put(AlertActionCreators.warning({message:'Para asignar primero debe guardar el documento.'}))
  }
  yield put(DokaltinActionCreators.saveFieldsCompleted({
    [fieldId]: {
      action: 'update',
      success: response.status === 200,
      response: response.data,
      pageId: pageId,
      control: response.data
    }
  }))
}