import { Button } from 'controls'
import { companyConfig } from 'globalConfigApp'

import styles from './styles'

export default [
  <article
    data-category="bin"
    data-title="¿Qué es la BIN?"
    data-url="que-es-la-bin"
    className="wrapper-article"
  >
    <h4>¿Qué es la BIN?</h4>
    <div className="content">
      <p>
        La Bolsa Inmobiliaria {companyConfig.name} (BIN) es una red creada para nuestros
        Neximers en la cuál pueden compartir sus propiedades con más de 400
        asesores en México. Ahora es muy fácil promover tu inventario, ofrecer a
        tus clientes exactamente lo que buscan y darle seguimiento, todo en un
        mismo lugar.
      </p>
      <Button color="primary" href="/dashboard/bin" size="tiny">
        Entrar ahora
      </Button>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="bin"
    data-title="¿Qué es un requerimiento?"
    data-url="que-es-un-requerimiento"
    className="wrapper-article"
  >
    <h4>¿Qué es un requerimiento?</h4>
    <div className="content">
      <p>
        Es una petición por parte de tus clientes sobre una propiedad en
        particular. Podrás enviar diferentes opciones de inmuebles de manera
        automática vía correo y mensaje de texto, podrás saber exactamente
        cuándo vio tu requerimiento, y el cliente podrá seleccionar las que sean
        de su interés.
      </p>
      <Button color="primary" href="/dashboard/bin" size="tiny">
        Empezar requerimiento
      </Button>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="bin"
    data-title="¿Por qué no puedo hacer un filtro por Estado o Delegación en la BIN?"
    data-url="por-que-no-puedo-hacer-un-filtro-por-estado-o-delegacion-en-la-bin"
    className="wrapper-article"
  >
    <h4>
      ¿Por qué no puedo hacer un filtro por Estado o Delegación en la BIN?
    </h4>
    <div className="content">
      <p>
        La BIN esta diseñada para cumplir las necesidades especificas dónde tu
        cliente desea comprar o rentar, es por eso que los filtros por ubicación
        únicamente funcionan agregando cada uno de los siguientes campos:{' '}
        <span className="tag">Estado > Delegación o Municipio > Colonia</span>,
        también puedes seleccionar múltiples colonias para obtener más opciones.
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="bin"
    data-title="¿Cuántas propiedades puedo compartir desde la BIN?"
    data-url="cuantas-propiedades-puedo-compartir-desde-la-bin"
    className="wrapper-article"
  >
    <h4>¿Cuántas propiedades puedo compartir desde la BIN?</h4>
    <div className="content">
      <p>
        Si encuentras diferentes opcipnes que se adecuan a las necesidades de tu
        cliente, podrás compartir fácil y rápido, sin embargo la BIN sólo te
        permite enviar hasta 10 propiedades.
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="bin"
    data-title="¿Al compartir la propiedad de otro Neximer aparecen sus datos de contacto?"
    data-url="al-compartir-la-propiedad-de-otro-neximer-aparecen-sus-datos-de-contacto"
    className="wrapper-article"
  >
    <h4>
      ¿Al compartir la propiedad de otro Neximer aparecen sus datos de contacto?
    </h4>
    <div className="content">
      <p>
        La BIN está programada para que puedas compartir las propieades de la
        red a tus clientes, por lo que sólo se envía la información del inmueble
        pero nunca los datos del otro asesor. Sin embargo, al Neximer que maneja
        esa propiedad le llegará una notificación cada que se envíe a un
        prospecto.
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="bin"
    data-title="¿Por qué mi propiedad no aparece en la BIN?"
    data-url="por-que-mi-propiedad-no-aparece-en-la-bin"
    className="wrapper-article"
  >
    <h4>¿Por qué mi propiedad no aparece en la BIN?</h4>
    <div className="content">
      <p>
      Te recomendamos revisar el cómo asignaste tu propiedad, si la marcaste como desarrollo o tiene contrato de colaboración no serán visibles en la BIN
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>
]
