import crudAction from './actions'

export default {
  name: 'neximerPerformanceStats',
  namespace: 'neximer_performance_stats',
  methods: [
    ...crudAction,
    {
      name: 'getStatsByDate',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/${namespace}/`,
      urlParams: ['id']
    },
  ]
}
