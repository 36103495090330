import { put } from 'redux-saga/effects'

import { DocumentationFolders } from 'services/api/entities'
import { DocumentationFoldersActionCreators } from 'app/reducers/DocumentationFoldersRedux'

export function* all({ params }) {
  const response = yield DocumentationFolders.all({ params })
  if (response.success()) {
    yield put(DocumentationFoldersActionCreators.allSuccess(response.data))
  } else {
    yield put(DocumentationFoldersActionCreators.allFailure(response.data))
  }
}
