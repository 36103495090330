import { call, put, select, take } from 'redux-saga/effects'
import { PortalsActionCreators } from 'app/reducers/PortalsRedux'
import api from 'app/services/api'
import { removeAllEmptyKeys } from 'services/utils'

export function* getDataTableCalls({ to, from, offset = null }) {
  const token = yield select(state => state.auth.token)
  const params = { to, from, offset }

  const response = yield call(
    api.portals.getDataTableCalls,
    token,
    {},
    removeAllEmptyKeys(params)
  )

  if (response.status === 200) {
    return yield put(
      PortalsActionCreators.getDataTableCallsSuccess(
        response.data.results,
        response.data.count
      )
    )
  }

  yield put(PortalsActionCreators.getDataTableCallsFailure(response.data))
}

export function* getCallRecordsStats({ to, from }) {
  const token = yield select(state => state.auth.token)
  const params = { to, from }

  const response = yield call(
    api.portals.getCallRecordsStats,
    token,
    {},
    params
  )

  if (response.status === 200) {
    return yield put(
      PortalsActionCreators.getCallRecordsStatsSuccess(response.data.records)
    )
  }
  yield put(PortalsActionCreators.getCallRecordsStatsFailure(response.data))
}
