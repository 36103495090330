import ApiBase from 'app/services/api/entities/Base'

function* recommendations(params = {}) {
  return yield ApiBase.privateGet(`/v1/mlm_networkstats/recommendations/`, {
    params,
  })
}

function* inventory(params = {}) {
  return yield ApiBase.privateGet('/v1/mlm_networkstats/inventory/', {
    params,
  })
}

function* inventoryQuality(params = {}) {
  return yield ApiBase.privateGet('/v1/mlm_networkstats/inventory_quality/', {
    params,
  })
}

function* contacts(params = {}) {
  return yield ApiBase.privateGet('/v1/mlm_networkstats/contacts/', {
    params,
  })
}

function* billing(params = {}) {
  return yield ApiBase.privateGet('/v1/mlm_networkstats/billing/', {
    params,
  })
}
function* inventoryQualityCSV(params = {}) {
  return yield ApiBase.privateGet('/v1/mlm_networkstats/inventory_quality_detail/', {
    params,
  })
}
function* inventoryCSV(params = {}) {
  return yield ApiBase.privateGet('/v1/mlm_networkstats/inventory_detail/', {
    params,
  })
}
function* contactsCSV(params = {}) {
  return yield ApiBase.privateGet('/v1/mlm_networkstats/contacts_detail/', {
    params,
  })
}
function* billingCSV(params = {}) {
  return yield ApiBase.privateGet('/v1/mlm_networkstats/billing_detail/', {
    params,
  })
}
export default {
  recommendations,
  inventory,
  inventoryQuality,
  contacts,
  billing,
  inventoryQualityCSV,
  inventoryCSV,
  contactsCSV,
  billingCSV,
}
