export const summaryNetworkStatsSelector = (stats, statType) => {
  if (statType === 'summary') {
    const {
      topClosers,
      topBilling,
      topNetworkBilling,
      topPropertiesInExclusive,
      topStock,
      topRecruited,
    } = stats

    return [
      {
        category: 'topClosers',
        categoryLabel: 'Top Cerradores',
        ...topClosers,
        total: topClosers.totalDeals,
      },
      {
        category: 'topBilling',
        categoryLabel: 'Top Facturación',
        ...topBilling,
      },
      {
        category: 'topNetworkBilling',
        categoryLabel: 'Top Facturación de red',
        ...topNetworkBilling,
      },
      {
        category: 'topPropertiesInExclusive',
        categoryLabel: 'Top Exclusivas',
        ...topPropertiesInExclusive,
        total: topPropertiesInExclusive.totalExclusives,
      },
      {
        category: 'topStock',
        categoryLabel: 'Top Inventario',
        ...topStock,
        total: topStock.totalStock,
      },
      {
        category: 'topRecruited',
        categoryLabel: 'Top Reclutamiento',
        ...topRecruited,
        total: topRecruited.totalRecruited,
      },
    ]
  }

  return stats
}
