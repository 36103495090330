
export const PAGES = {
  DEFAULT: 'DEFAULT',
  GLOBAL: 'GLOBAL',

  HOME: 'HOME',                                             // ''/' - neximo.mx
  SRP: 'SRP',                                               // {{ BASE_SRP_URL }} - property searchs
  VIP: 'VIP',                                               // '/property-type/operation-type/location/property-id' - property detail
  TEAM: 'TEAM',                                             // '/equipo' - All brokers
  TEAM_PROFILE: 'TEAM_PROFILE',                             // '/equipo/broker-name/boker-id' - broker details and its properties
  ABOUT: 'ABOUT',                                           // '/about' - About neximo (temporarily out of service)
  LOGOUT: 'LOGOUT',                                         // '/logout' - Just a redirect page before a logout
  TERMS: 'TERMS',                                           // '/terminos-condiciones' - Terms and conditions
  PRIVACY: 'PRIVACY',                                       // '/politica-privacidad' - Privacy terms
  SIGNUP: 'SIGNUP',                                         // '/registrarse' - Just Signup form (Don't have a nice design or footer)
  JOIN: 'JOIN',                                             // '/unete' - General Neximo's advantages (Imrpove info) and Signup form
  BETTER_SERVE_YOUR_CLIENTS: 'BETTER_SERVE_YOUR_CLIENTS',   // '/ayuda-tus-clientes' - Neximo's advantages: Portals
  ACQUIRE_MORE_PROPERTIES: 'ACQUIRE_MORE_PROPERTIES',       // '/capta-propiedades' - Neximo's advantages: Leads
  ADMINISTRATIVE_SUPPORT: 'ADMINISTRATIVE_SUPPORT',         // '/soporte-administrativo' - Neximo's advantages: Support services
  PROMOTE_YOUR_PROPERTIES: 'PROMOTE_YOUR_PROPERTIES',       // '/promueve-propiedades' - Neximo's advantages: Commissions
  TRANSACTION_MANAGEMENT: 'TRANSACTION_MANAGEMENT',         // '/gestion-transaccion' - Neximo's advantages: CRM
  PERSONAL_DEVELOPMENT: 'PERSONAL_DEVELOPMENT',             // '/desarrollo-personal' - Neximo's advantages: coaching
  FULL_SERVICE_ONLY: 'FULL_SERVICE_ONLY',                   // '/full-service-only' - Full service restriction message
  BIN: 'BIN',                                               // '/bin/requirement-id' - Interested dashboard
  BIN_VIP: 'BIN_VIP',                                       // '/bin/requirement-id/property/property-id' - Interested dashboard property detail 
  BIN_PUBLIC: 'BIN_PUBLIC',                                 // '/bin/public/:binLeadId/share/:contactShareId/' - Old requirements (out of service)
  BIN_VIP_PUBLIC: 'BIN_VIP_PUBLIC',                         // '/bin/public/:binLeadId/share/:contactShareId/property-id' - Old requirements (out of service)
  PRICES: 'PRICES',                                         // '/precios' - Memberships prices
  CONTACT: 'CONTACT',                                       // '/contactanos' - Contact page
  VALUATION_NEW: 'VALUATION_NEW',                           // '/valuation/new/:section' - Form to request a valuation property 
  RESET_PASSWORD: 'RESET_PASSWORD',                         // '/reset-password' - Just a simple form
  HELP: 'HELP',                                             // '/help' - Main help page
  HELP_CATEGORY: 'HELP_CATEGORY',                           // '/help/:category' or '/help/:category/:id' - Help topics details
}

export const URL_TYPE = {
  FOOTER: 'FOOTER'
}

export const NEXT_JS_NAVIGATION_PREFIX = '_next/data'

export const BASE_SRP_URL = '/en-venta'
