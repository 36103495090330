export default {
  name: 'oauth',
  namespace: 'oauth',
  methods: [
    {
      name: 'checkEmail',
      params: ['email'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/${namespace}/check_email/`,
      urlParams: []
    },
    {
      name: 'accessToken',
      params: ['email', 'password', 'fingerprint'],
      successCode: [200, 202],
      method: 'Post',
      url: ({ namespace }) => `/v1/${namespace}/access_token/`,
      urlParams: []
    },
    {
      name: 'refreshToken',
      params: ['token', 'fingerprint'],
      successCode: [200, 202],
      method: 'Post',
      url: ({ namespace }) => `/v1/${namespace}/refresh_token/`,
      urlParams: []
    },
    {
      name: 'refreshTokenWithToken',
      params: ['token'],
      successCode: [200, 202],
      method: 'Post',
      url: ({ namespace }) => `/v1/${namespace}/refresh_token/`,
      urlParams: [],
      saga: false
    },

    {
      name: 'accessContactToken',
      params: ['email', 'telephone'],
      successCode: [200, 202],
      method: 'Post',
      url: ({ namespace }) => `/v1/crm/unique_contact/oauth/access_token/`,
      urlParams: []
    },
    {
      name: 'refreshContactToken',
      params: ['token'],
      successCode: [200, 202],
      method: 'Post',
      url: ({ namespace }) => `/v1/crm/unique_contact/oauth/refresh_token/`,
      urlParams: []
    },
    {
      name: 'signup',
      params: [
        'email',
        'firstName',
        'lastName',
        'secondLastName',
        'telephone',
        'secondTelephone',
        'password',
        'role'
      ],
      successCode: 201,
      method: 'Post',
      url: ({ namespace }) => `/v1/${namespace}/signup/`,
      urlParams: []
    }
  ]
}
