import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {}

const { Types, Creators } = createActions(
  {
    asyncValidateForm: ['formName', 'errors', 'asyncValidatorName'],
    updateAsyncErrors: ['formName', 'asyncErrors']
  },
  { prefix: 'VALIDATE_FORM_' }
)

const updateAsyncErrors = (state, { formName, asyncErrors }) => ({
  ...state,
  [formName]: {
    asyncErrors
  }
})

const HANDLERS = {
  [Types.UPDATE_ASYNC_ERRORS]: updateAsyncErrors
}

export const ValidateFormActionTypes = Types

export const ValidateFormActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
