import { camelToSnake } from 'services/string'

import reducers from './reducers'
import sagas from './sagas/sagas'

const store = {

}

Object.keys(reducers).forEach((rk) => {
  reducers[rk].model.methods.forEach((m) => {
    const methodName = m.name
    store[methodName] = (modelName) => {
      const modelNameUpper = camelToSnake(modelName).toUpperCase()
      const actionNameUpper = camelToSnake(methodName).toUpperCase()
      const saga = sagas.find((s) => s.type === `STORE_${modelNameUpper}_${actionNameUpper}_REQUEST`)
      return {
        saga: saga ? saga.saga : null,
        action: reducers[modelName].Creators[`${methodName}Request`],
        clear: reducers[modelName].Creators[`${methodName}Clear`],
        updateResponse: reducers[modelName].Creators[`${methodName}UpdateResponse`],
        types: {
          clear: `STORE_${modelNameUpper}_${actionNameUpper}_CLEAR`,
          request: `STORE_${modelNameUpper}_${actionNameUpper}_REQUEST`,
          success: `STORE_${modelNameUpper}_${actionNameUpper}_SUCCESS`,
          failure: `STORE_${modelNameUpper}_${actionNameUpper}_FAILURE`,
          updateResponse: `STORE_${modelNameUpper}_${actionNameUpper}_UPDATE_RESPONSE`,
        },
        selector: (state) => state[`store_${modelName}`][methodName]
      }
    }
  })
})

export default store
