import { put, call, all } from 'redux-saga/effects'
import api from 'app/services/api/api'

import { getSEOFooterResourse } from 'app/sagas/SrpSagas'

import { VipActionCreators } from 'app/reducers/VipRedux'

function* getSEOFooterResourseFromProperty({
  propertyType,
  operationType,
  state
}) {
  const formatedState = state && state.toLowerCase()

  const footerParams = {
    property_type: propertyType,
    operation_type: operationType,
    location: { state: formatedState }
  }

  yield getSEOFooterResourse({
    typeOfResourse: ['footerUrls'],
    filters: footerParams,
  })
}

function* getVipUrlsAndSuggestProperties(property) {
  const {
    id,
    state,
    district,
    neighborhood,
    operationType,
    propertyType,
    price = {}
  } = property

  const basePayload = {
    state,
    district,
    neighborhood,
    property_id: id,
    property_type: propertyType,
    operation_type: operationType
  }

  const { vipResponse, suggestResponse } = yield all({
    vipResponse: call(api.get, '/v1/public/landing_page/vip_urls/', {
      ...basePayload,
      limit: 5
    }),
    suggestResponse: yield call(api.get, '/v1/public/properties/suggest/', {
      ...basePayload,
      price_amount: price.amount,
      price_currency: price.currency
    })
  })

  if (vipResponse.status === 200) {
    yield put(
      VipActionCreators.updateAsideLinksProperties(
        vipResponse.data.vipUrls || []
      )
    )
  }

  if (suggestResponse.status === 200) {
    yield put(
      VipActionCreators.updateSuggestedProperties(
        suggestResponse.data.results || []
      )
    )
  }
}

export function* getVipSeoInfoByPropertyId({ propertyId }) {
  const response = yield call(api.get, `/v1/public/properties/${propertyId}/`)

  if (response.status === 200) {
    const property = response.data

    const { state, district } = property

    if (state && district) {
      yield getSEOFooterResourseFromProperty(property)

      yield getVipUrlsAndSuggestProperties(property)
    }

    yield put(VipActionCreators.getVipSeoInfoByPropertyIdSuccess(response.data))

    return
  }

  yield put(VipActionCreators.getVipSeoInfoByPropertyIdFailure(response.data))
}
