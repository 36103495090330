import config from 'config'

const COMPANY_NAME = config('COMPANY_NAME')



const NEXIMO_CONFIG = {
  name: 'Neximo',
  lowerCaseName: 'neximo',
  upperCaseName: COMPANY_NAME,
  companyUrl: 'neximo.mx',
  company: 'neximo.mx',
  companyDomain: 'neximo.mx',
  teamDomain: 'neximo.mx',
  linkedinUrl: "https://www.linkedin.com/company/neximo-inmobiliaria-digital",
  youtubeUrl: "https://www.youtube.com/channel/UCGwPyDPheL-T79Vu7lNFYxA?view_as=subscriber",
  instagramUrl: "https://www.instagram.com/neximo_mx/",
}

const IAD_CONFIG = {
  name: 'iad México',
  lowerCaseName: 'iad',
  upperCaseName: COMPANY_NAME,
  companyUrl: 'iadmexico.mx',
  company: 'iadmexico.mx',
  companyDomain: 'iadmexico.mx',
  teamDomain: 'iadgroup.mx',
  linkedinUrl: "https://es.linkedin.com/company/iadmexico-inmobiliaria-digital",
  youtubeUrl: "https://www.youtube.com/channel/UCGwPyDPheL-T79Vu7lNFYxA?view_as=subscriber",
  instagramUrl: "https://www.instagram.com/iad_mexico/",
}

const COMPANY_CONFIG = COMPANY_NAME === 'NEXIMO' ? NEXIMO_CONFIG : IAD_CONFIG

module.exports = COMPANY_CONFIG
