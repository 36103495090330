import ApiBase from 'app/services/api/entities/Base'

const BASE_URL = '/v1/public/public_property_ads/'

function* all(params = {}) {
  return yield ApiBase.privateGet(BASE_URL, {
    params
  })
}

export default {
  all,
}
