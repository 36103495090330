/**
 * Created by neto on 2/21/17.
 */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles, { container } from './styles'

const RadioButton = ({
  id,
  value,
  label,
  checked,
  type="checkbox",
  radioButtonSize,
  disabled,
  className,
  children,
  ...rest
}) => (
  <label className={classnames("container", className, { disabled }, radioButtonSize)} htmlFor={id}>
    {label}
    <input
      value={value}
      type={type}
      disabled={disabled}
      {...rest}
      id={id}
      checked={checked}
    />
    <span className="checkmark" />
    <style jsx>{styles}</style>
  </label>
)

RadioButton.propTypes ={
  radioButtonSize: PropTypes.oneOf(['small', 'normal']) 
}

class RadioButtonsForm extends PureComponent {
  render() {
    const { className, meta, input, options, label, orientation='vertical', ...rest } = this.props
    const hasError = meta.invalid && meta.touched
    const radios = options.map((o, i) => {
      return (
        <RadioButton
          {...input}
          {...rest}
          key={i}
          id={i.id}
          value={o.value}
          type="radio"
          label={o.label}
          checked={input.value === o.value}
        />
      )
    })
    return (
      <div className={classnames('container', orientation, className)}>
        <p className='placeholder'>{label}</p>
        {radios}
        {hasError && <p className='error'>* {meta.error}</p>}
        <style jsx>{container}</style>
      </div>
    )
  }
}

class CheckBoxButtonsForm extends PureComponent {
  render() {
    const { fields, meta, input, options, ...rest } = this.props
    const valueWithIndex = fields.map((v, i) => ({ v: fields.get(i), i }))
    return options.map((o, i) => {
      const checked = valueWithIndex.find(vi => vi.v === o.value)
      return (
        <RadioButton
          {...input}
          {...rest}
          key={i}
          id={i.id}
          value={o.value}
          type="checkbox"
          label={o.label}
          checked={!!checked}
          onChange={() => {
            if (checked) {
              fields.remove(checked.i)
            } else {
              fields.push(o.value)
            }
          }}
        />
      )
    })
  }
}

export const RadioButtonForm = field => {
  return <RadioButton {...field.input} {...field} />
}

export { RadioButton as default, RadioButton, RadioButtonsForm, CheckBoxButtonsForm }
