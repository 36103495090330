import { colors, fonts } from 'theme'
import css from 'styled-jsx/css'

export default css`
.react-code-input input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
 
.react-code-input input[type="number"] {
    -moz-appearance: textfield;
}
`
