import { removeDiacritics } from 'services/string'
import utils from 'services/utils'
import config from 'config'
import { PARAMS_VALUES_LABELS } from 'components/Bin/Alerts/config'
import { getEnabledOptions } from 'globalConfigApp'
import { normalizeAsUrl, titleize, slugify, capitalizeAndLowerCase } from 'services/string'
import {
  amenitiesLabels,
  amenitiesToValues,
  buildAmenitiesDescription,
  buildURLAmenities,
  getAmenityPath,
  parseURLAmenities,
  SPECIAL_AMENITIES_FIELDS,
} from 'services/seo/amenities'

export const POSTAL_CODE_SIZE = 5 // Like 03000

export const LAND_TYPES = [
  {
    value: 'COMMERCIAL', label: 'Comercial',
    enabledCountries: ['es_MX']
  },
  {
    value: 'RESIDENTIAL', label: 'Residencial',
    enabledCountries: ['es_MX']
  },
  {
    value: 'INDUSTRIAL', label: 'Industrial',
    enabledCountries: ['es_MX']
  },
]

export const LAND_TYPES_BIBLIO = LAND_TYPES.reduce(
  (acc, { value, label }) => ({ ...acc, [value]: label }),
  {}
)

export const OPERATION_TYPES = [
  {
    url: 'en-venta',
    label: 'Venta',
    value: 'SELL'
  },
  {
    url: 'en-renta',
    label: 'Renta',
    value: 'RENT'
  }
]

export const OPERATION_TYPES_BIBLIO = OPERATION_TYPES.reduce(
  (acc, { value, label }) => ({ ...acc, [value]: label }),
  {}
)

export const OPERATION_TYPE_LABELS = {
  SELL: 'Venta',
  RENT: 'Renta',
  SHARE: 'Compartir'
}

export const PROPERTY_STATUS = [
  {
    label: 'En Proceso de Auditoria',
    value: 'PENDING_FOR_DELETE'
  },
  {
    label: 'Pausada',
    value: 'PAUSED'
  },
  {
    label: 'Borrador',
    value: 'DRAFT'
  },
  {
    label: 'En revisión',
    value: 'PENDING_FOR_APPROVAL'
  },
  {
    label: 'Creada',
    value: 'CREATED'
  },
  {
    label: 'Rechazada',
    value: 'USER_REVIEW_NEEDED'
  },
  {
    label: 'Aprobada',
    value: 'APPROVED'
  },
  {
    label: 'Publicada',
    value: 'PUBLISHED'
  },
  {
    label: 'En proceso',
    value: 'IN_PROCESS'
  },
  {
    label: 'Vendida',
    value: 'SOLD'
  },
  {
    label: 'Rentada',
    value: 'LEASED'
  },
  {
    label: 'En proceso de cierre',
    value: 'CLAIMED'
  },
  {
    label: 'Archivada',
    value: 'ARCHIVED'
  }
]

export const CURRENCY_TYPES = [
  {
    url: 'pesos',
    label: 'MXN',
    value: 'MXN',
    enabledCountries: ['es_MX']
  },
  {
    url: 'dolares',
    label: 'USD',
    value: 'USD',
    enabledCountries: ['es_MX']
  }
]

export const SALE_TYPES = [
  {
    label: 'Exclusiva',
    value: 'EXCLUSIVE'
  },
  {
    label: 'En Opción',
    value: 'OPEN'
  }
]

export const PROPERTY_TYPES = [
  {
    url: 'departamentos',
    label: 'Departamento',
    pluralLabel: 'Departamentos',
    value: 'APARTMENT',
    gender: 'M',
  },
  {
    url: 'casas',
    label: 'Casa',
    pluralLabel: 'Casas',
    value: 'HOUSE',
    gender: 'F',
  },
  {
    url: 'terreno',
    label: 'Terreno',
    pluralLabel: 'Terrenos',
    value: 'LOTS',
    gender: 'M',
  },
  {
    url: 'cuarto',
    label: 'Cuarto',
    pluralLabel: 'Cuartos',
    value: 'ROOMS',
    gender: 'M',
  },
  {
    url: 'local',
    label: 'Local',
    pluralLabel: 'Locales',
    value: 'STORE',
    gender: 'M',
  },
  {
    url: 'bodega',
    label: 'Bodega',
    pluralLabel: 'Bodegas',
    value: 'WAREHOUSE',
    gender: 'F',
  },
  {
    url: 'oficina',
    label: 'Oficina',
    pluralLabel: 'Oficinas',
    value: 'OFFICE',
    gender: 'F',
  },
  {
    url: 'edificio',
    label: 'Edificio',
    pluralLabel: 'Edificios',
    value: 'BUILDING',
    gender: 'M',
  },
  {
    url: 'hotel',
    label: 'Hotel',
    pluralLabel: 'Hoteles',
    value: 'HOTEL',
    gender: 'M',
  },
  {
    url: 'rancho',
    label: 'Rancho',
    pluralLabel: 'Ranchos',
    value: 'RANCH',
    gender: 'M',
  },
]

export const PROPERTY_SUB_CATEGORY = getEnabledOptions([
  {
    url: 'piso',
    label: 'Piso',
    value: 'HOUSE',
    enabledCountries: ['es']
  },
  {
    url: 'apartamento',
    label: 'Apartamento',
    value: 'APARTAMENT',
    enabledCountries: ['es']
  },
  {
    url: 'atico',
    label: 'Atico',
    value: 'ATTIC',
    enabledCountries: ['es']
  },
  {
    url: 'duplex',
    label: 'Duplex',
    value: 'DUPLEX',
    enabledCountries: ['es']
  },
  {
    url: 'estudio',
    label: 'Estudio',
    value: 'STUDY',
    enabledCountries: ['es']
  },
  {
    url: 'loft',
    label: 'Loft',
    value: 'LOFT',
    enabledCountries: ['es']
  },
  {
    url: 'chalet',
    label: 'Chalet',
    value: 'CHALET',
    enabledCountries: ['es']
  },
  {
    url: 'planta-baja',
    label: 'Planta Baja',
    value: 'GROUND_FLOOR',
    enabledCountries: ['es']
  },
  {
    url: 'finca-rustica',
    label: 'Finca Rústica',
    value: 'RUSTIC_STATE',
    enabledCountries: ['es']
  },
  {
    url: 'casa-abandonada',
    label: 'Casa Adosada',
    value: 'TERRACED_HOUSE',
    enabledCountries: ['es']
  },
])


export const SOIL_TYPE = getEnabledOptions([
  {
    url: 'terreno-residencial',
    label: 'Terreno Residencial',
    value: 'RESIDENTIAL_LOTS',
    enabledCountries: ['es']
  },
  {
    url: 'terreno-residencial',
    label: 'Terreno urbanizable',
    value: 'RESIDENTIAL_LOTS',
    enabledCountries: ['es']
  },
  {
    url: 'terreno-industrial',
    label: 'Terreno Industrial',
    value: 'INDUSTRIAL_LOTS',
    enabledCountries: ['es']
  },
  {
    url: 'terreno-residencial',
    label: 'Terreno no urbanizable',
    value: 'UNDEVELOPED_LOTS',
    enabledCountries: ['es']
  },
  {
    url: 'terreno-residencial',
    label: 'Terreno',
    value: 'LOTS',
    enabledCountries: ['es']
  }
])


export const CONDITION_TYPE = getEnabledOptions([
  {
    label: 'Necesita reforma total',
    value: 'TOTAL_RENEWED',
    enabledCountries: ['es']
  },
  {
    label: 'Necesita reforma parcial',
    value: 'PARTIAL_RENEWED',
    enabledCountries: ['es']
  },
  {
    label: 'Buen estado',
    value: 'GOOD_CONDICION',
    enabledCountries: ['es']
  },
  {
    label: 'A estrenar',
    value: 'NEW',
    enabledCountries: ['es']
  },

])


export const PROPERTY_TYPE_LABELS = {
  APARTMENT: 'Departamento',
  HOUSE: 'Casa',
  LOTS: 'Terreno',
  ROOMS: 'Cuarto',
  STORE: 'Local',
  WAREHOUSE: 'Bodega',
  OFFICE: 'Oficina',
  BUILDING: 'Edificio',
  HOTEL: 'Hotel',
  RANCH: 'Rancho'
}

export const SCALE_ENERGY = getEnabledOptions([
  {
    label: 'A',
    value: 'A',
    enabledCountries: ['es']
  },
  {
    label: 'B',
    value: 'B',
    enabledCountries: ['es']
  },
  {
    label: 'C',
    value: 'C',
    enabledCountries: ['es']
  },
  {
    label: 'D',
    value: 'D',
    enabledCountries: ['es']
  },
  {
    label: 'E',
    value: 'E',
    enabledCountries: ['es']
  },
  {
    label: 'F',
    value: 'F',
    enabledCountries: ['es']
  },
  {
    label: 'G',
    value: 'G',
    enabledCountries: ['es']
  },
])

export const PROPERTY_TYPES_BIBLIO = PROPERTY_TYPES.reduce(
  (acc, { label, value }) => ({ ...acc, [value]: label }),
  {}
)

export const isValidPropertyType = propertyType =>
  Boolean(PROPERTY_TYPES_BIBLIO[propertyType])

export const isValidOperationType = operationType =>
  Boolean(OPERATION_TYPES_BIBLIO[operationType])

export const srpUrlOnlyHasPropertyTypeParam = srpParams => {
  const { propertyType, operationType, location } = srpParams

  return !operationType && !location && isValidPropertyType(propertyType)
}

export const srpUrlOnlyHasOperationTypeParam = srpParams => {
  const { propertyType, operationType, location } = srpParams

  return !propertyType && !location && isValidOperationType(operationType)
}

export const srpUrlOnlyHasOTandPT = srpParams => {
  const { propertyType, operationType, location } = srpParams

  return (
    !location &&
    isValidOperationType(operationType) &&
    isValidPropertyType(propertyType)
  )
}

export const SEMAPHORE = [
  {
    label: 'Apta Publicación - Verificada',
    status: 'READY_FOR_PUBLICATION_AND_VERIFIED',
    statusDoc: ['APPROVED_DOCUMENTATION']
  },
  {
    label: 'Apta Publicación',
    status: 'READY_FOR_PUBLICATION',
    statusDoc: ['REGULARIZE_DOCUMENTATION', 'PENDING_SIGNATURE', ]
  },
  {
    label: 'No Apta Publicación',
    status: 'NOT_READY_FOR_PUBLICATION',
    statusDoc: ['REJECTED_DOCUMENTATION', 'PENDING_DOCUMENTATION']
  },
  {
    label: 'Apta Publicación 15 días',
    status: 'READY_FOR_PUBLICATION_15_DAYS',
    statusDoc: ['PENDING_DOCUMENTATION']
  },
]

export const CALL_TRACKING_TYPE = [
  { value: 'Missed Call', label: 'Llamada Perdida' },
  { value: 'Answered', label: 'Contestada' },
  { value: 'Voicemail', label: 'Buzón de voz' },
  { value: 'Blacklisted Number', label: 'Número en lista negra' },
  { value: 'Missed Call Network Failure', label: 'Falla en la red celular' },
  { value: 'Busy', label: 'Ocupado' }
]

export const CALL_TRACKING_TYPE_BIBLIO = CALL_TRACKING_TYPE.reduce(
  (acc, { value, label }) => ({
    ...acc,
    [value]: label
  }),
  {}
)

export const PROFILES_TYPE = [
  { value: 'INTERESTED_IN_BUYING', label: 'Interesado en comprar' },
  { value: 'INTERESTED_IN_RENTING', label: 'Interesado en rentar' },
  { value: 'INTERESTED_IN_SELLING', label: 'Interesado en vender' },
  {
    value: 'INTERESTED_IN_PUT_FOR_RENT',
    label: 'Interesado en dar en renta'
  },
  { value: 'LANDLORD', label: 'Propietario' },
  { value: 'TENANT', label: 'Inquilino' },
  { value: 'BUYER', label: 'Comprador' },
  { value: 'BROKER', label: 'Asesor inmobiliario' },
  { value: 'INVESTOR', label: 'Inversionista' },
  { value: 'RECRUITMENT', label: 'Reclutamiento'},
  { value: 'REFERRED', label: 'Referido' },
  { value: 'CANDIDATE', label: 'Candidato' },
]

export const PROFILES_TYPE_BIBLIO = PROFILES_TYPE.reduce(
  (acc, { value, label }) => ({
    ...acc,
    [value]: label
  }),
  {}
)

export const DEALS_STAGES = [
  { value: 'CANCELLED', label: 'Cancelado' },
  { value: 'CLOSED', label: 'Cerrado' },
  { value: 'PENDING_OFFER', label: 'Carta Oferta enviada' },
  { value: 'PENDING_SIGNATURE', label: 'Pendiente de firma' },
  { value: 'DOCUMENTING', label: 'En documentación' }
]

export const MARITAL_STATUS = [
  { value: 'SINGLE', label: 'Soltero(a)' },
  { value: 'MARRIED', label: 'Casado(a)' },
  { value: 'DIVORCED', label: 'Divorciado(a)' },
  { value: 'WIDOWED', label: 'Viudo(a)' }
]

export const IDENTITY_DOCUMENT_TYPE = [
  {
    value: "NATIONAL_ID",
    label: "Credencial para votar vigente (INE)",
  },
  {
    value: "PASSPORT",
    label: "Pasaporte vigente",
  },
  {
    value: "PROFESSIONAL_LICENSE",
    label: "Cédula profesional vigente con fotografía (exceptuando cédulas electrónicas)",
  },
  {
    value: "SENIOR_CITIZEN_ID",
    label: "Credencial del Instituto Nacional de las Personas Adultas Mayores vigente",
  },
  {
    value: "MINOR_ID",
    label: "Credencial de Instituciones de Educación Pública o Privada con fotografía y firma, o Cédula de Identidad Personal del Registro Nacional de Población",
  },
  {
    value: "FOREIGN_RESIDENT_ID",
    label: "Documento migratorio vigente emitido por autoridad competente",
  }
]

export const TAX_REGIMES = [
  {
    value: "NATURAL_PERSON", 
    label: "Persona física"
  },
  {
    value: "LEGAL_ENTITY", 
    label: "Persona moral"
  },
]

export const SOURCES = [
  { value: 'MASSIVE_EVENTS', label: 'Eventos Masivos' },
  { value: 'PO2N', label: 'PO2N' },
  { value: 'MASTERCLASS', label: 'Masterclass' },
  { value: 'MARKETING_CAMPAIGNS', label: 'Campañas de Marketing' },
  { value: 'MAILING', label: 'Mailing' },
  { value: 'CALLCENTER', label: 'Llamada Call Center' },
  { value: 'CEO_REFERRED', label: 'Referido CEO' },
  { value: 'NETWORK_REFERRED', label: 'Referido Red' },
  { value: 'HOT_PROSPECT_LIST', label: 'Lista Cálida' },
  { value: 'INVITED', label: 'Invitado' },
  {
    value: 'INTERNATIONAL_SPONSORING_IAD',
    label: 'Apadrinamiento Intl. Grupo IAD',
  },
]

export const MARITAL_STATUS_BIBLIO = MARITAL_STATUS.reduce(
  (acc, { value, label }) => ({
    ...acc,
    [value]: label
  }),
  {}
)

export const BROKER_PROFILE = [
  { value: 'INDEPENDENT_BROKER', label: 'Independiente' },
  { value: 'AGENCY', label: 'Inmobiliaria' }
]

export const BROKER_PROFILE_BIBLIO = BROKER_PROFILE.reduce(
  (acc, { value, label }) => ({
    ...acc,
    [value]: label
  }),
  {}
)

export const EVENT_TYPE_BIN = [
  { value: 'phone', label: 'Llamada', action: 'Realizar' },
  { value: 'email', label: 'Correo', action: 'Mandar' },
  { value: 'key', label: 'Cita', action: 'Crear' }
]


export const Contracts = {
  INTERMEDIATION: 'INTERMEDIATION',
  COLLABORATION: 'COLLABORATION'
}


export const REQUEST_CONTRACTS_TYPES = [
  { label: 'Intermediación', value: Contracts.INTERMEDIATION },
  { label: 'Colaboración', value: Contracts.COLLABORATION }
]

export const OCUPATION_STATUS = [
  { label: 'Rentada', value: 'RENT', labelColor: 'dangerLow', alertColor: 'danger' },
  { label: 'Vendida', value: 'SELL', labelColor: 'dangerLow', alertColor: 'danger' },
]

export const STATUS_LABEL = [
  { label: 'En proceso de Cierre', value: 'CLAIMED', labelColor: 'purple', alertColor: 'purple' }
]

export const ALL_SEO = {
  LAND_TYPES: LAND_TYPES,
  OPERATION_TYPES: OPERATION_TYPES,
  PROPERTY_STATUS: PROPERTY_STATUS,
  CURRENCY_TYPES: CURRENCY_TYPES,
  SALE_TYPES: SALE_TYPES,
  PROPERTY_TYPES: PROPERTY_TYPES,
  CALL_TRACKING_TYPE: CALL_TRACKING_TYPE,
  EVENT_TYPE_BIN: EVENT_TYPE_BIN
}

export const LOCATION_TYPES = {
  state: 'Estado',
  municipality: 'Ciudad',
  neighborhood: 'Colonia'
}

const NULL_VALUE = {
  url: 'N/A',
  label: 'N/A',
  value: 'N/A'
}

export const ACCESS_DEVICE_OPTION = [
  { label: 'correo', value: 'email' },
  { label: 'teléfono', value: 'telephone' }
]

export const AGE_PROPERTY = Array(100).fill(0).map((a, index) => {
  const label = index === 0 ? 'Nuevo' : (index === 1 ? `${index} Año` : `${index} Años`)
  return {
    value: index,
    label
  }
})

export const SALE_COMMISSION_DAY_OPTIONS = [10, 15, 30, 45, 60, 75, 90]

export const getPropertySEODescription = (propertyData = {}, config) => {
  const {
    withAmenities = false,
    maxAmenities = 10,
    withLandType = false,
  } = config || {}
  const {
    propertyType,
    operationType,
    address,
    bathrooms,
    bedrooms,
    constructionArea,
    halfBathrooms,
    amenities,
    surfaceArea,
    parkingSpaces,
    landType,
    developmentType,
    presaleDate,
  } = propertyData

  const getPropertyLabels = filters => Object.keys(filters).map(key => {
    const keyToLabel = PARAMS_VALUES_LABELS[key]
    const label = keyToLabel && filters[key] ? keyToLabel(filters[key], propertyData) : null
    return label ? { [key]: label } : label
  })
    .filter(Boolean)
    .reduce((acc, value) => ({ ...acc, ...value }), {})

  const labels = getPropertyLabels({
    bathrooms,
    bedrooms,
    surfaceArea,
    parkingSpaces,
    constructionArea,
    halfBathrooms,
    presaleDate,
    developmentType,
    landType: withLandType && landType,
  })

  let description = `${PROPERTY_TYPES_BIBLIO[propertyType]} en ${OPERATION_TYPES_BIBLIO[operationType]}`
  if (labels.developmentType) {
    description += ` ${labels.developmentType}`
  }
  if (labels.bedrooms) {
    description += `, con ${labels.bedrooms}`
  }
  if (labels.bathrooms) {
    description += `, con ${labels.bathrooms}`
  }
  if (labels.halfBathrooms) {
    description += `, con ${labels.halfBathrooms}`
  }
  if (labels.constructionArea) {
    description += `, con ${labels.constructionArea}`
  }
  if (labels.landType) {
    description += `, con ${labels.landType}`
  }
  if (withAmenities && amenities && amenities.length) {
    const amenitiesLabel = [...amenities].slice(0, maxAmenities).map(({ name }) => name)
    description += `, ${buildAmenitiesDescription(amenitiesLabel)}`
  }
  if (address) {
    description += ` en ${address}`
  }

  description += '.'

  return description
}

export const findSEO = (type, findFunc) =>
  ALL_SEO[type].find(findFunc) || NULL_VALUE

export const getLocationParam = location => {
  if (!location) {
    return null
  }
  const neighborhood = removeDiacritics(location.neighborhood).replace(
    / /gi,
    '-'
  )
  const state = removeDiacritics(location.state).replace(/ /gi, '-')
  const district = removeDiacritics(location.district).replace(/ /gi, '-')
  const country = removeDiacritics(location.country).replace(/ /gi, '-')
  if (location.neighborhood) {
    return `${neighborhood}--${state}`
  }
  if (location.district) {
    return `${district}--${country}`
  }
  return `${state}--${country}`
}

export const getFiltersFromUrl = url => {
  if (url.param1 || url.param2 || url.param3 || url.param4) {
    return getFiltersFromParams({
      ...url,
      param1: url.param1?.toLowerCase(),
      param2: url.param2?.toLowerCase(),
      param3: url.param3?.toLowerCase(),
      param4: url.param4?.toLowerCase(),
    })
  } else {
    return url
  }
}

const OPERATION_TYPES_URL = {
  'en-renta': 'RENT',
  'en-venta': 'SELL',
  'compartir': 'SHARE',
}

const PROPERTY_TYPES_URL = {
  departamentos: 'APARTMENT',
  casas: 'HOUSE',
  terreno: 'LOTS',
  cuarto: 'ROOMS',
  local: 'STORE',
  bodega: 'WAREHOUSE',
  oficina: 'OFFICE',
  edificio: 'BUILDING',
  hotel: 'HOTEL',
  rancho: 'RANCH'
}

const paramToOperationType = param =>  OPERATION_TYPES_URL[param]
const paramToPropertyType = param => PROPERTY_TYPES_URL[param]
const paramToAmenities = param => {
  const amenityPath = getAmenityPath(param)

  if (amenityPath) {
    return parseURLAmenities(amenityPath)
  }

  return null
}

export const getFiltersFromParams = ({ param1, param2, param3, param4, ...rest }) => {
  let filters = {
    operationType: '',
    location: '',
    propertyType: '',
    ...rest
  }

  const paramsFounded = {
    propertyType: false,
    operationType: false,
    location: false,
    amenities: false,
  }

  let params = [param1, param2, param3, param4]

  function paramToValue(param) {
    if (!paramsFounded.propertyType) {
      const propertyType = paramToPropertyType(param)

      if (propertyType) {
        filters.propertyType = propertyType
        paramsFounded.propertyType = true
        params = params.filter(p => p !== param)
      }
    }

    if (!paramsFounded.operationType) {
      const operationType = paramToOperationType(param)

      if (operationType) {
        filters.operationType = operationType
        paramsFounded.operationType = true
        params = params.filter(p => p !== param)
      }
    }

    if (!paramsFounded.amenities) {
      const amenities = paramToAmenities(param)

      if (amenities) {
        filters = {
          ...filters,
          ...amenities
        }
        paramsFounded.amenities = true
        params = params.filter(p => p !== param)
      }
    }
  }

  // Iterate over all params and remove the equivalent founded 
  params.forEach(paramToValue)

  const [location] = params

  if (location) {
    filters.location = location
  }

  return filters
}

/**
 * srpUrl Sanitized SRP url
 * @param {String} param.propertyType - Like HOUSE
 * @param {String} param.operationType - Like SELL
 * @param {String} param.location - Like abala--yucatan or Abalá--Yúcatan
 * @returns {String} /casas/en-venta/abala--yucatan
 */
export const srpUrl = ({ propertyType, operationType, location, ...rest }) => {
  const amenities = buildURLAmenities(rest)

  if (!propertyType && !operationType && !amenities && !location) {
    /**
     * This is the default url for search
     * we may want to put feature-properties or something else
     */
    return '/'
  }
  const propertyTypeUrl = PROPERTY_TYPES.find(pt => pt.value === propertyType)
  const operationTypeUrl = OPERATION_TYPES.find(
    ot => ot.value === operationType
  )
  let url = '/'

  if (operationTypeUrl) {
    url = `/${operationTypeUrl.url}${url}`
  }
  if (propertyTypeUrl) {
    url = `/${propertyTypeUrl.url}${url}`
  }

  if (location) {
    url = `${url}${location}/`
  }

  if (amenities) {
    url = `${url}${amenities}/`
  }

  return normalizeAsUrl(url.substring(0, url.length - 1))
}

/**
 * @function removeUnnusedSRPParams
 * @description
 *    Remove filters that you don't want to show at URL as param
 *    Example: operationType="RENT" becomes in '/en-renta' and not in '/?operationType=RENT',
 *    so you sholdn't include as param
 * @param {Object} srpParams
 * @returns {Object} 
 */
export const removeUnnusedSRPParams = (srpParams) => {
  const {
    limit,
    offset,
    page,
    operationType,
    propertyType,
    location,
    currency,

    ...restParams
  } = srpParams

  return Object.keys(restParams)
    .filter(param => !SPECIAL_AMENITIES_FIELDS.includes(param))
    .reduce((acc, paramName) => ({ ...acc, [paramName]: srpParams[paramName] }), {})
}

export const externalBrokerPathUrl = (externalBrokerName) => {
  return slugify(`compartido-por-asesor-${externalBrokerName}`)
}

export const vipPathUrl = (
  propertyType = '',
  operationType = '',
  title = '',
  id,
  externalBrokerName = null
) => {
  const sanitizedTitle = title
    ? slugify(title)
    : 'title'

  let typePropertyUrl = ''

  if (!propertyType || !propertyType) {
    typePropertyUrl = '/operation/operation/'
  } else {
    typePropertyUrl = srpUrl({ propertyType, operationType })
  }
  let urlParts = []
  if (externalBrokerName) {
    urlParts = [externalBrokerPathUrl(externalBrokerName), typePropertyUrl, sanitizedTitle, id]  
  } else {
    urlParts = [typePropertyUrl, sanitizedTitle, id]
  }

  const vipPath = urlParts
    .map(part => ((part && part.toString()) || '').replace(/^\/*|\/*$/g, ''))
    .join('/')
  return `/${vipPath}`
}

export const vipUrl = (
  propertyType = '',
  operationType = '',
  title = '',
  id
) =>
  config('HOST_URL') + vipPathUrl(propertyType, operationType, title, id)


const titleizeSRPTitle = srpTitle =>
  titleize(
    srpTitle
      .split(' ')
      .map(t => t.replace(/-/gi, ' '))
      .join(' ')
  )

/**
 * getTitleToSrpLink
 * @param {Object} params 
 * @param {String} params.propertyType
 * @param {String} params.operationType
 * @param {String} params.location - like interlomas--huixquilucan--mexico
 * @returns {String}
 */
export const getTitleToSrpLink = ({ propertyType, operationType, location }) => {
  let title = operationsSrpTitle({ propertyType, operationType });

  if (location) {
    title += locationSrpTitle({ location })
  }


  return title
}

const MAX_SRP_METATITLE_SIZE = 150;

/**
 * @function metaTitleSRP
 * @param {Object} params
 * @param {Number} params.maxTitleSize
 * @param {String} params.propertyType
 * @param {String} params.operationType
 * @param {String} params.location - like interlomas--huixquilucan--mexico
 * @returns {String}
 */
export const metaTitleSRP = ({
  maxTitleSize = MAX_SRP_METATITLE_SIZE,
  propertyType,
  operationType,
  location,
  ...amenities
}) => {
  let operationsTitle = operationsSrpTitle({ propertyType, operationType });
  let locationTitle = location ? locationSrpTitle({ location }) : "";
  let labels = amenitiesLabels(amenities);

  // Calculate if title is bigger than maxTitleSize
  while (
    String(operationsTitle + locationTitle + amenitiesSRPTitle(labels)).length >
    maxTitleSize
  ) {
    labels.pop()
  }

  return operationsTitle + amenitiesSRPTitle(labels) + locationTitle
}

const removeLastConnector = title =>
  title.endsWith(', con ') ? title.substring(0, title.length - 6) : title

/**
 * @function amenitiesSRPTitle
 * @param {String[]} labels - ['Gimnasio', 'Intercomunicador']
 * @returns {String}
 */
export const amenitiesSRPTitle = labels => {
  if (labels.length === 0) {
    return ''
  }

  let title = ' con '

  for(const label of labels) {
    if (label.includes('pesos') || label.includes('dolares')) {
      title = removeLastConnector(title)
      title += `, ${label}, con `
    } else {
      title += `${label}, con `
    }
  }

  return removeLastConnector(title).toLocaleLowerCase()
}

export const operationsSrpTitle = ({ operationType, propertyType }) => {
  const propertyTypeUrl = PROPERTY_TYPES.find(pt => pt.value === propertyType)
  const operationTypeUrl = OPERATION_TYPES.find(ot => ot.value === operationType)

  let title = "";

  if (propertyTypeUrl) {
    title += propertyTypeUrl.pluralLabel || propertyTypeUrl.label
  } else {
    title += "Inmuebles"
  }
  if (operationTypeUrl) {
    title += ` en ${operationTypeUrl.label}`
  }

  return capitalizeAndLowerCase(titleizeSRPTitle(title))
}

export const locationTitle = ({ state, municipality, district, neighborhood }) => {
  const mediumLevelLocation = municipality || district

  let title = "";

  if (state && mediumLevelLocation && neighborhood) {
    title += `${neighborhood}, ${mediumLevelLocation}, ${state}`
  } else if (state && mediumLevelLocation) {
    title += `${mediumLevelLocation}, ${state}`
  } else if (state) {
    title += `${state}`
  }

  return title
}

export const locationSrpTitle = ({ location = ""}) => {
  const { state, municipality, neighborhood } = utils.splitUrlByLocation(location)

  const title = titleizeSRPTitle(locationTitle({ state, municipality, neighborhood }))

  return ` en ${title}`
}

/**
 * footerLinkLabel
 * @param {Object} params
 * @param {String} params.propertyType - APARTMENT
 * @param {String} params.operationType - SELL
 * @param {String} params.locationLabel - Ciudad de México
 * @param {Number} params.level
 * @param {Number} params.levelsToShow
 * @returns {String}
 * @example "Departamento en Renta en Ciudad de México"
 */
export const footerLinkLabel = ({
  level,
  levelsToShow,
  propertyType,
  operationType,
  locationLabel
}) => {
  if (level > levelsToShow) {
    return locationLabel
  }

  const pTLabel = PROPERTY_TYPES_BIBLIO[propertyType]
  const oTLabel = OPERATION_TYPES_BIBLIO[operationType]

  let title = ''

  if (pTLabel) {
    title += `${pTLabel} en `
  }

  if (oTLabel) {
    title += `${oTLabel} en `
  }

  return `${title}${locationLabel}`
}

export const getLabelPropertyTypeOperation = (propertyType, operation) => {
  const propertyTypeVIP = PROPERTY_TYPES.find(pt => pt.value === propertyType)
  const operationVIP = OPERATION_TYPES.find(o => o.value === operation)

  const label = operationVIP.value === 'SHARE' ? 'para' : 'en'

  return `${propertyTypeVIP.label} ${label} ${operationVIP.label}`
}

export const PROPERTY_STATUS_AND_DELETE_REASONS= {
  APPROVED: [
    'REMOVE_SOLD_ME',
    'REMOVE_SOLD_NEXIMER',
    'REMOVE_SOLD_OTHER',
    'REMOVE_CLIENT_CANCEL_CONTRACT',
    'REMOVE_CLIENT_ANNULMENT',
    'REMOVE_SOLD_OTHER_WITHOUT_COMMISSION'
  ],
  PENDING_FOR_APPROVAL: [
    'REMOVE_SOLD_ME',
    'REMOVE_SOLD_NEXIMER',
    'REMOVE_SOLD_OTHER',
    'REMOVE_CLIENT_CANCEL_CONTRACT',
    'REMOVE_CLIENT_ANNULMENT',
    'REMOVE_SOLD_OTHER_WITHOUT_COMMISSION'
  ],
  DRAFT: [
    'REMOVE_SOLD_ME',
    'REMOVE_SOLD_NEXIMER',
    'REMOVE_SOLD_OTHER',
    'REMOVE_CLIENT_CANCEL_CONTRACT',
    'REMOVE_CLIENT_ANNULMENT',
    'REMOVE_ERROR_CAPTURE',
    'REMOVE_SOLD_OTHER_WITHOUT_COMMISSION'
  ],
  USER_REVIEW_NEEDED: [
    'REMOVE_SOLD_ME',
    'REMOVE_SOLD_NEXIMER',
    'REMOVE_SOLD_OTHER',
    'REMOVE_CLIENT_CANCEL_CONTRACT',
    'REMOVE_CLIENT_ANNULMENT',
    'REMOVE_SOLD_OTHER_WITHOUT_COMMISSION'
  ],
  PAUSED: [],
  ARCHIVED: [],
  CLAIMED: [],
  PENDING_FOR_DELETE: []
}

export const USER_STATUS = [
  { label: 'Creado', value: 'CREATED' },
  { label: 'Pendiente', value: 'PENDING' },  
  { label: 'Verificado', value: 'VERIFIED' },
  { label: 'Pendiente de aprobación', value: 'PENDING_VALIDATION' },
  { label: 'Rechazado', value: 'REJECTED' },
  { label: 'Activo', value: 'ACTIVE' },
  { label: 'Bloqueado', value: 'BLOCKED' },
  { label: 'En proceso', value: 'IS_PENDING' }
]

export const USER_STATUS_DICT = USER_STATUS.reduce(
  (acc, { label, value }) => ({ ...acc, [value]: label }),
  {}
)

/**
 *
 *
 * @param {*} filters
 * @returns Location Formater, Url by SEO
 */
export const getLocationUrl = (filters)=>{
  const paramsLocation = {}
  if (filters.selectedLocations && filters.selectedLocations.length > 0) {
    let loc = filters.selectedLocations

    const rep = loc => {
      let result = loc.split('::').filter(v => v)

      if (result.length === 4) {
        result = result.slice(1)
      }

      result = result.join('--').replace(/ /gi, '-')

      return normalizeAsUrl(result)
    }

    if (Array.isArray(loc)) {
      loc = rep(loc[0])
    } else {
      loc = rep(loc)
    }

    paramsLocation.location = loc
  }
  return paramsLocation
}

/**
 * buildLocationUrlFromLocationObject
 * To use with params or any property
 * @param {Object} LocationObject
 * @param {String} LocationObject.state // Like Ciudad de México
 * @param {String} LocationObject.municipality // Like Miguel Hidalgo
 * @param {String} LocationObject.district // Like Miguel Hidalgo
 * @param {String} LocationObject.neighborhood // Mariano Escobedo
 * @returns {String}
 */

export const buildLocationUrlFromLocationObject = ({ state, municipality, neighborhood, district }) => {
  const location = []

  if (neighborhood) {
    location.push(neighborhood)
  }
  if (municipality || district) {
    location.push(municipality || district)
  }
  if (state) {
    location.push(state)
  }

  return normalizeAsUrl(location.join('--').replace(/ /gi, '-'))
}

/**
 * seoTitleWithAddress
 * Create Seo title 
 * @param {Object} property - A Property object
 * @param {String} property.operationType
 * @param {String} property.propertyType
 * @param {String} property.address
 * @returns {String}
 * @example
 * "Casa en Venta en Condesa, Cuauhtémoc, Ciudad de México"
 */
export const seoTitleWithAddress = (property) => {
  const { propertyType, operationType } = property

  const operationTypeLabel = OPERATION_TYPES_BIBLIO[operationType]
  const propertyTypeLabel = PROPERTY_TYPES_BIBLIO[propertyType]

  const titleAddress = locationTitle(property)

  return `${propertyTypeLabel} en ${operationTypeLabel} en ${titleAddress}`
}

const isPlural = count => count !== 1;

const GENDER_CONNECTORS = {
  TYPE_1: {
    F: {
      plural: "las",
      singular: "la"
    },
    M: {
      plural: "los",
      singular: "el"
    },
  },
  TYPE_2: {
    F: {
      plural: "estas",
      singular: "esta"
    },
    M: {
      plural: "estos",
      singular: "este"
    },
  },
}

/**
 * genderCountConnector
 * @param {String} genderType - ["TYPE_1", "TYPE_2"] TODO: Improve names 
 * @param {String} gender - ["F", "M"] 
 * @param {Number} count
 * @returns {String} 
 */
export const genderCountConnector = (genderType, gender, count) => {
  const grammaticalNoun = isPlural(count) ? "plural" : "singular"

  const connector = GENDER_CONNECTORS[genderType][gender][grammaticalNoun]

  return connector;
}

/**
 * genderCountPropertyType
 * @param {String} genderType - ["TYPE_1", "TYPE_2"] TODO: Improve names 
 * @param {String} propertyType 
 * @param {Number} count
 * @return {String}
 */
export const genderCountPropertyType = (genderType, propertyType, count) => {
  const { gender } = PROPERTY_TYPES.find(
    ({ value }) => value === propertyType
  )

  return genderCountConnector(genderType, gender, count)
}

export const PRICE_AMOUNT_RANGES = [
  5000, 6000, 7000, 8000, 9000,                                    // 5_000 >= amount < 10_000
  10000, 12000, 14000, 16000, 18000,                               // 10_000 => amount < 20_000
  20000, 25000, 30000, 35000, 40000, 45000,                        // 20_000 => amount < 50_000
  50000, 60000, 70000, 80000, 90000,                               // 50_000 => amount < 100_000
  100000, 150000, 200000, 250000, 300000, 350000, 400000, 450000,  // 100_000 => amount < 500_000
  500000, 600000, 700000, 800000, 900000,                          // 500_000 => amount < 1_000_000
  1000000, 1200000, 1400000, 1600000, 1800000,                     // 1_000_000 => amount < 2_000_000
  2000000, 2500000, 3000000, 3500000, 4000000, 4500000, 5000000,   // 2_000_000 => amount < 5_000_000
  6000000, 7000000, 8000000, 9000000, 10000000,                    // 6_000_000 => amount < 10_000_000
  15000000, 20000000, 25000000, 30000000, 50000000,                // 15_000_000 => amount < 50_000_000
]

export const TRANSACTION_TYPE_LABEL = {
  "DEPOSIT": "Depósito",
  "WITHDRAWAL": "Cancelación",
  "AGENT_WITHDRAWAL": "Retiro",
  "SCHEDULED_DEPOSIT": "Depósito Programado"
}

export const DEVELOPMENT_TYPES_VALUES = {
  NONE: "NONE",
  PRESALE: "PRESALE",
  IMMEDIATE_DELIVERY: "IMMEDIATE_DELIVERY",
}

export const DEVELOPMENT_TYPE_OPTIONS = [
  {
    label: "No, Ninguno",
    value: DEVELOPMENT_TYPES_VALUES.NONE,
  },
  {
    label: "Preventa - con fecha de entrega",
    value: DEVELOPMENT_TYPES_VALUES.PRESALE,
    showable: true
  },
  {
    label: "Entrega inmediata",
    value: DEVELOPMENT_TYPES_VALUES.IMMEDIATE_DELIVERY,
    showable: true
  },
]

/**
 * binTagForDevelopment
 * @description
 *    Returns the tag legend for the BIN for the development type if marked as showable 
 * @param {String} tag - type of development
 * @return {String} - tag text or undefined
 */
export const binTagForDevelopment = tag =>
  DEVELOPMENT_TYPE_OPTIONS.filter(options => options.showable).find(
    ot => ot.value === tag
  )?.label


export const getDeviceType = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

function _matchItem(string, data) {
  let i = 0,
    j = 0,
    regex,
    regexv,
    match,
    matches,
    version

  for (i = 0; i < data.length; i += 1) {
    regex = new RegExp(data[i].value, 'i')
    match = regex.test(string)
    if (match) {
      regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i')
      matches = string.match(regexv)
      version = ''
      if (matches) {
        if (matches[1]) {
          matches = matches[1]
        }
      }
      if (matches) {
        matches = matches.split(/[._]+/)
        for (j = 0; j < matches.length; j += 1) {
          if (j === 0) {
            version += matches[j] + '.'
          } else {
            version += matches[j]
          }
        }
      } else {
        version = '0'
      }
      return {
        name: data[i].name,
        version: parseFloat(version).toFixed(2),
      }
    }
  }
  return { name: 'unknown', version: 0 }
}

const deviceInfo = {
  os: [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' },
  ],
  browser: [
    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
    { name: 'Safari', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
    { name: 'Opera', value: 'Opera', version: 'Opera' },
    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
  ],
}

export function allDeviceInfo() {
  const header = [
    navigator.platform,
    navigator.userAgent,
    navigator.appVersion,
    navigator.vendor,
    window.opera,
  ]

  const agent = header.join(' ')
  const os = _matchItem(agent, deviceInfo.os)
  const browser = _matchItem(agent, deviceInfo.browser)

  /**
   * @example
   * return {
   *   "os": {
   *       "name": "Macintosh",
   *       "version": 10.15
   *   },
   *   "browser": {
   *       "name": "Chrome",
   *       "version": 100.04
   *   },
   *   "devicetype": "desktop",
   * }
   */
  return { os: os, browser: browser, devicetype: getDeviceType() }
}

export function getNeximoBroadcastChannel() {
  if (typeof BroadcastChannel !== 'undefined') {
    return new BroadcastChannel('neximo-broadcast-channel')
  }
}

export const ADVISOR_TYPES = {
  'INDEPENDENT_ADVISOR': 'Asesor independiente' ,
  'FRANCHISE_ADVISOR': 'Asesor de una franquicia',
  'REAL_STATE_OWNER_WITH_MORE_THAN_TWO_ADVISORS': 'Dueño de una inmobiliaria con más de 2 asesores'
}

export const ADVERTISING_SOURCES = {
  'CALL': 'Llamada',
  'PODCAST': 'Podcast',
  'MAIL': 'Mail',
  'FACEBOOK': 'Facebook (No campaña)',
  'FACEBOOK_CAMPAIGN': 'Facebook (campaña)'
}

export const DIRECT_ANNONCE_LEAD_ASSIGNATION_STATUES = {
  ACCEPTED: 'Lead Aceptado',
  REJECTED: 'Lead Rechazado',
  PENDING: 'Pendiente Aceptar/Rechazar'
}

export const DISABLED_CONTACT_PROFILES = [
  'INTERESTED_IN_SELLING',
  'INTERESTED_IN_PUT_FOR_RENT',
  'BUYER',
]
