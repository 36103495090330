import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import config from 'config'

import { useRouter } from 'next/router'
import { wrapper } from 'app/store'
import { useLoadingPage } from 'app/hooks'
import { createClient, Provider as GrapqlProvider } from 'urql'
import { getNeximoBroadcastChannel } from 'services/seo'

import 'moment/locale/es'

const apiBaseUrl = config('API_BASE_URL')

const client = createClient({
  url: `${apiBaseUrl}/graphql/`,
});

function useBroadcastChannel() {
  const dispatch = useDispatch()

  const isBrowser = typeof window !== 'undefined'

  useEffect(() => {
    const bc = getNeximoBroadcastChannel()
    if (isBrowser && bc) {
      console.log("REGISTERING neximo-broadcast-channel BroadcastChannel")
      bc.onmessage = event => {
        // Event is a redux action
        if (event.data.type) {
          console.log("ACTION: ", event.data.type)
          dispatch(event.data)
        }
      }
    }
  }, [isBrowser])
}

const App = ({
  Component, pageProps, router
}) => {
  const { pageLoading, pageLoadingError } = useLoadingPage()
  const { asPath } = useRouter()
  useBroadcastChannel()

  return (
    <GrapqlProvider value={client}>
      <Component
        {...pageProps}
        url={router}
        asPath={asPath}
        pageLoading={pageLoading}
        pageLoadingError={pageLoadingError}
      />
    </GrapqlProvider>
  )
}

export default wrapper.withRedux(App)
