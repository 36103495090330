import crudAction from 'services/store/models/actions'
import models from 'services/store/models'
import api from 'services/api/api'
import { camelToSnake } from 'services/string'

const makeApi = model =>
  model.methods
    .filter(method => {
      return method.saga !== false
    })
    .map(method => {
      return {
        [method.name]: (token, actionPayload) => {
          const data = {}
          method.params.forEach(k => {
            if (!method.urlParams.includes(k)) {
              data[k] = actionPayload[k]
            }
          })
          let url = method.url({
            namespace: model.namespace,
            ...actionPayload
          })
          const dataName = method.method === 'Get' ? 'params' : 'data'
          let apiParams = { token, [dataName]: data }
          if (data.data) {
            apiParams[dataName] = data.data
          }
          if (data.customHeaders) {
            apiParams['headers'] = data.customHeaders
          }
          return api[`private${method.method}`](url, apiParams)
        }
      }
    })
    .reduce((acc, value) => ({ ...acc, ...value }))

const storeApi = Object.keys(models)
  .map(m => ({ [models[m].name]: makeApi(models[m]) }))
  .reduce((acc, value) => ({ ...acc, ...value }))

export default storeApi
