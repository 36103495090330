/**
 * Created by neto on 2/21/17.
 */

import React, { PureComponent } from 'react'
import classNames from 'classnames'

import Editor from 'components/Editor'
import styles from './styles'

class EditorForm extends PureComponent {
  render() {
    const {
      input: { onChange, value, onBlur },
      className,
      meta,
      ...rest
    } = this.props

    const invalid = meta.invalid && meta.touched

    return (
      <div
        style={{ width: '100%', ...this.props.style }}
        className={classNames(
          { invalid },
          className,
          'container',
          'editor-form-text'
        )}
      >
        <input type="hidden" name={this.props.input.name} />
        <Editor
          value={value}
          {...rest}
          onBlur={() => onBlur()}
          onChange={es => onChange(es)}
        />
        {invalid && <p className="message-error">{meta.error}</p>}
        <style jsx>{styles}</style>
      </div>
    )
  }
}

export { Editor as default, EditorForm }
