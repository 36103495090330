import { createReducer, createActions } from 'reduxsauce'
import { utils } from 'services'
// TODO:
// 1. abstract this to make it for all S3 file uploads
// 2. support several files and upload forms
// 3. support concurrent uploads
// 4. control upload form expiry per bucket + directory (avoid calling API for
//    an upload form if the one we have for the requested bucket is still valid)
// 5. add progress state

const INITIAL_CONTROL_STATE = {
  value: '',
  isNew: false,
  deleted: false,
  modified: false,
  format: {
    bold: false,
    italic: false,
    underline: false,
    alignment: 'left',
    fontSize: 14
  }
}

const INITIAL_STATE = {
  files: [],
  filesLoading: false,
  filesLoadError: null,

  // document data
  id: null,
  bondId: null,
  filename: null,
  name: null,

  pages: [],      // pages (sorted array)
  controls: {},   // controls - indexed by page
  editingField: {},

  loading: false,
  saving: false,
  modified: false,
  saved: false,

  sharing: false,
  shared: false,
  sharingError: null,
  sharedActorData: null,
  shareData: {},
  sharedDocumentError: null,

  assignFieldDialogOpen: false,
  assigningField: false
}

const getDocumentRequest = state => {
  return {
    ...state,
    shareData: {},
    loading: true
  }
}

const getDocumentSuccess = (state, { documentData }) => {
  const pages = documentData.pages.sort((a, b) => a.pageNumber >= b.pageNumber)
  const controls = pages.reduce((pageMem, page) => {
    pageMem[page.id] = page.fields.reduce((fieldMem, field) => {
      fieldMem[field.id] = {
        ...INITIAL_CONTROL_STATE,
        ...field
      }
      return fieldMem
    }, {})
    return pageMem
  }, {})

  return {
    ...state,
    id: documentData.id,
    bondId: documentData.bondId,
    filename: documentData.filename,
    name: documentData.name,
    lockedAt:documentData.lockedAt,
    pages,
    controls,
    loading: false
  }
}

const getDocumentFailure = state => {
  return {
    ...state,
    loading: false
  }
}

const setEditingField = (state, { pageId, fieldId }) => ({
  ...state,
  editingField: !pageId || !fieldId ? {} : { pageId, fieldId }
})

const addNewControl = (state, { pageIndex, fieldType, data}) => {
  const newControlId = `${fieldType}-${utils.getGuid()}`
  const page = state.pages[pageIndex]

  if (!page) {
    return state
  }

  return {
    ...state,
    editingField: {
      pageId: page.id,
      fieldId: newControlId
    },
    controls: {
      ...state.controls,
      [page.id]: {
        ...state.controls[page.id],
        [newControlId]: {
          ...INITIAL_CONTROL_STATE,
          ...data,
          isNew: true,
          modified: true,
          id: newControlId,
          fieldType
        }
      }
    }
  }
}

const updateControl = (state, { pageId, controlId, controlState }) => {
  const pageControls = state.controls[pageId]

  return {
    ...state,
    controls: {
      ...state.controls,
      [pageId]: {
        ...pageControls,
        [controlId]: {
          ...pageControls[controlId],
          ...controlState,
          modified: true
        }
      }
    }
  }
}


const applyFormatToControl = (state, { pageId, controlId, format, value }) => {
  const pageControls = state.controls[pageId]
  const control = pageControls[controlId]

  return {
    ...state,
    controls: {
      ...state.controls,
      [pageId]: {
        ...pageControls,
        [controlId]: {
          ...control,
          modified: true,
          format: {
            ...control.format,
            [format]: value
          }
        }
      }
    }
  }
}


/**
 * If it's a control that hasn't been saved yet, just remove it from the controls
 * If it's a control present in DB, flag it as deleted
 */
const deleteControl = (state, { pageId, controlId }) => {
  const pageControls = state.controls[pageId]

  if (!pageControls[controlId].isNew) {
    return updateControl({...state, editingField: {}}, { pageId, controlId, controlState: { deleted: true }} )
  }

  const updatedControls = Object.keys(pageControls).reduce((mem, pageControlId) => {
    if (pageControlId !== controlId) {
      mem[pageControlId] = pageControls[pageControlId]
    }

    return mem
  }, {})

  return {
    ...state,
    editingField: {},
    controls: {
      ...state.controls,
      [pageId]: {
        ...updatedControls
      }
    }
  }
}

const lockDocumentRequest = (state, {documentId}) => {
  return{
    ...state,
    loading:true
  }
}


const lockDocumentSuccess = state => ({
  ...state,
  lockedAt: true,
  loading:false
})

const saveRequest = state => ({
  ...state,
  saving: true
})

/**
 * Created fields:
 *   remove them from controls by old ID
 *   add them by new ID
 * Deleted fields:
 *   remove them from controls
 * Updated fields:
 *   update data
 */
const saveFieldsCompleted = (state, { responses }) => {
  const controlKeys = Object.keys(responses)
  let { controls } = state

  for (let fieldId in responses) {
    const item = responses[fieldId]
    const { action, success, pageId, control, response } = item
    let pageControls = controls[pageId]

    const field = pageControls[fieldId]

    // in case the call hasn't been successful
    field.modified = false
    field.deleted = false

    if (success && action === 'create') {
      delete pageControls[fieldId]
      pageControls[response.id] = {
        ...INITIAL_CONTROL_STATE,
        ...response
      }
    } else if (success && action === 'delete') {
      delete pageControls[fieldId]
    } else if (!success || action === 'update') {
      pageControls[fieldId] = {
        ...field,
        ...control
      }
    }

    controls = {
      ...controls,
      [pageId]: pageControls
    }
  }

  return {
    ...state,
    saving: false,
    assigningField: false,
    controls
  }
}

const shareDocumentRequest = (state, { actorId }) => ({
  ...state,
  sharing: true,
  shared: false,
  sharingError: null
})

const shareDocumentSuccess = state => ({
  ...state,
  sharing: false,
  shared: true,
  sharingError: null
})

const shareDocumentFailure = (state, { error }) => ({
  ...state,
  sharing: false,
  shared: false,
  sharingError: error
})

const clearSharingState = state => ({
  ...state,
  shared: false,
  sharing: false,
  sharingError: null
})

const getSharedDocumentRequest = (state, { hashId, validationCode }) => ({
  ...state,
  loading: true,
  sharedActorData: null,
  shareData: {}
})

const getSharedDocumentSuccess = (state, { data }) => ({
  ...state,
  sharedActorData: data.actor,
  shareData: {
    id: data.id,
    urlHash: data.urlHash,
    token: data.token
  },
})

const getSharedDocumentFailure = (state, { error }) => ({
  ...state,
  loading: false,
  sharedActorData: null,
  shareData: {},
  sharedDocumentError: error
})

const assignFieldRequest = (state, { fieldId, actorId, pageId }) => {
  return {
    ...state,
    assigningField: true
  }
}

const assignFieldDialogToggle = (state, { value }) => ({
  ...state,
  assignFieldDialogOpen: value
})

const { Types, Creators } = createActions({
  getDownloadedDocumentRequest: ['documentId'],

  getDocumentRequest: ['documentId'],
  getDocumentSuccess: ['documentData'],
  getDocumentFailure: ['error'],

  setEditingField: (pageId, fieldId) => ({
    pageId,
    fieldId,
    type: 'DOKALTIN_SET_EDITING_FIELD',
    meta: { debounce: { time: 50 } }
  }),

  addNewControl: ['pageIndex', 'fieldType', 'data'],
  updateControl: ['pageId', 'controlId', 'controlState'],
  updateControlSize: ['controlId', 'width', 'height'],
  updateControlValue: ['controlId', 'value'],

  applyFormatToControl: ['pageId', 'controlId', 'format', 'value'],

  deleteControl: ['pageId', 'controlId'],

  lockDocumentRequest:['documentId'],
  lockDocumentSuccess:['documentId'],

  saveRequest: [],
  saveFieldsCompleted: ['responses'],

  shareDocumentRequest: ['actorId'],
  shareDocumentSuccess: [''],
  shareDocumentFailure: ['error'],
  clearSharingState: [],
  getSharedDocumentRequest: ['hashId', 'validationCode'],
  getSharedDocumentSuccess: ['data'],
  getSharedDocumentFailure: ['error'],

  assignFieldDialogToggle: ['value'],
  assignFieldRequest: ['fieldId', 'actorId', 'pageId'],


}, { prefix: 'DOKALTIN_'})


const HANDLERS = {
  [Types.GET_DOWNLOADED_DOCUMENT_REQUEST]: getDocumentRequest,
  [Types.GET_DOCUMENT_REQUEST]: getDocumentRequest,
  [Types.GET_DOCUMENT_SUCCESS]: getDocumentSuccess,
  [Types.GET_DOCUMENT_FAILURE]: getDocumentFailure,

  [Types.SET_EDITING_FIELD]: setEditingField,
  [Types.ADD_NEW_CONTROL]: addNewControl,
  [Types.UPDATE_CONTROL]: updateControl,
  [Types.APPLY_FORMAT_TO_CONTROL]: applyFormatToControl,
  [Types.DELETE_CONTROL]: deleteControl,

  [Types.LOCK_DOCUMENT_REQUEST]: lockDocumentRequest,
  [Types.LOCK_DOCUMENT_SUCCESS]: lockDocumentSuccess,

  [Types.SAVE_REQUEST]: saveRequest,
  [Types.SAVE_FIELDS_COMPLETED]: saveFieldsCompleted,

  [Types.SHARE_DOCUMENT_REQUEST]: shareDocumentRequest,
  [Types.SHARE_DOCUMENT_SUCCESS]: shareDocumentSuccess,
  [Types.SHARE_DOCUMENT_FAILURE]: shareDocumentFailure,
  [Types.CLEAR_SHARING_STATE]: clearSharingState,

  [Types.GET_SHARED_DOCUMENT_REQUEST]: getSharedDocumentRequest,
  [Types.GET_SHARED_DOCUMENT_SUCCESS]: getSharedDocumentSuccess,
  [Types.GET_SHARED_DOCUMENT_FAILURE]: getSharedDocumentFailure,

  [Types.ASSIGN_FIELD_DIALOG_TOGGLE]: assignFieldDialogToggle,
  [Types.ASSIGN_FIELD_REQUEST]: assignFieldRequest

}

export const DokaltinActionTypes = Types

export const DokaltinActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)