import { createReducer, createActions } from 'reduxsauce'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback,
} from './utils'

const ACCEPTANCE_INITIAL_STATE = {
  acceptFeedback: initialFeedback(),
  rejectFeedback: initialFeedback(),
}

const INITIAL_STATE = {
  directAnnonceLeads: {},
  selectedDirectAnnonceLead: null,
  showingBrokerValuationData: false,
  brokerValuationFormOpen: false,
  loading: false,
  ...ACCEPTANCE_INITIAL_STATE
}

const getDirectAnnonceLeadRequest = (state, { id }) => {
  return {
    ...state,
    loading: true
  }
}

const getDirectAnnonceLeadSuccess = (state, { directAnnonceLead }) => {
  return {
    ...state,
    loading: false,
    selectedDirectAnnonceLead: directAnnonceLead,
    directAnnonceLeads: {
      ...state.directAnnonceLeads,
      [directAnnonceLead.id]: directAnnonceLead
    }
  }
}

const getDirectAnnonceLeadFailure = state => {
  return {
    ...state,
    loading: false
  }
}

const setShowingBrokerValuationData = (state, { value }) => ({
  ...state,
  showingBrokerValuationData: value
})

const setBrokerValuationFormOpen = (state, { value }) => ({
  ...state,
  brokerValuationFormOpen: value
})

//Acceptance states
const acceptDirectAnnonceLead = state => ({
  ...state,
  acceptFeedback: requestFeedback(),
})
const acceptDirectAnnonceLeadSuccess = state => ({
  ...state,
  acceptFeedback: successFeedback(),
})
const acceptDirectAnnonceLeadFailure = (state, { errors }) => ({
  ...state,
  acceptFeedback: failureFeedback(errors),
})

const rejectDirectAnnonceLead = state => ({
  ...state,
  rejectFeedback: requestFeedback(),
})
const rejectDirectAnnonceLeadSuccess = state => ({
  ...state,
  rejectFeedback: successFeedback(),
})
const rejectDirectAnnonceLeadFailure = (state, { errors }) => ({
  ...state,
  rejectFeedback: failureFeedback(errors),
})

const cleanAcceptanceState = state => ({
  ...state,
  ...ACCEPTANCE_INITIAL_STATE
})

const { Types, Creators } = createActions(
  {
    getDirectAnnonceLeadRequest: ['id'],
    getDirectAnnonceLeadSuccess: ['directAnnonceLead'],
    getDirectAnnonceLeadFailure: ['error'],
    setShowingBrokerValuationData: ['value'],
    setBrokerValuationFormOpen: ['value'],

    acceptDirectAnnonceLead: ['daLeadAssignationId'],
    acceptDirectAnnonceLeadSuccess: [],
    acceptDirectAnnonceLeadFailure: ['errors'],

    rejectDirectAnnonceLead: ['daLeadAssignationId', 'data'],
    rejectDirectAnnonceLeadSuccess: [],
    rejectDirectAnnonceLeadFailure: ['errors'],

    cleanAcceptanceState: [],
  },
  { prefix: 'DIRECT_ANNONCE_LEAD_' }
)

const HANDLERS = {
  [Types.GET_DIRECT_ANNONCE_LEAD_REQUEST]: getDirectAnnonceLeadRequest,
  [Types.GET_DIRECT_ANNONCE_LEAD_SUCCESS]: getDirectAnnonceLeadSuccess,
  [Types.GET_DIRECT_ANNONCE_LEAD_FAILURE]: getDirectAnnonceLeadFailure,
  [Types.SET_SHOWING_BROKER_VALUATION_DATA]: setShowingBrokerValuationData,
  [Types.SET_BROKER_VALUATION_FORM_OPEN]: setBrokerValuationFormOpen,

  [Types.ACCEPT_DIRECT_ANNONCE_LEAD]: acceptDirectAnnonceLead,
  [Types.ACCEPT_DIRECT_ANNONCE_LEAD_SUCCESS]: acceptDirectAnnonceLeadSuccess,
  [Types.ACCEPT_DIRECT_ANNONCE_LEAD_FAILURE]: acceptDirectAnnonceLeadFailure,

  [Types.REJECT_DIRECT_ANNONCE_LEAD]: rejectDirectAnnonceLead,
  [Types.REJECT_DIRECT_ANNONCE_LEAD_SUCCESS]: rejectDirectAnnonceLeadSuccess,
  [Types.REJECT_DIRECT_ANNONCE_LEAD_FAILURE]: rejectDirectAnnonceLeadFailure,

  [Types.CLEAN_ACCEPTANCE_STATE]: cleanAcceptanceState,
}

export const DirectAnnonceLeadActionTypes = Types

export const DirectAnnonceLeadActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
