/**
 * Get an array of all nested keys from any object
 * Note: Arrays will be skipped
 * @param {Object} obj
 * @param {String} prefix
 * @example
 * const input = {
 *  checkName: 'Name of HTTP check',
 *  tags: ['example_tag'],
 *  checkParams: {
 *    basicAuth: false,
 *    encryption: {
 *      enabled: true,
 *    }
 *  }
 * }
 * keyify(input) -> ["checkName", "checkParams.basicAuth", "checkParams.encryption.enabled"]
 */

export const keyify = (obj, prefix = '') =>
  Object.keys(obj).reduce((res, el) => {
    if (Array.isArray(obj[el])) {
      return res
    } else if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...keyify(obj[el], prefix + el + '.')]
    } else {
      return [...res, prefix + el]
    }
  }, [])

/**
 * @function isObject
 * @param {any} varType - any type of data
 * @returns {boolean} - boolean indicating if the data is an object or not
 */
export const isObject = varType =>
  typeof varType === 'object' && varType !== null

/**
 * @function getObjValueByPath
 * @description To access some property object by its string representation path
 * @example
 * const obj = { a: { b: "b" } }
 * getObjValueByPath(obj, "a.b")
 * > "b"
 * @param {Object} obj
 * @param {String} path - "a.b.c"
 * @returns {Any}
 */
export const getObjValueByPath = (obj, path) => {
  if (!isObject(obj) || !path) {
    return null
  }

  return String(path).split('.').reduce((o, i) => o?.[i] || null, obj)
}

/**
 * @function mergeObjs
 * @example
 * mergeObjs({ a: "a"}, { b: "b" }, { b: "c" } )
 * > {a: 'a', b: 'c'}
 * @param  {...Object} objects
 * @returns
 */
export function mergeObjs(...objects) {
  return objects.reduce((acc, current) => ({ ...acc, ...current }), {})
}
