import { companyConfig } from 'globalConfigApp'

import styles from './styles'

export default [
  <article
    data-category="portals"
    data-title="¿En qué plataformas se está publicando?"
    data-url="en-que-plataformas-se-esta-publicando"
    className="wrapper-article"
  >
    <h4>¿En qué plataformas se está publicando?</h4>
    <div className="content">
      <p>
        La selección de promoción en portales, destaque y publicidad en general queda a discreción de {companyConfig.name},
        según crea conveniente y de acuerdo a sus algoritmos inteligentes de promoción.
      </p>
      <b>Nacional</b>
      <ul>
        <li>Vivanuncios</li>
        <li>Inmuebles24</li>
        <li>Lamudi</li>
        <li>Propiedades.com</li>
        <li>Icasas</li>
        <li>Mercadolibre</li>
        <li>Goplace It</li>
        <li>Segundamano</li>
        <li>La Gran Inmobiliaria</li>
        <li>Rentealo (solo rentas)</li>
        <li>El Mapa</li>
        <li>Whatsapp-Icasas</li>
        <li>Whatsapp-Propiedades.com</li>
        <li>Whatsapp-Inmuebles24</li>
        <li>Nocnok</li>
        <li>Whatsapp-Nocnok (Leads)</li>
        <li>Whatsapp-Vivanuncios</li>
        <li>Whatsapp-Mercadolibre</li>
        <li>Whatsapp-Metros cúbicos</li>
        <li>Whatsapp-Segundamano</li>
        <li>Portal Terreno (Solo terrenos)</li>
      </ul>
      <b>Internacional</b>
      <ul>
        <li>List Globally</li>
        <li>Green Acres</li>
        <li>Point2Home</li>
        <li>Trovit</li>
        <li>Mitula</li>
        <li>Rubrikk</li>
        <li>Global MLS</li>
        <li>Mudafy</li>
        <li>Busca Hogares</li>
        <li>Banorte Sucursal</li>
      </ul>

      <p>
        {companyConfig.name} esta agregando más portales de manera constante para darte mayor
        visibilidad.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="portals"
    data-title="¿Qué tipo de anuncios se publicarán en los portales?"
    data-url="que-tipo-de-anuncios-se-publicaran-en-los-portales"
    className="wrapper-article"
  >
    <h4>¿Qué tipo de anuncios se publicarán en los portales?</h4>
    <div className="content">
      <p>
        La visibilidad de tus anuncios en los diferentes portales depende de
        diferentes factores:
      </p>
      <ol>
        <li>
          Con algunos portales como Vivanuncios e Inmuebles24, tu paquete
          incluye opciones de visibilidad como destacados o primeros resultados.
        </li>
        <li>
          Con otros portales, {companyConfig.name} cuenta con un volumen importante de
          destacados que aplica a las propiedades que reciben menos contactos.
        </li>
      </ol>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="portals"
    data-title="¿Cómo puedo encontrar mis propiedades publicadas?"
    data-url="como-puedo-encontrar-mis-propiedades-publicadas"
    className="wrapper-article"
  >
    <h4>¿Cómo puedo encontrar mis propiedades publicadas?</h4>
    <div className="content">
      <p>
        {companyConfig.name} te brinda esta información dentro de tu cuenta. Te indicamos
        donde estan tus propiedades en los portales después de cada publicación.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="portals"
    data-title="¿Por qué en mis propiedades me aparecen algunos portales marcados en color verde?"
    data-url="por-que-en-mis-propiedades-me-aparecen-algunos-portales-marcados-en-color-verde"
    className="wrapper-article"
  >
    <h4>
      ¿Por qué en mis propiedades me aparecen algunos portales marcados en color
      verde?
    </h4>
    <div className="content">
      <p>
        En el módulo de Gestión de Propiedades encontrarás los links de tus
        propiedades. Con un solo click en el portal marcado en verde te
        re-direccionamos al anuncio del portal seleccionado.
      </p>
      <p>
        Nos encontramos en la primera fase de esta actualización, por lo que los
        links que aún aparecen en gris aún no están enlazados. Esto no quiere
        decir que tus propiedades no estén publicadas, muy pronto estarán
        enlazados todos los portales.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="portals"
    data-title="¿Qué hacer para que mi propiedad aparezca apta para publicación?"
    data-url="que-hacer-para-que-mi-propiedad-aparezca-apta-para-publicacion"
    className="wrapper-article"
  >
    <h4>¿Qué hacer para que mi propiedad aparezca apta para publicación?</h4>
    <div className="content">
      <p>
        Los requisitos mínimos son un contrato firmado por ti y tu cliente mas la identificación de tu cliente.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="portals"
    data-title="¿Cuánto tiempo tarda en publicarse mi anuncio en portales?"
    data-url="cuanto-tiempo-tarda-en-publicarse-mi-anuncio-en-portales"
    className="wrapper-article"
  >
    <h4>¿Cuánto tiempo tarda en publicarse mi anuncio en portales?</h4>
    <div className="content">
      <p>
        Tus anuncios dependen que sean aptos para publicación, a partir de que el equipo legal revisa que toda la documentación está correcta de 24 a 72 horas se verán en portales.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>
]
