import { createReducer, createActions } from 'reduxsauce'
import { contractSelector } from 'selectors/NewChildAgent'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback,
} from './utils'

/**
 * @typedef {Object} Contract
 * @property {String|null} id
 * @property {String} status - "SUCCESS", "PENDING", "ERROR"
 * @property {String} name - "RED", "Prestación de Servicios", "Ética"
 * @property {String} documentType - service-contract | network-contract | code-conduct
 * @property {Object} feedback - { loading: boolean, success: boolean, error: boolean }
 */

const INITIAL_STATE = {
  agent: null, // { id: 1, firstName: "Dua", lastName: "Lipa", email: "..." }
  suscription: {
    createdAt: null,
    status: null, // SUCCESS, PENDING, ERROR
    feedback: initialFeedback(),
  },
  contracts: [], // {Contract[]}
  /**
   * isFinishedUserRegistrationProcess:
   * true if the user/broker is created and
   * has its subscription and contracts if false, make a request to
   * NewChildAgent.finishProcess
   */
  isFinishedUserRegistrationProcess: false,
  finishProcessFeedback: initialFeedback(),
  summaryFeedback: initialFeedback(), // Requesting agent, suscription and contracts
}

const { Types, Creators } = createActions(
  {
    summary: ['brokerId'],
    summarySuccess: ['data'],
    summaryFailure: ['errors'],

    createSubscription: ['brokerId'],
    createSubscriptionSuccess: ['suscription'],
    createSubscriptionFailure: ['errors'],

    createContract: ['brokerId', 'contractTypeId'],
    createContractSuccess: ['contractTypeId', 'contract'],
    createContractFailure: ['contractTypeId', 'errors'],

    finishProcess: ['brokerId'],
    finishProcessSuccess: [],
    finishProcessFailure: ['errors'],

    /**
     * At this point the user/broker is created but it needs its subscription and contracts
     * This action tries to finish the process by creating the subscription and contracts
     * Contracts depend on the subscription
     * If any of the requests fails, the process is not finished and the UI should show the error
     */
    tryToFinishRegisteredProcess: ['brokerId'],

    cleanState: [],
  },
  { prefix: 'NEW_CHILD_AGENT_' },
)

const summary = state => ({
  ...state,
  summaryFeedback: requestFeedback(),
})

const summarySuccess = (state, { data }) => ({
  ...state,
  agent: data.agent,
  suscription: {
    ...data.suscription,
    feedback: initialFeedback(),
  },
  contracts: data.contracts,
  isFinishedUserRegistrationProcess: data.isFinishedUserRegistrationProcess,
  summaryFeedback: successFeedback(),
})

const summaryFailure = (state, { errors }) => ({
  ...state,
  summaryFeedback: failureFeedback(errors),
})

const createSubscription = state => ({
  ...state,
  suscription: {
    ...state.suscription,
    feedback: requestFeedback(),
  },
})

const createSubscriptionSuccess = (state, { suscription }) => ({
  ...state,
  suscription: {
    ...state.suscription,
    ...suscription,
    feedback: successFeedback(),
  },
})

const createSubscriptionFailure = (state, { errors }) => ({
  ...state,
  suscription: {
    ...state.suscription,
    status: 'ERROR',
    feedback: failureFeedback(errors),
  },
})

const createContract = (state, { contractTypeId }) => ({
  ...state,
  contracts: state.contracts.map(contract =>
    contract.documentType === contractTypeId
      ? { ...contract, feedback: requestFeedback() }
      : contract,
  ),
})

const createContractSuccess = (state, { contractTypeId, contract }) => ({
  ...state,
  contracts: state.contracts.map(currentContract =>
    currentContract.documentType === contractTypeId
      ? {
          ...currentContract,
          ...contract,
          feedback: successFeedback(),
        }
      : currentContract,
  ),
})

const createContractFailure = (state, { contractTypeId, errors }) => ({
  ...state,
  contracts: state.contracts.map(currentContract =>
    currentContract.documentType === contractTypeId
      ? {
          ...currentContract,
          status: 'ERROR',
          feedback: failureFeedback(errors),
        }
      : currentContract,
  ),
})

const finishProcess = state => ({
  ...state,
  finishProcessFeedback: requestFeedback(),
})

const finishProcessSuccess = state => ({
  ...state,
  isFinishedUserRegistrationProcess: true,
  finishProcessFeedback: successFeedback(),
})

const finishProcessFailure = (state, { errors }) => ({
  ...state,
  finishProcessFeedback: failureFeedback(errors),
})

const cleanState = () => INITIAL_STATE

const HANDLERS = {
  [Types.SUMMARY]: summary,
  [Types.SUMMARY_SUCCESS]: summarySuccess,
  [Types.SUMMARY_FAILURE]: summaryFailure,

  [Types.CREATE_SUBSCRIPTION]: createSubscription,
  [Types.CREATE_SUBSCRIPTION_SUCCESS]: createSubscriptionSuccess,
  [Types.CREATE_SUBSCRIPTION_FAILURE]: createSubscriptionFailure,

  [Types.CREATE_CONTRACT]: createContract,
  [Types.CREATE_CONTRACT_SUCCESS]: createContractSuccess,
  [Types.CREATE_CONTRACT_FAILURE]: createContractFailure,

  [Types.FINISH_PROCESS]: finishProcess,
  [Types.FINISH_PROCESS_SUCCESS]: finishProcessSuccess,
  [Types.FINISH_PROCESS_FAILURE]: finishProcessFailure,

  [Types.CLEAN_STATE]: cleanState,
}

export const NewChildAgentActionTypes = Types
export const NewChildAgentActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
