import localForage from 'localforage'
import { createTransform } from 'redux-persist'
import { createBlacklistFilter } from 'redux-persist-transform-filter'
import jwtDecode from 'jwt-decode'

// Transformers
const wipeRefreshToken = createTransform(
  state => state,
  state => {
    let decodedToken = null;
    if (state.token) {
      decodedToken = jwtDecode(state.token)
    }

    // only reconciliate the auth info if the token is present and had not expired
    if (decodedToken && (decodedToken.exp * 1000) > Date.now()) {
      return {
        ...state,
        tokenRefreshed: false
      }
    }

    return {
      isAuthenticated: false,
      authenticating: false,
      refreshingToken: false,
      tokenRefreshed: false,
      token: null,
      profile: {},
      refreshToken: null,
    }
  },
  { whitelist: ['auth'] }
)

const filterBlacklistedAuthKeys = createBlacklistFilter(
  'auth',
  [
    'emailConfirmationLoading',
    'emailConfirmed',
    'emailConfirmationError'
  ]
);

export const WHITE_LIST_REDUCERS = [
  'auth',
  'userSession',
  'notification',
  'tracingTours',
  'propertyHighlight',
]
export const CLIENT_REDUCERS = [
  ...WHITE_LIST_REDUCERS,
  'app',
  'store_locationService',
  'store_crmBinLead',
]

export default {
  key: 'nextjs',
  whitelist: WHITE_LIST_REDUCERS,
  storage: localForage,
  transforms: [wipeRefreshToken, filterBlacklistedAuthKeys]
}
