import { createReducer, createActions } from 'reduxsauce'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback,
} from './utils'

export const INITIAL_STATE = {
  leadData: {},
  leadAssignationStatus: null, // ALREADY_ACCEPTED | ALREADY_REJECTED
  assignationDate: null,
  acceptRecruitmentLeadStatus: initialFeedback(),
  findRecruitmentLeadToRejectStatus: initialFeedback(),
  rejectRecruitmentLeadStatus: initialFeedback(),
  contactedRecruitmentLeadStatus: initialFeedback(),
}

const { Types, Creators } = createActions(
  {
    acceptRecruitmentLead: ['recruitmentLeadId'],
    acceptRecruitmentLeadSuccess: ['leadData'],
    acceptRecruitmentLeadFailure: ['errors', 'leadAssignationStatus', 'assignationDate'],

    findRecruitmentLeadToReject: ['recruitmentLeadId'],
    findRecruitmentLeadToRejectSuccess: ['leadData'],
    findRecruitmentLeadToRejectFailure: ['errors', 'leadAssignationStatus', 'assignationDate'],

    rejectRecruitmentLead: ['recruitmentLeadId', 'rejectedReason', 'rejectedComments'],
    rejectRecruitmentLeadSuccess: [],
    rejectRecruitmentLeadFailure: ['errors'],

    contactedRecruitmentLead: ['recruitmentLeadId', 'contacted'],
    contactedRecruitmentLeadSuccess: ['leadData'],
    contactedRecruitmentLeadFailure: ['errors'],

    cleanState: [],
  },
  { prefix: 'RECRUITMENT_LEAD_' },
)

const acceptRecruitmentLead = state => ({
  ...state,
  leadAssignationStatus: null,
  assignationDate: null,
  acceptRecruitmentLeadStatus: requestFeedback(),
})
const acceptRecruitmentLeadSuccess = (state, { leadData }) => ({
  ...state,
  leadData,
  leadAssignationStatus: null,
  assignationDate: null,
  acceptRecruitmentLeadStatus: successFeedback(),
})
const acceptRecruitmentLeadFailure = (state, { errors, leadAssignationStatus, assignationDate }) => ({
  ...state,
  leadAssignationStatus,
  assignationDate,
  acceptRecruitmentLeadStatus: failureFeedback(errors),
})

const findRecruitmentLeadToReject = state => ({
  ...state,
  leadAssignationStatus: null,
  assignationDate: null,
  findRecruitmentLeadToRejectStatus: requestFeedback(),
})
const findRecruitmentLeadToRejectSuccess = (state, { leadData }) => ({
  ...state,
  leadData,
  leadAssignationStatus: null,
  assignationDate: null,
  findRecruitmentLeadToRejectStatus: successFeedback(),
})
const findRecruitmentLeadToRejectFailure = (state, { errors, leadAssignationStatus, assignationDate }) => ({
  ...state,
  leadAssignationStatus,
  assignationDate,
  findRecruitmentLeadToRejectStatus: failureFeedback(errors),
})

const rejectRecruitmentLead = state => ({
  ...state,
  rejectRecruitmentLeadStatus: requestFeedback(),
})
const rejectRecruitmentLeadSuccess = state => ({
  ...state,
  rejectRecruitmentLeadStatus: successFeedback(),
})
const rejectRecruitmentLeadFailure = (state, { errors }) => ({
  ...state,
  rejectRecruitmentLeadStatus: failureFeedback(errors),
})

const contactedRecruitmentLead = state => ({
  ...state,
  contactedRecruitmentLeadStatus: requestFeedback(),
})
const contactedRecruitmentLeadSuccess = (state, { leadData }) => ({
  ...state,
  leadData,
  contactedRecruitmentLeadStatus: successFeedback(),
})
const contactedRecruitmentLeadFailure = (state, { errors }) => ({
  ...state,
  contactedRecruitmentLeadStatus: failureFeedback(errors),
})

const cleanState = () => INITIAL_STATE

const HANDLERS = {
  [Types.ACCEPT_RECRUITMENT_LEAD]: acceptRecruitmentLead,
  [Types.ACCEPT_RECRUITMENT_LEAD_SUCCESS]: acceptRecruitmentLeadSuccess,
  [Types.ACCEPT_RECRUITMENT_LEAD_FAILURE]: acceptRecruitmentLeadFailure,
  
  [Types.FIND_RECRUITMENT_LEAD_TO_REJECT]: findRecruitmentLeadToReject,
  [Types.FIND_RECRUITMENT_LEAD_TO_REJECT_SUCCESS]: findRecruitmentLeadToRejectSuccess,
  [Types.FIND_RECRUITMENT_LEAD_TO_REJECT_FAILURE]: findRecruitmentLeadToRejectFailure,

  [Types.REJECT_RECRUITMENT_LEAD]: rejectRecruitmentLead,
  [Types.REJECT_RECRUITMENT_LEAD_SUCCESS]: rejectRecruitmentLeadSuccess,
  [Types.REJECT_RECRUITMENT_LEAD_FAILURE]: rejectRecruitmentLeadFailure,

  [Types.CONTACTED_RECRUITMENT_LEAD]: contactedRecruitmentLead,
  [Types.CONTACTED_RECRUITMENT_LEAD_SUCCESS]: contactedRecruitmentLeadSuccess,
  [Types.CONTACTED_RECRUITMENT_LEAD_FAILURE]: contactedRecruitmentLeadFailure,

  [Types.CLEAN_STATE]: cleanState,
}

export const RecruitmentLeadActionTypes = Types

export const RecruitmentLeadActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
