import { call, put, select } from 'redux-saga/effects'
import { RfcActionCreators } from 'app/reducers/RFCRedux'
import api from 'app/services/api'

export function* getRfc() {
  const token = yield select(state => state.auth.token)
  const response = yield call(
    api.rfc.getRfc,
    token
  )

  if (response.status === 200) {
    return yield put(
      RfcActionCreators.getRfcSuccess(response.data)
    )
  }
}

