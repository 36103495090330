export default {
  name: 'paymentPayment',
  namespace: 'payments',
  methods: [
    {
      name: 'filter',
      params: ['limit', 'offset'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/payments/${namespace}/`,
      urlParams: [],
      seq: false
    },
    {
      name: 'create',
      params: ['data'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace }) => `/v1/payments/${namespace}/`,
      urlParams: [],
      seq: false
    },
    {
      name: 'getOrders',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/payments/${namespace}/orders/`,
      urlParams: [],
      seq: false
    }

  ]
}
