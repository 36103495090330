import { fonts, fontSizes, getFont, measurements, colors } from 'app/theme'
import css from 'styled-jsx/css'

export default css`
  .svg {
    display: block;
    margin: 0;
    max-width: 100%;
  }
  .svg-circle {
    fill: none;
    transform: rotate(-90deg);
    transform-origin: center;
  }
  .svg-circle-text {
    font-size: 2rem;
    text-anchor: middle;
    dominant-baseline: middle;
  }
`