export default {
  name: 'mlmNetwork',
  namespace: 'network',
  methods: [
    {
      name: 'monthlyCommissions',
      params: ['agent_id', 'start', 'end'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, agent_id, year, month }) =>
        `/v1/mlm/${namespace}/agent/${agent_id}/monthly_commission/`,
      urlParams: ['agent_id']
    },

    {
      name: 'viewCommissionEntered',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) =>
        `/v1/mlm/${namespace}/view_commission_entered/${id}/`,
      urlParams: ['id']
    },

    {
      name: 'quarterlyCommissions',
      params: ['agent_id', 'year', 'quarter'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, agent_id, year, quarter }) =>
        `/v1/mlm/${namespace}/agent/${agent_id}/quarterly_commission/${year}/${quarter}/`,
      urlParams: ['agent_id', 'year', 'quarter']
    },

    {
      name: 'annualCommissions',
      params: ['agent_id', 'year'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, agent_id, year }) =>
        `/v1/mlm/${namespace}/agent/${agent_id}/annual_commission/${year}/`,
      urlParams: ['agent_id', 'year']
    },

    {
      name: 'billing',
      params: ['agent_id', 'year', 'month'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, agent_id, year, month }) =>
        `/v1/mlm/${namespace}/agent/${agent_id}/billing/${year}/${month}/`,
      urlParams: ['agent_id', 'year', 'month']
    },

    {
      name: 'commissionsEntered',
      params: ['closed_at_after', 'closed_at_before', 'agent_id'],
      successCode: 200,
      method: 'Get',
      url: ({namespace}) =>
        `/v1/mlm/${namespace}/view_commission_entered/`,
      urlParams: []
    },

    {
      name: 'summaryDetailsCommissions',
      params: ['start', 'end'],
      successCode: 200,
      method: 'Get',
      url: () => `/v1/mlm/summary/`,
      urlParams: []
    },
    {
      name: 'commissionsDistribution',
      params: [],
      successCode: 200,
      method: 'Get',
      url: () =>
        `/v1/mlm_distribution/network_quotas/`,
      urlParams: []
    },
    {
      name: 'summaryAgentDetailCommissions',
      params: ['agent_id'],
      successCode: 200,
      method: 'Get',
      url: ({ agent_id }) => `/v1/mlm/agents/${agent_id}/summary/`,
      urlParams: ['agent_id']
    },
    {
      name: 'networkQuotasCommission',
      params: ['commission_id'],
      successCode: 200,
      method: 'Get',
      url: ({ commission_id }) => `/v1/mlm_distribution/commissions/${commission_id}/`,
      urlParams: ['commission_id']
    },
  ]
}
