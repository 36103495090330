import crudAction from './actions'

export default {
  name: 'directAnnonceLead',
  namespace: 'direct_annonce_leads',
  methods: [
    ...crudAction,
    {
      name: 'search',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/${namespace}/search/`,
      urlParams: []
    }
  ]
}

