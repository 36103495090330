import { call, put, select } from 'redux-saga/effects'
import { StatsActionCreators } from 'app/reducers/StatsRedux'
import api from 'app/services/api'

export function* getStats() {
  const token = yield select(state => state.auth.token)
  const response = yield call(api.stats.getStats, token)

  if (response.status === 200) {
    return yield put(StatsActionCreators.getStatsSuccess(response.data))
  }

  yield put(StatsActionCreators.getStatsFailure(response.data))
}
