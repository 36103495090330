import crudAction from '../actions'

export default {
  name: 'crmCandidate',
  namespace: 'crm/applicants',
  methods: [
    ...crudAction,
    {
      name: 'contactCandidate',
      params: ['candidateId', 'data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, candidateId }) => `/v1/${namespace}/${candidateId}/contacted/`,
      urlParams: ['candidateId'],
    },
    {
      name: 'notes',
      params: ['candidateId'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, candidateId }) => `/v1/${namespace}/${candidateId}/notes/`,
      urlParams: ['candidateId']
    },
    {
      name: 'createNote',
      params: ['candidateId', 'data'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace, candidateId }) => `/v1/${namespace}/${candidateId}/notes/`,
      urlParams: ['candidateId']
    },
    {
      name: 'rejectCanditate',
      params: ['candidateId', 'data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, candidateId }) => `/v1/${namespace}/${candidateId}/decline/`,
      urlParams: ['candidateId']
    },
  ],
}
