import css from 'styled-jsx/css'
import colors from 'theme/colors'

export default css`
  .button.success {
    background-color: ${colors.success};
    border-color: ${colors.success};

    transition: 100ms background-color, 100ms border-color;
  }

  .success {
    color: ${colors.white};

    transition: 100ms color;
  }

  .button:not(.disabled):hover.success, .button:not(.disabled):focus.success {
    background-color: ${colors.pickleGreen};
    border-color: ${colors.pickleGreen};
    color: ${colors.white};

    transition: 100ms background-color, 100ms border-color, 100ms color;
  }
`