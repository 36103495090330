export const initialFeedback = () => ({
  loading: false,
  success: false,
  error: false,
  errors: {}
})

export const requestFeedback = () => ({
  loading: true,
  success: false,
  error: false,
  errors: {}
})

export const successFeedback = () => ({
  loading: false,
  success: true,
  error: false,
  errors: {}
})

export const failureFeedback = errors => ({
  // errors is a response.data
  loading: false,
  success: false,
  error: true,
  errors: errors || {}
})

export function getHashedState(state, hash, defaultData = null) {
  const currentHashedState = state[hash] || defaultData
  return currentHashedState
}

export const composeGetHashedState = defaultData => (state, hash) =>
  getHashedState(state, hash, defaultData)

export function addHashedState(state, hash, data) {
  const copyState = { ...state }
  copyState[hash] = data
  return copyState
}

export function updateHashedState(state, hash, data) {
  const copyState = { ...state }
  const currentHashedState = copyState[hash] || {}
  copyState[hash] = {
    ...currentHashedState,
    ...data
  }
  return copyState
}

export function removeHashedState(state, hash) {
  return Object.keys(state)
    .filter(key => key !== hash)
    .reduce((acc, key) => ({ ...acc, [key]: state[key] }), {})
}
