import css from 'styled-jsx/css'
import colors from 'theme/colors'

export default css`
  .button.danger {
    background-color: ${colors.tartRed};
    border-color: ${colors.tartRed};

    transition: 100ms background-color, 100ms border-color;
  }

  .danger {
    color: ${colors.white};

    transition: 100ms color;
  }

  .button:not(.disabled):hover.danger, .button:not(.disabled):focus.danger {
    background-color: ${colors.crimsonRed};
    border-color: ${colors.candyRed};
    color: ${colors.white};

    transition: 100ms background-color, 100ms border-color, 100ms color;
  }
`