import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import QueryString from 'query-string'

import { getFiltersFromUrl, buildLocationUrlFromLocationObject } from 'services/seo'
import { amenitiesFromParams } from 'services/seo/amenities'

export const useParams = () => {
  const router = useRouter()
  const vipProperty = useSelector(state => state.vip.property) 
  const queryParams = QueryString.parseUrl(router.asPath)
  const filters = getFiltersFromUrl(router.query)

  if (router.query.propertyId) {
    filters.location = null
  } 
  if (vipProperty && vipProperty.id === Number(router.query.propertyId)) {
    filters.location = buildLocationUrlFromLocationObject(vipProperty)
  }

  return {
    filters,
    queryParams
  }
}

export const useSrpParams = () => {
  const { filters, queryParams } = useParams()

  const { operationType, propertyType, location, page } = filters
  const amenities = amenitiesFromParams(filters)

  return { operationType, propertyType, location, page, ...amenities }
}
