const METHODS_WITH_NO_BODY = ['GET', 'DELETE']


function removeUnwantedBody(requestConfig) {
  const shouldNotHaveBody = METHODS_WITH_NO_BODY.includes(requestConfig.method.toUpperCase())

  if (shouldNotHaveBody) {
    delete requestConfig.data
  }

  return requestConfig
}

export default removeUnwantedBody
