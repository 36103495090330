import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles'

class BaseIcon extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    family: PropTypes.oneOf(['simpleicon', 'ionicon', 'materialicon']),
    size: PropTypes.oneOf(['small', 'medium', 'large'])
  }

  static defaultProps = {
    size: 'medium',
    className: '',
    style: {},
    family: 'simpleicon'
  }

  getClasses = () => {
    if (this.props.family === 'ionicon') {
      return classnames(this.props.className, this.props.size, `ion-${this.props.icon}`)
    }

    if (this.props.family === 'materialicon') {
      return classnames(this.props.className, this.props.size, `material-icons`)
    }

    return classnames(this.props.className, this.props.size, `icon-${this.props.icon}`, 'icons')
  }

  getContent = () => {
    if (this.props.family === 'materialicon') {
      return this.props.icon
    }

    return null
  }

  render() {
    const {
      style,
      family,
      className,
      ...rest
    } = this.props
    return (
      <i
        style={style}
        className={classnames(this.getClasses(), family, className)}
        {...rest}
      >
        { this.getContent() }
        <style jsx>{styles}</style>
      </i>
    )
  }
}

export default BaseIcon
