import { colors } from 'theme'
import fonts from '../../app/theme/fonts'
import css from 'styled-jsx/css'

export default css`
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(39, 69, 123, 0.9);
    visibility: hidden;
    opacity: 0;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: opacity 200ms;
  }

  .overlay.top {
    justify-content: flex-start;
  }
  .overlay.top .modal {
    margin-top: 5rem;
  }
  .overlay.center {
    justify-content: center;
  }

  .overlay.modal-as-full-page {
    padding: 0;
  }

  .modal {
    padding: auto;
    background: ${colors.white};
    border-radius: 5px;
    width: 100%;
    max-width: 580px;
    height: auto;
    position: relative;
    transition: all 1s ease-in-out;
  }

  .modal.modal-as-full-page {
    width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .overlay.show {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .overlay.cover {
    padding: 0;
  }

  .overlay.cover .modal {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .overlay .modal :global(.close-button, .minimize-button) {
    position: absolute;
  }

  .overlay.cover .modal :global(.close-button) {
    color: ${colors.black};
    right: 0;
    top: 0;
  }

  .overlay.cover .modal :global(.close-button i) {
    color: ${colors.black};
  }

  .overlay:not(.cover) .modal :global(.close-button) {
    padding: 0;
    right: 0;
    top: -50px;
    white-space: pre;
  }

  .overlay:not(.cover) .modal :global(.minimize-button) {
    padding: 0;
    right: 70px;
    top: -50px;
    white-space: pre;
  }

  .overlay:not(.cover) .modal :global(.close-button i, .minimize-button i) {
    width: 15px;
    height: 15px;
    object-fit: contain;
  }

  .overlay:not(.cover) .modal :global(.close-button),
  .overlay:not(.cover) .modal :global(.close-button i) {
    color: ${colors.white};
    font: ${fonts.medium};
    font-size: 14px;
  }

  .overlay:not(.cover) .modal :global(.minimize-button),
  .overlay:not(.cover) .modal :global(.minimize-button i) {
    color: ${colors.white};
    font: ${fonts.medium};
    font-size: 14px;
  }

  @media screen and (orientation: landscape) and (max-width: 768px) and (min-width: 500px) {
    .overlay .modal {
      margin-top: 10rem;
    }
    .overlay.coverOnMobile .modal {
      margin: 0 auto;
    }

    .overlay.modal-as-full-page .modal {
      margin: 0;
    }
  }

  @media screen and (max-width: 500px) {
    .overlay {
      padding: 0.5rem;
    }
    .overlay:not(.cover) .modal {
      margin-top: 1rem;
    }

    .overlay.coverOnMobile {
      padding: 0;
      justify-content: flex-start;
    }

    .overlay.coverOnMobile .modal {
      width: 100%;
      height: auto;
      border-radius: 0;
    }

    .overlay.modal-as-full-page .modal {
      margin: 0;
    }

    .overlay.coverOnMobile .modal :global(.close-button) {
      color: ${colors.black};
      right: 0;
      top: 0;
    }

    .overlay.coverOnMobile .modal :global(.close-button i) {
      color: ${colors.black};
    }
  }
`
