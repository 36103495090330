export default {
  name: 'neximerOnboarding',
  namespace: 'neximerOnboarding',
  methods: [
    {
      name: 'onboardingStatus',
      params: [],
      successCode: 200,
      method: 'Get',
      url: () => `/v1/user_onboarding_status/`,
      urlParams: [],
    },
    {
      name: 'setMailboxAndSubdomain',
      params: ['data'],
      successCode: 201,
      method: 'Post',
      url: () => `/v1/users/set_mailbox_and_subdomain/`,
      urlParams: [],
    },
    {
      name: 'getMailboxAndSubdomain',
      params: [],
      successCode: 200,
      method: 'Get',
      url: () => `/v1/users/get_mailbox_and_subdomain/`,
      urlParams: [],
    },
    {
      name: 'setNewPassword',
      params: ['data'],
      successCode: 200,
      method: 'Post',
      url: () => `/v1/users/set_new_password/`,
      urlParams: [],
    },
    {
      name: 'activate',
      params: [],
      successCode: 200,
      method: 'Post',
      url: () => `/v1/users/activate/`,
      urlParams: [],
    },
  ],
}
