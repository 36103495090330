import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles'

class ProgressBar extends PureComponent {
  static propTypes = {
    style: PropTypes.object,
    barStyle: PropTypes.object,
    showProgress: PropTypes.bool,
    corderStyle: PropTypes.oneOf(['rounded', 'semiRounded']),
    bordered: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    backgroundColor: PropTypes.oneOf(['white', 'gray']),
    indicatorColor: PropTypes.oneOf(['primary', 'secondary']),
  }

  static defaultProps = {
    style: {},
    barStyle: {},
    corderStyle: 'semiRounded',
    size: 'small',
    bordered: true,
    showProgress: false,
    indicatorColor: 'secondary',
    backgroundColor: 'white'
  }

  get progress() {
    const { progress } = this.props

    if (progress > 100) {
      return 100
    }

    if (progress < 0) {
      return 0
    }

    return progress
  }

  render() {
    const {
      children,
      size,
      corderStyle,
      bordered,
      backgroundColor,
      className,
      indicatorColor,
    } = this.props

    return (
      <div
        className={classNames(
          'progress-bar-container',
          className,
          { bordered },
          size,
          corderStyle,
          backgroundColor,
          `indicator-color-${indicatorColor}`,
        )}
        style={this.props.style}
      >
        <div
          className="indicator"
          style={{ width: `${this.progress}%`, ...this.props.barStyle }}
        >
          {this.props.showProgress && `${this.props.progress}%`}
        </div>
        {children && <div className="progress-bar-content">{children}</div>}
        <style jsx>{styles}</style>
      </div>
    )
  }
}

export default ProgressBar
