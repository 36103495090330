import api from '../api'

const createCloseWithExternalAgent = (token, data) =>
  api.privatePost('/v1/crm/external_deals/', { token, data })

const createCloseWithoutExternalAgent = (token, data) =>
  api.privatePost('/v1/crm/deals/', { token, data })

export default {
  createCloseWithExternalAgent,
  createCloseWithoutExternalAgent
}
