export default {
  name: 'crmSite',
  namespace: 'sites',
  methods: [
    {
      name: 'filter',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/crm/${namespace}/${id}/`,
      urlParams: ['id']
    }
  ]
}
