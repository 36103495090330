import api from '../api'

const getDataTableCalls = (token, data = {}, params = {}) => {
  return api.privateGet('/v1/calltracking/records/', { token, data, params })
}

const getCallRecordsStats = (token, data = {}, params = {}) => {
  return api.privateGet('/v1/calltracking/stats/', { token, data, params })
}

export default {
  getDataTableCalls,
  getCallRecordsStats
}
