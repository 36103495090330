import { createReducer, createActions } from 'reduxsauce'

export const INITIAL_STATE = {
  contactsPages: {},
  totalCount: null,
  totalPages: null,
  pageSize: 10,
  currentPage: 0,
  selectedContact: {},

  // loaders
  loadingList: false,
  loadingDetails: false,
  saving: false
}

const { Types, Creators } = createActions(
  {
    getContactsRequest: ['page', 'pageSize'],
    getContactsSuccess: ['page', 'data'],
    getContactsFailure: ['error'],
    getContactDetailsRequest: [],
    getContactDetailsSuccess: [],
    getContactDetailsFailure: [],
    createContactRequest: [],
    createContactSuccess: [],
    createContactFailure: []
  },
  { prefix: 'DIRECTORY_' }
)

const getContactsRequest = (state, { page, pageSize }) => ({
  ...state,
  loadingList: true
})

const getContactsSuccess = (state, { page, data }) => {
  const totalCount = data.count
  const totalPages = Math.ceil(data.count / state.pageSize)

  return {
    ...state,
    contactsPages: {
      ...state.contactsPages,
      [page]: data.results
    },
    totalCount,
    totalPages,
    currentPage: page,
    loadingList: false
  }
}

const getContactsFailure = (state, { error }) => ({
  ...state,
  loadingList: false
})

const getContactDetailsRequest = state => ({
  ...state
})

const getContactDetailsSuccess = state => ({
  ...state
})

const getContactDetailsFailure = state => ({
  ...state
})

const createContactRequest = state => ({
  ...state
})

const createContactSuccess = state => ({
  ...state
})

const createContactFailure = state => ({
  ...state
})

const HANDLERS = {
  [Types.GET_CONTACTS_REQUEST]: getContactsRequest,
  [Types.GET_CONTACTS_SUCCESS]: getContactsSuccess,
  [Types.GET_CONTACTS_FAILURE]: getContactsFailure,
  [Types.GET_CONTACT_DETAILS_REQUEST]: getContactDetailsRequest,
  [Types.GET_CONTACT_DETAILS_SUCCESS]: getContactDetailsSuccess,
  [Types.GET_CONTACT_DETAILS_FAILURE]: getContactDetailsFailure,
  [Types.CREATE_CONTACT_REQUEST]: createContactRequest,
  [Types.CREATE_CONTACT_SUCCESS]: createContactSuccess,
  [Types.CREATE_CONTACT_FAILURE]: createContactFailure
}

export const DirectoryActionTypes = Types

export const DirectoryActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
