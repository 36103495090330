import css from 'styled-jsx/css'
import colors from 'theme/colors'
const color = colors.merigoldOrange;

export default css`
  .button.warning.outline {
    border-color: ${color};

    transition: 100ms background-color, 100ms border-color;
  }

  .button.warning.outline {
    color: ${color};

    transition: 100ms color;
  }

  .button:not(.disabled):hover.warning.outline, .button:not(.disabled):focus.warning.outline {
    background-color: ${color};
    color: ${colors.white};

    transition: 100ms background-color, 100ms color;
  }
`