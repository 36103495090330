import { colors, fonts, fontSizes, measurements } from 'theme'
import css from 'styled-jsx/css'

export default css`
  [role='button'],
  a,
  area,
  button,
  input:not([type='range']),
  label,
  select,
  summary,
  textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }
  input[type='number'],
  input[type='password'],
  input[type='text'],
  textarea {
    -webkit-appearance: none;
  }

  .fl-input-container {
    display: flex;
    flex-flow: column;
    position: relative;
  }
  .fl-input-label {
    font-size: ${fontSizes.small};
    padding: 0.5rem 0;
    font-weight: 600;
  }
  .fl-input-container.error .input,
  .fl-input-container.error input {
    border-color: ${colors.danger};
  }
  .fl-input-container .input:focus,
  .fl-input-container input:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  .fl-input-container .input:hover,
  .fl-input-container input:hover {
    border-color: ${colors.borderInput};
    border-right-width: 1px !important;
  }
  .fl-input-container .input:disabled:hover,
  .fl-input-container input:disabled:hover {
    box-shadow: none;
  }
  .fl-input-container .input,
  .fl-input-container input {
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    padding: 4px 11px;
    width: 100%;
    height: 2.1875rem;
    font-size: ${fontSizes.small};
    line-height: 1.5;
    color: ${colors.text};
    background-color: ${colors.white};
    background-image: none;
    border: 1px solid ${colors.borderInput};
    border-radius: ${measurements.borderRadiusInput};
    transition: all 0.3s;
  }
  .fl-input-container .input::placeholder,
  .fl-input-container input::placeholder {
    color: ${colors.darkGray};
  }
  .fl-error-msg {
    -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    color: ${colors.danger};
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
  .fl-input-container.disabled .input,
  .fl-input-container.disabled input {
    background: ${colors.disabled};
  }
  .fl-input-container.disabled .input:hover,
  .fl-input-container.disabled input:hover {
    cursor: not-allowed;
  }

  .fl-input-container.fat .input,
  .fl-input-container.fat input {
    border: none;
    height: 100%;
    min-height: 52px;
  }
  .fl-input-container.fat .input:focus,
  .fl-input-container.fat input:focus {
    box-shadow: none;
  }
  .fl-input-container.fat .input:hover,
  .fl-input-container.fat input:hover {
    border: none;
  }

  label.price-input.nexsite1-side-search {
    font-size: 0.75rem;
    margin-bottom: 0.3125rem;
  }

  .nexsite1-input-form {
    margin-top: 1rem;
  }

  .nexsite1-input-form .input,
  .nexsite1-input-form input {
    height: 3.5rem;
  }

  .price-input.nexsite1-side-search {
    max-width: 100%;
  }
  .fl-warning-msg {
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    color: ${colors.warning};
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }

  .fl-input-container .loading {
    position: absolute;
    right: 0.4rem;
    bottom: 0.4rem;
    background: ${colors.white};
    box-shadow: 0 0 0 0.2rem ${colors.white};
    border-radius: ${measurements.borderRadius};
  }

  .fl-input-container input[type='checkbox'] {
    height: auto;
  }
  .fl-input-container.is-checkbox {
    flex-direction: row-reverse;
    padding: 0.5rem 0;
    justify-content: flex-end;
    align-items: center;
  }
  .fl-input-container.is-checkbox label {
    padding: 0;
  }
  .fl-input-container.is-checkbox input[type='checkbox'] {
    height: 1rem;
    margin-right: 0.5rem;
    width: auto;
  }
`

export const nonFieldErrors = css``

export const select = css`
  .select-container {
    display: flex;
    flex-flow: column;
    flex-direction: column-reverse;
    position: relative;
    margin-top: 1rem;
  }
  label {
    font-size: ${fontSizes.small};
    padding: 0.5rem 0;
    font-weight: 600;
  }
  .select-container.error select {
    border-color: ${colors.danger};
  }
  .select-container select:hover {
    border-color: ${colors.info};
    border-right-width: 1px !important;
  }
  .select-container select:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .select-container select {
    padding-left: 0.5rem;
    width: 100%;
    height: 2.1875rem;
    font-size: ${fontSizes.small};
    color: ${colors.text};
    background-color: ${colors.white};
    background-image: none;
    border: 1px solid ${colors.borderInput};
    border-radius: ${measurements.borderRadiusInput};
    transition: all 0.3s;
  }

  .select-container select.disabled {
    background: ${colors.disabled};
    cursor: not-allowed;
  }

  select {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  select :disabled:hover {
    box-shadow: none;
  }

  select option.placeholder {
    color: ${colors.darkGray};
  }

  label.nexsite1-side-search {
    font-size: 0.75rem;
    margin-bottom: 0.3125rem;
  }

  .select-container .loading {
    position: absolute;
    right: 0.4rem;
    bottom: 0.4rem;
    background: ${colors.white};
    box-shadow: 0 0 0 0.2rem ${colors.white};
    border-radius: ${measurements.borderRadius};
  }
`

export const textarea = css`
  .wrapper-textarea-input {
    position: relative;
  }

  .fl-input-label {
    font-size: ${fontSizes.small};
    padding: 0.5rem 0;
    font-weight: 600;
    display: flex;
  }

  .textarea-input {
    width: 100%;
    background: ${colors.white};
    border-radius: ${measurements.borderRadiusInput};
    padding: 0.2rem 0.875rem 0.2rem;
    border: 1px solid ${colors.borderInput};
  }
  .textarea-input textarea {
    font-size: ${fontSizes.small};
    color: ${colors.text};
    width: inherit;
    max-width: 100%;
    min-width: 100%;
    min-height: 14.5rem;
    margin: 0.3rem 0 0;
    padding: 0;
    border: 0;
    outline: none;
    background: transparent;
  }

  .is-active-rows.textarea-input {
    min-height: 2.1875rem;
    line-height: 0.85rem;
    padding-bottom: 0;
  }
  .is-active-rows.textarea-input textarea {
    min-height: inherit;
    margin: 0;
  }
  .textarea-valid {
    border: 1px solid ${colors.primary};
  }
  .textarea-invalid {
    border: 1px solid ${colors.danger};
  }
  .textarea-invalid-message {
    color: ${colors.danger};
    position: absolute;
    right: 0.5rem;
    bottom: 3px;
    width: 90%;
    text-align: end;
    font-size: ${fontSizes.tiny};
  }
`

export const priceInputStyles = css`
  .input-price-container,
  .contract-input-price-container {
    display: grid;
    grid-template-columns: 1fr 5rem;
    grid-gap: 0.5rem;
  }

  .contract-input-price-container {
    align-items: flex-start;
  }

  :global(.contract-input-price-container .select-container) {
    margin: 0;
  }

  @media screen and (max-width: 548px) {
    .input-price-container,
    .contract-input-price-container {
      display: flex;
      flex-direction: column;
      grid-gap: 0;
      grid-template-columns: 1fr;
    }

    .contract-input-price-container :global(.fl-input-container),
    .input-price-container :global(.fl-input-container) {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
`