import { colors } from 'theme'
import css from 'styled-jsx/css'
const color = colors.primary

export default css`
  .button.primary.outline {
    background-color: ${colors.transparent};
    border-color: ${color};
    transition: 100ms background-color, 100ms border-color;
  }
  
  .button.primary.outline {
    color: ${color};
    transition: 100ms color;
  }

  .button.primary.outline.variation {
    color: ${colors.white};
    border: 1px solid ${colors.white};
  }
  
  .button.primary.outline.variation:hover {
    border: 1px solid ${color};
  }

  .button:not(.disabled):hover.primary.outline {
    background-color: ${color};
    color: ${colors.white};
    transition: 100ms background-color, 100ms color;
  }
`