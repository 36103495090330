import { createReducer, createActions } from 'reduxsauce'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback,
} from './utils'

const INITIAL_STATE = {
  originalUrl: null,
  modifiedUrl: null,
  feedback: initialFeedback(),
}

const { Types, Creators } = createActions(
  {
    imageTransformation: ['originalUrl', 'automaticAdjustment'],
    imageTransformationSuccess: ['originalUrl', 'modifiedUrl'],
    imageTransformationFailure: ['error'],
    imageTransformationClean: [],

    cleanState: [],
  },
  { prefix: 'IMAGE_' },
)

export const imageTransformation = (state, { originalUrl, automaticAdjustment }) => ({
  ...state,
  ...state.imageTransformation,
  originalUrl,
  feedback: requestFeedback(),
})

export const imageTransformationSuccess = (state, { originalUrl, modifiedUrl  }) => ({
  ...state,
  originalUrl,
  modifiedUrl,
  feedback: successFeedback(),
})

export const imageTransformationFailure = (state, { error }) => ({
  ...state,
  ...state.imageTransformation,
  feedback: failureFeedback(error),
})

export const imageTransformationClean = state => ({
  ...state,
  ...INITIAL_STATE,
})

const cleanState = () => INITIAL_STATE

const HANDLERS = {
  [Types.IMAGE_TRANSFORMATION]: imageTransformation,
  [Types.IMAGE_TRANSFORMATION_SUCCESS]: imageTransformationSuccess,
  [Types.IMAGE_TRANSFORMATION_FAILURE]: imageTransformationFailure,
  [Types.IMAGE_TRANSFORMATION_CLEAN]: imageTransformationClean,

  [Types.CLEAN_STATE]: cleanState,
}

export const ImageTransformationActionTypes = Types
export const ImageTransformationActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)