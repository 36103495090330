import css from 'styled-jsx/css'
import { colors } from 'theme'

export default css`
  .button.tertiary {
    background-color: ${colors.tertiary};
    border-color: ${colors.tertiary};

    transition: 100ms background-color, 100ms border-color;
  }

  .tertiary {
    color: ${colors.frame};

    transition: 100ms color;
  }

  .button:not(.disabled):hover.tertiary, .button:not(.disabled):focus.tertiary {
    background-color: ${colors.primary};
    border-color: ${colors.primary};
    color: ${colors.white};

    transition: 100ms background-color, 100ms border-color;
  }
`