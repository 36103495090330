import { colors } from 'theme'
import css from 'styled-jsx/css'

export default css`
  .button.secondary {
    background-color: ${colors.secondary};
    border-color: ${colors.secondary};

    transition: 100ms background-color, 100ms border-color;
  }

  .secondary {
    color: ${colors.white};

    transition: 100ms color;
  }

  .button:not(.disabled):hover.secondary, .button:not(.disabled):focus.secondary {
    background-color: ${colors.darkSecondary};
    border-color: ${colors.darkSecondary};

    transition: 100ms background-color, 100ms border-color;
  }
`