import { formatAppDate } from 'services/date'
import moment from 'moment'

export function getCurrency(value) {
  if (String(value).includes('USD')) {
    return 'USD'
  }

  return 'MXN'
}

export function moneyParser(value) {
  const [sanitizeValue, decimals] = String(value)
    .match(/([\d\.]+)/g)
    ?.join('')
    ?.split('.') || ['0', '0']

  const hasDot = String(value).includes('.')

  const parsedValue = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: 0
  }).format(sanitizeValue)
  // 123,456,789

  return `${parsedValue}${hasDot ? '.' : ''}${decimals || ''}`
}

export function getFormattedDate(value) {
  const momentDate = moment(value)

  return momentDate.isValid() ? formatAppDate(momentDate) : null
}
