import ApiBase from 'app/services/api/entities/Base'
import api from '../api'

const getAll = () => {
  return api.get('/v1/config/')
}

function* dashboard() {
  return yield ApiBase.privateGet('/v1/graphics_suite/banners/')
}

export default {
    getAll,
    dashboard,
}