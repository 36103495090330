import { call, put, take, select, race } from 'redux-saga/effects'
import { HelpDeskActionCreators } from 'app/reducers/HelpDeskRedux'
import { AlertActionCreators } from 'app/reducers/AlertRedux'
import store from 'services/store'
import api from 'app/services/api'

export function* createConversation({ data }) {
  const threadId = yield select(state => state.helpDesk.firstThreadId)

  if(!threadId) {
    yield put(store.create('helpDeskConversation').action(data))
    const {
      conversation,
      error
    } = yield race({
      conversation: take(store.create('helpDeskConversation').types.success),
      error: take(store.create('helpDeskConversation').types.failure)
    })

    if (error) {
      yield put(HelpDeskActionCreators.createConversationFailure(error))
      return yield put(AlertActionCreators.error({message: 'Ocurrió un error el crear el ticket, inténtalo más tarde.'}))
    }

    const {
      conversationId,
      firstThreadId
    } = conversation.response

    yield put(HelpDeskActionCreators.createConversationSuccess(conversationId, firstThreadId))
  }

  const {
    conversationId,
    firstThreadId
  } = yield select(state => ({
    conversationId: state.helpDesk.conversationId,
    firstThreadId: state.helpDesk.firstThreadId
  }))

  console.log('conversationId, firstThreadId', conversationId, firstThreadId)

  const attchments = yield select(state => state.helpDesk.files)

  for(let k of Object.keys(attchments)) {
    yield uploadAttachment({...attchments[k], conversationId, firstThreadId})
  }

  return yield put(
    yield put(HelpDeskActionCreators.uploadAttachmentsFinished(conversationId, firstThreadId))
  )
}


export function* uploadAttachment({ conversationId, firstThreadId, fileName, mimeType, data }) {
  const token = yield select(state => state.auth.token)
  const response = yield call(
    api.helpdesk.createAttachment,
    token,
    {fileName, mimeType, data, conversationId, threadId: firstThreadId}
  )

  if (response.status === 201) {
    return yield put(
      HelpDeskActionCreators.createAttachmentSuccess(response.data.fileName, response.data.hash)
    )
  }

  let errorMessage = 'Hubo un error al subir el archivo'

  switch (response.status) {
    case 413:
      errorMessage = 'El archivo es muy grande'
  }

  yield put(
    HelpDeskActionCreators.createAttachmentFailure(response.data.fileName, errorMessage)
  )
}
