import moment from 'moment'
import { formatAppDate } from 'services/date'
import { normalizeAsUrl } from 'services/string'

export const getTableType = query => query.category || 'summary'

/**
 * @function getQueryLabelsFromFilters
 * @param {Object[]} filters - [ { label: 'Some value', value: 'the-value' } ]
 * @returns {Object} - { 'the-value': 'Some value' }
 */
export function getQueryLabelsFromFilters(filters) {
  return filters.reduce(
    (acc, { label, value }) => ({ ...acc, [value]: label }),
    {},
  )
}

export const marketToOptionValue = market =>
  normalizeAsUrl(`${market.id}--${market.name}`).toLowerCase()

export const marketToOption = market => ({
  label: market.name,
  value: marketToOptionValue(market),
})

export const marketsToOptions = markets => markets.map(marketToOption)

export const constellationToOptionValue = constellation =>
  normalizeAsUrl(
    `${constellation.pk || constellation.id}--${constellation.name}`,
  ).toLowerCase()

export const constellationToOption = constellation => ({
  label: constellation.name,
  value: constellationToOptionValue(constellation),
})

export const constellationsToOptions = constellations =>
         constellations
           .filter(({ name }) => String(name).toUpperCase() !== 'IN HOUSE') // DON'T Show In House to Neximers
           .map(constellationToOption)

export const getIdFromOption = queryOption => {
  if (!queryOption) {
    return ''
  }

  const [id] = queryOption.split('--')
  return id
}

export function parseQueryParams(query) {
  const { market, constellation, from, to, ...restQuery } = query
  const newQuery = { ...restQuery }
  newQuery.market = getIdFromOption(market)
  newQuery.constellation = getIdFromOption(constellation)
  newQuery.from = from && formatAppDate(moment(from).startOf('month'))
  newQuery.to = to && formatAppDate(moment(to).endOf('month'))

  return newQuery
}
