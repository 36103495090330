const CONTRACT_VERSION_PERMISSIONS = {
  ADD_NEW_PROPERTY_REVISION: 'add_new_property_revision',
  CREATE_PROPERTY: 'create_property',
  CREATE_PROPERTY_ZONES: 'create_property_zones',
  ADD_NEW_CHILD: 'add_new_child',
  MONTHS_TO_CREATE_COLAB_CONTRACT: 'months_to_create_colab_contract',
}

export const CONTRACT_VERSION_PERMISSIONS_LIST = Object.values(CONTRACT_VERSION_PERMISSIONS)

export default CONTRACT_VERSION_PERMISSIONS
