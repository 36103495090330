import mime from 'mime-types'

export const downloadFileLink = ({ url, fileName = 'document' }) => {
  const myHeaders = new Headers()
  myHeaders.append('pragma', 'no-cache')
  myHeaders.append('cache-control', 'no-cache')
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: myHeaders
    })
      .then(res => res.blob())
      .then(resBlobl => {
        const element = document.createElement('a')

        element.setAttribute('href', window.URL.createObjectURL(resBlobl))

        element.setAttribute(
          'download',
          `${fileName}.${mime.extension(resBlobl.type)}`
        )
        element.setAttribute('target', "_blank")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
        resolve({ success: true })
      })
      .catch(err => reject({ error: true }))
  })
}

export const splitDotIndex = fileName => {
  const dotIndex = fileName.indexOf('.')
  if (dotIndex !== -1) {
    return fileName.split('.')[0]
  }
  return fileName
}

export const linkToFile = url =>
  new Promise((resolve, reject) => {
    try {
      const element = document.createElement('a')
      element.setAttribute('href', url)
      element.setAttribute('target', '_blank')
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
      resolve({ success: true })
    } catch (err) {
      reject({ error: true })
    }
  })
