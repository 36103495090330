import { createReducer, createActions } from 'reduxsauce'

const STEPS = ['tos', 'verify', 'profile', 'success']

const INITIAL_REQUEST_STATE = {
  loading: false,
  error: null,
  response: null,
  request: null
}

const INITIAL_STATE = {
  sendCode: { ...INITIAL_REQUEST_STATE, codeSent: false, tries: 0 },
  verifyCode: { ...INITIAL_REQUEST_STATE, verifiedPhone: false },
  kit: INITIAL_REQUEST_STATE,
  mailboxOptions: [],
  phoneNumber: '',
  tos: {
    tos: false,
    ethicsCode: false,
    tosMlm: false,
    mlm: false,
    neximoFounders: false,
  },
  ui: {
    currentStep: STEPS[0],
    noticeShown: false
  }
}

const { Types, Creators } = createActions(
  {
    getMailboxOptionsRequest: [],
    getMailboxOptionsSuccess: ['mailboxOptions'],

    sendCodeRequest: ['phoneNumber'],
    sendCodeSuccess: ['response'],
    sendCodeFailure: ['error'],

    verifyCodeRequest: ['code', 'phoneNumber'],
    verifyCodeSuccess: ['response'],
    verifyCodeFailure: ['error'],

    createKitRequest: ['data'],
    createKitSuccess: ['response'],
    createKitFailure: ['error'],

    setRejectedState: ['phoneNumber', 'hasAcceptedNeximoFounders', 'hasAcceptedMlm'],
    setPendingValidationState: [],

    showNotice: [],
    nextStep: [],
    previousStep: [],
    updateStep: ['step'],

    updateTos: ['tosName'],

    updatePhoneNumber: ['phoneNumber'],

    clear: []
  },
  { prefix: 'BROKER_INFO_' }
)

const genericAction = (stateKey, actionType) => (state, action) => {
  let newState = {}
  if (actionType === 'request') {
    newState = {
      ...INITIAL_REQUEST_STATE,
      loading: true,
      request: action
    }
  } else if (actionType === 'success') {
    const sk = stateKey === 'sendCode' ? 'codeSent' : 'verifiedPhone'
    const tries = stateKey === 'sendCode' ? { tries: state.sendCode.tries + 1 } : {}
    newState = {
      loading: false,
      response: action.response,
      [sk]: [200, 201].includes(action.response.status),
      ...tries
    }
  }
  if (actionType === 'failure') {
    newState = {
      loading: false,
      error: action.error
    }
  }
  if (stateKey === 'sendCode') {
    return {
      ...state,
      [stateKey]: {
        ...state[stateKey],
        ...newState
      },
      verifyCode: INITIAL_STATE.verifyCode
    }
  }
  return {
    ...state,
    [stateKey]: {
      ...state[stateKey],
      ...newState
    }
  }
}

const sendCodeRequest = genericAction('sendCode', 'request')
const sendCodeSuccess = genericAction('sendCode', 'success')
const sendCodeFailure = genericAction('sendCode', 'failure')

const verifyCodeRequest = genericAction('verifyCode', 'request')
const verifyCodeSuccess = genericAction('verifyCode', 'success')
const verifyCodeFailure = genericAction('verifyCode', 'failure')

const getMailboxOptionsSuccess = (state, { mailboxOptions }) => ({
  ...state,
  mailboxOptions
})

const createKitRequest = (state, { data }) => ({
  ...state,
  kit: {
    ...INITIAL_REQUEST_STATE,
    loading: true
  },
})
const createKitSuccess = (state, { response }) => ({
  ...state,
  kit: {
    ...state.kit,
    loading: false,
    response
  }
})
const createKitFailure = (state, { error }) => ({
  ...state,
  kit: {
    ...state.kit,
    loading: false,
    error
  }
})

export const setRejectedState = (state, { phoneNumber, hasAcceptedNeximoFounders, hasAcceptedMlm }) => ({
  ...state,
  phoneNumber,
  tos: {
    tos: true,
    ethicsCode: true,
    tosMlm: true,
    mlm: hasAcceptedMlm,
    neximoFounders: hasAcceptedNeximoFounders,
  },
  ui: {
    currentStep: 'profile'
  }
})

export const setPendingValidationState = (state) => ({
  ...state,
  ui: {
    ...state.ui,
    currentStep: 'success'
  }
})

const updateStep = (state, { step }) => ({
  ...state,
  ui: {
    ...state.ui,
    currentStep: step
  }
})

const showNotice = (state) => ({
  ...state,
  ui: {
    ...state.ui,
    noticeShown: true
  }
})

const updateSteps = type => state => {
  const currentIndex = STEPS.findIndex(s => s === state.ui.currentStep)
  const nextStep =
    type === 'next' ? STEPS[currentIndex + 1] : STEPS[currentIndex - 1]
  return updateStep(state, { step: nextStep })
}

const updateTos = (state, { tosName }) => ({
  ...state,
  tos: {
    ...state.tos,
    [tosName]: !state.tos[tosName]
  }
})

const updatePhoneNumber = (state, { phoneNumber }) => ({
  ...state,
  phoneNumber
})

export const clear = () => INITIAL_STATE

const HANDLERS = {
  [Types.GET_MAILBOX_OPTIONS_SUCCESS]: getMailboxOptionsSuccess,

  [Types.SEND_CODE_REQUEST]: sendCodeRequest,
  [Types.SEND_CODE_SUCCESS]: sendCodeSuccess,
  [Types.SEND_CODE_FAILURE]: sendCodeFailure,

  [Types.VERIFY_CODE_REQUEST]: verifyCodeRequest,
  [Types.VERIFY_CODE_SUCCESS]: verifyCodeSuccess,
  [Types.VERIFY_CODE_FAILURE]: verifyCodeFailure,

  [Types.CREATE_KIT_REQUEST]: createKitRequest,
  [Types.CREATE_KIT_SUCCESS]: createKitSuccess,
  [Types.CREATE_KIT_FAILURE]: createKitFailure,

  [Types.SET_REJECTED_STATE]: setRejectedState,
  [Types.SET_PENDING_VALIDATION_STATE]: setPendingValidationState,

  [Types.NEXT_STEP]: updateSteps('next'),
  [Types.PREVIOUS_STEP]: updateSteps('previous'),
  [Types.UPDATE_STEP]: updateStep,
  [Types.SHOW_NOTICE]: showNotice,

  [Types.UPDATE_TOS]: updateTos,

  [Types.UPDATE_PHONE_NUMBER]: updatePhoneNumber,

  [Types.CLEAR]: clear
}

export const BrokerInfoTypes = Types
export const BrokerInfoActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
