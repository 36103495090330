import { call, put, select, delay } from 'redux-saga/effects'
import { BinAlertsActionCreators } from 'app/reducers/BinAlertsRedux'
import api from 'app/services/api'
import Router from 'next/router'

export function* getAlerts({ params = {} }) {
  const token = yield select(state => state.auth.token)

  const { status, ...restParams } = params

  const newParams = { ...restParams }

  if (status === 'EXPIRED') {
    newParams.expired = 'True'
  } else if (status) {
    newParams.status = status
  }

  if (newParams.status === 'ACTIVE') {
    newParams.expired = 'False'
  }

  const response = yield call(api.binAlerts.getAlerts, token, newParams)

  if (response.status === 200) {
    return yield put(BinAlertsActionCreators.getAlertsSuccess(response.data))
  }

  yield put(BinAlertsActionCreators.getAlertsFailure(response.data))
}

export function* createAlert({ data }) {
  const token = yield select(state => state.auth.token)

  const response = yield call(api.binAlerts.createAlert, token, data)

  if (response.status === 201) {
    yield put(BinAlertsActionCreators.createAlertSuccess(response.data))
    yield delay(1500)
    return yield put(BinAlertsActionCreators.finishedCreateAlert())
  }

  yield put(BinAlertsActionCreators.createAlertFailure(response.data))
}

export function* changeStatusAlert({ alertStatus, id }) {
  const token = yield select(state => state.auth.token)

  const data = {
    status: alertStatus
  }

  if (alertStatus === 'ACTIVE') {
    data.lifespan = 15
  }

  const response = yield call(api.binAlerts.updateAlert, token, id, data)

  if (response.status === 200) {
    yield put(BinAlertsActionCreators.changeStatusAlertSuccess(response.data))
    yield delay(1500)
    yield put(BinAlertsActionCreators.finishedEditAlert())
    Router.push('/dashboard/bin/alerts')
    yield put(BinAlertsActionCreators.getAlerts())
    return
  }

  yield put(BinAlertsActionCreators.changeStatusAlertFailure(response.data))
}
