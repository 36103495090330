import styles from './styles'

const Breadcrumbs = () => (
  <ul className="breadcrumbs">
    <li>Inicio</li>
    <li>Casas en Renta</li>
    <style jsx>{styles}</style>
  </ul>
)

export default Breadcrumbs
