import ApiBase from 'app/services/api/entities/Base'

function* allPlans() {
  return yield ApiBase.privateGet('/v1/highlights/plans_configuration/')
}

function* allPropertyOrders(propertyId) {
  return yield ApiBase.privateGet(`/v1/highlights/properties/${propertyId}/`)
}

function* confirmOrder(data) {
  return yield ApiBase.privatePost('/v1/highlights/orders/', { data })
}

function* purchaseOrder(highlightOrderId) {
  return yield ApiBase.privateGet(
    `/v1/highlights/orders/${highlightOrderId}/`,
  )
}

function* createPurchaseOrder(highlightOrderId) {
  return yield ApiBase.privatePost(
    `/v1/highlights/orders/${highlightOrderId}/sessions/`,
  )
}

function* checkPropertiesToHighlightStatus(propertiesId) {
  const data = {"properties": propertiesId}
  return yield ApiBase.privatePost('/v1/highlights/check_properties/', { data })
}

export default {
  allPlans,
  allPropertyOrders,
  confirmOrder,
  purchaseOrder,
  createPurchaseOrder,
  checkPropertiesToHighlightStatus
}
