import config from 'config'
import colors from "./colors"

const COMPANY_THEME = config('COMPANY_THEME')

const designatedColorsNeximo = {
    titlesGrayToOrange: colors.outlineFrame,
    generalBackground: colors.lightGray,
    grayToBlackText: colors.gray,
    cardBackgroundLightIntoDarkGray: colors.lightGray,
    partnersCellBackground: colors.celestialBlue,
    lightTextToColor: colors.lightText,
    cardGray: colors.lightGray,
    grayToBlueBackround: colors.lightGray,
    lightGrayToLightBlue: colors.lightGray,
    checkoutformCreditCard: colors.lightGray,
}
const designatedColorsIAD = {
    titlesGrayToOrange: colors.primary,
    generalBackground: colors.tertiary,
    grayToBlackText: colors.black,
    cardBackgroundLightIntoDarkGray: colors.border,
    partnersCellBackground: colors.articBlue,
    lightTextToColor: colors.secondary,
    cardGray: colors.outlineFrame,
    grayToBlueBackround: colors.lightBlueBG,
    lightGrayToLightBlue: colors.lightBg,
    checkoutformCreditCard: colors.text,
}

export default COMPANY_THEME === "NEXIMO" ? designatedColorsNeximo : designatedColorsIAD
