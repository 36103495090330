import config from 'config'

const cdnOrigin = config('CDN_ORIGIN')


const resolvePath = type => (...source) => {
  const path = source.join('/')
  return `${cdnOrigin}/${type}/${path}`.replace(/([^:]\/)\/+/g, '$1');
}


export const image = resolvePath('img')
export const css = resolvePath('css')
export const tos = resolvePath('tos')
