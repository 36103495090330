import { createReducer, createActions } from 'reduxsauce'
import { failureFeedback, initialFeedback, requestFeedback, successFeedback } from './utils'

const INITIAL_STATE = {
  ALL: {
    count: 0,
    params: {},
    properties: [],
    feedback: initialFeedback(),
  },
  PUBLISHED: {
    count: 0,
    params: {},
    properties: [],
    feedback: initialFeedback(),
  },
  CLAIMED: {
    count: 0,
    params: {},
    properties: [],
    feedback: initialFeedback(),
  },
  ARCHIVED: {
    count: 0,
    params: {},
    properties: [],
    feedback: initialFeedback(),
  }
}

const { Types, Creators } = createActions(
  {
    getProperties: ['brokerId', 'params'],
    getPropertiesSuccess: ['params', 'properties', 'count'],
    getPropertiesFailure: ['errors', 'params']
  },
  { prefix: 'NEXIMER_PROPERTIES_' }
)

const getProperties =(state, { params }) => {
  const currentStatus = params?.status || "ALL"
  const copyState = {...state}
  copyState[currentStatus] = {
    ...copyState[currentStatus],
    params,
    feedback: requestFeedback()
  }
  return copyState
}

const getPropertiesSuccess = (state, { params, properties, count }) => {
  const currentStatus = params?.status || "ALL"
  const copyState = {...state}
  copyState[currentStatus] = {
    ...copyState[currentStatus],
    count,
    properties,
    feedback: successFeedback()
  }
  return copyState
}

const getPropertiesFailure = (state, { params, errors }) => {
  const currentStatus = params?.status || "ALL"
  const copyState = {...state}
  copyState[currentStatus] = {
    ...copyState[currentStatus],
    feedback: failureFeedback(errors)
  }
  return copyState
}
const HANDLERS = {
  [Types.GET_PROPERTIES]: getProperties,
  [Types.GET_PROPERTIES_SUCCESS]: getPropertiesSuccess,
  [Types.GET_PROPERTIES_FAILURE]: getPropertiesFailure
}

export const NeximerPropertyActionTypes = Types
export const NeximerPropertyActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
