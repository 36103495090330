import { REHYDRATE } from 'redux-persist'
import { createReducer, createActions } from 'reduxsauce'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback
} from './utils'

const INITIAL_STATE = {
  rehydrated: false,
  config: {},
  dashboard: {
    bannerSlides: [],
    feedback: initialFeedback(),
  },
}

const { Types, Creators } = createActions(
  {
    getConfigRequest: [],
    getConfigSuccess: ['config'],
    getConfigFailure: ['error'],

    dashboardRequest: [],
    dashboardSuccess: ['config'],
    dashboardFailure: ['error'],

  },
  { prefix: 'APP_' }
)

export const rehydrationComplete = state => ({
  ...state,
  rehydrated: true
})

export const getConfigSuccess = (state, { config }) => ({
  ...state,
  config
})

export const getConfigFailure = (state, { error }) => ({
  ...state,
  config: error
})

export const dashboardRequest = state => ({
  ...state,
  dashboard: {
    ...state.dashboard,
    feedback: requestFeedback()
  }
})

export const dashboardSuccess = (state, { config }) => ({
  ...state,
  dashboard: {
    ...state.dashboard,
    bannerSlides: config.bannerSlides,
    feedback: successFeedback()
  }
})

export const dashboardFailure = (state, { error }) => ({
  ...state,
  dashboard: {
    ...state.dashboard,
    feedback: failureFeedback(error)
  }
})

const HANDLERS = {
  [REHYDRATE]: rehydrationComplete,
  [Types.GET_CONFIG_SUCCESS]: getConfigSuccess,
  [Types.GET_CONFIG_FAILURE]: getConfigFailure,

  [Types.DASHBOARD_REQUEST]: dashboardRequest,
  [Types.DASHBOARD_SUCCESS]: dashboardSuccess,
  [Types.DASHBOARD_FAILURE]: dashboardFailure,
}

export const AppActionTypes = Types
export const AppActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
