import { call, put, select, take, delay } from 'redux-saga/effects'
import { NotificationActionCreators } from 'app/reducers/NotificationRedux'
import api from 'app/services/api'
import { REHYDRATE } from 'redux-persist'
import {getSessionData} from 'app/selectors'

export function* getNotifications() {
  const token = yield select(state => state.auth.token)
  const response = yield call(api.notification.getNotifications, token)

  if (response.status === 200) {
    return yield put(
      NotificationActionCreators.getNotificationsSuccess(response.data)
    )
  }

  yield put(NotificationActionCreators.getNotificationsFailure(response.data))
}

export function* checkForNotification() {
  yield take(REHYDRATE)

  while (true) {
    yield delay(15000)
    const sessionData = yield select(getSessionData)

    if (sessionData.isAuthenticated) {
      // yield put(NotificationActionCreators.getNotificationsRequest())
    }
  }
}
