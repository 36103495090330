import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {}

const { Types, Creators } = createActions(
  {
    sendGTEvent: ['section', 'action', 'extras'],
    sendContactJourneyEvent: ['section', 'action', 'extras'],
  },
  { prefix: 'SEND_CONTACT_JOURNEY_EVENT_' }
)

const HANDLERS = {}

export const ContactJourneyTypes = Types
export const ContactJourneyCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
