export const OPERATION_TYPE_BY_CONTRACT = {
  CONTRACT_OF_SALE: 'SELL',
  CONTRACT_LEASE: 'RENT',
  SALE_OFFER_LETTER: 'SELL',
  LEASE_OFFER_LETTER: 'RENT',
}

export const CONTRACT_STATUS = {
  DRAFT: 'DRAFT',
  DONE: 'DONE',
  PENDING_SIGNATURE: 'PENDING_SIGNATURE',
  SIGNED: 'SIGNED',
}

export const CONTRACT_STATUS_COLORS = {
  DRAFT: 'warning',
  DONE: 'primary',
  PENDING_SIGNATURE: 'secondary',
  SIGNED: 'info',
}

export const CONTRACT_LABEL_STATUS = {
  [CONTRACT_STATUS.DRAFT]: 'Draft',
  [CONTRACT_STATUS.DONE]: 'Creado',
  [CONTRACT_STATUS.PENDING_SIGNATURE]: 'Pendiente de firma',
  [CONTRACT_STATUS.SIGNED]: 'Firmado',
}

export const CONTRACT_TYPES = {
  CONTRACT_OF_SALE: 'CONTRACT_OF_SALE',
  CONTRACT_LEASE: 'CONTRACT_LEASE',
  SALE_OFFER_LETTER: 'SALE_OFFER_LETTER',
  LEASE_OFFER_LETTER: 'LEASE_OFFER_LETTER',
  SALE_CUSTOMER_REGISTRATION: 'SALE_CUSTOMER_REGISTRATION',
  LEASE_CUSTOMER_REGISTRATION: 'LEASE_CUSTOMER_REGISTRATION',
};

export const INTERMEDIATION_CONTRACTS = [
  CONTRACT_TYPES.CONTRACT_OF_SALE,
  CONTRACT_TYPES.CONTRACT_LEASE,
];

export const OFFER_LETTER_CONTRACTS = [
  CONTRACT_TYPES.SALE_OFFER_LETTER,
  CONTRACT_TYPES.LEASE_OFFER_LETTER,
];

export const CUSTOMER_REGISTRATION_CONTRACTS = [
  CONTRACT_TYPES.SALE_CUSTOMER_REGISTRATION,
  CONTRACT_TYPES.LEASE_CUSTOMER_REGISTRATION,
];

export function isIntermediationContract(contractType) {
  return INTERMEDIATION_CONTRACTS.includes(contractType)
}

export function isOfferLetterContract(contractType) {
  return OFFER_LETTER_CONTRACTS.includes(contractType)
}

export function isCustomerRegistrationContract(contractType) {
  return CUSTOMER_REGISTRATION_CONTRACTS.includes(contractType)
}
