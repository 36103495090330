import axios from 'axios'
import axiosMiddleware from 'redux-axios-middleware'
import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createWrapper } from "next-redux-wrapper";
import logger from 'redux-logger'
import rootReducer from 'app/reducers'
import rootSaga from 'app/sagas'
import persistConfig from './persistConfig'
import config from 'config'
import qs from 'qs'

export const apiBaseUrl = config('API_BASE_URL')

const isDev = config('NODE_ENV') !== 'production'

const middlewareConfig = {
  interceptors: {
    request: [
      function({ getState, dispatch, getSourceAction }, req) {
        const state = getState()
        const token = state.auth ? state.auth.token : ''
        const authHeader = token ? { Authorization: `JWT ${token}` } : {}
        req.headers.common = { ...req.headers.common, ...authHeader }
        return req
      }
    ],
    response: [
      function({ getState, dispatch, getSourceAction }, res) {
        return res
      }
    ]
  }
}

const client = axios.create({
  //all axios can be used, shown in axios documentation
  baseURL: apiBaseUrl,
  paramsSerializer: function(params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }
})


// BINDING MIDDLEWARE
const bindMiddleware = (middleware) => {
  if (isDev) {
    const { composeWithDevTools } = require("redux-devtools-extension")
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}


export const makeStore = (context) => {
  const sagaMiddleware = createSagaMiddleware()
  const isServer = typeof window === 'undefined'
  let middleware = [
    axiosMiddleware(client, middlewareConfig),
    sagaMiddleware
  ]

  if (!isServer && isDev) {
    middleware.push(logger)
  }

  let store = null

  if (isServer) {
    store = createStore(
      rootReducer,
      undefined,
      bindMiddleware(middleware)
    )
  } else {
    const { persistStore, persistReducer } = require("redux-persist")
    const persistedReducer = persistReducer(persistConfig, rootReducer)

    store = createStore(
      persistedReducer,
      undefined,
      bindMiddleware(middleware)
    )

    store.__persistor = persistStore(store)
  }

  store.sagaTask = sagaMiddleware.run(rootSaga)

  return store
}

export const wrapper = createWrapper(makeStore, { debug: false })