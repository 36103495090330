import { put } from 'redux-saga/effects'
import { UserOnboardingActionCreators } from 'app/reducers/UserOnboardingRedux'
import { UserOnboarding } from 'services/api/entities'

export function* status() {
  const response = yield UserOnboarding.getStatus()
  if (response.success()) {
    const { steps, isAssistedRegistration, isActivated } = response.data
    yield put(
      UserOnboardingActionCreators.statusSuccess(steps, isAssistedRegistration, isActivated),
    )
  } else {
    yield put(UserOnboardingActionCreators.statusFailure(response.data))
  }
}

export function* contracts({ brokerId }) {
  const response = yield UserOnboarding.getContracts(brokerId)
  if (response.success()) {
    const documents = response.data
    yield put(UserOnboardingActionCreators.contractsSuccess(documents))
  } else {
    yield put(UserOnboardingActionCreators.contractsFailure(response.data))
  }
}
