import crudAction from './actions'

export default {
  name: 'broker',
  namespace: 'brokers',
  methods: [
    {
      name: 'publicFind',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/public/${namespace}/${id}/`,
      urlParams: [ 'id' ]
    },
    {
      name: 'publicFilter',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, }) => `/v1/public/${namespace}/`,
      urlParams: []
    },
    {
      name: 'publicJoinTeam',
      params: ['id', 'data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/public/${namespace}/${id}/join_team/`,
      urlParams: ['id']
    }
  ]
}