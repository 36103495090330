import models from '../models'
import createReduxModel from './createReduxModel'

const reducers = Object.keys(models)
  .map((m) => ({ [models[m].name]: createReduxModel(models[m]) }))
  .reduce((acc, value) => ({ ...acc, ...value }))

export const INITIAL_STATE = Object.keys(reducers).map(
  (reducerName) => reducers[reducerName].INITIAL_STATE
).reduce((acc, value) => [...acc, value], [])

export const storeRedux = Object.keys(reducers)
  .map((reducerName) => ({ [`store_${reducerName}`]: reducers[reducerName].Reducer }))
  .reduce((acc, value) => ({ ...acc, ...value }))

export default reducers;
