import css from 'styled-jsx/css'
import { colors, measurements } from 'theme'

export default css`
  .selected-element,
  .not-selected-element {
    border: 0;
    padding: 0;
    background: inherit;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s;
  }
  .selected-element {
    border: 1px solid ${colors.primary};
    border-radius: ${measurements.borderRadius};
    position: relative;
    transition: all 0.2s;
    height: 100%;
  }
  .selected-element:active,
  .not-selected-element:active {
    transform: scale(0.9);
  }

  .selected-element :global(i.check-icon) {
    background-color: ${colors.primary};
    border-radius: 50%;
    color: ${colors.white};
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
  }
  .selected-element :global(i.check-icon::before) {
    width: 1.1rem;
  }
  .selected-element.fit,
  .not-selected-element.fit {
    width: fit-content;
  }

  .not-selected-element {
    border: 1px solid transparent;
  }
`
