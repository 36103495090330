import { put } from 'redux-saga/effects'

import { IA } from 'services/api/entities'
import { IAActionCreators } from 'reducers/IARedux'

export function* propertyDescription({ propertyId, description }) {
  const response = yield IA.propertyDescription({ propertyId, description })

  if (response.success()) {
    return yield put(
      IAActionCreators.propertyDescriptionSuccess(response.data.description),
    )
  }

  yield put(IAActionCreators.propertyDescriptionFailure(response.data))
}
