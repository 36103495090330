import ApiBase from 'app/services/api/entities/Base'

function*  getUserPrivateDetail(userId) {
  return yield ApiBase.privateGet(`/v1/crm/user_private_detail/${userId}/`)
}

function*  getUserPublicAreas( params) {
  return yield ApiBase.privateGet('/v1/public/areas/', {
    params 
  })
}

function* agent(userId) {
  return yield ApiBase.privateGet(`/v1/users/${userId}/agent/`)
}

export default {
  getUserPublicAreas,
  getUserPrivateDetail,
  agent,
}
