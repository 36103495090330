import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import GeoSuggest from 'react-geosuggest'

import { getGoogleMapsData, getLocationRequest } from 'selectors'
import { getNeximoAddress, mapResultToAddress } from 'services/google'
import { GeoActionCreators } from 'app/reducers/GeoRedux'

import geoSuggest from './styles'

class GeoSuggestNeximo extends Component {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    searchFullLocation: PropTypes.bool,
    style: PropTypes.object
  }

  static defaultProps = {
    className: '',
    searchFullLocation: false,
    style: {}
  }

  state = { suggest: null }

  UNSAFE_componentWillReceiveProps(nextProps, _) {
    const { geoRequest: currentGeoRequest, onSuggestSelect } = this.props
    const { geoRequest } = nextProps
    const { suggest } = this.state
    if (
      currentGeoRequest.loading &&
      geoRequest.finished &&
      !geoRequest.error &&
      nextProps.locationData
    ) {
      onSuggestSelect(suggest, nextProps.locationData)
    } else if (
      currentGeoRequest.loading &&
      geoRequest.finished &&
      (geoRequest.error || !nextProps.locationData)
    ) {
      onSuggestSelect(null)
    }
  }

  handleSuggestSelect = suggest => {
    this.setState({ suggest })
    const { searchFullLocation, name, onSuggestSelect, actions } = this.props

    if (!suggest || !suggest.location) {
      actions.clearData(name)
      onSuggestSelect(null)
      return
    }

    if (searchFullLocation) {
      actions.getAddressDetailedInfo(name, suggest.gmaps)
    } else {
      onSuggestSelect(
        suggest,
        getNeximoAddress(mapResultToAddress(suggest.gmaps))
      )
    }
  }

  render() {
    const { className, setRef, style, searchFullLocation, ...rest } = this.props
    return (
      <div className={classnames('geo-container', className)}>
        <GeoSuggest
          ref={setRef}
          queryDelay={1000}
          minLength={10}
          autoComplete="off"
          {...rest}
          style={{ suggestItem: { textAlign: 'left' }, ...style }}
          onSuggestSelect={this.handleSuggestSelect}
        />
        <style jsx global>
          {geoSuggest}
        </style>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  geoRequest: getLocationRequest(ownProps.name)(state),
  locationData: getGoogleMapsData(ownProps.name)(state)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getAddressDetailedInfo: GeoActionCreators.getFullLocationRequest,
      clearData: GeoActionCreators.clear
    },
    dispatch
  )
})

const ConnectedGeoSuggest = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeoSuggestNeximo)

export { GeoSuggestNeximo as default, ConnectedGeoSuggest }
