import colors from 'theme/colors'
import css from 'styled-jsx/css'

export default css`
  .button.danger.outline {
    border-color: ${colors.candyRed};

    transition: 100ms background-color, 100ms border-color;
  }

  .button.danger.outline {
    color: ${colors.candyRed};

    transition: 100ms color;
  }

  .button:not(.disabled):hover.danger.outline, .button:not(.disabled):focus.danger.outline {
    background-color: ${colors.candyRed};
    color: ${colors.white};

    transition: 100ms background-color, 100ms color;
  }
`