import ApiBase from 'app/services/api/entities/Base'

function* checkPermission(permission) {
  return yield ApiBase.privateGet(
    `/v1/contract_version/permissions/${permission}/`
  )
}

function* getUrlByContract(params = {}) {
  return yield ApiBase.privateGet(
    'v1/digitalsignature/signature/user_document_signature/', {
    params
  })
}
export default {
  checkPermission,
  getUrlByContract,
}
