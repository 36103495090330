import { call, take, put, select, cancelled } from 'redux-saga/effects'
import { DirectoryActionCreators } from 'app/reducers/DirectoryRedux'
import api from 'app/services/api'

export function* getContacts({ page, pageSize }) {
  const token = yield select(state => state.auth.token)

  const offset = !isNaN(page) ? page * pageSize : 0
  let response = yield call(api.directory.getContacts, token, pageSize, offset)

  if (response.status === 200) {
    const { count } = response.data
    const totalPages = Math.ceil(count / pageSize)

    if (totalPages < page) {
      response = yield call(api.directory.getContacts, token, pageSize, 0)
      return yield put(
        DirectoryActionCreators.getContactsSuccess(0, response.data)
      )
    }

    return yield put(
      DirectoryActionCreators.getContactsSuccess(page, response.data)
    )
  }

  yield put(DirectoryActionCreators.getContactsFailure(response.data))
}
