import crudAction from './actions'

export default {
  name: 'brokerValuation',
  namespace: 'broker_valuations',
  methods: [
    ...crudAction
  ]
}

