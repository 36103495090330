import { call, put, select } from 'redux-saga/effects'
import Router from 'next/router'
import { PropertyDocumentationActionCreators } from 'app/reducers/PropertyDocumentationRedux'
import api from 'app/services/api/api'

export function* getPropertyToDocumentation({ propertyId }) {
  const token = yield select(state => state.auth.token)

  const response = yield call(
    api.privateGet,
    `/v1/properties/${propertyId}/get_documentation/`,
    { token }
  )

  if(response.status === 200) {
    return yield put(PropertyDocumentationActionCreators.getPropertyToDocumentationSuccess(response.data))
  }
  yield put(PropertyDocumentationActionCreators.getPropertyToDocumentationFailure(response.data))
}

export function* saveDocumentation({ data, pk }) {
  const token = yield select(state => state.auth.token)

  const response = yield call(
    api.privatePost,
    `/v1/properties/${pk}/documentation/`,
    { token, data }
  )

  if (response.status === 201) {
    yield put(PropertyDocumentationActionCreators.saveDocumentationSuccess())

    yield put(PropertyDocumentationActionCreators.reset())

    Router.push('/dashboard/property-management')
    return
  }

  yield put(PropertyDocumentationActionCreators.saveDocumentationFailure())
}

export function* registerNewFile({ propertySnapshot, newDocument, registerDocumentType, fileName }) {
  const token = yield select(state => state.auth.token)

  yield put(PropertyDocumentationActionCreators.updateFileStatus({ fileName }))

  let newProperty = { ...propertySnapshot }
  let response = {}

  if (registerDocumentType === 'document') {
    const { documentTypeId, tag, highlightRequired } = newDocument.extraData || {}

    const data = {
      tag,
      highlightRequired,
      documentType: documentTypeId,
      propertyId: newProperty.id
    }

    response = yield call(
      api.privatePost,
      "/v1/crm/deal_document/",
      { token, data }
    )

    if (response.status === 201) {
      newProperty.documents = [
        ...newProperty.documents,
        { ...newDocument.extraData, ...response.data }
      ]
    }
  }

  if (response.status === 201) {
    yield put(PropertyDocumentationActionCreators.updateFileStatusSuccess({ fileName }))

    return yield put(PropertyDocumentationActionCreators.updatePropertyDetail(newProperty))
  }

  yield put(PropertyDocumentationActionCreators.updateFileStatusFailure({ fileName }))
}

export function* deleteDocument({ propertySnapshot, document, registerDocumentType, fileName }) {
  const token = yield select(state => state.auth.token)

  yield put(PropertyDocumentationActionCreators.updateFileStatus({ fileName }))

  let newProperty = { ...propertySnapshot }
  let response = {}

  if (registerDocumentType === 'document') {
    response = yield call(
      api.privateDelete,
      `/v1/crm/deal_document/${document.id}/`,
      { token }
    )

    if (response.status === 204) {
      newProperty.documents = propertySnapshot.documents.filter(({ id }) => id !== document.id)
    }
  }

  if (response.status === 200 || response.status === 204) {
    yield put(
      PropertyDocumentationActionCreators.updateFileStatusSuccess({ fileName })
    )

    return yield put(
      PropertyDocumentationActionCreators.updatePropertyDetail(newProperty)
    )
  }

  PropertyDocumentationActionCreators.updateFileStatusFailure({ fileName })
}
