export default {
  name: 'helpDeskAttachment',
  namespace: 'attachments',
  methods: [
    {
      name: 'create',
      params: ['data'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace }) => `/v1/helpscout/${namespace}/`,
      urlParams: [],
      seq: true // If we need one after another
    }
  ]
}
