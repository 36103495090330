import {
  call,
  take,
  put,
  race,
  select,
} from 'redux-saga/effects'
import { BrokerInfoActionCreators } from 'app/reducers/BrokerInfoRedux'
import { AlertActionCreators } from 'app/reducers/AlertRedux'
import {
  FileUploadActionCreators,
  FileUploadActionTypes
} from 'app/reducers/FileUploadRedux'
import api from 'app/services/api'
import { store } from 'services'
import { getTokenFields } from 'selectors'
import { PLANS } from 'services/config'
import { getUserPlans } from 'app/selectors'
import { companyConfig } from 'globalConfigApp'

export function* sendCode({ phoneNumber }) {
  const token = yield select(state => state.auth.token)
  const response = yield call(api.auth.sendCode, token, phoneNumber)

  if (response.status === 201) {
    yield put(BrokerInfoActionCreators.sendCodeSuccess(response.data))
    yield put(BrokerInfoActionCreators.updateStep('verify'))
  } else {
    yield put(BrokerInfoActionCreators.sendCodeFailure(response.data))
  }
}

export function* verifyCode({ code, phoneNumber }) {
  const token = yield select(state => state.auth.token)
  const response = yield call(api.auth.verifyCode, token, code, phoneNumber)

  if (response.status === 200) {
    yield put(BrokerInfoActionCreators.verifyCodeSuccess(response.data))
    yield put(BrokerInfoActionCreators.updateStep('profile'))
  } else {
    yield put(BrokerInfoActionCreators.verifyCodeFailure(response.data))
  }
}

export function* getMailboxOptionsRequest() {
  yield put(
    store.availableMailboxes('user').action()
  )

  const { errorOptions, successOptions } = yield race({
    errorOptions: take(store.availableMailboxes('user').types.failure),
    successOptions: take(store.availableMailboxes('user').types.success)
  })

  let availableMailboxes = []

  if (successOptions && successOptions.response) {
    availableMailboxes = successOptions.response
  }

  yield put(BrokerInfoActionCreators.getMailboxOptionsSuccess(availableMailboxes))
}


export function* createKitRequest({ data }) {
  const { mailbox, ...kitDataWithSvgs } = data
  const { billboardDataRent, billboardDataSell, cardData, ...kitData } = kitDataWithSvgs
  const userPlans = yield select(getUserPlans)
  const { user_id, username } = yield select(getTokenFields)

  yield put(
    FileUploadActionCreators.request(
      'billboard_rent.svg',
      new File(
        [new Blob([billboardDataRent], { type: 'image/svg+xml' })],
        'billboard_rent.svg'
      ),
      '/v1/users/presigned_billboard/',
      false
    )
  )
  const { url: billboardRentUrl } = yield take(FileUploadActionTypes.SUCCESS)

  yield put(
    FileUploadActionCreators.request(
      'billboard_sell.svg',
      new File(
        [new Blob([billboardDataSell], { type: 'image/svg+xml' })],
        'billboard_sell.svg'
      ),
      '/v1/users/presigned_billboard/',
      false
    )
  )
  const { url: billboardSellUrl } = yield take(FileUploadActionTypes.SUCCESS)

  let cardUrl = ''
  if (userPlans.includes(PLANS.FULL_SERVICE) || userPlans.includes(PLANS.IN_HOUSE_FULL)) {
    yield put(
      FileUploadActionCreators.request(
        'front.svg',
        new File([new Blob([cardData], { type: 'image/svg+xml' })], 'front.svg'),
        '/v1/users/presigned_card/',
        false
      )
    )

    const { url: cardUrlSuccess } = yield take(FileUploadActionTypes.SUCCESS)
    cardUrl = cardUrlSuccess
  }

  yield put(
    store.create('brokerKitData').action({
      ...kitData,
      broker: user_id,
      billboardRentUrl,
      billboardSellUrl,
      cardUrl,
      mailbox
    })
  )

  const { error, success } = yield race({
    error: take(store.create('brokerKitData').types.failure),
    success: take(store.create('brokerKitData').types.success)
  })

  if (success) {
    if ((
      userPlans.includes(PLANS.FULL_SERVICE) ||
      userPlans.includes(PLANS.IN_HOUSE_FULL) ||
      userPlans.includes(PLANS.AFFILIATE) ||
      userPlans.includes(PLANS.IN_HOUSE_RENT) ||
      userPlans.includes(PLANS.IN_HOUSE_SELL) ||
      userPlans.includes(PLANS.IN_HOUSE_ACQUISITION)
    ) && !username.includes(`@${companyConfig.companyUrl}`)) {

      const [chosenMailbox, _] = mailbox.split('@')

      yield put(
        store.createMailbox('user').action({
          chosenMailbox
        })
      )

      const { errorMailbox, successMailbox } = yield race({
        errorMailbox: take(store.createMailbox('user').types.failure),
        successMailbox: take(store.createMailbox('user').types.success)
      })

      if (errorMailbox) {
        yield put(AlertActionCreators.error({ message: 'Error al crear tu mailbox, inténtalo más tarde' }))
        yield put(BrokerInfoActionCreators.createKitFailure(errorMailbox))
        return
      }
    }

    yield put(BrokerInfoActionCreators.createKitSuccess(success))
    return
  }

  yield put(AlertActionCreators.error({ message: 'Error al crear tu kit, inténtalo más tarde' }))
  yield put(BrokerInfoActionCreators.createKitFailure(error))
}
