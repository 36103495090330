import { createReducer, createActions } from 'reduxsauce'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback
} from './utils'

/**
 * @typedef updateMaterialRequest
 *
 * @structure
 * {
 *  [material_id]: {
 *    updateInfo: {done: true, ...},
 *    loading: Boolean,
 *    success: Boolean,
 *    ...
 *  }
 * }
 */

const INITIAL_STATE = {
  materials: [],
  materialsFeedback: initialFeedback(),
  updateMaterialRequest: {}
}

const { Types, Creators } = createActions(
  {
    getAll: ['userId'],
    getAllSuccess: ['materials', 'lastRequestedUpdate'],
    getAllFailure: ['errors'],
    updateMaterial: ['materialId', 'updateInfo'],
    updateMaterialSuccess: ['materialId', 'newMaterial'],
    updateMaterialFailure: ['materialId'],
    cleanState: []
  },
  { prefix: 'ONBOARDING_MATERIALS_' }
)

const getAll = state => ({
  ...state,
  materialsFeedback: requestFeedback()
})
const getAllSuccess = (state, { materials }) => ({
  ...state,
  materials,
  materialsFeedback: successFeedback()
})
const getAllFailure = state => ({
  ...state,
  materialsFeedback: failureFeedback()
})

const updateMaterial = (state, { materialId, updateInfo }) => {
  const materials = state.materials.map(material =>
    material.id === materialId ? { ...material, loading: true } : material
  )

  const newState = { ...state, materials }

  newState.updateMaterialRequest[materialId] = {
    updateInfo,
    ...requestFeedback()
  }

  return newState
}

const updateMaterialSuccess = (state, { materialId, newMaterial }) => {
  const materialRequest = state.updateMaterialRequest[materialId]

  const materials = state.materials.map(material =>
    material.id === materialId
      ? { ...material, ...newMaterial, loading: false }
      : material
  )

  const newState = { ...state, materials }

  newState.updateMaterialRequest[materialId] = {
    ...materialRequest,
    ...successFeedback()
  }

  return newState
}

const updateMaterialFailure = (state, { materialId }) => {
  const materialRequest = state.updateMaterialRequest[materialId]
  const materials = state.materials.map(material =>
    material.id === materialId ? { ...material, loading: false } : material
  )

  const newState = { ...state, materials }

  newState.updateMaterialRequest[materialId] = {
    ...materialRequest,
    ...failureFeedback()
  }

  return newState
}

const cleanState = () => INITIAL_STATE

const HANDLERS = {
  [Types.GET_ALL]: getAll,
  [Types.GET_ALL_SUCCESS]: getAllSuccess,
  [Types.GET_ALL_FAILURE]: getAllFailure,

  [Types.UPDATE_MATERIAL]: updateMaterial,
  [Types.UPDATE_MATERIAL_SUCCESS]: updateMaterialSuccess,
  [Types.UPDATE_MATERIAL_FAILURE]: updateMaterialFailure,

  [Types.CLEAN_STATE]: cleanState
}

export const OnboardingMaterialsTypes = Types
export const OnboardingMaterialsCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
