import { createReducer, createActions } from 'reduxsauce'

const REQUEST_INITIAL_STATE = {
  loading: false,
  finished: false,
  error: null,
  request: null,
  response: null,
  location: null
}

const INITIAL_STATE = {
  locationDetails: {},
  currentLocation: null
}

const { Types, Creators } = createActions({
  getFullLocationRequest: ['name', 'gmaps'],
  getFullLocationSuccess: ['name', 'data', 'location'],
  getFullLocationFailure: ['name', 'error'],
  replaceCurrentLocation: ['newLocation'],
  getLatLngRequest: ['name', 'address'],
  getLatLngSuccess: ['name', 'latLng'],
  getLatLngFailure: ['name', 'error'],

  clear: ['name']

}, { prefix: 'GEO_' })

const getFullLocationRequest = (state, { name, gmaps }) => ({
    ...state,
    locationDetails: {
      ...state.locationDetails,
      [name]: {
        ...REQUEST_INITIAL_STATE,
        loading: true,
        request: { gmaps }
      }
    }
  }
)

const getFullLocationSuccess = (state, { name, data, location }) => ({
    ...state,
    locationDetails: {
      ...state.locationDetails,
      [name]: {
        loading: false,
        finished: true,
        response: data,
        location
      }
    }
  }
)
const getFullLocationFailure = (state, { name, error }) => ({
    ...state,
    locationDetails: {
      ...state.locationDetails,
      [name]: {
        loading: false,
        finished: true,
        error: error
      }
    }
  }
)



const getLatLngRequest = (state, {name, address}) => ({
  ...state,
    locationDetails: {
      ...state.locationDetails,
      [name]: {
        ...REQUEST_INITIAL_STATE,
        loading: true,
        request: { address }
      }
    }
  }
)

const getLatLngSuccess = (state, {name, latLng}) => ({
  ...state,
    locationDetails: {
      ...state.locationDetails,
      [name]: {
        loading: false,
        finish: true,
        error: null,
        response: latLng
      }
    }
  }
)

const getLatLngFailure = (state, {name, error}) => ({
  ...state,
    locationDetails: {
      ...state.locationDetails,
      [name]: {
        ...REQUEST_INITIAL_STATE,
        loading: false,
        finish: true,
        error: error
      }
    }
  }
)

const replaceCurrentLocation = (state, { newLocation: currentLocation }) => ({
    ...state,
    currentLocation
  }
)

export const GeoActionTypes = Types

export const clear = (state, { name }) => ({
  ...state,
  locationDetails: {
    ...state.locationDetails,
    [name]: REQUEST_INITIAL_STATE
  },
})

const HANDLERS = {
  [Types.GET_FULL_LOCATION_REQUEST]: getFullLocationRequest,
  [Types.GET_FULL_LOCATION_SUCCESS]: getFullLocationSuccess,
  [Types.GET_FULL_LOCATION_FAILURE]: getFullLocationFailure,

  [Types.GET_LAT_LNG_REQUEST]: getLatLngRequest,
  [Types.GET_LAT_LNG_SUCCESS]: getLatLngSuccess,
  [Types.GET_LAT_LNG_FAILURE]: getLatLngFailure,


  [Types.REPLACE_CURRENT_LOCATION]: replaceCurrentLocation,

  [Types.CLEAR]: clear

}

export const GeoActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
