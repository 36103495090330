import { buffers, eventChannel, END } from 'redux-saga'
import { call, put, select, take, actionChannel } from 'redux-saga/effects'
import mime from 'mime-types'
import filesOperations from 'services/api/entities/files'
import { apiBaseUrl, defaultHeaders } from 'services/api/api'
import { utils , validators}  from 'services'
import {
  FileUploadActionTypes,
  FileUploadActionCreators
} from 'app/reducers/FileUploadRedux'

function createUploadFileChannel({ baseURL, url, formData, fileName }) {
  return eventChannel(emitter => {
    let lastProgress = 0
    const apiCallConfig = {
      method: 'POST',
      baseURL,
      url: '',
      data: formData,
      onUploadProgress: progressEvent => {
        if (progressEvent.lengthComputable) {
          const progress = Math.floor(
            progressEvent.loaded * 100 / progressEvent.total
          )
          if(progress - lastProgress >= 5) {
            lastProgress = progress
            emitter({ progress })
          }
        }
      }
    }

    filesOperations
      .uploadFile(apiCallConfig)
      .then(response => {
        if (response.status === 204) {
          emitter({ response: 'success' })
        } else {
          emitter({ error: response.data })
        }
        emitter(END)
      })
      .catch(error => {
        console.log(error)
        emitter(FileUploadActionCreators.failure(fileName, error))
        emitter(END)
      })

    return () => {
      console.log('finished the upload stuff')
    }
  }, buffers.expanding(2))
}

export function* uploadRequestWatcher() {
  const fileChannel = yield actionChannel(FileUploadActionTypes.REQUEST, buffers.expanding(10))
  while(true) {
    const action = yield take(fileChannel)
    yield call(uploadFileSaga, action)
  }
}

export function* uploadFileSaga({ fileName, file, s3PresignedUrl, hasExpiration=true, hashPath='', extraData={}}) {
  const token = yield select(state => (state.auth ? state.auth.token : null))

  const s3PresignedResponse = yield call(
    filesOperations.s3_presigned,
    token,
    s3PresignedUrl,
    { hasExpiration, hashPath, ...extraData }
  )

  if (s3PresignedResponse.status === 201) {
    const s3Data = s3PresignedResponse.data

    const baseURL = s3Data.s3.url

    const formData = new FormData()
    Object.keys(s3Data.s3.fields).forEach(formKey => {
      formData.append(formKey, s3Data.s3.fields[formKey])
    })
    const mimeType = mime.contentType(file.type)

    if (!mimeType) {
      yield put(
        FileUploadActionCreators.failure(fileName, {
          message: 'MIME Type not allowed'
        })
      )
    }

    formData.append('Content-Type', mimeType)
    formData.append('file', file)

    const channel = yield call(createUploadFileChannel, {
      baseURL,
      formData,
      fileName
    })
    while (true) {
      const { progress = 0, error, response } = yield take(channel)
      if (error) {
        yield put(FileUploadActionCreators.failure(fileName, error))
        return
      }
      if (!error && response) {
        let fullUrl = utils.urlify(baseURL, s3Data.s3.fields.key).replace('${filename}', fileName)
        if (!validators.validUrl(fullUrl) ){
          fullUrl = utils.urlify(baseURL, s3Data.s3.fields.key).replace('${filename}', encodeURI(fileName))
        }
        yield put(FileUploadActionCreators.success(fileName, response, fullUrl))
        return
      }
      yield put(FileUploadActionCreators.progress(fileName, progress))
    }
  }

  yield put(
    FileUploadActionCreators.failure(fileName, {
      message: 'Unable to get presigned s3 key'
    })
  )
}
