import Router from 'next/router'

import { select, call, put, delay, race, take } from 'redux-saga/effects'

import { BinActionCreators } from 'app/reducers/BinRedux'
import { CRMActionCreators, CRMActionTypes } from 'app/reducers/CRMReducer'
import { mapPropertyAdsFormToIds } from 'app/selectors/crm'
import { store } from 'services'
import api from 'app/services/api/api'
import crm from 'app/services/api/entities/crm'
import config from 'config'

export function* updateAlertProperties({
  isAddedProperties,
  propertyAds,
  alertId
}) {
  const token = yield select(state => state.auth.token)
  const properties = yield select(state => state.bin.data.results)

  const { response: binLeadResponse } = yield select(state =>
    store.find('crmBinLead').selector(state)
  )

  const binLeadId = binLeadResponse.id

  const { propertiesSelected } = mapPropertyAdsFormToIds(
    propertyAds,
    properties
  )

  const selectedAds = propertiesSelected.map(({ id }) => id)

  const payload = {
    token,
    data: { ads: selectedAds }
  }

  const response = isAddedProperties
    ? yield call(
        api.privatePost,
        `/v1/crm/bin_lead_requests/${alertId}/bulk_suggest/`,
        payload
      )
    : yield call(
        api.privatePost,
        `/v1/crm/bin_lead_requests/${alertId}/bulk_reject/`,
        payload
      )

  if (response.status === 200) {
    yield put(CRMActionCreators.updateAlertPropertiesSuccess())
    yield delay(2500)
    yield put(CRMActionCreators.cleanState())
    yield put(
      BinActionCreators.search({
        request: {
          url: '/v1/crm/property_ads/',
          params: { bin_lead_request_id: alertId }
        }
      })
    )
    yield put(store.find('crmBinLead').action(binLeadId))
    return
  }

  yield put(CRMActionCreators.updateAlertPropertiesFailure())
}

export function* acceptLead({ leadId, isAcceptingLead, data }) {
  try {
    const token = yield select(state => state.auth.token)

    const baseUrl = `/v1/crm/bin_leads/${leadId}`
    const url = baseUrl + (isAcceptingLead ? '/accept/' : '/reject/')

    const response = yield call(api.privatePost, url, { data, token })

    if (response.status === 200) {
      return yield put(CRMActionCreators.acceptLeadSuccess(response.data))
    }

    if (response.status === 400) {
      const { message, acceptedAt, rejectedAt, reassignedAt } = response.data
      const dateOfAssignement = acceptedAt || rejectedAt || reassignedAt

      return yield put(
        CRMActionCreators.showActualLeadStatus(message, dateOfAssignement)
      )
    }
    yield put(CRMActionCreators.acceptLeadFailure())
    yield delay(2500)

    Router.push('/dashboard/bin/prospections')
  } catch (err) {
    console.log(err)
  }
}

export function* getPropertyValuation({ propertyId, provider }) {
  const response = yield crm.find(propertyId, provider)

  if (response.success()) {
    return yield put(CRMActionCreators.propertyValuationsSuccess(response.data))
  }
  return yield put(CRMActionCreators.propertyValuationsTryNextProvider())
}

export function* propertyValuations({ id }) {
  const providers = valuationProviderPriority()
  for (const provider of providers) {
    yield put(CRMActionCreators.getPropertyValuation(id, provider))

    const [success, error] = yield race([
      take(CRMActionTypes.PROPERTY_VALUATIONS_SUCCESS),
      take(CRMActionTypes.PROPERTY_VALUATIONS_TRY_NEXT_PROVIDER),
    ])

    if (success) {
      return
    }
  }
  yield put(CRMActionCreators.propertyValuationsFailure())
}

function setValuationProvider(data, provider) {
  return {
    ...data,
    provider,
  }
}

export function* newPropertyValuation({ data, provider }) {
  const response = yield crm.create(setValuationProvider(data, provider))
  if (response.success()) {
    return yield put(
      CRMActionCreators.createPropertyValuationSuccess(response.data),
    )
  }
  return yield put(CRMActionCreators.propertyValuationsTryNextProvider())
}

export function* createPropertyValuation({ data }) {
  const providers = valuationProviderPriority()
  for (const provider of providers) {
    yield put(CRMActionCreators.newPropertyValuation(data, provider))

    const [success, error] = yield race([
      take(CRMActionTypes.CREATE_PROPERTY_VALUATION_SUCCESS),
      take(CRMActionTypes.PROPERTY_VALUATIONS_TRY_NEXT_PROVIDER),
    ])

    if (success) {
      return
    }
  }
  yield put(CRMActionCreators.createPropertyValuationFailure())
}

function valuationProviderPriority() {
  //Returns array of the available valuation services in the order in which these are called
  return [
    config('PROPERTY_VALUATION_PRIMARY_PROVIDER'),
    config('PROPERTY_VALUATION_SECONDARY_PROVIDER'),
  ]
}
