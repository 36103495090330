import { call, put, select } from 'redux-saga/effects'
import { tagManager } from 'services'

import { ContactJourneyCreators } from 'app/reducers/ContactJourneyRedux'

export function* sendGTEvent({ section, action, extras = {} }) {
  try {
    const path = window.location.pathname

    const finalExtras = {
      path,
      ...extras,
    }
    const response = yield call(tagManager.tagEvent, section, action, finalExtras)
  } catch (e) {
    console.error('GTEvent: ', e)
  }
}

export function* sendContactJourneyEvent({ section, action, extras = {} }) {
  try {
    const contactProfile = yield select(state => state.auth.contactProfile)
    
    const newExtras = {
      ...extras,
      extraProps: { ...extras.extraProps, userId: contactProfile.pk },
      pageProps: { ...extras.pageProps, userId: contactProfile.pk }
    }
    
    const response = yield call(tagManager.pageViewEvent, section, action, newExtras)
  } catch(e) {
    console.error('CJ ', e)
  }
}
