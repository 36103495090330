import React from 'react'
import PropTypes from 'prop-types'
import { item } from './styles'

const Item = ({ label }) => (
  <div className="item">
    {label}
    <style jsx>{item}</style>
  </div>
)

Item.propTypes = {
  value: PropTypes.any.isRequired
}

export default Item
