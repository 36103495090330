import ApiBase from 'app/services/api/entities/Base'

const BASE_URL = '/v1/crm/deals/'

function* getAll(params = {}, requestOptions = {}) {
  return yield ApiBase.privateGet(BASE_URL, {
    ...requestOptions,
    params
  })
}

function* find(dealId, requestOptions = {}) {
  return yield ApiBase.privateGet(`${BASE_URL}${dealId}/`, requestOptions)
}

function* closedDeals(params, requestOptions) {
  return yield ApiBase.privateGet("/v1/crm/successfully_closed_deals/", {
    ...requestOptions,
    params
  })
}

function* closedDealsProfit(params, requestOptions) {
  return yield ApiBase.privateGet("/v1/mlm_distribution/author_quotas/profit/", {
    ...requestOptions,
    params
  })
}

function* closedChildDeals(params, requestOptions) {
  return yield ApiBase.privateGet("/v1/crm/mlm_successfully_closed_deals/", {
    ...requestOptions,
    params
  })
}

function* closedChildDealsProfit(params, requestOptions) {
  return yield ApiBase.privateGet("/v1/mlm_distribution/mlm_author_quotas/profit/", {
    ...requestOptions,
    params
  })
}

function* closedChildrenDeals(params = {}, requestOptions = {}) {
  return yield ApiBase.privateGet("/v1/crm/mlm_deals/", {
    ...requestOptions,
    params
  })
}

function* findChildDeal(dealId, requestOptions = {}) {
  return yield ApiBase.privateGet(`/v1/crm/mlm_deals/${dealId}/`, requestOptions)
}

function* detail(dealId, requestOptions = {}) {
  return yield ApiBase.privateGet(`/v1/crm/deals/${dealId}/`, requestOptions)
}

export default {
  getAll,
  find,
  closedDeals,
  closedDealsProfit,
  closedChildrenDeals,
  closedChildDeals,
  closedChildDealsProfit,
  findChildDeal,
  detail,
}
