import { createReducer, createActions } from 'reduxsauce'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback,
} from 'reducers/utils'

const INITIAL_STATE = {
  stats: {
    count: 0,
    stats: [],
    feedback: initialFeedback(),
  },
}

const { Types, Creators } = createActions(
  {
    getStatsRequest: ['params'],
    getStatsSuccess: ['count', 'stats'],
    getStatsFailure: ['errors'],
    cleanState: [],
  },
  { prefix: 'NETWORK_STATS_' },
)

const getStatsRequest = (state, { params }) => ({
  ...state,
  stats: {
    ...state.stats,
    feedback: requestFeedback(),
  },
})

const getStatsSuccess = (state, { count, stats }) => ({
  ...state,
  stats: {
    ...state.stats,
    count,
    stats,
    feedback: successFeedback(),
  },
})

const getStatsFailure = (state, { errors }) => ({
  ...state,
  stats: {
    ...state.stats,
    count: 0,
    stats: [],
    feedback: failureFeedback(errors)
  },
})

const cleanState = () => INITIAL_STATE

const HANDLERS = {
  [Types.GET_STATS_REQUEST]: getStatsRequest,
  [Types.GET_STATS_SUCCESS]: getStatsSuccess,
  [Types.GET_STATS_FAILURE]: getStatsFailure,
  [Types.CLEAN_STATE]: cleanState,
}
export const NetworkStatsActionTypes = Types
export const NetworkStatsActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
