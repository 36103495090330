import { GeoActionCreators } from 'app/reducers/GeoRedux'
import { call, put, take, all, race, select } from 'redux-saga/effects'

import api from 'app/services/api'
import { store } from 'services'
import { getNeximoAddress, mapResultToAddress } from 'services/google'
import { AlertActionCreators } from 'app/reducers/AlertRedux'

export function* loadAddressOptions({ postalCode }) {
  yield put(store.postalCodes('locationService').action(postalCode))
  const { postalCodes, failure } = yield race({
    postalCodes: take(store.postalCodes('locationService').types.success),
    failure: take(store.postalCodes('locationService').types.failure)
  })

  if (failure || postalCodes.response.length === 0) {
    yield put({
      type: store.allFromPostalCode('locationService').types.failure,
      error: 'Could not find postal codes'
    })
    yield put(AlertActionCreators.error({ message: 'Código postal inválido' }))
    return
  }

  const response = postalCodes.response

  if (response.length > 0) {
    yield all([
      put(store.states('locationService').action()),
      put(store.municipalities('locationService').action(response[0].stateId)),
      put(
        store
          .neighborhoods('locationService')
          .action(response[0].stateId, response[0].municipalityId)
      )
    ])
  }
}

export function* allFromPostalCode({ postalCode }) {
  yield put(store.postalCodes('locationService').action(postalCode))
  const { postalCodes, failure } = yield race({
    postalCodes: take(store.postalCodes('locationService').types.success),
    failure: take(store.postalCodes('locationService').types.failure)
  })

  if (failure || postalCodes.response.length === 0) {
    yield put({
      type: store.allFromPostalCode('locationService').types.failure,
      error: 'Could not find postal codes'
    })
    yield put(AlertActionCreators.error({ message: 'Código postal inválido' }))
    return
  }

  const response = postalCodes.response

  if (response.length > 0) {
    yield all([
      put(store.states('locationService').action()),
      put(store.municipalities('locationService').action(response[0].stateId)),
      put(
        store
          .neighborhoods('locationService')
          .action(response[0].stateId, response[0].municipalityId)
      )
    ])
    yield all([
      take(store.states('locationService').types.success),
      take(store.municipalities('locationService').types.success),
      take(store.neighborhoods('locationService').types.success)
    ])
  }

  yield put({
    type: store.allFromPostalCode('locationService').types.success,
    response
  })
}

export function* getFullLocation({ name, gmaps }) {
  const gmapsLocation = getNeximoAddress(mapResultToAddress(gmaps))
  const token = yield select(state => (state.auth ? state.auth.token : null))
  const locationDetailResponse = yield call(api.geo.getGeocodeData,
    token,
    {
      latlng: `${gmapsLocation.lat},${gmapsLocation.lng}`,
      result_type: gmaps.types.reduce((a, b) => `${a}|${b}`, '')
    }
  )

  if (
    locationDetailResponse.status === 200 &&
    locationDetailResponse.data &&
    Array.isArray(locationDetailResponse.data.results) &&
    locationDetailResponse.data.results.length === 0
  ) {
    yield put(
      GeoActionCreators.getFullLocationSuccess(name, locationDetailResponse, {
        ...gmapsLocation,
        isApproximate: true
      })
    )
    return
  }

  if (
    locationDetailResponse.status === 200 &&
    locationDetailResponse.data &&
    Array.isArray(locationDetailResponse.data.results) &&
    locationDetailResponse.data.results.length > 0
  ) {
    const result = locationDetailResponse.data.results.find(
      r => r.place_id === gmapsLocation.placeId
    )
    if (result) {
      yield put(
        GeoActionCreators.getFullLocationSuccess(name, locationDetailResponse, {
          ...getNeximoAddress(mapResultToAddress(result)),
          isApproximate: false
        })
      )
      return
    }

    const token = yield select(state => (state.auth ? state.auth.token : null))
    const postalCodeResponse = yield call(
      api.geo.getGeocodeData,
      token,
      {
        components: `postalCode:${gmapsLocation.postalCode}|country:${
          gmapsLocation.country
        }`
      }
    )

    if (
      postalCodeResponse.status === 200 &&
      postalCodeResponse.data &&
      Array.isArray(postalCodeResponse.data.results) &&
      postalCodeResponse.data.results.length > 0
    ) {
      yield put(
        GeoActionCreators.getFullLocationSuccess(name, locationDetailResponse, {
          ...getNeximoAddress(
            mapResultToAddress(postalCodeResponse.data.results[0])
          ),
          ...gmapsLocation,
          isApproximate: true
        })
      )
    } else {
      yield put(
        GeoActionCreators.getFullLocationSuccess(name, locationDetailResponse, {
          ...gmapsLocation,
          isApproximate: true
        })
      )
    }
  } else {
    yield put(
      GeoActionCreators.getFullLocationFailure(
        name,
        locationDetailResponse.data
      )
    )
  }
}


export function* getLatLngLocation({ name, address }) {
  const token = yield select(state => (state.auth ? state.auth.token : null))
  const latLngLocationResponse = yield call(
    api.geo.getGeocodeData,
    token,
    {
      address: `${address}`
    })

  if (
    latLngLocationResponse.status === 200 &&
    latLngLocationResponse.data &&
    Array.isArray(latLngLocationResponse.data.results) &&
    latLngLocationResponse.data.results.length > 0
  ) {
    const result = latLngLocationResponse.data

    if (result) {
      const coords = result.results[0].geometry.location
      yield put(GeoActionCreators.getLatLngSuccess(name, coords))
      return
    }
  }

  yield put(
    GeoActionCreators.getLatLngSuccess(name, {
      message: 'Could not find location for specified address.',
      ...latLngLocationResponse.data
    })
  )
  yield put(AlertActionCreators.error({ message: 'Dirección no encontrada.' }))
}
