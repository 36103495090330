import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {
  rejecting: false
}

const { Types, Creators } = createActions({
  rejectLeadRequest: ['lead', 'data'],
  rejectLeadSuccess: ['data']
}, { prefix: 'REJECT_LEAD_' })

const rejectLeadRequest = (state, { lead, data }) => ({
  ...state,
  rejecting: true
})

const rejectLeadSuccess = (state, { data}) => ({
  ...state,
  rejecting: false
})

const HANDLERS = {
  [Types.REJECT_LEAD_REQUEST]: rejectLeadRequest,
  [Types.REJECT_LEAD_SUCCESS]: rejectLeadSuccess
}

export const RejectLeadTypes = Types
export const RejectLeadActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)

