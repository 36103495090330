import { put, select, take, race } from 'redux-saga/effects'
import { store } from 'services'

export function* getExternalLeadSources() {
  const currentExternalLeadSourcesResponse = yield select(
    store.filter('externalLeadSources').selector,
  )

  if (currentExternalLeadSourcesResponse.response?.length > 0) {
    return currentExternalLeadSourcesResponse.response
  }

  yield put(store.filter('externalLeadSources').action())
  const [externalLeadSourcesError, externalLeadSourcesSuccess] = yield race([
    take(store.filter('externalLeadSources').types.failure),
    take(store.filter('externalLeadSources').types.success),
  ])

  if (externalLeadSourcesError) {
    return []
  }

  const externalLeadSourcesResponse = yield select(
    store.filter('externalLeadSources').selector,
  )

  return externalLeadSourcesResponse.response || []
}
