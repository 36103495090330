import { put } from 'redux-saga/effects'
import { RecruitmentLeadActionCreators } from 'app/reducers/RecruitmentLeadRedux'
import { RecruitmentLeadsUrls } from 'services/api/entities'

export function* acceptRecruitmentLead({ recruitmentLeadId }) {
  const response = yield RecruitmentLeadsUrls.acceptRecruitmentLead(
    recruitmentLeadId,
  )

  if (response.success()) {
    yield put(RecruitmentLeadActionCreators.acceptRecruitmentLeadSuccess())
  } else if (response.status === 400) {
    const { message, acceptedAt, rejectedAt } = response.data

    yield put(
      RecruitmentLeadActionCreators.acceptRecruitmentLeadFailure(
        null,
        message,
        acceptedAt || rejectedAt,
      ),
    )
  } else if (response.status === 404) {
    yield put(
      RecruitmentLeadActionCreators.acceptRecruitmentLeadFailure(
        null,
        'NOT_FOUND',
      ),
    )
  } else {
    yield put(RecruitmentLeadActionCreators.acceptRecruitmentLeadFailure())
  }
}

export function* findRecruitmentLeadToReject({ recruitmentLeadId }) {
  const response = yield RecruitmentLeadsUrls.findRecruitmentLeadToReject(
    recruitmentLeadId,
  )

  if (response.success()) {
    yield put(
      RecruitmentLeadActionCreators.findRecruitmentLeadToRejectSuccess(
        response.data,
      ),
    )
  } else if (response.status === 400) {
    const { message, acceptedAt, rejectedAt } = response.data

    yield put(
      RecruitmentLeadActionCreators.findRecruitmentLeadToRejectFailure(
        null,
        message,
        acceptedAt || rejectedAt,
      ),
    )
  } else if (response.status === 404) {
    yield put(
      RecruitmentLeadActionCreators.findRecruitmentLeadToRejectFailure(
        null,
        'NOT_FOUND',
      ),
    )
  } else {
    yield put(
      RecruitmentLeadActionCreators.findRecruitmentLeadToRejectFailure(),
    )
  }
}

export function* rejectRecruitmentLead(params) {
  const { recruitmentLeadId, rejectedComments, rejectedReason } = params
  const response = yield RecruitmentLeadsUrls.rejectRecruitmentLead(
    recruitmentLeadId,
    { rejectedComments, rejectedReason },
  )

  if (response.success()) {
    yield put(RecruitmentLeadActionCreators.rejectRecruitmentLeadSuccess())
  } else if (response.status === 400) {
    const { message, acceptedAt, rejectedAt } = response.data

    yield put(
      RecruitmentLeadActionCreators.rejectRecruitmentLeadFailure(
        null,
        message,
        acceptedAt || rejectedAt,
      ),
    )
  } else if (response.status === 404) {
    yield put(
      RecruitmentLeadActionCreators.rejectRecruitmentLeadFailure(
        null,
        'NOT_FOUND',
      ),
    )
  } else {
    yield put(RecruitmentLeadActionCreators.rejectRecruitmentLeadFailure())
  }
}

export function* contactedRecruitmentLead(params) {
  const { recruitmentLeadId, contacted } = params
  if (contacted === 'true') {
    const response = yield RecruitmentLeadsUrls.contactedRecruitmentLead(
      recruitmentLeadId,
    )
    if (response.success()) {
      yield put(RecruitmentLeadActionCreators.contactedRecruitmentLeadSuccess())
    } else {
      yield put(RecruitmentLeadActionCreators.contactedRecruitmentLeadFailure())
    }
  } else {
    yield put(RecruitmentLeadActionCreators.contactedRecruitmentLeadSuccess())
  }
}
