import { createReducer, createActions } from 'reduxsauce'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback
} from './utils'

export const INITIAL_STATE = {
  alerts: [],
  alertsCount: 0,
  newAlertData: {},
  idOfAlertChanged: null,
  isShowAlertModal: false,
  createAlert: initialFeedback(),
  getAlertsStatus: initialFeedback(),
  createAlertStatus: initialFeedback(),
  changeStatusAlertStatus: initialFeedback()
}

const { Types, Creators } = createActions(
  {
    getAlerts: ['params'],
    getAlertsSuccess: ['data'],
    getAlertsFailure: ['errors'],

    createAlert: ['data'],
    createAlertSuccess: ['data'],
    createAlertFailure: ['errors'],

    changeStatusAlert: ['alertStatus', 'id'],
    changeStatusAlertSuccess: ['data'],
    changeStatusAlertFailure: ['errors'],

    showAlertModal: ['toShow'],
    finishedCreateAlert: [],
    finishedEditAlert: [],

    cleanState: []
  },
  { prefix: 'BIN_ALERTS_' }
)

const getAlerts = state => ({ ...state, getAlertsStatus: requestFeedback() })
const getAlertsSuccess = (state, { data }) => ({
  ...state,
  alerts: data.results,
  alertsCount: data.count,
  getAlertsStatus: successFeedback()
})
const getAlertsFailure = (state, { errors }) => ({
  ...state,
  getAlertsStatus: failureFeedback(errors)
})

const createAlert = state => ({
  ...state,
  createAlertStatus: requestFeedback()
})
const createAlertSuccess = (state, { data }) => ({
  ...state,
  newAlertData: data,
  createAlertStatus: successFeedback()
})
const createAlertFailure = (state, { errors }) => ({
  ...state,
  createAlertStatus: failureFeedback(errors)
})

const changeStatusAlert = (state, { id }) => ({
  ...state,
  idOfAlertChanged: id,
  changeStatusAlertStatus: requestFeedback()
})
const changeStatusAlertSuccess = state => ({
  ...state,
  changeStatusAlertStatus: successFeedback()
})

const changeStatusAlertFailure = (state, { errors }) => ({
  ...state,
  changeStatusAlertStatus: failureFeedback(errors)
})

const showAlertModal = (state, { toShow }) => ({
  ...state,
  isShowAlertModal: toShow
})

const finishedCreateAlert = state => ({
  ...state,
  createAlertStatus: initialFeedback(),
  isShowAlertModal: false
})

const finishedEditAlert = state => ({
  ...state,
  changeStatusAlertStatus: initialFeedback()
})

const cleanState = () => INITIAL_STATE

const HANDLERS = {
  [Types.GET_ALERTS]: getAlerts,
  [Types.GET_ALERTS_SUCCESS]: getAlertsSuccess,
  [Types.GET_ALERTS_FAILURE]: getAlertsFailure,

  [Types.CREATE_ALERT]: createAlert,
  [Types.CREATE_ALERT_SUCCESS]: createAlertSuccess,
  [Types.CREATE_ALERT_FAILURE]: createAlertFailure,

  [Types.CHANGE_STATUS_ALERT]: changeStatusAlert,
  [Types.CHANGE_STATUS_ALERT_SUCCESS]: changeStatusAlertSuccess,
  [Types.CHANGE_STATUS_ALERT_FAILURE]: changeStatusAlertFailure,

  [Types.SHOW_ALERT_MODAL]: showAlertModal,
  [Types.FINISHED_EDIT_ALERT]: finishedEditAlert,
  [Types.FINISHED_CREATE_ALERT]: finishedCreateAlert,

  [Types.CLEAN_STATE]: cleanState
}

export const BinAlertsActionTypes = Types

export const BinAlertsActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
