import { put, all } from 'redux-saga/effects'

import { Deals } from 'services/api/entities'
import { MLMCommissionsActionCreators } from 'app/reducers/MLMCommissionsRedux'
import { removeAllEmptyKeys } from 'services/utils'

export function* getCommissionsRequest({ from, to, params }) {
  const closeDealsParams = removeAllEmptyKeys({
    closed_at_after: from,
    closed_at_before: to,
    ...params
  })

  const closeDealsProfitParams = removeAllEmptyKeys({
    created_at_after: from,
    created_at_before: to,
    ...params
  })

  let response = null
  if (closeDealsParams.author) {
    response = yield all([
      Deals.closedChildDeals(closeDealsParams),
      Deals.closedChildDealsProfit(closeDealsProfitParams)
    ])
  } else {
    response = yield all([
      Deals.closedDeals(closeDealsParams),
      Deals.closedDealsProfit(closeDealsProfitParams)
    ])
  }

  const [responseClosedDeals, responseProfit] = response

  const profit = responseProfit.success() ? responseProfit.data : null

  if (responseClosedDeals.success()) {
    const { count, results } = responseClosedDeals.data
    return yield put(
      MLMCommissionsActionCreators.getCommissionsSuccess(from, to, count, results, profit)
    )
  }

  yield put(MLMCommissionsActionCreators.getCommissionsFailure(from, to, responseClosedDeals.data))
}
