import { companyConfig } from 'globalConfigApp'

export const listCards = [
  {
    id: 0,
    title: 'Quiénes somos',
    category: 'about',
    icon: '/icons/faq/icono-about.svg'
  },
  {
    id: 1,
    title: 'Acerca de los Neximers',
    category: 'neximers',
    icon: '/icons/faq/icono-neximers.svg'
  },
  {
    id: 2,
    title: 'Mi cuenta',
    category: 'account',
    icon: '/icons/faq/icono-account.svg'
  },
  {
    id: 3,
    title: 'BIN',
    category: 'bin',
    icon: '/icons/faq/icono-bin.svg'
  },
  {
    id: 4,
    title: 'Mis fotos',
    category: 'photos',
    icon: '/icons/faq/icono-photos.svg'
  },
  {
    id: 5,
    title: 'Mis citas calificadas',
    category: 'dates',
    icon: '/icons/faq/icono-dates.svg'
  },
  {
    id: 6,
    title: 'Mis portales',
    category: 'portals',
    icon: '/icons/faq/icono-portals.svg'
  },
  {
    id: 7,
    title: 'Mis propiedades',
    category: 'properties',
    icon: '/icons/faq/icono-my-properties.svg'
  },
  {
    id: 8,
    title: 'Mis comisiones',
    category: 'fiscal',
    icon: '/icons/faq/icono-fiscal.svg'
  },
  {
    id: 9,
    title: 'Mis prospectos',
    category: 'prospects',
    icon: '/icons/faq/icono-prospects.svg'
  },
  {
    id: 10,
    title: 'Legal',
    category: 'legal',
    icon: '/icons/faq/icono-legal.svg'
  },
  {
    id: 11,
    title: `La red ${companyConfig.name}`,
    category: 'red',
    icon: '/icons/faq/icono-red.svg'
  },
  {
    id: 12,
    title: 'Otras preguntas',
    category: 'questions',
    icon: '/icons/faq/icono-questions.svg'
  }
]
