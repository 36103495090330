import React, { Component } from 'react'
import styles from './style'
import { colors } from 'theme'
import classnames from 'classnames'

class CircleProgress extends Component {
  static defaultProps = {
    lineWidth: 5,
    size: 140,
    color: `${colors.badge}`,
    innerColor: 'transparent',
    absolute: false,
    strokeLinecap: 'butt'
  }
  render() {
    const {
      size,
      lineWidth,
      color,
      absolute,
      message,
      percent,
      strokeLinecap,
      innerColor
    } = this.props
    const r = size / 2 - lineWidth * 2
    const circumference = r * 2 * Math.PI
    const offset = circumference - percent / 100 * circumference
    return (
      <div className="content-circle">
        <svg
          className={classnames('progress-ring', { absolute: absolute })}
          width={size}
          height={size}
        >
          <circle
            style={{
              strokeDasharray: `${circumference} ${circumference}`,
              strokeDashoffset: offset
            }}
            ref={this.setRef}
            className="progress-ring-circle"
            stroke={color}
            strokeWidth={lineWidth}
            fill={innerColor}
            r={r}
            cx={size / 2}
            cy={size / 2}
            strokeLinecap={strokeLinecap}
          />
          <style>{styles}</style>
        </svg>
        <div className="message">{message}</div>
      </div>
    )
  }
}

export default CircleProgress
