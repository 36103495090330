import { select, call, put, all } from 'redux-saga/effects'

import { Deals } from 'services/api/entities'

import { DealsActionCreators } from 'app/reducers/DealsRedux'

export function* getAll({ params, requestOptions }) {
  const response = yield Deals.getAll(params, requestOptions)

  if (response.success()) {
    yield put(DealsActionCreators.getAllSuccess(response.data))
  } else {
    yield put(DealsActionCreators.getAllFailure(response.data))
  }
}

export function* find({ dealId }) {
  const response = yield Deals.find(dealId)

  if (response.success()) {
    yield put(DealsActionCreators.findSuccess(response.data))
  } else {
    yield put(DealsActionCreators.findFailure(response.data))
  }
}

export function* getAllWithDetails({ params, requestOptions }) {
  const response = yield Deals.getAll(params, requestOptions)

  if (response.success()) {
    yield put(DealsActionCreators.updateDeals(response.data))

    const responseDetails = yield all(response.data.map(deal => Deals.find(deal.id)))

    const deals = []

    for (const response of responseDetails) {
      if (response.success()) {
        deals.push(response.data)
      }
    }

    yield put(DealsActionCreators.getAllWithDetailsSuccess(deals))

    return
  }

  yield put(DealsActionCreators.getAllWithDetailsFailure(response.data))
}
