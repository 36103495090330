import { required } from 'services/validators'

const getPrice = price => {
  if (
    !price ||
    !required(price.amount) ||
    isNaN(price.amount) ||
    !(price.currency && price.currency.value)
  ) {
    return null
  }

  return {
    amount: Number(price.amount),
    currency: price.currency.value
  }
}

export default getPrice
