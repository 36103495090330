export default {
  name: 'mlmValueEstimation',
  namespace: 'network/value_estimation',
  methods: [
    {
      name: 'valueEstimation',
      params: [],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/mlm/${namespace}/`,
      urlParams: []
    }
  ]
}
