export default {
  name: 'elearningModule',
  namespace: 'elearning/modules',
  methods: [
    {
      name: 'find',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/`,
      urlParams: [ 'id' ]
    },
    {
      name: 'enrollment',
      params: ['id'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/enroll/`,
      urlParams: [ 'id' ]
    },
  ]
}

