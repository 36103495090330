import { call, put, select, delay, all } from 'redux-saga/effects'
import { binLeadPrequalifyActionCreators } from 'app/reducers/binLeadPrequalifyRedux'
import { AlertActionCreators } from 'app/reducers/AlertRedux'
import { store } from 'services'

import {
  mapDataToSendRentQualify,
  mapDataToSentBuyQuality
} from 'app/selectors/binLeadPrequalify'
import api from 'app/services/api/api'

export function* getRentingRequirements() {
  const token = yield select(state => state.auth.token)

  const response = yield call(api.privateGet, '/v1/crm/renting_requirements/', { token })

  if (response.status === 200) {
    return yield put(
      binLeadPrequalifyActionCreators.getRentingRequirementsSuccess(
        response.data.results
      )
    )
  }

  yield put(binLeadPrequalifyActionCreators.getRentingRequirementsFailure())
}

export function* updatePrequalify({ data }) {
  const token = yield select(state => state.auth.token)
  const rentingRequirements = yield select(
    state => state.binLeadPrequalify.rentingRequirements
  )

  const {
    id,
    binLeadId,
    followupId,
    operationType,
    specialRequirements,
    ...restData
  } = data

  const baseUrl = `/v1/crm/followups/${followupId}`

  const urlWithOperation =
    operationType === 'SELL' ? `${baseUrl}/buying` : `${baseUrl}/renting`

  const editUrl = id ? `${urlWithOperation}/${id}/` : `${urlWithOperation}/`
  const entiti = id ? api.privatePut : api.privatePost

  const lastData =
    operationType === 'RENT'
      ? mapDataToSendRentQualify(restData, rentingRequirements)
      : mapDataToSentBuyQuality(restData)

  const { response } = yield all({
    response: call(entiti, editUrl, {
      data: lastData,
      token
    }),
    binLead: call(api.privatePatch, `/v1/crm/bin_leads/${binLeadId}/`, {
      token,
      data: { specialRequirements }
    })
  })

  if (response.status === 200 || response.status === 201) {
    yield put(
      binLeadPrequalifyActionCreators.updatePrequalifySuccess(response.data)
    )
    yield put(
      AlertActionCreators.success({ message: 'Se actualizó correctamente' })
    )
    yield delay(1500)
    yield put(binLeadPrequalifyActionCreators.cleanState())
    yield put(store.find('crmBinLead').action(binLeadId))
    return
  }

  yield put(
    binLeadPrequalifyActionCreators.updatePrequalifyFailure(response.data)
  )
}
