import ApiBase from 'app/services/api/entities/Base'

function* summary(params = {}) {
  return yield ApiBase.privateGet('/v1/mlm_networkstats/stats/summary/', {
    params,
  })
}

function* topClosers(params = {}) {
  return yield ApiBase.privateGet('/v1/mlm_networkstats/stats/top_closers/', {
    params,
  })
}

function* topBilling(params = {}) {
  return yield ApiBase.privateGet('/v1/mlm_networkstats/stats/top_billing/', {
    params,
  })
}

function* topNetworkBilling(params = {}) {
  return yield ApiBase.privateGet('/v1/mlm_networkstats/stats/top_network_billing/', {
    params,
  })
}

function* topPropertiesInExclusive(params = {}) {
  return yield ApiBase.privateGet(
    '/v1/mlm_networkstats/stats/top_properties_in_exclusive/',
    {
      params,
    },
  )
}

function* topStock(params = {}) {
  return yield ApiBase.privateGet('/v1/mlm_networkstats/stats/top_stock/', {
    params,
  })
}

function* topRecruited(params = {}) {
  return yield ApiBase.privateGet('/v1/mlm_networkstats/stats/top_recruited/', {
    params,
  })
}

export default {
  summary,
  topClosers,
  topBilling,
  topNetworkBilling,
  topPropertiesInExclusive,
  topStock,
  topRecruited,
}
