import { camelize } from 'services/string'

export const placeHolderMapData = {
  placeId: '',
  address: '',
  street: '',
  streetNumber: '',
  neighborhood: '',
  city: '',
  district: '',
  state: '',
  postalCode: '',
  country: '',
  lat: '',
  lng: '',
  timestamp: '',
  isApproximate: false
}

export const getNeximoAddress = curatedAddress => {
  if (curatedAddress) {
    return {
      placeId: curatedAddress.placeId,
      address: curatedAddress.formattedAddress,
      street: curatedAddress.route,
      streetNumber: curatedAddress.streetNumber,
      neighborhood: curatedAddress.sublocality || curatedAddress.neighborhood,
      city: curatedAddress.locality,
      district:
        curatedAddress.administrativeAreaLevel3 ||
        curatedAddress.administrativeAreaLevel2,
      state: curatedAddress.administrativeAreaLevel1,
      postalCode: curatedAddress.postalCode,
      country: curatedAddress.country,
      lat: curatedAddress.geoPoint.lat,
      lng: curatedAddress.geoPoint.lng,
      timestamp: curatedAddress.timestamp
    }
  }
  return null
}

export const mapResultToAddress = result => {
  const { address_components: components, place_id } = result

  const curated = {}
  const allowedKeys = [
    'street_number',
    'route',
    'postal_code',
    'neighborhood',
    'sublocality',
    'locality',
    'administrative_area_level_3',
    'administrative_area_level_2',
    'administrative_area_level_1',
    'country'
  ]

  for (let component of components) {
    for (let key of component.types) {
      if (!allowedKeys.includes(key)) {
        continue
      }

      curated[camelize(key)] = component.long_name
    }
  }
  curated.formattedAddress = result.formatted_address
  curated.geoPoint = { ...result.geometry.location }
  if(typeof curated.geoPoint.lat === 'function') {
    curated.geoPoint = { lat: curated.geoPoint.lat(), lng: curated.geoPoint.lng() }
  }
  curated.placeId = place_id

  return curated
}

/**
 * Receives the response from Google Geocode API and curates it to return a
 * valid object or null
 */
export const curateAddressData = (response) => {
  if (!response) {
    return null
  }
  if (
    response.status !== 200 ||
    !response.data ||
    !Array.isArray(response.data.results) ||
    response.data.results.length === 0
  ) {
    return null
  }

  return mapResultToAddress(response.data.results[0])
}

export default {
  placeHolderMapData,
  getNeximoAddress,
  mapResultToAddress,
  curateAddressData,
}
