export default {
  name: 'elearningCourse',
  namespace: 'elearning/courses',
  methods: [
    {
      name: 'filter',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/${namespace}/`,
      urlParams: []
    },
    {
      name: 'find',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/`,
      urlParams: ['id']
    },
    {
      name: 'enrollment',
      params: ['id'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/enroll/`,
      urlParams: ['id'],
      saga: false
    },
    {
      name: 'makeEnrollment',
      params: ['id'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/enroll/`,
      urlParams: ['id']
    }
  ]
}
