import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {
  rfc: {}
}

const getRfcRequest = state => {
  return state
}

const getRfcSuccess = (state, { rfc }) => ({
  ...state,
  rfc
})

const { Types, Creators } = createActions(
  {
    getRfcRequest: [],
    getRfcSuccess: ['rfc']
  },
  { prefix: 'RFC_' }
)

const HANDLERS = {
  [Types.GET_RFC_REQUEST]: getRfcRequest,
  [Types.GET_RFC_SUCCESS]: getRfcSuccess
}

export const RfcActionTypes = Types

export const RfcActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
