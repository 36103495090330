import about from './about'
import neximers from './neximers'
import account from './account'
import bin from './bin'
import photos from './photos'
import dates from './dates'
import portals from './portals'
import properties from './properties'
import fiscal from './fiscal'
import prospects from './prospects'
import legal from './legal'
import red from './red'
import questions from './questions'

const data = {
  about,
  neximers,
  account,
  bin,
  photos,
  dates,
  portals,
  properties,
  fiscal,
  prospects,
  legal,
  red,
  questions
}

const mapMetadata = (cont, index) => ({
  title: cont.props['data-title'],
  category: cont.props['data-category'],
  path: cont.props['data-url'],
  index
})

export const getContent = type => {
  const content = data[type]
  return content ? content : <h3>Sin información</h3>
}

export const getContentArticle = (type, id) => {
  const content = getContent(type)
  if (Array.isArray(content)) {
    const data = content.find(({ props }) => props['data-url'] === id)
    return data ? data : <h4>¡Ups! No hallamos lo que buscabas</h4>
  }
  return content
}

export const getAllQuestionsMetadata = () =>
  Object.keys(data)
    .map(key => data[key])
    .map(data => data.map(mapMetadata))
    .reduce((acc, category) => [...acc, ...category], [])

export const getMetadata = type => {
  const content = getContent(type)
  if (Array.isArray(content)) {
    return content.map(mapMetadata)
  } else {
    return content
  }
}
