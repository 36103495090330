import api from '../api'

const getDirectAnnonceLeadById = (token, id) => {
  return api.privateGet(`/v1/direct_annonce_leads/${id}/`, {token})
}

const rejectDirectAnnonceLead = (token, leadId, data) => {
  return api.privatePatch(`/v1/direct_annonce_leads/${leadId}/reject/`, {
    token,
    data
  })
}

const postponeDirectAnnonceLead = (token, leadId, data) => {
  return api.privatePatch(`/v1/direct_annonce_leads/${leadId}/postpone/`, {
    token,
    data
  })
}

const acceptDirectAnnonceLead = (token, leadId) => {
  return api.privatePost(`/v1/direct_annonce_leads/${leadId}/accept/`, {
    token,
    data: {}
  })
}

export default {
  getDirectAnnonceLeadById,
  rejectDirectAnnonceLead,
  postponeDirectAnnonceLead,
  acceptDirectAnnonceLead
}

