import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {
  request: false,
  success: false,
  loading: false,
  error: false,
  messageError: null
}

const { Types, Creators } = createActions(
  {
    getPdfRequest: ['endpoint', 'fileName', 'data'],
    getPdfSuccess: ['url'],
    getPdfFailure: ['error'],
    cleanState: []
  },
  { prefix: 'PDF_DOWNLOAD_' }
)

const getPdfRequest = state => ({
  ...INITIAL_STATE,
  loading: true
})

const getPdfSuccess = state => ({
  ...state,
  loading: false,
  success: true
})

const getPdfFailure = (state, { error }) => ({
  ...state,
  loading: false,
  error: true,
  messageError: error
})

const cleanState = () => INITIAL_STATE

const HANDLERS = {
  [Types.GET_PDF_REQUEST]: getPdfRequest,
  [Types.GET_PDF_SUCCESS]: getPdfSuccess,
  [Types.GET_PDF_FAILURE]: getPdfFailure,
  [Types.CLEAN_STATE]: cleanState
}

export const PdfActionTypes = Types
export const PdfActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
