import {
  put,
  all as callAll,
  call,
  select,
  take,
  actionChannel
} from 'redux-saga/effects'
import { buffers } from 'redux-saga'
import Router from 'next/router'

import { Contracts, rfc } from 'services/api/entities'
import {
  ContractsActionCreators,
  ContractsActionTypes
} from 'app/reducers/ContractsRedux'
import {
  saveDraftContractSelector,
  saveContractSelector,
  fillInitialValuesSelector,
} from 'selectors/contracts'
import { userSelector } from 'selectors/user'

export function* all({ params }) {
  const response = yield Contracts.all(params)

  if (response.success()) {
    const { count, results } = response.data

    yield put(ContractsActionCreators.allSuccess(count, results))
  } else {
    yield put(ContractsActionCreators.allFailure(response.data))
  }
}

export function* contract({ contractId }) {
  const response = yield Contracts.find(contractId)

  if (response.success()) {
    yield contractType({ contractTypeId: response.data.contractTypeId.id })
    const contract = response.data || {}
    const propertyId = contract.propertyId || null

    yield put(ContractsActionCreators.contractSuccess(contract))
    yield put(ContractsActionCreators.setOriginalContract(contract))
    yield put(ContractsActionCreators.setShallowSelectedProperty(propertyId))
    return
  }

  yield put(ContractsActionCreators.contractFailure(response.data))
}

function* getContractProperty(propertyId) {
  if (propertyId) {
    const response = yield Contracts.property(propertyId)
    return response.success() ? response.data : null
  }

  return null
}

export function* fillInitialValues({ contractTypeId, selectedProperty }) {
  let contractType = yield select(
    state => state.contracts.contractType.contractType.identifier
  )

  if (!contractType) {
    const response = yield Contracts.getQuestionnairy(contractTypeId)
    contractType = response.success() && response.data.identifier
  }

  const broker = yield select(userSelector)
  const [rfcResponse, propertyResponse] = yield callAll([
    rfc.rfc(),
    getContractProperty(selectedProperty)
  ])

  if (rfcResponse.success()) {
    broker.rfc = rfcResponse.data.rfc
  }
  const property = propertyResponse || {}
  property.broker = broker

  yield put(
    ContractsActionCreators.fillInitialValuesSuccess(
      fillInitialValuesSelector(contractType, property)
    )
  )
}

export function* types({ params }) {
  const response = yield Contracts.activeTypes(params)

  if (response.success()) {
    const { count, results } = response.data

    yield put(ContractsActionCreators.typesSuccess(count, results))
  } else {
    yield put(ContractsActionCreators.typesFailure(response.data))
  }
}

export function* contractLayoutVersion({ contractId }) {
  const response = yield Contracts.getCheckLayoutVersion(contractId)
  if (response.success()) {
    const { isUpdated } = response.data
    yield put(ContractsActionCreators.contractLayoutVersionSuccess(isUpdated))
  } else {
    yield put(
      ContractsActionCreators.contractLayoutVersionFailure(response.data)
    )
  }
}

export function* contractType({ contractTypeId }) {
  const response = yield Contracts.getQuestionnairy(contractTypeId)

  if (response.success()) {
    return yield put(ContractsActionCreators.contractTypeSuccess(response.data))
  }

  yield put(ContractsActionCreators.contractTypeFailure(response.data))
}

export function* save() {
  const contract = yield select(saveContractSelector)
  const contractId = yield select(state => state.contracts.originalContract.contract.id)

  const response = contractId
    ? yield Contracts.update(contractId, contract)
    : yield Contracts.create(contract)

  if (response.success()) {
    const responseContract = yield Contracts.pdf(contractId)
    const url = responseContract.success() && responseContract.data.url || null
    yield put(ContractsActionCreators.saveSuccess({...response.data, url }))
  } else {
    yield put(ContractsActionCreators.saveFailure(response.data))
  }
}

export function* partialUpdate({ contractId, partialContract }) {
  const contracts = yield select(state => state.contracts.list.results)
  const currentContract = contracts.find(contract => contract.id === contractId)

  const response = yield Contracts.update(contractId, {
    ...currentContract,
    contractTypeId: currentContract.contractTypeId.id,
    ...partialContract,
  })

  if (response.success()) {
    yield put(ContractsActionCreators.partialUpdateSuccess(response.data))
    yield put(
      ContractsActionCreators.updateContractInList(contractId, response.data)
    )
  } else {
    yield put(ContractsActionCreators.partialUpdateFailure(response.data))
  }
}

export function* draft() {
  const contract = yield select(saveDraftContractSelector)
  const contractId = yield select(state => state.contracts.originalContract.contract.id)

  // At least contract should have name to be a draft
  if (!contract.values.CONTRACT_NAME) {
    yield put(ContractsActionCreators.draftFailure())
    return
  }

  const response = contractId
    ? yield Contracts.updateDraft(contractId, contract)
    : yield Contracts.createDraft(contract)

  if (response.success()) {
    yield put(ContractsActionCreators.setOriginalContract(response.data))
    yield put(ContractsActionCreators.draftSuccess(response.data))

    if (!contractId) {
      yield redirectToContractDetail({ contractId: response.data.id })
      const broker = yield select(userSelector)
      const rfcResponse = yield rfc.rfc()

      if (rfcResponse.success()) {
        broker.rfc = rfcResponse.data.rfc
      }

      yield put(ContractsActionCreators.setOriginalContract(response.data))
    }
  } else {
    yield put(ContractsActionCreators.draftFailure(response.data))
  }
}

function* redirectToContractDetail({ contractId }) {
  Router.replace(
    {
      pathname: '/dashboard/contracts/contract-detail',
      query: { contractId },
    },
    `/dashboard/contracts/${contractId}`,
    {
      shallow: true,
    }
  )
}

export function* draftWatcher() {
  const requestChannel = yield actionChannel(
    ContractsActionTypes.DRAFT,
    buffers.sliding(1)
  )

  while (true) {
    const actions = yield take(requestChannel)
    yield call(draft, actions)
  }
}

export function* remove({ contractId }) {
  const response = yield Contracts.remove(contractId)

  if (response.success()) {
    yield put(ContractsActionCreators.removeSuccess())
  } else {
    yield put(ContractsActionCreators.removeFailure(response.data))
  }
}

export function* requestSignatures({ contractId, url }) {
  const response = yield Contracts.requestSignatures(contractId, url)

  if (response.success()) {
    yield put(ContractsActionCreators.requestSignaturesSuccess())
  } else {
    yield put(ContractsActionCreators.requestSignaturesFailure(response.data))
  }
}

export function* cancelSignatures({ contractId }) {
  const response = yield Contracts.cancelSignatures(contractId)

  if (response.success()) {
    yield put(ContractsActionCreators.cancelSignaturesSuccess())
  } else {
    yield put(ContractsActionCreators.cancelSignaturesFailure(response.data))
  }
}
