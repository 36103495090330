import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {
  postpone: false
}

const { Types, Creators } = createActions({
  postponeLeadRequest: ['lead', 'data'],
  postponeLeadSuccess: ['data']
}, { prefix: 'POSTPONE_LEAD_' })

const postponeLeadRequest = (state, { lead, data }) => ({
  ...state,
  postpone: true
})

const postponeLeadSuccess = (state, { data}) => ({
  ...state,
  postpone: false
})

const HANDLERS = {
  [Types.POSTPONE_LEAD_REQUEST]: postponeLeadRequest,
  [Types.POSTPONE_LEAD_SUCCESS]: postponeLeadSuccess
}

export const PostponeLeadTypes = Types
export const PostponeLeadActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)

