import { removeAllEmptyKeys } from 'services/utils'

import { getStringError, getEachErrorFromObj } from 'app/asyncValidators/utils'

const asyncValidator = errors => {
  const details = {
    title: errors.title,
    sites: errors.sites,
    propertyType: errors.propertyType,
    soil: errors.soil,
    condition: errors.condition,
    operationType: errors.operationType,
    price: errors.price,
    priceCurrency: errors.price,
    landType: errors.landType,
    description: errors.description,
    constructionArea: errors.constructionArea,
    surfaceArea: errors.surfaceArea,
    age: errors.age,
    bedrooms: errors.bedrooms,
    bathrooms: errors.bathrooms,
    halfBathrooms: errors.halfBathrooms,
    parkingSpaces: errors.parkingSpaces,
    subCategory: errors.subCategory,
    landRegisterNumber: errors.landRegisterNumber,
    cadastralReference: errors.cadastralReference,
    idufir: errors.idufir,
    scaleOfEnergy: errors.scaleOfEnergy,
    energyConsumptionValue: errors.energyConsumptionValue,
    energyEmissionsScale: errors.energyEmissionsScale,
    energyEmissionsValue: errors.energyEmissionsValue,
    presaleDate: errors.presaleDate
  }

  const address = {
    showFullLocation: errors.showFullLocation,
    address: errors.address,
    street: errors.street,
    streetNumber: errors.streetNumber,
    aptNumber: errors.aptNumber,
    country: errors.country,
    neighborhood: errors.neighborhood,
    city: errors.city,
    district: errors.district,
    state: errors.state,
    postalCode: errors.postalCode,
  }

  if (errors.lng || errors.lat) {
    address.latLng = {
      lng: errors.lng,
      lat: errors.lat
    }
  }

  const media = {
    videoUrl: errors.videoUrl,
    virtualTourUrl: errors.virtualTourUrl,
    imagesJson: errors.imagesJson
    // ToDo booking validation
    // booking: ''
  }

  const amenities = getStringError(errors.amenities)

  const sale = {
    operationType: errors.operationType,
    saleCommissionPercentage: errors.saleCommissionPercentage,
    saleCommissionDays: errors.saleCommissionDays,
    saleType: errors.saleType,
    saleGrossCommission: errors.saleGrossCommission,
    saleTotalCommission: errors.saleTotalCommission,
    hasSaleCommissionTax: errors.hasSaleCommissionTax
  }

  const finalErrors = {
    amenities: {},
    details: removeAllEmptyKeys(getEachErrorFromObj(details)),
    address: removeAllEmptyKeys(getEachErrorFromObj(address)),
    media: removeAllEmptyKeys(getEachErrorFromObj(media)),
    sale: removeAllEmptyKeys(getEachErrorFromObj(sale)),
  }

  if(amenities) {
    finalErrors.amenities = amenities
  }

  finalErrors._errors = getErrorPerPage(finalErrors)

  return finalErrors
}

const getErrorPerPage = finalErrors => {
  const errorAtDetailsCount = Object.keys(finalErrors.details).length
  const errorAtAddressCount = Object.keys(finalErrors.address).length
  const errorAtMediaCount = Object.keys(finalErrors.media).length
  const errorAtAmenitiesCount = Object.keys(finalErrors.amenities).length
  const errorAtSaleCount = Object.keys(finalErrors.sale).length

  const errorAtDetailsPage = errorAtDetailsCount && `${errorAtDetailsCount} en Detalles`
  const errorAtAddressPage = errorAtAddressCount && `${errorAtAddressCount} en Dirección`
  const errorAtMediaPage = errorAtMediaCount && `${errorAtMediaCount} en Media`
  const errorAtAmenitiesPage = errorAtAmenitiesCount && `${errorAtAmenitiesCount} en Amenidades`
  const errorAtSalePage = errorAtSaleCount && `${errorAtSaleCount} en Propietario`

  const descriptionErrors = [
    errorAtDetailsPage,
    errorAtAddressPage,
    errorAtMediaPage,
    errorAtAmenitiesPage,
    errorAtSalePage
  ]
    .filter(Boolean)
    .join(', ')

  return descriptionErrors.length > 0 && `Errores: ${descriptionErrors}`
}

export default asyncValidator
