import { createReducer, createActions } from 'reduxsauce'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback
} from './utils'

export const INITIAL_STATE = {
  addProperties: false,
  propertyValuations: initialFeedback(),
  createPropertyValuation: initialFeedback(),
  isOpenModalToAddProperties: false,
  leadData: {},
  actualLeadStatus: null,
  isAcceptingLead: null,
  dateOfAssignement: null,
  updateAlertPropertiesStatus: initialFeedback(),
  acceptLeadStatus: initialFeedback()
}

const { Types, Creators } = createActions(
  {
    updateAlertProperties: ['isAddedProperties', 'propertyAds', 'alertId'],
    updateAlertPropertiesSuccess: ['data'],
    updateAlertPropertiesFailure: ['errors'],

    acceptLead: ['leadId', 'isAcceptingLead', 'data'],
    acceptLeadSuccess: ['leadData'],
    acceptLeadFailure: ['errors'],

    showActualLeadStatus: ['actualLeadStatus', 'dateOfAssignement'],

    showModalToAddProperties: ['isOpen'],
    addProperties: ['addProperties'],
    cleanState: ['cleanState'],

    propertyValuations: ['id'],
    propertyValuationsSuccess: ['data'],
    propertyValuationsTryNextProvider: [],
    getPropertyValuation: ['propertyId', 'provider'],
    propertyValuationsFailure: ['errors'],

    createPropertyValuation: ['data'],
    createPropertyValuationSuccess: ['data'],
    newPropertyValuation: ['data', 'provider'],
    createPropertyValuationFailure: ['errors']
  },
  { prefix: 'CRM_' }
)

const updateAlertProperties = state => ({
  ...state,
  updateAlertPropertiesStatus: requestFeedback()
})
const updateAlertPropertiesSuccess = (state, { data }) => ({
  ...state,
  updateAlertPropertiesStatus: successFeedback()
})
const updateAlertPropertiesFailure = (state, { errors }) => ({
  ...state,
  updateAlertPropertiesStatus: failureFeedback(errors)
})

const acceptLead = (state, { isAcceptingLead }) => ({
  ...state,
  isAcceptingLead: isAcceptingLead,
  acceptLeadStatus: requestFeedback()
})
const acceptLeadSuccess = (state, { leadData }) => ({
  ...state,
  leadData,
  acceptLeadStatus: successFeedback(),
  actualLeadStatus: state.isAcceptingLead
    ? 'LEAD_ACCEPTED'
    : 'ALREADY_REJECTED'
})
const acceptLeadFailure = (state, { errors }) => ({
  ...state,
  acceptLeadStatus: failureFeedback(errors)
})

const showActualLeadStatus = (state, { actualLeadStatus, dateOfAssignement }) => ({
  ...state,
  actualLeadStatus,
  dateOfAssignement,
  acceptLeadStatus: successFeedback()
})

const showModalToAddProperties = (state, { isOpen }) => ({
  ...state,
  isOpenModalToAddProperties: isOpen
})

const addProperties = (state, { addProperties }) => ({
  ...state,
  addProperties
})

const propertyValuations = (state, { id }) => ({
  ...state,
  propertyValuations:requestFeedback()
})

const propertyValuationsSuccess = (state, { data }) => ({
  ...state,
  propertyValuations: {...successFeedback(), data}
})

const propertyValuationsFailure = (state, { errors }) => ({
  ...state,
  propertyValuations: failureFeedback(errors)
})

const createPropertyValuation = (state) => ({
  ...state,
  createPropertyValuation: requestFeedback()
})

const createPropertyValuationSuccess = (state, { data }) => ({
  ...state,
  createPropertyValuation: {...successFeedback(), data}
})

const createPropertyValuationFailure = (state, { errors }) => ({
  ...state,
  createPropertyValuation: failureFeedback(errors)
})

const cleanState = () => INITIAL_STATE

const HANDLERS = {
  [Types.UPDATE_ALERT_PROPERTIES]: updateAlertProperties,
  [Types.UPDATE_ALERT_PROPERTIES_SUCCESS]: updateAlertPropertiesSuccess,
  [Types.UPDATE_ALERT_PROPERTIES_FAILURE]: updateAlertPropertiesFailure,

  [Types.ACCEPT_LEAD]: acceptLead,
  [Types.ACCEPT_LEAD_SUCCESS]: acceptLeadSuccess,
  [Types.ACCEPT_LEAD_FAILURE]: acceptLeadFailure,

  [Types.SHOW_ACTUAL_LEAD_STATUS]: showActualLeadStatus,

  [Types.PROPERTY_VALUATIONS]: propertyValuations,
  [Types.PROPERTY_VALUATIONS_SUCCESS]: propertyValuationsSuccess,
  [Types.PROPERTY_VALUATIONS_FAILURE]: propertyValuationsFailure,

  [Types.CREATE_PROPERTY_VALUATION]: createPropertyValuation,
  [Types.CREATE_PROPERTY_VALUATION_SUCCESS]: createPropertyValuationSuccess,
  [Types.CREATE_PROPERTY_VALUATION_FAILURE]: createPropertyValuationFailure,


  [Types.ADD_PROPERTIES]: addProperties,
  [Types.SHOW_MODAL_TO_ADD_PROPERTIES]: showModalToAddProperties,
  [Types.CLEAN_STATE]: cleanState
}

export const CRMActionTypes = Types

export const CRMActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
