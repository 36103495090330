import { ActorType, ReferalSource } from 'app/constants/dealActors'

/**
 * @typedef {Object} Actor
 * @property {Number} dealId - The unique identifier for the deal.
 * @property {ActorType} type
 * @property {?string} userId - The identifier for the user, if any.
 * @property {?string} agentId - The identifier for the agent, if any.
 * @property {boolean} isInitialDealBroker - Indicates whether the actor is the owner's deal.
 * @property {?Number} contractVersion - The contract version for the actor.
 *
 * @property {number} percentage - The percentage of the deal that the actor receives.
 * @property {number} commissionIAD - The commission for IAD - Calculated from the percentage.
 * @property {number} commissionMLM - The commission for MLM - Calculated from the percentage.
 * @property {?number} referalPercentage - The referral percentage for the actor.
 *
 * @property {?string} referalId - The identifier for the referral, if any.
 * @property {?ReferalSource} source - The source of the referral, if any.
 *
 * @property {string} name - The name of the actor.
 * @property {?string} email - The actor's email address.
 * @property {?string} telephone - The actor's telephone number.
 */

/**
 * @typedef {Object} Agent
 * @property {number} id - The unique identifier for the agent.
 * @property {number} neximoPercentage - The percentage of the deal that the agent receives.
 * @property {number} networkPercentage - The percentage of the deal that the agent receives.
 */

/**
 * @typedef {Object} Broker
 * @property {string} fullName - The name of the broker.
 * @property {string} email - The email address of the broker.
 * @property {?number} commissionPercentage - The commission percentage for the broker.
 * @property {?number} referalPercentage - The referral percentage for the broker.
 * @property {Agent} agent - The agent information of the broker.
 */

/**
 * @typedef {Object} Property
 * @property {?Object} broker - The broker owner of the property.
 *                              null means that the property is new and belongs
 *                              to the current user.
 * @property {number} broker.id - The broker identifier.
 * @property {?number} referalLeadSource - Or null
 * @property {?string} referalLeadId - Or null
 * @property {?number|string} referalLeadCustomPercentage - Or "0.00"
 */

/**
 * @typedef {Object} Contact
 * @property {string} fullName
 * @property {string} email
 * @property {?number} referalLeadSource - Or null
 * @property {?string} referalLeadId - Or null
 * @property {?number|string} referalLeadCustomPercentage - Or "0.00"
 */

/**
 * @typedef {Object} ExternalAgent
 * @property {string} fullName
 * @property {string} email
 * @property {?string} telephone
 */

/**
 * @typedef {Object} ReferalLeadSource
 * @property {number} id
 * @property {boolean} editable
 * @property {string} name - Like "Propertips"
 * @property {string} referalPercentage - Like "13.00"
 */

/**
 * @function buildActors
 * @description Use it to send the actors to the server when a property is
 *              created for a deal.
 * @param {Object} payload
 * @param {number} payload.initialBrokerId - The broker identifier.
 * @param {Broker[]} payload.brokers - Broker owner of the property.
 * @param {Property} payload.property - Property information.
 * @param {?Contact} payload.contact - Contact information.
 * @param {?ExternalAgent} payload.externalAgents - External agents information.
 * @param {ReferalLeadSource[]} payload.referalLeadSourceOptions - Referral options.
 * @returns {Actor[]}
 */
export function buildActors({
  initialBrokerId,
  brokers: initialBrokers,
  property,
  contact,
  externalAgents: initialExternalAgents,
  referalLeadSourceOptions,
}) {
  const actors = []

  const allActors = ensureCommissionsFor([
    ...setBrokerType(initialBrokers),
    ...setExternalAgentType(initialExternalAgents),
  ])
  const brokers = allActors.filter(({ type }) => type === ActorType.BROKER)
  const owner = brokers.find(({ id }) => Number(id) === Number(initialBrokerId))
  const paterns = brokers.filter(({ id }) => Number(id) !== Number(initialBrokerId))
  const externalAgents = allActors.filter(({ type }) => type === ActorType.EXTERNAL)
  actors.push(getBrokerActor({ broker: owner, isInitialDealBroker: true }))
  actors.push(...paterns.map(broker => getBrokerActor({ broker })))
  actors.push(...externalAgents.map(getExternalAgentActor))

  const propertyActor = getPropertyActor(property, referalLeadSourceOptions)
  const contactActor = getCotactActor(contact, referalLeadSourceOptions)

  const finalActors = [...actors, propertyActor, contactActor].filter(Boolean)
  return assignReferal(property, finalActors)
}

/**
 * @function assignReferal
 * @description Use it to assign the referal to the actors.
 * @param {Property} property
 * @param {Actor[]} actors
 * @returns {Actor[]}
 */
function assignReferal(property, actors) {
  /**
   * Cases:
   * 
   * - Broker Alone:
   *      - Property referral (or client referral) will be assigned to the agent
   * - Shared:
   *      - With External
   *          - Property referral (or client referral) will be assigned to the
   *            agent
   *      - With Internal
   *          - Property referral will be assigned to the agent owner of the
   *            property (initialDealBroker)
   *          - Client referral will be assigned to the other agent
   * 
   * 
   * ---------------------------------------------------------------------------
   * 
   * 
   * Warning: If the deal has both a property referral and a client referral
   * but only ONE INTERNAL AGENT, the property referral will be used as the
   * deal agent referral and the client referral will be lost.
   *
   * This issue hadn't been reported in production, but it's a potential bug.
   *
   * With the next implementation (Actor on demand) this potential bug will be
   * fixed.
   */

  const referalActor = actors.find(({ type }) => type === ActorType.REFERRAL)

  if (!referalActor) {
    return actors
  }

  const brokerActors = actors.filter(isAllowedToPayReferalCommission)
  const externalActors = actors.filter(({ type }) => type === ActorType.EXTERNAL)

  // Broker Alone
  if (brokerActors.length === 1) {
    const broker = {
      ...brokerActors[0],
      referalPercentage: referalActor.percentage,
    }
    return [broker, referalActor, ...externalActors]
  }

  // Shared with External
  if (brokerActors.length > 1 && brokerActors.some(({ type }) => type === ActorType.EXTERNAL)) {
    return actors.map(actor => {
      if (actor.isInitialDealBroker) {
        return {
          ...actor,
          referalPercentage: referalActor.percentage,
        }
      }
      return actor
    })
  }

  // Shared with Internal

  const propertyOwnerId = property.broker?.id

  const propertyOwnerActor = propertyOwnerId && brokerActors.find(
    ({ userId }) => Number(userId) === Number(propertyOwnerId),
  )

  if (propertyOwnerActor) {
    return actors.map(actor => {
      if (Number(actor.userId) === Number(propertyOwnerActor.userId)) {
        return {
          ...actor,
          referalPercentage: referalActor.percentage,
        }
      }
      return actor
    })
  }

  if (!propertyOwnerActor && referalActor.source === ReferalSource.PROPERTY) {
    return actors.map(actor => {
      if (actor.isInitialDealBroker) {
        return {
          ...actor,
          referalPercentage: referalActor.percentage,
        }
      }
      return actor
    })
  }

  // Client referral will be assigned to the other agent case
  const otherBroker = brokerActors.find(actor => !actor.isInitialDealBroker)

  return actors.map(actor => {
    if (Number(actor.userId) === Number(otherBroker.userId)) {
      return {
        ...actor,
        referalPercentage: referalActor.percentage,
      }
    }
    return actor
  })
}

function setBrokerType(brokers) {
  return brokers.map(broker => ({
    ...broker,
    type: Boolean(broker.agent?.id) ? ActorType.BROKER : ActorType.EXTERNAL,
  }))
}

function setExternalAgentType(externalAgents) {
  return externalAgents.map(externalAgent => ({
    ...externalAgent,
    type: ActorType.EXTERNAL,
  }))
}

function getReferalOption(referalLeadSourceOptions, referalLeadSource) {
  return referalLeadSourceOptions.find(({ id }) => id === referalLeadSource)
}

function getBrokerActor({ broker, isInitialDealBroker = false }) {
  return {
    type: ActorType.BROKER,
    userId: Number(broker.id),
    agentId: broker.agent.id,
    isInitialDealBroker: isInitialDealBroker,
    contractVersion: broker.contractVersion || null,

    percentage: broker.commissionPercentage || 100,
    commissionIAD: broker.agent?.neximoPercentage || 0,
    commissionMLM: broker.agent?.networkPercentage || 0,
    referalPercentage: broker.referalPercentage || 0,

    name: broker.fullName,
    email: broker.email,
    telephone: broker.telephone,
  }
}

function getPropertyActor(property, referalLeadSourceOptions) {
  const referalLeadSource = getReferalOption(
    referalLeadSourceOptions,
    property.referalLeadSource,
  )

  if (!referalLeadSource) {
    return null
  }

  return {
    type: ActorType.REFERRAL,
    referalId: property.referalLeadId || null,
    isInitialDealBroker: false,
    source: ReferalSource.PROPERTY,

    percentage: parseFloat(property.referalLeadCustomPercentage),
    commissionIAD: 0,
    commissionMLM: 0,
    referalPercentage: 0,

    name: referalLeadSource.name,
    email: null,
  }
}

function getCotactActor(contact, referalLeadSourceOptions) {
  const referalLeadSource = getReferalOption(
    referalLeadSourceOptions,
    contact?.referalLeadSource,
  )

  if (!referalLeadSource) {
    return null
  }

  return {
    type: ActorType.REFERRAL,
    referalId: contact.referalLeadId || null,
    isInitialDealBroker: false,
    source: ReferalSource.CONTACT,

    percentage: parseFloat(contact.referalLeadCustomPercentage),
    commissionIAD: 0,
    commissionMLM: 0,
    referalPercentage: 0,

    name: referalLeadSource.name,
    email: null,
  }
}

function getExternalAgentActor(externalAgent) {
  return {
    type: ActorType.EXTERNAL,
    userId: null,
    agentId: null,
    isInitialDealBroker: false,
    contractVersion: null,

    percentage: externalAgent.commissionPercentage || 100,
    commissionIAD: 0,
    commissionMLM: 0,
    referalPercentage: 0,

    name: externalAgent.fullName,
    email: externalAgent.email,
    telephone: externalAgent.telephone,
  }
}

/**
 * @function ensureCommissionsFor
 * @description Use it to set the commissions for the brokers.
 * @param {Broker[]} brokers
 * @returns {Broker[]}
 */
function ensureCommissionsFor(initialBrokers) {
  const { comissionAlreadySet, brokersWithCommissionCount } = initialBrokers.reduce(
    (acc, broker) => {
      if (broker.commissionPercentage) {
        acc.comissionAlreadySet += broker.commissionPercentage
        acc.brokersWithCommissionCount += 1
      }
      return acc
    },
    { comissionAlreadySet: 0, brokersWithCommissionCount: 0 },
  )
  const equity =
    (100 - comissionAlreadySet) /
    (initialBrokers.length - brokersWithCommissionCount)

  const brokers = initialBrokers.map(broker => ({
    ...broker,
    commissionPercentage: broker.commissionPercentage || equity,
  }))

  return brokers
}

export function prepareAgentBrokers(initialBrokerId, brokers, agents) {
  const agentsByBrokerId = agents.reduce((acc, data) => {
    acc[data.userId] = data
    return acc
  }, {})

  const finalBrokers = brokers.map((broker, index) => {
    const agent = agentsByBrokerId[broker.id] || {}
    return {
      ...broker,
      fullName: broker.fullName || agent.fullName,
      email: broker.email || agent.email,
      isInitialDealBroker: Number(broker.id) === Number(initialBrokerId),
      contractVersion: agent.contractVersion || null,
      agent: agent,
    }
  })
  return finalBrokers
}

function isAllowedToPayReferalCommission(actor) {
  return [ActorType.BROKER, ActorType.BROKER_PM].includes(actor.type)
}
