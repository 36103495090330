import ApiBase from 'app/services/api/entities/Base'

function* all(folderId, params = {} ) {
  return yield ApiBase.privateGet(`/v1/nexfolders/${folderId}/nexfiles/`, {
    params
  })
}

export default {
  all,
}
