import { colors, fonts, fontSizes, measurements } from 'theme'
import css from 'styled-jsx/css'


export const dropDownProps = {
  minHeight: '3.25rem',
  minWidth: '100%',
  textIndent: '10px',
  borderColor: colors.borderInput,
  hoverColor: colors.tertiary,
  className: 'dropdown-neximo',
  hideSearchClassName: 'hide-search',
  optionTextColor: 'rgba(22, 50, 92, 0.87)'
}

export default css.global`
  .react-selectize {
    color: ${colors.black};
  }
  .react-selectize.root-node {
    position: relative;
    width: ${dropDownProps.minWidth};
  }
  .react-selectize.root-node.disabled {
    pointer-events: none;
  }
  .react-selectize.root-node .react-selectize-control {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 2px;
  }
  .react-selectize.root-node
    .react-selectize-control
    .react-selectize-placeholder {
    display: block;
    line-height: ${dropDownProps.minHeight};
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    position: absolute;
    max-width: calc(100% - 56px);
  }
  .react-selectize.root-node
    .react-selectize-control
    .react-selectize-search-field-and-selected-values {
    display: flex;
    min-height: ${dropDownProps.minHeight};
    flex-grow: 1;
    flex-wrap: wrap;
  }
  .react-selectize.root-node
    .react-selectize-control
    .react-selectize-search-field-and-selected-values
    .resizable-input {
    background: none;
    border: none;
    outline: none;
    font-size: 1rem;
    margin: 2px;
    padding: 4px 0px;
    vertical-align: middle;
    width: 0px;
    color: ${colors.text};
  }
  .react-selectize.root-node
    .react-selectize-control
    .react-selectize-search-field-and-selected-values
    .value-wrapper {
    display: flex;
    align-items: center;
  }
  .react-selectize.root-node
    .react-selectize-control
    .react-selectize-reset-button-container,
  .react-selectize.root-node
    .react-selectize-control
    .react-selectize-toggle-button-container {
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${dropDownProps.minHeight};
  }
  .react-selectize.root-node
    .react-selectize-control
    .react-selectize-reset-button-container {
    width: 16px;
  }
  .react-selectize.root-node
    .react-selectize-control
    .react-selectize-toggle-button-container {
    width: 32px;
  }
  .react-selectize.root-node
    .react-selectize-control
    .react-selectize-reset-button-container:hover
    .react-selectize-reset-button
    path {
    stroke: ${colors.borderInput};
  }
  .react-selectize.root-node
    .react-selectize-control
    .react-selectize-reset-button
    path {
    transition: stroke 0.5s 0s ease;
    stroke: ${colors.dirtGray};
    stroke-linecap: square;
    stroke-linejoin: mitter;
  }
  .react-selectize.root-node
    .react-selectize-control
    .react-selectize-toggle-button
    path {
    fill: ${colors.dirtGray};
  }
  .react-selectize.dropdown-menu-wrapper {
    position: absolute;
  }
  .react-selectize.dropdown-menu-wrapper.tethered {
    min-width: ${dropDownProps.minWidth};
  }
  .react-selectize.dropdown-menu-wrapper:not(.tethered) {
    width: 100%;
  }
  .react-selectize.dropdown-menu {
    box-sizing: border-box;
    overflow: auto;
    position: absolute;
    max-height: 200px;
    z-index: 10;
  }
  .react-selectize.dropdown-menu.tethered {
    min-width: ${dropDownProps.minWidth};
  }
  .react-selectize.dropdown-menu:not(.tethered) {
    width: 100%;
  }
  .react-selectize.dropdown-menu .groups.as-columns {
    display: flex;
  }
  .react-selectize.dropdown-menu .groups.as-columns > div {
    flex: 1;
  }
  .react-selectize.dropdown-menu .option-wrapper {
    cursor: pointer;
    outline: none;
  }
  .multi-select.react-selectize.root-node .simple-value {
    display: inline-block;
    margin: 2px;
    vertical-align: middle;
  }
  .multi-select.react-selectize.root-node .simple-value span {
    display: inline-block;
    padding: 2px 5px 4px;
    vertical-align: center;
  }
  .simple-select.react-selectize.root-node .simple-value {
    margin: 2px;
  }
  .simple-select.react-selectize.root-node .simple-value span {
    display: inline-block;
    vertical-align: center;
  }
  .react-selectize.default {
    font: ${fonts.medium};
  }
  .react-selectize.default.root-node .react-selectize-control {
    background-color: ${colors.white};
    border: 1px solid;
    border-color: ${dropDownProps.borderColor};
    border-radius: 4px;
    font-size: 1em;
  }
  .react-selectize.default.root-node
    .react-selectize-control
    .react-selectize-placeholder {
    color: ${colors.borderInput};
    text-indent: ${dropDownProps.textIndent};
  }
  .react-selectize.default.root-node
    .react-selectize-control
    .react-selectize-search-field-and-selected-values {
    padding-left: 5px;
  }
  .react-selectize.default.root-node.open.flipped .react-selectize-control {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .react-selectize.default.root-node.open:not(.flipped)
    .react-selectize-control {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .react-selectize.dropdown-menu-wrapper.default {
    overflow: hidden;
  }
  .react-selectize.dropdown-menu-wrapper.default
    .dropdown-menu.custom-enter-active,
  .react-selectize.dropdown-menu-wrapper.default
    .dropdown-menu.custom-leave-active {
    transition: transform 0.2s 0s ease;
  }
  .react-selectize.dropdown-menu-wrapper.default
    .dropdown-menu.flipped.custom-enter {
    transform: translateY(100%);
  }
  .react-selectize.dropdown-menu-wrapper.default
    .dropdown-menu.flipped.custom-enter-active {
    transform: translateY(0%);
  }
  .react-selectize.dropdown-menu-wrapper.default
    .dropdown-menu.flipped.custom-leave {
    transform: translateY(0%);
  }
  .react-selectize.dropdown-menu-wrapper.default
    .dropdown-menu.flipped.custom-leave-active {
    transform: translateY(100%);
  }
  .react-selectize.dropdown-menu-wrapper.default
    .dropdown-menu:not(.flipped).custom-enter {
    transform: translateY(-100%);
  }
  .react-selectize.dropdown-menu-wrapper.default
    .dropdown-menu:not(.flipped).custom-enter-active {
    transform: translateY(0%);
  }
  .react-selectize.dropdown-menu-wrapper.default
    .dropdown-menu:not(.flipped).custom-leave {
    transform: translateY(0%);
  }
  .react-selectize.dropdown-menu-wrapper.default
    .dropdown-menu:not(.flipped).custom-leave-active {
    transform: translateY(-100%);
  }
  .react-selectize.dropdown-menu.default {
    background: ${colors.white};
    border: 1px solid ${colors.lightDullGray};
    margin-top: -1px;
  }
  .react-selectize.dropdown-menu.default.flipped {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .react-selectize.dropdown-menu.default:not(.flipped) {
    border-color: ${dropDownProps.borderColor};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .react-selectize.dropdown-menu.default .no-results-found {
    color: ${colors.borderInput} !important;
    font-style: oblique;
    padding: 8px 10px;
  }
  .react-selectize.dropdown-menu.default .simple-group-title {
    background-color: ${colors.lightBg};
    padding: 8px 8px;
  }
  .react-selectize.dropdown-menu.default .option-wrapper.highlight {
    background: ${colors.grayBackground};
    color: ${colors.leatherBlack};
  }
  .react-selectize.dropdown-menu.default .option-wrapper .simple-option {
    color: ${colors.dirtGrayGradiant};
    cursor: pointer;
    padding: 8px 10px;
  }
  .react-selectize.dropdown-menu.default
    .option-wrapper
    .simple-option.not-selectable {
    background-color: ${colors.lighterGray};
    color: ${colors.dirtGray};
    cursor: default;
    font-style: oblique;
    text-shadow: 0px 1px 0px ${colors.white};
  }
  .multi-select.react-selectize.default.root-node .simple-value {
    background: ${colors.grayBackground};
    border: 1px solid ${colors.lightBorder};
    border-radius: 2px;
    color: ${colors.textBlueBright};
  }
  .simple-select.react-selectize.default.root-node.open
    .react-selectize-control {
    background-color: ${colors.white};
  }
  .simple-select.react-selectize.default.root-node:not(.open)
    .react-selectize-control {
    background-color: ${colors.lightBg};
    background-image: linear-gradient(to bottom, ${colors.grayBackground}, ${colors.disabled});
  }
  .react-selectize.bootstrap3 {
    font: ${fonts.medium};
  }
  .react-selectize.bootstrap3.root-node.open .react-selectize-control {
    background-color: ${colors.white};
    border: 1px solid ${dropDownProps.borderColor};
    /*box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);*/
  }
  .react-selectize.bootstrap3.root-node .react-selectize-control {
    border: 1px solid;
    border-color: ${dropDownProps.borderColor};
    border-radius: 4px;
    font-size: 1em;
  }
  .react-selectize.bootstrap3.root-node
    .react-selectize-control
    .react-selectize-placeholder {
    color: ${colors.borderInput};
    text-indent: ${dropDownProps.textIndent};
  }
  .react-selectize.bootstrap3.root-node
    .react-selectize-control
    .react-selectize-search-field-and-selected-values {
    padding-left: 5px;
  }
  .react-selectize.bootstrap3.dropdown-menu-wrapper.flipped {
    margin-bottom: 5px;
  }
  .react-selectize.bootstrap3.dropdown-menu-wrapper:not(.flipped) {
    margin-top: -5px;
    z-index: 100;
  }
  .react-selectize.bootstrap3.dropdown-menu-wrapper
    .dropdown-menu.custom-enter-active,
  .react-selectize.bootstrap3.dropdown-menu-wrapper
    .dropdown-menu.custom-leave-active {
    transition: opacity 0.2s 0s ease;
  }
  .react-selectize.bootstrap3.dropdown-menu-wrapper
    .dropdown-menu.custom-enter {
    opacity: 0;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: alpha(opacity=0);
  }
  .react-selectize.bootstrap3.dropdown-menu-wrapper
    .dropdown-menu.custom-enter-active {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  .react-selectize.bootstrap3.dropdown-menu-wrapper
    .dropdown-menu.custom-leave {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  .react-selectize.bootstrap3.dropdown-menu-wrapper
    .dropdown-menu.custom-leave-active {
    opacity: 0;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: alpha(opacity=0);
  }
  .react-selectize.bootstrap3.dropdown-menu {
    background: ${colors.white};
    border: 1px solid ${colors.lightDullGray};
    border-radius: 4px;
    /*box-shadow: 0 6px 12px rgba(0,0,0,0.175);*/
  }
  .react-selectize.bootstrap3.dropdown-menu.flipped {
    margin-bottom: 5px;
  }
  .react-selectize.bootstrap3.dropdown-menu:not(.flipped) {
    margin-top: 5px;
  }
  .react-selectize.bootstrap3.dropdown-menu .no-results-found {
    color: ${colors.borderInput} !important;
    font-style: oblique;
    padding: 8px 10px;
  }
  .react-selectize.bootstrap3.dropdown-menu
    .groups:not(.as-columns)
    > div:not(:first-child) {
    border-top: 1px solid ${colors.tertiary};
    margin: 12px 0px 0px 0px;
  }
  .react-selectize.bootstrap3.dropdown-menu .simple-group-title {
    background-color: ${colors.white};
    color: ${colors.dirtGray};
    padding: 8px 8px;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  }
  .react-selectize.bootstrap3.dropdown-menu .option-wrapper.highlight {
    background: ${colors.capriBlue};
  }
  .react-selectize.bootstrap3.dropdown-menu
    .option-wrapper.highlight
    .simple-option {
    color: ${colors.white};
  }
  .react-selectize.bootstrap3.dropdown-menu .option-wrapper .simple-option {
    color: ${colors.leatherBlack};
    cursor: pointer;
    padding: 8px 10px;
  }
  .react-selectize.bootstrap3.dropdown-menu
    .option-wrapper
    .simple-option.not-selectable {
    background-color: ${colors.lighterGray};
    color: ${colors.dirtGray};
    cursor: default;
    font-style: oblique;
    text-shadow: 0px 1px 0px ${colors.white};
  }
  .multi-select.react-selectize.bootstrap3.root-node .simple-value {
    background: ${colors.lightGray};
    border-radius: 4px;
    color: ${colors.leatherBlack};
  }
  .react-selectize.material {
    font-family: Roboto, sans-serif;
  }
  .react-selectize.material.root-node.open .react-selectize-control:after {
    transform: scaleX(1);
  }
  .react-selectize.material.root-node .react-selectize-control {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  .react-selectize.material.root-node .react-selectize-control:after {
    background-color: ${colors.info};
    content: '';
    transform: scaleX(0);
    transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    left: 0px;
    bottom: -1px;
    width: 100%;
    height: 2px;
  }
  .react-selectize.material.root-node
    .react-selectize-control
    .react-selectize-placeholder {
    color: rgba(0, 0, 0, 0.3);
    text-indent: 4px;
  }
  .react-selectize.material.dropdown-menu-wrapper.flipped {
    margin-bottom: 8px;
  }
  .react-selectize.material.dropdown-menu-wrapper.flipped .dropdown-menu {
    transform-origin: 100% 100%;
  }
  .react-selectize.material.dropdown-menu-wrapper:not(.flipped) {
    margin-top: 8px;
  }
  .react-selectize.material.dropdown-menu-wrapper:not(.flipped) .dropdown-menu {
    transform-origin: 0% 0%;
  }
  .react-selectize.material.dropdown-menu-wrapper
    .dropdown-menu.custom-enter-active,
  .react-selectize.material.dropdown-menu-wrapper
    .dropdown-menu.custom-leave-active {
    transition: transform 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms,
      opacity 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }
  .react-selectize.material.dropdown-menu-wrapper .dropdown-menu.custom-enter {
    opacity: 0;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: alpha(opacity=0);
    transform: scale(0, 0);
  }
  .react-selectize.material.dropdown-menu-wrapper
    .dropdown-menu.custom-enter-active {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    transform: scale(1, 1);
  }
  .react-selectize.material.dropdown-menu-wrapper .dropdown-menu.custom-leave {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    transform: scale(1, 1);
  }
  .react-selectize.material.dropdown-menu-wrapper
    .dropdown-menu.custom-leave-active {
    opacity: 0;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: alpha(opacity=0);
  }
  .react-selectize.material.dropdown-menu {
    background-color: ${colors.white};
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.118) 0px 1px 6px,
      rgba(0, 0, 0, 0.118) 0px 1px 4px;
    max-height: 250px;
    padding: 8px 0px;
  }
  .react-selectize.material.dropdown-menu.flipped {
    margin-bottom: 8px;
  }
  .react-selectize.material.dropdown-menu:not(.flipped) {
    margin-top: 8px;
  }
  .react-selectize.material.dropdown-menu .no-results-found {
    font-style: oblique;
    font-size: 16px;
    height: 32px;
    padding: 0px 16px;
    display: flex;
    align-items: center;
  }
  .react-selectize.material.dropdown-menu
    .groups:not(.as-columns)
    > div:not(:last-child) {
    border-bottom: 1px solid ${colors.tertiary};
  }
  .react-selectize.material.dropdown-menu .simple-group-title {
    color: ${colors.dirtGrayGradiant};
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 48px;
    padding: 0px 10px;
  }
  .react-selectize.material.dropdown-menu .option-wrapper.highlight {
    background-color: rgba(0, 0, 0, 0.098);
  }
  .react-selectize.material.dropdown-menu .option-wrapper .simple-option {
    color: rgba(0, 0, 0, 0.875);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 16px;
    height: 48px;
    padding: 0px 16px;
  }
  .react-selectize.material.dropdown-menu
    .option-wrapper
    .simple-option.not-selectable {
    background-color: ${colors.lighterGray};
    color: ${colors.dirtGray};
    cursor: default;
    font-style: oblique;
    text-shadow: 0px 1px 0px ${colors.white};
  }
  .multi-select.react-selectize.material.root-node .simple-value span {
    padding: 0px;
  }
  .multi-select.react-selectize.material.root-node .simple-value span:after {
    content: ',';
  }
  .simple-select.react-selectize.material.root-node .simple-value {
    margin: 4px 3px 3px 2px;
  }

  .wrapper-dropdown-selectize.error .react-selectize.bootstrap3.root-node .react-selectize-control{
    border: 1px solid ${colors.danger};
  }

  .wrapper-dropdown-selectize {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
  }

  .wrapper-dropdown-selectize label.fit-field.label {
    font-weight: 600;
    padding: 0.5rem 0;
    font-size: 0.875rem;
  }

  .wrapper-dropdown-selectize .loading {
    z-index: 1;
    right: 0.4rem;
    bottom: 0.5rem;
    position: absolute;
    background: ${colors.white};
  }
  .wrapper-dropdown-selectize .loading.disabled {
    background-color: ${colors.disabled};
  }
 
  .react-selectize.dropdown-neximo.fit-field .react-selectize-search-field-and-selected-values,
  .react-selectize.dropdown-neximo.fit-field .react-selectize-toggle-button-container,
  .react-selectize.dropdown-neximo.fit-field .react-selectize-placeholder,
  .react-selectize.dropdown-neximo.fit-field .value-wrapper,
  .react-selectize.dropdown-neximo.fit-field .value-wrapper .item,
  .react-selectize.dropdown-neximo.fit-field .value-wrapper {
    height: 2.1875rem;
    min-height: 2.187rem;
    max-height: 2.1875rem;
  }
  .react-selectize.dropdown-neximo.fit-field .value-wrapper,
  .react-selectize.dropdown-neximo.fit-field .value-wrapper .item {
    background-color: transparent;
  }
  .react-selectize.dropdown-neximo.fit-field .value-wrapper .item {
    height: 1.5rem;
    max-height: 1.5rem;
    min-height: 1.5rem;
  }
  .react-selectize.dropdown-neximo.fit-field .value-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
  }
  .react-selectize.dropdown-neximo.fit-field .value-wrapper .item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-selectize.dropdown-neximo.fit-field .react-selectize-placeholder {
    display: flex;
    align-items: center;
  }
  .react-selectize.dropdown-neximo.fit-field .react-selectize-control {
    padding: 0;
    height: 2.1875rem;
    align-items: center;
    min-height: 2.1875rem;
  }

  .wrapper-dropdown-selectize.fit-field-multiple {
    margin-top: 1rem;
    height: fit-content;
  }

  .react-selectize.dropdown-neximo.fit-field.fit-field-multiple .react-selectize-control,
  .react-selectize.dropdown-neximo.fit-field.fit-field-multiple .react-selectize-search-field-and-selected-values {
    max-height: max-content;
    height: auto;
  }
  .react-selectize.dropdown-neximo.fit-field .react-selectize-search-field-and-selected-values {
    width: max-content;
  }


`

export const overwrites = css.global`
  .${dropDownProps.className}.react-selectize.root-node
    .react-selectize-control
    .react-selectize-search-field-and-selected-values
    .value-wrapper {
  }

  .${dropDownProps.className}.react-selectize.dropdown-menu
    .option-wrapper.highlight {
    background-color: ${dropDownProps.hoverColor};
  }

  .${dropDownProps.className}.react-selectize.dropdown-menu
    .option-wrapper.highlight
    * {
      font-weight: bold;
    }

  .${dropDownProps.className}.react-selectize.root-node
    .react-selectize-control
    .react-selectize-placeholder {
    color: ${dropDownProps.borderColor};
    font: ${fonts.medium};
    line-height: ${dropDownProps.minHeight};
    font-size: ${fontSizes.p};
  }
  .${dropDownProps.className} .react-selectize-control {
    background-color: ${colors.white};
  }
  .${dropDownProps.className}.disabled .react-selectize-control {
    background-color: ${colors.white};
  }

  .${dropDownProps.className}.react-selectize.bootstrap3.root-node.simple-select.form-input-margin
    .react-selectize-control
    .react-selectize-search-field-and-selected-values {
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
  }
`

export const option = css`
  .option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem 1.5rem;
    min-height: 2.5rem;
    text-align: left;
    font: ${fonts.regular};
    color: ${dropDownProps.optionTextColor};
    font-size: 12px;
  }
`

export const item = css`
  .item {
    color: ${dropDownProps.optionTextColor};
    line-height: ${dropDownProps.minHeight};
    display: block;
    background: ${colors.white};
    margin: 0;
    padding-left: 0.5rem;
    font-family: Arial;
    font: ${fonts.regular};
    font-size: 0.95rem;;
  }
`

export const dropDownCheckboxStyles = css`

.option-checkbox-wrapper label,
  .dropdown-check-list .anchor {
    position: relative;
    user-select: none;
    border: none;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }
  .option-checkbox-wrapper {
    padding: 0.6rem;
  }
  .option-checkbox-wrapper.label {
    padding: 0;
  }

  .option-checkbox-wrapper label {
    justify-content: space-between;
  }

  .option-checkbox-wrapper input[type="checkbox"] {
    width: auto;
    height: fit-content;
  }

  .dropdown-check-list .anchor:after {
    position: absolute;
    content: "";
    border: 1px solid black;
    border-top-color: transparent;
    border-left-color: transparent;
    padding: 2px;
    right: 10px;
    top: 35%;
    transform: rotate(45deg) translate(50%, -50%);
    transition: all 0.3s;
  }

  .dropdown-check-list ul.items {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: hidden;
    height: 2.1875rem;
    margin: 0;
  }

  .dropdown-check-list ul.items li {
    list-style: none;
  }

  .dropdown-check-list.open .items {
    display: block;
    overflow: initial;
    height: auto;
  }

  .dropdown-check-list.open .anchor:after {
    transform: rotate(45deg) translate(50%, -50%);
    border: 1px solid black;
    border-bottom-color: transparent;
    border-right-color: transparent;
    top: 45%;
  }

`