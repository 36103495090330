import { formatDate } from 'services/date'
import { companyConfig } from 'globalConfigApp'
import config from 'config'
import utils from 'services/utils'

import { image } from 'services'
import { NEXIMO_ADDRESS } from 'services/config'
import { getLabelPropertyTypeOperation } from 'services/seo'
const CONTACT_PHONE = config('CONTACT_PHONE')

export const dataOrganizationJsonLD = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  legalName: companyConfig.name,
  areaServed: 'MX',
  availableLanguage: ['English', 'Spanish'],
  address: `${NEXIMO_ADDRESS}`,
  url: `https://www.${companyConfig.companyUrl}/`,
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: `+52 ${CONTACT_PHONE}`,
      email: `info@${companyConfig.companyDomain}`,
      contactType: 'customer support'
    }
  ],
  logo: 'https://cdn.neximo.mx/web/img/logo-blue.svg',
  sameAs: [
    'https://web.facebook.com/NeximoMexico/',
    'https://www.linkedin.com/company/neximo/',
    'https://www.youtube.com/channel/UCGwPyDPheL-T79Vu7lNFYxA',
    'https://www.instagram.com/neximomx/'
  ]
}

export const dataItemListJsonLD = {
  '@context': 'http://schema.org',
  '@type': 'ItemList',
  name: 'Encuentra tu próximo hogar',
  additionalType: 'SiteNavigationElement',
  itemListElement: []
}

export const dataProductJsonLD = {
  '@context': 'http://schema.org',
  '@type': 'Product',
  productID: '',
  sku: '',
  name: '',
  description: '',
  url: '',
  category: '',
  itemCondition: 'http://schema.org/UsedCondition',
  image: '',
  offers: {
    '@type': 'Offer',
    price: '',
    priceCurrency: 'MXN',
    availability: 'http://schema.org/InStock',
    availabilityStarts: '',
    areaServed: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        addressCountry: {
          '@type': 'Country',
          name: 'MX'
        },
        addressLocality: '',
        addressRegion: ''
      }
    },
    seller: {
      '@type': 'Person',
      name: '',
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: '',
          email: '',
          contactType: 'sales'
        }
      ]
    }
  }
}

export const getJsonLDListItemProperties = data => {
  const jsonLDListItem = { ...dataItemListJsonLD }
  jsonLDListItem.itemListElement = data.map((propertyData, index) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      name: propertyData.title,
      url: propertyData.url
    }
  })

  return jsonLDListItem
}

export const getJsonLDProduct = data => {
  const { id, imagesJson, operationType, propertyType, description } = data
  const gallery = imagesJson.filter(ij => ij.imageType === 'GALLERY')
  const firstImage =
    gallery.length > 0
      ? utils.getCroppedImage({file: gallery[0].url, width: 350, height: 190})
      : image('property/defaultproperty.jpg')

  const jsonLD = { ...dataProductJsonLD }
  const category = getLabelPropertyTypeOperation(propertyType, operationType)

  jsonLD.productID = `NEX-${data.id}`
  jsonLD.sku = `NEX-${data.id}`
  jsonLD.name = data.title
  jsonLD.description = data.description
  jsonLD.url = data.url
  jsonLD.category = category
  jsonLD.itemCondition =
    data.age === 0
      ? 'https://schema.org/NewCondition'
      : 'http://schema.org/UsedCondition'
  jsonLD.image = firstImage
  jsonLD.offers.price = data.price.amount
  jsonLD.offers.priceCurrency = data.price.currency
  jsonLD.offers.availability = 'http://schema.org/InStock'
  jsonLD.offers.availabilityStarts = formatDate(
    data.createdAt,
    'YYYY-MM-DD H:mm:s'
  )
  jsonLD.offers.areaServed.address.addressCountry = 'MX'
  jsonLD.offers.areaServed.address.addressLocality = data.district
  jsonLD.offers.areaServed.address.addressRegion = data.state
  if (data.brokerData) {
    jsonLD.offers.seller.name = `${data.brokerData.firstName} ${
      data.brokerData.lastName
    }`
    jsonLD.offers.seller.contactPoint[0].telephone = `${
      data.brokerData.telephone
    }`
    jsonLD.offers.seller.contactPoint[0].email = data.brokerData.mailboxEmail
  }

  return jsonLD
}
