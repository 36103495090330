import { createReducer, createActions } from 'reduxsauce'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback,
} from './utils'

const INITIAL_STATE = {
  propertyDescription: {
    description: null,
    feedback: initialFeedback(),
  },
}

const { Types, Creators } = createActions(
  {
    propertyDescription: ['propertyId', 'description'],
    propertyDescriptionSuccess: ['description'],
    propertyDescriptionFailure: ['error'],
    propertyDescriptionClean: [],

    cleanState: [],
  },
  { prefix: 'IA_' },
)

export const propertyDescription = (state, { description }) => ({
  ...state,
  propertyDescription: {
    ...state.propertyDescription,
    feedback: requestFeedback(),
  },
})

export const propertyDescriptionSuccess = (state, { description }) => ({
  ...state,
  propertyDescription: {
    ...state.propertyDescription,
    description: String(description)
      .split('\n')
      .filter(Boolean)
      .map(t => `<p>${t}</p> <br />`)
      .join(' '),
    feedback: successFeedback(),
  },
})

export const propertyDescriptionFailure = (state, { error }) => ({
  ...state,
  propertyDescription: {
    ...state.propertyDescription,
    feedback: failureFeedback(error),
  },
})

export const propertyDescriptionClean = state => ({
  ...state,
  propertyDescription: {
    ...state.propertyDescription,
    description: INITIAL_STATE.propertyDescription.description,
    feedback: initialFeedback(),
  },
})

const cleanState = () => INITIAL_STATE

const HANDLERS = {
  [Types.PROPERTY_DESCRIPTION]: propertyDescription,
  [Types.PROPERTY_DESCRIPTION_SUCCESS]: propertyDescriptionSuccess,
  [Types.PROPERTY_DESCRIPTION_FAILURE]: propertyDescriptionFailure,
  [Types.PROPERTY_DESCRIPTION_CLEAN]: propertyDescriptionClean,

  [Types.CLEAN_STATE]: cleanState,
}

export const IAActionTypes = Types
export const IAActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
