import { useState, useEffect } from 'react'

const getWindowSize = () => {
  const { innerWidth, innerHeight } =
    (typeof window !== 'undefined' && window) || {}
  return {
    innerWidth: innerWidth || 0,
    innerHeight: innerHeight || 0
  }
}

/**
 * get window innerHeight and innerWidth size
 * @return {Object} - { innerHeight, innerWidth }
 */

const useResizeWindow = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize)
  useEffect(() => {
    const windowResizeHandle = ({ target }) => {
      const { innerHeight, innerWidth } = target
      setWindowSize({ innerHeight, innerWidth })
    }

    typeof window !== 'undefined' &&
      window.addEventListener('resize', windowResizeHandle)

    return () => {
      typeof window !== 'undefined' &&
        window.removeEventListener('resize', windowResizeHandle)
    }
  }, [])
  return windowSize
}

export default useResizeWindow
