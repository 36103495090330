import { createReducer, createActions } from 'reduxsauce'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback,
} from 'reducers/utils'

/**
const LOCATION_STATE_EXAMPLE = {
  "Ciudad de México": {
    id: 9,
    feedback: initialFeedback(),
    ...
    "Coyoacán": {
      id: 1,
      feedback: initialFeedback(),
      
      ...
      "Pedregal": {
        id: 1,
        postalCode: 12345,
        feedback: initialFeedback(),
      },
    },
  },
}
*/

export const INITIAL_STATE = {
  states: {
    feedback: initialFeedback(),
  },
}

const { Types, Creators } = createActions(
  {
    states: [],
    statesSuccess: ['states'],
    statesFailure: ['errors'],
    statesSkipRequest: [],

    municipalities: ['stateName'],
    municipalitiesSuccess: ['stateName', 'municipalities'],
    municipalitiesFailure: ['stateName', 'errors'],
    municipalitiesSkipRequest: ['stateName'],

    neighborhoods: ['stateName', 'municipalityName'],
    neighborhoodsSuccess: ['stateName', 'municipalityName', 'neighborhoods'],
    neighborhoodsFailure: ['stateName', 'municipalityName', 'errors'],
    neighborhoodsSkipRequest: ['stateName', 'municipalityName'],

    getByFullLocation: ['stateName', 'municipalityName', 'neighborhoodName'],

    cleanState: [],
  },
  { prefix: 'LOCATION_' },
)

const states = state => ({
  ...state,
  states: {
    ...state.states,
    feedback: requestFeedback(),
  },
})

const statesSuccess = (state, { states }) => ({
  ...state,
  states: {
    ...state.states,
    ...states,
    feedback: successFeedback(),
  },
})

const statesFailure = (state, { errors }) => ({
  ...state,
  states: {
    ...state.states,
    feedback: failureFeedback(errors),
  },
})

const statesSkipRequest = state => ({
  ...state,
  states: {
    ...state.states,
    feedback: successFeedback(),
  },
})

const municipalities = (state, { stateName }) => ({
  ...state,
  states: {
    ...state.states,
    [stateName]: {
      ...state.states[stateName],
      feedback: requestFeedback(),
    },
  },
})

const municipalitiesSuccess = (state, { stateName, municipalities }) => ({
  ...state,
  states: {
    ...state.states,
    [stateName]: {
      ...state.states[stateName],
      ...municipalities,
      feedback: successFeedback(),
    },
  },
})

const municipalitiesFailure = (state, { stateName, errors }) => ({
  ...state,
  states: {
    ...state.states,
    [stateName]: {
      ...state.states[stateName],
      feedback: failureFeedback(errors),
    },
  },
})

const municipalitiesSkipRequest = (state, { stateName }) => ({
  ...state,
  states: {
    ...state.states,
    [stateName]: {
      ...state.states[stateName],
      feedback: successFeedback(),
    },
  },
})

const neighborhoods = (state, { stateName, municipalityName }) => ({
  ...state,
  states: {
    ...state.states,
    [stateName]: {
      ...state.states[stateName],
      [municipalityName]: {
        ...state.states[stateName][municipalityName],
        feedback: requestFeedback(),
      },
    },
  },
})

const neighborhoodsSuccess = (
  state,
  { stateName, municipalityName, neighborhoods },
) => ({
  ...state,
  states: {
    ...state.states,
    [stateName]: {
      ...state.states[stateName],
      [municipalityName]: {
        ...state.states[stateName][municipalityName],
        ...neighborhoods,
        feedback: successFeedback(),
      },
    },
  },
})

const neighborhoodsFailure = (
  state,
  { stateName, municipalityName, errors },
) => ({
  ...state,
  states: {
    ...state.states,
    [stateName]: {
      ...state.states[stateName],
      [municipalityName]: {
        ...state.states[stateName][municipalityName],
        feedback: failureFeedback(errors),
      },
    },
  },
})

const neighborhoodsSkipRequest = (state, { stateName, municipalityName }) => ({
  ...state,
  states: {
    ...state.states,
    [stateName]: {
      ...state.states[stateName],
      [municipalityName]: {
        ...state.states[stateName][municipalityName],
        feedback: successFeedback(),
      },
    },
  },
})

const cleanState = () => INITIAL_STATE

const HANDLERS = {
  [Types.STATES]: states,
  [Types.STATES_SUCCESS]: statesSuccess,
  [Types.STATES_FAILURE]: statesFailure,
  [Types.STATES_SKIP_REQUEST]: statesSkipRequest,

  [Types.NEIGHBORHOODS]: neighborhoods,
  [Types.NEIGHBORHOODS_SUCCESS]: neighborhoodsSuccess,
  [Types.NEIGHBORHOODS_FAILURE]: neighborhoodsFailure,
  [Types.NEIGHBORHOODS_SKIP_REQUEST]: neighborhoodsSkipRequest,

  [Types.MUNICIPALITIES]: municipalities,
  [Types.MUNICIPALITIES_SUCCESS]: municipalitiesSuccess,
  [Types.MUNICIPALITIES_FAILURE]: municipalitiesFailure,
  [Types.MUNICIPALITIES_SKIP_REQUEST]: municipalitiesSkipRequest,

  [Types.CLEAN_STATE]: cleanState,
}

export const LocationActionTypes = Types
export const LocationActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)