import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { IonIcon } from 'controls'
import { colors } from 'theme'

import styles, { withRemove } from './styles'

const Label = ({
  color,
  children,
  size,
  className,
  style,
  disabled,
  styleType,
  ...rest
}) => (
  <span
    {...rest}
    className={classnames('label', color, size, className, styleType, {
      disabled,
      clickable: Boolean(rest.onClick),
    })}
    style={style}
  >
    {children}
    <style jsx>{styles}</style>
  </span>
)

Label.propTypes = {
  children: PropTypes.any.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'danger',
    'info',
    'none',
    'primary',
    'secondary',
    'success',
    'lightSuccess',
    'warning',
    'lightWarning',
    'tertiary',
    'darkFrame',
    'text',
    'purple',
    'babyBlue'
  ]),
  styleType: PropTypes.oneOf(['normal', 'rounded']),
  size: PropTypes.oneOf(['micro', 'tiny', 'small', 'normal', 'medium', 'large'])
}

Label.defaultProps = {
  style: {},
  color: 'none',
  size: 'medium'
}

class LabelWithRemove extends Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    onRemove: PropTypes.func.isRequired,
    className: PropTypes.string
  }

  static defaultProps = {
    disabled: false
  }

  render() {
    const { onRemove, disabled, className = '', ...rest } = this.props
    return (
      <Label
        color="primary"
        className={classnames(className)}
        disabled={disabled}
        {...rest}
      >
        {this.props.children}
        <button className="button" onClick={onRemove} disabled={disabled}>
          <IonIcon
            icon="ios-close-circle"            
            className={classnames("success-icon", "btn-close", className)}
          />
        </button>
        <style jsx>{withRemove}</style>
      </Label>
    )
  }
}

export { Label as default, LabelWithRemove }
