import { take, put, call } from 'redux-saga/effects'
import { stopSubmit, touch } from 'redux-form'

import {
  ValidateFormActionTypes,
  ValidateFormActionCreators
} from 'app/reducers/ValidateFormRedux'
import { mapAsyncErrors } from 'app/asyncValidators/utils'
import { keyify } from 'app/services/object'

import asyncValidates from 'app/asyncValidators'

export function* asyncValidateForm({ formName, errors, asyncValidatorName }) {
  try {
    const { errorCodes, ...restErrors } = errors
    const asyncValidator = asyncValidates[asyncValidatorName]

    const asyncErrors = asyncValidator(restErrors)

    const sanitizeAsyncErrors = mapAsyncErrors(asyncErrors)

    yield put(touch(formName, ...keyify(sanitizeAsyncErrors)))
    yield put(stopSubmit(formName, sanitizeAsyncErrors))
    yield put(ValidateFormActionCreators.updateAsyncErrors(formName, sanitizeAsyncErrors))
  } catch (err) {
    console.error(`Error at Validate Form Sagas: `, { formName, err })
  }
}

export function* validateFormWatcher() {
  while (true) {
    const action = yield take(ValidateFormActionTypes.ASYNC_VALIDATE_FORM)
    yield call(asyncValidateForm, action)
  }
}
