import { call, put, select, delay } from 'redux-saga/effects'
import { utils } from 'services'
import { getDocumentPages, getDocumentPostData } from 'selectors/documentFile'
import { DocumentFileActionCreators } from 'app/reducers/DocumentFileRedux'
import { FileUploadActionCreators } from 'app/reducers/FileUploadRedux'
import { BondActionCreators } from 'app/reducers/BondRedux'
import api from 'app/services/api'

const ALLOWED_DOCUMENT_TYPES = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/jpg'
]

const EXTENSIONS = {
  'application/pdf': 'pdf',
  'image/png': 'png',
  'image/jpeg': 'jpg',
  'image/jpg': 'jpg'
}

function readFile (file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.onload = event => {
      resolve(fileReader.result)
    }

    fileReader.onerror = error => {
      reject(null)
    }

    if (file.type === 'application/pdf') {
      fileReader.readAsArrayBuffer(file)
    } else {
      fileReader.readAsDataURL(file)
    }
  })
}

export function* processDocument({ bondId, file }) {
  const fileType = file.type.toLowerCase()
  const documentRead = yield call(readFile, file)

  if (!ALLOWED_DOCUMENT_TYPES.includes(fileType) || documentRead === null) {
    return yield put(DocumentFileActionCreators.documentProcessed(0, []))
  }

  // gie some time to finish updating the state as we'll need some of its data
  yield call(delay, 50)
  const hashPath = yield select(state => state.documentFile.hashPath)

  // save original document
  yield put(FileUploadActionCreators.request(encodeURIComponent(file.name), file, '/v1/dokaltin/get_upload_form/', false, hashPath))

  // avoid processing document if the document is an image
  if (fileType !== 'application/pdf') {
    return yield put(DocumentFileActionCreators.documentProcessed(1, [{
      id: `${utils.getGuid(true)}.${EXTENSIONS[fileType]}`,
      order: 1,
      pdfPage: null,
      content: documentRead,
      imageType: fileType
    }]))
  }
  let documentFile
  try {
    documentFile = yield call(PDFJS.getDocument, documentRead)
  } catch (ex) {
    console.log(ex.message)
  }
  const pages = []
  const pageCount = documentFile.numPages

  for (let currentPage = 1; currentPage <= pageCount; currentPage++) {
    const pdfPage = yield call([documentFile, documentFile.getPage], currentPage)
    const hashedPageName = utils.getGuid(true)
    const page = {
      id: `${hashedPageName}.png`,
      order: currentPage,
      pdfPage
    }
    pages.push(page)
  }

  yield put(DocumentFileActionCreators.documentProcessed(pageCount, pages))
}

export function* uploadPages() {
  const pages = yield select(getDocumentPages)
  const hashPath = yield select(state => state.documentFile.hashPath)

  for (let iX = 0; iX < pages.length; iX++) {
    const page = pages[iX]
    const file = new File([utils.dataURItoBlob(page.content)], page.id, { type: page.imageType })

    yield put(FileUploadActionCreators.request(page.id, file, '/v1/dokaltin/get_upload_form/', false, hashPath))
  }
}

export function* uploadFinished(context) {
  const { fileName } = context
  const { bondId, pagesData } = yield select(state => ({
    bondId: state.documentFile.bondId,
    pagesData: state.documentFile.pagesData
  }))
  const pagesKeys = Object.keys(pagesData)

  if (!pagesKeys.includes(fileName)) {
    return
  }
  // give a little bit of time to finish updating the state
  yield call(delay, 50)

  const uploadingState = pagesKeys.reduce((mem, pageKey) => {
      mem.uploading = mem.uploading || pagesData[pageKey].uploading
      mem.error = mem.error || pagesData[pageKey].uploadError

      return mem
    },
    {
      uploading: false,
      error: false
    })

  if (uploadingState.uploading) {
    return
  }

  if (!uploadingState.uploading && uploadingState.error) {
    yield put(DocumentFileActionCreators.failure(uploadingState.error))
    return
  }

  // call api to post the document
  const postData = yield select(getDocumentPostData)
  const token = yield select(state => state.auth.token)
  const response = yield call(api.dokaltin.createDocument, token, bondId, postData)

  if (response.status === 201) {
    yield put(DocumentFileActionCreators.documentCreated(response.data))
    yield put(BondActionCreators.addDocumentToBond(bondId, response.data))
    return
  }

  yield put(DocumentFileActionCreators.failure(response.data))


}