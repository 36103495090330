import { put, select } from 'redux-saga/effects'
import { PROPERTY_HIGHLIGHT_ORDER_STATUS } from 'app/constants/propertyHighlight'
import PropertyHighlight from 'services/api/entities/PropertyHighlight'
import { PropertyHighlightActionCreators } from 'app/reducers/PropertyHighlightRedux'
import {
  formatPlans,
  createOrderSelector,
  shoppingCartSelector,
} from 'app/selectors/propertyHighlight'

export function* allPlans() {
  const response = yield PropertyHighlight.allPlans()

  if (response.success()) {
    return yield put(
      PropertyHighlightActionCreators.allPlansSuccess(
        formatPlans(response.data),
      ),
    )
  }

  yield put(PropertyHighlightActionCreators.allPlansFailure(response.data))
}

export function* allPropertyOrders({ propertyId }) {
  const response = yield PropertyHighlight.allPropertyOrders(propertyId)

  if (response.success()) {
    return yield put(
      PropertyHighlightActionCreators.allPropertyOrdersSuccess(propertyId.toString(), response.data.results),
    )
  }

  yield put(
    PropertyHighlightActionCreators.allPropertyOrdersFailure(response.data),
  )
}

export function* confirmOrder() {
  const orders = yield select(createOrderSelector)

  const response = yield PropertyHighlight.confirmOrder({
    orders,
  })

  if (response.success()) {
    return yield put(
      PropertyHighlightActionCreators.confirmOrderSuccess(response.data.id),
    )
  }

  yield put(PropertyHighlightActionCreators.confirmOrderFailure(response.data))
}

export function* purchaseOrder({ highlightOrderId }) {
  const response = yield PropertyHighlight.purchaseOrder(highlightOrderId)

  if (response.success()) {
    return yield put(
      PropertyHighlightActionCreators.purchaseOrderSuccess(response.data),
    )
  }

  yield put(PropertyHighlightActionCreators.purchaseOrderFailure(response.data))
}

export function* createPurchaseOrder({ highlightOrderId }) {
  const response = yield PropertyHighlight.createPurchaseOrder(highlightOrderId)

  if (response.success()) {
    return yield put(
      PropertyHighlightActionCreators.createPurchaseOrderSuccess({
        paymentOrderUrl: response.data.url,
        status: PROPERTY_HIGHLIGHT_ORDER_STATUS.PENDING,
        // After create a purchase order, the order status is PENDING
      }),
    )
  }

  yield put(
    PropertyHighlightActionCreators.createPurchaseOrderFailure(response.data),
  )
}

export function* refreshPurchaseOrder({ highlightOrderId }) {
  const response = yield PropertyHighlight.purchaseOrder(highlightOrderId)

  if (response.success()) {
    return yield put(
      PropertyHighlightActionCreators.refreshPurchaseOrderSuccess(response.data),
    )
  }

  yield put(
    PropertyHighlightActionCreators.refreshPurchaseOrderFailure(response.data),
  )
}

export function* checkPropertiesStatus() {
  const shoppingCart = yield select(shoppingCartSelector)
  const propertiesId = Object.keys(shoppingCart)
  const response = yield PropertyHighlight.checkPropertiesToHighlightStatus(propertiesId)

  if (response.success()) {
    return yield put(
      PropertyHighlightActionCreators.checkPropertiesStatusSuccess(response.data),
    )
  }

  yield put(
    PropertyHighlightActionCreators.checkPropertiesStatusFailure(response.data),
  )
}
