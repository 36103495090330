import { createReducer, createActions } from 'reduxsauce'

import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback
} from './utils'

const INITIAL_STATE = {
  deals: [],
  findingDealId: null,
  getAllFeedback: initialFeedback(),
  getAllWithDetails: initialFeedback(),
  findFeedback: initialFeedback()
}

const { Types, Creators } = createActions(
  {
    getAll: ['params', 'requestOptions'],
    getAllSuccess: ['deals'],
    getAllFailure: ['errors'],
    
    getAllWithDetails: ['params', 'requestOptions'],
    getAllWithDetailsSuccess: ['deals'],
    getAllWithDetailsFailure: ['errors'],
    
    updateDeals: ['deals'],

    find: ['dealId'],
    findSuccess: ['deal'],
    findFailure: ['errors'],
  },
  { prefix: 'DEALS_' }
)

const getAll = state => ({
  ...state,
  getAllFeedback: requestFeedback()
})

const getAllSuccess = (state, { deals }) => ({
  ...state,
  deals,
  getAllFeedback: successFeedback()
})

const getAllFailure = (state, { errors }) => ({
  ...state,
  getAllFeedback: failureFeedback(errors)
})

const getAllWithDetails = state => ({
  ...state,
  getAllWithDetailsFeedback: requestFeedback()
})

const getAllWithDetailsSuccess = (state, { deals }) => ({
  ...state,
  deals,
  getAllWithDetailsFeedback: successFeedback()
})

const getAllWithDetailsFailure = (state, { errors }) => ({
  ...state,
  getAllWithDetailsFeedback: failureFeedback(errors)
})

const updateDeals = (state, { deals }) => ({
  ...state,
  deals
})

const find = (state, { dealId }) => ({
  ...state,
  findingDealId: dealId,
  findFeedback: requestFeedback()
})

const findSuccess = (state, { deal }) => ({
  ...state,
  deals: state.deals.map(d => (d.id === deal.id ? { ...d, ...deal } : d)),
  findFeedback: successFeedback()
})

const findFailure = (state, { errors }) => ({
  ...state,
  findFeedback: failureFeedback(errors)
})

const HANDLERS = {
  [Types.GET_ALL]: getAll,
  [Types.GET_ALL_SUCCESS]: getAllSuccess,
  [Types.GET_ALL_FAILURE]: getAllFailure,

  [Types.GET_ALL_WITH_DETAILS]: getAllWithDetails,
  [Types.GET_ALL_WITH_DETAILS_SUCCESS]: getAllWithDetailsSuccess,
  [Types.GET_ALL_WITH_DETAILS_FAILURE]: getAllWithDetailsFailure,
  
  [Types.UPDATE_DEALS]: updateDeals,

  [Types.FIND]: find,
  [Types.FIND_SUCCESS]: findSuccess,
  [Types.FIND_FAILURE]: findFailure,
}

export const DealsActionTypes = Types
export const DealsActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
