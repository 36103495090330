import crudAction from './actions'

export default {
  name: 'appointment',
  namespace: 'appointments',
  methods: [
    ...crudAction,
    {
      name: 'scheduleAvailability',
      params: ['date', 'offset', 'brokerId', 'appointmentType'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/${namespace}/schedule_availability/`,
      urlParams: []
    },
    {
      name: 'between',
      params: ['fromUtcTime', 'toUtcTime'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/${namespace}/between/`,
      urlParams: []
    },
    {
      name: 'getPropertyCandidates',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/public/property_candidates/`,
      urlParams: []
    },
    {
      name: 'createValuation',
      params: ['data'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace }) => "/v1/organic_leads/",
      urlParams: []
    },
  ]
}
