export default {
  name: 'mlmBills',
  namespace: 'bills',
  methods: [
    {
      name: 'uploadBills',
      params: ['billing_id', 'data'],
      successCode: 200,
      method: 'Put',
      url: ({ billing_id }) => `/v1/mlm/bills/${billing_id}/`,
      urlParams: ['billing_id']
    }
  ]
}
