export default {
  name: 'networkDescendants',
  namespace: 'descendants',
  methods: [
    {
      name: 'properties',
      params: ['user_id', 'limit', 'offset'],
      successCode: 200,
      method: 'Get',
      url: ({ user_id }) => `/v1/descendants/${user_id}/properties/`,
      urlParams: ['user_id']
    },
    {
      name: 'deletedProperties',
      params: ['user_id', 'limit', 'offset'],
      successCode: 200,
      method: 'Get',
      url: ({ user_id }) => `/v1/descendants/${user_id}/deleted_properties/`,
      urlParams: ['user_id']
    },
  ]
}
