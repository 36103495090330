import { initialFeedback } from 'reducers/utils'

export function hasChildLocationsSelector(location) {
  const { id, feedback, ...rest } = location || {}
  return Object.keys(rest).length > 0
}

/**
 * @function formatLocationsSelector
 * @param {Object[]} locations - [{ id: "05", name: "Coahuila de Zaragoza"}]
 * @returns {Object}
 * @example
 * formatLocationsSelector([{ id: "05", name: "Coahuila de Zaragoza"}])
 * {
 *   "Coahuila de Zaragoza": {
 *     id: "05",
 *     feedback: initialFeedback(),
 *   }
 * }
 */
export function formatLocationsSelector(
  locations,
  config = { includePostalCode: false },
) {
  const options = locations.reduce((acc, location) => {
    acc[location.name] = {
      id: location.id,
      feedback: initialFeedback(),
    }

    if (config.includePostalCode) {
      acc[location.name].postalCode = location.postalCode
    }
    return acc
  }, {})

  return Object.keys(options)
    .sort()
    .reduce((acc, key) => {
      acc[key] = options[key]
      return acc
    }, {})
}

const _baseLocation = () => ({
  feedback: initialFeedback(),
})

export function getLocationStateSelector(states, locations = {}) {
  if ('stateName' in locations && 'municipalityName' in locations) {
    const municipalities = states[locations.stateName] || _baseLocation()
    const neighborhoods = municipalities[locations.municipalityName]
    return neighborhoods || _baseLocation()
  }

  if ('stateName' in locations) {
    const municipalities = states[locations.stateName] || _baseLocation()
    return municipalities
  }

  return states || _baseLocation()
}

export function locationOptionsSelector(states, locations = {}) {
  const locationState = getLocationStateSelector(states, locations)

  const { id, feedback, ..._locations } = locationState
  return Object.keys(_locations).map(location => ({
    id: _locations[location].id,
    'data-id': _locations[location].id,
    'data-postalcode': _locations[location].postalCode,
    value: location,
    label: _locations[location].postalCode
      ? `${location} - ${_locations[location].postalCode}`
      : location,
  }))
}

export const getLocationIdsSelector = (
  states,
  { stateName, municipalityName, neighborhoodName },
) => ({
  stateId: states[stateName]?.id,
  municipalityId: states[stateName]?.[municipalityName]?.id,
  neighborhoodId: states[stateName]?.[municipalityName]?.[neighborhoodName]?.id,
})