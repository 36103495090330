import { call, take, put, select, cancelled } from 'redux-saga/effects'
import { AppActionCreators } from 'app/reducers/AppRedux'
import api from 'app/services/api'
import APIConfig from 'app/services/api/entities/config'

export function* getConfig({ lat, lng, types=[] }) {
  const response = yield call(api.config.getAll)

  if (response.status === 200) {
    yield put(AppActionCreators.getConfigSuccess(response.data))
  } else {
    yield put(AppActionCreators.getConfigFailure(response.data))
  }
}

export function* dashboardRequest() {
  const response = yield APIConfig.dashboard()
  if (response.success()) {
    return yield put(AppActionCreators.dashboardSuccess(response.data))
  }
  return yield put(AppActionCreators.dashboardFailure(response.data))
}
