export default {
  name: 'nexsitesProperty',
  namespace: 'properties',
  methods: [
    {
      name: 'listProperties',
      params: ['data', 'customHeaders'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/nexsites/${namespace}/`,
      urlParams: []
    },
    {
      name: 'infoProperty',
      params: ['id', 'customHeaders'],
      successCode: 200,
      method: 'Get',
      url: ({ id }) => `/nexsites/property_detail/${id}/`,
      urlParams: ['id']
    },
    {
      name: 'searchLocation',
      params: [],
      successCode: 200,
      method: 'Get',
      url: () => `/nexsites/autocomplete_locations/`,
      urlParams: []
    }
  ]
}