import css from 'styled-jsx/css'
import { colors, measurements, designatedColors } from 'theme'

export default css`
  article.wrapper-article {
    font-size: 1rem;
  }

  article.wrapper-article p::selection,
  article.wrapper-article h4::selection,
  article.wrapper-article a::selection,
  article.wrapper-article li::selection {
    background: ${colors.disabled};
  }

  article.wrapper-article a {
    color: ${colors.secondary};
    transition: all 0.3s;
  }

  article.wrapper-article a:hover {
    text-decoration: underline;
  }

  article.wrapper-article h4 {
    color: ${designatedColors.titlesGrayToOrange};
  }

  article.wrapper-article .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: justify;
    min-height: 9rem;
    padding: 0rem 2.5rem 0rem 1.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0.8rem;
    border-left: 0.5rem solid ${colors.border};
  }

  article.wrapper-article .tag {
    border-radius: ${measurements.borderRadius};
    background-color: ${colors.border};
    padding: 0 0.5rem;
  }

  article.wrapper-article .tag .tag {
    background-color: ${colors.borderInput};
  }

  @media screen and (max-width: 992px) {
    article.wrapper-article .content {
      padding: 0 1rem 0;
    }
  }
`
