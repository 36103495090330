import { useState, useCallback, useEffect } from 'react';

function useCopyToClipboard(text, timeoutValue = 1500) {
  const [isCopied, setCopied] = useState(false);
  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(text).then(
      () => setCopied(true),
      () => setCopied(false),
    )
  }, []);

  useEffect(() => {
    let timer = null
    if (isCopied) {
      timer = setTimeout(() => setCopied(false), timeoutValue)
    }
    return () => clearTimeout(timer)
  }, [isCopied]);

  return [isCopied, handleCopy];
}

export default useCopyToClipboard
