import api from '../api'

const getGeocodeData = (token, query) => api.privatePost(
  'v1/locationservice/geocoding/',
  {
    token,
    data: {
      components: 'country:MX',
      ...query
    }
  }
)

export default {
  getGeocodeData
}
