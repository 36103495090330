import Router, { useRouter } from 'next/router'

/**
 * ToDo - Find a better way to get url params.
 * 
 * Next/Router only works if you pass url params in right order.
 * So, url params (ej. :agentId or :section) are inside query object
 * as other query params as page,
 * I created this function because I needed to split url params from query params.
 */

const parseUrlPattern = (UserType, ROUTES_PATTERN, query) => {
  const [baseUrl, ...params] = ROUTES_PATTERN[UserType].split('/:')

  const sectionPatternsUrl = params.map(param => query[param]).join('/')
  const sanitizeQuery = {}

  for (const q in query) {
    if (!params.includes(q)) {
      sanitizeQuery[q] = query[q]
    }
  }

  return { sectionPatternsUrl, sanitizeQuery }
}

const useCreationRouteSection = (UserType, ROUTES_PATTERN) => {
  const { pathname, asPath, query, route, ...rest } = useRouter()
  const { section, ...restQuery } = query

  const changeSection = (section, extraQuery = {}) => {
    const { sectionPatternsUrl, sanitizeQuery } = parseUrlPattern(UserType, ROUTES_PATTERN, {
      ...query,
      section
    })

    Router.push(
      { pathname, query: { section, ...restQuery, ...extraQuery } },
      {
        pathname: `${route}/${sectionPatternsUrl}`,
        query: { ...sanitizeQuery, ...extraQuery }
      }
    )
  }

  return changeSection
}

export default useCreationRouteSection