import ApiBase from 'app/services/api/entities/Base'

const BASE_URL = '/v1/mlm_distribution/network_quotas/'

function* all(params = {}, requestOptions = {}) {
  return yield ApiBase.privateGet(BASE_URL, {
    ...requestOptions,
    params
  })
}

function* profit(params = {}, requestOptions = {}) {
  return yield ApiBase.privateGet("/v1/mlm_distribution/network_quotas/profit/", {
    ...requestOptions,
    params
  })
}

function* total(params = {}, requestOptions = {}) {
  return yield ApiBase.privateGet("/v1/mlm_distribution/network_quotas/total/", {
    ...requestOptions,
    params
  })
}

export default {
  all,
  profit,
  total,
}
