import styles from './styles'

export default [
  <article
    data-category="about"
    data-title="¿Quiénes somos?"
    data-url="quienes-somos"
    className="wrapper-article"
  >
    <div className="content">
      <h4>¿Quiénes somos?</h4>
      <p>
        Néximo es la primera inmobiliaria digital en México que empodera a sus
        agentes con Capacitación, Tecnología y Soporte de un equipo
        multidisciplinario que se encarga de tareas que no aportan valor a su
        trabajo o que no son de su ámbito profesional, para que puedan enfocarse
        en dar una excelente atención a nuestros clientes.
      </p>
      <p>
        ¡Apóyate en Néximo! Estamos en la búsqueda de agentes que deseen crecer
        con nosotros.
      </p>
      <p>
        Te compartimos unos videos que te ayudarán a entender quiénes somos:
      </p>

      <ol>
        <li>
          <a
            href="https://www.youtube.com/watch?v=xbmCcNmNdFE&t=1169s"
            target="_blank"
          >
            Entrevista de nuestro CEO Karim Goudiaby
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=sPGSi9yY3OE" target="_blank">
            Video de Reclutamiento
          </a>
        </li>
      </ol>
    </div>

    <style jsx>{styles}</style>
  </article>
]
