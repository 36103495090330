import css from 'styled-jsx/css'
import { colors, fontSizes } from 'theme'

export default css`
  .container.editor-form-text {
    position: relative;
    background: ${colors.white};
  }
  .container.editor-form-text .message-error {
    margin: 0;
    width: 90%;
    text-align: right;
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    color: ${colors.danger};
    font-size: ${fontSizes.small};
  }
  .container.invalid :global(.quill) {
    border: solid 1px ${colors.danger};
  }
`
