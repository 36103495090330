import { put } from 'redux-saga/effects'

import { Transaction } from 'services/api/entities'
import { TransactionsActionCreators } from 'app/reducers/TransactionsRedux'
import { removeAllEmptyKeys } from 'services/utils'

export function* getTransactionsRequest({ balanceId, from, to, params }) {
  const newParams = removeAllEmptyKeys({
    created_at_after: from,
    created_at_before: to,
    ...params
  })

  const response = yield Transaction.all(balanceId, newParams)

  if (response.success()) {
    const { count, results } = response.data
    return yield put(
      TransactionsActionCreators.getTransactionsSuccess(
        balanceId,
        from,
        to,
        count,
        results,
      )
    )
  }

  yield put(
    TransactionsActionCreators.getTransactionsFailure(
      balanceId,
      from,
      to,
      response.data
    )
  )
}
