import ApiBase from 'app/services/api/entities/Base'

function* propertyDescription({ propertyId, description }) {
  return yield ApiBase.privatePost(
    `/v1/artificial_intelligence/property_description/`,
    {
      data: { property: propertyId, description },
    },
  )
}

export default {
  propertyDescription,
}
