import moment, { months } from 'moment'

export const DATE_PICKER_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_MONTH_YEAR_FORMAT = 'MMM YYYY'
export const DATE_MONTH_YEAR_FORMAT_HUMAN = 'MMMM [de] YYYY'

export const monthNames = {
  '0': 'Enero',
  '1': 'Febrero',
  '2': 'Marzo',
  '3': 'Abril',
  '4': 'Mayo',
  '5': 'Junio',
  '6': 'Julio',
  '7': 'Agosto',
  '8': 'Septiembre',
  '9': 'Octubre',
  '10': 'Noviembre',
  '11': 'Diciembre'
}

export const abbrMonthNames = {
  '0': 'ENE',
  '1': 'FEB',
  '2': 'MAR',
  '3': 'ABR',
  '4': 'MAY',
  '5': 'JUN',
  '6': 'JUL',
  '7': 'AGO',
  '8': 'SEP',
  '9': 'OCT',
  '10': 'NOV',
  '11': 'DIC'
}

export const dayShortNames = {
  '0': 'Dom',
  '1': 'Lun',
  '2': 'Mar',
  '3': 'Mie',
  '4': 'Jue',
  '5': 'Vie',
  '6': 'Sab'
}

export const quarterlyList = [
  'Enero a Marzo',
  'Abril a Junio',
  'Julio a Septiembre',
  'Octubre a Diciembre'
]

export const formatDates = {
  'SHORT_DAY_NAME_WITH_MONTH_NAME': 'ddd D [de] MMMM',
  'FULL_READABLE_DATE':'dddd, D [de] MMMM [del] YYYY'
}

export const isValid = date => {
  return !isNaN(date.getTime())
}

export const getMonthName = date => {
  if (!isValid(date)) {
    return ''
  }

  return monthNames[date.getMonth().toString()]
}

/**
 * Returns the short name of the day.
 * Expects a date or a Date.getDay() result
 */
export const getDayShortName = day => {
  if (typeof day === 'object' && day.getDay) {
    return dayShortNames[day.getDay().toString()]
  }

  const curated = Number(day)
  if (isNaN(curated) || curated < 0 || curated > 6) {
    return ''
  }

  return dayShortNames[curated]
}

export const getReverse = date => {
  if (!isValid(date)) {
    return ''
  }

  const day = String(date.getUTCDate())
  const month = String(date.getUTCMonth() + 1)
  const year = date.getUTCFullYear()

  return `${year}${month.padStart(2, '0')}${day.padStart(2, '0')}`
}

export const formatDate = (value, format) => moment(value).format(format)

export const formatAppDate = (value) => formatDate(value, DATE_PICKER_DATE_FORMAT)

export const getFirstDayOfMonth = (year, month) => {
  const value = new Date(year, month - 1, 1)
  return formatAppDate(value)
}

export const getLastDayOfMonth = (year, month) => {
  const value = new Date(year, month, 0)
  return formatAppDate(value)
}

export const getMonthsInQuarter = (quarter) => {
  const months = []
  if(quarter === 1) {
    months.push(1,2,3)
  } else if(quarter === 2) {
    months.push(4,5,6)
  } if(quarter === 3) {
    months.push(7,8,9)
  } if(quarter === 4) {
    months.push(10,11,12)
  } 
  return months
}

export const formattedTimeOffset = offset => {
  let start = new Date()
  start.setHours(0, 0, 0, 0)
  return formatDate(start.getTime() + offset, 'H:mm')
}

const makeIntArray = length =>
  length > 0 ? new Array(length).fill(1).map((_, i) => i + 1) : []

const getDates = (currentDate, { isRight, timeMeasure, offset }) => {
  const dates = []

  if (!isRight) {
    makeIntArray(offset).reverse().forEach(int => {
      const current = moment(currentDate).subtract(int, timeMeasure)
      dates.push(current)
    })
  } else {
    makeIntArray(offset).forEach(int => {
      const current = moment(currentDate).add(int, timeMeasure)
      dates.push(current)
    })
  }

  return dates
}

/**
 * getAvailableDatesForCalendar - Function to get an array of dates with before and after offset.
 * @param {Date | String} currentDate - Or any accepted value from moment - https://momentjs.com/docs/#/parsing/now/
 * @param {Object} config
 * @returns {moment[]}
 */

export const getAvailableDatesForCalendar = (currentDate, config = {}) => {
  const defaultConfig = {
    leftOffset: 0,
    rightOffset: 0,
    timeMeasure: 'days', // https://momentjs.com/docs/#/manipulating/add/
    disabledDate: currentMomentDate => {
      // Can not select days before today
      return currentMomentDate && currentMomentDate < moment().startOf('day')
    },
    formatResults: (currentMomentDate) => {
    },
    ...config
  }

  const { leftOffset, rightOffset, timeMeasure } = defaultConfig

  const dates = [
    ...getDates(currentDate, { isRight: false, timeMeasure, offset: leftOffset }),
    moment(currentDate),
    ...getDates(currentDate, { isRight: true, timeMeasure, offset: rightOffset })
  ]

  const rightDates = dates.filter(d => !defaultConfig.disabledDate(d))

  if(rightDates.length !== dates.length) {
    const lastDate = rightDates[rightDates.length - 1]
    const offset = dates.length - rightDates.length

    return [
      ...rightDates,
      ...getDates(moment(lastDate), { isRight: true, timeMeasure, offset })
    ]
  }

  return dates
}

export const daysRemaining = (end) => Math.trunc((new Date(end) - new Date()) / dayInMilliseconds)

export const hourInMilliseconds = 3600000
export const halfHourInMilliseconds = 1800000
export const dayInMilliseconds = 86400000

/**
 * @function addDays
 * @param {String|Date|Number} oldDate - Any valid Date constructor value
 * @param {Number} days 
 * @returns {Date}
 */
export const addDays = (oldDate, days) => {
  const date = new Date(oldDate);
  date.setDate(date.getDate() + days);
  return date;
}