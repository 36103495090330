import { call, put, select } from 'redux-saga/effects'
import { ErrorActionCreators } from 'app/reducers/ErrorRedux'
import { camelToSnake } from 'services/string'
import reducers from 'services/store/reducers'
import api from 'services/store/api'

const getRequestTypes = (types) => Object.keys(types)
  .filter((t) => t.endsWith('_REQUEST'))
  .map((k) => types[k])

const getGenerator = (actionCreators, method, model) => {
  const modelNameUpper = camelToSnake(model.name).toUpperCase()
  const actionNameUpper = camelToSnake(method.name).toUpperCase()
  return {
    type: `STORE_${modelNameUpper}_${actionNameUpper}_REQUEST`,
    * saga(action) {
      const token = yield select(
        (state) => (state.auth ? state.auth.token : null)
      )
      const response = yield call(api[model.name][method.name], token, action)
      const { successCode } = model.methods.find((m) => m.name === method.name)
      const successCodes = Array.isArray(successCode) ? successCode : [successCode]

      if (successCodes.includes(response.status)) {
        yield put(actionCreators[`${method.name}Success`](response.data))
        return
      }

      yield put(actionCreators[`${method.name}Failure`](response.data))
      yield put(ErrorActionCreators.apiError(response.data)) // IT MUST BE response.data!!!!!
    }
  }
}

const sagas = Object.keys(reducers)
  .map((name) => reducers[name].model.methods
    .filter((method) => method.saga !== false)
    .map((m) => getGenerator(reducers[name].Creators, m, reducers[name].model)))
  .reduce((acc, value) => [...acc, ...value])

export default sagas
