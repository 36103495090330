import { companyConfig } from 'globalConfigApp'

import styles from './styles'

export default [
  <article
    data-category="red"
    data-title={`Del patrimonio que forme en ${companyConfig.name} por la creación de una red, ¿puedo
    legalmente heredarla a alguien?`}
    data-url="del-patrimonio-que-forme-en-neximo-por-la-creacion-de-una-red-puedo-legalmente-heredarla-a-alguien"
    className="wrapper-article"
  >
    <h4>
      Del patrimonio que forme en {companyConfig.name} por la creación de una red, ¿puedo
      legalmente heredarla a alguien?
    </h4>
    <div className="content">
      <p>
        Los fondos de la red son heredables sin embargo, la red no se puede
        heredar. Es importante entender que trabajar en una red requiere trabajo
        en equipo. Por lo tanto es posible heredar la red, pero no se realiza en
        automático. Cualquier cambio de liderazgo de la red debe ser aprobado
        previamente por {companyConfig.name}.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="red"
    data-title="¿Puedo ser Aliado y no formar red?"
    data-url="puedo-ser-aliado-y-no-formar-red"
    className="wrapper-article"
  >
    <h4>¿Puedo ser Aliado y no formar red?</h4>
    <div className="content">
      <p>
        Cualquier Neximer puede ser aliado sin formar una red ya que no es
        obligatorio. El financiamiento de la red se realiza usando la comisión
        del 25% que se otorga a {companyConfig.name}.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="red"
    data-title="Si tengo una red que ya está funcionando y decido retirarme de la
    actividad para jubilarme. ¿Puedo seguir recibiendo las ganancias de mi
    red?"
    data-url="si-tengo-una-red-que-ya-esta-funcionando-y-decido-retirarme-de-la-actividad-para-jubilarme-puedo-seguir-recibiendo-las-ganancias-de-mi-red"
    className="wrapper-article"
  >
    <h4>
      Si tengo una red que ya está funcionando y decido retirarme de la
      actividad para jubilarme. ¿Puedo seguir recibiendo las ganancias de mi
      red?
    </h4>
    <div className="content">
      <p>
        Nuestra red no es vitalicia. Todos los montos acumulados en la red son
        la propiedad del dueño de la red. La red es tu traspaso comercial y se
        puede vender. Si consideras jubilarte, te aconsejemos ponerte en
        contacto con nuestro equipo legal para revisar tus opciones de salida y
        maximizar el valor de tu red.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="red"
    data-title="¿A dónde va el 10% y a nombre de quien está la cuenta de la Red?"
    data-url="a-donde-va-el-10-y-a-nombre-de-quien-esta-la-cuenta-de-la-red"
    className="wrapper-article"
  >
    <h4>¿A dónde va el 10% y a nombre de quien está la cuenta de la Red?</h4>
    <div className="content">
      <p>
        {companyConfig.name} se obliga a contribuir a Red {companyConfig.name} 40% de las comisiones que te
        cobra a ti por tus servicios.
      </p>
      <p>
        Así por ejemplo, si {companyConfig.name} te cobra $25 de esa cantidad separa $10 y se
        la paga a la Red {companyConfig.name}, conforme ésta lo necesite, para fondear las
        reglas de distribución de comisiones que se encuentran descritas en el
        Reglamento de la Red {companyConfig.name}.
      </p>
      <p>
        En todos los casos esas cantidades son depositadas en una cuenta a
        nombre de Red {companyConfig.name}, S.C.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="red"
    data-title="¿Cómo podré comprobar que las personas de las que soy patrocinador las
    coloquen en mi grupo?"
    data-url="como-podre-comprobar-que-las-personas-de-las-que-soy-patrocinador-las-coloquen-en-mi-grupo"
    className="wrapper-article"
  >
    <h4>
      ¿Cómo podré comprobar que las personas de las que soy patrocinador las
      coloquen en mi grupo?
    </h4>
    <div className="content">
      <p>
        {companyConfig.name} busca que toda operación sea totalmente transparente por lo que
        podrás ver como se conforma tu red directamente en tu cuenta, en el
        módulo <span className="tag"> Mi equipo </span> encontrarás tu
        facturación y la facturación de toda tu red.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="red"
    data-title="¿Puedo yo hacer presentaciones privadas para reclutar asesores para mi
    grupo?"
    data-url="puedo-yo-hacer-presentaciones-privadas-para-reclutar-asesores-para-mi-grupo"
    className="wrapper-article"
  >
    <h4>
      ¿Puedo yo hacer presentaciones privadas para reclutar asesores para mi
      grupo?
    </h4>
    <div className="content">
      <p>
        Absolutamente, acércate de nuestro equipo comercial para obtener todo el
        material de presentación.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="red"
    data-title="¿Dónde puedo encontrar el contacto de un asesor?"
    data-url="donde-puedo-encontrar-el-contacto-de-un-asesor"
    className="wrapper-article"
  >
    <h4>¿Dónde puedo encontrar el contacto de un asesor?</h4>
    <div className="content">
      <p>
        En la bolsa inmobiliaria al buscar la propiedad por id o filtros puedes
        ver el perfil del asesor y datos
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="red"
    data-title="¿Cómo doy de alta a un ahijado?"
    data-url="como-doy-de-alta-a-un-ahijado"
    className="wrapper-article"
  >
    <h4>¿Cómo doy de alta a un ahijado?</h4>
    <div className="content">
      <p>
        Desde tu plataforma en la sección <span className='tag'>Red {companyConfig.name} &gt; Dar de alta</span>
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="red"
    data-title="¿Yo gano comisión por operaciones de mis ahijados?"
    data-url="yo-gano-comision-por-operaciones-de-mis-ahijados"
    className="wrapper-article"
  >
    <h4>¿Yo gano comisión por operaciones de mis ahijados?</h4>
    <div className="content">
      <p>
        Si, depende que nivel sea tu ahijado es el porcentaje de comisión:
      </p>
      <ul>
        <li>1a Línea - 6%</li>
        <li>2da Linea - 2.5%</li>
        <li>3ra Línea - .5%</li>
        <li>4ta Línea - .5%</li>
        <li>5ta Línea - .5%</li>
      </ul>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="red"
    data-title="¿Cuántos ahijados puedo tener?"
    data-url="cuantos-ahijados-puedo-tener"
    className="wrapper-article"
  >
    <h4>¿Cuántos ahijados puedo tener?</h4>
    <div className="content">
      <p>
        Depende de tu nivel de red
      </p>
      <ul>
        <li>Bronce - 5 asesores</li>
        <li>Plata - 10 asesores</li>
        <li>Oro - 15 asesores</li>
        <li>Platino - 20 asesores</li>
        <li>Platino asociado - ilimitado</li>
      </ul>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="red"
    data-title="¿Puedo tener ahijados en otros estados?"
    data-url="puedo-tener-ahijados-en-otros-estados"
    className="wrapper-article"
  >
    <h4>¿Puedo tener ahijados en otros estados?</h4>
    <div className="content">
      <p>
        Si puedes sin problema pero siempre les recomendamos que mejor esten en tu ciudad para que el acompañamiento inicial sea muy puntual.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>
]
