import { initialFeedback, getHashedState } from 'reducers/utils'

export const TRANSACTIONS_RESULTS_LIMIT = 10

export const BASE_MLM_TRANSACTIONS = {
  count: 0,
  transactions: [],
  params: {
    offset: 0,
    limit: TRANSACTIONS_RESULTS_LIMIT
  },
  feedback: initialFeedback()
}

/**
 *
 * @param  {...String} args
 * @example
 * > createHashFromDates(balanceId, '01-01-2021', '01-03-2021')
 * > "01-01-2021--01-03-2021"
 * @returns {String}
 */
export const createHashFromDates = (...args) => args.join('--')

export const TransactionsSelector = (state, balanceId, from, to) =>
  getHashedState(
    state.transactions.transactions,
    createHashFromDates(balanceId, from, to),
    BASE_MLM_TRANSACTIONS
  )
