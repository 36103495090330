export const PROPERTY_HIGHLIGHT_ORDER_STATUS = {
  CREATED: 'CREATED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  DELIVERED: 'DELIVERED',
}

export const PROPERTY_HIGHLIGHT_PAYMENT_STATUS = {
  CREATED: 'CREATED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  FAILED: 'FAILED',
  DELIVERED: 'DELIVERED',
}

export const PROPERTY_HIGHLIGHT_SUCCESS_STATUS = [
  PROPERTY_HIGHLIGHT_ORDER_STATUS.PAID,
  PROPERTY_HIGHLIGHT_ORDER_STATUS.DELIVERED,
]

export const PROPERTY_HIGHLIGHT_AUTHOR = {
  NEXIMER: 'NEXIMER',
  NEXIMO: 'NEXIMO',
}

export const DURATION_MEASURE_CONFIG = {
  LEADS: {
      icon: 'md-information-circle',
      label: "leads"
  },
  DAYS: {
     icon: "md-calendar",
     label: "días"
  }
}

export const HIGHLIGTH_PLANS_DESCRIPTION = {
  boost_redes_sociales_bronce: "recomendado para propiedades de 0 a 5 millones de MXN",
  boost_redes_sociales_oro: "recomendado para propiedades de 5 a 10 millones de MXN",
  boost_redes_sociales_platinum: "recomendado para propiedades de 10 millones de MXN en adelante"
}

//List of portals from which we can ensure the status of their publications, so we can correctly display the Highlight label
export const PORTALS_SHOW_HIGHLIGHT_LABEL = ["mercadolibre","inmuebles24"]

export const DISTRICTS_I24_ON_DEMAND = ["Miguel Hidalgo"]
