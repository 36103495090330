import { put } from 'redux-saga/effects'
import { ContractVersion } from 'services/api/entities'
import { ContractVersionActionCreators } from 'app/reducers/ContractVersionRedux'
import { checkFeatureFlag } from 'config'


export function* checkPermission({ permission }) {
  if (!checkFeatureFlag("new-comercial-plan")) {
    yield put(ContractVersionActionCreators.checkPermissionSuccess(permission))
    return
  }
  const response = yield ContractVersion.checkPermission(permission)

  if (response.success()) {
    yield put(ContractVersionActionCreators.checkPermissionSuccess(permission))
    return
  }

  yield put(
    ContractVersionActionCreators.checkPermissionFailure(permission, response.data.detail)
  )
}

export function* getUrlByContract({params}) {
  const response = yield ContractVersion.getUrlByContract(params)

  if (response.success()) {
    yield put(ContractVersionActionCreators.getUrlByContractSuccess(
      response.data.url
    ))
    return
  }

  yield put(
    ContractVersionActionCreators.getUrlByContractFailure(response.data)
  )
}