import { createReducer, createActions } from 'reduxsauce'

export const DRAFT_ACTION = '@@NEXIMO/PROPERTY/DRAFT_ACTION'
export const DRAFT_ACTION_END = '@@NEXIMO/PROPERTY/DRAFT_ACTION_END'

const INITIAL_STATE = {
  isSavingDraft: false,
  closeDealErrors: null,
  isClosingDeal: false,
}

const { Types, Creators } = createActions(
  {
    draftAction: [],
    cleanIsSavingDraft: [],
    closeDealProperty: ['propertyId', 'property', 'dealValues'],
    closeDealPropertyError: ['errors'],
    closeDealPropertyFinished: [],
    closeDealPropertyCleanErrors: [],
    cleanState: [],
  },
  { prefix: 'PROPERTY_' }
)

const draftAction = state => ({ ...state, isSavingDraft: true })
const draftActionEnd = state => ({ ...state, isSavingDraft: false })
const cleanIsSavingDraft = state => ({ ...state, isSavingDraft: false })
const cleanState = () => INITIAL_STATE

const closeDealProperty = (state) => ({
  ...state,
  closeDealErrors: null,
  isClosingDeal: true,
})

const closeDealPropertyError = (state, {errors}) => ({
  ...state,
  closeDealErrors: errors,
  isClosingDeal: false,
})

const closeDealPropertyFinished = (state) => ({
  ...state,
  closeDealErrors: null,
  isClosingDeal: false,
})

const closeDealPropertyCleanErrors = (state) => ({
  ...state,
  closeDealErrors: null,
})

const HANDLERS = {
  [DRAFT_ACTION]: draftAction,
  [DRAFT_ACTION_END]: draftActionEnd,
  [Types.DRAFT_ACTION]: draftAction,
  [Types.CLEAN_IS_SAVING_DRAFT]: cleanIsSavingDraft,
  [Types.CLOSE_DEAL_PROPERTY]: closeDealProperty,
  [Types.CLOSE_DEAL_PROPERTY_ERROR]: closeDealPropertyError,
  [Types.CLOSE_DEAL_PROPERTY_CLEAN_ERRORS]: closeDealPropertyCleanErrors,
  [Types.CLOSE_DEAL_PROPERTY_FINISHED]: closeDealPropertyFinished,
  [Types.CLEAN_STATE]: cleanState,
}

export const PropertyActionTypes = Types

export const PropertyActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
