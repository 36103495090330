import { createReducer, createActions } from 'reduxsauce'
import {
  failureFeedback,
  initialFeedback,
  requestFeedback,
  successFeedback,
} from './utils'

/**
 * @typedef {Object} Plan
 * @property {Number} id - planId - "portalName---planName---duration"
 * @property {String} plan - Plan name - Use as label
 * @property {Number} stock - Stock
 * @property {Number} duration - Duration in days
 * @property {Object} price - { currency: String, amount: Number }
 */

/**
 * @typedef {Object} PropertyOrder
 * @property {Number} highlightOrder - Order id
 * @property {String} plan - Plan name - Use as label
 * @property {Number} property - Property id
 * @property {Number} site - Site id
 * @property {String} siteName - Site name
 * @property {String} orderStatus - Order status 
 * @property {String} orderPaidAt - Order paid date
 * @property {String} source - Source = <NEXIMER, NEXIMO>
 */

// prettier-ignore
const INITIAL_STATE = {
  shoppingDetailModal: {
    propertyId: null,
    firstImage: null, // Current selected property cover image
    operationType: null, // Operation type = <RENT, SELL>
  },
  /**
   * plans
   * @type {Object.<string, Plan[]>}
   * @example { portalName: [planId] }
   */
  plans: {},
  plansFeedback: initialFeedback(),
  /**
   * propertyOrders
   * Current paid property plans
   * @type {Object.<number, PropertyOrder[]>}
   * @example { propertyId: ["portalName---planName---duration"] }
   */
  propertyOrders: {},
  propertyOrdersFeedback: initialFeedback(),

  /**
   * @typedef {Object} ShoppingCartItem
   * @property {String[]} planIds - ["portalName---planName---duration"]
   * @property {String} firstImage - Cover image URL
   * @property {Boolean} isValidToHighlight - Represent if the property is valid to highlight
   */

  /**
   * shoppingCart
   * @type {Object.<string|number, ShoppingCartItem[]>}
   * @example {
   *            propertyId: {
   *              firstImage: '...',
   *              planIds: ['portalName---planName---duration'],
   *              isValidToHighlight: true
   *            }
   *          }
   */
  shoppingCart: {},

  /**
   * validatedPropertiesShoppingCart
   * @description Properties available to be purchased - Validated by the user
  * @type {Number[]}
   */
  validatedPropertiesShoppingCart: [],

  highlightOrderId: null,
  confirmOrderFeedback: initialFeedback(),

  /**
   * purchaseOrder uses highlightOrderId as id and gets the info related to it
   */
  purchaseOrder: {
    feedback: initialFeedback(),
    partialFeedback: initialFeedback(),
    createdAt: null,
    finishedAt: null,
    updatedAt: null,
    status: null,               // PENDING | PENDING | PAID
    paidAt: null,               // STRIPE paid at
    paymentStatus: null,        // ACTIVE | PENDING | CANCELLED | SUBMITTED | SUCCESS
    paymentOrderUrl: null,      // STRIPE payment URL to redirect to pay
    receiptUrl: null,           // STRIPE receipt URL
    externalId: null,           // STRIPE external id
    paymentAmount: null,        // STRIPE payment amount - in pesos
    paymentMethod: null,        // STRIPE payment method - STRIPE | CASH | TRANSFER
    taxAmount: null,            // STRIPE tax amount - in pesos
    totalAmount: null,          // STRIPE total amount - in pesos
  },
  /**
   * @typedef {Object} DataValidation
   * @property {Number} property - Property id
   * @property {String} status - Property status
   * @property {Boolean} semaphore - Property legal status
   * @property {Boolean} isValid - Represent if the property is valid to highlight
   */
  /**
   * propertiesStatusCheck
   * @description Contains the current status of the property and if it is valid to highlight
   * @example { 
   *            data: [ 
   *              {
   *                property: 140556,
   *                status: "APPROVED",
   *                semaphore: "READY_FOR_PUBLICATION_AND_VERIFIED",
   *                isValid: false
   *              }
   *            ],
   *            feedback: {...} 
   *        }
   */
  propertiesStatusCheck: {
    data: null, //{DataValidation[]}
    feedback: initialFeedback()
  },
}

const { Types, Creators } = createActions(
  {
    allPlans: [],
    allPlansSuccess: ['plans'],
    allPlansFailure: ['errors'],

    allPropertyOrders: ['propertyId'],
    allPropertyOrdersSuccess: ['propertyId', 'propertyOrders'],
    allPropertyOrdersFailure: ['errors'],

    openModal: ['propertyId', 'firstImage', 'property'],
    closeModal: [],

    addPropertyToShoppingCart: ['propertyId', 'planIds'],
    removePropertyFromShoppingCart: ['propertyId', 'planId'],
    clearShoppingCard: [],

    addValidatedPropertyToShoppingCart: ['propertyId'],

    confirmOrder: [],
    confirmOrderSuccess: ['highlightOrderId'],
    confirmOrderFailure: ['errors'],
    confirmOrderClearState: [],

    purchaseOrder: ['highlightOrderId'],
    purchaseOrderSuccess: ['purchaseOrder'],
    purchaseOrderFailure: ['errors'],

    createPurchaseOrder: ['highlightOrderId'],
    createPurchaseOrderSuccess: ['purchaseOrder'],
    createPurchaseOrderFailure: ['errors'],

    refreshPurchaseOrder: ['highlightOrderId'],
    refreshPurchaseOrderSuccess: ['purchaseOrder'],
    refreshPurchaseOrderFailure: ['errors'],

    checkPropertiesStatus: [],
    checkPropertiesStatusSuccess: ['properties'],
    checkPropertiesStatusFailure: ['errors'],

    cleanState: [],
  },
  { prefix: 'PROPERTY_HIGHLIGHT_' },
)

const allPlans = state => ({
  ...state,
  plans: [],
  plansFeedback: requestFeedback(),
})

const allPlansSuccess = (state, { plans }) => ({
  ...state,
  plans,
  plansFeedback: successFeedback(),
})

const allPlansFailure = (state, { errors }) => ({
  ...state,
  plansFeedback: failureFeedback(errors),
})

const allPropertyOrders = state => ({
  ...state,
  propertyOrdersFeedback: requestFeedback(),
})

const allPropertyOrdersSuccess = (state, { propertyId, propertyOrders }) => ({
  ...state,
  propertyOrders: {...state.propertyOrders, [propertyId]: propertyOrders},
  propertyOrdersFeedback: successFeedback(),
})

const allPropertyOrdersFailure = (state, { errors }) => ({
  ...state,
  propertyOrdersFeedback: failureFeedback(errors),
})

const openModal = (state, { propertyId, firstImage, property }) => ({
  ...state,
  shoppingDetailModal: {
    ...state.shoppingDetailModal,
    propertyId,
    firstImage,
    operationType: property.operationType,
    district: property.district,
  },
})

const closeModal = state => ({
  ...state,
  shoppingDetailModal: {
    ...state.shoppingDetailModal,
    propertyId: null,
    firstImage: null,
    operationType: null,
    district: null,
  },
})

const addPropertyToShoppingCart = (state, { propertyId, planIds }) => ({
  ...state,
  shoppingCart: {
    ...state.shoppingCart,
    [propertyId]: {
      planIds,
      firstImage: state.shoppingDetailModal.firstImage,
      isValidToHighlight: true,
    },
  },
})

const removePropertyFromShoppingCart = (
  state,
  { propertyId: deletedPropertyId, planId },
) => ({
  ...state,
  shoppingCart: Object.keys(state.shoppingCart).reduce(
    (newShoppingCart, propertyId) => {
      const currentProperty = state.shoppingCart[propertyId]
      if (Number(deletedPropertyId) !== Number(propertyId)) {
        newShoppingCart[propertyId] = currentProperty
        return newShoppingCart
      }

      const newPlanIds = currentProperty.planIds.filter(id => id !== planId)

      if (newPlanIds.length === 0) {
        return newShoppingCart
      }

      newShoppingCart[propertyId] = {
        ...currentProperty,
        planIds: newPlanIds,
      }

      return newShoppingCart
    },
    {},
  ),
})

const clearShoppingCard = state => ({
  ...state,
  shoppingCart: INITIAL_STATE.shoppingCart,
})

const addValidatedPropertyToShoppingCart = (state, { propertyId }) => ({
  ...state,
  validatedPropertiesShoppingCart: [
    ...state.validatedPropertiesShoppingCart,
    Number(propertyId),
  ],
})

const confirmOrder = state => ({
  ...state,
  confirmOrderFeedback: requestFeedback(),
})

const confirmOrderSuccess = (state, { highlightOrderId }) => ({
  ...state,
  highlightOrderId,
  confirmOrderFeedback: successFeedback(),
})

const confirmOrderFailure = (state, { errors }) => ({
  ...state,
  confirmOrderFeedback: failureFeedback(errors),
})

const confirmOrderClearState = state => ({
  ...state,
  purchaseOrder: {
    ...INITIAL_STATE.purchaseOrder
  },
  confirmOrderFeedback: initialFeedback(),
})

const purchaseOrder = state => ({
  ...state,
  purchaseOrder: {
    ...INITIAL_STATE.purchaseOrder,
    feedback: requestFeedback(),
  },
})

const purchaseOrderSuccess = (state, { purchaseOrder }) => ({
  ...state,
  purchaseOrder: {
    ...state.purchaseOrder,
    ...purchaseOrder,
    feedback: successFeedback(),
  },
})

const purchaseOrderFailure = (state, { errors }) => ({
  ...state,
  purchaseOrder: {
    ...state.purchaseOrder,
    feedback: failureFeedback(errors),
  },
})

const createPurchaseOrder = state => ({
  ...state,
  purchaseOrder: {
    ...state.purchaseOrder,
    feedback: requestFeedback(),
  },
})

const createPurchaseOrderSuccess = (state, { purchaseOrder }) => ({
  ...state,
  purchaseOrder: {
    ...state.purchaseOrder,
    ...purchaseOrder,
    feedback: successFeedback(),
  },
})

const createPurchaseOrderFailure = (state, { errors }) => ({
  ...state,
  purchaseOrder: {
    ...state.purchaseOrder,
    feedback: failureFeedback(errors),
  },
})

const refreshPurchaseOrder = state => ({
  ...state,
  purchaseOrder: {
    ...state.purchaseOrder,
    partialFeedback: requestFeedback(),
  },
})

const refreshPurchaseOrderSuccess = (state, { purchaseOrder }) => ({
  ...state,
  purchaseOrder: {
    ...state.purchaseOrder,
    ...purchaseOrder,
    partialFeedback: successFeedback(),
  },
})

const refreshPurchaseOrderFailure = (state, { errors }) => ({
  ...state,
  purchaseOrder: {
    ...state.purchaseOrder,
    partialFeedback: failureFeedback(errors),
  },
})

const checkPropertiesStatus = state  => ({
  ...state,
  propertiesStatusCheck: {
    ...state.propertiesStatusCheck,
    feedback: requestFeedback(),
  },
})

const checkPropertiesStatusSuccess = (state, { properties })  => {
  let shoppingCartValidation = {}

  properties.forEach( propertyData => {
    shoppingCartValidation[propertyData.property] = propertyData.isValid
  });

  return {
    ...state,
    propertiesStatusCheck: {
      data: properties,
      feedback: successFeedback(),
    },
    shoppingCart:  Object.keys(state.shoppingCart).reduce(
      (newShoppingCart, propertyId) => {
        const currentProperty = state.shoppingCart[propertyId]
        return {
          ...newShoppingCart,
          [propertyId]: {
            ...currentProperty,
            isValidToHighlight: shoppingCartValidation[propertyId]
          }
        }
      },
      {},
    ),
  }
}

const checkPropertiesStatusFailure = (state, { errors })  => ({
  ...state,
  propertiesStatusCheck: {
    ...state.propertiesStatusCheck,
    feedback: failureFeedback(errors),
  }
})

const cleanState = () => INITIAL_STATE

const HANDLERS = {
  [Types.ALL_PLANS]: allPlans,
  [Types.ALL_PLANS_SUCCESS]: allPlansSuccess,
  [Types.ALL_PLANS_FAILURE]: allPlansFailure,

  [Types.ALL_PROPERTY_ORDERS]: allPropertyOrders,
  [Types.ALL_PROPERTY_ORDERS_SUCCESS]: allPropertyOrdersSuccess,
  [Types.ALL_PROPERTY_ORDERS_FAILURE]: allPropertyOrdersFailure,

  [Types.OPEN_MODAL]: openModal,
  [Types.CLOSE_MODAL]: closeModal,

  [Types.ADD_PROPERTY_TO_SHOPPING_CART]: addPropertyToShoppingCart,
  [Types.REMOVE_PROPERTY_FROM_SHOPPING_CART]: removePropertyFromShoppingCart,
  [Types.CLEAR_SHOPPING_CARD]: clearShoppingCard,

  [Types.ADD_VALIDATED_PROPERTY_TO_SHOPPING_CART]: addValidatedPropertyToShoppingCart,

  [Types.CONFIRM_ORDER]: confirmOrder,
  [Types.CONFIRM_ORDER_SUCCESS]: confirmOrderSuccess,
  [Types.CONFIRM_ORDER_FAILURE]: confirmOrderFailure,
  [Types.CONFIRM_ORDER_CLEAR_STATE]: confirmOrderClearState,

  [Types.PURCHASE_ORDER]: purchaseOrder,
  [Types.PURCHASE_ORDER_SUCCESS]: purchaseOrderSuccess,
  [Types.PURCHASE_ORDER_FAILURE]: purchaseOrderFailure,

  [Types.CREATE_PURCHASE_ORDER]: createPurchaseOrder,
  [Types.CREATE_PURCHASE_ORDER_SUCCESS]: createPurchaseOrderSuccess,
  [Types.CREATE_PURCHASE_ORDER_FAILURE]: createPurchaseOrderFailure,

  [Types.REFRESH_PURCHASE_ORDER]: refreshPurchaseOrder,
  [Types.REFRESH_PURCHASE_ORDER_SUCCESS]: refreshPurchaseOrderSuccess,
  [Types.REFRESH_PURCHASE_ORDER_FAILURE]: refreshPurchaseOrderFailure,

  [Types.CHECK_PROPERTIES_STATUS]: checkPropertiesStatus,
  [Types.CHECK_PROPERTIES_STATUS_SUCCESS]: checkPropertiesStatusSuccess,
  [Types.CHECK_PROPERTIES_STATUS_FAILURE]: checkPropertiesStatusFailure,

  [Types.CLEAN_STATE]: cleanState,
}

export const PropertyHighlightActionTypes = Types
export const PropertyHighlightActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
