
export default {
  name: 'propertyVirtualTour',
  namespace: 'tours',
  methods: [
    {
      name: 'tour',
      params: ['propertyId'],
      successCode: 200,
      method: 'Get',
      url: ({ propertyId }) => `/v1/property/tours/${propertyId}/`,
      urlParams: ['propertyId']
    },
    {
      name: 'updateTour',
      params: ['propertyId', 'data'],
      successCode: 200,
      method: 'Patch',
      url: ({ propertyId }) => `/v1/property/tours/${propertyId}/`,
      urlParams: ['propertyId']
    },
    {
      name: 'publish',
      params: ['propertyId'],
      successCode: 201,
      method: 'Post',
      url: ({ propertyId }) => `/v1/property/tours/${propertyId}/publish/`,
      urlParams: ['propertyId']
    },
  ]
}
