import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button, IonIcon } from 'controls'
import { useBlockWindowOverflow } from 'app/hooks'
import styles from './styles'

class Modal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    keepContent: PropTypes.bool,
    onClose: PropTypes.func,
    onMinimize: PropTypes.func,
    onModalClick: PropTypes.func,
    cover: PropTypes.bool,
    closeOnOutClick: PropTypes.bool,
    maxWidth: PropTypes.string,
    width: PropTypes.string,
    style: PropTypes.object,
    overlayStyle: PropTypes.object,
    showCloseButton: PropTypes.bool,
    showMinimizeButton: PropTypes.bool,
    position: PropTypes.oneOf(['center', 'top'])
  }

  static defaultProps = {
    isOpen: false,
    keepContent: false,
    onClose: () => {},
    onMinimize: () => {},
    onModalClick: () => {},
    cover: false,
    closeOnOutClick: true,
    showCloseButton: true,
    showMinimizeButton: false,
    overlayStyle: {},
    position: 'center'
  }

  close = () => {
    this.props.onClose()
  }

  minimize = () => {
    this.props.onMinimize()
  }

  onOverlayClick = e => {
    // ignore bubbled events
    if (this.props.closeOnOutClick && e.target === e.currentTarget) {
      this.close()
    }
  }

  render() {
    const {
      isOpen,
      keepContent,
      cover,
      coverOnMobile,
      maxWidth,
      width,
    } = this.props
    if (!isOpen && !keepContent) {
      return null
    }
    const widthStyle = !cover && maxWidth && width ? { maxWidth, width } : {}

    return (
      <ModalContent
        {...this.props}
        widthStyle={widthStyle}
        onClose={this.close}
        onMinimize={this.minimize}
        onOverlayClick={this.onOverlayClick}
      />
    )
  }
}

const MODAL_TYPES_CLASS = {
  clasic: "clasic-modal",
  fullPage: "modal-as-full-page"
}

const ModalContent = ({
  isOpen,
  widthStyle,
  onModalClick,
  cover,
  coverOnMobile,
  style,
  overlayStyle,
  showMinimizeButton,
  showCloseButton,
  position,
  onClose,
  onMinimize,
  children,
  modalType = "clasic",
  onOverlayClick
}) => {
  useBlockWindowOverflow(isOpen)

  return (
    <div
      className={classnames('overlay', position, MODAL_TYPES_CLASS[modalType], {
        show: isOpen,
        cover,
        coverOnMobile
      })}
      style={overlayStyle}
      onClick={onOverlayClick}
    >
      <div
        onClick={onModalClick}
        style={{ ...widthStyle, ...style }}
        className={classnames("modal", MODAL_TYPES_CLASS[modalType])}
      >
        {showMinimizeButton && (
          <Button className="minimize-button" onClick={onMinimize}>
            {!cover && 'Minimizar'} <IonIcon icon="ios-arrow-down" />
          </Button>
        )}
        {showCloseButton && (
          <Button className="close-button" onClick={onClose}>
            {!cover && 'Cerrar'} <IonIcon icon="md-close" />
          </Button>
        )}
        {children}
      </div>
      <style jsx>{styles}</style>
    </div>
  )
}

export default Modal
