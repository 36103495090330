import { createReducer, createActions } from 'reduxsauce'
import {
  failureFeedback,
  initialFeedback,
  requestFeedback,
  successFeedback
} from './utils'

const INITIAL_STATE = {
  files: [],
  feedback: initialFeedback()
}

const { Types, Creators } = createActions(
  {
    all: ['folderId', 'params'],
    allSuccess: ['files'],
    allFailure: ['errors'],
    clear: []
  },
  { prefix: 'DOCUMENTATION_FILES_' }
)

const all = (state, { folderId, params }) => ({
  ...state,
  feedback: requestFeedback()
})

const allSuccess = (state, { files }) => ({
  ...state,
  files,
  feedback: successFeedback()
})

const allFailure = (state, { errors }) => ({
  ...state,
  feedback: failureFeedback(errors)
})

const clear = () => INITIAL_STATE

export const DocumentationFilesActionTypes = Types

const HANDLERS = {
  [Types.ALL]: all,
  [Types.ALL_SUCCESS]: allSuccess,
  [Types.ALL_FAILURE]: allFailure,
  [Types.CLEAR]: clear
}

export const DocumentationFilesActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
