import { createReducer, createActions } from 'reduxsauce'
import { mergeObjs } from 'services/object'
import {
  WIZARD_VIEWS,
  normalizeContracValues,
} from 'app/selectors/contracts'
import {
  failureFeedback,
  initialFeedback,
  requestFeedback,
  successFeedback,
  updateHashedState
} from './utils'


const INITIAL_STATE = {
  list: {
    count: 0,
    results: [],
    params: {
      limit: 10,
      offset: 0
    },
    feedback: initialFeedback()
  },
  types: {
    count: 0,
    results: [],
    params: {
      limit: 10,
      offset: 0
    },
    feedback: initialFeedback()
  },

  originalContract: {
    contract: {}, // { id: 123, name: "...", values: [...] }
    feedback: initialFeedback(),
  },

  draft: {
    hasChanges: false,
    answers: {},
    progress: 0,
    lastUpdate: null,
    feedback: initialFeedback(),
  },

  fillInitialValuesFeedback: initialFeedback(),

  selectedProperty: null, // PropertyId

  contractType: {
    contractType: {},
    feedback: initialFeedback()
  },
  contractLayoutVersion:{
    isUpdated: true,
    feedback: initialFeedback()
  },

  wizard: {
    contractName: null, // User contract name

    view: WIZARD_VIEWS.QUESTIONS, // "QUESTIONS" | "FEEDBACK"
    startNode: null,
    nodesPath: [],
    currentIndex: 0,

    saveFeedback: initialFeedback(),
  },
  remove: {
    feedback: initialFeedback()
  },
  partialUpdate: {
    feedback: initialFeedback()
  },
  requestSignatures: {
    feedback: initialFeedback()
  },
  cancelSignatures: {
    feedback: initialFeedback()
  }
}

const { Types, Creators } = createActions(
  {
    all: ['params'],
    allSuccess: ['count', 'results'],
    allFailure: ['errors'],

    contract: ['contractId'],
    contractSuccess: ['contract'],
    contractFailure: ['errors'],
    setOriginalContract: ['contract'],

    remove: ['contractId'],
    removeSuccess: [],
    removeFailure: ['errors'],
    removeClearState: [],

    draft: [],
    draftSuccess: ['contract'],
    draftFailure: ['errors'],

    types: ['params'],
    typesSuccess: ['count', 'results'],
    typesFailure: ['errors'],
    setSelectedProperty: ['selectedProperty'],
    fillInitialValues: ['contractTypeId', 'selectedProperty'],
    fillInitialValuesSuccess: ['initialValues'],
    fillInitialValuesFailure: [],
    updateAnswers: ['answers', 'progress'],
    setShallowSelectedProperty: ['selectedProperty'],

    contractType: ['contractTypeId'],
    contractTypeSuccess: ['contractType'],
    contractTypeFailure: ['errors'],

    contractLayoutVersion: ['contractId'],
    contractLayoutVersionSuccess: ['isUpdated'],
    contractLayoutVersionFailure: ['errors'],

    wizardChangeView: ['view'],
    setContractType: ['contractType'],
    setCurrentIndexQuestion: ['currentIndex'],

    fillNodesNextValue: [],

    save: [],
    saveSuccess: ['contract'],
    saveFailure: ['errors'],

    partialUpdate: ['contractId', 'partialContract'],
    partialUpdateSuccess: ['contract'],
    partialUpdateFailure: ['errors'],
    cleanPartialUpdate: [],
    updateContractInList: ['contractId', 'newContract'],

    clear: [],

    requestSignatures: ['contractId'],
    requestSignaturesSuccess: [],
    requestSignaturesFailure: ['errors'],
    requestSignaturesClearState: [],

    cancelSignatures: ['contractId'],
    cancelSignaturesSuccess: [],
    cancelSignaturesFailure: ['errors'],
    cancelSignaturesClearState: [],
  },
  { prefix: 'CONTRACTS_' }
)

const all = (state, { params }) => ({
  ...state,
  list: {
    ...state.list,
    params,
    feedback: requestFeedback()
  }
})

const allSuccess = (state, { count, results }) => ({
  ...state,
  list: {
    ...state.list,
    count,
    results,
    feedback: successFeedback()
  }
})

const allFailure = (state, { errors }) => ({
  ...state,
  list: {
    ...state.list,
    feedback: failureFeedback(errors)
  }
})

const contract = state =>
  updateHashedState(state, 'originalContract', {
    feedback: requestFeedback(),
  })

const contractSuccess = (state, { contract }) => ({
  ...state,
  originalContract: {
    ...state.originalContract,
    contract: normalizeContracValues(contract),
    feedback: successFeedback(),
  },
})

const contractFailure = (state, { errors }) =>
  updateHashedState(state, 'originalContract', {
    contract: {},
    feedback: failureFeedback(errors),
  })

const setOriginalContract = (state, { contract }) => {
  const parsedContract = normalizeContracValues(contract)
  return {
    ...state,
    originalContract: {
      ...state.originalContract,
      contract: parsedContract,
    },
    draft: {
      ...state.draft,
      progress: parsedContract.progress,
      answers: parsedContract.values
    }
  }
}

const remove = state =>
  updateHashedState(state, 'remove', {
    feedback: requestFeedback(),
  })

const removeSuccess = (state, { contractId }) =>
  updateHashedState(state, 'remove', {
    feedback: successFeedback(),
  })

const removeFailure = (state, { errors }) =>
  updateHashedState(state, 'remove', {
    feedback: failureFeedback(errors),
  })

const removeClearState = (state) =>
  updateHashedState(state, 'remove', INITIAL_STATE.remove)

const draft = state =>
  updateHashedState(state, 'draft', {
    feedback: requestFeedback(),
  })

const draftSuccess = (state, { contract }) =>
  updateHashedState(state, 'draft', {
    hasChanges: false,
    lastUpdate: new Date().getTime(),
    feedback: successFeedback(),
  })

const draftFailure = (state, { errors }) =>
  updateHashedState(state, 'draft', {
    hasChanges: false,
    feedback: failureFeedback(errors),
  })

const types = (state, { params }) => ({
  ...state,
  types: {
    ...state.types,
    params,
    feedback: requestFeedback()
  }
})

const typesSuccess = (state, { count, results }) => ({
  ...state,
  types: {
    ...state.types,
    count,
    results: results.map(result => ({
      ...result,
      icon: result.logo,
      label: result.title
    })),
    feedback: successFeedback()
  }
})

const typesFailure = (state, { errors }) => ({
  ...state,
  types: {
    ...state.types,
    feedback: failureFeedback(errors)
  }
})

const contractType = (state, { contractTypeId }) => ({
  ...state,
  contractType: {
    contractType: {},
    feedback: requestFeedback()
  }
})


const contractTypeSuccess = (state, { contractType }) => ({
  ...state,
  contractType: {
    contractType,
    feedback: successFeedback()
  }
})

const contractTypeFailure = (state, { errors }) => ({
  ...state,
  contractType: {
    contractType: {},
    feedback: failureFeedback(errors)
  }
})

const contractLayoutVersion = (state) => ({
  ...state,
  contractLayoutVersion:{
    ...state.contractLayoutVersion,
    feedback: requestFeedback()
  }
})

const contractLayoutVersionSuccess = (state, { isUpdated }) => ({
  ...state,
  contractLayoutVersion:{
    isUpdated: isUpdated,
    feedback: successFeedback()
  }
})

const contractLayoutVersionFailure = (state, { errors }) => ({
  ...state,
  contractLayoutVersion:{
    feedback: failureFeedback(errors),
    ...state.contractLayoutVersion,
  }
})

const setSelectedProperty = (state, { selectedProperty }) => ({
  ...state,
  selectedProperty,
  draft: {
    ...state.draft,
    hasChanges: true,
  }
})

const updateAnswers = (state, { answers, progress = 0 }) => ({
  ...state,
  draft: {
    ...state.draft,
    answers,
    progress,
    hasChanges: true,
  }
})

const fillInitialValues = state => ({
  ...state,
  fillInitialValuesFeedback: requestFeedback()
})

const fillInitialValuesSuccess = (state, { initialValues }) => ({
  ...state,
  originalContract: updateHashedState(state.originalContract, 'contract', {
    values: mergeObjs(initialValues, state.originalContract.values)
  }),
  fillInitialValuesFeedback: successFeedback()
})

const fillInitialValuesFailure = state => ({
  ...state,
  fillInitialValuesFeedback: failureFeedback()
})

const setShallowSelectedProperty = (state, { selectedProperty }) => ({
  ...state,
  selectedProperty,
})

const wizardChangeView = (state, { view }) =>
  updateHashedState(state, 'wizard', {
    view
  })

const setContractType = (state, { contractType }) => ({
  ...state,
  contractType: {
    contractType,
    ...state.contractType,
  }
})

const setCurrentIndexQuestion = (state, { currentIndex }) => ({
  ...state,
  wizard: {
    ...state.wizard,
    currentIndex
  }
})

const save = state => ({
  ...state,
  wizard: {
    ...state.wizard,
    saveFeedback: requestFeedback()
  }
})

const saveSuccess = (state, { contract }) => ({
  ...state,
  originalContract: {
    ...state.originalContract,
    contract: normalizeContracValues(contract),
  },
  wizard: {
    ...state.wizard,
    view: WIZARD_VIEWS.FEEDBACK,
    saveFeedback: successFeedback(),
  },
})

const saveFailure = (state, { errors }) => ({
  ...state,
  wizard: {
    ...state.wizard,
    saveFeedback: failureFeedback(errors)
  }
})

const partialUpdate = state => ({
  ...state,
  partialUpdate: {
    feedback: requestFeedback(),
  },
})

const partialUpdateSuccess = (state, { contract }) => ({
  ...state,
  partialUpdate: {
    feedback: successFeedback(),
  },
})

const partialUpdateFailure = (state, { errors }) => ({
  ...state,
  partialUpdate: {
    feedback: failureFeedback(errors),
  },
})

const cleanPartialUpdate = state => ({
  ...state,
  partialUpdate: {
    feedback: initialFeedback(),
  },
})

const updateContractInList = (state, { contractId, newContract }) => ({
  ...state,
  list: {
    ...state.list,
    results: state.list.results.map(contract =>
      contract.id === contractId
        ? { ...contract, ...newContract }
        : contract
    ),
  },
})

const clear = () => INITIAL_STATE

const requestSignatures = state =>
  updateHashedState(state, 'requestSignatures', {
    feedback: requestFeedback(),
  })

const requestSignaturesSuccess = (state, { contractId }) =>
  updateHashedState(state, 'requestSignatures', {
    feedback: successFeedback(),
  })

const requestSignaturesFailure = (state, { errors }) =>
  updateHashedState(state, 'requestSignatures', {
    feedback: failureFeedback(errors),
  })

const requestSignaturesClearState = (state) =>
  updateHashedState(state, 'requestSignatures', INITIAL_STATE.requestSignatures)

const cancelSignatures = state =>
  updateHashedState(state, 'cancelSignatures', {
    feedback: requestFeedback(),
  })

const cancelSignaturesSuccess = (state, { contractId }) =>
  updateHashedState(state, 'cancelSignatures', {
    feedback: successFeedback(),
  })

const cancelSignaturesFailure = (state, { errors }) =>
  updateHashedState(state, 'cancelSignatures', {
    feedback: failureFeedback(errors),
  })

const cancelSignaturesClearState = (state) =>
  updateHashedState(state, 'cancelSignatures', INITIAL_STATE.cancelSignatures)

export const SeoUrlsActionTypes = Types

const HANDLERS = {
  [Types.ALL]: all,
  [Types.ALL_SUCCESS]: allSuccess,
  [Types.ALL_FAILURE]: allFailure,

  [Types.CONTRACT]: contract,
  [Types.CONTRACT_SUCCESS]: contractSuccess,
  [Types.CONTRACT_FAILURE]: contractFailure,
  [Types.SET_ORIGINAL_CONTRACT]: setOriginalContract,

  [Types.REMOVE]: remove,
  [Types.REMOVE_SUCCESS]: removeSuccess,
  [Types.REMOVE_FAILURE]: removeFailure,
  [Types.REMOVE_CLEAR_STATE]: removeClearState,

  [Types.DRAFT]: draft,
  [Types.DRAFT_SUCCESS]: draftSuccess,
  [Types.DRAFT_FAILURE]: draftFailure,

  [Types.TYPES]: types,
  [Types.TYPES_SUCCESS]: typesSuccess,
  [Types.TYPES_FAILURE]: typesFailure,
  [Types.SET_SELECTED_PROPERTY]: setSelectedProperty,
  [Types.UPDATE_ANSWERS]: updateAnswers,
  [Types.SET_SHALLOW_SELECTED_PROPERTY]: setShallowSelectedProperty,

  [Types.CONTRACT_TYPE]: contractType,
  [Types.CONTRACT_TYPE_SUCCESS]: contractTypeSuccess,
  [Types.CONTRACT_TYPE_FAILURE]: contractTypeFailure,

  [Types.CONTRACT_LAYOUT_VERSION]: contractLayoutVersion,
  [Types.CONTRACT_LAYOUT_VERSION_SUCCESS]: contractLayoutVersionSuccess,
  [Types.CONTRACT_LAYOUT_VERSION_FAILURE]: contractLayoutVersionFailure,

  [Types.FILL_INITIAL_VALUES]: fillInitialValues,
  [Types.FILL_INITIAL_VALUES_SUCCESS]: fillInitialValuesSuccess,
  [Types.FILL_INITIAL_VALUES_FAILURE]: fillInitialValuesFailure,

  [Types.WIZARD_CHANGE_VIEW]: wizardChangeView,
  [Types.SET_CONTRACT_TYPE]: setContractType,
  [Types.SET_CURRENT_INDEX_QUESTION]: setCurrentIndexQuestion,

  [Types.SAVE]: save,
  [Types.SAVE_SUCCESS]: saveSuccess,
  [Types.SAVE_FAILURE]: saveFailure,

  [Types.PARTIAL_UPDATE]: partialUpdate,
  [Types.PARTIAL_UPDATE_SUCCESS]: partialUpdateSuccess,
  [Types.PARTIAL_UPDATE_FAILURE]: partialUpdateFailure,
  [Types.CLEAN_PARTIAL_UPDATE]: cleanPartialUpdate,
  [Types.UPDATE_CONTRACT_IN_LIST]: updateContractInList,


  [Types.CLEAR]: clear,

  [Types.REQUEST_SIGNATURES]: requestSignatures,
  [Types.REQUEST_SIGNATURES_SUCCESS]: requestSignaturesSuccess,
  [Types.REQUEST_SIGNATURES_FAILURE]: requestSignaturesFailure,
  [Types.REQUEST_SIGNATURES_CLEAR_STATE]: requestSignaturesClearState,

  [Types.CANCEL_SIGNATURES]: cancelSignatures,
  [Types.CANCEL_SIGNATURES_SUCCESS]: cancelSignaturesSuccess,
  [Types.CANCEL_SIGNATURES_FAILURE]: cancelSignaturesFailure,
  [Types.CANCEL_SIGNATURES_CLEAR_STATE]: cancelSignaturesClearState,
}

export const ContractsActionTypes = Types

export const ContractsActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
