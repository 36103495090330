import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {
  loading: false,
  error: false,
  success: false,
  report: null,
  profilesType: [],
  statusDeleteProperty: {
    id: null,
    loading: false,
    success: false,
    error: false
  },
  statusDeleteTenants: {
    id: null,
    loading: false,
    success: false,
    error: false
  },
  loadingContact: false,
  loadingProperties: false,
  loadingGetContact: false,
  successDeleteContact: false,
  loadingDeleteContact: false,
  errorDeleteContact: false,
  errorGetContact: false,
  loadingSubmit: false,
  initialDataContact: {},
  properties: [],
  loadingFollowUps: false,
  errorGetFollowUps: false,
  followUpsContact: {},
  loadingFollowUpDetail: false,
  errorGetFollowUpDetail: false,
  followUpDetail: {},
}

const { Types, Creators } = createActions(
  {
    sentDataContactRequest: ['data'],
    sentDataContactSuccess: [],
    sentDataContactFailure: [],
    sentDataContactClean: [],
    getContactRequest: ['id'],
    getContactSuccess: ['payload'],
    getContactFailure: [],
    deleteContactPropertyRequest: ['id', 'nameForm', 'nameField', 'index'],
    deleteContactPropertySuccess: [],
    deleteContactPropertyFailure: [],
    getContactReportRequest: ['id'],
    getContactReportSuccess: ['data'],
    getContactReportFailure: [],
    getPropertiesRequest: ['id'],
    getPropertiesSuccess: ['payload'],
    getPropertiesFailure: [],
    getProfilesTypeRequest: [],
    getProfilesTypeSuccess: ['payload'],
    getProfilesTypeFailure: [],
    deleteContactRequest: ['id'],
    deleteContactSuccess: [],
    deleteContactFailure: [],
    deleteContact: ['payload'],
    handleSubmitContactFormRequest: [
      'data',
      'profileType',
      'id',
      'handleError'
    ],
    handleSubmitContactFormSuccess: [],
    handleSubmitContactFormFailure: [],
    cleanPartialState: [],
    getContactFollowupsRequest: ['id'],
    getContactFollowupsSuccess: ['payload'],
    getContactFollowupsFailure: [],
    getFollowUpDetailRequest: ['id'],
    getFollowUpDetailSuccess: ['payload'],
    getFollowUpDetailFailure: [],
  },
  { prefix: 'CONTACT_' }
)

const sentDataContactRequest = state => ({
  ...state,
  loading: true,
  error: false,
  success: false
})

const sentDataContactSuccess = state => ({
  ...state,
  data: {},
  success: true,
  error: false,
  loading: false
})

const getContactRequest = (state, { payload }) => ({
  ...state,
  loadingContact: true,
  errorGetContact: false
})

const getContactSuccess = (state, { payload }) => ({
  ...state,
  initialDataContact: payload.data,
  loadingContact: false,
  errorGetContact: false
})

const getContactFailure = (state, { payload }) => ({
  ...state,
  loadingContact: false,
  errorGetContact: true,
  initialDataContact: {}
})

const getProfilesTypeRequest = (state, { payload }) => ({
  ...state
})

const getProfilesTypeSuccess = (state, { payload }) => ({
  ...state,
  profilesType: payload
})

const getProfilesTypeFailure = (state, { payload }) => ({
  ...state
})

const getContactReportRequest = (state, { payload }) => ({
  ...state,
  loading: true,
  success: false,
  error: false
})

const getContactReportSuccess = (state, { data }) => ({
  ...state,
  report: data,
  loading: false,
  success: true,
  error: false
})

const getContactReportFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  success: false,
  error: true
})

const deleteContactPropertyRequest = (state, { id }) => ({
  ...state,
  statusDeleteProperty: {
    id,
    loading: true,
    success: false,
    error: false
  }
})

const deleteContactPropertySuccess = state => ({
  ...state,
  statusDeleteProperty: {
    id: null,
    loading: false,
    success: true,
    error: false
  }
})

const deleteContactPropertyFailure = (state, { payload }) => ({
  ...state,
  statusDeleteProperty: {
    id: null,
    loading: false,
    success: false,
    error: true
  }
})

const deleteContactRequest = (state, { payload }) => ({
  ...state,
  successDeleteContact: false,
  loadingDeleteContact: true,
  errorDeleteContact: false
})

const deleteContactSuccess = (state, { payload }) => ({
  ...state,
  successDeleteContact: true,
  loadingDeleteContact: false,
  errorDeleteContact: false
})

const deleteContactFailure = (state, { payload }) => ({
  ...state,
  successDeleteContact: true,
  loadingDeleteContact: false,
  errorDeleteContact: true
})

const sentDataContactFailure = state => ({
  data: {},
  success: false,
  error: true,
  loading: false
})

const getPropertiesRequest = state => ({
  ...state,
  success: false,
  error: false,
  loadingProperties: true
})

const getPropertiesSuccess = (state, { payload }) => {
  const properties = payload.data ? [payload.data] : []
  return {
    ...state,
    properties,
    success: true,
    error: false,
    loadingProperties: false
  }
}

const getPropertiesFailure = state => ({
  ...state,
  success: false,
  error: true,
  loadingProperties: false
})

const deleteContact = state => state

const handleSubmitContactFormRequest = state => ({
  ...state,
  loadingSubmit: true,
  errorSubmit: false,
  success: false
})

const handleSubmitContactFormSuccess = state => ({
  ...state,
  loadingSubmit: false,
  errorSubmit: false,
  success: false
})

const handleSubmitContactFormFailure = state => ({
  ...state,
  loadingSubmit: false,
  errorSubmit: true,
  success: false
})

const cleanPartialState = ({ success, error }) => ({
  ...INITIAL_STATE,
  success,
  error
})

const getContactFollowupsRequest = (state, { payload }) => ({
  ...state,
  loadingFollowUps: true,
  errorGetFollowUps: false
})

const getContactFollowupsSuccess = (state, { payload }) => ({
  ...state,
  followUpsContact: payload.data,
  loadingFollowUps: false,
  errorGetFollowUps: false
})

const getContactFollowupsFailure = (state, { payload }) => ({
  ...state,
  loadingFollowUps: false,
  errorGetFollowUps: true,
  followUpsContact: {}
})

const getFollowUpDetailRequest = (state, { payload }) => ({
  ...state,
  loadingFollowUpDetail: true,
  errorGetFollowUpDetail: false
})
const getFollowUpDetailSuccess = (state, { payload }) => ({
  ...state,
  followUpDetail: payload.data,
  loadingFollowUpDetail: false,
  errorGetFollowUpDetail: false
})
const getFollowUpDetailFailure = (state, { payload }) => ({
  ...state,
  loadingFollowUpDetail: false,
  errorGetFollowUpDetail: true,
  followUpDetail: {}
})


const sentDataContactClean = state => INITIAL_STATE

const HANDLERS = {
  [Types.SENT_DATA_CONTACT_REQUEST]: sentDataContactRequest,
  [Types.SENT_DATA_CONTACT_SUCCESS]: sentDataContactSuccess,
  [Types.SENT_DATA_CONTACT_FAILURE]: sentDataContactFailure,

  [Types.GET_CONTACT_REQUEST]: getContactRequest,
  [Types.GET_CONTACT_SUCCESS]: getContactSuccess,
  [Types.GET_CONTACT_FAILURE]: getContactFailure,

  [Types.GET_CONTACT_REPORT_REQUEST]: getContactReportRequest,
  [Types.GET_CONTACT_REPORT_SUCCESS]: getContactReportSuccess,
  [Types.GET_CONTACT_REPORT_FAILURE]: getContactReportFailure,

  [Types.DELETE_CONTACT_PROPERTY_REQUEST]: deleteContactPropertyRequest,
  [Types.DELETE_CONTACT_PROPERTY_SUCCESS]: deleteContactPropertySuccess,
  [Types.DELETE_CONTACT_PROPERTY_FAILURE]: deleteContactPropertyFailure,

  [Types.GET_PROPERTIES_REQUEST]: getPropertiesRequest,
  [Types.GET_PROPERTIES_SUCCESS]: getPropertiesSuccess,
  [Types.GET_PROPERTIES_FAILURE]: getPropertiesFailure,

  [Types.GET_PROFILES_TYPE_REQUEST]: getProfilesTypeRequest,
  [Types.GET_PROFILES_TYPE_SUCCESS]: getProfilesTypeSuccess,
  [Types.GET_PROFILES_TYPE_FAILURE]: getProfilesTypeFailure,

  [Types.DELETE_CONTACT_REQUEST]: deleteContactRequest,
  [Types.DELETE_CONTACT_SUCCESS]: deleteContactSuccess,
  [Types.DELETE_CONTACT_FAILURE]: deleteContactFailure,

  [Types.DELETE_CONTACT]: deleteContact,

  [Types.HANDLE_SUBMIT_CONTACT_FORM_REQUEST]: handleSubmitContactFormRequest,
  [Types.HANDLE_SUBMIT_CONTACT_FORM_SUCCESS]: handleSubmitContactFormSuccess,
  [Types.HANDLE_SUBMIT_CONTACT_FORM_FAILURE]: handleSubmitContactFormFailure,

  [Types.CLEAN_PARTIAL_STATE]: cleanPartialState,

  [Types.SENT_DATA_CONTACT_CLEAN]: sentDataContactClean,

  [Types.GET_CONTACT_FOLLOWUPS_REQUEST]: getContactFollowupsRequest,
  [Types.GET_CONTACT_FOLLOWUPS_SUCCESS]: getContactFollowupsSuccess,
  [Types.GET_CONTACT_FOLLOWUPS_FAILURE]: getContactFollowupsFailure,

  [Types.GET_FOLLOW_UP_DETAIL_REQUEST]: getFollowUpDetailRequest,
  [Types.GET_FOLLOW_UP_DETAIL_SUCCESS]: getFollowUpDetailSuccess,
  [Types.GET_FOLLOW_UP_DETAIL_FAILURE]: getFollowUpDetailFailure

}

export const ContactActionTypes = Types

export const ContactActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
