import { CURRENCY_TYPES } from 'services/seo'
import { makeRangeIterator } from 'components/utils'
import { titleize } from 'app/services/string'

/**
 * In the next function "matches" param is an array with the result of 'urlPath'.match(AMENITIES_PATTERNS.amenity)
 * AMENITIES_PATTERNS.amenity is a Regex that has the Regex of amenity
 */

const priceParser = matches => {
  if (!matches) {
    return null
  }
  const [minPrice, maxPrice] = matches[0].match(/\d+/g)
  const [currency] = matches[0].match(/(pesos|dolares)/g)

  const params = {
    currency: CURRENCY_TYPES.find(({ url }) => url === currency)?.value
  }

  if (minPrice && maxPrice) {
    params.minPrice = Number(minPrice)
    params.maxPrice = Number(maxPrice)

    return params
  }

  params.price = Number(minPrice)

  return params
}

const numberMatch = (matches, key) => {
  if (!matches) {
    return null
  }

  const [count] = matches[0].match(/\d+/g)
  return { [key]: Number(count) }
}

const booleanMatch = (matches, key) => {
  if (!matches) {
    return null
  }

  return { [key]: true }
}

const AMENITIES_PARSERS = {
  gym: matches => booleanMatch(matches, 'gym'),
  minAndMaxPrice: priceParser,
  minPrice: matches => {
    const { currency, price } = priceParser(matches)
    return {
      currency,
      minPrice: price
    }
  },
  maxPrice: matches => {
    const { currency, price } = priceParser(matches)
    return {
      currency,
      maxPrice: price
    }
  },
  bedrooms: matches => numberMatch(matches, 'bedrooms'),
  minBedrooms: matches => numberMatch(matches, 'minBedrooms'),
  parkingSpaces: matches => numberMatch(matches, 'parkingSpaces'),
  minParkingSpaces: matches => numberMatch(matches, 'minParkingSpaces'),
  bathrooms: matches => numberMatch(matches, 'bathrooms'),
  minBathrooms: matches => numberMatch(matches, 'minBathrooms'),
  halfBathrooms: matches => numberMatch(matches, 'halfBathrooms'),
  minHalfBathrooms: matches => numberMatch(matches, 'minHalfBathrooms'),
  swimmingPool: matches => booleanMatch(matches, 'swimmingPool'),
  elevator: matches => booleanMatch(matches, 'elevator'),
  security: matches => booleanMatch(matches, 'security'),
  intercom: matches => booleanMatch(matches, 'intercom'),
  equippedKitchen: matches => booleanMatch(matches, 'equippedKitchen'),
  wardrobe: matches => booleanMatch(matches, 'wardrobe'),
  airConditioning: matches => booleanMatch(matches, 'airConditioning'),
  heating: matches => booleanMatch(matches, 'heating'),
  serviceRoom: matches => booleanMatch(matches, 'serviceRoom'),
  balcony: matches => booleanMatch(matches, 'balcony'),
  terrace: matches => booleanMatch(matches, 'terrace'),
  visitorParking: matches => booleanMatch(matches, 'visitorParking'),
  kidsPlayArea: matches => booleanMatch(matches, 'kidsPlayArea'),
  garden: matches => booleanMatch(matches, 'garden'),
  jacuzzi: matches => booleanMatch(matches, 'jacuzzi'),
  roofGarden: matches => booleanMatch(matches, 'roofGarden')
}

const AMENITIES_PATTERNS = {
  bedrooms: /con-\d+-habitacion[es]{0,2}-cuarto[s]{0,1}/,
  minBedrooms: /con-mas-de-\d+-habitacion[es]{0,2}-cuarto[s]{0,1}/g,
  parkingSpaces: /con-\d+-estacionamiento[s]{0,1}/g,
  minParkingSpaces: /con-mas-de-\d+-estacionamiento[s]{0,1}/g,
  bathrooms: /con-\d+-bano[s]{0,1}/g,
  minBathrooms: /con-mas-de-\d+-bano[s]{0,1}/g,
  halfBathrooms: /con-\d+-medio[s]{0,1}-bano[s]{0,1}/g,
  minHalfBathrooms: /con-mas-de-\d+-medio[s]{0,1}-bano[s]{0,1}/g,
  swimmingPool: /con-alberca/g,
  minAndMaxPrice: /de-\d+-a-\d+-(pesos|dolares)/g,
  minPrice: /desde-\d+-(pesos|dolares)/g,
  maxPrice: /hasta-\d+-(pesos|dolares)/g,
  elevator: /con-elevador/g,
  security: /con-seguridad-guardia/g,
  intercom: /con-intercomunicador/g,
  equippedKitchen: /con-cocina-equipada/g,
  wardrobe: /con-armario/g,
  gym: /con-gimnasio/g,
  airConditioning: /con-aire-acondicionado/g,
  heating: /con-calefaccion/g,
  serviceRoom: /con-cuarto-de-servicio/g,
  balcony: /con-balcon/g,
  terrace: /con-terraza/g,
  visitorParking: /con-estacionamiento-para-visitas/g,
  kidsPlayArea: /con-juegos-infantiles/g,
  garden: /con-jardin/g,
  jacuzzi: /con-jacuzzi/g,
  roofGarden: /con-roof-garden/g
}

/**
 * Special amenities should be ordered by relevance
 * because it's going to use to build URL path
 */
export const SPECIAL_AMENITIES = [
  {
    label: 'Habitaciones/cuartos',
    value: 'bedrooms',
    propertyTypes: ['HOUSE', 'APARTMENT'],
  },
  {
    label: 'Habitaciones/cuartos',
    value: 'minBedrooms',
    propertyTypes: ['HOUSE', 'APARTMENT'],
  },
  {
    label: 'Estacionamientos',
    value: 'parkingSpaces',
    propertyTypes: ['HOUSE', 'APARTMENT', 'OFFICE'],
  },
  {
    label: 'Estacionamientos',
    value: 'minParkingSpaces',
    propertyTypes: ['HOUSE', 'APARTMENT', 'OFFICE'],
  },
  {
    label: 'Baños',
    value: 'bathrooms',
    propertyTypes: ['HOUSE', 'APARTMENT', 'OFFICE'],
  },
  {
    label: 'Baños',
    value: 'minBathrooms',
    propertyTypes: ['HOUSE', 'APARTMENT', 'OFFICE'],
  },
  {
    label: 'Medios baños',
    value: 'halfBathrooms',
    propertyTypes: ['HOUSE', 'APARTMENT'],
  },
  {
    label: 'Medios baños',
    value: 'minHalfBathrooms',
    propertyTypes: ['HOUSE', 'APARTMENT'],
  },
  {
    label: 'Alberca',
    value: 'swimmingPool'
  },

  {
    label: 'Rango de precio',
    value: 'minAndMaxPrice'
  },
  {
    label: 'Precio mínimo',
    value: 'minPrice'
  },
  {
    label: 'Precio máximo',
    value: 'maxPrice'
  },

  {
    label: 'Elevadores',
    value: 'elevator'
  },
  {
    label: 'Seguridad privada',
    value: 'security'
  },
  {
    label: 'Intercomunicador',
    value: 'intercom'
  },
  {
    label: 'Cocina equipada',
    value: 'equippedKitchen'
  },
  {
    label: 'Armarios empotrados',
    value: 'wardrobe'
  },
  {
    label: 'Gimnasio',
    value: 'gym'
  },
  {
    label: 'Aire acondicionado',
    value: 'airConditioning'
  },
  {
    label: 'Calefacción',
    value: 'heating'
  },
  {
    label: 'Cuarto de servicio',
    value: 'serviceRoom'
  },
  {
    label: 'Balcón',
    value: 'balcony'
  },
  {
    label: 'Terraza',
    value: 'terrace'
  },
  {
    label: 'Estacionamiento para visitas',
    value: 'visitorParking'
  },
  {
    label: 'Área de juegos infantiles',
    value: 'kidsPlayArea'
  },
  {
    label: 'Jardín',
    value: 'garden'
  },
  {
    label: 'Jacuzzi',
    value: 'jacuzzi'
  },
  {
    label: 'Roof garden',
    value: 'roofGarden'
  }
]

export const SPECIAL_AMENITIES_FIELDS = SPECIAL_AMENITIES.map(
  ({ value }) => value
)

export const NUMERIC_SPECIAL_AMENITIES = [
  'bedrooms',
  'parkingSpaces',
  'bathrooms',
  'halfBathrooms',
]

export const BOOLEAN_SPECIAL_AMENITIES = [
  'swimmingPool',
  'elevator',
  'security',
  'intercom',
  'equippedKitchen',
  'wardrobe',
  'gym',
  'airConditioning',
  'heating',
  'serviceRoom',
  'balcony',
  'terrace',
  'visitorParking',
  'kidsPlayArea',
  'garden',
  'jacuzzi',
  'roofGarden'
]

export const DROPDOWN_AMENITIES = SPECIAL_AMENITIES.filter(a =>
  BOOLEAN_SPECIAL_AMENITIES.includes(a.value)
)

/**
 * { bedrooms: 'Habitaciones/cuartos', roofGarden: 'Roof garden', ... }
 */
export const SPECIAL_AMENITIES_LABELS = SPECIAL_AMENITIES.reduce(
  (acc, { label, value }) => ({
    ...acc,
    [value]: label
  }),
  {}
)

/**
 * { 'Habitaciones/cuartos': 'bedrooms', 'Roof garden': 'roofGarden', ... }
 */
 export const SPECIAL_AMENITIES_NAMES = SPECIAL_AMENITIES.reduce(
  (acc, { label, value }) => ({
    ...acc,
    [label]: value
  }),
  {}
)

export const AMENITIES_BUILDERS = {
  bedrooms: ({ bedrooms }) => {
    let path = ''

    if (Number(bedrooms) > 1) {
      path = `con-${bedrooms}-habitaciones-cuartos`
    } else if (Number(bedrooms) === 1) {
      path = `con-${bedrooms}-habitacion-cuarto`
    }

    return validatePath('bedrooms', path)
  },
  minBedrooms: ({ minBedrooms }) => {
    let path = ''

    if (Number(minBedrooms) > 1) {
      path = `con-mas-de-${minBedrooms}-habitaciones-cuartos`
    } else if (Number(minBedrooms) === 1) {
      path = `con-mas-de-${minBedrooms}-habitacion-cuarto`
    }

    return validatePath('minBedrooms', path)
  },
  parkingSpaces: ({ parkingSpaces }) => {
    let path = ''

    if (Number(parkingSpaces) > 1) {
      path = `con-${parkingSpaces}-estacionamientos`
    } else if (Number(parkingSpaces) === 1) {
      path = `con-${parkingSpaces}-estacionamiento`
    }

    return validatePath('parkingSpaces', path)
  },
  minParkingSpaces: ({ minParkingSpaces }) => {
    let path = ''

    if (Number(minParkingSpaces) > 1) {
      path = `con-mas-de-${minParkingSpaces}-estacionamientos`
    } else if (Number(minParkingSpaces) === 1) {
      path = `con-mas-de-${minParkingSpaces}-estacionamiento`
    }

    return validatePath('minParkingSpaces', path)
  },
  bathrooms: ({ bathrooms }) => {
    let path = ''

    if (Number(bathrooms) > 1) {
      path = `con-${bathrooms}-banos`
    } else if (Number(bathrooms) === 1) {
      path = `con-${bathrooms}-bano`
    }

    return validatePath('bathrooms', path)
  },
  minBathrooms: ({ minBathrooms }) => {
    let path = ''

    if (Number(minBathrooms) > 1) {
      path = `con-mas-de-${minBathrooms}-banos`
    } else if (Number(minBathrooms) === 1) {
      path = `con-mas-de-${minBathrooms}-bano`
    }

    return validatePath('minBathrooms', path)
  },
  halfBathrooms: ({ halfBathrooms }) => {
    let path = ''

    if (Number(halfBathrooms) > 1) {
      path = `con-${halfBathrooms}-medios-banos`
    } else if (Number(halfBathrooms) === 1) {
      path = `con-${halfBathrooms}-medio-bano`
    }

    return validatePath('halfBathrooms', path)
  },
  minHalfBathrooms: ({ minHalfBathrooms }) => {
    let path = ''

    if (Number(minHalfBathrooms) > 1) {
      path = `con-mas-de-${minHalfBathrooms}-medios-banos`
    } else if (Number(minHalfBathrooms) === 1) {
      path = `con-mas-de-${minHalfBathrooms}-medio-bano`
    }

    return validatePath('minHalfBathrooms', path)
  },
  swimmingPool: ({ swimmingPool }) =>
    validatePath('swimmingPool', swimmingPool && 'con-alberca'),
  minAndMaxPrice: amenities => priceBuilder('minAndMaxPrice', amenities),
  minPrice: amenities => priceBuilder('minPrice', amenities),
  maxPrice: amenities => priceBuilder('maxPrice', amenities),
  elevator: ({ elevator }) =>
    validatePath('elevator', elevator && 'con-elevador'),
  security: ({ security }) =>
    validatePath('security', security && 'con-seguridad-guardia'),
  intercom: ({ intercom }) =>
    validatePath('intercom', intercom && 'con-intercomunicador'),
  equippedKitchen: ({ equippedKitchen }) =>
    validatePath('equippedKitchen', equippedKitchen && 'con-cocina-equipada'),
  wardrobe: ({ wardrobe }) =>
    validatePath('wardrobe', wardrobe && 'con-armario'),
  gym: ({ gym }) => validatePath('gym', gym && 'con-gimnasio'),
  airConditioning: ({ airConditioning }) =>
    validatePath(
      'airConditioning',
      airConditioning && 'con-aire-acondicionado'
    ),
  heating: ({ heating }) =>
    validatePath('heating', heating && 'con-calefaccion'),
  serviceRoom: ({ serviceRoom }) =>
    validatePath('serviceRoom', serviceRoom && 'con-cuarto-de-servicio'),
  balcony: ({ balcony }) => validatePath('balcony', balcony && 'con-balcon'),
  terrace: ({ terrace }) => validatePath('terrace', terrace && 'con-terraza'),
  visitorParking: ({ visitorParking }) =>
    validatePath(
      'visitorParking',
      visitorParking && 'con-estacionamiento-para-visitas'
    ),
  kidsPlayArea: ({ kidsPlayArea }) =>
    validatePath('kidsPlayArea', kidsPlayArea && 'con-juegos-infantiles'),
  garden: ({ garden }) => validatePath('garden', garden && 'con-jardin'),
  jacuzzi: ({ jacuzzi }) => validatePath('jacuzzi', jacuzzi && 'con-jacuzzi'),
  roofGarden: ({ roofGarden }) =>
    validatePath('roofGarden', roofGarden && 'con-roof-garden')
}

export const AMENITIES_LABELS_BUILDERS = {
  bedrooms: ({ bedrooms }) => {
    if (Number(bedrooms) > 1) {
      return `${bedrooms} habitaciones/cuartos`
    } else if (Number(bedrooms) === 1) {
      return `${bedrooms} habitación/cuarto`
    }
  },
  minBedrooms: ({ minBedrooms }) => {
    if (Number(minBedrooms) > 1) {
      return `Más de ${minBedrooms} habitaciones/cuartos`
    } else if (Number(minBedrooms) === 1) {
      return `Más de ${minBedrooms} habitación/cuarto`
    }
  },
  parkingSpaces: ({ parkingSpaces }) => {
    if (Number(parkingSpaces) > 1) {
      return `${parkingSpaces} estacionamientos`
    } else if (Number(parkingSpaces) === 1) {
      return `${parkingSpaces} estacionamiento`
    }
  },
  minParkingSpaces: ({ minParkingSpaces }) => {
    if (Number(minParkingSpaces) > 1) {
      return `Más de ${minParkingSpaces} estacionamientos`
    } else if (Number(minParkingSpaces) === 1) {
      return `Más de ${minParkingSpaces} estacionamiento`
    }
  },
  bathrooms: ({ bathrooms }) => {
    if (Number(bathrooms) > 1) {
      return `${bathrooms} baños`
    } else if (Number(bathrooms) === 1) {
      return `${bathrooms} baño`
    }
  },
  minBathrooms: ({ minBathrooms }) => {
    if (Number(minBathrooms) > 1) {
      return `Más de ${minBathrooms} baños`
    } else if (Number(minBathrooms) === 1) {
      return `Más de ${minBathrooms} baño`
    }
  },
  halfBathrooms: ({ halfBathrooms }) => {
    if (Number(halfBathrooms) > 1) {
      return `${halfBathrooms} medios baños`
    } else if (Number(halfBathrooms) === 1) {
      return `${halfBathrooms} medio baño`
    }
  },
  minHalfBathrooms: ({ minHalfBathrooms }) => {
    if (Number(minHalfBathrooms) > 1) {
      return `Más de ${minHalfBathrooms} baños`
    } else if (Number(minHalfBathrooms) === 1) {
      return `Más de ${minHalfBathrooms} baño`
    }
  },
  swimmingPool: ({ swimmingPool }) =>
    swimmingPool && SPECIAL_AMENITIES_LABELS.swimmingPool,

  minAndMaxPrice: amenities => priceLabelBuilder('minAndMaxPrice', amenities),
  minPrice: amenities => priceLabelBuilder('minPrice', amenities),
  maxPrice: amenities => priceLabelBuilder('maxPrice', amenities),

  elevator: ({ elevator }) => elevator && SPECIAL_AMENITIES_LABELS.elevator,
  security: ({ security }) => security && SPECIAL_AMENITIES_LABELS.security,
  intercom: ({ intercom }) => intercom && SPECIAL_AMENITIES_LABELS.intercom,
  equippedKitchen: ({ equippedKitchen }) =>
    equippedKitchen && SPECIAL_AMENITIES_LABELS.equippedKitchen,
  wardrobe: ({ wardrobe }) => wardrobe && SPECIAL_AMENITIES_LABELS.wardrobe,
  gym: ({ gym }) => gym && SPECIAL_AMENITIES_LABELS.gym,
  airConditioning: ({ airConditioning }) =>
    airConditioning && SPECIAL_AMENITIES_LABELS.airConditioning,
  heating: ({ heating }) => heating && SPECIAL_AMENITIES_LABELS.heating,
  serviceRoom: ({ serviceRoom }) =>
    serviceRoom && SPECIAL_AMENITIES_LABELS.serviceRoom,
  balcony: ({ balcony }) => balcony && SPECIAL_AMENITIES_LABELS.balcony,
  terrace: ({ terrace }) => terrace && SPECIAL_AMENITIES_LABELS.terrace,
  visitorParking: ({ visitorParking }) =>
    visitorParking && SPECIAL_AMENITIES_LABELS.visitorParking,
  kidsPlayArea: ({ kidsPlayArea }) =>
    kidsPlayArea && SPECIAL_AMENITIES_LABELS.kidsPlayArea,
  garden: ({ garden }) => garden && SPECIAL_AMENITIES_LABELS.garden,
  jacuzzi: ({ jacuzzi }) => jacuzzi && SPECIAL_AMENITIES_LABELS.jacuzzi,
  roofGarden: ({ roofGarden }) =>
    roofGarden && SPECIAL_AMENITIES_LABELS.roofGarden
}

/**
 * getAmenityPath
 * @param {String} urlPath - /departamentos/con-balcon/pagina-3
 * @returns {String|null} - con-balcon
 */
export function getAmenityPath(urlPath) {
  const paths = String(urlPath).split('/')

  const foundPath = paths.find(path =>
    SPECIAL_AMENITIES.find(({ value }) => path.match(AMENITIES_PATTERNS[value]))
  )

  return foundPath || null
}

/**
 * parseURLAmenities
 * @param {String} urlPath - /con-alberca-5-habitaciones-cuartos
 * @returns {Object|null}
 *  {
 *    values: { bedrooms: 5, waterpool: true },
 *    formattedPath: '5-habitaciones-cuartos-con-alberca'
 *  }
 */
export function parseURLAmenities(urlPath) {
  let amenities = {}

  for (const { value } of SPECIAL_AMENITIES) {
    const pattern = AMENITIES_PATTERNS[value]
    const parseValue = AMENITIES_PARSERS[value]
    const matches = urlPath.match(pattern)

    if (matches && parseValue) {
      amenities = {
        ...amenities,
        ...parseValue(matches)
      }
    }
  }

  return Object.keys(amenities).length > 0 ? amenities : null
}

const validatePath = (key, path) => {
  const pattern = AMENITIES_PATTERNS[key]

  if (pattern?.lastIndex) {
    // To Reset lastIndex and prevent error after multiples calls
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/lastIndex
    pattern.lastIndex = 0
  }

  return pattern && pattern.test(path) ? path : ''
}

const priceBuilder = (
  type,
  { minAndMaxPrice, minPrice, maxPrice, currency = 'MXN' }
) => {
  let path = ''
  const currencyUrl = CURRENCY_TYPES.find(({ value }) => value === currency)
    ?.url

  if (type === 'minAndMaxPrice' && minAndMaxPrice) {
    const [minPrice, maxPrice] = minAndMaxPrice.split('-')
    return validatePath(
      'minAndMaxPrice',
      `de-${minPrice}-a-${maxPrice}-${currencyUrl}`
    )
  }

  if (type === 'minPrice') {
    return validatePath('minPrice', `desde-${minPrice}-${currencyUrl}`)
  }

  if (type === 'maxPrice') {
    return validatePath('maxPrice', `hasta-${maxPrice}-${currencyUrl}`)
  }

  return path
}

/**
 * @function mapAmenities
 * @description To use with calculated values as minAndMaxPrice param that comes form min and max price.
 * @param {Object} amenities
 */
const mapAmenities = amenities => {
  const { minPrice, maxPrice, ...restAmenities } = amenities

  const copyAmenities = { ...restAmenities }

  if (minPrice && maxPrice) {
    copyAmenities.minAndMaxPrice = `${minPrice}-${maxPrice}`
  } else if (minPrice) {
    copyAmenities.minPrice = minPrice
  } else if (maxPrice) {
    copyAmenities.maxPrice = maxPrice
  }

  return copyAmenities
}

/**
 * amenityPaths
 * @param {Object[]} amenities
 * @example
 * buildURLAmenities({ gym: true, garden: true })
 * > ['con-gimnasio', 'con-jardin']
 * @returns {String[]}
 */
export function amenityPaths(amenities) {
  const path = []
  const mappedAmenities = mapAmenities(amenities)

  for (const { value } of SPECIAL_AMENITIES) {
    const builder = AMENITIES_BUILDERS[value]

    const amenityURL = builder && builder(mappedAmenities)

    if (amenityURL) {
      path.push(amenityURL)
    }
  }

  return path
}

/**
 * buildURLAmenities
 * @param {Object[]} - amenities
 * @example
 * buildURLAmenities({ gym: true, garden: true })
 * > 'con-gimnasio-con-jardin'
 * @returns {String} - With path or an empty string
 */
export function buildURLAmenities(amenities) {
  const path = amenityPaths(amenities)

  return path.join('-')
}

/**
 * buildAmenitiesDescription
 * @param {Object[]} - amenities
 * @example
 * buildAmenitiesDescription(['Seguridad privada', 'Cancha de tenis'])
 * > 'con Seguridad privada y Cancha de tenis'
 * @returns {String} - With path or an empty string
 */
export function buildAmenitiesDescription(amenities) {
  if (!amenities || amenities.length === 0) {
    return ''
  }
  const [amenity, ...path] = amenities
  const description = `con ${path.join(', ')}`

  if (amenities.length >= 2) {
    return `${description} y ${amenity}`
  }
  return description
}

/**
 * TODO: build a unique function to map SRP params.
 * Today SRP receibes all params and map them to 
 * conver, for example, bedrooms >= 5 ---> minBedrooms = 5;
 */
const PARAMS_MANAGED_BY_SRP = [
  "bedrooms",
  "minBedrooms",
  "bathrooms",
  "minBathrooms",
  "parkingSpaces",
  "minParkingSpaces",
]

/**
 * @function getValidAmenityParams
 * To use only in SRP
 * @param {Object} params
 */
 export const getValidAmenityParams = params => {
  return Object.keys(params)
    .filter(
      param =>
        !PARAMS_MANAGED_BY_SRP.includes(param) &&
        SPECIAL_AMENITIES_FIELDS.includes(param)
    )
    .reduce(
      (acc, paramName) => ({ ...acc, [paramName]: params[paramName] }),
      {}
    )
}

/**
 * @function isAmenityParam
 * @param {String} param
 * @returns {Boolean}
 */
export const isBoolAmenityParam = param => BOOLEAN_SPECIAL_AMENITIES.includes(param)

const priceLabelBuilder = (
  type,
  { minAndMaxPrice, minPrice, maxPrice, currency = 'MXN' }
) => {
  const currencyLabel = CURRENCY_TYPES.find(({ value }) => value === currency)
    ?.url

  if (type === 'minAndMaxPrice' && minAndMaxPrice) {
    const [minPrice, maxPrice] = minAndMaxPrice.split('-')
    return `De ${minPrice} a ${maxPrice} ${currencyLabel}`
  }

  if (type === 'minPrice' && minPrice) {
    return `Desde ${minPrice} ${currencyLabel}`
  }

  if (type === 'maxPrice' && maxPrice) {
    return `Hasta ${maxPrice} ${currencyLabel}`
  }
}

/**
 * @function amenitiesLabels
 * @param {Object} amenities
 * @example amenitiesLabels({ minPrice: 15000, gym: true }) -> ['desde 15000 pesos', 'con gimnasio']
 * @returns {String[]}
 */
export const amenitiesLabels = amenities => {
  const labels = []
  const mappedAmenities = mapAmenities(amenities)

  for (const { value } of SPECIAL_AMENITIES) {
    const builder = AMENITIES_LABELS_BUILDERS[value]

    const amenityLabel = builder && builder(mappedAmenities)

    if (amenityLabel) {
      labels.push(amenityLabel)
    }
  }

  return labels
}

/**
 * @function amenitiesFromParams
 * @param {Object} srpParams
 * @returns {Object} - Only amenities with values
 */
export const amenitiesFromParams = srpParams =>
  SPECIAL_AMENITIES_FIELDS.filter(amenity => srpParams[amenity]).reduce(
    (acc, amenity) => ({
      ...acc,
      [amenity]: srpParams[amenity]
    }),
    {}
  )

/**
 * @function amenitiesToValues
 * It only works with boolean amenities like
 * @example
 * > amenityNames(['Gimnasio', 'Aire acondicionado'])
 * > { gym: true, airConditioning: true }
 * @param {String[]} amenityNames
 */
 export const amenitiesToValues = amenityNames => {
  const amenities = {}
  amenityNames.forEach(label => {
    const fieldName = SPECIAL_AMENITIES_NAMES[label]
    if (fieldName) {
      amenities[fieldName] = true
    }
  })
  return amenities
}

/**
 * @function getNumericAmenities
 * @returns {Object[]}
 * @example
 * getNumericAmenities()
 * [
 *  {
 *    name: 'Habitaciones/cuartos',
 *    amenities: [
 *      {
 *        name: "Con 1 Habitación/cuarto",
 *        fieldName: "bedrooms",
 *        amenityValue: { bedrooms: 1 },
 *        propertyTypes: ['HOUSE', 'APARTMENT']
 *      },
 *      {
 *        name: "Con 2 Habitaciones/cuartos",
 *        fieldName: "bedrooms",
 *        amenityValue: { bedrooms: 2 },
 *        propertyTypes: ['HOUSE', 'APARTMENT']
 *      },
 *      ...
 *    ]
 *  }
 *  ...
 * ]
 */
export function getNumericAmenities() {
  const numOfAmenities = Array.from(makeRangeIterator(1, 5))
  const numericAmenities = []

  for (const name of NUMERIC_SPECIAL_AMENITIES) {
    const amenities = []
    for (const number of numOfAmenities) {
      amenities.push({
        name: titleize('con ' + amenitiesLabels({ [name]: number }).shift()),
        fieldName: name,
        amenityValue: {
          [name]: number
        },
        propertyTypes:
          SPECIAL_AMENITIES.find(amenity => amenity.value === name)
            ?.propertyTypes || []
      })
    }
    numericAmenities.push({
      name: SPECIAL_AMENITIES_LABELS[name],
      amenities: amenities
    })
  }

  return numericAmenities
}
