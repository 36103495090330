import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {
  tableCall: {
    dataTableCalls: [],
    offset: 0,
    currentPage: 0,
    loading: false,
    error: false,
    success: false
  },
  callRecordsStats: {
    dataCallRecordsStats: [],
    loading: false,
    error: false,
    success: false
  },
  generalParams: {
    from: null,
    to: null,
    currentPage: 0,
    offset: null,
    count: 0
  }
}

const { Types, Creators } = createActions(
  {
    getDataTableCalls: ['to', 'from', 'currentPage', 'offset'],
    getDataTableCallsSuccess: ['dataTableCalls', 'count'],
    getDataTableCallsFailure: [],
    getCallRecordsStats: ['to', 'from'],
    getCallRecordsStatsSuccess: ['dataCallRecordsStats'],
    getCallRecordsStatsFailure: [],
    cleanState: []
  },
  { prefix: 'PORTALS_' }
)

const getCallRecordsStats = (state, { to, from }) => ({
  ...state,
  generalParams: {
    ...state.generalParams,
    to,
    from
  },
  callRecordsStats: {
    dataCallRecordsStats: [],
    loading: true,
    error: false,
    success: false
  }
})

const getCallRecordsStatsSuccess = (state, { dataCallRecordsStats }) => ({
  ...state,
  callRecordsStats: {
    ...state.callRecordsStats,
    dataCallRecordsStats,
    loading: false,
    error: false,
    success: true
  }
})

const getCallRecordsStatsFailure = (state, {}) => ({
  ...state,
  callRecordsStats: {
    ...state.callRecordsStats,
    dataCallRecordsStats: [],
    loading: false,
    error: true,
    success: false
  }
})

const getDataTableCalls = (
  state,
  { to, from, offset = 0, currentPage = 0 }
) => ({
  ...state,
  generalParams: { to, from, currentPage, offset },
  tableCall: {
    dataTableCalls: [],
    loading: true,
    success: false,
    error: false
  }
})

const getDataTableCallsSuccess = (state, { dataTableCalls, count = 0 }) => ({
  ...state,
  generalParams: {
    ...state.generalParams,
    count
  },
  tableCall: {
    dataTableCalls,
    loading: false,
    success: true,
    error: false
  }
})

const getDataTableCallsFailure = (state, {}) => ({
  ...state,
  tableCall: {
    dataTableCalls: [],
    loading: false,
    success: false,
    error: true
  }
})

const cleanState = () => INITIAL_STATE

const HANDLERS = {
  [Types.GET_DATA_TABLE_CALLS]: getDataTableCalls,
  [Types.GET_DATA_TABLE_CALLS_SUCCESS]: getDataTableCallsSuccess,
  [Types.GET_DATA_TABLE_CALLS_FAILURE]: getDataTableCallsFailure,

  [Types.GET_CALL_RECORDS_STATS]: getCallRecordsStats,
  [Types.GET_CALL_RECORDS_STATS_SUCCESS]: getCallRecordsStatsSuccess,
  [Types.GET_CALL_RECORDS_STATS_FAILURE]: getCallRecordsStatsFailure,

  [Types.CLEAN_STATE]: cleanState
}

export const PortalsActionTypes = Types
export const PortalsActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
