import { createReducer, createActions } from 'reduxsauce'
import { camelToSnake } from 'services/string'

const BLUEPRINT_STATE = {
  loading: false,
  finished: false,
  error: null,
  request: null,
  response: null
}

const getInitialState = (actions) => actions
  .map((a) => ({ [a.name]: BLUEPRINT_STATE }))
  .reduce((acc, value) => ({ ...acc, ...value }))

const getActionParams = (actions) => actions
  .map((a) => ({
    [`${a.name}Clear`]: [],
    [`${a.name}Request`]: a.params,
    [`${a.name}Success`]: ['response'],
    [`${a.name}Failure`]: ['error'],
    [`${a.name}UpdateResponse`]: ['newResponse'],
  }))
  .reduce((acc, value) => ({ ...acc, ...value }))

const createHandlers = (actions, initialState, types, model) => actions
  .map((a) => {
    const actionName = a.name
    const modelNameUpper = camelToSnake(model.name).toUpperCase()
    const actionNameUpper = camelToSnake(actionName).toUpperCase()
    return {
      [`STORE_${modelNameUpper}_${actionNameUpper}_CLEAR`]: (state) => ({
        ...state,
        [actionName]: {
          ...initialState[actionName]
        }
      }),
      [`STORE_${modelNameUpper}_${actionNameUpper}_REQUEST`]: (state, action) => {
        const request = {}
        a.params.forEach((p) => (request[p] = action[p]))
        return {
          ...state,
          [actionName]: {
            ...initialState[actionName],
            loading: true,
            request
          }
        }
      },
      [`STORE_${modelNameUpper}_${actionNameUpper}_SUCCESS`]: (state, action) => ({
        ...state,
        [actionName]: {
          ...state[actionName],
          loading: false,
          finished: true,
          response: action.response
        }
      }),
      [`STORE_${modelNameUpper}_${actionNameUpper}_FAILURE`]: (state, action) => ({
        ...state,
        [actionName]: {
          ...state[actionName],
          loading: false,
          finished: true,
          error: action.error
        }
      }),
      [`STORE_${modelNameUpper}_${actionNameUpper}_UPDATE_RESPONSE`]: (state, action) => ({
        ...state,
        [actionName]: {
          ...state[actionName],
          response: action.newResponse,
        }
      }),
    }
  })
  .reduce((acc, value) => ({ ...acc, ...value }))

const createReduxModel = (model) => {
  const modelNameUpper = camelToSnake(model.name).toUpperCase()

  const INITIAL_STATE = getInitialState(model.methods)

  const { Types, Creators } = createActions(getActionParams(model.methods), {
    prefix: `STORE_${modelNameUpper}_`
  })

  const HANDLERS = createHandlers(model.methods, INITIAL_STATE, Types, model)

  const Reducer = createReducer(INITIAL_STATE, HANDLERS)

  return {
    Types,
    Creators,
    Reducer,
    Prefix: `STORE_${modelNameUpper}_`,
    model,
    INITIAL_STATE,
  }
}

export default createReduxModel
