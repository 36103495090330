import ApiBase from 'app/services/api/entities/Base'
// Crear entity
function* find(id, provider) {
  return yield ApiBase.privateGet(`/v1/crm/property_valuations/${id}/?provider=${provider}`)
}

function* create(data) {
  return yield ApiBase.privatePost(`/v1/crm/property_valuations/`, {data})
}

function* imageTransformation(data) {
  return yield ApiBase.privatePost(`/v1/crm/image_transformation/`, {data})
}
export default {find, create, imageTransformation}