import css from 'styled-jsx/css'
import { colors } from 'theme'

export default css`
  .pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }

  .pagination-item {
    min-width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    background-color: ${colors.white};
    border: 1px solid ${colors.border};
    padding: 0 8px;
    padding-bottom: 2px;

    transition: background-color 280ms, color 280ms;
  }

  .pagination-item.active {
    background-color: ${colors.altText};
    color: ${colors.white};
    cursor: default;

    transition: color 280ms, background-color 280ms;
  }

  .arrow-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    color: ${colors.frame};

    transition: color 280ms;
  }

  .arrow-button-container.disabled {
    color: ${colors.disabled};
    cursor: default;

    transition: color 280ms;
  }

  .pagination-button {
    cursor: pointer;
  }
`
