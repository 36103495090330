import { createReducer, createActions } from 'reduxsauce'

/**
 * checks state:
 *    It is used to storage some events through
 *    the app, like show a modal only once in a differente view,
 *    or a message or some congratulation, etc.
 *
 *    invitations:
 *      Store info about Network invitations.
 *      This info is used to display a modal into the dashboard
 *      only once.
 *      One user can be invitted to different levels, so, we used
 *      invitationId as key:
 *      invitationId: {
 *        status: null, // "ACCEPTED"
 *        acceptedAt: null, // Or String date
 *        modalWasShowed: Boolean,
 *      }
 */

const INITIAL_STATE = {
  notifications: [],
  unreadNotifications: [],
  badge: false,
  loading: false,
  error: null,
  lastId: null,
  viewedAt: null,
  checks: {
    invitations: {}
  }
}

const { Types, Creators } = createActions(
  {
    getNotificationsRequest: [],
    getNotificationsSuccess: ['data'],
    getNotificationsFailure: ['error'],
    newNotification: ['lastId', 'viewedAt'],
    addNotification: ['notification'],
    hideBadge: [],
    acceptNetworkInvitation: ['invitationId', 'level'],
    showAcceptedNetworkInvitationMessage: ['invitationId']
  },
  { prefix: 'NOTIFICATION_' }
)

const getNotificationsRequest = state => {
  return {
    ...state,
    loading: true
  }
}

const showBadge = (lastId, viewedAt, unreadNotifications) => {
  if (!lastId || !viewedAt) {
    return true
  }

  const lastUnreadNotification = unreadNotifications[0]

  return !!(
    lastUnreadNotification &&
    lastUnreadNotification.id !== lastId &&
    lastUnreadNotification.createdAt > viewedAt
  )
}

const getNotificationsSuccess = (state, { data }) => {
  const unreadNotifications = data.results.filter(n => !n.read)
  const badge = showBadge(state.lastId, state.viewedAt, unreadNotifications)

  return {
    ...state,
    notifications: data.results,
    unreadNotifications,
    badge,
    loading: false
  }
}

const getNotificationsFailure = (state, { error }) => {
  return {
    ...state,
    error: error,
    loading: false
  }
}

const newNotification = (state, { lastId, viewedAt }) => {
  return {
    ...state,
    lastId,
    viewedAt
  }
}

const addNotification = (state, { notification }) => {
  const notifications = [notification, ...state.notifications]
  const unreadNotifications = notifications.filter(n => !n.read)

  return {
    ...state,
    notifications,
    unreadNotifications,
    badge: true
  }
}

const hideBadge = state => {
  return {
    ...state,
    badge: false
  }
}

const acceptNetworkInvitation = (state, { invitationId, level }) => {
  const copyState = { ...state }
  const { acceptedAt } = state?.checks?.invitations?.[invitationId] || {}
  if (!acceptedAt) {
    copyState.checks = {
      ...copyState.checks,
      invitations: {
        ...copyState?.checks?.invitations,
        [invitationId]: {
          level,
          acceptedAt: new Date().toString(),
          status: 'ACCEPTED',
          modalWasShowed: false
        }
      }
    }
  }
  return copyState
}

const showAcceptedNetworkInvitationMessage = (state, { invitationId }) => {
  const copyState = { ...state }
  const { acceptedAt, modalWasShowed } =
    state?.checks?.invitations?.[invitationId] || {}
  if (acceptedAt && !modalWasShowed) {
    copyState.checks = {
      ...copyState.checks,
      invitations: {
        ...copyState?.checks?.invitations,
        [invitationId]: {
          ...copyState?.checks?.invitations?.[invitationId],
          modalWasShowed: true
        }
      }
    }
  }
  return copyState
}

const HANDLERS = {
  [Types.GET_NOTIFICATIONS_REQUEST]: getNotificationsRequest,
  [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
  [Types.GET_NOTIFICATIONS_FAILURE]: getNotificationsFailure,
  [Types.NEW_NOTIFICATION]: newNotification,
  [Types.ADD_NOTIFICATION]: addNotification,
  [Types.HIDE_BADGE]: hideBadge,
  [Types.ACCEPT_NETWORK_INVITATION]: acceptNetworkInvitation,
  [Types.SHOW_ACCEPTED_NETWORK_INVITATION_MESSAGE]: showAcceptedNetworkInvitationMessage
}

export const NotificationActionTypes = Types
export const NotificationActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
