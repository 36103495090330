import crudAction from './actions'

export default {
  name: 'property',
  namespace: 'properties',
  methods: [
    ...crudAction,
    {
      name: 'contacts',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/contacts/`,
      urlParams: ['id']
    },
    {
      name: 'contactCreate',
      params: ['id', 'data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/contacts/`,
      urlParams: ['id']
    },
    {
      name: 'contactRemove',
      params: ['id', 'propertyId'],
      successCode: 204,
      method: 'Delete',
      url: ({ namespace, id, propertyId }) =>
        `/v1/${namespace}/${id}/contacts/${propertyId}/`,
      urlParams: ['id', 'propertyId']
    },
    {
      name: 'deleteWithReason',
      params: ['id', 'data'],
      successCode: 204,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/takedown/`,
      urlParams: ['id']
    },
    {
      name: 'draft',
      params: ['id', 'data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/draft/`,
      urlParams: ['id']
    },
    {
      name: 'review',
      params: ['id', 'data'],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/review/`,
      urlParams: ['id']
    },
    {
      name: 'createAndReview', // How to call the action ej: store.review('property')
      params: ['id', 'data'], // The params pass to the action store.review('property').action(id, data)
      successCode: 200, // Expected response from the backend
      method: 'Post', // Request method
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/review/`, // Url
      urlParams: ['id'], // These params will be taken from the action payload and inserted in the url instead
      saga: false // If you want only the reducers and not the saga
    },
    {
      name: 'vipContact',
      params: [
        'id',
        'fullName',
        'telephone',
        'email',
        'message',
        'propertyUrl',
        'utmSource'
      ],
      successCode: 200,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/public/${namespace}/${id}/vip_contact/`,
      urlParams: ['id']
    },
    {
      name: 'publicSearch',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/public/${namespace}/search/`,
      urlParams: []
    },
    {
      name: 'searchPublicAds',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/public/public_property_ads/`,
      urlParams: []
    },
    {
      name: 'publicFind',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/public/${namespace}/${id}/`,
      urlParams: ['id']
    },
    {
      name: 'getAvailablePhotoSessions',
      params: [],
      successCode: 200,
      method: 'Get',
      url: () => `/v1/fotec/user/available_credits/`,
      urlParams: []
    },
    {
      name: 'reportDealByProperty',
      params: ['id', 'data'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/close/`,
      urlParams: ['id']
    },
    {
      name: 'propertyHealth',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/health/`,
      urlParams: ['id']
      
    },
    {
      name: 'propertyInterestedContacts',
      params: ['id', 'data'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/interested_contacts/`,
      urlParams: ['id']
    },
    {
      name: 'propertyPortalContacts',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/portal_contacts/`,
      urlParams: ['id']
    },
    {
      name: 'getCampaigns',
      params: [],
      successCode: 200,
      method: 'Get',
      url: () => `/v1/crm/campaigns/`,
      urlParams: []
    },
    {
      name: 'internalProperties',
      params: ['propertyId'],
      successCode: 200,
      method: 'Get',
      url: ({ propertyId }) => `/v1/internal/properties/${propertyId}/`,
      urlParams: ['propertyId']
    },
  ]
}
