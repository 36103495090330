/**
 * Get you an array of request values to use into useEffect parameter
 * @example
  import { store } from 'services'
  const { someState } = useSelector(state => ({
    someState: store.someMethod('someModel').selector(state)
  }))

  useEffect(() => {
    ...
  }, mapObjToArrayValues(someState))

 * @param {Object} storeReducer
 */

export const mapObjToArrayValues = storeReducer => {
  const { loading, finished, error, response } = storeReducer

  return [loading, finished, error, response]
}