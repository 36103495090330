import api from '../api'
import { utils } from 'services'

const lockDocument = (token, documentId) => {
  const lockData =  utils.windowDataLog()

  return api.privatePost(`/v1/dokaltin/documents/${documentId}/lock/`, {
    token,
    data: {
      lockData
    }
  })
}


const getUploadForm = token => {
  return api.privateGet('/v1/dokaltin/get_upload_form', {})
}

const processFile = token => {
  return api.privatePost('/v1/dokaltin/process_file', {})
}

const getDocument = (token, documentId) => {
  return api.privateGet(`/v1/dokaltin/documents/${documentId}/`, { token })
}

const shareDocument = (token, documentId, actorId) => {
  return api.privatePost(`/v1/dokaltin/documents/${documentId}/share/`, {
    token,
    data: {
      actorId
    }
  })
}

const createDocument = (token, bondId, data) => {
  return api.privatePost(`/v1/dokaltin/bonds/${bondId}/documents/`, {
    token,
    data
  })
}

const createField = (token, documentId, pageId, data) => {
  return api.privatePost(`/v1/dokaltin/documents/${documentId}/pages/${pageId}/fields/`, { token, data })
}

const updateField = (token, fieldId, data) => {
  return api.privatePatch(`/v1/dokaltin/fields/${fieldId}/`, { token, data })
}

const deleteField = (token, fieldId) => {
  return api.privateDelete(`/v1/dokaltin/fields/${fieldId}/`, { token })
}

const getSharedDocument = (hashId, validationCode) => {
  return api.get(`/v1/dokaltin/document/${hashId}/${validationCode}/`)
}

const getDownloadedDocument = (documentId) => {
  return api.get(`/v1/dokaltin/document/${documentId}/download/`)
}

const setLogData = (token, documentId, shareId) => {
  const logData =  utils.windowDataLog()
  return api.privatePatch(`/v1/dokaltin/documents/${documentId}/share/${shareId}/`, { token, data: { logData } })
}

export default {
  getUploadForm,
  processFile,
  getDocument,
  shareDocument,
  createDocument,
  createField,
  updateField,
  deleteField,
  getSharedDocument,
  getDownloadedDocument,
  setLogData,
  lockDocument
}
