import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {
  propertiesAmount: [],
  portfolioValueMxn: [],
  portfolioValueUsd: [],
  profitEvolution: [],
  error: null
}

const { Types, Creators } = createActions(
  {
    getStatsRequest: [''],
    getStatsSuccess: ['data'],
    getStatsFailure: ['error']
  },
  { prefix: 'STATS_' }
)

const getStatsRequest = state => state

const getStatsSuccess = (state, { data }) => ({
  propertiesAmount: data.propertiesAmount,
  portfolioValueMxn: data.portfolioValueMxn,
  portfolioValueUsd: data.portfolioValueUsd,
  profitEvolution: data.profitEvolution
})

const getStatsFailure = (state, { error }) => ({
  ...state,
  error: error
})

const HANDLERS = {
  [Types.GET_STATS_REQUEST]: getStatsRequest,
  [Types.GET_STATS_SUCCESS]: getStatsSuccess,
  [Types.GET_STATS_FAILURE]: getStatsFailure
}

export const StatsActionTypes = Types
export const StatsActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
