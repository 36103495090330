import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { store } from 'services'

import { mapObjToArrayValues } from './utils'

const useManagerCallMeBack = (initialTypeOfModal = 'form') => {
  const [madeRequest, setMadeRequest] = React.useState(false)
  const [typeOfModal, setTypeOfModal] = React.useState(initialTypeOfModal)

  const dispatch = useDispatch()

  const { callMeBackRequest } = useSelector(state => ({
    callMeBackRequest: store.callMeBack('crmBinLead').selector(state)
  }))

  useEffect(() => {
    const { loading, finished, error, response } = callMeBackRequest

    if (madeRequest && (!loading && finished, !error)) {
      setTypeOfModal('success')
    }
    if (madeRequest && (!loading && finished, error)) {
    }
  }, mapObjToArrayValues(callMeBackRequest))

  const makeCallMeBackRequest = (binLeadId, values) => {
    setMadeRequest(true)
    dispatch(store.callMeBack('crmBinLead').action(binLeadId, values))
  }

  const sharedData = {
    typeOfModal,
    loading: callMeBackRequest.loading,
    error: callMeBackRequest.error
  }

  return [sharedData, { makeCallMeBackRequest, setTypeOfModal }]
}

export default useManagerCallMeBack
