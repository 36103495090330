import config from 'config'
import base64js from 'base64-js'
import { stringify } from 'qs'
import { image } from 'services'
import { required as isFilledValue } from 'app/services/validators'
import { camelToSnake } from 'app/services/string.js'

const CDN_ORIGIN = config('CDN_ORIGIN')
const CDN_PUBLIC_HOST = config('CDN_PUBLIC_HOST')
const { hasOwnProperty } = Object.prototype

export function getThumbnailSize(sizeName) {
  let width = 120
  let height = 120

  switch (sizeName) {
    case 'avatar':
      width = 400
      height = 400
      break
    case 'hero':
      width = 1100
      height = 750
      break
    case 'propertyCard':
      width = 300
      height = 200
      break
    case 'thumbnailBrochure':
      width = 350
      height = 250
      break
    case 'thumbnailBrochureTemplate':
      width = 480
      height = 375
      break
    case 'fullScreen':
      width = 1296
      height = 864
      break
    case 'background':
      width = 1440
      height = 600
      break
    default:
      break
  }

  return { width, height }
}

export function getThumbnail({
  file, sizeName, format, watermark
}) {
  const { width, height } = getThumbnailSize(sizeName)

  return getCroppedImage({
    file, watermark, width, height, format
  })
}

function safetyUrl(file) {
  try {
    return new URL(file)
  } catch (e) {
    return null
  }
}

const getRealPath = (file) => {
  // We have two cases:
  // 1. https://production-neximo.s3.amazonaws.com/users/7462/properties/179349/74b022d26854.png
  // 2. https://s3.amazonaws.com/staging.public.iadmexico.mx/users/20/properties/3019/9729a210555d.png

  const cleanFile = file.replace(/\/s3\.amazonaws\.com\//, '/')
  const fileURL = new URL(cleanFile)
  let [fileName, ...resturl] = fileURL.pathname.split('/').reverse()

  if (String(cleanFile).startsWith(CDN_ORIGIN)) {
    resturl = cleanFile
      .replace(CDN_ORIGIN, '')
      .split('/')
      .reverse()
      .slice(1)
  }

  const baseFileURL = `${CDN_PUBLIC_HOST}/public${resturl.reverse().join('/')}`

  return {
    fileName,
    baseFileURL,
  }
}

/** @function getCroppedImage
 * This function takes an url of an image stored in s3 and returns a cloudfront url
 * with the desired size and format.›
 * @param {string} file - The s3 url where the original image is stored
 * @param {boolean} watermark - True if you wanna add a watermark to the picture
 * @param {number} width - The desired width of the image
 * @param {number} height - The desired height of the image
 * @param {string} format - The desired output format of the image
 * The output formats allowed are (heic,heif,jpeg,png,raw,tiff,webp)
 * @returns {string} - The cloudfront url that includes the width, height and format desired
 * @example
 * The parameters are
 * file: 'https://production-neximo.s3.amazonaws.com/users/142/properties/6037/0753c712bcc8.jpeg'
 * watermark: true
 * width: 350
 * height: 190
 * format: 'webp'
 * the function getCropped returns
 * 'https://d3r0s78y1y4zwt.cloudfront.net/public/users/142/properties/6037/watermark/350x190/0753c712bcc8-jpeg.webp'
 */
export function getCroppedImage({
  file,
  watermark = true,
  width,
  height,
  format
}) {
  const url = safetyUrl(file)

  if (!url) {
    return image('property/defaultproperty.jpg')
  }

  let { fileName, baseFileURL } = getRealPath(file, url)

  if (watermark) {
    baseFileURL += '/watermark'
  }

  if (width && height) {
    baseFileURL += `/${width}x${height}`
  }

  if (format) {
    const [name, extension] = fileName.split('.')
    const result = `${baseFileURL}/${name}-${extension}.${format}`
    return result + url.search
  }

  return `${baseFileURL}/${fileName}${url.search}`
}

export function is(x, y) {
  // SameValue algorithm
  if (x === y) {
    // Steps 1-5, 7-10
    // Steps 6.b-6.e: +0 != -0
    // Added the nonzero y check to make Flow happy, but it is redundant
    return x !== 0 || y !== 0 || 1 / x === 1 / y
  }
  // Step 6.a: NaN == NaN
  return x !== x && y !== y
}

export function shallowEqual(objA, objB) {
  if (is(objA, objB)) {
    return true
  }

  if (
    typeof objA !== 'object'
    || objA === null
    || typeof objB !== 'object'
    || objB === null
  ) {
    return false
  }

  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)

  if (keysA.length !== keysB.length) {
    return false
  }

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i++) {
    if (
      !hasOwnProperty.call(objB, keysA[i])
      || !is(objA[keysA[i]], objB[keysA[i]])
    ) {
      return false
    }
  }

  return true
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

export function removeEmptyKeys(obj) {
  const newObject = {}
  Object.keys(obj).forEach((k) => {
    const val = obj[k]
    if (typeof val === 'boolean' || typeof val === 'number' || Boolean(val)) {
      newObject[k] = obj[k]
    }
  })
  return newObject
}

export function removeAllEmptyKeys(obj) {
  const newObject = {}
  Object.keys(obj).forEach((k) => {
    const val = obj[k]
    if (typeof val === 'object' && !Array.isArray(val) && val !== null) {
      const newVal = removeAllEmptyKeys(val)
      if (Object.keys(newVal).length > 0) {
        newObject[k] = newVal
      }
    } else if (
      typeof val === 'boolean'
      || typeof val === 'number'
      || Boolean(val)
    ) {
      newObject[k] = val
    }
  })
  return newObject
}

const isObj = (val) => typeof val === 'object' && val !== null
const isArr = (val) => Array.isArray(val)

export function normalizeObjKeys(obj, fn) {
  const newObject = {}

  for (const key in obj) {
    const newKey = fn(key)
    newObject[newKey] = obj[key]

    if (isArr(newObject[newKey])) {
      newObject[newKey] = newObject[newKey].map((val) => normalizeObjKeys(val, fn))
    } else if (isObj(newObject[newKey])) {
      newObject[newKey] = normalizeObjKeys(newObject[newKey], fn)
    }
  }

  return newObject
}

export const allKeysToUpperSnakeCase = (obj) => normalizeObjKeys(obj, (key) => camelToSnake(key).toUpperCase())

export const allKeysToSnakeCase = (obj) => normalizeObjKeys(obj, camelToSnake)

export function emptyKeysToNull(values) {
  const newValues = { ...values }

  for (const key in values) {
    const value = values[key]

    if (!isFilledValue(value)) {
      newValues[key] = null
    }
  }

  return newValues
}

export function getBase64FromImage(imageUrl) {
  const apiGatewayBasePath = config('IMAGE_GW_PATH')
  const url = `${apiGatewayBasePath}?imageUrl=${imageUrl}`

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.responseType = 'json'
    xhr.open('GET', url)

    xhr.onload = function () {
      resolve(xhr.response)
    }
    xhr.onerror = (err) => {
      reject(err)
    }
    xhr.send()
  })
}

function getGuid(includeDate = false) {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }

  if (includeDate) {
    return `${notReadableDate()}-${s4()}${s4()}${s4()}`
  }

  return s4() + s4() + s4()
}

function notReadableDate() {
  const map = {
    1: 'n',
    2: '2',
    3: 'e',
    4: '5',
    5: 'x',
    6: '6',
    7: 'i',
    8: '9',
    9: 'm',
    0: '7'
  }

  const d = Date.now().toString()

  return d
    .split('')
    .map((i) => map[i])
    .reduce((t, c) => t + c, '')
}

function getHashFilename(file) {
  const { name } = file
  const extension = name.substr(name.lastIndexOf('.'))

  return `${getGuid()}${extension}`
}

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

function findOr(iter, lambda, def) {
  const found = iter.find(lambda)
  return found || def
}

function base64ToString(data) {
  try {
    return decodeUTF8(base64js.toByteArray(data))
  } catch (ex) {
    console.log('Base64 decode error:', ex.message)
    return ''
  }
}

function decodeUTF8(bytes) {
  let s = ''
  let i = 0
  while (i < bytes.length) {
    let c = bytes[i++]
    if (c > 127) {
      if (c > 191 && c < 224) {
        if (i >= bytes.length) throw 'UTF-8 decode: incomplete 2-byte sequence'
        c = ((c & 31) << 6) | (bytes[i] & 63)
      } else if (c > 223 && c < 240) {
        if (i + 1 >= bytes.length) throw 'UTF-8 decode: incomplete 3-byte sequence'
        c = ((c & 15) << 12) | ((bytes[i] & 63) << 6) | (bytes[++i] & 63)
      } else if (c > 239 && c < 248) {
        if (i + 2 >= bytes.length) throw 'UTF-8 decode: incomplete 4-byte sequence'
        c = ((c & 7) << 18)
          | ((bytes[i] & 63) << 12)
          | ((bytes[++i] & 63) << 6)
          | (bytes[++i] & 63)
      } else {
        throw `UTF-8 decode: unknown multibyte start 0x${
          c.toString(16)
        } at index ${
          i - 1}`
      }
      ++i
    }

    if (c <= 0xffff) s += String.fromCharCode(c)
    else if (c <= 0x10ffff) {
      c -= 0x10000
      s += String.fromCharCode((c >> 10) | 0xd800)
      s += String.fromCharCode((c & 0x3ff) | 0xdc00)
    } else {
      throw `UTF-8 decode: code point 0x${
        c.toString(16)
      } exceeds UTF-16 reach`
    }
  }
  return s
}

function base64toBlob(base64Data, contentType, sliceSize) {
  contentType = contentType || 'application/octet-stream'
  sliceSize = sliceSize || 512

  const dataBase64Rep = base64Data.replace(/-/g, '+').replace(/_/g, '/')
  const byteCharacters = atob(dataBase64Rep)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  const urlBlob = URL.createObjectURL(blob)
  return urlBlob
}

function urlify(...args) {
  return args.join('/').replace(/([^:]\/)\/+/g, '$1')
}

function windowDataLog() {
  const _window = window || {}
  const _navigator = navigator || {}
  const _document = document || {}
  const _screen = screen || {}

  return {
    pageon: _window.location.pathname,
    referrer: _document.referrer,

    browserName: _navigator.appName,
    browserEngine: _navigator.product,
    appVersion: _navigator.appVersion,
    userAgent: _navigator.userAgent,
    browserLanguage: _navigator.language,
    browserOnline: _navigator.onLine,
    browserPlatform: _navigator.platform,
    javaEnabled: _navigator.javaEnabled(),
    dataCookiesEnabled: _navigator.cookieEnabled,
    cookies: _document.cookie,

    sizeScreenW: _screen.width,
    sizeScreenH: _screen.height,
    sizeDocW: _document.width,
    sizeDocH: _document.height,
    sizeAvailW: _screen.availWidth,
    sizeAvailH: _screen.availHeight,
    scrColorDepth: _screen.colorDepth,
    scrPixelDepth: _screen.pixelDepth
  }
}

export function debounce(context, func, wait, immediate = false) {
  let timeout
  return function (...args) {
    // const context = this
    const later = function () {
      timeout = null
      if (!immediate) {
        func.apply(context, ...args)
      }
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) {
      func.apply(context, ...args)
    }
  }
}

/**
 * Returns an object group by the key provided
 * @param a
 * @param key
 */
export function groupBy(a = [], key) {
  return a.reduce((acc, currentValue) => {
    const currentKeyObject = acc[currentValue[key]]
    const currentArray = currentKeyObject || []
    currentArray.push(currentValue)
    acc[currentValue[key]] = currentArray
    return acc
  }, {})
}

/**
 * Strips out html tags from HTML text
 */
function htmlToText(text = '') {
  return text.replace(/(&nbsp;|<([^>]+)>)/gi, '')
}

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[\s\W-]+/g, '-')
}

const formatTime = (time) => (time < 10 ? `0${time}` : time)

export const formattedTime = (secs) => {
  const min = parseInt(secs / 60, 10)
  const seconds = parseInt(secs % 60, 10)

  return `${formatTime(min)}:${formatTime(seconds)}`
}

const range = (from, to, step = 1) => Array.from(new Array(to), (_, index) => ({
  index: index + 1,
  m: (index + 1) % step
}))
  .filter((e) => !e.m)
  .map((e) => e.index)

function metaDescriptionProperty(description, limit = 100) {
  return htmlToText(description).substr(0, limit)
}

function getFirstImageProperty(imagesJson, width = 350, height = 230) {
  const gallery = imagesJson.filter((i) => i.imageType === 'GALLERY')
  return gallery.length > 0
    ? getCroppedImage({ file: gallery[0].url, width, height })
    : image('property/defaultproperty.jpg')
}

export function getFirstImageOriginalProperty(imagesJson) {
  const gallery = imagesJson.filter((i) => i.imageType === 'GALLERY')

  return gallery.length > 0
    ? gallery[0].url
    : image('property/defaultproperty.jpg')
}

const getLocationParamsFromSearches = (locationItem) => {
  const COM = '@'
  const params = []
  const { neighborhood, municipality, state } = locationItem

  if (neighborhood && neighborhood.postalCode) {
    params.push(`${neighborhood.postalCode}`)
  } else {
    params.push(COM)
  }

  if (neighborhood && neighborhood.name) {
    params.push(`${neighborhood.name}`)
  } else {
    params.push(COM)
  }

  if (municipality && municipality.name) {
    params.push(`${municipality.name}`)
  } else {
    params.push(COM)
  }

  if (state && state.name) {
    params.push(`${state.name}`)
  } else {
    params.push(COM)
  }

  return params.join('::').replace(/@/gi, '')
}

function queryParamsRequirementsToBin(searches) {
  const allLocations = []
  if (searches.selectedLocations) {
    searches.selectedLocations.forEach((item) => {
      allLocations.push(getLocationParamsFromSearches(item))
    })
  }

  const {
    operationType,
    propertyType,
    bathrooms,
    bedrooms,
    maxPrice,
    minPrice,
    parkingSpaces,
    surfaceArea,
    constructionArea,
    halfBathrooms
  } = searches

  const urlSearches = stringify(
    removeAllEmptyKeys({
      operationType,
      propertyType,
      bathrooms,
      bedrooms,
      maxPrice,
      minPrice,
      parkingSpaces,
      surfaceArea,
      constructionArea,
      halfBathrooms,
      selectedLocations: allLocations
    }),
    { arrayFormat: 'repeat' }
  )

  return urlSearches
}

/**
 * makeChunks - split an array in chunks of an specific size
 * @param {Array} array
 * @param {Number} size
 * @returns {Array[]}
 */
export const makeChunks = (array, size) => {
  const chunkedArr = []
  let index = 0
  while (index < array.length) {
    chunkedArr.push(array.slice(index, size + index))
    index += size
  }
  return chunkedArr
}

/**
 * splitUrlByLocation
 * @param {String} location - like "interlomas--huixquilucan--mexico"
 * @returns {Object} like { state: "mexico", municipality: "huixquilucan", neighborhood: "interlomas" }
 */
const splitUrlByLocation = (location) => {
  let currentLocation = location || ''

  if (Array.isArray(location)) {
    currentLocation = location[0]
  }

  const [state, municipality, neighborhood] = currentLocation
    .split('--')
    .reverse()

  return { state, municipality, neighborhood }
}

export function getElementOffset(el) {
  const rect = el.getBoundingClientRect()
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop

  return {
    left: rect.left + scrollLeft,
    top: rect.top + scrollTop
  }
}

export default {
  formattedTime,
  formatTime,
  getThumbnail,
  getCroppedImage,
  is,
  shallowEqual,
  isEmpty,
  removeEmptyKeys,
  removeAllEmptyKeys,
  getBase64FromImage,
  findOr,
  getGuid,
  getHashFilename,
  dataURItoBlob,
  base64ToString,
  decodeUTF8,
  base64toBlob,
  urlify,
  windowDataLog,
  groupBy,
  htmlToText,
  slugify,
  debounce,
  range,
  metaDescriptionProperty,
  getFirstImageProperty,
  getFirstImageOriginalProperty,
  queryParamsRequirementsToBin,
  makeChunks,
  splitUrlByLocation
}
