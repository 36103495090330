import getConfig from 'next/config'
import { getFeatureFlagConfig } from 'featureFlagConfig'

const config = (field, onlyPublic = false) => {
  const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()
  if (onlyPublic) {
    return publicRuntimeConfig[field]
  }

  return serverRuntimeConfig[field] || publicRuntimeConfig[field]
}

export const checkFeatureFlag = (name) => {
  const envValues  = config('FEATURE_FLAGS')

  if (!envValues) {
    console.warn('No feature flags found, probably building?')
    return false
  }

  const ff = getFeatureFlagConfig(config('FEATURE_FLAGS'))
  try {
    return ff.featureFlags[name].isEnabled
  } catch (e) {
    console.error(`Error getting feature flag ${name}`)
    console.error(e)
    throw e
  }
}

export default config
