import css from 'styled-jsx/css'
import { colors, fonts, fontSizes, fontWeights } from 'theme'

export default css.global`
.react-phone-number-input__row {
	/* This is done to stretch the contents of this component */
	
	/* Wrapper del componente */
	display: flex;
	align-items: center;
	display: flex;
	flex-direction: row;
	height: 2.1875rem;
	border: 1px solid ${colors.borderInput};
	background-color: ${colors.white};
	border-radius: 4px;
	position: relative;
	transition: 120ms border-color;
}

/* .react-phone-number-input__input.react-phone-number-input__phone */

.react-phone-number-input__phone {
	/* The phone number input stretches to fill all empty space */
	flex : 1;

	/* The phone number input should shrink
	   to make room for the extension input */
	min-width : 0;
}

.react-phone-number-input__icon {
	/* The flag icon size is 4x3 hence the exact \`width\` and \`height\` values */
	width: 2.5rem;
	height: 100%;
	display: flex;

}

.react-phone-number-input__icon--international {
	/* The international icon size is square */
	/* and also has no border hence the \`2 * 1px\` \`width\` and \`height\` compensation */
	width  : calc(0.93em + 2px);
	height : calc(0.93em + 2px);

	/* The international icon size is square hence the exact \`padding\` value */
	/* for precise alignment with \`4x3\` sized country flags. */
	padding-left  : 0.155em;
	padding-right : 0.155em;

	border : none;
}

.react-phone-number-input__error {
	margin-left : calc(1.24em + 2px + 0.3em + 0.35em + 0.5em);
	margin-top  : calc(0.3rem);
	color       : ${colors.danger};
}

.react-phone-number-input__icon-image {
	max-width  : 100%;
	max-height : 100%;
}

/* Removes \`<input type="number"/>\` up/down arrows in Webkit browsers. */
.react-phone-number-input__ext-input::-webkit-inner-spin-button,
.react-phone-number-input__ext-input::-webkit-outer-spin-button {
	margin             : 0 !important;
	-webkit-appearance : none !important;
	-moz-appearance    : textfield !important;
}

.react-phone-number-input__ext-input {
	width : 3em;
}

.react-phone-number-input__ext {
	white-space: nowrap;
}

.react-phone-number-input__ext,
.react-phone-number-input__ext-input {
	margin-left : 0.5em;
}

/* Styling native country \`<select/>\`. */

.react-phone-number-input__country.react-phone-number-input__country--native{
	/* Wrapper banderas */

}

.react-phone-number-input__country--native
{
	position     : relative;
	align-self   : stretch;
	display      : flex;
	align-items  : center;
	margin-right : 0.5em;
	border-right: 1px solid ${colors.borderInput};
  padding: .5rem;
}

.react-phone-number-input__country-select {
	position : absolute;
	top      : 0;
	left     : 0;
	height   : 100%;
	width    : 100%;
	z-index  : 1;
	border   : 0;
	opacity  : 0;
	cursor   : pointer;
}

.react-phone-number-input__country-select-arrow {
	display            : block;
	content            : '';
	width              : 0;
	height             : 0;
	margin-bottom      : 0.1em;
	margin-top         : 0.3em;
	margin-left        : 0.3em;
	border-width       : 0.35em 0.2em 0 0.2em;
	border-style       : solid;
	border-left-color  : transparent;
	border-right-color : transparent;
	color              : ${colors.gray};
	opacity            : 0.7;
	transition         : color 0.1s;
}

/* Something from stackoverflow. */
.react-phone-number-input__country-select-divider {
	font-size  : 1px;
	background : black;
}

.react-phone-number-input__country-select:focus + .react-phone-number-input__country-select-arrow {
	color : ${colors.info};
}

/* Styling phone number input */

.react-phone-number-input__input {
	height: calc(0.3rem * 6);
	outline: none;
	border-radius: 0;
	padding: 0;
	appearance: none;
	border: none;
	border-bottom: 1px solid ${colors.boxShadow};
	transition: border 0.1s;
	font-size: inherit;
	height: 100%;
  border: 0;
}

.react-phone-number-input__input:focus {
	border-color : ${colors.info};
}

.react-phone-number-input__input::placeholder {
	color : ${colors.darkGray};
	font: 500 1rem 'Source Sans Pro', sans-serif;
}

.react-phone-number-input__input--disabled {
	cursor : default;
}

.react-phone-number-input__input--invalid,
.react-phone-number-input__input--invalid:focus {
	border-color : ${colors.danger};
}

/* Overrides Chrome autofill yellow background color */
.react-phone-number-input__input:-webkit-autofill {
	box-shadow : 0 0 0 1000px white inset;
}

/* A small fix for \`react-responsive-ui\` */
.react-phone-number-input__country .rrui__select__button {
	border-bottom : none;
}


/* Custom styles */

.wrapper-phone-input {
	position: relative;
	font: ${fonts.medium};
	display: flex;
	flex-flow: column;
	position: relative;
}

.fl-input-label {
	font-size: 0.875rem;
	padding: 0.5rem 0;
	font-weight: ${fontWeights.semiBold};
}

.wrapper-phone-input :global(.react-phone-number-input__input.react-phone-number-input__phone) {
	font: ${fonts.medium};
	border-radius: 0.5rem;
	background: transparent;
}

.wrapper-phone-input .message-error-phone {
    text-align: right;
    font-size: 12px;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 85%;
    word-break: break-all;
    word-wrap: break-word;
    pointer-events: none;

		color: ${colors.danger};
    bottom: 3px;
    right: 0.5rem;
}

.wrapper-phone-input:hover .react-phone-number-input__row{
	border-color: ${colors.highlightBlue};
  border-right-width: 1px !important;
}
.wrapper-phone-input.is-invalid .react-phone-number-input__row {
	border : 1px solid ${colors.danger} ;
}

.wrapper-phone-input .react-phone-number-input__icon svg{
	width: inherit;
	height: inherit;
}

`
