import css from 'styled-jsx/css'
import colors from 'theme/colors'

export default css`
  .button.warning {
    background-color: ${colors.merigoldOrange};
    border-color: ${colors.merigoldOrange};

    transition: 100ms background-color, 100ms border-color;
  }

  .warning {
    color: ${colors.white};

    transition: 100ms color;
  }

  .button:not(.disabled):hover.warning, .button:not(.disabled):focus.warning {
    background-color: ${colors.apricotOrange};
    border-color: ${colors.apricotOrangeBorder};
    color: ${colors.white};

    transition: 100ms background-color, 100ms border-color, 100ms color;
  }
`