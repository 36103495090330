import { createSelector } from 'reselect'
import { isContentCountry } from 'globalConfigApp'
const moment = require('moment')

import {
  PROPERTY_TYPES,
  OPERATION_TYPES,
  LAND_TYPES,
  CURRENCY_TYPES,
  AGE_PROPERTY,
  PROPERTY_SUB_CATEGORY,
  SOIL_TYPE,
  SCALE_ENERGY,
  CONDITION_TYPE,
  DEVELOPMENT_TYPE_OPTIONS,
} from 'services/seo'

import { getTokenFields } from './index'
import Property from 'app/classes/Property'

export const propertyDataToForm = (data, config) => {
  const { propertyDescriptionWithIA } = config || {}
  if (!data) {
    const formData = {
      details: {
        title: '',
        sites: [],
        price: '',
        priceCurrency: CURRENCY_TYPES.find(ct => ct.value === 'MXN'),
        subCategory: '',
        soil: '',
        condition: '',
        landRegisterNumber: '',
        cadastralReference: '',
        idufir: '',
        energyConsumptionScale: '',
        energyConsumptionValue: '',
        energyEmissionsScale: '',
        energyEmissionsValue: '',
        developmentType: { value: 'NONE' },
        presaleDate: null,
      },
      address: {
        address: '',
        street: '',
        streetNumber: '',
        aptNumber: '',
        country: '',
        neighborhood: null,
        city: '',
        district: null,
        state: null,
        postalCode: '',
        latLng: null,
      },
      media: {
        videoUrl: '',
        virtualTourUrl: '',
        imagesJson: [],
        booking: {
          requested: false,
          address: '',
          contactName: '',
          contactPhone: '',
          bookingDate: null,
          bookingTime: null,
          comments: '',
        },
      },
      amenities: [],
      landlord: {
        email: '',
        firstName: '',
        lastName: '',
        secondLastName: '',
        secondTelephone: '',
        telephone: '',
      },
      sale: {
        operationType: '',
        saleCommissionPercentage: null,
        saleCommissionDays: null,
        saleType: '',
        saleGrossCommission: 0,
        saleTotalCommission: 0,
        hasSaleCommissionTax: false,
        referalLeadSource: 'noneReferalLead',
        referalLeadId: null,
      },
    }

    if (propertyDescriptionWithIA) {
      formData.iaDescription = {
        description: null,
      }
      formData.propertyDescriptionWithIA = propertyDescriptionWithIA
    } else {
      formData.details.description = ''
    }

    return formData
  }

  const price = data.price ? data.price : {}
  const sop = OPERATION_TYPES.find(ot => ot.value === data.operationType)
  const age = AGE_PROPERTY.find(ot => ot.value === data.age)

  const propertyType =
    PROPERTY_TYPES.find(ot => ot.value === data.propertyType) || {}

  const saleGrossCommission = data.saleGrossCommission
    ? data.saleGrossCommission
    : {}
  const saleTotalCommission = data.saleTotalCommission
    ? data.saleTotalCommission
    : {}
  const landlordPropertyData = data.landlordPropertyData
    ? data.landlordPropertyData
    : {}
  const scaleOfEnergy =
    SCALE_ENERGY.find(ot => ot.value === data.scaleOfEnergy) || {}
  const energyEmissionsScale =
    SCALE_ENERGY.find(ot => ot.value === data.energyEmissionsScale) || {}
  const condition = CONDITION_TYPE.find(ot => ot.value === data.condition) || {}

  const subCategory =
    PROPERTY_SUB_CATEGORY.find(ot => ot.value === data.subCategory) || {}
  const soil = SOIL_TYPE.find(ot => ot.value === data.soil) || {}
  const neighborhood =
    isContentCountry(['es']) && data.neighborhood
      ? data.neighborhood
      : data.neighborhood
      ? { value: data.neighborhood, label: data.neighborhood }
      : null
  const district =
    isContentCountry(['es']) && data.district
      ? data.district
      : data.district
      ? { value: data.district, label: data.district }
      : null
  const state =
    isContentCountry(['es']) && data.state
      ? data.state
      : data.state
      ? { value: data.state, label: data.state }
      : null

  const amenitiesFiltered = amenitiesByType(data.amenities)
  const developmentTypeOption =
    DEVELOPMENT_TYPE_OPTIONS.find(ot => ot.value === data.developmentType) || {}

  const formData = {
    details: {
      id: data.id,
      title: data.title,
      sites: data.sites,
      propertyType: propertyType.value,
      soil,
      condition,
      operationType: data.operationType,
      price: price.amount,
      priceCurrency: CURRENCY_TYPES.find(
        ct => ct.value === (price.currency || 'MXN'),
      ),
      landType: data.landType,
      constructionArea: String(data.constructionArea),
      surfaceArea: String(data.surfaceArea),
      age: age ? age : {},
      bedrooms: String(data.bedrooms),
      bathrooms: String(data.bathrooms),
      halfBathrooms: String(data.halfBathrooms),
      parkingSpaces: String(data.parkingSpaces),
      subCategory,
      landRegisterNumber: data.landRegisterNumber,
      cadastralReference: data.cadastralReference,
      idufir: data.idufir,
      scaleOfEnergy,
      energyConsumptionValue: data.energyConsumptionValue,
      energyEmissionsScale,
      energyEmissionsValue: data.energyEmissionsValue,
      developmentType: developmentTypeOption,
      presaleDate: data.presaleDate,
    },
    address: {
      showFullLocation: data.showFullLocation,
      address: data.address,
      street: data.street,
      streetNumber: data.streetNumber,
      aptNumber: data.aptNumber,
      country: data.country,
      neighborhood,
      city: data.city,
      district,
      state,
      postalCode: data.postalCode,
      latLng: {
        lng: data.lng,
        lat: data.lat,
      },
    },
    media: {
      videoUrl: data.videoUrl,
      virtualTourUrl: data.virtualTourUrl,
      imagesJson: data.imagesJson,
      booking: {
        requested: !!(data.bookingData && data.bookingData.status),
        address: data.address,
        contactName: data.bookingData ? data.bookingData.fullName : null,
        contactPhone: data.bookingData ? data.bookingData.phone : null,
        date: data.bookingData
          ? moment(data.bookingData.scheduledTo)
              .format('YYYY-MM-DD')
              .toString()
          : null,
        time: {
          hour: {
            label: data.bookingData
              ? moment(data.bookingData.scheduledTo)
                  .format('HH')
                  .toString()
              : '09',
            value: data.bookingData
              ? moment(data.bookingData.scheduledTo)
                  .format('HH')
                  .toString()
              : '09',
          },
          minutes: {
            label: data.bookingData
              ? moment(data.bookingData.scheduledTo)
                  .format('mm')
                  .toString()
              : '00',
            value: data.bookingData
              ? moment(data.bookingData.scheduledTo)
                  .format('mm')
                  .toString()
              : '00',
          },
        },
        comments: data.bookingData ? data.bookingData.comments : null,
      },
    },
    amenities: amenitiesFiltered,
    landlord: {
      email: landlordPropertyData.email,
      firstName: landlordPropertyData.firstName,
      lastName: landlordPropertyData.lastName,
      secondLastName: landlordPropertyData.secondLastName,
      secondTelephone: landlordPropertyData.secondTelephone,
      telephone: landlordPropertyData.telephone,
    },

    sale: {
      operationType: sop ? sop.label : '',
      saleType: data.saleType,
      saleCommissionPercentage: data.saleCommissionPercentage
        ? data.saleCommissionPercentage
        : null,
      saleCommissionDays: data.saleCommissionDays
        ? {
            value: `${data.saleCommissionDays}`,
            label: `${data.saleCommissionDays}`,
          }
        : null,
      saleGrossCommission: saleGrossCommission.amount,
      saleTotalCommission: saleTotalCommission.amount,
      hasSaleCommissionTax: data.hasSaleCommissionTax,
      referalLeadSource: data.referalLeadSource
        ? {
            value: `${data.referalLeadSource}`,
          }
        : { value: 'noneReferalLead' },
      referalLeadId: data.referalLeadId,
      referalLeadCustomPercentage: Number(data.referalLeadCustomPercentage) || 0,
    },
  }

  if (propertyDescriptionWithIA) {
    formData.iaDescription = {
      description: data.description || '',
    }
    formData.propertyDescriptionWithIA = propertyDescriptionWithIA
  } else {
    formData.details.description = data.description || ''
  }

  return formData
}

/**
 * @function amenitiesByType
 * @param {array} amenitiesData - array of amenities from a certain property
 * @returns {object} - object of amenities divided by amenities and characteristics
 */
const amenitiesByType = amenitiesData => {
  const amenities = {}
  const characteristics = {}

  for (const amenity of amenitiesData) {
    if (amenity.type === 'AMENITY') {
      amenities[amenity.id] = true
    } else {
      characteristics[amenity.id] = true
    }
  }

  return {
    characteristics,
    amenities,
  }
}

export const formToProperty = (
  createProperty,
  tokenFields,
  allAmenities = [],
) => {
  if (!tokenFields || !createProperty) {
    return null
  }
  const { values = {} } = createProperty
  //const { booking = {} } = values

  const {
    details = {},
    address = {},
    media = {},
    amenities = [],
    landlord = {},
    iaDescription = {},
    sale = {},
    propertyDescriptionWithIA,
  } = values

  const amenitiesToSend = setAmenitiesToSend(allAmenities, amenities)

  const price =
    details.price && details.priceCurrency && details.priceCurrency.value
      ? {
          amount: details.price,
          currency: details.priceCurrency.value,
        }
      : null
  const saleGrossCommission =
    price && sale.saleGrossCommission
      ? {
          amount: sale.saleGrossCommission,
          currency: price.currency,
        }
      : null
  const saleTotalCommission =
    price && sale.saleTotalCommission
      ? {
          amount: sale.saleTotalCommission,
          currency: price.currency,
        }
      : null
  const latLng = address.latLng || {}

  const booking =
    media.booking &&
    media.booking.requested &&
    media.booking.contactName &&
    media.booking.contactPhone &&
    media.booking.date &&
    media.booking.time
      ? {
          requested: media.booking.requested,
          address: address.address,
          contactName: media.booking.contactName,
          contactPhone: media.booking.contactPhone,
          bookingDate: media.booking.date,
          bookingTime: media.booking.time
            ? media.booking.time.hour.value +
              ':' +
              media.booking.time.minutes.value
            : null,
          comments: media.booking.comments ? media.booking.comments : null,
        }
      : null
  let subCategory = null
  let soil = null
  let condition = null
  const propertyType = details.propertyType

  if (
    details.propertyType &&
    isContentCountry(['es']) &&
    details.propertyType === 'HOUSE'
  ) {
    subCategory = details.subCategory ? details.subCategory.value : null
  }
  if (
    details.propertyType &&
    isContentCountry(['es']) &&
    details.propertyType === 'LOTS'
  ) {
    soil = details.soil ? details.soil.value : null
  }
  if (isContentCountry(['es']) && details.condition) {
    condition = details.condition ? details.condition.value : null
  }
  const age = details.age
    ? AGE_PROPERTY.find(ot => ot.value === details.age.value)
    : {}
  const district = isContentCountry(['es'])
    ? address.district
    : address.district
    ? address.district.value
    : null
  const neighborhood = isContentCountry(['es'])
    ? address.neighborhood
    : address.neighborhood
    ? address.neighborhood.value
    : null
  const state = isContentCountry(['es'])
    ? address.state
    : address.state
    ? address.state.value
    : null

  return {
    amenities: amenitiesToSend,
    sites: details.sites,

    title: details.title,
    age: age ? age.value : null,
    description: propertyDescriptionWithIA
      ? iaDescription.description
      : details.description,
    propertyType: propertyType,
    operationType: details.operationType,
    subCategory,
    soil,
    condition,
    // landRegisterNumber: details.landRegisterNumber,
    // cadastralReference: details.cadastralReference,
    landType: details.landType,
    price,
    bathrooms: details.bathrooms,
    halfBathrooms: details.halfBathrooms,
    bedrooms: details.bedrooms,
    parkingSpaces: details.parkingSpaces,
    surfaceArea: details.surfaceArea,
    constructionArea: details.constructionArea,
    developmentType: details.developmentType?.value,
    presaleDate: details.presaleDate,

    // scaleOfEnergy: details.scaleOfEnergy ? details.scaleOfEnergy.value: null,
    // energyConsumptionValue: details.energyConsumptionValue ? details.energyConsumptionValue : 0,

    // energyEmissionsScale: details.energyEmissionsScale ? details.energyEmissionsScale.value : null ,
    // energyEmissionsValue: details.energyEmissionsValue ? details.energyEmissionsValue :0,
    // idufir: details.idufir ? details.idufir : 0,
    broker: tokenFields.user_id,
    //
    // landlordPropertyData: {
    //   email: landlord.email,
    //   firstName: landlord.firstName,
    //   lastName: landlord.lastName,
    //   secondLastName: landlord.secondLastName,
    //   secondTelephone: landlord.secondTelephone,
    //   telephone: landlord.telephone
    // },

    virtualTourUrl: media.virtualTourUrl,
    videoUrl: media.videoUrl,
    imagesJson: media.imagesJson,

    address: address.address,
    street: address.street,
    streetNumber: address.streetNumber,
    aptNumber: address.aptNumber,
    country: address.country,
    showFullLocation: address.showFullLocation,
    city: address.city,
    neighborhood,
    district,
    state,
    postalCode: address.postalCode,
    lng: latLng.lng,
    lat: latLng.lat,

    saleType: sale.saleType,
    saleGrossCommission,
    saleTotalCommission,
    hasSaleCommissionTax: sale.hasSaleCommissionTax,
    saleCommissionPercentage: sale.saleCommissionPercentage
      ? sale.saleCommissionPercentage
      : null,
    saleCommissionDays: sale.saleCommissionDays
      ? sale.saleCommissionDays.value
      : null,
    booking,
    referalLeadSource:
      sale.referalLeadSource?.value !== 'noneReferalLead'
        ? sale.referalLeadSource?.value
        : null,
    referalLeadId: sale.referalLeadId || null,
    referalLeadCustomPercentage: sale.referalLeadCustomPercentage || null,
  }
}

/**
 * @function setAmenitiesToSend
 * @param {array} allAmenities - array with all amenities available
 * @param {object} amenitiesSelected - object of amenities and characteristics choosen from the form
 *                     { amenities: { amenityId: Boolean }, characteristics: { amenityId: Boolean } }
 * @returns {array} - array of amenities to send in the form
 */
export const setAmenitiesToSend = (allAmenities, amenitiesSelected) => {
  const selectedAmenities = {
    ...amenitiesSelected.characteristics,
    ...amenitiesSelected.amenities,
  }
  return allAmenities.filter(amen => selectedAmenities[amen.id])
}

export const getAmenities = state =>
  state.store_amenity.filter?.response?.results

export const getPropertyData = formName =>
  createSelector(
    state => state.form[formName],
    state => getTokenFields(state),
    state => getAmenities(state),
    formToProperty,
  )

export const getEditPropertyData = createSelector(
  state => state.store_property.find,
  state => getTokenFields(state),
  (editProperty, tokenFields) => {
    if (!tokenFields || !editProperty || editProperty.loading) {
      return null
    }
    const response = editProperty.response ? editProperty.response : {}
    return propertyDataToForm(response)
  },
)


export const isUploadingAnImage = createSelector(
  state => state.fileUpload,
  fileUploads => {
    return Object.keys(fileUploads).reduce((mem, fileKey) => {
      return mem || fileUploads[fileKey].loading
    }, false)
  },
)

// state.store_property.contacts.response.results

export const isPropertyContact = createSelector(
  state => state.store_property.contacts,
  contacts => {
    let valid = false
    contacts.response && contacts.response.results.length > 0
      ? (valid = true)
      : ''
    return valid
  },
)

export const getPropertyClose = createSelector(
  state => state.store_property.find,
  property => {
    const { response } = property || {}

    if (!response || !response.id) {
      return {}
    }

    return {
      propertyId: response.id,
      price: response.price,
      commissionPercentage: response.saleCommissionPercentage,
      requestedDocument: '',
    }
  },
)

export const isPropertyInClaimedProcess = property => {
  const p = new Property(property)

  return p.isPropertyInClaimedProcess()
}

export const getRawEditProperty = createSelector(
  state => state.store_property.find,
  state => getTokenFields(state),
  (editProperty, tokenFields) => {
    if (!tokenFields || !editProperty || editProperty.loading) {
      return {}
    }

    return editProperty.response
  },
)

export const isLastRevisionOfEditPropertyDraft = createSelector(
  getRawEditProperty,
  editProperty => {
    return editProperty && editProperty.lastRevisionStatus === 'DRAFT'
  },
)
