// Stolen from https://github.com/rickharrison/validate.js/blob/master/validate.js

const ruleRegex = /^(.+?)\[(.+)\]$/,
  numericRegex = /^[0-9]+$/,
  integerRegex = /^\-?[0-9]+$/,
  decimalRegex = /^\-?[0-9]*\.?[0-9]+$/,
  lazyEmailRegex = /^(([^ñÑ<>()\[\]\.,;:\s@\"]+(\.[^ñÑ<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
  alphaRegex = /^[a-z]+$/i,
  alphaNumericRegex = /^[a-z0-9]+$/i,
  alphaDashRegex = /^[a-z0-9_\-]+$/i,
  naturalRegex = /^[0-9]+$/i,
  naturalNoZeroRegex = /^[1-9][0-9]*$/i,
  ipRegex = /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})$/i,
  base64Regex = /[^a-zA-Z0-9\/\+=]/i,
  numericDashRegex = /^[\d\-\s]+$/,
  urlRegex = /^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/,
  dateRegex = /\d{4}-\d{1,2}-\d{1,2}/,
  urlVimeo = /^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/,
  emailRegexWeetrust = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[a-zA-Z]{2,})$/,
  richText = /[<pbr\/>\s]/g,
  subdomainRegex = /^[a-z0-9]+$/,
  latinCharactersEncoding = /^[\x00-\xff]+$/g,
  minimalSecurityPasswordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
  /**
   * 
   * minium 8 characters, at least one lowercase letter, one uppercase letter, one number, one special character
   */
  mailboxPasswordRegex = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W|_]).*).{8,}/,
  curpRegex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
  urlYoutube = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/
/**
 *  General Email Regex (RFC 5322 Official Standard)
 */
let emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g

const getValidDate = function(date) {
  if (!date.match('today') && !date.match(dateRegex)) {
    return false
  }

  const validDate = new Date()
  let validDateArray

  if (!date.match('today')) {
    validDateArray = date.split('-')
    validDate.setFullYear(validDateArray[0])
    validDate.setMonth(validDateArray[1] - 1)
    validDate.setDate(validDateArray[2])
  }
  return validDate
}

export const getNumber = value => {
  return value.replace(/\D+/g, '')
}
export const required = value => {
  return typeof value !== 'undefined' && value !== null && value !== ''
}

export const matches = (value, match) => {
  return value === match
}

export const validEmail = value => {
  const [email] = String(value).match(emailRegex) || []
  return email === value
}

export const validEmailRegexWeetrust = value => {
  const [email] = String(value).match(emailRegexWeetrust) || []
  return email === value
}

export const validLazyEmail = value => lazyEmailRegex.test(value)

export const validSubdomain = value => subdomainRegex.test(value)

export const validUrlVimeo = value => {
  return urlVimeo.test(value)
}
export const validUrlYoutube = value => {
  return urlYoutube.test(value)
}

export const validEmails = value => {
  const result = value.split(/\s*,\s*/g)

  let i = 0
  const resultLength = result.length
  for (; i < resultLength; i++) {
    if (!emailRegex.test(result[i])) {
      return false
    }
  }

  return true
}

export const minLength = (value, length) => {
  if (!numericRegex.test(length)) {
    return false
  }

  return value.length >= parseInt(length, 10)
}

export const maxLength = (value, length) => {
  if (!numericRegex.test(length)) {
    return false
  }

  return value.length <= parseInt(length, 10)
}

export const exactLength = (value, length) => {
  if (!numericRegex.test(length)) {
    return false
  }

  return value.length === parseInt(length, 10)
}

export const greaterThan = (value, param) => {
  if (!decimalRegex.test(value)) {
    return false
  }

  return parseFloat(value) > parseFloat(param)
}

export const lessThan = (value, param) => {
  if (!decimalRegex.test(value)) {
    return false
  }

  return parseFloat(value) < parseFloat(param)
}

export const alpha = value => {
  return alphaRegex.test(value)
}

export const alphaNumeric = value => {
  return alphaNumericRegex.test(value)
}

export const alphaDash = value => {
  return alphaDashRegex.test(value)
}

export const numeric = value => {
  return numericRegex.test(value)
}

export const integer = value => {
  return integerRegex.test(value)
}

export const decimal = value => {
  return decimalRegex.test(value)
}

export const isNatural = value => {
  return naturalRegex.test(value)
}

export const isNaturalNoZero = value => {
  return naturalNoZeroRegex.test(value)
}

export const validIp = value => {
  return ipRegex.test(value)
}

export const validBase64 = value => {
  return base64Regex.test(value)
}

export const validUrl = value => {
  return urlRegex.test(value)
}

export const validCreditCard = value => {
  // Luhn Check Code from https://gist.github.com/4075533
  // accept only digits, dashes or spaces
  if (!numericDashRegex.test(value)) return false

  // The Luhn Algorithm. It's so pretty.
  let nCheck = 0,
    nDigit = 0,
    bEven = false
  const strippedField = value.replace(/\D/g, '')

  for (let n = strippedField.length - 1; n >= 0; n--) {
    const cDigit = strippedField.charAt(n)
    nDigit = parseInt(cDigit, 10)
    if (bEven) {
      if ((nDigit *= 2) > 9) nDigit -= 9
    }

    nCheck += nDigit
    bEven = !bEven
  }

  return nCheck % 10 === 0
}

export const isFileType = (value, type) => {
  if (value.type !== 'file') {
    return true
  }

  const ext = value.substr(value.lastIndexOf('.') + 1),
    typeArray = type.split(',')
  let inArray = false,
    i = 0
  const len = typeArray.length

  for (i; i < len; i++) {
    if (ext.toUpperCase() == typeArray[i].toUpperCase()) inArray = true
  }

  return inArray
}

export const greaterThanDate = (value, date) => {
  let enteredDate = getValidDate(value),
    validDate = getValidDate(date)

  if (!validDate || !enteredDate) {
    return false
  }

  return enteredDate > validDate
}

export const lessThanDate = (value, date) => {
  let enteredDate = getValidDate(value),
    validDate = getValidDate(date)

  if (!validDate || !enteredDate) {
    return false
  }

  return enteredDate < validDate
}

export const greaterThanOrEqualDate = (value, date) => {
  let enteredDate = getValidDate(value),
    validDate = getValidDate(date)

  if (!validDate || !enteredDate) {
    return false
  }

  return enteredDate >= validDate
}

export const lessThanOrEqualDate = (value, date) => {
  let enteredDate = getValidDate(value),
    validDate = getValidDate(date)

  if (!validDate || !enteredDate) {
    return false
  }

  return enteredDate <= validDate
}

export const richTextToPlainText = value =>
  typeof value === 'string' && value.replace(richText, '')

export const requiredRichText = value =>
  typeof value === 'string' && richTextToPlainText(value).length > 0

export const minLengthRichText = (value, min = 0) =>
  typeof value === 'string' && richTextToPlainText(value).length > min

export const validCurp = (value) => curpRegex.test(value)

export const validLatinCharacters = (value) => {
  // Check only ISO-8859-1 (ISO Latin 1) Character Encoding
  // https://www.ic.unicamp.br/~stolfi/EXPORT/www/ISO-8859-1-Encoding.html

  latinCharactersEncoding.lastIndex = 0;
  return latinCharactersEncoding.test(value)
}

export const validMinimalSecurityPassword = value =>
  minimalSecurityPasswordRegex.test(value)

export const validMailboxPassword = value => mailboxPasswordRegex.test(value)

export default {
  getNumber,
  required,
  matches,
  validEmail,
  validEmails,
  validSubdomain,
  minLength,
  maxLength,
  exactLength,
  greaterThan,
  lessThan,
  alpha,
  alphaNumeric,
  alphaDash,
  numeric,
  integer,
  decimal,
  isNatural,
  isNaturalNoZero,
  validIp,
  validBase64,
  validUrl,
  validCreditCard,
  isFileType,
  greaterThanDate,
  lessThanDate,
  greaterThanOrEqualDate,
  lessThanOrEqualDate
}
