/**
 * This module works with hashed store to support multiple request
 * For example, if some view has the same info but from different dates
 * in different tables.
 *
 * Requirements:
 *    All request need fromDate and toDate, this values are strings.
 *
 *
 * Component example:
 *    useEffect(() => {
 *       dispatch(actionCreator(fromDate, toDate, params))
 *    }, [fromDate, toDate])
 *
 *    const first_table_results = useSelector(
 *       state => commissionSelector(state, fromDate, toDate)
 *    )
 */

import { createReducer, createActions } from 'reduxsauce'
import {
  requestFeedback,
  successFeedback,
  failureFeedback,
  addHashedState,
  updateHashedState,
} from 'reducers/utils'

import { createHashFromDates, BASE_MLM_COMMISSIONS } from 'selectors/MLMCommissions'

const INITIAL_STATE = {
  commissions: {},
}

const { Types, Creators } = createActions(
  {
    getCommissionsRequest: ['from', 'to', 'params'],
    getCommissionsSuccess: ['from', 'to', 'count', 'commissions', 'total'],
    getCommissionsFailure: ['from', 'to', 'errors'],
  },
  { prefix: 'MLM_COMMISSIONS_' }
)

const getCommissionsRequest = (state, { from, to, params }) => ({
  ...state,
  commissions: addHashedState(state.commissions, createHashFromDates(from, to), {
    ...BASE_MLM_COMMISSIONS,
    params,
    feedback: requestFeedback()
  })
})

const getCommissionsSuccess = (state, { from, to, count, commissions, total }) => ({
  ...state,
  commissions: updateHashedState(state.commissions, createHashFromDates(from, to), {
    count,
    total,
    commissions,
    feedback: successFeedback()
  })
})

const getCommissionsFailure = (state, { from, to, errors }) => ({
  ...state,
  commissions: updateHashedState(state.commissions, createHashFromDates(from, to), {
    feedback: failureFeedback(errors)
  })
})

const HANDLERS = {
  [Types.GET_COMMISSIONS_REQUEST]: getCommissionsRequest,
  [Types.GET_COMMISSIONS_SUCCESS]: getCommissionsSuccess,
  [Types.GET_COMMISSIONS_FAILURE]: getCommissionsFailure
}
export const MLMCommissionsActionTypes = Types
export const MLMCommissionsActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
