import { put, call } from 'redux-saga/effects'
import { HereActionCreators} from '../reducers/LocationHereRedux'
import { fetchLocations , fetchLocationRetrive} from 'services/apiHere'
import {change} from 'redux-form'

export function* getLocationData({ search }) {
  if (search){
    const response = yield call(fetchLocations, search)
    if (response.status !== 200) {
      yield put(HereActionCreators.getLocationFailure(response.data))
      return
    }
    yield put(HereActionCreators.getLocationSuccess(response.data.suggestions))
  }
}



export function* setLocation({location}){

  const response = yield call(fetchLocationRetrive, location.locationId)
  if(response.status==200){

    const {data} = response 
    const { Latitude, Longitude } = data.Response.View[0].Result[0].Location.DisplayPosition
    var { Label, State, Country, City, Street, HouseNumber, PostalCode, District } = data.Response.View[0].Result[0].Location.Address
    const address = {
      address: Label,
      state: State,
      country: Country ,
      neighborhood: City,
      district: District,
      street : Street,
      streetNumber: HouseNumber,
      postalCode : PostalCode,
    }
    yield put(change('editProperty', 'address', address))  
    yield put(change('editProperty', 'address.latLng', { lat: Latitude, lng: Longitude}))
    
  }
  // yield put(change('editProperty', 'address.latLng', { lat: response.Response.View[0].Location.DisplayPosition.Latitude, lng: response.Response.View[0].Location.DisplayPosition.Longitude }))
}