export default {
  name: 'nexsitesLeads',
  namespace: 'leads',
  methods: [
    {
      name: 'neximer',
      params: ['data', 'customHeaders'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace }) => `/nexsites/${namespace}/neximer/`,
      urlParams: []
    },
    {
      name: 'offer',
      params: ['data'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace }) => `/nexsites/${namespace}/offer/`,
      urlParams: []
    },
    {
      name: 'property',
      params: ['id', 'data', 'customHeaders'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace, id }) => `/nexsites/${namespace}/${id}/property/`,
      urlParams: ['id']
    }
  ]
}
