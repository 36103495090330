export default {
  name: 'mlmAgent',
  namespace: 'agent',
  methods: [
    {
      name: 'treeAgent',
      params: ['agent_id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, agent_id }) =>
        `/v1/mlm/${namespace}/tree/${agent_id}/`,
      urlParams: ['agent_id']
    },
    {
      name: 'generalInfo',
      params: ['id', 'stats'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/mlm/${namespace}/general_info/${id}/`,
      urlParams: ['id']
    },
    {
      name: 'statsAgent',
      params: ['agent_id', 'year', 'month', 'backward_months'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, agent_id, year, month, backward_months }) =>
        `/v1/mlm/${namespace}/${agent_id}/stats/${year}/${month}/${backward_months}/`,
      urlParams: ['agent_id', 'year', 'month', 'backward_months']
    }
  ]
}
