export default {
  name: 'nexsitesBroker',
  namespace: 'broker',
  methods: [
    {
      name: 'infoNeximer',
      params: ['customHeaders'],
      successCode: 200,
      method: 'Get',
      url: () => `/nexsites/broker/`,
      urlParams: []
    },

    {
      name: 'availableSubdomain',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: () => `/nexsites/subdomains/available_domain/`,
      urlParams: []
    },

    {
      name: 'suggestedSubdomains',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: () => `/nexsites/subdomains/suggestions/`,
      urlParams: []
    },

    {
      name: 'getSocialNetworks',
      params: [],
      successCode: 200,
      method: 'Get',
      url: () => `/nexsites/user/get_social_networks/`,
      urlParams: []
    },

    {
      name: 'saveSocialNetworks',
      params: ['data'],
      successCode: 201,
      method: 'Post',
      url: () => `/nexsites/user/save_social_networks/`,
      urlParams: []
    }
  ]
}
