export default {
  name: 'paymentPlan',
  namespace: 'plans',
  methods: [
    {
      name: 'filter',
      params: [],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/payments/${namespace}/`,
      urlParams: [],
      seq: false
    }

  ]
}
