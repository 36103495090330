export default {
  name: 'dokaltinDocument',
  namespace: 'dokaltin/documents',
  methods: [
    {
      name: 'download',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/${namespace}/${id}/download/`,
      urlParams: ['id']
    }
  ]
}
