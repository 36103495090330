import { put } from 'redux-saga/effects'

import { UserPrivateDetail } from 'services/api/entities'
import { UserPrivateDetailActionCreators } from 'app/reducers/UserPrivateDetailRedux'

export function* getUserPrivateDetail(params) {
  const response = yield UserPrivateDetail.getUserPrivateDetail(params.data)
  if (response.success()) {
    yield put(UserPrivateDetailActionCreators.getUserPrivateDetailSuccess(response.data))
  } else {
    yield put(UserPrivateDetailActionCreators.getUserPrivateDetailFailure(response.data))
  }
}

export function* getBinProfileAreas( params ) {
  const response = yield UserPrivateDetail.getUserPublicAreas({broker: params.data} )
  if (response.success()) {
    yield put(UserPrivateDetailActionCreators.areasSuccess(response.data))
  } else {
    yield put(UserPrivateDetailActionCreators.areasFailure(response.data))
  }
}
