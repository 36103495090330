import ApiBase from 'app/services/api/entities/Base'

function* acceptRecruitmentLead(recruitmentLeadId) {
  return yield ApiBase.privatePost(
    `/v1/crm/applicants/${recruitmentLeadId}/accept/`,
  )
}

function* findRecruitmentLeadToReject(recruitmentLeadId) {
  return yield ApiBase.privateGet(
    `/v1/crm/applicants/${recruitmentLeadId}/reject/`
  )
}

function* rejectRecruitmentLead(recruitmentLeadId, data) {
  return yield ApiBase.privatePost(
    `/v1/crm/applicants/${recruitmentLeadId}/reject/`,
    {
      data: data,
    },
  )
}

function* contactedRecruitmentLead(recruitmentLeadId) {
  return yield ApiBase.privatePost(
    `/v1/crm/applicants/${recruitmentLeadId}/contacted/`,
  )
}

export default {
  acceptRecruitmentLead,
  findRecruitmentLeadToReject,
  rejectRecruitmentLead,
  contactedRecruitmentLead,
}
