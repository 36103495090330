import { call, put, select } from 'redux-saga/effects'
import { ContactActionCreators } from 'app/reducers/ContactReducer'
import { arrayRemove } from 'redux-form'
import api from 'app/services/api/api'
import Router from 'next/router'

export function* sentDataContactRequest({ data }) {
  const response = yield call(api.post, '/v1/public/contact/', {}, data)

  if (response.status === 200) {
    yield put(ContactActionCreators.sentDataContactSuccess())
    return
  }

  yield put(ContactActionCreators.sentDataContactFailure())
}

export function* getContactRequest({ id }) {
  const token = yield select(state => state.auth.token)
  const response = yield call(
    api.privateGet,
    `/v1/crm/contact_profiles/${id}/`,
    { token }
  )
  if (response.status === 200) {
    return yield put(ContactActionCreators.getContactSuccess(response))
  }
  yield put(ContactActionCreators.getContactFailure())
}

export function* handleSubmitContactForm({
  data,
  profileType,
  id,
  handleError
}) {
  const token = yield select(state => state.auth.token)

  const response = id
    ? yield call(api.privatePut, `/v1/crm/contact_profiles/${id}/`, {
        data,
        token
      })
    : yield call(api.privatePost, `/v1/crm/contact_profiles/`, { data, token })

  if (response.status === 200) {
    if (id) {
      return Router.push(
        {
          pathname: `/dashboard/crm/contacts/idContact/`,
          query: {
            idContact: id
          }
        },
        {
          pathname: `/dashboard/crm/contacts/${id}/`
        })
    }
    return yield put(ContactActionCreators.handleSubmitContactFormSuccess())
  } else if (response.status === 201) {
    if (
      profileType.value === 'INTERESTED_IN_BUYING' ||
      profileType.value === 'INTERESTED_IN_RENTING'
    ) {
      return Router.push({
        pathname: '/dashboard/bin/',
        query: { profile: profileType.value }
      })
    }
    Router.push({
      pathname: `/dashboard/crm/contacts/add/${profileType.value}/`
    })
    return yield put(ContactActionCreators.handleSubmitContactFormSuccess())
  }
  handleError()
  return yield put(ContactActionCreators.handleSubmitContactFormFailure())
}

export function* deleteContact({ id }) {
  const token = yield select(state => state.auth.token)
  const response = yield call(
    api.privateDelete,
    `/v1/crm/contact_profiles/${id}/`,
    {
      token
    }
  )
  if (response.status === 204) {
    return yield put(ContactActionCreators.deleteContactSuccess())
  }
  yield put(ContactActionCreators.deleteContactFailure())
}

export function* getContactReport({ id }) {
  const token = yield select(state => state.auth.token)
  const response = yield call(
    api.privateGet,
    `/v1/crm/reports/landlord/${id}/`,
    {
      token
    }
  )
  if (response.status === 200) {
    return yield put(
      ContactActionCreators.getContactReportSuccess(response.data)
    )
  }
  yield put(ContactActionCreators.getContactReportFailure())
}

export function* getProfilesTypeRequest() {
  const token = yield select(state => state.auth.token)
  const response = yield call(api.privateGet, `/v1/crm/profiles/`, {
    token
  })
  if (response.status === 200) {
    return yield put(
      ContactActionCreators.getProfilesTypeSuccess(response.data)
    )
  }
  yield put(ContactActionCreators.getProfilesTypeFailure())
}

export function* getProperties({ id }) {
  const token = yield select(state => state.auth.token)
  const response = yield call(api.privateGet, `/v1/properties/${id}/`, {
    token
  })
  if (response.status === 200) {
    return yield put(ContactActionCreators.getPropertiesSuccess(response))
  }
  yield put(ContactActionCreators.getPropertiesFailure())
}

export function* deleteContactPropertyRequest({
  id,
  nameForm,
  nameField,
  index
}) {
  const token = yield select(state => state.auth.token)
  const response = yield call(
    api.privateDelete,
    `/v1/crm/contact_profile_properties/${id}/`,
    {
      token
    }
  )
  if (response.status === 204) {
    yield put(arrayRemove(nameForm, nameField, index))
    return yield put(
      ContactActionCreators.deleteContactPropertySuccess(response)
    )
  }
  yield put(ContactActionCreators.deleteContactPropertyFailure())
}

export function* getContactFollowupsRequest({ id }) {
  const token = yield select(state => state.auth.token)
  const response = yield call(
    api.privateGet,
    `/v1/crm/contacts/${id}/followups/`,
    { token }
  )
  if (response.status === 200) {
    return yield put(ContactActionCreators.getContactFollowupsSuccess(response))
  }
  yield put(ContactActionCreators.getContactFollowupsFailure())
}

export function* getFollowUpDetailRequest({ id }) {
  const token = yield select(state => state.auth.token)
  const response = yield call(
    api.privateGet,
    `/v1/crm/contacts/${id}/followups/`,
    { token }
  )
  if (response.status === 200) {
    return yield put(ContactActionCreators.getFollowUpDetailSuccess(response))
  }
  yield put(ContactActionCreators.getFollowUpDetailFailure())
}