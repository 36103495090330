import css from 'styled-jsx/css'
import colors from 'theme/colors'

const color = colors.success

export default css`
  .button.success.outline {
    border-color: ${color};

    transition: 100ms background-color, 100ms border-color;
  }

  .button.success.outline {
    color: ${color};

    transition: 100ms color;
  }

  .button:not(.disabled):hover.success.outline, .button:not(.disabled):focus.success.outline {
    background-color: ${color};
    color: ${colors.white};

    transition: 100ms background-color, 100ms color;
  }
`