import store from 'services/store'
import { initialFeedback, getHashedState } from 'reducers/utils'
import { PROFILE } from 'components/SEO/utils'
import { formatAmount } from 'services/number'
import { translateQuotaError } from 'components/MLM/Tracing/quotaErrors'

export const MLM_QUOTAS_RESULTS_LIMIT = 10

export const BASE_MLM_QUOTAS = {
  count: 0,
  quotas: [],
  total: {
    amount: 0,
    currency: 'MXN',
  },
  params: {
    offset: 0,
    limit: MLM_QUOTAS_RESULTS_LIMIT
  },
  feedback: initialFeedback()
}

/**
 *
 * @param  {...String} args
 * @example
 * > createHashFromDates('01-01-2021', '01-03-2021')
 * > "01-01-2021--01-03-2021"
 * @returns {String}
 */
const createHashFromDates = (...args) => args.join('--')

const addQuotaErrors = quotas =>
  quotas.map(quota => ({
    ...quota,
    originalError: quota.error,
    error: translateQuotaError(quota.error)
  }))

export const MLMQuotasSelector = (state, from, to) => {
  const { quotas, ...rest } = getHashedState(
    state.mlmQuotas.quotas,
    createHashFromDates(from, to),
    BASE_MLM_QUOTAS
  )

  return {
    ...rest,
    quotas: addQuotaErrors(quotas)
  }
}

export const allMLMChildAgents = (children = [], agents = [], offset = 0) => {
  let newAgents = [...agents]
  for (const child of children) {
    if (child.userStatus === 'ACTIVE' || child.userStatus === 'BLOCKED') {
      const fullName = PROFILE.fullName(child)
      newAgents.push({
        offset,
        fullName,
        value: child.userId,
        isActive: child.userStatus === 'ACTIVE',
        label: `(${child.userId}) ${fullName}`
      })

      if (child.children && child.children.length > 0) {
        const childrenAgents = allMLMChildAgents(child.children, [], offset + 1)
        newAgents = [...newAgents, ...childrenAgents]
      }
    }
  }

  return newAgents
}

export const allMLMAgentsInTree = state => {
  const { response } = store.treeAgent('mlmAgent').selector(state)
  return allMLMChildAgents(response?.children)
}

export const sumSaleTotalCommission = activeDeals => {
  let activeDealsMXN = null
  let activeDealsUSD = null

  if (activeDeals) {
    for (const { saleTotalCommission } of activeDeals) {
      if (saleTotalCommission) {
        if (saleTotalCommission.currency === 'MXN') {
          activeDealsMXN = {
            amount: (activeDealsMXN?.amount || 0) + saleTotalCommission.amount,
            currency: 'MXN'
          }
        } else if (saleTotalCommission.currency === 'USD') {
          activeDealsUSD = {
            amount: (activeDealsUSD?.amount || 0) + saleTotalCommission.amount,
            currency: 'MXN'
          }
        }
      }
    }
  }

  return {
    activeDealsMXN,
    activeDealsUSD
  }
}

export const transactionsSummarySelector = state => {
  const {
    feedback,
    profit,
    activeDeals,
    activeDealsUSD,
    notPayedProfit,
    activeDealsId,
    activeDealsDetail,
    activeDealsCount,
  } = state.mlmQuotas.transactionSummary

  return {
    loading: feedback.loading,
    profit: formatAmount(profit),
    activeDeals: formatAmount(activeDeals),
    activeDealsUSD: activeDealsUSD && formatAmount(activeDealsUSD),
    notPayedProfit: formatAmount(notPayedProfit),
    activeDealsId,
    activeDealsDetail,
    activeDealsCount,
  }
}

export { createHashFromDates as createHash }
