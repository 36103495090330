import { call, put, select, take } from 'redux-saga/effects'
import api from 'app/services/api'
import { store, utils } from 'services'

export function* enrollmentModule({ id }) {
  yield put(store.find('elearningCourse').action(id))
  const responseCourse = yield take('STORE_ELEARNING_COURSE_FIND_SUCCESS')
  const course = responseCourse.response

  if (utils.isEmpty(course.enrollment)) {
    yield put(store.makeEnrollment('elearningCourse').action(course.slug))
    const responseEnrollmentCourse = yield take(
      'STORE_ELEARNING_COURSE_MAKE_ENROLLMENT_SUCCESS'
    )
  }

  const module = course.modules[0]
  yield put(store.find('elearningModule').action(module.slug))
  const responseEnrollmentModule = yield take('STORE_ELEARNING_MODULE_FIND_SUCCESS')

  // if (utils.isEmpty(module.enrollment)) {
    yield put(store.enrollment('elearningModule').action(module.slug))
    yield take(
      'STORE_ELEARNING_MODULE_ENROLLMENT_SUCCESS'
    )
  // }
}
