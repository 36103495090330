import css from 'styled-jsx/css'
import { colors } from 'theme'

export default css`
  .large {
    font-size: 1.375rem;
  }

  .medium {
    font-size: 1rem;
  }
  
  .small {
    font-size: 0.75rem;
  }

  .large.ionicon {
    font-size: 2rem;
  }
  
  .medium.ionicon {
    font-size: 1.5rem;
  }

  .small.ionicon {
    font-size: 1rem;
  }

  .large.materialicon {
    font-size: 1.75rem;
  }
  .medium.materialicon {
    /* not used yet */
    font-size: 1.25rem;
  }  
  .success-icon.btn-close {
    color: ${colors.white};
  }
  .success-icon.btn-close.nexsite1-side-search {
    color: ${colors.text};
    font-size: 0.875rem;
  }
`
