import { companyConfig } from 'globalConfigApp'

import styles from './styles'

export default [
  <article
    data-category="account"
    data-title="¿Qué incluye mi membresía?"
    data-url="que-incluye-mi-membresia"
    className="wrapper-article"
  >
    <h4>¿Qué incluye mi membresía?</h4>
    <div className="content">
      <p>
        Nuestro servicio integral ofrece a todos nuestros aliados {companyConfig.name} los
        siguientes servicios:
      </p>
      <p>Plan integral</p>
      <ul>
        <li>Módulo de rastreo de propiedades de particulares.</li>
        <li>Difusión en portales inmobilliarios.</li>
        <li>Optimización de anuncios.</li>
        <li>Sesiones de fotos profesionales (3 por mes).</li>
        <li>Integración de página de Facebook.</li>
        <li>Integración de anuncios y videos.</li>
        <li>CRM.</li>
        <li>Gestión de la transacción Lazos.</li>
        <li>Acceso a nuestro módulo de capacitación 24/7.</li>
        <li>Asesoramiento Legal.</li>
        <li>Asesoramiento Fiscal.</li>
        <li>Protección Fiscal.</li>
        <li>Protección Legal.</li>
        <li>Contabilidad.</li>
      </ul>
      <a
        href="https://www.youtube.com/watch?v=b1LevIteBr4&t=31s"
        target="_blank"
      >
        <strong>¡Únete a {companyConfig.name}!</strong>
      </a>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="account"
    data-title={`¿Con qué datos ingreso a mi cuenta ${companyConfig.name}?`}
    data-url="con-que-datos-ingreso-a-mi-cuenta-neximo"
    className="wrapper-article"
  >
    <h4>¿Con qué datos ingreso a mi cuenta {companyConfig.name}?</h4>
    <div className="content">
      <p>
        Puedes ingresar a{' '}
        <u>
          <a href="/ingresar" target="_blank">
            <strong>{companyConfig.name}</strong>
          </a>
        </u>{' '}
        utilizando tu correo personal y la contraseña asignada por el sistema.
      </p>
      <p>Estos datos te los haremos llegar vía correo eletrónico.</p>
      <p>
        Hemos creado un breve tutorial que puedes ver a{' '}
        <a href="https://www.youtube.com/watch?v=d95AxAJ8ub0" target="_blank">
          aquí
        </a>
        .
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="account"
    data-title="¿Cómo puedo realizar los pagos de mi membresía y en dónde?"
    data-url="como-puedo-realizar-los-pagos-de-mi-membresia-y-en-donde"
    className="wrapper-article"
  >
    <h4>¿Cómo pago mi membresía?</h4>
    <div className="content">
      <p>
        Pagar tu membresía es muy sencillo, te recomendamos domiciliar tu tarjeta de crédito
        o débito o bien, puedes hacerlo por medio de transferencia (la imagen con el numero
        de referencia se adjunta a continuación), liga de pago de mercado pago o payu. 
        Es muy importante que nos informes cuando hayas realizado tu pago.
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="account"
    data-title="¿Cuál es el proceso para obtener mi factura?"
    data-url="cual-es-el-proceso-para-obtener-mi-factura"
    className="wrapper-article"
  >
    <h4>¿Cuál es el proceso para obtener mi factura?</h4>
    <div className="content">
      <p>
        Solicitar tu factura es muy fácil. Te invitamos a solicitarla a través
        de la plataforma en
        <br />
        <span className="tag">
         {'Soporte administrativo > Otros > Solicitar mi factura'}
        </span>{' '}
        o mandar un correo a facturacion@{companyConfig.companyDomain} para hacerte llegar tu
        factura.
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="account"
    data-title={`¿Puedo cambiar mi correo personal para acceder a mi cuenta ${companyConfig.name}?`}
    data-url="puedo-cambiar-mi-correo-personal-para-acceder-a-mi-cuenta-neximo"
    className="wrapper-article"
  >
    <h4>¿Puedo cambiar mi correo personal para acceder a mi cuenta {companyConfig.name}?</h4>
    <div className="content">
      <p>
        Para cambiar el correo personal de acceso a tu cuenta {companyConfig.name},
        necesitarás mandar una solicitud formal a través de la plataforma en{' '}
        <span className="tag">
          {'Soporte Administrativo > otros > cambiar mi correo.'}
        </span>
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="account"
    data-title={`¿Cómo puedo acceder a mi página Facebook ${companyConfig.name}?`}
    data-url="como-puedo-acceder-a-mi-pagina-facebook-neximo"
    className="wrapper-article"
  >
    <h4>¿Cómo puedo acceder a mi página Facebook {companyConfig.name}?</h4>
    <div className="content">
      <p>
        Puedes ingresar a tu cuenta de Facebook aceptando la invitación como{' '}
        <span className="tag">Editor</span> que te llega vía correo electrónico
        al dar de alta tu cuenta. De esta manera podrás empezar a crear un
        perfil profesional en redes sociales.
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="account"
    data-title={`¿Puedo realizar publicaciones en mi Facebook ${companyConfig.name}?`}
    data-url="puedo-realizar-publicaciones-en-mi-facebook-neximo"
    className="wrapper-article"
  >
    <h4>¿Puedo realizar publicaciones en mi Facebook {companyConfig.name}?</h4>
    <div className="content">
      <p>
        Facebook es uno de los principales canales de comunicación en México por
        lo que si puedes realizar publicaciones para crear una audiencia y
        atraer más personas interesadas en tus propiedades. No olvides invitar a
        todos tus contactos a dar like a tu página para que comience a ser
        visible para otros.
      </p>
      <p>
        Para invitar tus contactos, seleccionar el boton de
        <span className="tag">Invitar amigos</span>. Recuerda agregar un mensaje
        explicando los servicios que ofreces como asesor inmobiliario. Para
        realizar publicaciones en tu página, es importante que aceptes el rol de
        <span className="tag">Editor</span> que te ortogamos al crear tu página.
      </p>
      <p>
        Cada día {companyConfig.name} publicará un video de tus propiedades, no olvides
        compartirlo.
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="account"
    data-title={`¿Cómo puedo vincular mi correo ${companyConfig.name} a mi celular?`}
    data-url="como-puedo-vincular-mi-correo-neximo-a-mi-celular"
    className="wrapper-article"
  >
    <h4>¿Cómo puedo vincular mi correo {companyConfig.name} a mi celular?</h4>
    <div className="content">
      <p>
        Poder recibir tus correos {companyConfig.name} en tu celular es muy útil, es por eso
        que te explicamos como puedes agregar tu cuenta para estar al día en
        todo momento. Para vincular tu correo {companyConfig.name} tienes que ingresar desde
        tu celular a tu aplicación de correo electrónico y seleccionar{' '}
        <span className="tag">Configuración</span> a continuación seleccionar{' '}
        <span className="tag">agregar cuenta</span> e ingresar tu correo de
        {companyConfig.name}, así como tu contraseña asignada por el sistema.
      </p>
      <p>
        Puedes guiarte por los mensajes de Google y podrás ver tu correo
        configurado.
      </p>
      <p>
        Hemos creado un breve tutorial que puedes ver a continuación:{' '}
        <a href="https://www.youtube.com/watch?v=H5oX_hu7XuQ" target="_blank">
          ver aquí
        </a>
        .
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="account"
    data-title="¿Cuál es el proceso para cancelar mi membresía?"
    data-url="cual-es-el-proceso-para-cancelar-mi-membresia"
    className="wrapper-article"
  >
    <h4>¿Cuál es el proceso para cancelar mi membresía?</h4>
    <div className="content">
      <p>
        Lo más importante para {companyConfig.name} es conocer los motivos que te llevaron a
        cancelar tu cuenta, mejoramos continuamente para ofrecer el mejor
        servicio. Nuestra política de cancelación es clara y sencilla, te la
        explicamos a continuación:
      </p>

      <ol>
        <li>
          Solicitud de baja por parte del aliado al email info@{companyConfig.companyDomain}.
        </li>
        <li>Se envía encuesta para conocer las razones de la cancelación.</li>
        <li>
          Se aplican condiciones de reembolso con base en cada acción realizada
          con el aliado.
        </li>
        <li>
          Se aplicará cualquier posible reembolso 45 días después de la
          solicitud de aliado.
        </li>
      </ol>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="account"
    data-title="¿Si cancelo mi membresía me reembolsaran mi dinero?"
    data-url="si-cancelo-mi-membresia-me-reembolsaran-mi-dinero"
    className="wrapper-article"
  >
    <h4>¿Si cancelo mi membresía me reembolsaran mi dinero?</h4>
    <div className="content">
      <p>
        Queremos ofrecer el mejor servicio a nuestros aliados. Cuando
        consideramos que no te hemos dado el servicio prometido, te podemos
        reembolsar todo a parte de tu membresia. Para saber si eres elegible a
        un reembolso, necesitas ponerte en contacto con nuestro equipo de
        onboarding (info@{companyConfig.companyDomain}).
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="account"
    data-title="¿Qué opciones tengo en caso de no poder pagar mi membresía a tiempo?"
    data-url="que-opciones-tengo-en-caso-de-no-poder-pagar-mi-membresia-a-tiempo"
    className="wrapper-article"
  >
    <h4>
      ¿Qué opciones tengo en caso de no poder pagar mi membresía a tiempo?
    </h4>
    <div className="content">
      <p>
        En {companyConfig.name} queremos apoyarte, es por eso que en caso de no haber
        realizado tu pago, pero de contar con dinero en tu Red, será posible
        realizar el pago de tu membresía a través de esta fuente.
      </p>
      <p>
        En caso de no poder realizar el pago, la cuenta será suspendida y no
        será posible retirar tus propiedades hasta después de liquidar el
        adeudo.
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="account"
    data-title="¿Neximo me asesorará para llevar mi contabilidad indicándome qué puedo
    hacer deducible y cuándo tengo que declarar de acuerdo al regimen fiscal
    que tengo?"
    data-url="neximo-me-asesorara-para-llevar-mi-contabilidad-indicandome-que-puedo-hacer-deducible-y-cuando-tengo-que-declarar-de-acuerdo-al-regimen-fiscal-que-tengo"
    className="wrapper-article"
  >
    <h4>
      ¿Neximo me asesorará para llevar mi contabilidad indicándome qué puedo
      hacer deducible y cuándo tengo que declarar de acuerdo al regimen fiscal
      que tengo?
    </h4>
    <div className="content">
      <p>
        {companyConfig.name} cuenta con todos los servicios que te ayudarán a brindar el mejor
        servicio a tus clientes. Nuestro equipo legal, fiscal y de contabilidad
        te brindará toda la asesoría necesaria para cumplir con los requisitos
        contables y fiscales relacionados a tu actividad.
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="account"
    data-title="¿Cómo puedo acceder al sistema para comercializar una propiedad?"
    data-url="como-puedo-acceder-al-sistema-para-comercializar-una-propiedad"
    className="wrapper-article"
  >
    <h4>¿Cómo puedo acceder al sistema para comercializar una propiedad?</h4>
    <div className="content">
      <p>
        Para publicar una propiedad te sugerimos tener todos los datos a la mano
        para que sea mucho más fácil subir tu propiedad.
      </p>
      <p>Los datos que te solicitamos son:</p>
      <ul>
        <li>Ficha técnica del inmueble.</li>
        <li>Descripción.</li>
        <li>Ubicación exacta (calle, número, colonia, CP.).</li>
        <li>
          Y tener a la mano el archivo que contiene las fotografías del
          inmueble.
        </li>
      </ul>
      <p>
        Una vez en tu plataforma debes ingresar a la pestaña{' '}
        <span className="tag">{'Gestión de propiedades > Publicar ahora'}</span>.
      </p>
      <p>
        No olvides llenar cada uno de los campos requeridos para que puedas
        avanzar en el menú. Al llegar a la parte de{' '}
        <span className="tag">Media</span> selecciona las fotos desde tu
        ordenador y finaliza con los datos del propietario.
      </p>
      <p>
        Recuerda que tenemos habiliatada la función del{' '}
        <span className="tag">Borrador</span> que guarda en automático los
        cambios que hayas realizado.
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="account"
    data-title="¿Cómo puedo editar mi perfil?"
    data-url="como-puedo-editar-mi-perfil"
    className="wrapper-article"
  >
    <h4>¿Cómo puedo editar mi perfil?</h4>
    <div className="content">
      <p>Puedes modificar fácilmente tu perfil desde tu cuenta.</p>
      <p>
        Haz clic en tu foto para abrir el menú desplegable y posteriormente en{' '}
        <span className="tag">Mi perfil</span>, esto te llevará directamente al
        menú donde podrás editar toda tu información personal y sobre todo
        agregar una descripción tuya y de lo que has logrado en el ramo
        inmobiliario. La información de perfil te permite presentarte tal y como
        quieres que los demás te vean.
      </p>

      <p>
        Hemos creado un breve tutorial que puedes ver a continuación:{' '}
        <a href="https://www.youtube.com/watch?v=_2HhoDkvHPg" target="_blank">
          ver aquí
        </a>
        .
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="account"
    data-title="¿Por qué es importante agregar Mis zonas?"
    data-url="por-que-es-importante-agregar-mis-zonas"
    className="wrapper-article"
  >
    <h4>¿Por qué es importante agregar Mis zonas?</h4>
    <div className="content">
      <p>
        Queremos ayudarte a ahorrar tiempo en tu búsqueda y prospección de
        inmuebles por lo que requerimos de tus 6 zonas de trabajo para poder
        ofrecerte nuestro servicio de
        <br />
        <span className="tag">Captación de propiedades</span> y{' '}
        <span className="tag">Citas calificadas</span>.
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="account"
    data-title="¿Cómo puedo acceder a mis cursos de Capacitación en Línea?"
    data-url="como-puedo-acceder-a-mis-cursos-de-capacitacion-en-linea"
    className="wrapper-article"
  >
    <h4>¿Cómo puedo acceder a mis cursos de Capacitación en Línea?</h4>
    <div className="content">
      <p>
        {companyConfig.name} busca formar a los mejores asesores inmobiliarios de México, por
        ello ponemos a tu disposición una serie de cursos en línea que puedes
        tomar desde tu plataforma, debes ingresar a la sección{' '}
        <span className="tag">Universidad {companyConfig.name}</span> y ahí podrás comenzar
        con tu capacitación.
      </p>
      <p>
        Podrás accesar a ellos 24/7, recuerda que todos nuestros cursos son
        secuenciales, asegúrate que al término de cada video tomes tu prueba
        para accesar al siguiente.
      </p>
      <p>
        Hemos creado un breve tutorial que puedes ver a continuación:{' '}
        <a href="https://www.youtube.com/watch?v=3NvmjK_oGRY" target="_blank">
          ver aquí
        </a>
      </p>
    </div>

    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="account"
    data-title="¿Qué hago si domicilie mi tarjeta y no se hace el cobro automático?"
    data-url="que-hago-si-domicilie-mi-tarjeta-y-no-se-hace-el-cobro-automatico"
    className="wrapper-article"
  >
    <h4>¿Qué hago si domicilie mi tarjeta y no se hace el cobro automático?</h4>
    <div className="content">
      <p>
        En ocasiones esto no sucede por diversos motivos:
        <ul>
          <li>La tarjeta se encuentra apagada</li>
          <li>La tarjeta no tiene fondos disponibles</li>
          <li>El horario de cobro automatizado no está permitido por el banco</li>
          <li>Otros</li>
        </ul>
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="account"
    data-title="Gané un premio, ¿cómo lo obtengo?"
    data-url="gane-un-premio-como-lo-obtengo"
    className="wrapper-article"
  >
    <h4>Gané un premio, ¿cómo lo obtengo?</h4>
    <div className="content">
      <p>
        Cada Nexipremio tiene una dinámica diferente, por ello deberás estar
        atento a las indicaciones, podrás tener información detallada en{' '}
        <a href={`mailto:info@${companyConfig.companyDomain}`}>{`info@${companyConfig.companyDomain}`}</a>
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="account"
    data-title="¿Cuáles son los teléfonos de CX o Atención al Cliente?"
    data-url="cuales-son-los-telefonos-de-cx-o-atencion-al-cliente"
    className="wrapper-article"
  >
    <h4>¿Cuáles son los teléfonos de CX o Atención al Cliente?</h4>
    <div className="content">
      <p>
        Para atención personalizada puedes comunicarte a los siguientes
        teléfonos: 55 1328 9530
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="account"
    data-title="¿Quién es mi ejecutivo de experiencia?"
    data-url="quien-es-mi-ejecutivo-de-experiencia"
    className="wrapper-article"
  >
    <h4>¿Quién es mi ejecutivo de experiencia?</h4>
    <div className="content">
      <p>
        Saber quien es tu ejecutivo de cuanta es muy sencillo, para conocerlo,
        debes ingresar a tu dashboard y dar click en soporte.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="account"
    data-title="¿Cómo puedo realizar un cierre?"
    data-url="como-puedo-realizar-un-cierre"
    className="wrapper-article"
  >
    <h4>¿Cómo puedo realizar un cierre?</h4>
    <div className="content">
      <p>
        Existen diferentes tipos de cierres para cada uno de ellos tenemos un
        tutorial de soporte en la siguiente liga:{' '}
        <a
          href="https://www.youtube.com/@soportetecniconeximo4739/videos"
          target="_blank"
        >
          ver aquí
        </a>
      </p>
      <p>
        Pero si aun así tienes dudas, puedes enviar un correo a tu ejecutivo de
        cuenta o a <a href={`mailto:info@${companyConfig.companyDomain}`}>{`info@${companyConfig.companyDomain}`}</a>
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="account"
    data-title="¿Por qué no me llegan leads? // ¿Como generar más leads?"
    data-url="por-que-no-me-llegan-leads-como-generar-mas-leads"
    className="wrapper-article"
  >
    <h4>¿Por qué no me llegan leads? // ¿Como generar más leads?</h4>
    <div className="content">
      <ol>
        <li>Ubicación: la ubicación de la propiedad puede ser inapropiada o poco atractiva para los clientes.</li>
        <li>Precios: los precios pueden ser demasiado altos en comparación con propiedades similares en la zona.</li>
        <li>Falta de características atractivas: la propiedad puede no tener características o servicios que la hagan atractiva para los clientes.</li>
        <li>Competencia: hay muchas propiedades similares en la zona, lo que hace que sea más difícil para su propiedad destacar.</li>
        <li>Reputación: la propiedad puede tener una mala reputación debido a experiencias previas de los clientes.</li>
        <li>Falta de mantenimiento: la propiedad puede no estar en buen estado o no estar bien mantenida, lo que puede afectar la experiencia del cliente.</li>
      </ol>
      <p>Es importante evaluar cada uno de estos factores y trabajar en ellos para mejorar la situación.</p>
      <p>De igual forma si tienes dudas de si se están publicando correctamente en portales puedes llamar o escribirnos al correo de <a href={`mailto:info@${companyConfig.companyDomain}`}>{`info@${companyConfig.companyDomain}`}</a> para que podamos revisarlo.</p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="account"
    data-title="¿Por qué me solicita un código de acceso siempre que quiero entrar a mi plataforma?"
    data-url="por-que-me-solicita-un-codigo-de-acceso-siempre-que-quiero-entrar-a-mi-plataforma"
    className="wrapper-article"
  >
    <h4>¿Por qué me solicita un código de acceso siempre que quiero entrar a mi plataforma?</h4>
    <div className="content">
      <p>
        Esto sucede cuando estás cambiando de dispositivos continuamente o cuando dejas mucho tiempo sin cerrar tu sesión, te recomendamos solo tener tu sesión en 2 dispositivos de tu preferencia.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="account"
    data-title="¿A donde ir por mis productos que compre en la Nexitienda?"
    data-url="a-donde-ir-por-mis-productos-que-compre-en-la-nexitienda"
    className="wrapper-article"
  >
    <h4>¿A donde ir por mis productos que compre en la Nexitienda?</h4>
    <div className="content">
      <p>
        Puedes pasar por ellos a nuestras oficinas, es importante que verifiques antes que los productos se encuentras disponibles en oficina, o bien, puedes solicitar envío a tu domicilio con un costo adicional de 250 mxn
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="account"
    data-title={`¿En dónde encuentro los diseños ${companyConfig.name}?`}
    data-url="en-donde-encuentro-los-disenos-neximo"
    className="wrapper-article"
  >
    <h4>¿En dónde encuentro los diseños {companyConfig.name}?</h4>
    <div className="content">
      <p>
        Encontraras todos los creativo, logos, pantones y todo lo relacionado con la marca en la direccion <a href="https://brand.iadmexico.mx">Nexitienda</a>
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="account"
    data-title={`Termine la Universidad ${companyConfig.name}, ¿que sigue?`}
    data-url="termine-la-universidad-neximo-que-sigue"
    className="wrapper-article"
  >
    <h4>Termine la Universidad {companyConfig.name}, ¿que sigue?</h4>
    <div className="content">
      <p>
        Al siguiente mes de que la termines te llegara tu diploma o lo puedes solicitar a hilda.carballo@{companyConfig.companyDomain}
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="account"
    data-title="¿Cómo agrego mi correo neximo a mi smartphone iOS o Android?"
    data-url="como-agrego-mi-correo-neximo-a-mi-smartphone-ios-o-android"
    className="wrapper-article"
  >
    <h4>¿Cómo agrego mi correo neximo a mi smartphone iOS o Android?</h4>
    <div className="content">
      <p>
        En tu sección correo tenemos videos con los tutoriales paso a paso para que los des de alta en tus dispositivos móviles.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="account"
    data-title="¿Cuánto debo de mi pago de mensualidad?"
    data-url="cuanto-debo-de-mi-pago-de-mensualidad"
    className="wrapper-article"
  >
    <h4>¿Cuánto debo de mi pago de mensualidad?</h4>
    <div className="content">
      <p>
        Puedes revisar tu fecha de corte, saldo, la tarjeta que diste de alta y tus recibos en tu sección pagos y facturación
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="account"
    data-title="¿Puedo cambiar mi correo personal al de neximo?"
    data-url="puedo-cambiar-mi-correo-personal-al-de-neximo"
    className="wrapper-article"
  >
    <h4>¿Puedo cambiar mi correo personal al de neximo?</h4>
    <div className="content">
      <p>
        No se puede hacer cambio del correo ya que este esta ligado a tu cuenta y a los clientes.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>
]
