import { createReducer, createActions } from 'reduxsauce'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback
} from './utils'

const INITIAL_STATE = {
  isReportFormOpen: false,
  propertyReported: null,
  postPropertyReport: initialFeedback()
}

const { Types, Creators } = createActions(
  {
    openReportModal: ['isReportFormOpen'],
    reportProperty: ['property'],
    sendReportRequest: ['propertyId', 'formData', 'isPublicReport'],
    sendReportSuccess: [],
    sendReportFailure: ['error'],
    clearPostPropertyReport: {}
  },
  { prefix: 'PROPERTY_COMPLAINT_' }
)

export const openReportModal = (state, {isReportFormOpen}) => ({
  ...state,
  isReportFormOpen
})

export const reportProperty = (state, { property }) => ({
  ...state,
  propertyReported: property
})

export const sendReportRequest = (state, { formData, propertyId }) => {
  return {
    ...state,
    postPropertyReport: requestFeedback()
  }
}

export const sendReportSuccess = state => ({
  ...state,
  postPropertyReport: successFeedback()
})

export const sendReportFailure = (state, { error }) => ({
  ...state,
  postPropertyReport: failureFeedback(error)
})

export const clearPostPropertyReport = state => ({
  ...state,
  postPropertyReport: INITIAL_STATE.postPropertyReport
})

const HANDLERS = {
  [Types.OPEN_REPORT_MODAL]: openReportModal,
  [Types.REPORT_PROPERTY]: reportProperty,
  [Types.SEND_REPORT_REQUEST]: sendReportRequest,
  [Types.SEND_REPORT_SUCCESS]: sendReportSuccess,
  [Types.SEND_REPORT_FAILURE]: sendReportFailure,
  [Types.CLEAR_POST_PROPERTY_REPORT]: clearPostPropertyReport
}

export const PropertyComplaintsTypes = Types
export const PropertyComplaintsCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
