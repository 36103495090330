import { createReducer, createActions } from 'reduxsauce'
import {
  initialFeedback,
  failureFeedback,
  requestFeedback,
  successFeedback,
} from './utils'
import { INITIAL_PERMISSION_STATE } from 'app/selectors/contractVersion'

const INITIAL_STATE = {
  permissions: {}, // { 'permission-name': { isAllowed: Bool, error: String, feedback: {...} } }
  urlForContract: {
    url: null,
    feedback: initialFeedback(),
  },
}

const { Types, Creators } = createActions(
  {
    checkPermission: ['permission'],
    checkPermissionSuccess: ['permission'],
    checkPermissionFailure: ['permission', 'error'],
    getUrlByContract: ['params'],
    getUrlByContractSuccess: ['url'],
    getUrlByContractFailure: ['errors'],
  },
  { prefix: 'CONTRACT_VERSION_' }
)

const checkPermissionReducer = (state, permission, newData = {}) => {
  const permissionState = {
    ...INITIAL_PERMISSION_STATE,
    ...newData
  }

  return {
    ...state,
    permissions: {
      ...state.permissions,
      [permission]: permissionState
    }
  }
}

const checkPermission = (state, { permission }) =>
  checkPermissionReducer(state, permission, { feedback: requestFeedback() })

const checkPermissionSuccess = (state, { permission }) =>
  checkPermissionReducer(state, permission, {
    isAllowed: true,
    feedback: successFeedback()
  })

const checkPermissionFailure = (state, { permission, error }) =>
  checkPermissionReducer(state, permission, {
    isAllowed: false,
    error: error,
    feedback: failureFeedback()
})

const getUrlByContract = (state) => ({
  ...state,
  urlForContract: {
    feedback: requestFeedback()
  }
})

const getUrlByContractSuccess = (state, { url }) => ({
  ...state,
  urlForContract: {
    url: url,
    feedback: successFeedback(),
  }
})

const getUrlByContractFailure = (state, { errors }) => ({
  ...state,
  urlForContract: {
    url: null,
    feedback: failureFeedback(errors),
  }
})

const HANDLERS = {
  [Types.CHECK_PERMISSION]: checkPermission,
  [Types.CHECK_PERMISSION_SUCCESS]: checkPermissionSuccess,
  [Types.CHECK_PERMISSION_FAILURE]: checkPermissionFailure,
  [Types.GET_URL_BY_CONTRACT]: getUrlByContract,
  [Types.GET_URL_BY_CONTRACT_SUCCESS]: getUrlByContractSuccess,
  [Types.GET_URL_BY_CONTRACT_FAILURE]: getUrlByContractFailure,
}

export const ContractVersionActionTypes = Types
export const ContractVersionActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
