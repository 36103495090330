import ApiBase from 'app/services/api/entities/Base'

function* validateImage(data) {
  return yield ApiBase.privatePost('/v1/virtualtour/validate_image_floorfy/', {
    data
  })
}

export default {
  validateImage,
}
