import {
  operationsSrpTitle,
  locationSrpTitle,
  srpUrlOnlyHasPropertyTypeParam,
  srpUrlOnlyHasOperationTypeParam,
  srpUrlOnlyHasOTandPT,
  isValidOperationType,
  isValidPropertyType,
  metaTitleSRP,
} from 'services/seo'
import { companyConfig } from 'globalConfigApp'

export const searchNowMsg =
  `Encuentra la que más se adapte a tus necesidades en ${companyConfig.name}. Busca Hoy.`

const OT_ACTIONS = {
  SELL: 'vender',
  RENT: 'rentar'
}

const SRP_DESC = {
  /**
   * Descriptions patterns
   */
  base: ({ countResults }) => `Tenemos más de ${countResults}`,
  propertyType: ({ countResults }) => `Tenemos más de ${countResults}`,
  operationType: ({ countResults }) => `Tenemos más de ${countResults}`,
  'operationType-propertyType': ({ operationTypeLabel, countResults }) =>
    `Elige ${operationTypeLabel} entre más de ${countResults}`,
  'operationType-propertyType-location': ({ operationTypeLabel, countResults}) => 
    `Elige ${operationTypeLabel} entre más de ${countResults}`,

  /** 
    * Use to build a description with specific rules
    */
  build: srpParams => {
    const { propertyType, operationType, location, countResults, ...rest } = srpParams

    const metaTitle = metaTitleSRP({ ...srpParams, location: srpParams.location || 'México' })

    /* Pages like: /casas/en-venta/condesa--cuauhtemoc--ciudad-de-mexico */
    if (
      location &&
      isValidOperationType(operationType) &&
      isValidPropertyType(propertyType)
    ) {
      const description = SRP_DESC['operationType-propertyType-location']
      const srpDesc = description({
        countResults,
        operationTypeLabel: OT_ACTIONS[operationType]
      })

      return `${srpDesc} ${metaTitle}`
    }

    /* Pages like: /casas/en-venta */
    if (srpUrlOnlyHasOTandPT(srpParams)) {
      const description = SRP_DESC['operationType-propertyType']
      const srpDesc = description({
        countResults,
        operationTypeLabel: OT_ACTIONS[operationType]
      })

      return `${srpDesc} ${metaTitle}`
    }

    /* Pages like: /en-venta */
    if (srpUrlOnlyHasOperationTypeParam(srpParams)) {
      const description = SRP_DESC.operationType
      const srpDesc = description({ countResults })

      return `${srpDesc} ${metaTitle}`
    }

    /* Pages like: /casas */
    if (srpUrlOnlyHasPropertyTypeParam(srpParams)) {
      const description = SRP_DESC.propertyType
      const srpDesc = description({ countResults })

      return `${srpDesc} ${metaTitle}`
    }

    const srpDesc = SRP_DESC.base(srpParams)

    return `${srpDesc} ${metaTitle}`
  }
}

export { SRP_DESC }
