import React from 'react'
import PropTypes from 'prop-types'

import { option } from './styles'

const Option = ({ label }) => (
  <div className="option">
    {label}
    <style jsx>{option}</style>
  </div>
)

Option.propTypes = {
  label: PropTypes.any.isRequired
}

export default Option
