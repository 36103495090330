import { createReducer, createActions } from 'reduxsauce'

const INITIAL_REQUEST_STATE = {
  loading: false,
  error: null,
  response: null,
  request: null
}

const INITIAL_STATE = {
  createRequest: INITIAL_REQUEST_STATE,
  updateRequest: INITIAL_REQUEST_STATE,
  findRequest: INITIAL_REQUEST_STATE,
  likedAds: [],
  ads: [],
  rejectedAds: [],
  selectedProperties: [],
  loadingSearch: false,
  contact: {
    id: null
  },
  event: {},
  page: 0,
  data: {
    results: []
  },
  stateData: {},
  municipalityData: {},
  neighborhoodData: {},
  filters: {
    propertyType: [],
    operationType: '',
    bedrooms: '',
    bathrooms: '',
    parkingSpaces: '',
    minPrice: '',
    maxPrice: '',
    currency: '',
    selectedLocations: []
  },
  usedFilters: [
    {
      bathrooms: '',
      bedrooms: '',
      id: '',
      limit: 10,
      maxPrice: '',
      minPrice: '',
      currency: '',
      offset: 0,
      operationType: '',
      parkingSpaces: '',
      propertyType: [],
      selectedLocations: []
    }
  ],
  payload: {},
  error: null,
  showFilter: false,
  step: 'SEARCH_PROPERTIES', // values : [SEARCH_PROPERTIES, PREVIEW_PROPERTIES, SEND_PROPERTIES_STEP, EVENT_SCHEDULE]
  contactStep: 'SEARCH', // values: [SEARCH, NEW]
}

const { Types, Creators } = createActions(
  {
    addProperty: ['property'],
    removeProperty: ['property'],

    search: ['payload'],
    searchSuccess: ['payload'],
    searchFail: ['payload'],

    createLead: ['payload'],
    createLeadSuccess: ['payload'],
    createLeadFail: ['payload'],

    updateLead: ['payload'],
    updateLeadSuccess: ['payload'],
    updateLeadFail: ['payload'],

    findLead: ['payload'],
    findLeadSuccess: ['payload'],
    findLeadFail: ['payload'],

    findContact: ['payload'],
    findContactSuccess: ['payload'],
    findContactFail: ['payload'],

    showFilter: ['showFilter'],

    changeStep: ['step'],

    changeContactStep: ['contactStep'],

    updateContact: ['contact'],
    updateEvent: ['event'],

    updateFilters: ['filters'],
    applyFilters: ['page'],
    updateLocations: ['locationInfo'],

    clear: []
  },
  { prefix: 'BIN_' }
)

export const addProperty = (state, { property }) => ({
  ...state,
  data: {
    ...state.data,
    results: state.data.results.map(r =>
      r.id === property.id ? { ...r, hasBeenAdded: true } : r
    )
  },
  selectedProperties: [
    ...state.selectedProperties,
    { ...property, hasBeenAdded: true }
  ]
})
export const removeProperty = (state, { property }) => ({
  ...state,
  data: {
    ...state.data,
    results: state.data.results.map(r => {
      if (r.id === property.id) {
        const { hasBeenAdded, ...rest } = r
        return { ...rest }
      }
      return r
    })
  },
  selectedProperties: state.selectedProperties.filter(p => p.id !== property.id)
})

export const search = (state, { payload }) => ({
  ...state,
  loadingSearch: true,
  payload
})
export const searchSuccess = (state, { payload }) => {
  const mappedResults = payload.data.results.map(r => {
    if (state.selectedProperties.find(sp => sp.id === r.id)) {
      return { ...r, hasBeenAdded: true }
    }
    if (state.likedAds.find(la => la.ad.id === r.id)) {
      return { ...r, hasBeenLiked: true }
    }
    if (state.rejectedAds.find(ra => ra.ad.id === r.id)) {
      return { ...r, hasBeenRejected: true }
    }
    if (state.ads.find(a => a.ad.id === r.id)) {
      return { ...r, hasBeenSent: true }
    }
    return r
  })
  let results = [...state.data.results, ...mappedResults]
  if (state.page === 0) {
    results = mappedResults
  }
  return {
    ...state,
    loadingSearch: false,
    data: {
      ...payload.data,
      results
    }
  }
}
export const searchFail = (state, { error }) => ({
  ...state,
  loadingSearch: false,
  error
})

export const createLead = (state, { payload }) => ({
  ...state,
  createRequest: {
    ...INITIAL_REQUEST_STATE,
    loading: true,
    request: payload.request
  }
})
export const createLeadSuccess = (state, { payload }) => ({
  ...state,
  createRequest: {
    ...state.createRequest,
    loading: false,
    response: payload.data
  }
})
export const createLeadFail = (state, { error }) => ({
  ...state,
  createRequest: {
    ...state.createRequest,
    loading: false,
    error: {
      ...error.response.data,
      errorResponseStatus: error.response.status
    }
  }
})

export const updateLead = (state, { payload }) => ({
  ...state,
  updateRequest: {
    ...INITIAL_REQUEST_STATE,
    loading: true,
    request: payload.request
  }
})
export const updateLeadSuccess = (state, { payload }) => ({
  ...state,
  updateRequest: {
    ...state.updateRequest,
    loading: false,
    response: payload.data
  }
})
export const updateLeadFail = (state, { error }) => ({
  ...state,
  updateRequest: {
    ...state.updateRequest,
    loading: false,
    error: error.response.data
  }
})

export const findLead = (state, { payload }) => ({
  ...state,
  findRequest: {
    ...INITIAL_REQUEST_STATE,
    loading: true,
    request: payload.request
  },
  likedAds: [],
  rejectedAds: []
})
export const findLeadSuccess = (state, { payload }) => ({
  ...state,
  findRequest: {
    ...state.findRequest,
    loading: false,
    response: payload.data
  },
  contact: payload.data.contact,
  event: {
    name: payload.data.name,
    description: payload.data.description
  },
  ads: payload.data.ads,
  likedAds: payload.data.likedAds,
  rejectedAds: payload.data.rejectedAds
})
export const findLeadFail = (state, { payload = {} }) => ({
  ...state,
  findRequest: {
    ...state.findRequest,
    loading: false,
    error: payload.data
  }
})

export const findContactSuccess = (state, { payload }) => ({
  ...state,
  contact: {
    ...payload.data
  }
})

export const showFilter = (state, { showFilter }) => ({
  ...state,
  showFilter
})

export const changeStep = (state, { step }) => ({
  ...state,
  step
})

export const changeContactStep = (state, { contactStep }) => ({
  ...state,
  contactStep
})

export const updateContact = (state, { contact }) => ({
  ...state,
  contact
})
export const updateEvent = (state, { event }) => ({
  ...state,
  event
})
export const updateFilters = (state, { filters }) => ({
  ...state,
  filters: {
    ...state.filters,
    ...filters
  }
})

export const applyFilters = (state, { page }) => ({
  ...state,
  page,
  usedFilters: [state.filters, ...state.usedFilters]
})

const updateLocations = (state, { locationInfo }) => {
  const { stateData, municipalityData, neighborhoodData } = state

  const newsLocations = {
    stateData,
    municipalityData,
    neighborhoodData
  }

  if (locationInfo.locationType === 'state') {
    newsLocations.stateData[locationInfo.name] = locationInfo
  }
  if (locationInfo.locationType === 'municipality') {
    newsLocations.municipalityData[locationInfo.name] = locationInfo
  }
  if (locationInfo.locationType === 'neighborhood') {
    newsLocations.neighborhoodData[locationInfo.name] = locationInfo
  }

  return {
    ...state,
    ...newsLocations
  }
}

export const clear = () => INITIAL_STATE

const HANDLERS = {
  [Types.ADD_PROPERTY]: addProperty,
  [Types.REMOVE_PROPERTY]: removeProperty,
  [Types.SEARCH]: search,
  [Types.SEARCH_SUCCESS]: searchSuccess,
  [Types.SEARCH_FAIL]: searchFail,
  [Types.SHOW_FILTER]: showFilter,

  [Types.CREATE_LEAD]: createLead,
  [Types.CREATE_LEAD_SUCCESS]: createLeadSuccess,
  [Types.CREATE_LEAD_FAIL]: createLeadFail,

  [Types.UPDATE_LEAD]: updateLead,
  [Types.UPDATE_LEAD_SUCCESS]: updateLeadSuccess,
  [Types.UPDATE_LEAD_FAIL]: updateLeadFail,

  [Types.FIND_LEAD]: findLead,
  [Types.FIND_LEAD_SUCCESS]: findLeadSuccess,
  [Types.FIND_LEAD_FAIL]: findLeadFail,

  [Types.FIND_CONTACT_SUCCESS]: findContactSuccess,

  [Types.CHANGE_STEP]: changeStep,
  [Types.CHANGE_CONTACT_STEP]: changeContactStep,

  [Types.UPDATE_CONTACT]: updateContact,
  [Types.UPDATE_EVENT]: updateEvent,

  [Types.UPDATE_FILTERS]: updateFilters,
  [Types.APPLY_FILTERS]: applyFilters,
  [Types.UPDATE_LOCATIONS]: updateLocations,

  [Types.CLEAR]: clear
}

export const BinActionTypes = Types
export const BinActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
