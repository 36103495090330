import { createReducer, createActions } from 'reduxsauce'
import { AuthActionTypes } from './AuthRedux'
import {
  failureFeedback,
  initialFeedback,
  requestFeedback,
  successFeedback,
} from './utils'

const INITIAL_STATE = {
  userSessionId: null,
  fingerprint: null,
  shouldBeLogout: false,
  excededDevices: false,
  newDevice: false,
  shouldBeRevalidated: false,
  alias: null,
  limitUserActiveSessions: 1,
  activeSessions: [],
  createSessionFeedback: initialFeedback(),
  getSessionsFeedback: initialFeedback(),
  logoutSessionsFeedback: initialFeedback(),
}

const { Types, Creators } = createActions(
  {
    createSessionRequest: ['alias'],
    createSessionSuccess: ['userSessionId', 'fingerprint', 'alias'],
    createSessionFailure: ['errors'],
    cleanFeedback: [],

    getSessionsRequest: [],
    getSessionsSuccess: ['sessions'],
    getSessionsFailure: ['errors'],

    logoutSessionsRequest: ['sessions'],
    logoutSessionsSuccess: [],
    logoutSessionsFailure: ['errors'],

    revalidateSession: [],
    revalidateSessionSuccess: [],

    loginOrRefreshTokenSuccess: ['refreshData'],

    logoutSessionRequest: [], // Device is logging out

  },
  { prefix: 'USER_SESSION_' },
)

const createSessionRequest = state => ({
  ...state,
  createSessionFeedback: requestFeedback(),
})
const createSessionSuccess = (
  state,
  { userSessionId, fingerprint, alias },
) => ({
  ...state,
  userSessionId,
  fingerprint,
  alias,
  newDevice: false,
  createSessionFeedback: successFeedback(),
})
const createSessionFailure = (state, { errors }) => ({
  ...state,
  createSessionFeedback: failureFeedback(errors),
})
const cleanFeedback = state => ({
  ...state,
  createSessionFeedback: initialFeedback(),
})

const getSessionsRequest = state => ({
  ...state,
  getSessionsFeedback: requestFeedback(),
})
const getSessionsSuccess = (state, { sessions }) => ({
  ...state,
  activeSessions: sessions.filter(
    session => session.id !== state.userSessionId,
  ),
  getSessionsFeedback: successFeedback(),
})
const getSessionsFailure = (state, { errors }) => ({
  ...state,
  getSessionsFeedback: failureFeedback(errors),
})

const logoutSessionsRequest = state => ({
  ...state,
  logoutSessionsFeedback: requestFeedback(),
})
const logoutSessionsSuccess = state => ({
  ...state,
  shouldBeLogout: false,
  excededDevices: false,
  newDevice: false,
  logoutSessionsFeedback: successFeedback(),
})
const logoutSessionsFailure = (state, { errors }) => ({
  ...state,
  logoutSessionsFeedback: failureFeedback(errors),
})

const revalidateSessionSuccess = (state) => ({
  ...state,
  shouldBeRevalidated: false,
})

const loginOrRefreshTokenSuccess = (state, { response, refreshData }) => {
  const data = { ...response, ...refreshData }

  const nextState = { ...state }

  nextState['fingerprint'] = data.fingerprint || state.fingerprint || null

  nextState['shouldBeLogout'] = data.shouldBeLogout || false
  nextState['excededDevices'] = data.excededDevices || false
  nextState['newDevice'] = data.newDevice || false
  nextState['limitUserActiveSessions'] =
    data.limitUserActiveSessions || state.limitUserActiveSessions
  nextState['shouldBeRevalidated'] = data.shouldBeRevalidated || false

  return nextState
}

const HANDLERS = {
  [Types.CREATE_SESSION_REQUEST]: createSessionRequest,
  [Types.CREATE_SESSION_SUCCESS]: createSessionSuccess,
  [Types.CREATE_SESSION_FAILURE]: createSessionFailure,

  [Types.GET_SESSIONS_REQUEST]: getSessionsRequest,
  [Types.GET_SESSIONS_SUCCESS]: getSessionsSuccess,
  [Types.GET_SESSIONS_FAILURE]: getSessionsFailure,

  [Types.LOGOUT_SESSIONS_REQUEST]: logoutSessionsRequest,
  [Types.LOGOUT_SESSIONS_SUCCESS]: logoutSessionsSuccess,
  [Types.LOGOUT_SESSIONS_FAILURE]: logoutSessionsFailure,

  [Types.REVALIDATE_SESSION_SUCCESS]: revalidateSessionSuccess,

  [Types.CLEAN_FEEDBACK]: cleanFeedback,
  STORE_OAUTH_ACCESS_TOKEN_SUCCESS: loginOrRefreshTokenSuccess,
  [AuthActionTypes.REFRESH_TOKEN_SUCCESS]: loginOrRefreshTokenSuccess,

  [Types.LOGIN_OR_REFRESH_TOKEN_SUCCESS]: loginOrRefreshTokenSuccess,
}

export const UserSessionTypes = Types
export const UserSessionActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
