
import api from '../api';

const uploadFile = config => api.makeRequest(config);

const s3_presigned = (token, url, data) => api.privatePost(url, { token, data });

const s3_presigned_get = (token, url, params) => api.privateGet(url, { token, params });

export default {
  uploadFile,
  s3_presigned,
  s3_presigned_get
}
