import { put } from 'redux-saga/effects'
import { ImageTransformationActionCreators } from 'app/reducers/ImageTransformationRedux'
import { crm } from 'services/api/entities'

export function* transformImage({ originalUrl, automaticAdjustment }) {
  const response = yield crm.imageTransformation({ img_url:originalUrl, automaticAdjustment })

  if (response.success()) {
    return yield put(
        ImageTransformationActionCreators.imageTransformationSuccess(response.data.originialImgUrl, response.data.modifiedImgUrl),
    )
  }

  yield put(ImageTransformationActionCreators.imageTransformationFailure(response.data))
}