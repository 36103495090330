/**
 * This module works with hashed store to support multiple request
 * For example, if some view has the same info but from different dates
 * in different tables.
 *
 * Requirements:
 *    All request need fromDate and toDate, this values are strings.
 *
 *
 * Component example:
 *    useEffect(() => {
 *       dispatch(actionCreator(fromDate, toDate, params))
 *    }, [fromDate, toDate])
 *
 *    const first_table_results = useSelector(
 *       state => transactionSelector(state, fromDate, toDate)
 *    )
 */

import { createReducer, createActions } from 'reduxsauce'
import {
  requestFeedback,
  successFeedback,
  failureFeedback,
  addHashedState,
  updateHashedState,
} from 'reducers/utils'

import {
  createHashFromDates,
  BASE_MLM_TRANSACTIONS
} from 'selectors/Transactions'

const INITIAL_STATE = {
  transactions: {}
}

const { Types, Creators } = createActions(
  {
    getTransactionsRequest: ['balanceId', 'from', 'to', 'params'],
    getTransactionsSuccess: ['balanceId', 'from', 'to', 'count', 'transactions'],
    getTransactionsFailure: ['balanceId', 'from', 'to', 'errors']
  },
  { prefix: 'TRANSACTIONS_' }
)

const getTransactionsRequest = (state, { balanceId, from, to, params }) => ({
  ...state,
  transactions: addHashedState(
    state.transactions,
    createHashFromDates(balanceId, from, to),
    {
      ...BASE_MLM_TRANSACTIONS,
      params,
      feedback: requestFeedback()
    }
  )
})

const getTransactionsSuccess = (state, { balanceId, from, to, count, transactions }) => ({
  ...state,
  transactions: updateHashedState(
    state.transactions,
    createHashFromDates(balanceId, from, to),
    {
      count,
      transactions,
      feedback: successFeedback()
    }
  )
})

const getTransactionsFailure = (state, { balanceId, from, to, errors }) => ({
  ...state,
  transactions: updateHashedState(
    state.transactions,
    createHashFromDates(balanceId, from, to),
    { feedback: failureFeedback(errors) }
  )
})


const HANDLERS = {
  [Types.GET_TRANSACTIONS_REQUEST]: getTransactionsRequest,
  [Types.GET_TRANSACTIONS_SUCCESS]: getTransactionsSuccess,
  [Types.GET_TRANSACTIONS_FAILURE]: getTransactionsFailure,
}
export const TransactionsActionTypes = Types
export const TransactionsActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
