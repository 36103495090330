import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {}

const { Types, Creators } = createActions({
  saveAnswer: ['requirement', 'answer']
}, { prefix: 'BIN_LEAD_QUALITY_CHECK_' })

const saveAnswer = (state, { requirement, answer }) => {
  return state
}

const HANDLERS = {
  [Types.SAVE_ANSWER]: saveAnswer
}

export const BinLeadQualityCheckTypes = Types
export const BinLeadQualityCheckActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
