import { Button } from 'controls'
import { companyConfig } from 'globalConfigApp'

import styles from './styles'

export default [
  <article
    data-category="properties"
    data-title={`Me acabo de unir a ${companyConfig.name} ¿qué sucede con las propiedades que manejo actualmente?`}
    data-url="me-acabo-de-unir-a-neximo-que-sucede-con-las-propiedades-que-manejo-actualmente"
    className="wrapper-article"
  >
    <h4>
      Me acabo de unir a {companyConfig.name} ¿qué sucede con las propiedades que manejo
      actualmente?
    </h4>
    <div className="content">
      <p>
        Nuestro servicio de onboarding realizará una carga de sus propiedades a
        nuestro sistema. Todas estas propiedades se podrán vender bajo las
        condiciones comerciales acordadas con el cliente.
      </p>
      <p>
        Entendemos que es posible que no cuentes con un contrato de
        intermediacion. Queremos que puedas vender rápido con {companyConfig.name}. Por eso te
        ofrecemos esta flexibilidad. Después de esta primera carga, te pediremos
        un contrato de intermediación para cualquier propiedad que quisieras
        comercializar.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="properties"
    data-title="¿Por qué no puedo avanzar en el menú para subir mi propiedad?"
    data-url="por-que-no-puedo-avanzar-en-el-menu-para-subir-mi-propiedad"
    className="wrapper-article"
  >
    <h4>¿Por qué no puedo avanzar en el menú para subir mi propiedad?</h4>
    <div className="content">
      <p>
        Para avanzar en el menú al subir una propiedad debes asegurarte de tener
        todos los campos completos, de lo contrario no te deja pasar a la
        siguiente sección.
      </p>
      <p>
        Hemos creado un breve tutorial que puedes ver a continuación:{' '}
        <a href="https://www.youtube.com/watch?v=QiAInPAOQZA&t=6s">ver aquí</a>.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="properties"
    data-title="¿Por qué ha sido rechazada mi propiedad por las fotos?"
    data-url="por-que-ha-sido-rechazada-mi-propiedad-por-las-fotos"
    className="wrapper-article"
  >
    <h4>¿Por qué ha sido rechazada mi propiedad por las fotos?</h4>
    <div className="content">
      <p>
        Agregar fotos a tu anuncio es muy importante ya que establecen la
        credibilidad del mismo y además es la mejor forma de captar la atención
        de las personas que lo ven.
      </p>
      <p>Te damos algunos tips sobre las fotos de tus propiedades:</p>

      <ol>
        <li>Asegúrate de que son de buena calidad y de buen tamaño.</li>
        <li>
          Todas las fotos deben ser rotadas correctamente y en el orden en el
          que deseas que aparezcan.
        </li>
        <li>
          Evita fotos borrosas, de muy baja resolución o fotos invertidas.
        </li>
      </ol>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="properties"
    data-title="¿Por qué es necesario ingresar los datos del propietario?"
    data-url="por-que-es-necesario-ingresar-los-datos-del-propietario"
    className="wrapper-article"
  >
    <h4>¿Por qué es necesario ingresar los datos del propietario?</h4>
    <div className="content">
      <p>
        Nuestra misión es desarrollar los mejores asesores de {companyConfig.name} dándote las
        mejores herramientas y los mejores servicios. Parte de nuestra misión es
        ayudarte a brindar un servicio al cliente fenomenal. Nuestra plataforma
        automatiza la comunicación que tienes con el propietario para brindarles
        transparencia y seguridad en el manejo de su operación.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="properties"
    data-title="¿Qué documentos se necesitan para dar de alta una propiedad?"
    data-url="que-documentos-se-necesitan-para-dar-de-alta-una-propiedad"
    className="wrapper-article"
  >
    <h4>¿Qué documentos se necesitan para dar de alta una propiedad?</h4>
    <div className="content">
      <p>
        Queremos ofrecerte todas las herramientas necesarias para que te sea más
        fácil publicar una propiedad en {companyConfig.name} y en los demás portales, es por
        eso que una vez que damos de alta tu cuenta, te hacemos llegar vía
        correo electrónico una carta con la documentación requerida para
        publicar tus propiedades.
      </p>
      <p>
        También puedes solicitar apoyo en el módulo{' '}
        <span className="tag">
          Soporte administrativo > Otros > Carta de documentación
        </span>
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="properties"
    data-title="¿En qué parte debo subir los documentos?"
    data-url="en-que-parte-debo-subir-los-documentos"
    className="wrapper-article"
  >
    <h4>¿En qué parte debo subir los documentos?</h4>
    <div className="content">
      <p>
        Después de completar el proceso de subir una propiedad debes regresar a{' '}
        <span className="tag">Gestión de propiedades</span> y encontrarás un
        botón con el nombre de <span className="tag">Lazos</span> en la cual
        tendrás la gestión de los documentos de dicho inmueble.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="properties"
    data-title="¿Cómo subir mis documentos a lazos?"
    data-url="como-subir-mis-documentos-a-lazos"
    className="wrapper-article"
  >
    <h4>¿Cómo subir mis documentos a lazos?</h4>
    <div className="content">
      <p>
        Para subir tus documentos solo debes arrastrar desde tu equipo las
        imágenes o abrir los archivos y seleccionarlos, de esta manera nuestro
        equipo podrá verificarlos antes de aprobar tu propiedad.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="properties"
    data-title="¿Por qué no se encuentran publicadas mis propiedades?"
    data-url="por-que-no-se-encuentran-publicadas-mis-propiedades"
    className="wrapper-article"
  >
    <h4>¿Por qué no se encuentran publicadas mis propiedades?</h4>
    <div className="content">
      <p>
        Para conocer el motivo por el cual tu propiedad no ha sido publicada,
        debes ir a <span className="tag">Gestión de propiedades</span> y en cada
        una de estas encontrarás el status. Los motivos principales por los
        cuales no se aprueba una propiedad son los siguientes:
      </p>

      <ol>
        <li>Falta de documentación</li>
        <li>Descripción escasa de la propiedad</li>
        <li>Falta de fotos</li>
      </ol>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="properties"
    data-title={`Si decido retirarme voluntariamente, ¿qué pasará con mis propiedades
    obtenidas a través de ${companyConfig.name}?`}
    data-url="si-decido-retirarme-voluntariamente-que-pasara-con-mis-propiedades-obtenidas-a-traves-de-neximo"
    className="wrapper-article"
  >
    <h4>
      Si decido retirarme voluntariamente, ¿qué pasará con mis propiedades
      obtenidas a través de {companyConfig.name}?
    </h4>
    <div className="content">
      <p>
        Las propiedades que obtengas a través de {companyConfig.name} seguiran siendo del
        aliado. Existen dos casos donde no se podrán retirar propiedades:
      </p>

      <ol>
        <li>
          Tener adeudos con {companyConfig.name} y en ese caso la cuenta será suspendida.
        </li>
        <li>
          Propiedades bajo el estatuto de oferta ya que tendrán que seguir el
          proceso de venta a través de {companyConfig.name}.
        </li>
      </ol>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="properties"
    data-title={`Si algún cliente decide otorgar una propiedad en opción a más de un Aliado
    de ${companyConfig.name}, ¿Se podrá publicar dicha propiedad más de una vez? ¿Cómo
    funciona?`}
    data-url="si-algun-cliente-decide-otorgar-una-propiedad-en-opcion-a-mas-de-un-aliado-de-neximo-se-podra-publicar-dicha-propiedad-mas-de-una-vez-como-funciona"
    className="wrapper-article"
  >
    <h4>
      Si algún cliente decide otorgar una propiedad en opción a más de un Aliado
      de {companyConfig.name}, ¿Se podrá publicar dicha propiedad más de una vez? ¿Cómo
      funciona?
    </h4>
    <div className="content">
      <p>
        {companyConfig.name} cuenta con una bolsa inmobiliaria donde podrás encontrar todas
        las propiedades que comercializa. Sin embargo es posible que dos aliados
        comercialicen dos propiedades similares. {companyConfig.name} publica en su plataforma
        cada propiedad vinculada con un contrato de intermediación.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="properties"
    data-title="¿Puedo manejar propiedades por fuera de la red?"
    data-url="puedo-manejar-propiedades-por-fuera-de-la-red"
    className="wrapper-article"
  >
    <h4>¿Puedo manejar propiedades por fuera de la red?</h4>
    <div className="content">
      <p>
        No es posible ser parte de {companyConfig.name} y de cualquier otra inmobiliaria. Al
        pertenecer a nuestra inmobiliaria, aceptas comercializar todas tus
        operaciones con {companyConfig.name}.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="properties"
    data-title="¿Cuántas publicaciones puedo hacer por mes?"
    data-url="cuantas-publicaciones-puedo-hacer-por-mes"
    className="wrapper-article"
  >
    <h4>¿Cuántas publicaciones puedo hacer por mes?</h4>
    <div className="content">
      <p>No existe un límite de publicación de propiedades.</p>
      <Button color="primary" href="/dashboard/property-management" size="tiny">
        Publicar ahora
      </Button>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="properties"
    data-title="¿Qué pasa si una propiedad que obtengo no está en exclusiva?"
    data-url="que-pasa-si-una-propiedad-que-obtengo-no-esta-en-exclusiva"
    className="wrapper-article"
  >
    <h4>¿Qué pasa si una propiedad que obtengo no está en exclusiva?</h4>
    <div className="content">
      <p>
        No te preocupes en {companyConfig.name} se pueden publicar propiedades en Opción o en
        Exclusiva. Además, puedes publicar propiedades de otros corredores si
        cuentas con un contrato de colaboración con dicho corredor inmobiliario.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="properties"
    data-title="¿Cómo puedo comunicarme con otros asesores mediante la plataforma?"
    data-url="como-puedo-comunicarme-con-otros-asesores-mediante-la-plataforma"
    className="wrapper-article"
  >
    <h4>¿Cómo puedo comunicarme con otros asesores mediante la plataforma?</h4>
    <div className="content">
      <p>
        Tenemos dos opcionas para que puedad estar en contacto con otros
        asesores:
      </p>

      <ol>
        <li>
          Contamos con un directorio de asesores donde podrás encontrar su
          contacto y sus propiedades.
        </li>
        <li>
          Contamos con una bolsa inmobiliaria donde podrás acceder a las
          propiedades de nuestra red {companyConfig.name}.
        </li>
      </ol>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="properties"
    data-title={`¿Cómo puedo conocer toda la oferta de propiedades que tenemos en ${companyConfig.name}?`}
    data-url="como-puedo-conocer-toda-la-oferta-de-propiedades-que-tenemos-en-neximo"
    className="wrapper-article"
  >
    <h4>
      ¿Cómo puedo conocer toda la oferta de propiedades que tenemos en {companyConfig.name}?
    </h4>
    <div className="content">
      <p>
        Al ingresar a la página de {companyConfig.name}, en la pestaña{' '}
        <span className="tag">Nuestras propiedades</span> podrás encontrar las
        propiedades publicadas en nuestra plataforma.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="properties"
    data-title="¿Cómo funciona la sección Captación de propiedades?"
    data-url="como-funciona-la-seccion-captacion-de-propiedades"
    className="wrapper-article"
  >
    <h4>
      ¿Cómo funciona la sección <u>Captación de propiedades</u>?
    </h4>
    <div className="content">
      <p>
        Queremos ayudarte a ahorrar tiempo en tu búsqueda y prospección de
        inmuebles, ahora en la sección{' '}
        <span className="tag">Captación de propiedades</span> podrás encontrar
        una recopilación de inmuebles todos los días para ofrecer tus servicios
        como asesor inmobiliario.
      </p>
      <p>
        Dentro de este modulo podrás dar seguimiento adecuado a los prospectos
        hasta conseguir la propiedad y posteriormente al aceptarla, pasará
        automaticamente a tu módulo de
        <br />
        <span className="tag">Gestión de propiedades</span>.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="properties"
    data-title="¿Cómo subo una propiedad?"
    data-url="como-subo-una-propiedad"
    className="wrapper-article"
  >
    <h4>¿Cómo subo una propiedad?</h4>
    <div className="content">
      <p>
        Desde tu dashboard en la esquina superior izquierda esta el boton crear
        propiedad.
      </p>
      <p>
        <a href="https://youtu.be/UEWnSHtkRsw" target="_blank">
          Tutorial
        </a>
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="properties"
    data-title="¿Cuándo puedo subir una propiedad?"
    data-url="cuando-puedo-subir-una-propiedad"
    className="wrapper-article"
  >
    <h4>¿Cuándo puedo subir una propiedad?</h4>
    <div className="content">
      <p>
        Tienes que terminar los cursos de la semana 1 y semana 2 de la universidad
        {companyConfig.name}.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="properties"
    data-title="¿Cómo puedo hacer un estimado de valor?"
    data-url="como-puedo-hacer-un-estimado-de-valor"
    className="wrapper-article"
  > 
    <h4>¿Cómo puedo hacer un estimado de valor?</h4>
    <div className="content">
      <p>
        Desde la seccion estimado de valor en tu dashboard, aquí te dejamos un video tutorial:
      </p>
      <a href="https://youtu.be/gvZOnY6PFig">https://youtu.be/gvZOnY6PFig</a>
    </div>
    <style jsx>{styles}</style>
  </article>
]
