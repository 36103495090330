import styles from './styles'

export default [
  <article
    data-category="prospects"
    data-title="¿Por qué razón tengo agregados contactos en mi agenda que no son míos?"
    data-url="por-que-razon-tengo-agregados-contactos-en-mi-agenda-que-no-son-mios"
    className="wrapper-article"
  >
    <h4>
      ¿Por qué razón tengo agregados contactos en mi agenda que no son míos?
    </h4>
    <div className="content">
      <p>
        Queremos hacer que tu cuenta sea cada vez más fácil y rápida de usar, es
        por eso que cuando recibas mensajes de prospectos interesados en tus
        propiedades, estos se guardarán automaticamente en tu agenda.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="prospects"
    data-title="¿Cuál es la función de seguimiento de llamadas?"
    data-url="cual-es-la-funcion-de-seguimiento-de-llamadas"
    className="wrapper-article"
  >
    <h4>¿Cuál es la función de seguimiento de llamadas?</h4>
    <div className="content">
      <p>
        La función de Call tracking conecta vía telefónica a los interesados en
        las propiedades con los Neximers a través de un sistema que no revela el
        número telefónico real del asesor para proteger tus datos personales.
      </p>
      <p>
        Es decir que te asignaremos un nuevo número que se enlazará con tu
        celular para no perder registro de sus llamadas, las cuales serán
        grabadas y podrás escuchar y descargar.
      </p>
      <p>
        Así mismo quedará registro de las llamadas perdidas para no perder la
        oportunidad de contactar a futuros clientes.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="prospects"
    data-title="¿Registran todas mis llamadas personales?"
    data-url="registran-todas-mis-llamadas-personales"
    className="wrapper-article"
  >
    <h4>¿Registran todas mis llamadas personales?</h4>
    <div className="content">
      <p>
        Únicamente tenemos la posibilidad de registrar las llamadas entrantes
        del número que previamente te asignamos para fines de calidad en el
        servicio, no podemos realizar ningún resgistro de tus llamadas
        personales.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,
  <article
    data-category="prospects"
    data-title="¿Dónde puedo encontrar los leads que llegan a mi correo?"
    data-url="donde-puedo-encontrar-los-leads-que-llegan-a-mi-correo"
    className="wrapper-article"
  >
    <h4>¿Dónde puedo encontrar los leads que llegan a mi correo?</h4>
    <div className="content">
      <p>
        En tu dashboard en la seccion mis requerimientos
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>
]
