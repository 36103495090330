import React from 'react'
import classnames from 'classnames'

import { dateElementStyles } from './styles'

const DateElement = ({ currentMomentDate, onSelect, selectedDate }) => {
  const stringDate = currentMomentDate.startOf('day').toString()
  const isSelected = stringDate === selectedDate

  return (
    <button
      onClick={() => onSelect(stringDate)}
      className={classnames('date-element-container', {
        'is-selected': isSelected
      })}
    >
      <span className="date-element-name">
        {currentMomentDate.format('ddd')}
      </span>
      <span className="date-element-number">
        {currentMomentDate.format('D')}
      </span>

      <style jsx>{dateElementStyles}</style>
    </button>
  )
}

export default DateElement