import React from 'react'
import { IonIcon } from 'controls'
import classnames from 'classnames'
import styles from './styles'

const SelectedElement = ({
  isSelected,
  selected,
  children = null,
  onClick,
  fit,
  className = '',
  style = {},
  width,
  height,
  margin,
  padding
}) =>
  isSelected || selected ? (
    <button
      onClick={onClick}
      className={classnames('selected-element', className, { fit })}
      style={{ width, height, margin, padding, ...style }}
    >
      <IonIcon icon="md-checkmark" className="check-icon" size="small" />
      {children}
      <style jsx>{styles}</style>
    </button>
  ) : (
    <button
      onClick={onClick}
      className={classnames('not-selected-element', className, { fit })}
      style={{ ...style, width, height, margin }}
    >
      {children}
      <style jsx>{styles}</style>
    </button>
  )

export default SelectedElement
