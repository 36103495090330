import moment from 'moment'
import { emptyKeysToNull } from 'services/utils'

export const mapDataToSendRentQualify = (
  data = {},
  rentingRequirementsOpt = []
) => {
  const {
    additionalComment,
    extraComment,
    tentativeMovingDate,
    THREE_MONTHS,
    ENDORSEMENT,
    BAIL,
    SIGN_POLICY
  } = data

  const rentingRequirementsUser = {
    THREE_MONTHS,
    ENDORSEMENT,
    BAIL,
    SIGN_POLICY
  }

  const rentingRequirements = rentingRequirementsOpt.filter(({ requirement }) =>
    Object.keys(rentingRequirementsUser).find(
      key => rentingRequirementsUser[key] && key === requirement
    )
  )

  return {
    additionalComment,
    extraComment,
    tentativeMovingDate: moment(tentativeMovingDate).format('YYYY-MM-DD'),
    rentingRequirements
  }
}

export const mapDataToSentBuyQuality = (prequalify = {}) => {
  return emptyKeysToNull({
    seeker: null,
    status: null,
    purchasePlanningTime: null,
    mortgageCredit: null,
    otherMortgageCredit: null,
    creditValidation: null,
    additionalOptions: null,
    brokerOrigin: null,
    brokerIntentions: null,
    supportNeeded: null,
    meanOfCommunication: null,
    additionalComment: null,
    ...prequalify
  })
}

export const mapDataToFormRent = (data = {}) => {
  const { requirements = [], ...restData } = data

  const requirementsFields = requirements
    .map(({ requirement }) => requirement)
    .reduce(
      (acc, nameOfRequirement) => ({ ...acc, [nameOfRequirement]: 'true' }),
      {}
    )

  return {
    ...restData,
    ...requirementsFields
  }
}
