import { put, race, take } from 'redux-saga/effects'

import NewChildAgent from 'app/services/api/entities/NewChildAgent'
import {
  NewChildAgentActionCreators,
  NewChildAgentActionTypes,
} from 'app/reducers/NewChildAgentRedux'
import {
  pendingContractsSelector,
  summarySelector,
  contractSelector,
  subscriptionSelector,
} from 'app/selectors/NewChildAgent'

export function* summary({ brokerId }) {
  const response = yield NewChildAgent.summary(brokerId)

  if (response.success()) {
    return yield put(
      NewChildAgentActionCreators.summarySuccess(
        summarySelector(response.data),
      ),
    )
  }

  return yield put(NewChildAgentActionCreators.summaryFailure(response.errors))
}

export function* createSubscription({ brokerId }) {
  const response = yield NewChildAgent.suscription(brokerId)

  if (response.success()) {
    return yield put(
      NewChildAgentActionCreators.createSubscriptionSuccess(
        subscriptionSelector(response.data),
      ),
    )
  }

  return yield put(
    NewChildAgentActionCreators.createSubscriptionFailure(response.errors),
  )
}

export function* createContract({ brokerId, contractTypeId }) {
  const response = yield NewChildAgent.createContract(brokerId, contractTypeId)

  if (response.success()) {
    return yield put(
      NewChildAgentActionCreators.createContractSuccess(
        contractTypeId,
        contractSelector(response.data),
      ),
    )
  }

  return yield put(
    NewChildAgentActionCreators.createContractFailure(
      contractTypeId,
      response.data,
    ),
  )
}

export function* finishProcess({ brokerId }) {
  const response = yield NewChildAgent.finishProcess(brokerId)

  if (response.success()) {
    return yield put(NewChildAgentActionCreators.finishProcessSuccess())
  }

  return yield put(
    NewChildAgentActionCreators.finishProcessFailure(response.errors),
  )
}

export function* tryToFinishRegisteredProcess({ brokerId }) {
  /**
   * At this point the user/broker is created but it needs its subscription and contracts
   * This action tries to finish the process by creating the subscription and contracts
   * Contracts depend on the subscription
   * If any of the requests fails, the process is not finished and the UI should show the error
   */

  yield put(NewChildAgentActionCreators.summary(brokerId))

  const [summaryError, summarySuccess] = yield race([
    take(NewChildAgentActionTypes.SUMMARY_FAILURE),
    take(NewChildAgentActionTypes.SUMMARY_SUCCESS),
  ])

  if (summaryError) {
    return
  }

  if (summarySuccess.data.suscription.status === 'PENDING') {
    yield put(NewChildAgentActionCreators.createSubscription(brokerId))

    const [suscriptionError] = yield race([
      take(NewChildAgentActionTypes.CREATE_SUBSCRIPTION_FAILURE),
      take(NewChildAgentActionTypes.CREATE_SUBSCRIPTION_SUCCESS),
    ])

    if (suscriptionError) {
      return
    }
  }

  const contracts = pendingContractsSelector(summarySuccess.data.contracts)

  let errorAtContractCreation = false

  for (const contract of contracts) {
    yield put(
      NewChildAgentActionCreators.createContract(
        brokerId,
        contract.documentType,
      ),
    )
    const [error] = yield race([
      take(NewChildAgentActionTypes.CREATE_CONTRACT_FAILURE),
      take(NewChildAgentActionTypes.CREATE_CONTRACT_SUCCESS),
    ])
    if (error) {
      errorAtContractCreation = true
      break
    }
  }

  if (
    !errorAtContractCreation
  ) {
    if (!summarySuccess.data.isFinishedUserRegistrationProcess) {
      yield put(NewChildAgentActionCreators.finishProcess(brokerId))
    } else {
      yield put(NewChildAgentActionCreators.finishProcessSuccess())
    }
  }
}
