export default {
  name: 'tracingStats',
  namespace: 'stats',
  methods: [
    {
      name: 'closedDeals',
      params: ['id', 'year', 'month'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/crm/closed_deals/${id}/${namespace}/`,
      urlParams: ['id']
    },
    {
      name: 'descendants',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/mlm/descendants/${id}/${namespace}/`,
      urlParams: ['id']
    },
    {
      name: 'exclusive',
      params: ['id', 'year', 'month'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/exclusive_properties/${id}/${namespace}/`,
      urlParams: ['id']
    }
  ]
}

