import { createSelector } from 'reselect'
import { getObjValueByPath, mergeObjs } from 'services/object';
import { camelize } from 'services/string';
import { moneyParser, getCurrency } from 'components/Survey/Widgets/utils';
import { userSelector } from 'selectors/user';
import { allKeysToUpperSnakeCase } from 'app/services/utils'
import {
  isIntermediationContract,
  isOfferLetterContract,
  isCustomerRegistrationContract,
} from 'components/Contracts/constants'

const OPERATION_TYPE_LABELS = {
  SELL: 'VENTA',
  RENT: 'RENTA',
  SHARE: 'COMPARTIR'
}

const LAND_TYPES_LABELS = {
  RESIDENTIAL: 'HABITACIONAL',
  COMMERCIAL: 'COMERCIAL',
  MIXTO: 'MIXTO',
}

const PROPERTY_TYPE_LABELS = {
  APARTMENT: 'DEPARTAMENTO',
  HOUSE: 'CASA',
  LOTS: 'TERRENO',
  ROOMS: 'CUARTO',
  STORE: 'LOCAL',
  WAREHOUSE: 'BODEGA',
  OFFICE: 'OFICINA'
}


export const WIZARD_VIEWS = {
  QUESTIONS: 'QUESTIONS', // Questions section
  FEEDBACK: 'FEEDBACK' // Success save/update
}

export const wizardState = state => state.contracts.wizard

export const removeContractSelector = state => state.contracts.remove
export const removeFeedbackContractSelector = createSelector(
  removeContractSelector,
  ({ feedback }) => feedback,
)

export const requestSignaturesContractSelector = state => state.contracts.requestSignatures
export const requestSignaturesFeedbackContractSelector = createSelector(
  requestSignaturesContractSelector,
  ({ feedback }) => feedback,
)

export const cancelSignaturesContractSelector = state => state.contracts.cancelSignatures
export const cancelSignaturesFeedbackContractSelector = createSelector(
  cancelSignaturesContractSelector,
  ({ feedback }) => feedback,
)

export const parseOriginalContract = (originalContract, broker) => {
  const property =  originalContract.property || {}

  if (broker) {
    property.broker = mergeObjs(broker, property.broker)
  }

  return {
    ...originalContract,
    property,
    propertyId: property.id || originalContract.propertyId ||null,
  }
}

export const originalContractSelector = state => 
  parseOriginalContract(state.contracts.originalContract, userSelector(state))

export const contractFeedbackSelector = createSelector(
  originalContractSelector,
  originalContract => originalContract.feedback
)

export const originalContractIdSelector = createSelector(
  originalContractSelector,
  ({ contract }) => contract.id
)

export const draftContractSelector = state => state.contracts.draft
export const contractTypeSelector = state => state.contracts.contractType
export const isContractLayoutVersionUpdatedSelector = state => state.contracts.contractLayoutVersion.isUpdated
export const fillInitialValuesFeedback = state => state.contracts.fillInitialValuesFeedback 
export const getContractTypeSelector = createSelector(
  contractTypeSelector,
  (contractType) => ({ ...contractType.contractType, logo: null })
)

export const wizardSelector = createSelector(
  wizardState,
  contractTypeSelector,
  contractFeedbackSelector,
  draftContractSelector,
  fillInitialValuesFeedback,
  ({ view, saveFeedback }, contractType, contractFeedback, draft, fillInitialValuesFeedback) => ({
    view,
    saveFeedback,
    answers: draft.answers,
    contractType: contractType.contractType,
    loadingContractType: contractType.feedback.loading,
    loadingContract: contractFeedback.loading,
    fillingValues: fillInitialValuesFeedback.loading,
  })
)

export const previewSelector = createSelector(
  state => state.contracts,
  contractTypeSelector,
  ({ selectedProperty, originalContract }, { contractType }) => ({
    selectedProperty,
    url: originalContract.contract.url,
    contractType: contractType.identifier,
    contractName: originalContract.contract.name
  })
)

export const partialUpdateContractSelector = state =>
  state.contracts.partialUpdate

export const partialUpdateFeedbackSelector = createSelector(
  partialUpdateContractSelector,
  ({ feedback }) => feedback
)

export const saveDraftContractSelector = state => {
  const { selectedProperty, contractType } = state.contracts
  const { answers, progress } = state.contracts.draft

  return {
    progress,
    contractTypeId: contractType.contractType.id,
    propertyId: selectedProperty,
    name: answers.CONTRACT_NAME,
    status: 'DRAFT',
    values: answers
  }
}

export const saveContractSelector = createSelector(
  saveDraftContractSelector,
  draft => ({
    ...draft,
    status: 'DONE',
    progress: 100, // Progress should be saved as complete.
  })
)

export const normalizeContracValues = contract => ({
  ...contract,
  values: allKeysToUpperSnakeCase(contract.values)
})

const questionMetaToDotNotation = meta =>
  meta
    .split('__')
    .map(camelize)
    .join('.')

const AVAILABLE_FIELDS_TO_TRANSLATE = {
  last_revision__land_type: LAND_TYPES_LABELS,
  last_revision__property_type: PROPERTY_TYPE_LABELS,
  last_revision__operation_type: OPERATION_TYPE_LABELS,
}

/**
 * @function translateContractValues
 * @param {String} metaNameField - Comes from Contract identifier
 *                                 Ex: 'property__last_revision__property_type'
 * @param {Any} value 
 */
 function translateContractValues(metaNameField, value) {
  const LABELS = AVAILABLE_FIELDS_TO_TRANSLATE[metaNameField]

  if (LABELS) {
    return LABELS[value] || value;
  }

  return value
}

const AREA_VALUES = [
  'last_revision__surface_area',
  'last_revision__construction_area',
]

const PRICE_VALUES = [
  'last_revision__price',
]

const getAndParseValues = (property, meta) => {
  const value = getObjValueByPath(
    property,
    questionMetaToDotNotation(meta)
  )

  const translatedValue = translateContractValues(meta, value)

  if (AREA_VALUES.includes(meta)) {
    return (translatedValue || 0) + ' m2'
  }
  if (PRICE_VALUES.includes(meta)) {
    const currency = getCurrency(value?.currency)

    return `$ ${moneyParser(value?.amount)} ${currency}`
  }

  return translatedValue
}

const fillBroker = property => ({
  BROKER__FISICA__NOMBRE: getAndParseValues(property, 'broker__full_name'),
  BROKER__FISICA__RFC: getAndParseValues(property, 'broker__rfc'),
  BROKER__FISICA__EMAIL: getAndParseValues(property, 'broker__email'),
  BROKER__FISICA__TELEFONO: getAndParseValues(property, 'broker__primary_telephone'),
})

const fillPropertyValues = property => ({
  PROPERTY__DETAILS__DIRECCION: getAndParseValues(property, 'last_revision__address'),
  PROPERTY__DETAILS__PROPERTY_TYPE: getAndParseValues(property, 'last_revision__property_type'),
  PROPERTY__DETAILS__LAND_USE: getAndParseValues(property, 'last_revision__land_type'),
  PROPERTY__DETAILS__LAND_AREA: getAndParseValues(property, 'last_revision__surface_area'),
  PROPERTY__DETAILS__CONSTRUCTION_AREA: getAndParseValues(property, 'last_revision__construction_area')
})

const fillLandlordMatrix = property => ({
  LANDLORD_MATRIX: [
    {
      FISICA__NOMBRE: getAndParseValues(property, 'landlord_profile__full_name'),
      FISICA__EMAIL: getAndParseValues(property, 'landlord_profile__email'),
      FISICA__TELEFONO: getAndParseValues(property, 'landlord_profile__telephone')
    }
  ],
})

function fillCommonValues(property) {
  return {
    ...fillBroker(property),
    ...fillPropertyValues(property),
  }
}

function intermediationContractInitialValues(property) {
  return {
    ...fillLandlordMatrix(property),
    ...fillCommonValues(property)
  }
}

function offerLetterInitialValues(property) {
  return {
    ...fillBroker(property),

    PROPERTY__DETAILS__STREET: getAndParseValues(property, 'last_revision__street'),
    PROPERTY__DETAILS__STREET_NUMBER: getAndParseValues(property, 'last_revision__street_number'),
    PROPERTY__DETAILS__NEIGHBORHOOD: getAndParseValues(property, 'last_revision__neighborhood'),
    PROPERTY__DETAILS__DISTRICT: getAndParseValues(property, 'last_revision__district'),
    PROPERTY__DETAILS__STATE: getAndParseValues(property, 'last_revision__state'),
    PROPERTY__DETAILS__POSTAL_CODE: getAndParseValues(property, 'last_revision__postal_code'),
    PROPERTY__DETAILS__PRICE: getAndParseValues(property, 'last_revision__price'),
  }
}

function customerRegistrationInitialValues(property) {
  const propertyId = getAndParseValues(property, 'id');
  const nexId = propertyId && 'NEX-' + propertyId || "";

  return {
    BROKER__FISICA__NOMBRE: getAndParseValues(property, 'broker__full_name'),
    BROKER__FISICA__EMAIL: getAndParseValues(property, 'broker__email'),
    ATTACHEMENTS__CUSTOMER_REGISTRATION__PROPERTY: nexId,
  }
}

/**
 * @function fillInitialValuesSelector
 * @param {String} contractType - Like 'CONTRACT_OF_SALE', 'CONTRA_LEASE', etc.
 * @param {Object} property 
 * @returns 
 */
export const fillInitialValuesSelector = (contractType, property) => {
  if (isIntermediationContract(contractType)) {
    return intermediationContractInitialValues(property)
  }

  if (isOfferLetterContract(contractType)) {
    return offerLetterInitialValues(property)
  }

  if (isCustomerRegistrationContract(contractType)) {
    return customerRegistrationInitialValues(property)
  }

  return fillCommonValues(property)
}

