import { createReducer, createActions } from 'reduxsauce'
import {
  initialFeedback,
  requestFeedback,
  successFeedback,
  failureFeedback
} from './utils'

export const INITIAL_STATE = {
  createCloseDeal: initialFeedback(),
  createCloseDealStatus: initialFeedback(),
  typeClose: 'EXISTING_PROPERTY',
  previusSecctionClose: 'EXISTING_PROPERTY',
  selectedPropertyToDeal: {},
  closeDealData: {},
  createCloseDealData: {},
  legalSupportValues: {},
  contactTransaction: {},
}

const { Types, Creators } = createActions(
  {
    createCloseDeal: ['data'],
    createCloseDealSuccess: ['data'],
    createCloseDealFailure: ['errors'],
    finishedCreateCloseDeal: [],

    createCloseDealWithExternalAgent: ['data'],
    createCloseDealWithExternalAgentSuccess: ['data'],
    createCloseDealWithExternalAgentFailure: ['errors'],
    finishedCreateCloseDealWithExternalAgent: [],

    changeTypeClose: ['typeClose'],
    updateSelectedBinLeadToDeal: ['selectedPropertyToDeal'],
    requestLegalSupport: ['legalSupportValues'],

  updateContactTransaction: ['contactTransaction'],
    updateCloseDealData: [
      'closeDealData',
      'previusSecctionClose',
      'propertyDataToCloseDeal'
    ],

    cleanState: []
  },
  { prefix: 'BIN_CLOSE_DEAL_' }
)
const createCloseDeal = state => ({
  ...state,
  createCloseDealStatus: requestFeedback()
})
const createCloseDealSuccess = (state, { data }) => ({
  ...state,
  createCloseDealData: data,
  createCloseDealStatus: successFeedback()
})
const createCloseDealFailure = (state, { errors }) => ({
  ...state,
  createCloseDealStatus: failureFeedback(errors)
})

const finishedCreateCloseDeal = state => ({
  ...state,
  createCloseDealStatus: initialFeedback()
})

const createCloseDealWithExternalAgent = state => ({
  ...state,
  createCloseDealStatus: requestFeedback()
})
const createCloseDealWithExternalAgentSuccess = (state, { data }) => ({
  ...state,
  createCloseDealData: data,
  createCloseDealStatus: successFeedback()
})
const createCloseDealWithExternalAgentFailure = (state, { errors }) => ({
  ...state,
  createCloseDealStatus: failureFeedback(errors)
})

const finishedCreateCloseDealWithExternalAgent = state => ({
  ...state,
  createCloseDealStatus: initialFeedback()
})

const changeTypeClose = (state, { typeClose }) => ({
  ...state,
  typeClose
})

const requestLegalSupport = (state, { legalSupportValues }) => ({
  ...state,
  legalSupportValues
})

const updateSelectedBinLeadToDeal = (state, { selectedPropertyToDeal }) => ({
  ...state,
  selectedPropertyToDeal
})

const updateContactTransaction = (state, { contactTransaction }) => ({
  ...state,
  contactTransaction
})

const updateCloseDealData = (state, { closeDealData, previusSecctionClose, propertyDataToCloseDeal }) => ({
  ...state,
  closeDealData,
  propertyDataToCloseDeal,
  previusSecctionClose
})

const cleanState = () => INITIAL_STATE

const HANDLERS = {
  [Types.CREATE_CLOSE_DEAL]: createCloseDeal,
  [Types.CREATE_CLOSE_DEAL_SUCCESS]: createCloseDealSuccess,
  [Types.CREATE_CLOSE_DEAL_FAILURE]: createCloseDealFailure,
  [Types.FINISHED_CREATE_CLOSE_DEAL]: finishedCreateCloseDeal,

  [Types.CREATE_CLOSE_DEAL_WITH_EXTERNAL_AGENT]: createCloseDealWithExternalAgent,
  [Types.CREATE_CLOSE_DEAL_WITH_EXTERNAL_AGENT_SUCCESS]: createCloseDealWithExternalAgentSuccess,
  [Types.CREATE_CLOSE_DEAL_WITH_EXTERNAL_AGENT_FAILURE]: createCloseDealWithExternalAgentFailure,
  [Types.FINISHED_CREATE_CLOSE_DEAL_WITH_EXTERNAL_AGENT]: finishedCreateCloseDealWithExternalAgent,

  [Types.CHANGE_TYPE_CLOSE]: changeTypeClose,
  [Types.UPDATE_SELECTED_BIN_LEAD_TO_DEAL]: updateSelectedBinLeadToDeal,
  [Types.REQUEST_LEGAL_SUPPORT]: requestLegalSupport,

  [Types.UPDATE_CONTACT_TRANSACTION]: updateContactTransaction,
  [Types.UPDATE_CLOSE_DEAL_DATA]: updateCloseDealData,

  [Types.CLEAN_STATE]: cleanState
}

export const BinCloseDealActionTypes = Types

export const BinCloseDealActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
