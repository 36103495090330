import { put, select } from 'redux-saga/effects'

import { Location } from 'services/api/entities'
import { LocationActionCreators } from 'app/reducers/LocationRedux'
import {
  hasChildLocationsSelector,
  getLocationStateSelector,
  formatLocationsSelector,
  getLocationIdsSelector,
} from 'app/selectors/location'

function* avoidRequestIfChildLocations(location) {
  const states = yield select(state => state.location.states)

  const locationState = getLocationStateSelector(states, location)

  return hasChildLocationsSelector(locationState)
}

export function* states() {
  if (yield avoidRequestIfChildLocations()) {
    return yield put(LocationActionCreators.statesSkipRequest())
  }

  const response = yield Location.states()

  if (response.success()) {
    return yield put(
      LocationActionCreators.statesSuccess(
        formatLocationsSelector(response.data),
      ),
    )
  }

  return yield put(LocationActionCreators.statesFailure(response.errors))
}

export function* municipalities({ stateName }) {
  if (yield avoidRequestIfChildLocations({ stateName })) {
    return yield put(
      LocationActionCreators.municipalitiesSkipRequest(stateName),
    )
  }
  const { stateId } = yield select(state =>
    getLocationIdsSelector(state.location.states, { stateName }),
  )

  const response = yield Location.municipalities(stateId)

  if (response.success()) {
    return yield put(
      LocationActionCreators.municipalitiesSuccess(
        stateName,
        formatLocationsSelector(response.data),
      ),
    )
  }

  return yield put(
    LocationActionCreators.municipalitiesFailure(stateName, response.errors),
  )
}

export function* neighborhoods({ stateName, municipalityName }) {
  if (yield avoidRequestIfChildLocations({ stateName, municipalityName })) {
    return yield put(
      LocationActionCreators.neighborhoodsSkipRequest(
        stateName,
        municipalityName,
      ),
    )
  }

  const { stateId, municipalityId } = yield select(state =>
    getLocationIdsSelector(state.location.states, {
      stateName,
      municipalityName,
    }),
  )

  const response = yield Location.neighborhoods(stateId, municipalityId)

  if (response.success()) {
    return yield put(
      LocationActionCreators.neighborhoodsSuccess(
        stateName,
        municipalityName,
        formatLocationsSelector(response.data, { includePostalCode: true }),
      ),
    )
  }

  return yield put(
    LocationActionCreators.neighborhoodsFailure(
      stateName,
      municipalityName,
      response.errors,
    ),
  )
}

export function* getByFullLocation({
  stateName,
  municipalityName,
  neighborhoodName,
}) {
  yield states()
  yield municipalities({ stateName })
  yield neighborhoods({ stateName, municipalityName })
}