import { colors } from 'theme'
import css from 'styled-jsx/css'

export default css`
  .button.none.outline {
    background-color: ${colors.transparent};
    border: 0;
  }

  .button:not(.disabled):hover.none.outline, .button:not(.disabled):focus.none.outline {
    background-color: ${colors.transparent};
    border: 0;
  }
`