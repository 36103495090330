import { call, take, put, select } from 'redux-saga/effects'
import { OnboardingMaterials } from 'app/services/api/entities'

import { OnboardingMaterialsCreators } from 'app/reducers/OnboardingMaterialsRedux'

export function* getAll({ userId }) {
  let response

  if (userId) {
    response = yield OnboardingMaterials.getAllUserMaterials(userId)
  } else {
    response = yield OnboardingMaterials.getAll()
  }

  if (response.success()) {
    return yield put(OnboardingMaterialsCreators.getAllSuccess(response.data))
  }
  yield put(OnboardingMaterialsCreators.getAllFailure(response))
}

export function* updateMaterial({ materialId, updateInfo }) {
  const response = yield OnboardingMaterials.updateMaterial(materialId, updateInfo)

  if (response.success()) {
    return yield put(
      OnboardingMaterialsCreators.updateMaterialSuccess(
        materialId,
        response.data
      )
    )
  }
  yield put(
    OnboardingMaterialsCreators.updateMaterialFailure(materialId, response.data)
  )
}
