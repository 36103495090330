import ApiBase from 'app/services/api/entities/Base'

function* getStatus() {
  return yield ApiBase.privateGet(`/v1/user_onboarding_status/`)
}

function* getContracts(brokerId) {
  return yield ApiBase.privateGet(`/v1/neximer_documents/${brokerId}/documents/`)
}

export default {
  getStatus,
  getContracts,
}