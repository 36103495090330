import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { store } from 'services'

function useUserNetworkConfig(userId) {
  const dispatch = useDispatch()
  const { loading, response, error } = useSelector(state => store.networkConfig('user').selector(state))

  useEffect(
    () => {
      dispatch(store.networkConfig('user').action(userId))
    },
    [userId]
  )

  return {
    loading,
    error,
    ...response
  }
}

export default useUserNetworkConfig
