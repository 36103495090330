import css from 'styled-jsx/css'
import colors from 'theme/colors'


export default css`
  .button.info {
    background-color: ${colors.capriBlue};
    border-color: ${colors.capriBlue};

    transition: 100ms background-color, 100ms border-color;
  }

  .info {
    color: ${colors.white};

    transition: 100ms color;
  }

  .button:not(.disabled):hover.info, .button:not(.disabled):focus.info {
    background-color: ${colors.info};
    border-color: ${colors.infoBorder};
    color: ${colors.white};

    transition: 100ms background-color, 100ms border-color, 100ms color;
  }
`