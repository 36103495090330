import { colors } from 'theme'
import css from 'styled-jsx/css'

export default css`
  .button.primary {
    background-color: ${colors.primary};
    border-color: ${colors.primary};
    color: ${colors.white};
    transition: 100ms background-color, 100ms border-color, 100ms color;
  }

  .button.primary.variation {
    background-color: ${colors.transparent};
    border: 1px solid ${colors.white};
  }

  .button:not(.disabled):hover.primary, .button:not(.disabled):focus.primary {
    background-color: ${colors.darkPrimary};
    border-color: ${colors.darkPrimary};
    color: ${colors.white};

    transition: 100ms background-color, 100ms border-color, 100ms color;
  }
`