import crudAction from './actions'

export default {
  name: 'locationService',
  namespace: 'locationservice',
  methods: [
    {
      name: 'postalCodes',
      params: ['postalCode'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, postalCode }) => `/v1/${namespace}/postal_codes/${postalCode}/`,
      urlParams: ['postalCode']
    },
    {
      name: 'allFromPostalCode',
      params: ['postalCode'],
      successCode: 200,
      method: 'Get',
      url: () => '',
      urlParams: [],
      saga: false
    },
    {
      name: 'loadAddressOptions',
      params: ['postalCode'],
      successCode: 200,
      method: 'Get',
      url: () => '',
      urlParams: [],
      saga: false
    },
    {
      name: 'states',
      params: [],
      successCode: 200,
      method: 'Get',
      url: ({ namespace }) => `/v1/${namespace}/states/`,
      urlParams: []
    },
    {
      name: 'municipalities',
      params: ['stateId'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, stateId }) => `/v1/${namespace}/states/${stateId}/municipalities/`,
      urlParams: ['stateId']
    },
    {
      name: 'neighborhoods',
      params: ['stateId', 'municipalityId'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, stateId, municipalityId }) => `/v1/${namespace}/states/${stateId}/municipalities/${municipalityId}/neighborhoods/`,
      urlParams: ['stateId', 'municipalityId']
    },
    {
      name: 'find',
      params: ['locationId'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, locationId }) => `/v1/${namespace}/locations/${locationId}/`,
      urlParams: ['locationId']
    },
  ]
}
