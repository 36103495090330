import ApiBase from 'app/services/api/entities/Base'

const BASE_URL = "/v1/onboarding_materials/"

function* getAll(params = {}, requestOptions = {}) {
  return yield ApiBase.privateGet(BASE_URL, {
    ...requestOptions,
    params
  })
}

function* getAllUserMaterials(userId, params = {}, requestOptions = {}) {
  return yield ApiBase.privateGet(`/v1/brokers/${userId}/network_onboarding_materials/`, {
    ...requestOptions,
    params
  })
}

function* updateMaterial(materialId, data = {}, requestOptions = {}) {
  return yield ApiBase.privatePatch(`${BASE_URL}${materialId}/`, {
    ...requestOptions,
    data
  })
}

export default {
  getAll,
  getAllUserMaterials,
  updateMaterial,
}
