import crudAction from './actions'

export default {
  name: 'neximers',
  namespace: 'neximers',
  methods: [
    {
      name: 'validateNeximerData',
      params: ['data'],
      successCode: 200,
      method: 'Post',
      url: () => `/v1/validate_neximer_data/`,
      urlParams: [],
    },
    ...crudAction,
  ]
}
