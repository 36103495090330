// Expected format for FEATURE_FLAGS:
// FEATURE_FLAG_1_NAME::FEATURE_1_FLAG_DESCRIPTION::FEATURE_1_FLAG_ENABLED:::FEATURE_FLAG_2_NAME::FEATURE_FLAG_2_DESCRIPTION::FEATURE_FLAG_2_ENABLED

export function getFeatureFlagConfig(featureFlagsEnv) {
  const flags = featureFlagsEnv.split(':::')
  const featureFlags = {}

  for (const flag of flags) {
    const [name, description, enabled] = flag.split('::')
    featureFlags[name] = {
      description,
      isEnabled: enabled.toLowerCase() === 'true'
    }
  }
  return { featureFlags }
}
