import crudAction from './actions'

export default {
  name: 'landlordPropertyData',
  namespace: 'landlord_property_data',
  methods: [
    ...crudAction
  ]
}

