import config from 'config'

const PLANS = {
  FULL_SERVICE: config('FULL_SERVICE_CODE'),
  AFFILIATE: config('AFFILIATE_CODE'),
  LITE: config('LITE_CODE'),
  IN_HOUSE_FULL: config('IN_HOUSE_FULL_CODE'),
  IN_HOUSE_RENT: config('IN_HOUSE_RENT_CODE'),
  IN_HOUSE_SELL: config('IN_HOUSE_SELL_CODE'),
  IN_HOUSE_ACQUISITION: config('IN_HOUSE_ACQUISITION_CODE'),
}

const ALL = {
  PLANS
}

const NEXIMO_ADDRESS = 'Av. Insurgentes Sur 1079, interior 08W104, col. Noche Buena, Benito Juárez, C.P. 03720, CDMX.'
const NEXIMO_LAT = 19.381390616885923
const NEXIMO_LNG = -99.17670888465702
const NEXIMO_YEAR_CREATION = 2018

const NEXI_CREDITO_URL = 'https://credito.iadmexico.mx'
const HESTIA_URL = 'https://www.hestiaplp.com.mx'
const MY_PROPERTIES_URL = `${config('HOST_URL')}/dashboard/property-management`

export {
  ALL as default,
  PLANS,
  NEXIMO_ADDRESS,
  NEXIMO_LAT,
  NEXIMO_LNG,
  NEXIMO_YEAR_CREATION,
  NEXI_CREDITO_URL,
  HESTIA_URL,
  MY_PROPERTIES_URL,
}
