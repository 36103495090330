import config from 'config'

const COMPANY_THEME = config('COMPANY_THEME')

const i = parseInt,
  r = Math.round

const sbcRip = function(d) {
  const l = d.length,
    RGB = {}
  if (l > 9) {
    d = d.split(',')
    if (d.length < 3 || d.length > 4) return null
    ;(RGB[0] = i(d[0].slice(4))),
      (RGB[1] = i(d[1])),
      (RGB[2] = i(d[2])),
      (RGB[3] = d[3] ? parseFloat(d[3]) : -1)
  } else {
    if (l === 8 || l === 6 || l < 4) return null
    if (l < 6)
      d =
        '#' +
        d[1] +
        d[1] +
        d[2] +
        d[2] +
        d[3] +
        d[3] +
        (l > 4 ? d[4] + '' + d[4] : '')
    ;(d = i(d.slice(1), 16)),
      (RGB[0] = (d >> 16) & 255),
      (RGB[1] = (d >> 8) & 255),
      (RGB[2] = d & 255),
      (RGB[3] =
        l === 9 || l === 5 ? r((((d >> 24) & 255) / 255) * 10000) / 10000 : -1)
  }
  return RGB
}

export const shadeBlendConvert = (p, from, to) => {
  if (
    typeof p !== 'number' ||
    p < -1 ||
    p > 1 ||
    typeof from !== 'string' ||
    (from[0] !== 'r' && from[0] !== '#') ||
    (typeof to !== 'string' && typeof to !== 'undefined')
  )
    return null
  var h = from.length > 9,
    h =
      typeof to === 'string'
        ? to.length > 9
          ? true
          : to === 'c'
          ? !h
          : false
        : h,
    b = p < 0,
    p = b ? p * -1 : p,
    to = to && to !== 'c' ? to : b ? '#000000' : '#FFFFFF',
    f = sbcRip(from),
    t = sbcRip(to)
  if (!f || !t) return null
  if (h)
    return (
      'rgb(' +
      r((t[0] - f[0]) * p + f[0]) +
      ',' +
      r((t[1] - f[1]) * p + f[1]) +
      ',' +
      r((t[2] - f[2]) * p + f[2]) +
      (f[3] < 0 && t[3] < 0
        ? ')'
        : ',' +
          (f[3] > -1 && t[3] > -1
            ? r(((t[3] - f[3]) * p + f[3]) * 10000) / 10000
            : t[3] < 0
            ? f[3]
            : t[3]) +
          ')')
    )
  else
    return (
      '#' +
      (
        0x100000000 +
        (f[3] > -1 && t[3] > -1
          ? r(((t[3] - f[3]) * p + f[3]) * 255)
          : t[3] > -1
          ? r(t[3] * 255)
          : f[3] > -1
          ? r(f[3] * 255)
          : 255) *
          0x1000000 +
        r((t[0] - f[0]) * p + f[0]) * 0x10000 +
        r((t[1] - f[1]) * p + f[1]) * 0x100 +
        r((t[2] - f[2]) * p + f[2])
      )
        .toString(16)
        .slice(f[3] > -1 || t[3] > -1 ? 1 : 3)
    )
}

export const alpha = (hexColor, alpha) => {
  const rgb = hexToRgb(hexColor)
  if (rgb === null) {
    return colors.lightGray
  }

  const { r, g, b } = rgb

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

// Don't forget to add the color text to components/Layout/styles
const NEXIMO_COLORS = {
  primary: '#20C063',
  lightPrimary: '#dff0d8',
  secondary: '#3973E7',
  text: '#2A5082',
  lightText: '#54698d',

  borderInput: '#a8b7c7',
  darkGray: '#A8B7C7',
  gray: '#acb8cc',
  boxShadow: '#c5cfde',
  lightBorder: '#CFE1FF',
  border: '#D4E5FE',
  textBlueBackground: '#d9edf7',
  disabled: '#E0E5EE',
  dullGray: '#E6EBF1',
  tertiary: '#EFF1F5',
  lightGray: '#F5F7FA',
  lighterGray: '#F7F8FA',
  grayBackground: '#fbfbfb',
  lightBg: '#f4f6fa',
  white: '#FFFFFF',
  textBlueBright: '#3a87ad',

  altText: '#16325c',
  badge: '#F5A623',
  badgeYellow: '#fcf9e2',
  purple: '#9013FE',
  frame: '#19325C',
  terciary: '#5E23DC',
  black: '#000000',

  
  danger: '#E8011B',
  success: '#27AE60',
  warning: '#f7a700',
  lightWarning: '#FEE4A5',
  transparent: 'rgba(255, 255, 255, 0)',
  sell: '#5E23DC',
  rent: '#2A5082',
  darkGreen: '#26A257',
 
  highlightBlue: '#5B97F2',
  info: '#31B0D5',
  cautionOrange: '#FF6D4A',

  heroBg: '#04396B',
  outlineFrame: '#19325C',
  footerBg: '#28487d',
  

  alpha: alpha,
  facebook: '#3b5998',
  darkWarning: '#8b740a',
  dangerLow:'#FF4F64',
  warningLow:'#FFAB00',

  darkBlue: '#1A315F',
  indigo: '#294783',
  capriBlue: '#5979B9',
  babyBlue: '#8EA7DA',
  celestialBlue: '#BAD0FC',
  opaqueGrayBackground: '#e5f0ff85',
  loginText: "#54698d",

  yellow: '#ffdd57',
  brown: '#3b3108',
  dirtGray: '#999999',
  dirtGrayGradiant: "#717171",
  lightDullGray:"#ddd", 
  leatherBlack: "#373a3c",
  limeGreen: "#38c154",
  charcoalBlack: "#23241f",
  articBlue:"#9bcdff",
  anchorGray: "##34495e",
  steelGray: "##45595e",
  tartRed:"#E74C3C",
  crimsonRed:"#C9302C",
  candyRed:"#C12E2A",
  infoBorder: "#2AABD2",
  pickleGreen: "#1B9C51",
  apricotOrange: "#EC971F",
  apricotOrangeBorder: "#EB9316",
  merigoldOrange: "#F0AD4E",
  lavanderPink: "#fcdfe2",
  pineGreen: "#308c67",
  mintGreen: "#a3f2cf",
  wineRed: "#8b181b",
  chocolateBrown: "#2a1d16",
  lightBlueBG: '#E1F6FF',
  boxShadowGray: '#c5cfde',
}

const IAD_COLORS = {
  primary: "#FF7F31",
  lightPrimary: "#FEF3E9",
  secondary: "#0F6E94",
  text: "#121112",
  textBlueBackground: "#E1F6FF",
  textBlueBright: "#0F6E94",
  lightText: "#E1F6FF",
  altText: "#2A5082",
  badge: "#E6A23C",
  badgeYellow: "#FAECD8",
  border: "#e5e5e5",
  borderInput: "#e5e5e5",
  lightBorder: "#e5e5e5",
  purple: "#FF7F31",
  frame: "#0F6E94",
  terciary: "#0F9FD6",
  white: "#ffffff",
  black: "#000000",
  gray: "#BFBFBF",
  lightGray: "#FCFDFD",
  lighterGray: "#FCFDFD",
  darkGray: "#BFBFBF",
  dullGray: "#BFBFBF",
  grayBackground: "#ffffff",
  disabled: "#E0E5EE",
  danger: "#E6A23C",
  success: "#2CC84D",
  warning: "#E6A23C",
  lightWarning: "#FAECD8",
  transparent: 'rgba(255, 255, 255, 0)',
  sell: "#E1F6FF",
  rent: "#E1F6FF",
  darkGreen: "#E5722C",
  boxShadow: "rgba(0,0,0,.04)",
  highlightBlue: "#FF7F31",
  info: "#409EFF",
  cautionOrange: "#E6A23C",
  tertiary: "#E5E5E5",

  heroBg: "#ffffff",
  outlineFrame: "#E5E5E5",
  footerBg: "#0F6E94",
  lightBg: "#f6f6f6",

  alpha: alpha,
  facebook: "#3b5998",
  darkWarning: "#E6A23C",
  dangerLow: "#F56C6C",
  warningLow: "#E6A23C",

  darkBlue: "#0F6E94",
  indigo: "#0F6E94",
  capriBlue: "#0F9FD6",
  babyBlue: "#0F9FD6",
  celestialBlue: "#E1F6FF",
  opaqueGrayBackground: '#e5f0ff85',
  loginText: "#121112",
  
  yellow: '#ffdd57',
  brown: '#3b3108',
  dirtGray: '#999999',
  dirtGrayGradiant: "#717171",
  lightDullGray:"#ddd", 
  leatherBlack: "#373a3c",
  limeGreen: "#38c154",
  charcoalBlack: "#23241f",
  articBlue:"#9bcdff",
  anchorGray: "##34495e",
  steelGray: "##45595e",
  tartRed:"#E74C3C",
  crimsonRed:"#C9302C",
  candyRed:"#C12E2A",
  infoBorder: "#2AABD2",
  pickleGreen: "#1B9C51",
  apricotOrange: "#EC971F",
  apricotOrangeBorder: "#EB9316",
  merigoldOrange: "#F0AD4E",
  lavanderPink: "#fcdfe2",
  pineGreen: "#308c67",
  mintGreen: "#a3f2cf",
  wineRed: "#8b181b",
  chocolateBrown: "#2a1d16",
  boxShadowGray: '#c5cfde',
  lightBlueBG: '#f0faff',
}

export const iadProvitionalColors = {
  primary: "#FF7F31",
  lightPrimary: "#FEF3E9",
  secondary: "#0F6E94",
  text: "#121112",
  textBlueBackground: "#E1F6FF",
  textBlueBright: "#0F6E94",
  lightText: "#E1F6FF",
  altText: "#2A5082",
  badge: "#E6A23C",
  badgeYellow: "#FAECD8",
  border: "#e5e5e5",
  borderInput: "#e5e5e5",
  lightBorder: "#e5e5e5",
  purple: "#FF7F31",
  frame: "#0F6E94",
  terciary: "#0F9FD6",
  white: "#ffffff",
  black: "#000000",
  danger: '#E8011B',
  gray: "#BFBFBF",
  lightGray: "#FCFDFD",
  lighterGray: "#FCFDFD",
}

// Don't forget to add the color text to components/Layout/styles
const colors = COMPANY_THEME === "NEXIMO" ? NEXIMO_COLORS : IAD_COLORS 
colors.lightSecondary = shadeBlendConvert(0.1, colors.secondary)
colors.darkSecondary = shadeBlendConvert(-0.1, colors.secondary)
colors.darkPrimary = shadeBlendConvert(-0.1, colors.primary)
colors.darkFrame = shadeBlendConvert(-0.1, colors.frame)
colors.darkText = shadeBlendConvert(-0.35, colors.text)

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}

export default colors
