import axios from 'axios'
import qs from 'qs'
import config from 'config'


import removeUnwantedBody from './utils/removeUnwantedBody'


export const apiBaseUrl = config('API_BASE_URL')

export const defaultHeaders = {
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'application/json'  
   //'Cache-Control': 'no-cache'
   //'Access-Control-Allow-Origin': '*'
}

function catcher(error) {
  if (typeof error.response === 'object') {
    const errorResponse = error.response

    if (typeof error.response.data === 'object') {
      errorResponse.data.errorResponseStatus = errorResponse.status
    } else if (errorResponse.status) {
      errorResponse.data = {
        originalError: errorResponse.data,
        errorResponseStatus: errorResponse.status
      }
    }

    return Promise.resolve(errorResponse)
  }

  const errorMessage = typeof error.response === 'string' ? error.response : ''

  return Promise.resolve({
    status: 500,
    data: {
      timestamp: new Date().getTime(),
      status: 500,
      error: errorMessage,
      errorResponseStatus: 500,
      message: errorMessage || 'No message available'
    }
  })
}

/**
 * The request function, this calls axios
 * @param {any} context The method or the config to use
 * @param {string} endpoint
 * @param {object} params - query params
 * @param {object} data - request's body (only for post, put and patch)
 * @param {object} headers - additional headers
 * @returns {*|Promise|Promise.<T>|Promise<R>|Promise<U>}
 */
function makeRequest(context, endpoint, params, data, headers = {}) {
  if (typeof context === 'object') {
    return axios.request(removeUnwantedBody(context)).catch(catcher)
  }

  const config = {
    method: context,
    baseURL: apiBaseUrl,
    url: endpoint,
    data,
    params,
    paramsSerializer: function(params) {
       return qs.stringify(params, {arrayFormat: 'repeat'})
    },
    headers: { ...defaultHeaders, ...headers }
  }
  return axios.request(removeUnwantedBody(config)).catch(catcher)
}

function get(...args) {
  return makeRequest('get', ...args)
}

function post(...args) {
  return makeRequest('post', ...args)
}

function patch(...args) {
  return makeRequest('patch', ...args)
}

function privateRequest(method, endpoint, params, data, token, headers = {}) {
  const authHeader = token ? { 'Authorization': `JWT ${token}` } : {}

  return makeRequest(method, endpoint, params, data, {
    ...authHeader,
    ...headers
  })
}

/**
 * privateGet
 * @args endpoint, params, data, token
 * @return {[type]} [description]
 */
function privateGet(endpoint, { params = {}, data = {}, token, headers = {} }) {
  return privateRequest('get', endpoint, params, data, token, headers)
}

/**
 * privatePost
 * @args endpoint, params, data, token
 * @return {[type]} [description]
 */
function privatePost(
  endpoint,
  { params = {}, data = {}, token, headers = {} }
) {
  return privateRequest('post', endpoint, params, data, token, headers)
}

/**
 * privatePut
 * @args endpoint, params, data, token
 * @return {[type]} [description]
 */
function privatePut(endpoint, { params = {}, data = {}, token, headers = {} }) {
  return privateRequest('put', endpoint, params, data, token, headers)
}

/**
 * privatePut
 * @args endpoint, params, data, token
 * @return {[type]} [description]
 */
function privatePatch(endpoint, { params = {}, data = {}, token, headers = {} }) {
  return privateRequest('patch', endpoint, params, data, token, headers)
}

/**
 * privateDelete
 * @args endpoint, params, data, token
 * @return {[type]} [description]
 */
function privateDelete(
  endpoint,
  { params = {}, data = {}, token, headers = {} }
) {
  return privateRequest('delete', endpoint, params, data, token, headers)
}

export default {
  get,
  post,
  patch,
  privateGet,
  privatePost,
  privatePatch,
  privatePut,
  privateDelete,
  makeRequest // for custom calls
}
