import { createSelector } from 'reselect'
import { store } from 'services'

export const userToForm = userData => {
  if (!userData) {
    return {
      firstName: '',
      lastName: '',
      secondLastName: '',
      birthday: '',
      brokerType: '',
      currentCrm: ''
    }
  }

  return userData
}

export const mapDataProfile = data => ({
  ...data,
  country: data.country,
  curp: data.curp || ''
})

export const formToUser = formData => {
  if (!formData) {
    return null
  }
  const { values = {} } = formData
  return values
}

export const getNewUserApiData = formName =>
  createSelector(
    state => state.form[formName],
    formToUser
  )

export const getNewUserFormData = createSelector(
  state => state.auth,
  auth => userToForm(auth.profile)
)

export const userSelector = state => state.auth.profile

export const confirmVerificationCodeSuccessSelector = state => {
  const confirmCodeFeedback = store
    .confirmVerificationCode('user')
    .selector(state)

  return (
    !confirmCodeFeedback.loading &&
    !confirmCodeFeedback.error &&
    Boolean(confirmCodeFeedback.response)
  )
}
