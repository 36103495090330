import { fonts, fontSizes, getFont, measurements, colors } from 'app/theme'
import css from 'styled-jsx/css'

export default css`
  .progress-bar-container {
    height: 8px;
    width: 100%;
    border-radius: 5px;
    box-sizing: content-box !important;
    position: relative;
  }
  .progress-bar-container.bordered {
    border: 1px solid ${colors.secondary};
  }
  .progress-bar-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .progress-bar-container.small {
    height: 8px;
  }
  .progress-bar-container.medium {
    height: 1rem;
  }
  .progress-bar-container.large {
    height: 1.6rem;
  }

  .progress-bar-container.gray {
    background-color: ${colors.alpha(colors.darkGray, 0.3)};
  }

  .progress-bar-container.rounded,
  .progress-bar-container.rounded .indicator {
    border-radius: 1rem;
  }

  .indicator {
    background-color: ${colors.secondary};
    color: ${colors.white};
    height: 100%;
  }

  .progress-bar-container.primary {
    border-color: ${colors.primary};
  }

  .progress-bar-container.primary .indicator {
    background-color: ${colors.primary};
  }
`