import api from '../api'

const getContacts = (token, limit, offset) => {
  const params = {
    limit,
    offset
  }
  return api.privateGet('/v1/crm/contacts/', { token, params })
}

export default {
  getContacts
}
