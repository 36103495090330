import api from '../api'

const createReportBroker = (token, propertyId, data) =>
  api.privatePost(`/v1/properties/${propertyId}/property_complaints/`, {
    token,
    data
  })

const createReportPublic = (propertyId, data) =>
  api.post(`/v1/public/properties/${propertyId}/property_complaints/`, {}, data)

export default {
  createReportBroker,
  createReportPublic
}
