import React, { Component } from 'react'
import classnames from 'classnames'

import { IonIcon } from 'controls'
import Cards from 'react-credit-cards'

import styles, { deleteButton } from './styles'

class Card extends Component {
  render() {
    const { containerClassName = '', ...rest } = this.props
    return (
      <section className={classnames(containerClassName)}>
        <Cards {...rest} />
        <style jsx global>{styles}</style>
      </section>
    )
  }
}

export class CardWithDeleteButton extends Component {
  render() {
    const { containerClassName = '', onDelete, ...rest } = this.props
    return (
      <section className={classnames(containerClassName, 'with-delete-button')}>
        <div className="button-delete-wrapper">
          <IonIcon icon="android-delete" onClick={onDelete} size='large'/>
        </div>
        <Cards {...rest} />
        <style jsx global>{styles}</style>
        <style jsx>{deleteButton}</style>
      </section>
    )
  }
}

export default Card
