import colors from './colors'
import fonts, {
  fontSizes,
  getFont,
  fontSizesMobile,
  fontWeights,
  fontFamily,
} from './fonts'
import measurements from './measurements'
import BREAKPOINTS from './breakpoints'
import designatedColors from './designatedColors'

export {
  colors,
  fonts,
  measurements,
  fontSizes,
  fontFamily,
  getFont,
  fontSizesMobile,
  fontWeights,
  designatedColors,
  BREAKPOINTS
}
