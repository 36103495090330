import { createReducer, createActions } from 'reduxsauce'


const INITIAL_STATE = {
  bonds: {},

  loading: false,
  loadingError: null,
  loadingTask: false,

  creatingActor: false,
  createActorError: null,

  deletingActor: false,
  deleteActorError: false,
  documentCompleted: []
}

const getBondsRequest = state => ({
  ...state,
  loading: true
})


const getBondsSuccess = (state, { bonds }) => {

  const bondsObject = {}

  for(let bond of bonds.results) {
    bondsObject[bond.id] = bond
  }

  return {
    ...state,
    loading: false,
    bonds: bondsObject
  }
}

const getBondsFailure = (state, { error }) => ({
  ...state,
  loading: false,
  loadingError: error,
})



const getBondCompletedRequest = (state, { id }) => {
  return {
    ...state,
    loading: true
  }
}

const getBondCompletedSuccess = (state, { bond }) => {
  const documentCompleted = bond.documents
    .filter(d => d.shareCount > 0 && d.shares.reduce((mem, share) => mem && share.completedAt !== null,true))

  return {
    ...state,
    loading: false,
    bonds: {
      ...state.bonds,
      [bond.id]: bond
    },
    documentCompleted
  }
}

const getBondCompletedFailure = state => {
  return {
    ...state,
    loading: false
  }
}

const getBondRequest = (state, { id }) => {
  return {
    ...state,
    loading: true
  }
}

const getBondSuccess = (state, { bond }) => {
  return {
    ...state,
    loading: false,
    bonds: {
      ...state.bonds,
      [bond.id]: bond
    }
  }
}

const getBondFailure = state => {
  return {
    ...state,
    loading: false
  }
}

const addDocumentToBond = (state, { bondId, newDocument }) => {
  return {
    ...state,
    bonds: {
      ...state.bonds,
      [bondId]: {
        ...state.bonds[bondId],
        documents: [
          ... state.bonds[bondId].documents,
          newDocument
        ]
      }
    }
  }
}

const createActorRequest = (state, { bondId, fullName, email, role }) => {
  return {
    ...state,
    creatingActor: true
  }
}

const createActorSuccess = (state, { bondId, actor }) => {
  return {
    ...state,
    creatingActor: false,
    bonds: {
      ...state.bonds,
      [bondId]: {
        ...state.bonds[bondId],
        actors: [
          ...state.bonds[bondId].actors,
          actor
        ]
      }
    }
  }
}

const createActorFailure = (state, { error }) => {
  return {
    ...state,
    creatingActor: false,
    createActorError: error
  }
}


const deleteActorRequest = (state, { bondId, actorId }) => {
  return {
    ...state,
    deletingActor: true
  }
}

const deleteActorSuccess = (state, { bondId, actorId }) => {
  const actors = state.bonds[bondId].actors.filter(
    actor => actor.id !== actorId
  )
  const tasks = state.bonds[bondId].tasks.map(t => ({
    ...t,
    status: t.assignee === actorId ? 'CANCELLED' : t.status
  }))

  return {
    ...state,
    deletingActor: false,
    bonds: {
      ...state.bonds,
      [bondId]: {
        ...state.bonds[bondId],
        actors,
        tasks
      }
    }

  }
}

const deleteActorFailure = (state, { error }) => {
  return {
    ...state,
    deletingActor: false,
    deleteActorError: error
  }
}


const createTaskRequest = (state, { bondId, description, actorId, status }) => {
  return {
    ...state,
    loadingTask: true
  }
}

const createTaskSuccess = (state, { bondId, task }) => {
  return {
    ...state,
    loadingTask: false,
    bonds: {
      ...state.bonds,
      [bondId]: {
        ...state.bonds[bondId],
        tasks: [
          ...state.bonds[bondId].tasks,
          task
        ]
      }
    }
  }
}

const createTaskFailure = (state, { error }) => {
  return {
    ...state,
    loadingTask: false
  }
}

const updateTaskRequest = (...args) => {
  return createTaskRequest(...args)
}

const updateTaskSuccess = (state, { bondId, task }) => {
  const tasks = state.bonds[bondId].tasks.filter(t => t.id !== task.id)


  return {
    ...state,
    loadingTask: true,
    bonds: {
      ...state.bonds,
      [bondId]: {
        ...state.bonds[bondId],
        tasks: [
          ...tasks,
          task
        ]
      }
    }
  }
}

const updateTaskFailure = (...args) => {
  return createTaskFailure(...args)
}

const deleteTaskRequest = (...args) => {
  return createTaskRequest(...args)
}

const deleteTaskSuccess = (state, { bondId, taskId }) => {
  const tasks = state.bonds[bondId].tasks.filter(t => t.id !== task.id)

  delete tasks[taskid]

  return {
    ...state,
    loadingTask: true,
    bonds: {
      ...state.bonds,
      [bondId]: {
        ...state.bonds[bondId],
        tasks
      }
    }
  }
}

const deleteTaskFailure = (...args) => {
  return createTaskFailure(...args)
}



const { Types, Creators } = createActions({
  getBondsRequest: [''],
  getBondsSuccess: ['bonds'],
  getBondsFailure: ['error'],

  getBondRequest: ['id'],
  getBondSuccess: ['bond'],
  getBondFailure: ['error'],

  getBondCompletedRequest: ['id'],
  getBondCompletedSuccess: ['bond'],
  getBondCompletedFailure: ['error'],

  createActorRequest: ['bondId', 'fullName', 'email', 'role'],
  createActorSuccess: ['bondId', 'actor'],
  createActorFailure: ['error'],

  deleteActorRequest: ['bondId', 'actorId'],
  deleteActorSuccess: ['bondId', 'actorId'],
  deleteActorFailure: ['bondId', 'actorId', 'error'],

  addDocumentToBond: ['bondId', 'newDocument'],

  createTaskRequest: ['bondId', 'description', 'actorId'],
  createTaskSuccess: ['bondId', 'task'],
  createTaskFailure: ['error'],

  updateTaskRequest: ['bondId', 'taskId', 'data'],
  updateTaskSuccess: ['bondId', 'task'],
  updateTaskFailure: ['error'],

  deleteTaskRequest: ['bondId', 'taskId'],
  deleteTaskSuccess: ['bondId', 'taskId'],
  deleteTaskFailure: ['error'],


}, { prefix: 'BOND_'})


const HANDLERS = {
  [Types.GET_BONDS_REQUEST]: getBondsRequest,
  [Types.GET_BONDS_SUCCESS]: getBondsSuccess,
  [Types.GET_BONDS_FAILURE]: getBondsFailure,

  [Types.GET_BOND_REQUEST]: getBondRequest,
  [Types.GET_BOND_SUCCESS]: getBondSuccess,
  [Types.GET_BOND_FAILURE]: getBondFailure,

  [Types.GET_BOND_COMPLETED_REQUEST]: getBondCompletedRequest,
  [Types.GET_BOND_COMPLETED_SUCCESS]: getBondCompletedSuccess,
  [Types.GET_BOND_COMPLETED_FAILURE]: getBondCompletedFailure,

  [Types.CREATE_ACTOR_REQUEST]: createActorRequest,
  [Types.CREATE_ACTOR_SUCCESS]: createActorSuccess,
  [Types.CREATE_ACTOR_FAILURE]: createActorFailure,

  [Types.DELETE_ACTOR_REQUEST]: deleteActorRequest,
  [Types.DELETE_ACTOR_SUCCESS]: deleteActorSuccess,
  [Types.DELETE_ACTOR_FAILURE]: deleteActorFailure,

  [Types.CREATE_TASK_REQUEST]: createTaskRequest,
  [Types.CREATE_TASK_SUCCESS]: createTaskSuccess,
  [Types.CREATE_TASK_FAILURE]: createTaskFailure,

  [Types.UPDATE_TASK_REQUEST]: updateTaskRequest,
  [Types.UPDATE_TASK_SUCCESS]: updateTaskSuccess,
  [Types.UPDATE_TASK_FAILURE]: updateTaskFailure,

  [Types.DELETE_TASK_REQUEST]: deleteTaskRequest,
  [Types.DELETE_TASK_SUCCESS]: deleteTaskSuccess,
  [Types.DELETE_TASK_FAILURE]: deleteTaskFailure,


  [Types.ADD_DOCUMENT_TO_BOND]: addDocumentToBond
}


export const BondActionTypes = Types

export const BondActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
