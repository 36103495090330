/**
 * @readonly
 * @enum {string}
 */
export const ActorType = {
  BROKER: 'BROKER_IAD',
  BROKER_PM: 'BROKER_PM', // Product Manager
  EXTERNAL: 'BROKER_EXTERNAL',
  EXTERNAL_PM: 'BROKER_EXTERNAL_PM', // Product Manager
  REFERRAL: 'REFERED'
  // RECO_IAD: 'BROKER_RECO_IAD', // It is the same as 'REFERED'
}

// TODO: PM actors are not used right now, it will be used in the future, in
// deals 2.0
export const DEAL_ACTOR_OPTIONS = [
  {
    label: 'Asesor',
    value: ActorType.BROKER
  },
  // {
  //   label: 'Asesor PM',
  //   value: ActorType.BROKER_PM
  // },
  {
    label: 'Asesor Externo',
    value: ActorType.EXTERNAL
  },
  // {
  //   label: 'Asesor PM Externo',
  //   value: ActorType.EXTERNAL_PM
  // },
  {
    label: 'Referido/Recomendado',
    value: ActorType.REFERRAL
  }
]

/**
 * @readonly
 * @enum {string}
 */
export const ReferalSource = {
  PROPERTY: 'PROPERTY',
  CONTACT: 'CONTACT',
}


/**
 * @readonly
 * @enum {string}
 */
export const DEAL_SHARED_TYPE = {
  ALONE: 'ALONE',
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
  // MIXED: 'MIXED', // It will be used in the future, in deals 2.0
}

export const DEAL_SHARED_TYPE_LABELS = {
  [DEAL_SHARED_TYPE.ALONE]: 'Solo',
  [DEAL_SHARED_TYPE.INTERNAL]: 'Entre neximers',
  [DEAL_SHARED_TYPE.EXTERNAL]: 'Externo',
  // [DEAL_SHARED_TYPE.MIXED]: 'Mixto',
}