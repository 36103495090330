import crudAction from '../actions'

export default {
  name: 'crmCloseDeal',
  namespace: 'crm/closings',
  methods: [
    ...crudAction,
    {
      name: 'closedDeals',
      params: ['closed_at_after', 'closed_at_before'],
      successCode: 200,
      method: 'Get',
      url: () =>
        `/v1/crm/successfully_closed_deals/`,
      urlParams: []
    }
  ]
}
