import { REQUEST_CONTRACTS_TYPES } from 'services/seo'

const REQUEST_CONTRACTS_TYPES_VALUES = REQUEST_CONTRACTS_TYPES.map(
  ({ value }) => value
)

export const mapDataToSend = values => {
  const {
    commercialTerms,
    requiredContract,
    documentType,
    term,
    contracts,
    documents,
    validUntil,
    ...rest
  } = values

  const finalValues = {
    documents,
    contracts,
    term: !isNaN(term) ? Number(term) : 0,
    requiredContract: JSON.parse(requiredContract),
    commercial_terms: commercialTerms,
    document_type: documentType,
    validUntil: validUntil || null
  }

  finalValues.contracts = contracts.map((contract) => ({
    ...contract,
    validUntil
  }))
  const newInterContractIndex = contracts.findIndex(({ id }) => id === 0)

  if (newInterContractIndex !== -1) {
    finalValues.contracts[newInterContractIndex] = {
      ...finalValues.contracts[newInterContractIndex],
      nameId: documentType,
      validUntil: validUntil
    }
  }

  return finalValues
}

export const mapDocumentOptions = (options = [], categoties = [] ,operationType) =>
  options
    .filter(({ operation }) => operation.includes(operationType))
    .filter(({ category }) => categoties.includes(category.nameId))
    .map(opt => ({
      label: opt.name,
      value: opt.id,
      fileName: opt.name,
      documentTypeId: opt.id,
      ...opt
    }))

export const mapPropertyDocumentationOptions = ({ initialValues, documentOptions }) =>
  mapDocumentOptions(documentOptions, ['PROPERTY_DOCS', 'OWNER_DOCS'], initialValues && initialValues.operationType)

export const mapInitialValuesToPropertyDoc = (initValues) => {
  const initialValues = {
    requiredContract: 'true',
    ...initValues,
  }
  if(initValues && typeof initValues.term === 'undefined') {
    initialValues.term = 12
  }

  initialValues.requiredContract = JSON.stringify(
    JSON.parse(initialValues.requiredContract)
  )

  if (initialValues.documentType && !getIntermediationContract(initialValues.contracts)) {
    /**
     * TODO Find a better way to do this:
     * When a user creates a new property and go to upload legal documents,
     * there isn't a contract type selected (such a 'INTERMEDIATION' or 'COLLABORATION')
     * for that reason we created this ugly contract whit id 0.
     */

    initialValues.contracts = [
      ...initialValues.contracts,
      {
        id: 0,
        url: [],
        nameId: initialValues.documentType
      }
    ]
  }

  return initialValues
}

export const getIntermediationContract = contracts => {
  return (
    contracts &&
    contracts.find(({ nameId }) =>
      REQUEST_CONTRACTS_TYPES_VALUES.includes(nameId)
    )
  )
}

export const getReqAndOptDocs = documents => {
  // Filter for both sections
  const currentRequiredDocuments = documents.filter(
    ({ highlightRequired }) => highlightRequired
  )
  const currentOptionalDocuments = documents.filter(
    ({ highlightRequired }) => !highlightRequired
  )

  return { currentRequiredDocuments, currentOptionalDocuments }
}

export const getInitialSelectedDocuments = ({ initialValues, documentOptions }) => {
  const currentDocuments = (initialValues && initialValues.documents) || []
  const { validUntil = null } = initialValues

  const interContract = (initialValues.contracts || []).find(({ nameId }) =>
    REQUEST_CONTRACTS_TYPES_VALUES.includes(nameId)
  ) || { validUntil: null, url: [] }

  if (validUntil) {
    interContract.validUntil = validUntil
  }
  
  const allDocumentOptions = mapPropertyDocumentationOptions({ initialValues, documentOptions })
  const { currentRequiredDocuments, currentOptionalDocuments } = getReqAndOptDocs(currentDocuments)

  return { allDocumentOptions, interContract, currentRequiredDocuments, currentOptionalDocuments }
}

export const propertyFormToPropertyDocumentation = property => {
  const {
    id,
    operationType,
    propertyType,
    saleCommissionDays,
    saleCommissionPercentage,
    saleType
  } = property || {}

  return {
    id,
    operationType,
    propertyType,
    saleCommissionDays,
    saleCommissionPercentage,
    saleType
  }
}
