import { createReducer, createActions } from 'reduxsauce'
import { hideAssistedRegistration} from 'app/constants/newChildAgent.js'

import {
  failureFeedback,
  initialFeedback,
  requestFeedback,
  successFeedback,
  updateHashedState,
} from './utils'

const INITIAL_STATE = {
  status: {
    steps: {
      documents: false,
      paymentSubscription: false,
      mailboxConfigured: false,
      paymentSignupFee: false,
      passwordChange: false,
    },
    isAssistedRegistration: hideAssistedRegistration,
    isActivated: false,
    /**
     * It's false when the broker is registered by another broker
     * If false and paymentSignupFee as null the UI should
     * show paymentSignupFee step as done
     */
    initialized: false, // It changes at the first request and it's using for avoid show loading
    feedback: initialFeedback(),
  },
  contracts: {
    documents: [],
    initialized: false, // It changes at the first request and it's using for avoid show loading
    feedback: initialFeedback(),
  },
}

const { Types, Creators } = createActions(
  {
    status: [],
    statusSuccess: ['steps', 'isAssistedRegistration', 'isActivated'],
    statusFailure: ['errors'],
    statusClearState: [],
    statusPartialUpdate: ['stepStatus'],
    activatedPartialUpdate: ['isActivated'],

    contracts: ['brokerId'],
    contractsSuccess: ['documents'],
    contractsFailure: ['errors'],
    contractsClearState: [],
  },
  { prefix: 'USER_ONBOARDING_' },
)

const status = state =>
  updateHashedState(state, 'status', {
    feedback: requestFeedback(),
  })

const statusSuccess = (
  state,
  { steps, isAssistedRegistration, isActivated },
) => ({
  ...state,
  status: {
    isAssistedRegistration,
    isActivated,
    steps: {
      ...state.status.steps,
      ...steps,
    },
    initialized: true,
    feedback: successFeedback(),
  },
})

const statusFailure = (state, { errors }) =>
  updateHashedState(state, 'status', {
    initialized: true,
    feedback: failureFeedback(errors),
  })

const statusClearState = state =>
  updateHashedState(state, 'status', INITIAL_STATE.status)

const statusPartialUpdate = (state, { stepStatus }) => ({
  ...state,
  status: {
    ...state.status,
    steps: {
      ...state.status.steps,
      ...stepStatus,
    },
  },
})

const activatedPartialUpdate = (state, { isActivated }) => ({
  ...state,
  status: {
    ...state.status,
    isActivated,
  },
})

const contracts = state =>
  updateHashedState(state, 'contracts', {
    feedback: requestFeedback(),
  })

const contractsSuccess = (state, { documents }) => ({
  ...state,
  contracts: {
    documents: documents,
    initialized: true,
    feedback: successFeedback(),
  },
})

const contractsFailure = (state, { errors }) =>
  updateHashedState(state, 'contracts', {
    initialized: true,
    feedback: failureFeedback(errors),
  })

const contractsClearState = state =>
  updateHashedState(state, 'contracts', INITIAL_STATE.contracts)

const HANDLERS = {
  [Types.STATUS]: status,
  [Types.STATUS_SUCCESS]: statusSuccess,
  [Types.STATUS_FAILURE]: statusFailure,
  [Types.STATUS_CLEAR_STATE]: statusClearState,
  [Types.STATUS_PARTIAL_UPDATE]: statusPartialUpdate,
  [Types.ACTIVATED_PARTIAL_UPDATE]: activatedPartialUpdate,
  [Types.CONTRACTS]: contracts,
  [Types.CONTRACTS_SUCCESS]: contractsSuccess,
  [Types.CONTRACTS_FAILURE]: contractsFailure,
  [Types.CONTRACTS_CLEAR_STATE]: contractsClearState,
}

export const UserOnboardingActionTypes = Types

export const UserOnboardingActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
