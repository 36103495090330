import crudAction from './actions'

export default {
  name: 'rfc',
  namespace: 'rfc',
  methods: [
    {
      name: 'filter',
      params: ['data'],
      successCode: 200,
      method: 'Get',
      url: () => '/v1/rfc_agent_register/',
      urlParams: []
    },
  ]
}