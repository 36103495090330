import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import SEOLink from 'components/SEO/SEOLink'
import classnames from 'classnames'
import styles from './styles'

class Link extends PureComponent {
  static propTypes = {
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    className: PropTypes.string,
    icon: PropTypes.any,
    useNext: PropTypes.bool,
    children: PropTypes.any
  }

  static defaultProps = {
    href: '',
    className: '',
    children: '',
    useNext: true,
  }

  render() {
    return (
      <SEOLink href={this.props.href} as={this.props.as} useNext={this.props.useNext}>
        <a className={classnames(this.props.className, 'link')}>
          <span>
            {this.props.icon && (
              this.props.icon
            )}
            {this.props.children}
          </span>
          <div className="underline" />
          <style jsx>{styles}</style>
        </a>
      </SEOLink>
    )
  }
}

export default Link
