import { checkFeatureFlag } from 'config'
import { initialFeedback } from 'reducers/utils'

export const INITIAL_PERMISSION_STATE = {
  isAllowed: !checkFeatureFlag("new-comercial-plan"), // All should be allowed if feature is off
  error: null,
  feedback: initialFeedback()
}

export const contractVersionPermissionSelector = (state, permission) => {
  if (checkFeatureFlag("new-comercial-plan")) {
    return state.contractVersion.permissions[permission] || INITIAL_PERMISSION_STATE
  }

  return INITIAL_PERMISSION_STATE
}

export const urlForContractSelector = state => {
  return state.contractVersion.urlForContract
}
