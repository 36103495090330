import { companyConfig } from 'globalConfigApp'

import styles from './styles'

export default [
  <article
    data-category="neximers"
    data-title={`¿Cuento con algún comprobante al ser Socio ${companyConfig.name}?`}
    data-url="cuento-con-algun-comprobante-al-ser-socio-neximo"
    className="wrapper-article"
  >
    <h4>¿Cuento con algún comprobante al ser Socio {companyConfig.name}?</h4>
    <div className="content">
      <p>
        Si podrás contar con un comprobante. Te invitamos a acercarte a nuestro
        equipo legal a traves de tu módulo administrativo.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="neximers"
    data-title={`¿Es necesario estar dado de alta en hacienda para entrar a ${companyConfig.name}?`}
    data-url="es-necesario-estar-dado-de-alta-en-hacienda-para-entrar-a-neximo"
    className="wrapper-article"
  >
    <h4>¿Es necesario estar dado de alta en hacienda para entrar a {companyConfig.name}?</h4>
    <div className="content">
      <p>
        {companyConfig.name} es una inmobiliaria digital que desarrolla a los mejores asesores
        de México. De la misma manera nos aseguramos de trabajar de manera
        profesional. Todos los ingresos de comisión generados por {companyConfig.name} están
        declarados ante las autoridades fiscales mexicanas, es por ello que
        requerimos que nuestros aliados estén dados de alta para poder cobrar
        sus comisiones.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="neximers"
    data-title={`¿Cuáles son las herramientas con las que cuento al entrar a ${companyConfig.name}?`}
    data-url="cuales-son-las-herramientas-con-las-que-cuento-al-entrar-a-neximo"
    className="wrapper-article"
  >
    <h4>¿Cuáles son las herramientas con las que cuento al entrar a {companyConfig.name}?</h4>
    <div className="content">
      <p>
        {companyConfig.name} te ofrece una serie de herramientas para realizar tu trabajo.
      </p>
      <ul>
        <li>
          A través de nuestra plataforma, puedes manejar toda tu actividad
          inmobiliaria. Tu paquete incluye varios servicios que podrás ver{' '}
          <u>
            <a
              href="https://www.youtube.com/watch?v=b1LevIteBr4"
              target="_blank"
            >
              aquí
            </a>
          </u>
          .
        </li>
        <li>
          <p>
            Te ofrecemos herramientas para que puedas brindar el mejor servicio
            a tus clientes propietarios y compradores:
          </p>
          <ol>
            <li>Tarjeta Digital.</li>
            <li>Tu presentación de servicios para captar propiedades.</li>
            <li>Tus contratos de Servicio.</li>
            <li>Tu carpeta de evidencia.</li>
          </ol>
        </li>
      </ul>
    </div>

    <style jsx>{styles}</style>
  </article>,

  <article
    data-category="neximers"
    data-title="¿Cómo puedo solicitar la certificación AMPI?"
    data-url="como-puedo-solicitar-la-certificacion-ampi"
    className="wrapper-article"
  >
    <h4>¿Cómo puedo solicitar la certificación AMPI?</h4>
    <div className="content">
      <p>
        {companyConfig.name} quiere desarrollar a los mejores asesores de México. Por ello te
        ofrecemos más de 300 horas de capacitación en línea. Nuestro programa de
        capacitación te ofrece también más de 50 horas por año de capacitación
        presencial y una certificación. Nuestra certificación (EC110 de AMPI).
      </p>
      <p>
        Puedes realizar tu certificación después de atender a nuestra
        capacitación de evaluación y de calificar por esta misma.
      </p>
    </div>
    <style jsx>{styles}</style>
  </article>
]
