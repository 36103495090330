/**
 * Created by neto on 2/21/17.
 */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import style from './styles'

class CheckBox extends PureComponent {
  static propTypes = {
    checked: PropTypes.bool,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.any,
    id: PropTypes.string
  }

  render() {
    const { checked, readOnly, onChange, label, id, ...rest } = this.props
    return (
      <label className={style} htmlFor={id} style={{ cursor: 'pointer' }}>
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={onChange}
          readOnly={readOnly}
          {...rest}
        />
        {label}
      </label>
    )
  }
}

export class CheckboxForm extends PureComponent {
  static propTypes = {
    currentValue: PropTypes.any
  }

  constructor(props) {
    super(props)
    const { checkboxValue, checked } = props
    this.state = {
      checkboxValue: checkboxValue || null,
      checked: checked || false
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.currentValue !== nextProps.currentValue) {
      this.props.input.onChange(nextProps.currentValue)
    }
  }

  onChange = e => {
    e.preventDefault()
    const nextChecked = !this.state.checked
    this.setState({
      checked: nextChecked
    })
    if (nextChecked) {
      this.props.input.onChange(this.state.checkboxValue)
    } else {
      this.props.input.onChange(null)
    }
  }

  render() {
    const { checkboxValue, checked: cProps, ...rest } = this.props
    const { checked } = this.state
    return <CheckBox {...rest} checked={checked} onChange={this.onChange} />
  }
}

export default CheckBox
