import css from 'styled-jsx/css'
import { colors, fonts, fontSizes, fontWeights, measurements } from 'theme'

const checkbox = css`
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding: 0.5rem 0 0.5rem 2rem;
  cursor: pointer;
  font-size: ${fontSizes.body};
  user-select: none;
}

label.container.small {
  font-size: ${fontSizes.p};
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0.75rem;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background-color: ${colors.white};
  border: 1px solid ${colors.text};
  transition: 200ms ease background, 200ms ease border;
}
.container.disabled input ~ .checkmark {
  background-color: ${colors.gray};
  border: none;
}


label.container.small .checkmark {
  top: 0.6rem;
}

input[type="radio"] ~ .checkmark {
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: ${colors.primary};
  border: none;
}

.container.disabled:hover input ~ .checkmark {
  cursor: not-allowed;
  background-color: ${colors.gray};
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: ${colors.primary};
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 1px;
  top: 1px;
  width: 14px;
  height: 14px;
  border: 2px solid ${colors.white};
}
/* Radio Buttons */
.container input[type="radio"] ~ .checkmark:after {
  border-radius: 50%;
}
`

const container = css`
:global(.radio-button-card) {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: column;
  border: 1px solid ${colors.articBlue};
  padding: 1rem 2rem 1rem 1rem;
  border-radius: 5px;
  width: fit-content;
}
:global(.radio-button-card label) {
  font-size: 10px;
}
:global(.radio-button-card p) {
  margin: 0.5rem 0;
}
.placeholder {
  width: 100%;
}
.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.error {
  color: ${colors.danger}
}
.container > :global(p) {
  font-size: ${fontSizes.small};
  padding: 0.5rem 0;
  font-weight: ${fontWeights.semiBold};
}
.container > :global(:not(p)) {
  margin-left: 1rem;
}
.container.vertical {
  flex-direction: column;
  align-items: flex-start;
}
.container p {
  margin-top: 0;
  margin-bottom: 0.2rem;
}
@media screen and (max-width: 992px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
  }
}
`

export const cardRadioButtonsStyles = css`
  .card-radio-buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .card-radio-buttons-container :global(.card-radio-button--container) {
    max-width: 9.5rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .card-radio-button-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .card-radio-button-container img {
    margin: 1rem auto;
  }
  .checkmark {
    height: 2rem;
    display: block;
  }
  .card-radio-buttons-container :global(.card-radio-button) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .card-radio-buttons-container :global(.card-radio-button .checkmark) {
    position: relative;
    top: 0;
  }
`

export { checkbox as default, container }
