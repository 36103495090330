import { combineReducers } from 'redux'
import { HYDRATE } from 'next-redux-wrapper'

import { CLIENT_REDUCERS } from 'app/store/persistConfig'

import { storeRedux } from 'services/store/reducers'
import { reducer as formReducer } from 'redux-form'
import AuthReducer, { AuthActionTypes } from './AuthRedux'
import AlertReducer from './AlertRedux'
import AppReducer from './AppRedux'
import Contact from './ContactReducer'
import binLeadPrequalifyRedux from './binLeadPrequalifyRedux'
import BinReducer from './BinRedux'
import BinAlertsReducer from './BinAlertsRedux'
import CRMReducer from './CRMReducer'
import ContractsRedux from './ContractsRedux'
import ContractVersionReducer from './ContractVersionRedux'
import ErrorReducer from './ErrorRedux'
import GeoReducer from './GeoRedux'
import NotificationReducer from './NotificationRedux'
import SrpReducer from './SrpRedux'
import FileUploadReducer from './FileUploadRedux'
import DocumentFileReducer from './DocumentFileRedux'
import BondReducer from './BondRedux'
import BrokerInfoReducer from './BrokerInfoRedux'
import DirectAnnonceLeadReducer from './DirectAnnonceLeadRedux'
import PortalsReducer from './PortalsRedux'
import CustomEventReducer from './CustomEventRedux'
import StatsReducer from './StatsRedux'
import HelpDeskReducer from './HelpDeskRedux'
import IAReducer from './IARedux'
import ContactJourneyRedux from './ContactJourneyRedux'
import DirectoryReducer from './DirectoryRedux'
import RejectLeadReducer from './RejectLeadRedux'
import PostponeLeadReducer from './PostponeLeadRedux'
import AcceptLeadReducer from './AcceptLeadRedux'
import RFCReducer from './RFCRedux'
import PropertyDocumentation from './PropertyDocumentationRedux'
import PropertyHighlightReducer from './PropertyHighlightRedux'
import PdfDownloadReducer from './PdfDownloadRedux'
import CloseDealReducer from './CloseDealRedux'
import TracingToursReducer from './TracingToursRedux'
import ValidateFormRedux from './ValidateFormRedux'
import VipReducer from './VipRedux'
import LocationHereReducer from './LocationHereRedux'
import LocationRedux from './LocationRedux'
import OnboardingMaterialsReducer from './OnboardingMaterialsRedux'
import DealsReducer from './DealsRedux'
import ReportPropertyReducer from './ReportPropertiesReducer'
import NeximerPropertyReducer from './NeximerPropertyRedux'
import NetworkReportsReducer from './NetworkReportsRedux'
import SeoUrlsRedux from './SeoUrlsRedux'
import RecruitmentLeadRedux from './RecruitmentLeadRedux'
import MLMQuotasRedux from './MLMQuotasRedux'
import MLMCommissionsRedux from './MLMCommissionsRedux'
import MLMValueEstimation from './MLMValueEstimation'
import NetworkStatsRedux from './NetworkStatsRedux'
import NewChildAgentRedux from './NewChildAgentRedux'
import TransactionsRedux from './TransactionsRedux'
import DocumentationFoldersRedux from './DocumentationFoldersRedux'
import DocumentationFilesRedux from './DocumentationFilesRedux'
import PropertyRedux from './PropertyRedux'
import UserSessionRedux from './UserSessionRedux'
import UserPrivateDetailRedux from './UserPrivateDetailRedux'
import UserOnboardingRedux from './UserOnboardingRedux'
import VirtualTourRedux from './VirtualTourRedux'
import ImageTransformationRedux from './ImageTransformationRedux'

const reducers = {
  alerts: AlertReducer,
  app: AppReducer,
  contact: Contact,
  error: ErrorReducer,
  auth: AuthReducer,
  geo: GeoReducer,
  locationHere: LocationHereReducer,
  location: LocationRedux,
  notification: NotificationReducer,
  fileUpload: FileUploadReducer,
  documentFile: DocumentFileReducer,
  bond: BondReducer,
  crm: CRMReducer,
  contracts: ContractsRedux,
  contractVersion: ContractVersionReducer,
  brokerInfo: BrokerInfoReducer,
  srp: SrpReducer,
  form: formReducer,
  portals: PortalsReducer,
  directAnnonceLead: DirectAnnonceLeadReducer,
  customEvent: CustomEventReducer,
  stats: StatsReducer,
  helpDesk: HelpDeskReducer,
  ia: IAReducer,
  directory: DirectoryReducer,
  binLeadPrequalify: binLeadPrequalifyRedux,
  bin: BinReducer,
  binAlerts: BinAlertsReducer,
  rejectLead: RejectLeadReducer,
  postponeLead: PostponeLeadReducer,
  acceptLead: AcceptLeadReducer,
  rfc: RFCReducer,
  propertyDocumentation: PropertyDocumentation,
  propertyHighlight: PropertyHighlightReducer,
  pdfDownload: PdfDownloadReducer,
  closeDeal: CloseDealReducer,
  tracingTours: TracingToursReducer,
  vip: VipReducer,
  validateForm: ValidateFormRedux,
  contactJourney: ContactJourneyRedux,
  onboardingMaterials: OnboardingMaterialsReducer,
  deals: DealsReducer,
  propertyReports: ReportPropertyReducer,
  neximerProperty: NeximerPropertyReducer,
  networkReports: NetworkReportsReducer,
  seoUrls: SeoUrlsRedux,
  recruiting: RecruitmentLeadRedux,
  mlmQuotas: MLMQuotasRedux,
  mlmCommissions: MLMCommissionsRedux,
  mlmValueEstimation: MLMValueEstimation,
  networkStats: NetworkStatsRedux,
  newChildAgent: NewChildAgentRedux,
  transactions: TransactionsRedux,
  documentationFolders: DocumentationFoldersRedux,
  documentationFiles: DocumentationFilesRedux,
  property: PropertyRedux,
  userSession: UserSessionRedux,
  userPrivateDetail: UserPrivateDetailRedux,
  userOnboarding: UserOnboardingRedux,
  virtualTour: VirtualTourRedux,
  imageTransformation: ImageTransformationRedux,
  ...storeRedux
}

const withLogOut = (reducer) => (state, action) => {
  const newState = action.type === AuthActionTypes.LOGOUT_REQUEST
    ? { app: state.app, userSession: state.userSession } : state
  return reducer(newState, action)
}

export const hydratedReducers = Object.entries(reducers).map(
  ([name, reducer]) => {
    if (CLIENT_REDUCERS.includes(name)) {
      return { [name]: withLogOut(reducer) }
    }

    const hydratedReducer = (state, action) => (
      action.type === HYDRATE
        ? action.payload[name]
        : reducer(state, action)
    )
    return { [name]: withLogOut(hydratedReducer) }
  }
).reduce((acc, v) => ({ ...acc, ...v }), {})

const allReducers = combineReducers(reducers)

const rootReducer = (state, action) => {
  if (action.type === AuthActionTypes.LOGOUT_REQUEST) {
    const { app, userSession } = state
    state = { app, userSession }
  }

  switch (action.type) {
    case HYDRATE: {
      // Avoid overwrite important client states
      const newState = action.payload

      const serverState = Object.keys(newState).reduce((acc, reducerName) => {
        if (!CLIENT_REDUCERS.includes(reducerName)) {
          return { ...acc, [reducerName]: newState[reducerName] }
        }

        return acc
      }, {})

      return {
        ...state,
        ...serverState
      }
    }
  }

  return allReducers(state, action)
}

export default rootReducer
