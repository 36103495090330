import { colors } from 'theme'
import css from 'styled-jsx/css'

export default css`
  .button.secondary.outline {
    background-color: ${colors.white};
    border-color: ${colors.borderInput};
    border-color: ${colors.borderInput};
    color: ${colors.darkGray};
    transition: 100ms color;
  }

  .button:not(.disabled):hover.secondary.outline, .button:not(.disabled):focus.secondary.outline {
    color: ${colors.altText};
    transition: 100ms color;
  }
`