import { createReducer, createActions } from 'reduxsauce'
import {
  failureFeedback,
  initialFeedback,
  requestFeedback,
  successFeedback
} from './utils'

const INITIAL_STATE = {
  allUrls: {
    urls: [],
    feedback: initialFeedback()
  }
}

const { Types, Creators } = createActions(
  {
    all: ['page', 'params'],
    allSuccess: ['urls'],
    allFailure: ['errors'],
    clear: []
  },
  { prefix: 'SEO_URLS_' }
)

const all = (state, { page, params }) => ({
  ...state,
  allUrls: {
    ...state.allUrls,
    feedback: requestFeedback()
  }
})

const allSuccess = (state, { urls }) => ({
  ...state,
  allUrls: {
    ...state.allUrls,
    urls,
    feedback: successFeedback()
  }
})

const allFailure = (state, { errors }) => ({
  ...state,
  allUrls: {
    ...state.allUrls,
    feedback: failureFeedback(errors)
  }
})

const clear = () => INITIAL_STATE

export const SeoUrlsActionTypes = Types

const HANDLERS = {
  [Types.ALL]: all,
  [Types.ALL_SUCCESS]: allSuccess,
  [Types.ALL_FAILURE]: allFailure,
  [Types.CLEAR]: clear
}

export const SeoUrlsActionCreators = Creators

export default createReducer(INITIAL_STATE, HANDLERS)
