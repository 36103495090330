import api from '../api'

const initSession = (token, subscriptionId, data) => api.privatePost(
  `/v1/payments/subscriptions/${subscriptionId}/sessions/`,
  { token, data }
)

const subscriptionPortalUrl = (token, subscriptionId) => api.privateGet(
  `/v1/payments/subscriptions/${subscriptionId}/portal_url/`,
  { token }
)

const getSignupFee = (token, subscriptionId) => api.privateGet(
  `/v1/payments/subscriptions/${subscriptionId}/signup_fee/`,
  { token }
)

export default {
  initSession,
  subscriptionPortalUrl,
  getSignupFee,
}
