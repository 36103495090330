export default {
  name: 'helpDeskConversation',
  namespace: 'conversations',
  methods: [
    {
      name: 'find',
      params: ['id'],
      successCode: 200,
      method: 'Get',
      url: ({ namespace, id }) => `/v1/helpscout/${namespace}/${id}.json`,
      urlParams: ['id']
    },
    {
      name: 'create',
      params: ['data'],
      successCode: 201,
      method: 'Post',
      url: ({ namespace }) => `/v1/helpscout/${namespace}/`,
      urlParams: []
    }
  ]
}
