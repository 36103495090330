import { put, select, call } from 'redux-saga/effects'
import api from 'app/services/api/api'

import { shouldMakeANewRequest  } from 'services/api/utils/requestWithCache'
import { hourInMilliseconds } from 'services/date'

import { store } from 'services'

import { GeoActionCreators } from 'app/reducers/GeoRedux'
import { SrpActionCreators } from 'app/reducers/SrpRedux'

import { isBoolAmenityParam, SPECIAL_AMENITIES_LABELS } from 'services/seo/amenities'
import { snakeToCamel } from 'services/string'

const maximumTimeForValidCache = hourInMilliseconds * 24

export function* searchWithLocationString({ filters, headersNexsites }) {
  const { location } = filters

  if (!location) {
    yield searchWithLocationObject({
      filters,
      headersNexsites
    })
  } else {
    const locationParts = location.split('--').map(l => l.replace(/-/gi, ' '))
    let locationObject = {
      neighborhood: '',
      district: '',
      state: '',
      postalCode: '',
      country: ''
    }

    if (locationParts.length === 3) {
      locationObject.neighborhood = locationParts[0]
      locationObject.district = locationParts[1]
      locationObject.state = locationParts[2]
    } else if (locationParts.length === 2) {
      locationObject.district = locationParts[0]
      locationObject.state = locationParts[1]
    } else if (locationParts.length === 1) {
      locationObject.state = locationParts[0]
    } else {
      locationObject = null
    }

    yield searchWithLocationObject({
      filters: {
        ...filters,
        location: locationObject
      },
      headersNexsites
    })
  }
}

export function* searchWithLocationObject({ filters, headersNexsites }) {
  let location = filters.location
  let queryParams = {}
  let amenities = []

  Object.keys(filters).forEach(k => {
    const lowerName = snakeToCamel(k)
    if (isBoolAmenityParam(lowerName)) {
      amenities.push(SPECIAL_AMENITIES_LABELS[lowerName])
    } else if (filters[k] && k !== 'location') {
      queryParams[k] = filters[k]
    }
  })

  if (amenities.length > 0) {
    queryParams.amenities = amenities
  }

  if (location) {
    queryParams['selected_locations'] = [
      `${location.neighborhood}::${location.district}::${location.state}`
    ]
    yield put(GeoActionCreators.replaceCurrentLocation(location))
  }

  if (headersNexsites) {
    yield put(
      store
        .listProperties('nexsitesProperty')
        .action(queryParams, headersNexsites)
    )
  } else {
    yield put(store.publicSearch('property').action(queryParams))
  }
}

function* requestFooterLinks({ filters }) {
  const { location, ...restFilters } = filters || {}
  
  const response = yield call(
    api.get,
    '/v1/public/landing_page/footer_urls/',
    restFilters
  )

  if (response.status === 200) {
    yield put(SrpActionCreators.updateFooterLinksProperties(response.data, new Date().getTime()))
  }
}

export function* getSEOFooterResourse({ typeOfResourse = [], filters = {} }) {
  if (typeOfResourse.includes('highlightsProperties')) {
    const response = yield call(api.get, '/v1/public/landing_page/highlights/')

    if (response.status === 200) {
      yield put(
        SrpActionCreators.updateHighlightsProperties(response.data.categories)
      )
    }
  }

  if (typeOfResourse.includes('footerUrls')) {
    const lastRequestedUpdate = yield select(state => state.srp.lastRequestedUpdate)

    if (shouldMakeANewRequest(false, lastRequestedUpdate, maximumTimeForValidCache)) {
      yield requestFooterLinks({ filters })
    }
  }
}
